export const MAX_CHALLENGES = 6
export const ALERT_TIME_MS = 2000
export const REVEAL_TIME_MS = 350
export const WELCOME_INFO_MODAL_MS = 350
export const DISCOURAGE_INAPP_BROWSERS = true
export const ENABLE_MIGRATE_STATS = true
export const BLOWFISH_KEY = 'XCquahSIK#tHQ&lg*8ES2dSz$3BW^E'
export const BLOWFISH_IV = '#45xMf^W'
export const SUPP_WIDTH = 80
export const SITE_URL = 'https://bopomofogame.com'
export const INDEX_OFFSET = 200 // artificially sync up with old version
