export const WORDS = [
"ㄏㄨㄢㄍㄨ", // 環顧
"ㄕㄤㄒㄧㄢ", // 上限
"ㄖㄨㄐㄧㄥ", // 入境
"ㄩㄝㄊㄨㄢ", // 樂團
"ㄒㄧㄣㄕㄥ", // 心聲
"ㄈㄟㄒㄧㄥ", // 飛行
"ㄧㄡㄉㄧㄢ", // 優點
"ㄊㄠㄓㄨㄤ", // 套裝
"ㄌㄨㄍㄨㄛ", // 路過
"ㄗㄨㄛㄉㄠ", // 做到
"ㄐㄧㄠㄧㄝ", // 郊野
"ㄏㄨㄐㄧㄠ", // 呼叫
"ㄧㄡㄓㄩㄢ", // 幼稚園
"ㄏㄨㄛㄕㄠ", // 火燒
"ㄍㄨㄥㄍㄨ", // 鞏固
"ㄈㄢㄓㄨㄢ", // 反轉
"ㄓㄣㄙㄨㄛ", // 診所
"ㄒㄧㄍㄨㄢ", // 習慣
"ㄌㄧㄝㄖㄣ", // 獵人
"ㄕㄥㄑㄧㄢ", // 生前
"ㄓㄨㄍㄨㄢ", // 主管
"ㄔㄨㄥㄅㄞ", // 崇拜
"ㄐㄧㄒㄧㄝ", // 機械
"ㄐㄧㄚㄑㄧ", // 假期
"ㄘㄠㄎㄨㄥ", // 操控
"ㄏㄢㄐㄧㄢ", // 罕見
"ㄖㄣㄊㄨㄥ", // 認同
"ㄎㄜㄒㄧㄣ", // 可信
"ㄧㄠㄉㄧㄢ", // 要點
"ㄊㄡㄒㄧㄠ", // 偷笑
"ㄐㄧㄝㄖㄨ", // 介入
"ㄩㄣㄗㄨㄛ", // 運作
"ㄊㄧㄢㄕㄤ", // 天上
"ㄌㄠㄉㄨㄥ", // 勞動
"ㄕㄨㄣㄧㄢ", // 順眼
"ㄕㄡㄐㄧㄠ", // 手腳
"ㄧㄡㄒㄧㄣ", // 有心
"ㄗㄥㄐㄧㄚ", // 增加
"ㄆㄢㄉㄧㄥ", // 判定
"ㄉㄤㄒㄩㄢ", // 當選
"ㄕㄤㄒㄩㄝ", // 上學
"ㄊㄧㄊㄧㄝ", // 體貼
"ㄉㄠㄐㄧㄚ", // 到家
"ㄎㄨㄥㄒㄩ", // 空虛
"ㄇㄧㄣㄕㄥ", // 民生
"ㄈㄚㄒㄧㄢ", // 發現
"ㄉㄚㄍㄨㄣ", // 打滾
"ㄐㄧㄝㄌㄧ", // 竭力
"ㄖㄣㄒㄧㄣ", // 忍心
"ㄈㄚㄒㄧㄝ", // 發洩
"ㄕㄨㄉㄧㄢ", // 書店
"ㄘㄨㄛㄏㄜ", // 撮合
"ㄐㄧㄚㄖㄨ", // 假如
"ㄨㄤㄐㄧㄢ", // 望見
"ㄓㄨㄥㄉㄨ", // 中毒
"ㄒㄧㄣㄉㄧ", // 心地
"ㄐㄧㄆㄧㄠ", // 機票
"ㄧㄡㄑㄧㄥ", // 友情
"ㄎㄨㄞㄍㄢ", // 快感
"ㄓㄠㄆㄧㄢ", // 照片
"ㄑㄧㄤㄖㄣ", // 強人
"ㄇㄤㄉㄧㄢ", // 盲點
"ㄏㄨㄛㄓㄜ", // 或者
"ㄒㄧㄥㄑㄧ", // 興起
"ㄌㄧㄇㄧㄢ", // 裡面
"ㄏㄨㄤㄏㄜ", // 黃河
"ㄏㄨㄚㄩㄢ", // 花園
"ㄐㄧㄝㄎㄡ", // 藉口
"ㄒㄧㄠㄌㄩ", // 效率
"ㄒㄧㄤㄍㄜ", // 相隔
"ㄏㄨㄛㄕㄠ", // 火燒
"ㄌㄠㄍㄨㄥ", // 老公
"ㄑㄧㄥㄔㄨ", // 清楚
"ㄑㄧㄥㄍㄢ", // 情感
"ㄑㄩㄝㄈㄚ", // 缺乏
"ㄉㄨㄥㄧㄠ", // 動搖
"ㄍㄨㄛㄑㄩ", // 過去
"ㄗㄨㄛㄖㄣ", // 做人
"ㄍㄨㄥㄖㄣ", // 工人
"ㄧㄡㄌㄧㄥ", // 幽靈
"ㄎㄨㄥㄐㄩ", // 恐懼
"ㄨㄞㄅㄧㄠ", // 外表
"ㄍㄨㄤㄔㄤ", // 廣場
"ㄏㄞㄅㄧㄢ", // 海邊
"ㄨㄤㄌㄨㄛ", // 網絡
"ㄊㄜㄉㄧㄢ", // 特點
"ㄒㄧㄓㄨㄤ", // 西裝
"ㄈㄨㄐㄧㄣ", // 附近
"ㄓㄢㄍㄨㄛ", // 戰國
"ㄒㄧㄢㄕㄥ", // 先生
"ㄓㄨㄥㄈㄨ", // 中伏
"ㄊㄜㄒㄧㄥ", // 特性
"ㄍㄨㄥㄩㄢ", // 公園
"ㄊㄨㄥㄅㄢ", // 同班
"ㄩㄝㄌㄧㄤ", // 月亮
"ㄏㄨㄢㄌㄜ", // 歡樂
"ㄒㄧㄠㄔㄡ", // 小丑
"ㄒㄧㄣㄎㄨ", // 辛苦
"ㄔㄨㄥㄕㄤ", // 重傷
"ㄕㄠㄋㄧㄢ", // 少年
"ㄎㄜㄐㄧㄢ", // 可見
"ㄊㄧㄢㄏㄞ", // 填海
"ㄍㄨㄥㄐㄩ", // 工具
"ㄅㄠㄑㄧㄢ", // 抱歉
"ㄐㄧㄠㄕㄥ", // 叫聲
"ㄋㄢㄇㄧㄣ", // 難民
"ㄉㄚㄌㄨㄢ", // 大亂
"ㄐㄧㄥㄇㄛ", // 靜默
"ㄒㄧㄠㄈㄥ", // 校風
"ㄕㄤㄌㄧㄡ", // 上流
"ㄋㄧㄢㄉㄞ", // 年代
"ㄔㄥㄋㄨㄛ", // 承諾
"ㄧㄣㄑㄧㄥ", // 引擎
"ㄆㄧㄣㄆㄞ", // 品牌
"ㄍㄨㄥㄒㄧ", // 恭喜
"ㄊㄨㄓㄨㄥ", // 途中
"ㄒㄧㄣㄉㄧ", // 心底
"ㄑㄩㄣㄊㄧ", // 群體
"ㄑㄧㄣㄈㄢ", // 侵犯
"ㄏㄠㄒㄧㄤ", // 好像
"ㄉㄨㄢㄑㄧ", // 斷氣
"ㄒㄧㄒㄧㄣ", // 細心
"ㄅㄣㄒㄧㄥ", // 本性
"ㄗㄨㄟㄕㄠ", // 最少
"ㄐㄧㄢㄎㄤ", // 健康
"ㄅㄠㄓㄨㄥ", // 保重
"ㄧㄠㄎㄨㄥ", // 遙控
"ㄘㄞㄌㄧㄠ", // 材料
"ㄍㄜㄙㄨㄥ", // 歌頌
"ㄧㄣㄒㄧㄤ", // 音響
"ㄍㄨㄉㄧㄢ", // 古典
"ㄉㄧㄢㄉㄥ", // 電燈
"ㄧㄎㄡㄑㄧ", // 一口氣
"ㄐㄧㄠㄅㄨ", // 腳步
"ㄍㄨㄛㄧㄡ", // 國有
"ㄓㄥㄑㄩㄢ", // 政權
"ㄑㄩㄢㄔㄥ", // 全城
"ㄗㄐㄧㄖㄣ", // 自己人
"ㄍㄨㄛㄓㄣ", // 果真
"ㄉㄧㄥㄐㄩ", // 定居
"ㄧㄠㄐㄧㄣ", // 要緊
"ㄅㄠㄒㄧㄢ", // 保險
"ㄐㄧㄝㄕㄨ", // 結束
"ㄉㄧㄅㄧㄠ", // 地標
"ㄑㄧㄥㄒㄩ", // 情緒
"ㄒㄧㄚㄅㄢ", // 下班
"ㄘㄨㄌㄩㄝ", // 粗略
"ㄕㄜㄊㄨㄢ", // 社團
"ㄍㄣㄙㄨㄟ", // 跟隨
"ㄕㄣㄒㄧㄣ", // 身心
"ㄒㄧㄣㄔㄤ", // 心腸
"ㄅㄧㄠㄅㄤ", // 標榜
"ㄕㄣㄐㄧㄚ", // 身家
"ㄑㄩㄝㄖㄣ", // 確認
"ㄑㄧㄣㄑㄧ", // 親戚
"ㄍㄨㄛㄌㄩ", // 過濾
"ㄑㄧㄥㄌㄧ", // 情理
"ㄒㄧㄢㄏㄡ", // 先後
"ㄕㄢㄉㄧㄢ", // 閃電
"ㄐㄧㄉㄨㄥ", // 激動
"ㄓㄨㄢㄧㄢ", // 轉眼
"ㄉㄚㄒㄧㄥ", // 大型
"ㄏㄨㄛㄅㄧ", // 貨幣
"ㄇㄨㄑㄧㄢ", // 目前
"ㄊㄨㄥㄎㄨ", // 痛哭
"ㄕㄜㄧㄥㄕ", // 攝影師
"ㄍㄨㄛㄏㄡ", // 過後
"ㄒㄧㄤㄧㄥ", // 相應
"ㄎㄨㄞㄐㄧ", // 會計
"ㄏㄜㄒㄧㄝ", // 和諧
"ㄋㄧㄢㄕㄠ", // 年少
"ㄨㄞㄍㄨㄥ", // 外公
"ㄐㄧㄢㄒㄧ", // 見習
"ㄅㄧㄢㄇㄚ", // 編碼
"ㄌㄨㄣㄌㄧ", // 倫理
"ㄗㄞㄒㄧㄚ", // 在下
"ㄧㄣㄕㄨㄚ", // 印刷
"ㄗㄨㄟㄔㄨ", // 最初
"ㄅㄧㄢㄙㄜ", // 變色
"ㄉㄚㄉㄨㄛ", // 大多
"ㄔㄨㄉㄨㄥ", // 出動
"ㄉㄨㄥㄒㄧ", // 東西
"ㄕㄣㄉㄨㄢ", // 身段
"ㄒㄧㄥㄑㄧ", // 星期
"ㄊㄡㄊㄧㄠ", // 頭條
"ㄈㄨㄒㄧㄥ", // 復興
"ㄊㄧㄢㄑㄧ", // 天氣
"ㄘㄠㄗㄨㄛ", // 操作
"ㄆㄞㄉㄨㄟ", // 排隊
"ㄌㄥㄐㄧㄥ", // 冷靜
"ㄔㄥㄐㄧㄡ", // 成就
"ㄌㄧㄢㄒㄧ", // 聯繫
"ㄋㄢㄇㄧㄢ", // 難免
"ㄅㄠㄓㄨㄤ", // 包裝
"ㄑㄧㄥㄕㄢ", // 青山
"ㄕㄨㄌㄧㄤ", // 數量
"ㄌㄠㄇㄚㄗ", // 老媽子
"ㄐㄧㄝㄓㄜ", // 接著
"ㄒㄧㄏㄨㄢ", // 喜歡
"ㄒㄧㄥㄊㄞ", // 形態
"ㄉㄚㄐㄧㄚ", // 大家
"ㄒㄧㄇㄧㄝ", // 熄滅
"ㄨㄤㄒㄧㄤ", // 妄想
"ㄈㄟㄕㄨㄟ", // 廢水
"ㄕㄤㄌㄧㄤ", // 商量
"ㄉㄨㄍㄨㄛ", // 度過
"ㄅㄨㄒㄧㄝ", // 不屑
"ㄒㄧㄠㄉㄧ", // 小弟
"ㄊㄧㄝㄇㄚ", // 鐵馬
"ㄏㄠㄊㄧㄥ", // 好聽
"ㄍㄞㄒㄧㄝ", // 改寫
"ㄖㄨㄍㄨㄛ", // 如果
"ㄍㄢㄒㄧㄥ", // 感性
"ㄗㄨㄛㄓㄢ", // 作戰
"ㄒㄧㄤㄨㄤ", // 嚮往
"ㄓㄥㄑㄩㄝ", // 正確
"ㄒㄧㄥㄔㄥ", // 行程
"ㄍㄨㄏㄨㄛ", // 蠱惑
"ㄈㄤㄒㄩㄝ", // 放學
"ㄗㄨㄛㄑㄩ", // 作曲
"ㄔㄤㄍㄨㄥ", // 長工
"ㄎㄨㄒㄧㄠ", // 苦笑
"ㄐㄧㄏㄨㄟ", // 集會
"ㄉㄞㄐㄧㄚ", // 代價
"ㄅㄧㄠㄊㄧ", // 標題
"ㄐㄧㄥㄌㄧ", // 經理
"ㄐㄩㄒㄧㄢ", // 局限
"ㄒㄧㄚㄑㄧ", // 下氣
"ㄆㄞㄊㄨㄛ", // 拍拖
"ㄐㄧㄉㄨㄢ", // 極端
"ㄐㄧㄝㄈㄟ", // 劫匪
"ㄒㄧㄠㄏㄞ", // 小孩
"ㄐㄧㄥㄊㄡ", // 鏡頭
"ㄓㄨㄥㄖㄣ", // 眾人
"ㄒㄧㄤㄧㄡ", // 享有
"ㄖㄣㄐㄧㄢ", // 人間
"ㄖㄣㄒㄧㄥ", // 人性
"ㄍㄜㄒㄧㄥ", // 個性
"ㄇㄟㄐㄧㄝ", // 媒介
"ㄒㄧㄤㄨㄟ", // 香味
"ㄅㄞㄊㄨㄛ", // 擺脫
"ㄍㄨㄥㄋㄥ", // 功能
"ㄒㄧㄣㄩㄥ", // 信用
"ㄊㄨㄥㄍㄢ", // 同感
"ㄓㄨㄥㄔㄢ", // 中產
"ㄔㄨㄣㄕㄨ", // 純熟
"ㄎㄨㄣㄋㄢ", // 困難
"ㄒㄧㄚㄌㄞ", // 下來
"ㄨㄟㄒㄧㄝ", // 威脅
"ㄑㄧㄓㄨㄥ", // 其中
"ㄌㄧㄡㄩㄝ", // 六月
"ㄈㄚㄉㄧㄥ", // 法定
"ㄓㄥㄐㄧㄢ", // 證件
"ㄐㄧㄢㄔㄚ", // 檢查
"ㄘㄢㄒㄩㄢ", // 參選
"ㄋㄨㄥㄔㄤ", // 農場
"ㄧㄢㄍㄨㄤ", // 眼光
"ㄏㄠㄒㄧㄝ", // 好些
"ㄅㄨㄍㄨㄛ", // 不過
"ㄐㄧㄍㄨㄢ", // 機關
"ㄎㄨㄥㄉㄧ", // 空地
"ㄉㄨㄢㄎㄨ", // 短褲
"ㄓㄣㄗㄨㄛ", // 振作
"ㄙㄨㄛㄨㄟ", // 所謂
"ㄊㄧㄍㄨㄥ", // 提供
"ㄖㄜㄑㄧㄥ", // 熱情
"ㄩㄝㄅㄧㄥ", // 月餅
"ㄕㄡㄒㄧㄢ", // 首先
"ㄍㄨㄛㄋㄟ", // 國內
"ㄕㄣㄑㄧㄥ", // 申請
"ㄆㄧㄥㄕㄥ", // 平聲
"ㄉㄠㄑㄧㄢ", // 道歉
"ㄐㄧㄥㄧㄡ", // 經由
"ㄍㄨㄟㄌㄩ", // 規律
"ㄓㄨㄟㄕㄚ", // 追殺
"ㄈㄤㄒㄧㄢ", // 防線
"ㄊㄨㄥㄅㄨ", // 同步
"ㄧㄥㄒㄩㄥ", // 英雄
"ㄧㄡㄅㄧㄢ", // 右邊
"ㄗㄨㄐㄧㄢ", // 組件
"ㄋㄧㄥㄨㄤ", // 凝望
"ㄧㄡㄅㄧㄥ", // 有病
"ㄍㄢㄒㄧㄝ", // 感謝
"ㄈㄢㄘㄨㄛ", // 犯錯
"ㄓㄨㄤㄔㄜ", // 撞車
"ㄔㄚㄗㄨㄟ", // 插嘴
"ㄓㄨㄇㄧㄥ", // 著名
"ㄈㄚㄏㄨㄟ", // 發揮
"ㄧㄡㄧㄕㄡ", // 有一手
"ㄐㄧㄥㄘㄞ", // 精彩
"ㄏㄠㄏㄨㄚ", // 好話
"ㄈㄣㄇㄧㄥ", // 分明
"ㄏㄨㄤㄓㄤ", // 慌張
"ㄒㄧㄠㄩㄥ", // 效用
"ㄅㄧㄢㄐㄧ", // 編輯
"ㄍㄨㄥㄈㄨ", // 工夫
"ㄏㄨㄣㄧㄠ", // 混淆
"ㄐㄧㄣㄕㄨ", // 金屬
"ㄑㄩㄝㄅㄠ", // 確保
"ㄆㄞㄆㄧㄢ", // 拍片
"ㄉㄨㄥㄒㄧ", // 東西
"ㄉㄧㄥㄌㄩ", // 定律
"ㄒㄧㄣㄌㄧ", // 心力
"ㄉㄜㄍㄨㄛ", // 得過
"ㄌㄧㄡㄔㄥ", // 流程
"ㄧㄣㄍㄨㄛ", // 因果
"ㄉㄧㄢㄋㄠ", // 電腦
"ㄉㄨㄟㄎㄤ", // 對抗
"ㄏㄨㄟㄓㄤ", // 會長
"ㄖㄣㄐㄧㄚ", // 人家
"ㄊㄨㄢㄊㄧ", // 團體
"ㄉㄧㄒㄧㄚ", // 底下
"ㄇㄧㄣㄍㄢ", // 敏感
"ㄅㄨㄐㄧㄣ", // 不盡
"ㄐㄧㄥㄙㄜ", // 景色
"ㄏㄨㄢㄏㄨ", // 歡呼
"ㄈㄨㄑㄧㄣ", // 父親
"ㄉㄧㄠㄔㄚ", // 調查
"ㄓㄨㄢㄊㄡ", // 轉頭
"ㄉㄧㄥㄉㄤ", // 叮噹
"ㄨㄣㄐㄧㄥ", // 文靜
"ㄒㄧㄚㄓㄞ", // 狹窄
"ㄒㄧㄥㄖㄣ", // 行人
"ㄊㄧㄌㄧㄤ", // 體諒
"ㄌㄞㄌㄧㄣ", // 來臨
"ㄋㄟㄐㄧㄡ", // 內疚
"ㄐㄧㄣㄕㄥ", // 今生
"ㄊㄡㄒㄧㄢ", // 頭先
"ㄒㄧㄣㄑㄧ", // 新奇
"ㄉㄚㄌㄧㄤ", // 打量
"ㄒㄧㄤㄈㄢ", // 相反
"ㄋㄧㄥㄩㄢ", // 寧願
"ㄧㄡㄕㄏㄡ", // 有時候
"ㄩㄢㄒㄧㄥ", // 圓形
"ㄒㄧㄥㄈㄣ", // 興奮
"ㄆㄧㄐㄩㄢ", // 疲倦
"ㄐㄧㄣㄊㄡ", // 盡頭
"ㄅㄨㄎㄨㄟ", // 不愧
"ㄒㄧㄥㄧㄝ", // 行業
"ㄧㄡㄓㄨㄥ", // 有種
"ㄙㄨㄐㄧㄠ", // 塑膠
"ㄉㄨㄟㄓㄠ", // 對照
"ㄔㄡㄒㄧㄤ", // 抽象
"ㄒㄧㄢㄓㄨ", // 顯著
"ㄏㄨㄤㄇㄤ", // 慌忙
"ㄑㄧㄑㄧㄡ", // 祈求
"ㄅㄨㄌㄧㄡ", // 不留
"ㄐㄧㄢㄖㄣ", // 賤人
"ㄏㄨㄛㄕㄢ", // 火山
"ㄌㄠㄉㄨㄥ", // 勞動
"ㄌㄧㄢㄒㄧ", // 連繫
"ㄐㄧㄝㄙㄢ", // 解散
"ㄏㄜㄒㄧㄣ", // 核心
"ㄎㄞㄔㄨㄤ", // 開創
"ㄇㄛㄍㄨㄟ", // 魔鬼
"ㄕㄣㄑㄧㄥ", // 神情
"ㄧㄡㄇㄧㄥ", // 有命
"ㄑㄧㄥㄅㄞ", // 清白
"ㄅㄞㄒㄧㄥ", // 百姓
"ㄐㄧㄚㄖㄣ", // 家人
"ㄎㄞㄏㄨㄚ", // 開花
"ㄇㄧㄢㄐㄩ", // 面具
"ㄕㄢㄕㄨㄛ", // 閃爍
"ㄈㄚㄒㄧㄥ", // 發行
"ㄐㄧㄥㄔㄚ", // 警察
"ㄈㄢㄉㄨㄟ", // 反對
"ㄈㄚㄉㄨㄥ", // 發動
"ㄐㄧㄝㄨㄟ", // 結尾
"ㄕㄤㄑㄧㄝ", // 尚且
"ㄑㄧㄠㄏㄜ", // 巧合
"ㄐㄧㄥㄒㄧ", // 精細
"ㄉㄨㄟㄉㄞ", // 對待
"ㄉㄚㄗㄨㄛ", // 大作
"ㄗㄞㄕㄨㄛ", // 再說
"ㄓㄥㄑㄩㄢ", // 證券
"ㄗㄨㄛㄨㄢ", // 昨晚
"ㄍㄨㄢㄎㄢ", // 觀看
"ㄏㄨㄢㄇㄢ", // 緩慢
"ㄉㄤㄋㄧㄢ", // 當年
"ㄕㄨㄟㄈㄨ", // 說服
"ㄒㄧㄣㄗㄤ", // 心臟
"ㄩㄣㄉㄨㄥ", // 運動
"ㄅㄚㄍㄨㄥ", // 罷工
"ㄇㄧㄢㄙㄜ", // 面色
"ㄋㄩㄒㄧㄥ", // 女性
"ㄉㄨㄛㄕㄠ", // 多少
"ㄋㄢㄒㄧㄚ", // 南下
"ㄈㄨㄑㄧㄢ", // 膚淺
"ㄅㄨㄆㄧㄥ", // 不平
"ㄑㄧㄥㄔㄨ", // 清除
"ㄅㄣㄑㄧㄢ", // 本錢
"ㄑㄧㄢㄍㄨ", // 千古
"ㄋㄨㄛㄈㄨ", // 懦夫
"ㄊㄠㄏㄨㄚ", // 桃花
"ㄙㄨㄟㄖㄢ", // 雖然
"ㄍㄨㄢㄩㄢ", // 官員
"ㄎㄢㄐㄧㄢ", // 看見
"ㄏㄥㄌㄧㄤ", // 衡量
"ㄒㄩㄢㄧㄤ", // 宣揚
"ㄖㄨㄗㄨㄛ", // 入座
"ㄉㄨㄥㄅㄟ", // 東北
"ㄋㄢㄍㄨㄞ", // 難怪
"ㄍㄨㄢㄈㄤ", // 官方
"ㄗㄨㄟㄏㄡ", // 最後
"ㄇㄟㄍㄨㄟ", // 玫瑰
"ㄇㄧㄢㄅㄠ", // 麵包
"ㄒㄧㄌㄧㄝ", // 系列
"ㄎㄜㄌㄧㄢ", // 可憐
"ㄉㄨㄧㄨㄦ", // 獨一無二
"ㄓㄨㄥㄍㄨ", // 中古
"ㄈㄚㄎㄨㄤ", // 發狂
"ㄒㄩㄢㄗㄜ", // 選擇
"ㄐㄧㄢㄓㄥ", // 見證
"ㄍㄠㄒㄧㄚ", // 高下
"ㄓㄨㄥㄕㄥ", // 終生
"ㄓㄨㄗㄨㄛ", // 著作
"ㄗㄧㄨㄟㄕ", // 自以為是
"ㄏㄡㄍㄨㄛ", // 後果
"ㄎㄣㄉㄧㄥ", // 肯定
"ㄓㄥㄐㄧㄥ", // 正經
"ㄐㄧㄤㄏㄨ", // 江湖
"ㄌㄧㄢㄒㄩ", // 連續
"ㄑㄩㄝㄎㄡ", // 缺口
"ㄏㄨㄟㄌㄞ", // 回來
"ㄧㄡㄒㄧㄠ", // 有效
"ㄧㄣㄐㄧㄡ", // 飲酒
"ㄔㄨㄥㄕㄤ", // 重傷
"ㄊㄧㄠㄊㄧ", // 挑剔
"ㄓㄨㄥㄌㄧ", // 中立
"ㄑㄧㄢㄕㄜ", // 前設
"ㄅㄢㄑㄩㄢ", // 版權
"ㄍㄢㄘㄨㄟ", // 乾脆
"ㄧㄥㄐㄧㄝ", // 迎接
"ㄐㄧㄣㄑㄩ", // 禁區
"ㄩㄣㄉㄨㄥ", // 運動
"ㄐㄧㄠㄧㄡ", // 交友
"ㄇㄚㄑㄩㄝ", // 麻雀
"ㄉㄚㄐㄧㄝ", // 打劫
"ㄨㄣㄒㄧㄢ", // 文獻
"ㄑㄩㄌㄧㄡ", // 去留
"ㄍㄥㄐㄧㄚ", // 更加
"ㄅㄚㄑㄩㄢ", // 霸權
"ㄒㄩㄥㄓㄤ", // 兄長
"ㄓㄨㄥㄌㄟ", // 種類
"ㄔㄤㄋㄧㄢ", // 長年
"ㄊㄡㄆㄧㄠ", // 投票
"ㄑㄧㄢㄏㄡ", // 前後
"ㄐㄧㄣㄓㄠ", // 今朝
"ㄒㄧㄚㄑㄩ", // 下去
"ㄏㄨㄟㄊㄡ", // 回頭
"ㄉㄨㄍㄨㄛ", // 渡過
"ㄗㄧㄢㄗㄩ", // 自言自語
"ㄒㄧㄠㄒㄧ", // 消息
"ㄊㄢㄐㄧㄡ", // 探究
"ㄊㄨㄛㄧㄢ", // 拖延
"ㄋㄨㄥㄌㄧ", // 農曆
"ㄊㄧㄢㄇㄧ", // 甜蜜
"ㄅㄨㄔㄨㄥ", // 補充
"ㄑㄩㄢㄊㄡ", // 拳頭
"ㄕㄜㄐㄧㄠ", // 社交
"ㄓㄨㄟㄨㄣ", // 追問
"ㄒㄧㄣㄊㄡ", // 心頭
"ㄌㄧㄡㄌㄨ", // 流露
"ㄌㄨㄇㄧㄢ", // 路面
"ㄑㄧㄕㄨㄟ", // 汽水
"ㄆㄞㄌㄧㄝ", // 排列
"ㄍㄞㄇㄧㄥ", // 改名
"ㄨㄞㄏㄨㄟ", // 外匯
"ㄓㄨㄤㄅㄟ", // 裝備
"ㄉㄚㄍㄨㄥ", // 打工
"ㄏㄣㄒㄧㄣ", // 狠心
"ㄉㄧㄑㄧㄡ", // 地球
"ㄉㄤㄓㄨㄥ", // 當中
"ㄊㄧㄠㄌㄧ", // 條例
"ㄐㄧㄡㄅㄚ", // 酒吧
"ㄊㄧㄥㄨㄣ", // 聽聞
"ㄧㄡㄧㄨㄧ", // 有意無意
"ㄧㄢㄑㄧㄡ", // 眼球
"ㄒㄧㄣㄧㄤ", // 信仰
"ㄆㄧㄥㄉㄥ", // 平等
"ㄇㄧㄥㄩㄝ", // 明月
"ㄍㄨㄥㄔㄨ", // 共處
"ㄍㄜㄒㄧㄣ", // 革新
"ㄐㄧㄚㄍㄜ", // 價格
"ㄍㄠㄊㄧㄝ", // 高鐵
"ㄇㄢㄏㄨㄚ", // 漫畫
"ㄔㄥㄑㄧㄥ", // 澄清
"ㄕㄥㄊㄧㄠ", // 聲調
"ㄓㄥㄗㄨㄥ", // 正宗
"ㄧㄡㄒㄧㄥ", // 有幸
"ㄨㄌㄧㄍㄢ", // 無力感
"ㄅㄧㄠㄉㄚ", // 表達
"ㄗㄨㄛㄉㄚ", // 做大
"ㄒㄧㄥㄔㄥ", // 形成
"ㄕㄨㄛㄈㄚ", // 說法
"ㄧㄕㄐㄧㄢ", // 一時間
"ㄒㄧㄢㄔㄤ", // 現場
"ㄒㄩㄥㄉㄧ", // 兄弟
"ㄒㄧㄢㄇㄨ", // 羨慕
"ㄕㄡㄒㄧㄚ", // 手下
"ㄍㄨㄟㄌㄟ", // 歸類
"ㄖㄣㄇㄧㄥ", // 人命
"ㄕㄤㄐㄧㄚ", // 商家
"ㄒㄩㄝㄍㄠ", // 雪糕
"ㄗㄠㄐㄧㄡ", // 造就
"ㄅㄛㄉㄨㄛ", // 剝奪
"ㄋㄧㄢㄉㄧ", // 年底
"ㄑㄧㄢㄊㄨ", // 前途
"ㄔㄥㄍㄨㄥ", // 成功
"ㄆㄨㄊㄨㄥ", // 普通
"ㄒㄧㄚㄅㄚ", // 下巴
"ㄔㄨㄢㄉㄧ", // 傳遞
"ㄔㄨㄆㄧㄣ", // 出品
"ㄧㄢㄐㄧㄝ", // 眼界
"ㄐㄧㄠㄊㄚ", // 腳踏
"ㄎㄜㄊㄧㄥ", // 客廳
"ㄊㄜㄉㄧㄥ", // 特定
"ㄙㄢㄐㄧㄠ", // 三角
"ㄅㄧㄢㄐㄩ", // 編劇
"ㄔㄠㄍㄨㄛ", // 超過
"ㄌㄨㄛㄕㄡ", // 落手
"ㄔㄨㄢㄨㄣ", // 傳聞
"ㄏㄨㄚㄕㄥ", // 花生
"ㄕㄤㄅㄧㄢ", // 上邊
"ㄍㄡㄐㄧㄝ", // 勾結
"ㄉㄧㄥㄑㄧ", // 定期
"ㄐㄧㄝㄗㄡ", // 節奏
"ㄒㄧㄣㄖㄣ", // 信任
"ㄍㄨㄢㄘㄞ", // 棺材
"ㄓㄥㄐㄧㄡ", // 拯救
"ㄧㄠㄏㄨㄤ", // 搖晃
"ㄎㄞㄏㄨㄟ", // 開會
"ㄔㄤㄐㄧㄥ", // 場景
"ㄒㄧㄢㄑㄧ", // 嫌棄
"ㄌㄧㄥㄔㄣ", // 凌晨
"ㄘㄨㄣㄗㄞ", // 存在
"ㄊㄢㄌㄨㄣ", // 談論
"ㄓㄨㄥㄉㄚ", // 重大
"ㄐㄧㄚㄖㄨ", // 加入
"ㄉㄧㄢㄅㄛ", // 電波
"ㄍㄨㄟㄖㄣ", // 貴人
"ㄏㄨㄌㄨㄢ", // 胡亂
"ㄕㄨㄣㄕㄡ", // 順手
"ㄕㄥㄏㄨㄛ", // 生活
"ㄑㄧㄢㄧㄕ", // 潛意識
"ㄘㄨㄥㄓㄥ", // 從政
"ㄐㄧㄚㄗㄚ", // 夾雜
"ㄒㄧㄒㄧㄠ", // 細小
"ㄧㄢㄒㄧㄥ", // 言行
"ㄇㄨㄅㄧㄠ", // 目標
"ㄨㄣㄉㄧㄥ", // 穩定
"ㄐㄧㄉㄨㄛ", // 幾多
"ㄉㄨㄛㄅㄢ", // 多半
"ㄈㄨㄇㄧㄢ", // 負面
"ㄏㄨㄟㄍㄨ", // 回顧
"ㄖㄨㄑㄧㄣ", // 入侵
"ㄅㄞㄊㄨㄛ", // 拜託
"ㄉㄚㄏㄨㄚ", // 大話
"ㄒㄧㄚㄕㄡ", // 下手
"ㄒㄩㄝㄩㄣ", // 學運
"ㄧㄢㄎㄨㄤ", // 眼眶
"ㄋㄧㄡㄖㄡ", // 牛肉
"ㄉㄨㄛㄎㄡ", // 多口
"ㄉㄨㄢㄓㄢ", // 短暫
"ㄖㄨㄥㄒㄩ", // 容許
"ㄅㄠㄘㄨㄣ", // 保存
"ㄉㄧㄢㄕㄡ", // 點收
"ㄏㄨㄐㄧㄠ", // 胡椒
"ㄌㄧㄌㄨㄣ", // 理論
"ㄅㄨㄒㄧㄚ", // 不下
"ㄐㄧㄥㄔㄤ", // 經常
"ㄒㄧㄚㄨㄣ", // 下文
"ㄑㄩㄢㄆㄢ", // 全盤
"ㄑㄧㄥㄌㄩ", // 情侶
"ㄏㄨㄟㄕㄡ", // 揮手
"ㄗㄨㄑㄧㄡ", // 足球
"ㄌㄧㄏㄨㄣ", // 離婚
"ㄊㄢㄙㄨㄛ", // 探索
"ㄓㄨㄛㄕㄨ", // 著數
"ㄐㄧㄠㄊㄤ", // 教堂
"ㄍㄨㄤㄅㄛ", // 廣播
"ㄅㄨㄓㄨㄛ", // 捕捉
"ㄕㄢㄍㄨㄤ", // 閃光
"ㄉㄨㄟㄓㄤ", // 隊長
"ㄧㄠㄑㄧㄥ", // 邀請
"ㄔㄥㄒㄧㄠ", // 成效
"ㄓㄜㄒㄩㄝ", // 哲學
"ㄍㄠㄓㄨㄥ", // 高中
"ㄊㄨㄥㄅㄠ", // 同胞
"ㄘㄨㄥㄨㄟ", // 從未
"ㄐㄩㄉㄨㄛ", // 居多
"ㄐㄧㄤㄧㄥ", // 僵硬
"ㄈㄚㄍㄨㄢ", // 法官
"ㄋㄞㄒㄧㄥ", // 耐性
"ㄍㄨㄢㄅㄧ", // 關閉
"ㄈㄤㄒㄧㄚ", // 放下
"ㄒㄩㄝㄒㄧ", // 學習
"ㄔㄨㄥㄕㄣ", // 重申
"ㄏㄠㄒㄩㄝ", // 好學
"ㄐㄧㄑㄧㄥ", // 激情
"ㄊㄢㄒㄧㄥ", // 彈性
"ㄐㄧㄚㄙㄨ", // 加速
"ㄒㄧㄥㄈㄨ", // 幸福
"ㄐㄧㄥㄖㄣ", // 驚人
"ㄕㄡㄑㄧㄤ", // 手槍
"ㄐㄧㄝㄍㄡ", // 結構
"ㄉㄨㄥㄖㄣ", // 動人
"ㄍㄨㄥㄓㄥ", // 公正
"ㄐㄩㄝㄨㄤ", // 絕望
"ㄋㄧㄌㄧㄡ", // 逆流
"ㄔㄨㄢㄩㄝ", // 穿越
"ㄉㄚㄅㄧㄠ", // 達標
"ㄓㄨㄢㄌㄢ", // 專欄
"ㄍㄨㄛㄨㄤ", // 國王
"ㄉㄤㄑㄧㄢ", // 當前
"ㄇㄚㄗㄨㄟ", // 麻醉
"ㄙㄨㄛㄧㄡ", // 所有
"ㄐㄧㄥㄧㄚ", // 驚訝
"ㄕㄤㄒㄧㄣ", // 上心
"ㄈㄚㄐㄩㄝ", // 發掘
"ㄕㄥㄐㄧㄤ", // 升降
"ㄍㄨㄛㄊㄡ", // 過頭
"ㄏㄨㄢㄅㄠ", // 環保
"ㄈㄚㄇㄧㄥ", // 發明
"ㄧㄢㄐㄧㄤ", // 演講
"ㄒㄧㄠㄩㄢ", // 校園
"ㄐㄧㄑㄩㄢ", // 極權
"ㄆㄧㄥㄗㄜ", // 平仄
"ㄇㄛㄕㄨㄟ", // 墨水
"ㄍㄨㄥㄨㄣ", // 公文
"ㄍㄨㄥㄕㄣ", // 公審
"ㄒㄧㄠㄗㄨ", // 小組
"ㄔㄥㄐㄧㄠ", // 成交
"ㄓㄨㄤㄉㄚ", // 壯大
"ㄒㄩㄝㄌㄧ", // 學歷
"ㄐㄧㄤㄉㄠ", // 講道
"ㄧㄡㄒㄧㄢ", // 優先
"ㄉㄚㄒㄩㄢ", // 大選
"ㄆㄧㄥㄉㄢ", // 平淡
"ㄍㄠㄍㄨㄢ", // 高官
"ㄐㄧㄝㄇㄨ", // 節目
"ㄓㄢㄒㄧㄢ", // 展現
"ㄇㄧㄢㄈㄟ", // 免費
"ㄒㄧㄚㄔㄣ", // 下沉
"ㄗㄠㄗㄨㄛ", // 造作
"ㄒㄧㄣㄒㄧ", // 信息
"ㄐㄧㄝㄉㄠ", // 街道
"ㄐㄧㄥㄕㄣ", // 精神
"ㄇㄧㄝㄨㄤ", // 滅亡
"ㄐㄩㄍㄨㄥ", // 鞠躬
"ㄐㄧㄝㄔㄨ", // 接觸
"ㄆㄨㄅㄧㄢ", // 普遍
"ㄌㄨㄛㄌㄟ", // 落淚
"ㄈㄛㄐㄧㄠ", // 佛教
"ㄍㄥㄒㄧㄣ", // 更新
"ㄒㄧㄠㄈㄨ", // 校服
"ㄒㄧㄠㄇㄟ", // 小妹
"ㄕㄥㄇㄧㄥ", // 聲明
"ㄌㄧㄢㄕㄥ", // 連聲
"ㄏㄠㄒㄧㄠ", // 好笑
"ㄒㄧㄚㄔㄤ", // 下場
"ㄍㄨㄛㄨㄤ", // 過往
"ㄐㄩㄝㄅㄨ", // 絕不
"ㄍㄨㄥㄅㄨ", // 公布
"ㄅㄟㄇㄧㄢ", // 杯麵
"ㄇㄡㄒㄧㄝ", // 某些
"ㄌㄧㄥㄨㄞ", // 另外
"ㄐㄧㄝㄕㄡ", // 接收
"ㄕㄣㄐㄧㄥ", // 神經
"ㄧㄥㄍㄨㄤ", // 螢光
"ㄘㄜㄇㄧㄢ", // 側面
"ㄧㄡㄒㄧㄢ", // 有限
"ㄊㄧㄢㄖㄢ", // 天然
"ㄓㄨㄢㄩㄥ", // 專用
"ㄐㄧㄐㄧㄠ", // 計較
"ㄕㄜㄏㄨㄟ", // 社會
"ㄑㄧㄤㄉㄚ", // 搶答
"ㄐㄧㄠㄉㄨ", // 角度
"ㄊㄨㄥㄏㄠ", // 同好
"ㄧㄢㄏㄨㄚ", // 煙花
"ㄧㄢㄓㄨㄥ", // 嚴重
"ㄍㄨㄋㄧㄤ", // 姑娘
"ㄉㄢㄆㄧㄥ", // 單憑
"ㄔㄥㄋㄧㄢ", // 成年
"ㄐㄧㄢㄓㄨ", // 建築
"ㄓㄨㄣㄅㄟ", // 準備
"ㄋㄢㄍㄨㄛ", // 難過
"ㄍㄨㄐㄧㄣ", // 古今
"ㄈㄚㄉㄧㄢ", // 發電
"ㄏㄠㄐㄧㄥ", // 好景
"ㄐㄧㄥㄑㄧ", // 經期
"ㄏㄨㄟㄈㄨ", // 回復
"ㄅㄧㄥㄈㄟ", // 並非
"ㄈㄤㄔㄥㄕ", // 方程式
"ㄙㄨㄟㄩㄝ", // 歲月
"ㄐㄧㄝㄊㄡ", // 街頭
"ㄏㄨㄒㄧㄤ", // 互相
"ㄆㄧㄥㄩㄥ", // 平庸
"ㄔㄨㄥㄈㄣ", // 充分
"ㄍㄞㄅㄧㄢ", // 改變
"ㄧㄚㄍㄨㄢ", // 牙關
"ㄈㄤㄅㄧㄢ", // 方便
"ㄉㄨㄟㄈㄤ", // 對方
"ㄕㄥㄒㄧㄚ", // 剩下
"ㄌㄧㄌㄧㄤ", // 力量
"ㄉㄨㄟㄈㄨ", // 對付
"ㄐㄩㄝㄉㄜ", // 覺得
"ㄒㄧㄝㄈㄚ", // 寫法
"ㄐㄩㄒㄧㄥ", // 舉行
"ㄐㄧㄢㄌㄧ", // 建立
"ㄈㄥㄙㄨㄛ", // 封鎖
"ㄎㄜㄒㄩㄝ", // 科學
"ㄙㄢㄍㄨㄛ", // 三國
"ㄏㄨㄛㄔㄞ", // 火柴
"ㄐㄧㄥㄊㄢ", // 驚嘆
"ㄈㄤㄍㄨㄛ", // 放過
"ㄊㄨㄥㄙㄨ", // 通俗
"ㄆㄧㄥㄈㄢ", // 平反
"ㄍㄨㄢㄏㄨ", // 關乎
"ㄓㄥㄇㄧㄥ", // 正名
"ㄖㄨㄛㄍㄢ", // 若干
"ㄑㄧㄌㄧㄥ", // 欺凌
"ㄧㄢㄌㄨㄣ", // 言論
"ㄉㄨㄥㄊㄞ", // 動態
"ㄧㄕㄨㄔㄥ", // 一事無成
"ㄊㄞㄒㄩㄝ", // 大學
"ㄎㄨㄞㄙㄨ", // 快速
"ㄓㄨㄢㄓㄨ", // 專注
"ㄔㄨㄥㄐㄧ", // 衝擊
"ㄇㄨㄙㄨㄥ", // 目送
"ㄑㄧㄐㄧㄢ", // 期間
"ㄑㄩㄢㄕㄣ", // 全身
"ㄑㄧㄢㄓㄜ", // 前者
"ㄍㄨㄢㄊㄡ", // 罐頭
"ㄍㄨㄛㄍㄜ", // 國歌
"ㄏㄨㄥㄙㄜ", // 紅色
"ㄆㄠㄒㄧㄠ", // 咆哮
"ㄗㄨㄢㄧㄢ", // 鑽研
"ㄐㄧㄚㄔㄤ", // 家常
"ㄅㄤㄐㄧㄚ", // 綁架
"ㄆㄧㄥㄈㄣ", // 評分
"ㄏㄠㄍㄨㄛ", // 好過
"ㄒㄧㄣㄊㄞ", // 心態
"ㄋㄢㄒㄧㄥ", // 男性
"ㄈㄚㄒㄧㄥ", // 髮型
"ㄊㄡㄊㄨㄥ", // 頭痛
"ㄐㄧㄣㄓㄢ", // 進展
"ㄅㄨㄒㄧㄤ", // 不祥
"ㄨㄞㄐㄧㄝ", // 外界
"ㄊㄧㄢㄏㄡ", // 天后
"ㄘㄨㄥㄇㄤ", // 匆忙
"ㄐㄧㄤㄕㄨ", // 講述
"ㄒㄧㄠㄋㄩ", // 小女
"ㄔㄨㄤㄗㄠ", // 創造
"ㄐㄧㄌㄧㄝ", // 激烈
"ㄐㄧㄠㄊㄨ", // 教徒
"ㄌㄨㄣㄊㄢ", // 論壇
"ㄌㄧㄥㄧㄤ", // 領養
"ㄗㄞㄒㄧㄣ", // 在心
"ㄇㄞㄌㄨㄛ", // 脈絡
"ㄖㄨㄐㄧㄣ", // 如今
"ㄊㄠㄗㄨㄟ", // 陶醉
"ㄈㄥㄐㄧㄥ", // 風景
"ㄕㄡㄉㄨㄢ", // 手段
"ㄍㄨㄛㄉㄨ", // 過度
"ㄐㄧㄣㄉㄨ", // 進度
"ㄏㄨㄤㄖㄢ", // 恍然
"ㄊㄧㄠㄓㄥ", // 調整
"ㄓㄥㄊㄨㄥ", // 正統
"ㄉㄧㄇㄧㄢ", // 地面
"ㄌㄧㄡㄌㄧ", // 流利
"ㄕㄤㄕㄨㄟ", // 上水
"ㄏㄨㄟㄏㄜ", // 回合
"ㄐㄧㄠㄉㄞ", // 交代
"ㄍㄨㄥㄌㄧ", // 功力
"ㄐㄩㄑㄧㄥ", // 劇情
"ㄆㄧㄥㄈㄢ", // 平凡
"ㄔㄨㄉㄨㄥ", // 觸動
"ㄌㄧㄥㄉㄠ", // 領導
"ㄋㄧㄥㄇㄥ", // 檸檬
"ㄐㄧㄣㄙㄜ", // 金色
"ㄏㄨㄛㄔㄜ", // 貨車
"ㄨㄣㄒㄩㄝ", // 文學
"ㄐㄧㄢㄕㄜ", // 建設
"ㄍㄨㄛㄊㄨ", // 國土
"ㄍㄨㄥㄖㄣ", // 公認
"ㄏㄨㄢㄓㄜ", // 患者
"ㄍㄨㄥㄎㄜ", // 功課
"ㄌㄡㄌㄨㄛ", // 嘍囉
"ㄉㄧㄒㄧㄚ", // 地下
"ㄔㄨㄓㄨㄥ", // 初中
"ㄋㄟㄖㄨㄥ", // 內容
"ㄐㄧㄝㄆㄞ", // 節拍
"ㄓㄣㄉㄧㄥ", // 鎮定
"ㄇㄧㄣㄗㄨ", // 民族
"ㄅㄞㄇㄧㄥ", // 擺明
"ㄕㄨㄣㄌㄧ", // 順利
"ㄗㄠㄋㄧㄢ", // 早年
"ㄨㄟㄕㄐㄧ", // 威士忌
"ㄒㄧㄚㄓㄡ", // 下晝
"ㄇㄧㄢㄆㄧ", // 面皮
"ㄉㄨㄥㄩㄢ", // 動員
"ㄙㄨㄛㄗㄞ", // 所在
"ㄧㄢㄏㄨㄚ", // 演化
"ㄉㄚㄌㄧㄤ", // 大量
"ㄐㄧㄥㄧㄢ", // 經驗
"ㄏㄨㄟㄧㄥ", // 回應
"ㄈㄡㄉㄧㄥ", // 否定
"ㄏㄨㄣㄏㄜ", // 混合
"ㄕㄨㄌㄨㄛ", // 數落
"ㄊㄨㄥㄓㄣ", // 童真
"ㄍㄜㄓㄨㄥ", // 各種
"ㄑㄩㄢㄌㄧ", // 全力
"ㄊㄨㄟㄔㄨ", // 退出
"ㄏㄡㄊㄨㄟ", // 後退
"ㄉㄚㄗㄖㄢ", // 大自然
"ㄩㄣㄊㄨㄣ", // 雲吞
"ㄊㄧㄠㄨㄣ", // 條文
"ㄐㄧㄚㄖㄜ", // 加熱
"ㄨㄢㄨㄧㄕ", // 萬無一失
"ㄏㄜㄌㄧㄡ", // 合流
"ㄎㄨㄥㄆㄚ", // 恐怕
"ㄒㄩㄉㄨㄛ", // 許多
"ㄏㄡㄇㄧㄢ", // 後面
"ㄍㄨㄤㄙㄨ", // 光速
"ㄔㄜㄒㄧㄤ", // 車廂
"ㄍㄨㄛㄕㄣ", // 過身
"ㄨㄢㄏㄨㄟ", // 晚會
"ㄌㄩㄒㄧㄥ", // 旅行
"ㄈㄤㄙㄨㄥ", // 放鬆
"ㄧㄡㄏㄨㄛ", // 誘惑
"ㄏㄠㄊㄧㄢ", // 好天
"ㄕㄥㄒㄧㄤ", // 聲響
"ㄊㄨㄒㄧㄥ", // 土星
"ㄌㄧㄠㄌㄧ", // 料理
"ㄌㄧㄝㄔㄜ", // 列車
"ㄨㄢㄏㄨㄟ", // 挽回
"ㄉㄤㄐㄧㄣ", // 當今
"ㄅㄨㄉㄨㄟ", // 部隊
"ㄑㄩㄢㄌㄧ", // 權力
"ㄒㄩㄥㄉㄧ", // 兄弟
"ㄑㄧㄢㄖㄣ", // 前人
"ㄕㄤㄐㄧㄣ", // 上進
"ㄍㄨㄛㄖㄣ", // 過人
"ㄅㄨㄇㄧㄢ", // 不免
"ㄒㄧㄣㄉㄜ", // 心得
"ㄉㄞㄅㄧㄠ", // 代表
"ㄗㄨㄟㄖㄣ", // 罪人
"ㄋㄧㄡㄋㄞ", // 牛奶
"ㄍㄨㄤㄧㄣ", // 光陰
"ㄒㄧㄡㄨㄟ", // 臭味
"ㄖㄨㄒㄩㄝ", // 入學
"ㄧㄤㄍㄨㄤ", // 陽光
"ㄒㄧㄥㄍㄢ", // 性感
"ㄈㄚㄗㄨㄛ", // 發作
"ㄌㄞㄏㄨㄟ", // 來回
"ㄏㄨㄟㄈㄨ", // 恢復
"ㄆㄣㄑㄩㄢ", // 噴泉
"ㄌㄢㄊㄧㄢ", // 藍天
"ㄙㄨㄟㄕㄡ", // 隨手
"ㄐㄧㄡㄈㄢ", // 就範
"ㄊㄨㄥㄕㄥ", // 同聲
"ㄓㄣㄐㄧㄝ", // 貞節
"ㄉㄢㄒㄧㄣ", // 擔心
"ㄅㄠㄍㄨㄟ", // 寶貴
"ㄖㄨㄊㄨㄥ", // 如同
"ㄍㄡㄊㄨㄥ", // 溝通
"ㄕㄞㄒㄩㄢ", // 篩選
"ㄕㄡㄌㄧㄢ", // 收斂
"ㄊㄧㄢㄘㄞ", // 天才
"ㄉㄚㄊㄨㄟ", // 大腿
"ㄓㄨㄥㄨㄣ", // 中文
"ㄐㄧㄏㄨㄚ", // 計劃
"ㄓㄨㄌㄧㄡ", // 主流
"ㄌㄨㄛㄉㄢ", // 落單
"ㄨㄟㄉㄧㄥ", // 未定
"ㄉㄧㄒㄧㄚ", // 低下
"ㄐㄧㄥㄌㄧ", // 經歷
"ㄅㄨㄐㄩㄝ", // 不絕
"ㄈㄥㄎㄨㄤ", // 瘋狂
"ㄒㄧㄥㄨㄣ", // 行文
"ㄍㄨㄟㄗㄨ", // 貴族
"ㄍㄨㄟㄐㄩ", // 規矩
"ㄖㄣㄑㄩㄣ", // 人群
"ㄊㄡㄉㄧㄥ", // 頭頂
"ㄌㄨㄥㄊㄡ", // 龍頭
"ㄔㄤㄙㄨㄛ", // 場所
"ㄉㄧㄌㄨㄛ", // 低落
"ㄉㄨㄥㄌㄧ", // 動力
"ㄕㄨㄒㄧㄝ", // 書寫
"ㄏㄨㄟㄉㄚ", // 回答
"ㄉㄧㄢㄔㄜ", // 電車
"ㄐㄧㄠㄐㄧ", // 焦急
"ㄘㄥㄇㄧㄢ", // 層面
"ㄍㄨㄟㄋㄚ", // 歸納
"ㄎㄨㄛㄉㄚ", // 擴大
"ㄅㄟㄒㄧㄣ", // 背心
"ㄍㄨㄑㄧㄝ", // 姑且
"ㄅㄞㄋㄧㄢ", // 拜年
"ㄏㄜㄎㄨㄤ", // 何況
"ㄌㄧㄢㄖㄣ", // 連任
"ㄓㄨㄢㄕㄣ", // 轉身
"ㄑㄩㄒㄧㄢ", // 曲線
"ㄅㄨㄐㄧㄡ", // 不久
"ㄌㄟㄊㄨㄥ", // 類同
"ㄍㄨㄛㄐㄧ", // 國籍
"ㄌㄧㄢㄗㄞ", // 連載
"ㄏㄡㄏㄨㄟ", // 後悔
"ㄐㄧㄠㄕㄡ", // 教授
"ㄊㄨㄥㄌㄟ", // 同類
"ㄓㄨㄧㄌㄧ", // 注意力
"ㄇㄧㄒㄧㄣ", // 迷信
"ㄊㄞㄍㄨㄛ", // 太過
"ㄅㄧㄉㄧㄥ", // 必定
"ㄍㄞㄋㄧㄢ", // 概念
"ㄏㄨㄚㄕㄨ", // 滑鼠
"ㄆㄠㄒㄧㄠ", // 咆哮
"ㄔㄨㄥㄧㄤ", // 重陽
"ㄓㄥㄇㄧㄢ", // 正面
"ㄎㄨㄛㄓㄤ", // 擴張
"ㄘㄜㄌㄩㄝ", // 策略
"ㄕㄨㄣㄉㄞ", // 順帶
"ㄒㄧㄢㄓㄨ", // 顯著
"ㄉㄧㄒㄧㄢ", // 底線
"ㄑㄧㄢㄕㄜ", // 牽涉
"ㄈㄣㄍㄨㄥ", // 分工
"ㄌㄨㄣㄨㄣ", // 論文
"ㄍㄨㄟㄈㄢ", // 規範
"ㄧㄡㄎㄨㄥ", // 有空
"ㄕㄜㄉㄧㄥ", // 設定
"ㄍㄨㄤㄊㄡ", // 光頭
"ㄏㄨㄟㄑㄩ", // 回去
"ㄅㄧㄥㄖㄣ", // 病人
"ㄉㄧㄢㄩㄢ", // 店員
"ㄎㄜㄍㄨㄢ", // 客觀
"ㄧㄡㄍㄨㄢ", // 有關
"ㄌㄧㄢㄇㄥ", // 聯盟
"ㄏㄨㄣㄕㄚ", // 婚紗
"ㄒㄧㄢㄔㄨ", // 顯出
"ㄨㄧㄓㄨㄥ", // 無意中
"ㄒㄧㄣㄎㄡ", // 心口
"ㄖㄡㄖㄨㄢ", // 柔軟
"ㄒㄩㄣㄏㄠ", // 訊號
"ㄉㄨㄟㄕㄤ", // 對上
"ㄊㄤㄕㄨㄟ", // 糖水
"ㄔㄣㄗㄨㄟ", // 沉醉
"ㄕㄨㄐㄧㄚ", // 書架
"ㄈㄣㄅㄧㄝ", // 分別
"ㄧㄡㄒㄧㄥ", // 遊行
"ㄒㄧㄤㄧㄥ", // 相應
"ㄒㄧㄤㄕㄡ", // 享受
"ㄑㄩㄢㄅㄨ", // 全部
"ㄨㄢㄑㄩㄢ", // 完全
"ㄒㄧㄠㄍㄨ", // 小姑
"ㄒㄧㄣㄒㄩ", // 心虛
"ㄧㄚㄑㄧㄢ", // 牙籤
"ㄅㄧㄢㄩㄢ", // 邊緣
"ㄐㄧㄡㄓㄥ", // 就正
"ㄉㄧㄥㄨㄟ", // 定位
"ㄩㄢㄍㄨㄥ", // 員工
"ㄧㄒㄧㄚㄗ", // 一下子
"ㄌㄨㄛㄏㄡ", // 落後
"ㄒㄧㄤㄌㄞ", // 向來
"ㄒㄧㄡㄈㄚ", // 秀髮
"ㄉㄨㄥㄐㄧ", // 動機
"ㄨㄣㄏㄨㄚ", // 文化
"ㄨㄢㄐㄧㄚ", // 玩家
"ㄅㄢㄍㄨㄥ", // 辦公
"ㄉㄧㄐㄧㄢ", // 低賤
"ㄉㄚㄐㄧㄢ", // 打尖
"ㄏㄨㄚㄊㄧ", // 話題
"ㄅㄨㄊㄧㄥ", // 不停
"ㄌㄧㄤㄌㄠ", // 兩老
"ㄉㄚㄐㄩㄣ", // 大軍
"ㄍㄞㄍㄨㄚ", // 概括
"ㄍㄜㄇㄧㄥ", // 革命
"ㄍㄨㄥㄧㄝ", // 工業
"ㄈㄣㄉㄧㄢ", // 分店
"ㄒㄧㄣㄐㄧ", // 心急
"ㄌㄨㄥㄇㄣ", // 龍門
"ㄏㄨㄒㄧㄣ", // 互信
"ㄍㄨㄥㄌㄧ", // 功利
"ㄋㄩㄑㄩㄢ", // 女權
"ㄒㄧㄤㄅㄧ", // 相比
"ㄉㄚㄐㄧㄠ", // 打交
"ㄌㄧㄇㄧㄥ", // 黎明
"ㄑㄧㄣㄕㄡ", // 親手
"ㄌㄧㄝㄧㄣ", // 列印
"ㄍㄤㄊㄧㄝ", // 鋼鐵
"ㄓㄣㄖㄨㄥ", // 陣容
"ㄩㄥㄕㄨㄟ", // 用水
"ㄕㄨㄙㄨㄛ", // 事務所
"ㄘㄞㄊㄨㄢ", // 財團
"ㄓㄨㄓㄨㄥ", // 注重
"ㄆㄧㄠㄈㄤ", // 票房
"ㄌㄟㄕㄨㄟ", // 淚水
"ㄔㄨㄥㄊㄨ", // 衝突
"ㄍㄨㄛㄩㄝ", // 國樂
"ㄉㄨㄟㄕㄡ", // 對手
"ㄙㄨㄛㄋㄚ", // 嗩吶
"ㄧㄅㄨㄅㄨ", // 一步步
"ㄅㄢㄑㄧㄠ", // 板橋
"ㄕㄓㄥㄈㄨ", // 市政府
"ㄊㄨㄕㄨㄕ", // 圖書室
"ㄏㄨㄛㄆㄛ", // 活潑
"ㄒㄩㄝㄕㄥ", // 學生
"ㄎㄠㄐㄧㄣ", // 靠近
"ㄏㄨㄢㄖㄠ", // 環繞
"ㄩㄢㄌㄧㄠ", // 原料
"ㄖㄨㄛㄈㄟ", // 若非
"ㄒㄩㄝㄓㄤ", // 學長
"ㄒㄧㄋㄧㄡ", // 犀牛
"ㄊㄜㄅㄧㄝ", // 特別
"ㄆㄞㄑㄧㄡ", // 排球
"ㄆㄧㄆㄧㄥ", // 批評
"ㄅㄠㄌㄧㄡ", // 保留
"ㄍㄠㄎㄨㄥ", // 高空
"ㄓㄢㄌㄩㄝ", // 戰略
"ㄊㄧㄠㄓㄢ", // 挑戰
"ㄇㄥㄐㄩㄣ", // 盟軍
"ㄧㄠㄆㄧㄣ", // 藥品
"ㄒㄩㄥㄉㄢ", // 熊膽
"ㄨㄟㄏㄨㄣ", // 未婚
"ㄙㄜㄑㄧㄥ", // 色情
"ㄏㄟㄉㄨㄥ", // 黑洞
"ㄊㄧㄝㄌㄨ", // 鐵路
"ㄓㄜㄒㄧㄝ", // 這些
"ㄉㄧㄆㄧㄢ", // 底片
"ㄏㄞㄒㄧㄡ", // 害羞
"ㄍㄨㄢㄎㄚ", // 關卡
"ㄊㄧㄏㄨㄟ", // 體會
"ㄩㄣㄓㄨㄢ", // 運轉
"ㄘㄠㄘㄨㄥ", // 草叢
"ㄉㄢㄅㄞㄓ", // 蛋白質
"ㄎㄠㄌㄧㄤ", // 考量
"ㄑㄩㄋㄧㄢ", // 去年
"ㄓㄨㄟㄓㄨ", // 追逐
"ㄔㄨㄤㄌㄧ", // 創立
"ㄩㄢㄕㄨㄞ", // 元帥
"ㄑㄧㄚㄏㄠ", // 恰好
"ㄘㄜㄌㄧㄤ", // 測量
"ㄉㄤㄕㄖㄣ", // 當事人
"ㄋㄧㄥㄎㄜ", // 寧可
"ㄉㄨㄟㄧㄥ", // 對應
"ㄓㄨㄉㄨㄛ", // 諸多
"ㄇㄧㄥㄅㄞ", // 明白
"ㄋㄩㄏㄞㄗ", // 女孩子
"ㄒㄧㄤㄔㄨ", // 相處
"ㄆㄛㄑㄧㄝ", // 迫切
"ㄈㄨㄘㄨㄥ", // 服從
"ㄕㄡㄆㄧㄢ", // 受騙
"ㄔㄨㄢㄓㄣ", // 傳真
"ㄘㄨㄣㄈㄤ", // 存放
"ㄑㄧㄥㄌㄞ", // 青睞
"ㄌㄧㄤㄆㄤ", // 兩旁
"ㄅㄧㄢㄍㄥ", // 變更
"ㄏㄨㄚㄧㄤ", // 花樣
"ㄎㄨㄥㄑㄧ", // 空氣
"ㄏㄨㄉㄧㄝ", // 蝴蝶
"ㄨㄣㄋㄨㄢ", // 溫暖
"ㄇㄧㄢㄧㄢ", // 綿延
"ㄏㄡㄧㄓㄥ", // 後遺症
"ㄌㄟㄒㄧㄥ", // 類型
"ㄐㄧㄢㄉㄢ", // 簡單
"ㄋㄟㄒㄧㄣ", // 內心
"ㄑㄧㄢㄨㄟ", // 前衛
"ㄕㄤㄇㄧㄢ", // 上面
"ㄙㄨㄛㄓㄤ", // 所長
"ㄙㄨㄕㄨㄛ", // 訴說
"ㄕㄤㄉㄧㄢ", // 商店
"ㄆㄞㄇㄧㄥ", // 排名
"ㄉㄞㄎㄨㄢ", // 貸款
"ㄑㄧㄥㄨㄚ", // 青蛙
"ㄆㄢㄉㄨㄢ", // 判斷
"ㄎㄨㄥㄍㄠ", // 控告
"ㄎㄨㄞㄇㄣ", // 快門
"ㄍㄣㄐㄧㄣ", // 跟進
"ㄗㄥㄊㄧㄢ", // 增添
"ㄑㄧㄤㄆㄛ", // 強迫
"ㄔㄨㄤㄏㄨ", // 窗戶
"ㄇㄟㄇㄧㄠ", // 美妙
"ㄓㄢㄊㄧㄥ", // 暫停
"ㄕㄨㄛㄌㄞ", // 說來
"ㄕㄢㄕㄨㄟ", // 山水
"ㄅㄧㄥㄍㄡ", // 併購
"ㄘㄜㄙㄨㄛ", // 廁所
"ㄉㄢㄉㄧㄠ", // 單調
"ㄙㄨㄟㄏㄡ", // 隨後
"ㄒㄧㄣㄏㄠ", // 信號
"ㄏㄡㄌㄨㄥ", // 喉嚨
"ㄋㄥㄌㄧㄤ", // 能量
"ㄧㄡㄔㄨㄥ", // 幼蟲
"ㄉㄤㄅㄧㄥ", // 當兵
"ㄍㄨㄤㄇㄤ", // 光芒
"ㄏㄨㄛㄗㄞ", // 火災
"ㄘㄨㄒㄧㄠ", // 促銷
"ㄔㄨㄛㄏㄠ", // 綽號
"ㄍㄞㄌㄧㄤ", // 改良
"ㄒㄩㄌㄧㄝ", // 序列
"ㄓㄥㄉㄨㄣ", // 整頓
"ㄍㄨㄙㄨㄟ", // 骨髓
"ㄌㄧㄤㄔㄢ", // 量產
"ㄌㄧㄝㄖㄨ", // 列入
"ㄌㄧㄡㄅㄤ", // 劉邦
"ㄅㄞㄊㄧㄢ", // 白天
"ㄅㄧㄝㄖㄣ", // 別人
"ㄓㄨㄋㄧㄢ", // 逐年
"ㄏㄤㄑㄩㄢ", // 航權
"ㄕㄨㄟㄓㄠ", // 睡著
"ㄔㄜㄏㄨㄛ", // 車禍
"ㄎㄞㄎㄨㄛ", // 開闊
"ㄋㄧㄥㄐㄩ", // 凝聚
"ㄏㄨㄉㄨㄥ", // 互動
"ㄗㄥㄉㄨㄛ", // 增多
"ㄇㄧㄠㄕㄨ", // 描述
"ㄅㄛㄖㄨㄛ", // 薄弱
"ㄇㄧㄣㄧㄥ", // 民營
"ㄒㄧㄝㄓㄨ", // 協助
"ㄖㄜㄌㄨㄛ", // 熱絡
"ㄊㄠㄌㄨㄣ", // 討論
"ㄍㄞㄐㄧㄢ", // 改建
"ㄊㄨㄟㄘㄜ", // 推測
"ㄗㄨㄥㄘㄞ", // 總裁
"ㄗㄨㄛㄓㄜ", // 作者
"ㄋㄚㄊㄧㄢ", // 那天
"ㄅㄢㄙㄨㄟ", // 伴隨
"ㄩㄢㄌㄧㄤ", // 原諒
"ㄅㄧㄉㄨㄟ", // 比對
"ㄆㄧㄥㄏㄥ", // 平衡
"ㄗㄢㄊㄨㄥ", // 贊同
"ㄈㄥㄍㄨㄤ", // 風光
"ㄓㄨㄚㄓㄨ", // 抓住
"ㄑㄧㄢㄋㄥ", // 潛能
"ㄗㄨㄟㄅㄚ", // 嘴巴
"ㄉㄧㄢㄈㄢ", // 典範
"ㄔㄥㄗㄨㄛ", // 乘坐
"ㄓㄨㄢㄌㄧ", // 專利
"ㄎㄞㄒㄩㄝ", // 開學
"ㄔㄨㄥㄉㄤ", // 充當
"ㄉㄥㄍㄨㄤ", // 燈光
"ㄍㄨㄥㄓㄞ", // 公債
"ㄙㄨㄢㄌㄜ", // 算了
"ㄔㄜㄌㄧㄤ", // 車輛
"ㄓㄨㄣㄗㄜ", // 準則
"ㄇㄧㄢㄐㄧ", // 面積
"ㄅㄠㄎㄨㄛ", // 包括
"ㄌㄧㄏㄨㄢ", // 罹患
"ㄑㄧㄥㄔㄜ", // 清澈
"ㄌㄟㄅㄧㄝ", // 類別
"ㄑㄧㄤㄉㄠ", // 強盜
"ㄏㄨㄌㄩㄝ", // 忽略
"ㄍㄨㄢㄇㄛ", // 觀摩
"ㄏㄞㄐㄩㄣ", // 海軍
"ㄩㄢㄉㄨㄥ", // 遠東
"ㄊㄞㄋㄢㄕ", // 臺南市
"ㄌㄡㄉㄨㄥ", // 漏洞
"ㄔㄨㄥㄍㄠ", // 崇高
"ㄍㄜㄗㄒㄧ", // 歌仔戲
"ㄒㄧㄊㄨㄥ", // 系統
"ㄊㄥㄊㄨㄥ", // 疼痛
"ㄐㄩㄢㄖㄨ", // 捲入
"ㄐㄧㄚㄍㄡ", // 架構
"ㄇㄛㄊㄜㄦ", // 模特兒
"ㄩㄥㄆㄧㄣ", // 用品
"ㄌㄧㄥㄍㄢ", // 靈感
"ㄉㄤㄇㄧㄢ", // 當面
"ㄏㄢㄍㄨㄛ", // 韓國
"ㄊㄨㄟㄓㄢ", // 推展
"ㄏㄠㄆㄧㄥ", // 好評
"ㄖㄨㄐㄧㄚ", // 儒家
"ㄅㄢㄕㄔㄨ", // 辦事處
"ㄍㄨㄢㄔㄚ", // 觀察
"ㄉㄨㄛㄊㄡ", // 多頭
"ㄒㄧㄣㄊㄥ", // 心疼
"ㄏㄤㄑㄧㄥ", // 行情
"ㄐㄧㄠㄨㄤ", // 交往
"ㄋㄞㄒㄧㄣ", // 耐心
"ㄗㄥㄑㄧㄤ", // 增強
"ㄆㄛㄏㄨㄞ", // 破壞
"ㄏㄨㄛㄒㄩ", // 或許
"ㄅㄧㄠㄧㄢ", // 表演
"ㄕㄡㄑㄩㄢ", // 授權
"ㄖㄜㄌㄧㄝ", // 熱烈
"ㄩㄝㄉㄨㄟ", // 樂隊
"ㄍㄨㄛㄉㄚ", // 國大
"ㄒㄧㄠㄔㄨ", // 消除
"ㄌㄨㄉㄨㄢ", // 路段
"ㄕㄜㄐㄧㄕ", // 設計師
"ㄍㄨㄛㄨㄞ", // 國外
"ㄐㄧㄝㄘㄥ", // 階層
"ㄒㄩㄥㄅㄨ", // 胸部
"ㄈㄨㄨㄧㄝ", // 服務業
"ㄉㄜㄗㄨㄟ", // 得罪
"ㄙㄨㄟㄉㄧ", // 隨地
"ㄏㄜㄉㄧㄥ", // 核定
"ㄇㄨㄎㄨㄢ", // 募款
"ㄧㄥㄧㄝㄜ", // 營業額
"ㄉㄨㄛㄇㄜ", // 多麼
"ㄘㄥㄐㄧㄥ", // 曾經
"ㄊㄡㄍㄨㄛ", // 透過
"ㄅㄧㄢㄍㄜ", // 變革
"ㄊㄨㄟㄈㄢ", // 推翻
"ㄑㄧㄚㄉㄤ", // 恰當
"ㄇㄧㄑㄧㄝ", // 密切
"ㄓㄤㄊㄧㄝ", // 張貼
"ㄑㄧㄢㄌㄞ", // 前來
"ㄏㄞㄍㄨㄢ", // 海關
"ㄋㄧㄢㄔㄨ", // 年初
"ㄐㄧㄤㄋㄢ", // 江南
"ㄉㄨㄟㄅㄧ", // 對比
"ㄏㄨㄣㄌㄧ", // 婚禮
"ㄆㄟㄊㄨㄥ", // 陪同
"ㄑㄧㄢㄊㄧ", // 前提
"ㄎㄨㄛㄗㄥ", // 擴增
"ㄅㄧㄝㄕㄨ", // 別墅
"ㄓㄨㄢㄊㄧ", // 專題
"ㄓㄣㄉㄨㄟ", // 針對
"ㄖㄣㄐㄧㄠ", // 任教
"ㄅㄟㄉㄨㄥ", // 被動
"ㄏㄨㄛㄒㄧ", // 獲悉
"ㄋㄨㄥㄉㄧ", // 農地
"ㄓㄨㄔㄖㄣ", // 主持人
"ㄨㄞㄇㄧㄢ", // 外面
"ㄌㄧㄢㄙㄜ", // 臉色
"ㄎㄚㄆㄧㄢ", // 卡片
"ㄊㄧㄢㄊㄤ", // 天堂
"ㄇㄥㄏㄨㄢ", // 夢幻
"ㄍㄨㄆㄧㄠ", // 股票
"ㄉㄨㄐㄧㄥ", // 讀經
"ㄧㄡㄩㄥㄔ", // 游泳池
"ㄉㄧㄥㄍㄡ", // 訂購
"ㄎㄨㄚㄩㄝ", // 跨越
"ㄏㄜㄗㄨㄛ", // 合作
"ㄕㄦㄓㄔㄤ", // 十二指腸
"ㄘㄞㄒㄧㄥ", // 採行
"ㄗㄥㄙㄨㄥ", // 贈送
"ㄧㄝㄐㄧㄝ", // 業界
"ㄍㄡㄒㄧㄤ", // 構想
"ㄓㄨㄥㄊㄡ", // 鐘頭
"ㄧㄡㄆㄧㄠ", // 郵票
"ㄐㄧㄢㄅㄤ", // 肩膀
"ㄨㄟㄒㄧㄡ", // 維修
"ㄋㄨㄥㄧㄠ", // 農藥
"ㄍㄨㄥㄖㄢ", // 公然
"ㄏㄞㄕㄨㄟ", // 海水
"ㄍㄨㄛㄌㄞ", // 過來
"ㄉㄤㄒㄧㄚ", // 當下
"ㄊㄞㄎㄨㄥ", // 太空
"ㄎㄨㄞㄌㄜ", // 快樂
"ㄙㄨㄛㄕㄨ", // 所屬
"ㄗㄨㄛㄨㄣ", // 作文
"ㄎㄨㄛㄓㄢ", // 擴展
"ㄖㄜㄒㄧㄣ", // 熱心
"ㄔㄠㄊㄧㄥ", // 朝廷
"ㄖㄥㄐㄧㄡ", // 仍舊
"ㄇㄨㄍㄨㄤ", // 目光
"ㄒㄧㄤㄊㄨ", // 鄉土
"ㄒㄩㄢㄅㄨ", // 宣布
"ㄓㄤㄊㄧㄥ", // 漲停
"ㄍㄨㄥㄒㄩ", // 供需
"ㄏㄨㄚㄓㄢ", // 畫展
"ㄕㄨㄤㄉㄚ", // 雙打
"ㄉㄧㄢㄧㄥ", // 電影
"ㄗㄨㄛㄧㄡ", // 左右
"ㄒㄩㄥㄇㄠ", // 熊貓
"ㄎㄨㄞㄧㄠ", // 快要
"ㄅㄛㄨㄩㄢ", // 博物院
"ㄇㄧㄣㄧㄠ", // 民謠
"ㄕㄢㄌㄧㄣ", // 山林
"ㄏㄨㄞㄅㄠ", // 懷抱
"ㄓㄨㄟㄍㄢ", // 追趕
"ㄍㄨㄤㄈㄨ", // 光復
"ㄐㄧㄓㄨㄣ", // 基準
"ㄍㄨㄛㄖㄢ", // 果然
"ㄙㄡㄒㄩㄣ", // 搜尋
"ㄋㄢㄏㄞㄗ", // 男孩子
"ㄇㄧㄣㄓㄨ", // 民主
"ㄌㄨㄐㄩㄣ", // 陸軍
"ㄏㄨㄥㄅㄠ", // 紅包
"ㄇㄧㄢㄇㄨ", // 面目
"ㄖㄣㄒㄩㄢ", // 人選
"ㄉㄢㄐㄧㄤ", // 淡江
"ㄅㄧㄠㄧㄤ", // 表揚
"ㄑㄩㄉㄨㄥ", // 驅動
"ㄓㄗㄠㄧㄝ", // 製造業
"ㄐㄧㄝㄕㄠ", // 介紹
"ㄊㄨㄛㄓㄢ", // 拓展
"ㄕㄥㄅㄧㄥ", // 生病
"ㄊㄨㄥㄉㄥ", // 同等
"ㄑㄧㄣㄏㄞ", // 侵害
"ㄉㄨㄥㄑㄩ", // 東區
"ㄆㄧㄢㄩㄢ", // 偏遠
"ㄘㄠㄆㄧㄥ", // 草坪
"ㄊㄧㄥㄔㄜ", // 停車
"ㄏㄨㄤㄙㄜ", // 黃色
"ㄅㄢㄎㄨㄞ", // 板塊
"ㄐㄧㄢㄕㄠ", // 減少
"ㄏㄨㄤㄧㄢ", // 謊言
"ㄩㄥㄐㄧㄡ", // 永久
"ㄨㄟㄍㄨㄟ", // 違規
"ㄎㄜㄍㄨㄟ", // 可貴
"ㄗㄨㄒㄧㄢ", // 祖先
"ㄇㄢㄒㄧㄥ", // 慢性
"ㄈㄢㄉㄨㄛ", // 繁多
"ㄓㄨㄥㄗㄨ", // 種族
"ㄕㄢㄉㄧㄥ", // 山頂
"ㄓㄨㄟㄔㄚ", // 追查
"ㄉㄧㄝㄆㄛ", // 跌破
"ㄏㄨㄟㄌㄩ", // 匯率
"ㄔㄨㄢㄩㄢ", // 船員
"ㄖㄨㄥㄋㄚ", // 容納
"ㄌㄨㄒㄧㄢ", // 路線
"ㄐㄧㄠㄍㄟ", // 交給
"ㄋㄧㄡㄑㄩ", // 扭曲
"ㄆㄧㄓㄨㄣ", // 批准
"ㄉㄨㄟㄘㄜ", // 對策
"ㄕㄡㄒㄩㄣ", // 受訓
"ㄏㄤㄒㄧㄢ", // 航線
"ㄐㄧㄚㄔㄜ", // 駕車
"ㄉㄨㄛㄕㄨ", // 多數
"ㄊㄧㄓㄨㄥ", // 體重
"ㄋㄠㄐㄧㄣ", // 腦筋
"ㄍㄨㄛㄩㄢ", // 果園
"ㄕㄨㄟㄋㄧ", // 水泥
"ㄇㄧㄣㄙㄨ", // 民俗
"ㄑㄧㄍㄨㄞ", // 奇怪
"ㄉㄨㄥㄅㄨ", // 東部
"ㄧㄡㄑㄧㄢ", // 有錢
"ㄊㄨㄆㄧㄢ", // 圖片
"ㄏㄞㄊㄨㄥ", // 孩童
"ㄔㄨㄘㄨㄣ", // 儲存
"ㄔㄨㄥㄇㄢ", // 充滿
"ㄔㄢㄌㄧㄤ", // 產量
"ㄕㄨㄟㄍㄡ", // 水溝
"ㄗㄞㄗㄨㄛ", // 在座
"ㄒㄩㄢㄌㄩ", // 旋律
"ㄍㄤㄐㄧㄣ", // 鋼筋
"ㄑㄧㄥㄓㄠ", // 清朝
"ㄒㄩㄑㄧㄡ", // 需求
"ㄔㄨㄢㄖㄢ", // 傳染
"ㄩㄝㄑㄧㄡ", // 月球
"ㄇㄧㄢㄉㄜ", // 免得
"ㄐㄩㄉㄨㄥ", // 舉動
"ㄙㄨㄣㄏㄞ", // 損害
"ㄍㄞㄐㄧㄣ", // 改進
"ㄅㄤㄑㄧㄡ", // 棒球
"ㄩㄣㄙㄨㄢ", // 運算
"ㄌㄧㄢㄎㄠ", // 聯考
"ㄒㄩㄣㄓㄠ", // 尋找
"ㄧㄣㄑㄧㄝ", // 殷切
"ㄎㄨㄚㄎㄜ", // 夸克
"ㄊㄧㄢㄓㄣ", // 天真
"ㄎㄨㄟㄧㄤ", // 潰瘍
"ㄍㄤㄑㄧㄣ", // 鋼琴
"ㄏㄡㄋㄧㄠ", // 候鳥
"ㄔㄥㄑㄩㄣ", // 成群
"ㄏㄨㄟㄧㄕ", // 會議室
"ㄔㄚㄏㄨㄛ", // 查獲
"ㄒㄧㄕㄨㄞ", // 蟋蟀
"ㄓㄥㄊㄧㄢ", // 整天
"ㄇㄧㄢㄇㄠ", // 面貌
"ㄉㄧㄌㄧㄢ", // 低廉
"ㄓㄓㄥㄉㄤ", // 執政黨
"ㄏㄞㄊㄨㄣ", // 海豚
"ㄏㄜㄆㄧㄥ", // 和平
"ㄕㄨㄒㄧㄚ", // 屬下
"ㄌㄧㄢㄇㄤ", // 連忙
"ㄓㄨㄥㄅㄨ", // 中部
"ㄆㄧㄥㄩㄢ", // 平原
"ㄏㄤㄌㄧㄝ", // 行列
"ㄙㄨㄛㄉㄜ", // 所得
"ㄐㄩㄢㄗㄥ", // 捐贈
"ㄕㄨㄤㄈㄤ", // 雙方
"ㄩㄝㄍㄨㄤ", // 月光
"ㄋㄨㄥㄧㄝ", // 農業
"ㄅㄢㄓㄨㄛ", // 辦桌
"ㄑㄧㄣㄧㄡ", // 親友
"ㄓㄨㄢㄔㄤ", // 專長
"ㄆㄛㄌㄧㄝ", // 破裂
"ㄐㄧㄢㄆㄢ", // 鍵盤
"ㄕㄤㄎㄨㄥ", // 上空
"ㄐㄩㄇㄧㄣ", // 居民
"ㄏㄨㄚㄖㄣ", // 華人
"ㄑㄧㄣㄖㄨ", // 侵入
"ㄗㄥㄐㄧㄣ", // 增進
"ㄌㄧㄉㄨㄛ", // 利多
"ㄨㄞㄍㄨㄛ", // 外國
"ㄩㄥㄒㄧㄣ", // 用心
"ㄒㄩㄝㄓㄜ", // 學者
"ㄔㄤㄐㄧㄤ", // 長江
"ㄧㄔㄚㄋㄚ", // 一剎那
"ㄧㄥㄆㄧㄢ", // 影片
"ㄌㄠㄋㄧㄢ", // 老年
"ㄐㄧㄚㄩㄥ", // 家用
"ㄆㄧㄢㄔㄚ", // 偏差
"ㄊㄜㄑㄩㄢ", // 特權
"ㄨㄟㄑㄩㄢ", // 威權
"ㄐㄧㄥㄍㄠ", // 警告
"ㄈㄢㄗㄨㄟ", // 犯罪
"ㄑㄩㄥㄖㄣ", // 窮人
"ㄋㄧㄠㄧㄝ", // 尿液
"ㄍㄢㄐㄩㄝ", // 感覺
"ㄑㄩㄢㄓㄡ", // 泉州
"ㄎㄢㄅㄧㄥ", // 看病
"ㄌㄧㄖㄨㄣ", // 利潤
"ㄩㄝㄉㄧㄥ", // 約定
"ㄐㄧㄧㄊㄧ", // 記憶體
"ㄌㄧㄢㄓㄠ", // 聯招
"ㄐㄩㄊㄨㄢ", // 劇團
"ㄇㄣㄆㄧㄠ", // 門票
"ㄌㄜㄙㄨㄛ", // 勒索
"ㄍㄜㄐㄧㄢ", // 隔間
"ㄒㄩㄝㄎㄜ", // 學科
"ㄕㄤㄒㄧㄚ", // 上下
"ㄔㄢㄆㄧㄣ", // 產品
"ㄍㄢㄉㄨㄥ", // 感動
"ㄧㄉㄚㄗㄠ", // 一大早
"ㄖㄣㄇㄧㄣ", // 人民
"ㄋㄨㄥㄉㄨ", // 濃度
"ㄍㄜㄊㄧㄢ", // 隔天
"ㄐㄩㄣㄏㄥ", // 均衡
"ㄔㄨㄍㄨㄛ", // 出國
"ㄑㄧㄣㄇㄧ", // 親密
"ㄎㄨㄘㄨㄣ", // 庫存
"ㄐㄧㄤㄌㄞ", // 將來
"ㄒㄩㄝㄨㄣ", // 學問
"ㄍㄢㄎㄨㄞ", // 趕快
"ㄎㄡㄕㄨㄟ", // 口水
"ㄗㄡㄉㄨㄥ", // 走動
"ㄓㄣㄉㄨㄢ", // 診斷
"ㄌㄧㄢㄙㄞ", // 聯賽
"ㄙㄨㄟㄉㄠ", // 隧道
"ㄏㄨㄥㄔㄚ", // 紅茶
"ㄆㄧㄐㄧㄡ", // 啤酒
"ㄗㄨㄣㄕㄡ", // 遵守
"ㄞㄗㄅㄧㄥ", // 愛滋病
"ㄉㄚㄌㄧㄕ", // 大理石
"ㄉㄨㄛㄈㄤ", // 多方
"ㄔㄨㄒㄧㄝ", // 出血
"ㄧㄝㄋㄧㄠ", // 野鳥
"ㄌㄧㄥㄊㄨ", // 領土
"ㄍㄨㄤㄈㄢ", // 廣泛
"ㄐㄧㄡㄔㄢ", // 糾纏
"ㄒㄩㄝㄈㄣ", // 學分
"ㄌㄞㄅㄧㄣ", // 來賓
"ㄧㄣㄌㄧㄠ", // 飲料
"ㄕㄨㄤㄕㄡ", // 雙手
"ㄒㄧㄣㄌㄤ", // 新郎
"ㄏㄤㄎㄨㄥ", // 航空
"ㄔㄣㄌㄧㄝ", // 陳列
"ㄏㄡㄊㄧㄢ", // 後天
"ㄎㄞㄒㄧㄣ", // 開心
"ㄊㄡㄗㄖㄣ", // 投資人
"ㄍㄥㄏㄨㄢ", // 更換
"ㄑㄧㄆㄧㄢ", // 欺騙
"ㄊㄞㄅㄟㄕ", // 臺北市
"ㄕㄡㄐㄧㄚ", // 售價
"ㄅㄥㄎㄨㄟ", // 崩潰
"ㄌㄧㄕㄓㄤ", // 理事長
"ㄋㄧㄠㄌㄟ", // 鳥類
"ㄅㄣㄌㄧㄥ", // 本領
"ㄆㄤㄒㄧㄝ", // 螃蟹
"ㄒㄧㄣㄨㄟ", // 欣慰
"ㄍㄢㄒㄧㄤ", // 感想
"ㄉㄨㄥㄓㄨ", // 動植物
"ㄧㄣㄐㄧㄣ", // 引進
"ㄐㄧㄚㄕㄣ", // 加深
"ㄏㄨㄤㄏㄡ", // 皇后
"ㄔㄠㄗㄨㄛ", // 炒作
"ㄑㄧㄣㄌㄠ", // 勤勞
"ㄋㄩㄝㄉㄞ", // 虐待
"ㄘㄢㄎㄨㄟ", // 慚愧
"ㄗㄨㄍㄨㄛ", // 祖國
"ㄑㄧㄤㄧㄥ", // 強硬
"ㄕㄤㄊㄧㄢ", // 上天
"ㄏㄨㄢㄩㄢ" // 還原
      ]
