export const VALID_GUESSES : any = {
"ㄅㄚㄅㄟㄗ":"八輩子",
"ㄅㄚㄅㄧㄠ":"八表",
"ㄅㄚㄅㄧㄥ":"把柄/八病",
"ㄅㄚㄆㄨㄗ":"拔脯子",
"ㄅㄚㄆㄨㄦ":"拔脯兒",
"ㄅㄚㄇㄧㄢ":"罷免",
"ㄅㄚㄈㄣㄧ":"八分儀",
"ㄅㄚㄉㄜㄕ":"八德市",
"ㄅㄚㄉㄞㄩ":"八帶魚",
"ㄅㄚㄉㄡㄗ":"八斗子",
"ㄅㄚㄉㄧㄠ":"拔掉",
"ㄅㄚㄉㄧㄥ":"把定/拔碇",
"ㄅㄚㄉㄨㄛ":"把舵",
"ㄅㄚㄉㄨㄢ":"把斷",
"ㄅㄚㄉㄨㄥ":"巴東/八洞",
"ㄅㄚㄉㄨㄦ":"八都兒",
"ㄅㄚㄊㄡㄦ":"扒頭兒",
"ㄅㄚㄊㄨㄟ":"罷退/拔腿",
"ㄅㄚㄊㄨㄦ":"把突兒",
"ㄅㄚㄋㄨㄥ":"把弄",
"ㄅㄚㄌㄟㄨ":"芭蕾舞",
"ㄅㄚㄌㄧㄗ":"笆籬子",
"ㄅㄚㄌㄧㄢ":"把斂",
"ㄅㄚㄌㄧㄥ":"巴陵",
"ㄅㄚㄌㄧㄩ":"巴利語",
"ㄅㄚㄌㄨㄥ":"八龍",
"ㄅㄚㄌㄩㄝ":"霸略",
"ㄅㄚㄍㄠㄗ":"巴羔子",
"ㄅㄚㄍㄨㄚ":"八卦",
"ㄅㄚㄍㄨㄛ":"巴國",
"ㄅㄚㄍㄨㄢ":"把關/拔關",
"ㄅㄚㄍㄨㄣ":"巴棍",
"ㄅㄚㄍㄨㄥ":"罷工/八公",
"ㄅㄚㄎㄨㄟ":"拔葵",
"ㄅㄚㄏㄨㄚ":"把話/把滑",
"ㄅㄚㄏㄨㄤ":"八荒",
"ㄅㄚㄏㄨㄥ":"八紘",
"ㄅㄚㄐㄧㄚ":"把家",
"ㄅㄚㄐㄧㄝ":"巴結/巴解",
"ㄅㄚㄐㄧㄠ":"八角/芭蕉",
"ㄅㄚㄐㄧㄡ":"八九/把酒",
"ㄅㄚㄐㄧㄢ":"拔尖",
"ㄅㄚㄐㄧㄣ":"巴金",
"ㄅㄚㄐㄧㄤ":"巴江",
"ㄅㄚㄐㄩㄢ":"把卷",
"ㄅㄚㄐㄩㄣ":"八俊/八駿",
"ㄅㄚㄑㄧㄠ":"灞橋",
"ㄅㄚㄑㄧㄡ":"巴丘",
"ㄅㄚㄑㄧㄢ":"把錢",
"ㄅㄚㄑㄧㄣ":"拔親",
"ㄅㄚㄑㄩㄢ":"霸權/把權",
"ㄅㄚㄑㄩㄣ":"拔群",
"ㄅㄚㄒㄧㄚ":"把下/八遐",
"ㄅㄚㄒㄧㄝ":"拔薤",
"ㄅㄚㄒㄧㄡ":"罷休",
"ㄅㄚㄒㄧㄢ":"八仙/巴先",
"ㄅㄚㄒㄧㄣ":"靶心",
"ㄅㄚㄒㄧㄤ":"巴想/八相",
"ㄅㄚㄒㄧㄥ":"八刑",
"ㄅㄚㄓㄧㄓ":"拔幟易幟",
"ㄅㄚㄓㄨㄛ":"把捉/拔擢",
"ㄅㄚㄓㄨㄞ":"巴拽",
"ㄅㄚㄓㄨㄢ":"八專",
"ㄅㄚㄔㄨㄢ":"巴船",
"ㄅㄚㄕㄊㄧ":"巴氏體",
"ㄅㄚㄕㄕㄨ":"拔十失五",
"ㄅㄚㄕㄢㄓ":"拔山志",
"ㄅㄚㄕㄨㄟ":"巴水/灞水",
"ㄅㄚㄗㄇㄟ":"八字眉",
"ㄅㄚㄗㄎㄚ":"巴茲卡",
"ㄅㄚㄗㄏㄨ":"八字鬍",
"ㄅㄚㄗㄨㄛ":"把做/把作",
"ㄅㄚㄗㄨㄟ":"跋嘴",
"ㄅㄚㄗㄨㄥ":"把總/八宗",
"ㄅㄚㄘㄨㄟ":"拔萃",
"ㄅㄚㄙㄅㄚ":"八思巴",
"ㄅㄚㄙㄨㄛ":"八索",
"ㄅㄚㄦㄍㄡ":"巴兒狗",
"ㄅㄚㄦㄕㄚ":"巴爾沙",
"ㄅㄚㄧㄡㄔ":"巴油池",
"ㄅㄚㄧㄢㄕ":"八言詩",
"ㄅㄚㄨㄤㄖ":"八王日",
"ㄅㄛㄅㄧㄣ":"薄鬢",
"ㄅㄛㄅㄧㄥ":"薄餅/撥兵",
"ㄅㄛㄆㄧㄢ":"薄片",
"ㄅㄛㄇㄧㄢ":"薄面",
"ㄅㄛㄇㄧㄥ":"搏命/薄明",
"ㄅㄛㄈㄥㄩ":"撥風羽",
"ㄅㄛㄉㄥㄦ":"撥瞪兒",
"ㄅㄛㄉㄨㄛ":"剝奪",
"ㄅㄛㄉㄨㄢ":"波段",
"ㄅㄛㄉㄨㄣ":"踣頓",
"ㄅㄛㄉㄨㄥ":"波動/撥動",
"ㄅㄛㄊㄧㄝ":"薄貼",
"ㄅㄛㄊㄧㄢ":"薄田",
"ㄅㄛㄊㄨㄛ":"剝脫/餺飥",
"ㄅㄛㄋㄧㄤ":"伯娘/波娘",
"ㄅㄛㄋㄨㄥ":"播弄/撥弄",
"ㄅㄛㄌㄧㄓ":"玻璃紙",
"ㄅㄛㄌㄧㄙ":"玻璃絲",
"ㄅㄛㄌㄧㄝ":"薄劣/擘裂",
"ㄅㄛㄌㄧㄠ":"撥撩",
"ㄅㄛㄌㄧㄡ":"波流/播流",
"ㄅㄛㄌㄧㄢ":"薄臉/搏煉",
"ㄅㄛㄌㄧㄥ":"伯靈/伯陵",
"ㄅㄛㄌㄨㄙ":"勃露斯",
"ㄅㄛㄌㄨㄛ":"剝落/薄落",
"ㄅㄛㄌㄨㄢ":"播亂/撥亂",
"ㄅㄛㄍㄥㄗ":"脖頸子",
"ㄅㄛㄍㄥㄦ":"脖頸兒",
"ㄅㄛㄍㄨㄚ":"瓟瓜",
"ㄅㄛㄍㄨㄟ":"撥龜",
"ㄅㄛㄍㄨㄢ":"博貫",
"ㄅㄛㄍㄨㄤ":"波光",
"ㄅㄛㄍㄨㄥ":"伯公/撥弓",
"ㄅㄛㄍㄨㄦ":"博古兒",
"ㄅㄛㄎㄨㄢ":"撥款",
"ㄅㄛㄏㄨㄚ":"玻化/擘畫",
"ㄅㄛㄏㄨㄛ":"撥火/柏濩",
"ㄅㄛㄏㄨㄞ":"泊懷",
"ㄅㄛㄏㄨㄟ":"駁回",
"ㄅㄛㄏㄨㄢ":"博換/薄宦",
"ㄅㄛㄐㄧㄝ":"撥接/駁詰",
"ㄅㄛㄐㄧㄠ":"跛腳/撥交",
"ㄅㄛㄐㄧㄡ":"柏酒/砵酒",
"ㄅㄛㄐㄧㄢ":"薄賤/擘牋",
"ㄅㄛㄐㄧㄣ":"剝盡/波津",
"ㄅㄛㄐㄩㄝ":"跛蹶/渤潏",
"ㄅㄛㄑㄧㄚ":"博洽",
"ㄅㄛㄑㄧㄠ":"波俏/波峭",
"ㄅㄛㄑㄧㄢ":"撥錢/簸錢",
"ㄅㄛㄑㄧㄣ":"薄親",
"ㄅㄛㄑㄧㄤ":"伯強",
"ㄅㄛㄑㄧㄥ":"薄情",
"ㄅㄛㄑㄩㄝ":"薄怯",
"ㄅㄛㄑㄩㄣ":"波群",
"ㄅㄛㄒㄧㄠ":"薄曉",
"ㄅㄛㄒㄧㄢ":"撥弦",
"ㄅㄛㄒㄧㄤ":"薄相/孛相",
"ㄅㄛㄒㄧㄥ":"薄行/波形",
"ㄅㄛㄒㄩㄝ":"剝削/博學",
"ㄅㄛㄒㄩㄥ":"伯兄",
"ㄅㄛㄓㄠㄕ":"伯趙氏",
"ㄅㄛㄓㄨㄛ":"薄酌/剝啄",
"ㄅㄛㄓㄨㄤ":"薄裝",
"ㄅㄛㄓㄨㄥ":"播種/伯仲",
"ㄅㄛㄔㄨㄢ":"駁船/泊船",
"ㄅㄛㄕㄠㄓ":"薄紹之",
"ㄅㄛㄕㄨㄛ":"瓝槊",
"ㄅㄛㄕㄨㄞ":"撥甩",
"ㄅㄛㄖㄨㄛ":"薄弱",
"ㄅㄛㄖㄨㄢ":"撥阮",
"ㄅㄛㄖㄨㄥ":"撥冗",
"ㄅㄛㄗㄧㄚ":"伯子牙",
"ㄅㄛㄗㄨㄟ":"撥嘴/薄醉",
"ㄅㄛㄘㄨㄟ":"薄脆",
"ㄅㄛㄙㄐㄩ":"波斯菊",
"ㄅㄛㄙㄨㄥ":"播送",
"ㄅㄛㄧㄈㄨ":"伯夷父",
"ㄅㄛㄧㄎㄠ":"伯邑考",
"ㄅㄛㄧㄡㄓ":"柏油紙",
"ㄅㄛㄨㄩㄢ":"博物院",
"ㄅㄛㄩㄊㄡ":"缽盂頭",
"ㄅㄞㄅㄚㄗ":"拜把子",
"ㄅㄞㄅㄠㄓ":"白報紙",
"ㄅㄞㄅㄧㄝ":"拜別",
"ㄅㄞㄅㄧㄠ":"拜表",
"ㄅㄞㄅㄧㄥ":"百病",
"ㄅㄞㄆㄧㄠ":"拜票",
"ㄅㄞㄆㄧㄣ":"白蘋",
"ㄅㄞㄆㄧㄥ":"擺平",
"ㄅㄞㄆㄨㄦ":"擺譜兒",
"ㄅㄞㄇㄚㄙ":"白馬寺",
"ㄅㄞㄇㄚㄦ":"百嗎兒",
"ㄅㄞㄇㄚㄧ":"白螞蟻",
"ㄅㄞㄇㄣㄗ":"擺門子",
"ㄅㄞㄇㄧㄠ":"白描",
"ㄅㄞㄇㄧㄢ":"白面/白麵",
"ㄅㄞㄇㄧㄥ":"擺明/拜命",
"ㄅㄞㄇㄨㄦ":"白木耳",
"ㄅㄞㄈㄣㄓ":"百分制",
"ㄅㄞㄈㄨㄗ":"白附子",
"ㄅㄞㄉㄞㄩ":"白帶魚",
"ㄅㄞㄉㄧㄢ":"白顛/拜墊",
"ㄅㄞㄉㄧㄥ":"白丁",
"ㄅㄞㄉㄨㄛ":"白墮",
"ㄅㄞㄉㄨㄢ":"百端",
"ㄅㄞㄉㄨㄥ":"擺動/白洞",
"ㄅㄞㄊㄢㄗ":"擺攤子",
"ㄅㄞㄊㄧㄝ":"拜帖/白鐵",
"ㄅㄞㄊㄧㄠ":"白條/白鰷",
"ㄅㄞㄊㄧㄢ":"白天",
"ㄅㄞㄊㄨㄗ":"白土子",
"ㄅㄞㄊㄨㄛ":"擺脫/拜託",
"ㄅㄞㄊㄨㄟ":"敗退",
"ㄅㄞㄊㄨㄢ":"白團",
"ㄅㄞㄊㄨㄥ":"白銅/白桐",
"ㄅㄞㄋㄚㄧ":"百衲衣",
"ㄅㄞㄋㄧㄠ":"白鳥/百鳥",
"ㄅㄞㄋㄧㄢ":"拜年/百年",
"ㄅㄞㄋㄨㄥ":"擺弄/擺弄",
"ㄅㄞㄌㄤㄗ":"擺浪子",
"ㄅㄞㄌㄧㄝ":"擺列",
"ㄅㄞㄌㄧㄠ":"百僚/百寮",
"ㄅㄞㄌㄧㄡ":"百六/敗柳",
"ㄅㄞㄌㄧㄢ":"白臉/白練",
"ㄅㄞㄌㄧㄣ":"白磷",
"ㄅㄞㄌㄧㄤ":"白糧",
"ㄅㄞㄌㄧㄥ":"白領/百靈",
"ㄅㄞㄌㄨㄓ":"白鹿紙",
"ㄅㄞㄌㄨㄙ":"白鷺鷥",
"ㄅㄞㄌㄨㄛ":"白落/敗落",
"ㄅㄞㄌㄨㄣ":"百論",
"ㄅㄞㄍㄨㄛ":"白果/百果",
"ㄅㄞㄍㄨㄟ":"白圭/白珪",
"ㄅㄞㄍㄨㄢ":"白鸛/百官",
"ㄅㄞㄍㄨㄤ":"白光",
"ㄅㄞㄍㄨㄥ":"白宮/百工",
"ㄅㄞㄎㄨㄛ":"擺闊",
"ㄅㄞㄎㄨㄞ":"白快",
"ㄅㄞㄎㄨㄟ":"百揆",
"ㄅㄞㄎㄨㄢ":"擺款",
"ㄅㄞㄏㄨㄚ":"白話/白花",
"ㄅㄞㄏㄨㄛ":"百貨/敗火",
"ㄅㄞㄏㄨㄞ":"敗壞",
"ㄅㄞㄏㄨㄟ":"拜會/百會",
"ㄅㄞㄏㄨㄢ":"白環",
"ㄅㄞㄏㄨㄣ":"白混",
"ㄅㄞㄏㄨㄤ":"白謊/掰謊",
"ㄅㄞㄏㄨㄥ":"白虹/白澒",
"ㄅㄞㄐㄧㄚ":"敗家/百家",
"ㄅㄞㄐㄧㄝ":"百結/拜節",
"ㄅㄞㄐㄧㄠ":"白教/白膠",
"ㄅㄞㄐㄧㄡ":"擺酒/白酒",
"ㄅㄞㄐㄧㄢ":"拜見/白簡",
"ㄅㄞㄐㄧㄣ":"白金/百金",
"ㄅㄞㄐㄧㄤ":"敗醬",
"ㄅㄞㄐㄧㄥ":"白淨",
"ㄅㄞㄐㄩㄝ":"拜爵",
"ㄅㄞㄐㄩㄢ":"白卷",
"ㄅㄞㄐㄩㄣ":"白軍/敗軍",
"ㄅㄞㄐㄩㄧ":"白居易",
"ㄅㄞㄑㄧㄢ":"白前/白錢",
"ㄅㄞㄑㄧㄣ":"拜親",
"ㄅㄞㄑㄧㄤ":"白鏹",
"ㄅㄞㄑㄩㄝ":"敗缺",
"ㄅㄞㄒㄧㄚ":"拜匣",
"ㄅㄞㄒㄧㄝ":"拜謝",
"ㄅㄞㄒㄧㄠ":"白蛸",
"ㄅㄞㄒㄧㄢ":"白癬/白蘚",
"ㄅㄞㄒㄧㄤ":"白相/拜相",
"ㄅㄞㄒㄧㄥ":"百姓/敗行",
"ㄅㄞㄒㄩㄝ":"白雪",
"ㄅㄞㄒㄩㄥ":"白熊",
"ㄅㄞㄓㄨㄛ":"白濁",
"ㄅㄞㄓㄨㄤ":"白撞",
"ㄅㄞㄓㄨㄥ":"擺鐘",
"ㄅㄞㄔㄉㄥ":"白熾燈",
"ㄅㄞㄔㄞㄕ":"白差使",
"ㄅㄞㄔㄤㄩ":"白鯧魚",
"ㄅㄞㄔㄨㄢ":"百川",
"ㄅㄞㄔㄨㄣ":"拜春",
"ㄅㄞㄕㄓㄕ":"百世之師",
"ㄅㄞㄕㄕㄣ":"白石神",
"ㄅㄞㄕㄜㄦ":"擺設兒",
"ㄅㄞㄕㄨㄛ":"白說/擺搠",
"ㄅㄞㄕㄨㄟ":"白水",
"ㄅㄞㄕㄨㄣ":"百順",
"ㄅㄞㄖㄇㄥ":"白日夢",
"ㄅㄞㄖㄎㄜ":"百日咳",
"ㄅㄞㄖㄗㄟ":"白日賊",
"ㄅㄞㄖㄘㄠ":"百日草",
"ㄅㄞㄖㄨㄟ":"白桵",
"ㄅㄞㄖㄨㄩ":"白如玉",
"ㄅㄞㄗㄊㄨ":"百子圖",
"ㄅㄞㄗㄒㄧ":"白字戲",
"ㄅㄞㄗㄓㄤ":"百子帳",
"ㄅㄞㄗㄨㄛ":"白做",
"ㄅㄞㄗㄨㄟ":"百晬",
"ㄅㄞㄘㄨㄛ":"敗挫",
"ㄅㄞㄙㄨㄛ":"百索",
"ㄅㄞㄙㄨㄟ":"百歲/敗歲",
"ㄅㄞㄙㄨㄣ":"敗損",
"ㄅㄞㄙㄨㄥ":"白松",
"ㄅㄞㄜㄏㄨ":"白額虎",
"ㄅㄞㄜㄐㄧ":"白堊紀",
"ㄅㄞㄜㄒㄧ":"白堊系",
"ㄅㄞㄜㄧㄢ":"白額雁",
"ㄅㄞㄧㄖㄣ":"白衣人",
"ㄅㄞㄧㄗㄨ":"拜衣族",
"ㄅㄞㄧㄤㄗ":"擺樣子",
"ㄅㄞㄨㄧㄕ":"百無一是",
"ㄅㄞㄨㄧㄦ":"百無一二",
"ㄅㄞㄩㄈㄣ":"白魚粉",
"ㄅㄞㄩㄌㄡ":"白玉樓",
"ㄅㄞㄩㄌㄢ":"白玉蘭",
"ㄅㄞㄩㄣㄕ":"白雲石",
"ㄅㄟㄅㄧㄠ":"背褾",
"ㄅㄟㄅㄧㄢ":"北邊",
"ㄅㄟㄆㄢㄨ":"杯盤舞",
"ㄅㄟㄆㄧㄠ":"北票",
"ㄅㄟㄆㄧㄢ":"被騙",
"ㄅㄟㄆㄧㄣ":"備品",
"ㄅㄟㄇㄧㄡ":"悖謬/悖繆",
"ㄅㄟㄇㄧㄢ":"杯麵/背面",
"ㄅㄟㄇㄧㄣ":"悲憫",
"ㄅㄟㄇㄧㄥ":"杯茗/北冥",
"ㄅㄟㄈㄣㄕ":"悲憤詩",
"ㄅㄟㄉㄚㄗ":"被搭子",
"ㄅㄟㄉㄧㄢ":"杯墊",
"ㄅㄟㄉㄨㄛ":"貝多",
"ㄅㄟㄉㄨㄥ":"被動",
"ㄅㄟㄊㄧㄝ":"碑帖",
"ㄅㄟㄊㄧㄢ":"悲田",
"ㄅㄟㄊㄧㄥ":"背聽/北庭",
"ㄅㄟㄊㄨㄛ":"備妥",
"ㄅㄟㄊㄨㄥ":"悲痛/悲慟",
"ㄅㄟㄌㄜㄕ":"背了時",
"ㄅㄟㄌㄧㄝ":"卑劣",
"ㄅㄟㄌㄧㄠ":"備料",
"ㄅㄟㄌㄧㄡ":"輩流",
"ㄅㄟㄌㄧㄢ":"悲憐",
"ㄅㄟㄌㄧㄣ":"北林/碑林",
"ㄅㄟㄌㄧㄤ":"悲涼/北涼",
"ㄅㄟㄌㄧㄥ":"北嶺/碑嶺",
"ㄅㄟㄌㄨㄢ":"悖亂",
"ㄅㄟㄌㄨㄣ":"卑論/悖倫",
"ㄅㄟㄍㄨㄛ":"北國/杯鍋",
"ㄅㄟㄍㄨㄢ":"悲觀/北管",
"ㄅㄟㄍㄨㄤ":"背光",
"ㄅㄟㄍㄨㄥ":"背工/背躬",
"ㄅㄟㄏㄞㄢ":"北海岸",
"ㄅㄟㄏㄨㄚ":"背花",
"ㄅㄟㄏㄨㄛ":"北貨",
"ㄅㄟㄏㄨㄞ":"悲懷",
"ㄅㄟㄏㄨㄟ":"背悔/背晦",
"ㄅㄟㄏㄨㄢ":"悲歡",
"ㄅㄟㄏㄨㄤ":"備荒",
"ㄅㄟㄐㄧㄚ":"備家/悲笳",
"ㄅㄟㄐㄧㄝ":"碑碣",
"ㄅㄟㄐㄧㄠ":"杯珓/杯筊",
"ㄅㄟㄐㄧㄡ":"被酒",
"ㄅㄟㄐㄧㄢ":"卑賤/卑見",
"ㄅㄟㄐㄧㄣ":"卑謹/貝錦",
"ㄅㄟㄐㄧㄥ":"背景/北京",
"ㄅㄟㄐㄩㄝ":"倍覺",
"ㄅㄟㄑㄧㄝ":"悲切",
"ㄅㄟㄑㄧㄡ":"被囚/悲秋",
"ㄅㄟㄑㄧㄥ":"悲情",
"ㄅㄟㄑㄩㄝ":"北闕/卑怯",
"ㄅㄟㄑㄩㄢ":"杯圈/北拳",
"ㄅㄟㄒㄧㄚ":"卑下",
"ㄅㄟㄒㄧㄣ":"背心/悲心",
"ㄅㄟㄒㄧㄤ":"背巷",
"ㄅㄟㄒㄧㄥ":"杯行/背興",
"ㄅㄟㄒㄩㄝ":"北學/碑學",
"ㄅㄟㄒㄩㄣ":"備詢",
"ㄅㄟㄓㄌㄧ":"北直隸",
"ㄅㄟㄓㄨㄛ":"杯酌/盃酌",
"ㄅㄟㄓㄨㄤ":"悲壯",
"ㄅㄟㄓㄨㄥ":"貝塚",
"ㄅㄟㄔㄨㄟ":"北垂",
"ㄅㄟㄔㄨㄢ":"碑穿",
"ㄅㄟㄔㄨㄤ":"悲愴",
"ㄅㄟㄕㄨㄟ":"背水",
"ㄅㄟㄗㄓㄨ":"被子植物",
"ㄅㄟㄗㄨㄥ":"北宗",
"ㄅㄟㄘㄨㄟ":"悲摧",
"ㄅㄟㄙㄨㄢ":"悲酸",
"ㄅㄟㄙㄨㄥ":"背誦/北宋",
"ㄅㄟㄢㄎㄨ":"備安庫",
"ㄅㄟㄦㄍㄠ":"北二高",
"ㄅㄟㄦㄏㄨ":"貝爾湖",
"ㄅㄟㄧㄩㄢ":"貝義淵",
"ㄅㄠㄅㄢㄓ":"包班制",
"ㄅㄠㄅㄧㄠ":"保鑣/保鏢",
"ㄅㄠㄅㄧㄢ":"豹變/褒貶",
"ㄅㄠㄅㄧㄥ":"暴病/抱病",
"ㄅㄠㄆㄧㄠ":"保票/包票",
"ㄅㄠㄆㄧㄣ":"報聘",
"ㄅㄠㄆㄧㄥ":"寶瓶",
"ㄅㄠㄆㄨㄗ":"抱朴子",
"ㄅㄠㄇㄧㄣ":"暴民",
"ㄅㄠㄇㄧㄥ":"報名/保命",
"ㄅㄠㄈㄥㄩ":"暴風雨",
"ㄅㄠㄉㄞㄓ":"包待制",
"ㄅㄠㄉㄧㄝ":"暴跌",
"ㄅㄠㄉㄧㄢ":"寶典/寶殿",
"ㄅㄠㄉㄧㄥ":"保定/寶鼎",
"ㄅㄠㄉㄨㄥ":"暴動",
"ㄅㄠㄉㄨㄦ":"爆肚兒",
"ㄅㄠㄊㄚㄕ":"寶塔詩",
"ㄅㄠㄊㄡㄕ":"包頭市",
"ㄅㄠㄊㄧㄝ":"報帖",
"ㄅㄠㄊㄧㄠ":"報條",
"ㄅㄠㄊㄧㄢ":"暴殄",
"ㄅㄠㄊㄨㄢ":"報團",
"ㄅㄠㄊㄨㄥ":"包通/報童",
"ㄅㄠㄋㄧㄝ":"寶鑷",
"ㄅㄠㄋㄧㄠ":"鴇鳥",
"ㄅㄠㄋㄨㄢ":"飽暖",
"ㄅㄠㄋㄩㄝ":"暴虐",
"ㄅㄠㄌㄧㄓ":"豹立直",
"ㄅㄠㄌㄧㄝ":"爆裂/暴烈",
"ㄅㄠㄌㄧㄡ":"保留",
"ㄅㄠㄌㄧㄢ":"暴斂",
"ㄅㄠㄌㄧㄣ":"保林",
"ㄅㄠㄌㄨㄛ":"包羅/抱鑼",
"ㄅㄠㄌㄨㄢ":"暴亂",
"ㄅㄠㄌㄨㄥ":"包籠",
"ㄅㄠㄌㄩㄝ":"豹略/暴掠",
"ㄅㄠㄍㄜㄦ":"飽嗝兒",
"ㄅㄠㄍㄨㄛ":"包裹/報國",
"ㄅㄠㄍㄨㄟ":"寶貴/暴貴",
"ㄅㄠㄍㄨㄢ":"報館/保管",
"ㄅㄠㄍㄨㄤ":"葆光",
"ㄅㄠㄍㄨㄥ":"包公/包工",
"ㄅㄠㄎㄨㄛ":"包括",
"ㄅㄠㄎㄨㄟ":"抱愧",
"ㄅㄠㄏㄠㄙ":"包浩斯",
"ㄅㄠㄏㄨㄚ":"鉋花",
"ㄅㄠㄏㄨㄛ":"包伙/寶貨",
"ㄅㄠㄏㄨㄢ":"包換/豹環",
"ㄅㄠㄏㄨㄤ":"包荒",
"ㄅㄠㄏㄨㄥ":"暴洪",
"ㄅㄠㄐㄧㄚ":"報價/保家",
"ㄅㄠㄐㄧㄝ":"保結/報界",
"ㄅㄠㄐㄧㄠ":"保教/包腳",
"ㄅㄠㄐㄧㄢ":"寶劍/保健",
"ㄅㄠㄐㄧㄣ":"包金/報禁",
"ㄅㄠㄐㄧㄤ":"褒獎/保獎",
"ㄅㄠㄐㄧㄥ":"報警/保警",
"ㄅㄠㄐㄩㄢ":"寶卷/寶眷",
"ㄅㄠㄐㄩㄣ":"暴君",
"ㄅㄠㄑㄧㄡ":"抱球",
"ㄅㄠㄑㄧㄢ":"抱歉/報錢",
"ㄅㄠㄑㄧㄣ":"保親/抱琴",
"ㄅㄠㄑㄧㄥ":"報請/寶慶",
"ㄅㄠㄑㄩㄢ":"保全/抱拳",
"ㄅㄠㄑㄩㄣ":"抱裙",
"ㄅㄠㄒㄧㄚ":"暴下",
"ㄅㄠㄒㄧㄝ":"報謝",
"ㄅㄠㄒㄧㄠ":"爆笑/報銷",
"ㄅㄠㄒㄧㄡ":"報修",
"ㄅㄠㄒㄧㄢ":"保險/保鮮",
"ㄅㄠㄒㄧㄣ":"報信",
"ㄅㄠㄒㄧㄤ":"寶相/包廂",
"ㄅㄠㄒㄧㄥ":"暴行/寶星",
"ㄅㄠㄒㄩㄝ":"飽學/鉋削",
"ㄅㄠㄒㄩㄥ":"胞兄",
"ㄅㄠㄓㄚㄨ":"爆炸物",
"ㄅㄠㄓㄤㄕ":"保章氏",
"ㄅㄠㄓㄨㄣ":"保准/包准",
"ㄅㄠㄓㄨㄤ":"包裝/報狀",
"ㄅㄠㄓㄨㄥ":"保重/寶重",
"ㄅㄠㄔㄨㄢ":"包船",
"ㄅㄠㄔㄨㄤ":"寶幢/鉋床",
"ㄅㄠㄔㄨㄥ":"苞蟲",
"ㄅㄠㄕㄋㄤ":"鮑氏囊",
"ㄅㄠㄕㄔㄣ":"包世臣",
"ㄅㄠㄕㄖㄣ":"報事人",
"ㄅㄠㄕㄨㄟ":"報稅/保稅",
"ㄅㄠㄖㄨㄥ":"包容/包融",
"ㄅㄠㄗㄊㄧ":"胞子體",
"ㄅㄠㄗㄋㄤ":"孢子囊",
"ㄅㄠㄗㄑㄧ":"胞子器",
"ㄅㄠㄗㄓㄨ":"孢子植物",
"ㄅㄠㄗㄧㄝ":"孢子葉",
"ㄅㄠㄗㄨㄛ":"寶座/寶祚",
"ㄅㄠㄗㄨㄟ":"抱罪",
"ㄅㄠㄘㄨㄣ":"保存",
"ㄅㄠㄙㄨㄟ":"報歲",
"ㄅㄠㄙㄨㄣ":"苞筍",
"ㄅㄠㄙㄨㄥ":"保送",
"ㄅㄠㄢㄗㄨ":"保安族",
"ㄅㄠㄩㄍㄨ":"鮑魚菇",
"ㄅㄠㄩㄓㄙ":"鮑魚之肆",
"ㄅㄠㄩㄩㄢ":"保育員",
"ㄅㄢㄅㄟㄗ":"半輩子",
"ㄅㄢㄅㄠㄦ":"半包兒",
"ㄅㄢㄅㄧㄝ":"班別",
"ㄅㄢㄅㄧㄠ":"班彪",
"ㄅㄢㄅㄧㄢ":"半邊",
"ㄅㄢㄅㄧㄣ":"斑鬢/頒斌",
"ㄅㄢㄅㄧㄥ":"班兵/搬兵",
"ㄅㄢㄆㄧㄠ":"半票",
"ㄅㄢㄆㄧㄢ":"半偏",
"ㄅㄢㄇㄣㄗ":"半門子",
"ㄅㄢㄇㄧㄢ":"版面/半面",
"ㄅㄢㄈㄟㄕ":"斑飛虱",
"ㄅㄢㄈㄥㄦ":"半瘋兒",
"ㄅㄢㄉㄠㄕ":"班導師",
"ㄅㄢㄉㄠㄦ":"半道兒",
"ㄅㄢㄉㄧㄝ":"絆跌/斑蝶",
"ㄅㄢㄉㄧㄢ":"半點/斑點",
"ㄅㄢㄉㄨㄣ":"半蹲",
"ㄅㄢㄉㄨㄥ":"搬動",
"ㄅㄢㄊㄧㄠ":"般調/搬調",
"ㄅㄢㄊㄧㄢ":"半天/阪田",
"ㄅㄢㄊㄨㄛ":"辦妥",
"ㄅㄢㄊㄨㄥ":"伴同",
"ㄅㄢㄋㄧㄤ":"伴娘",
"ㄅㄢㄋㄨㄛ":"搬挪",
"ㄅㄢㄋㄨㄥ":"般弄/搬弄",
"ㄅㄢㄌㄧㄝ":"班列",
"ㄅㄢㄌㄧㄡ":"半綹",
"ㄅㄢㄌㄧㄤ":"鈑梁",
"ㄅㄢㄌㄨㄣ":"班輪",
"ㄅㄢㄍㄨㄕ":"扮故事",
"ㄅㄢㄍㄨㄟ":"半規",
"ㄅㄢㄍㄨㄥ":"辦公/半弓",
"ㄅㄢㄍㄨㄦ":"伴姑兒",
"ㄅㄢㄎㄨㄞ":"板塊",
"ㄅㄢㄎㄨㄥ":"半空",
"ㄅㄢㄏㄜㄦ":"半合兒",
"ㄅㄢㄏㄢㄗ":"半憨子",
"ㄅㄢㄏㄨㄚ":"版畫/板畫",
"ㄅㄢㄏㄨㄛ":"辦貨/伴夥",
"ㄅㄢㄏㄨㄟ":"班會/半會",
"ㄅㄢㄏㄨㄢ":"伴奐",
"ㄅㄢㄐㄧㄚ":"搬家/半價",
"ㄅㄢㄐㄧㄝ":"板結/半截",
"ㄅㄢㄐㄧㄠ":"絆腳/絆跤",
"ㄅㄢㄐㄧㄡ":"半舊/搬九",
"ㄅㄢㄐㄧㄢ":"半鑑",
"ㄅㄢㄐㄧㄣ":"板金/板巾",
"ㄅㄢㄐㄧㄤ":"頒獎/板僵",
"ㄅㄢㄐㄧㄥ":"半徑/班荊",
"ㄅㄢㄐㄩㄣ":"班軍",
"ㄅㄢㄑㄧㄚ":"半恰/半掐",
"ㄅㄢㄑㄧㄠ":"板橋",
"ㄅㄢㄑㄧㄡ":"板球/半球",
"ㄅㄢㄑㄧㄢ":"搬遷",
"ㄅㄢㄑㄧㄤ":"板強",
"ㄅㄢㄑㄧㄥ":"搬請",
"ㄅㄢㄑㄩㄢ":"版權/阪泉",
"ㄅㄢㄒㄧㄚ":"半夏",
"ㄅㄢㄒㄧㄝ":"半歇",
"ㄅㄢㄒㄧㄢ":"半仙",
"ㄅㄢㄒㄧㄣ":"辦心/版心",
"ㄅㄢㄒㄧㄤ":"扮相/瓣香",
"ㄅㄢㄒㄧㄥ":"頒行/半星",
"ㄅㄢㄒㄩㄝ":"辦學",
"ㄅㄢㄓㄨㄛ":"辦桌",
"ㄅㄢㄓㄨㄟ":"斑騅",
"ㄅㄢㄓㄨㄤ":"扮裝/版樁",
"ㄅㄢㄔㄚㄗ":"扳岔子",
"ㄅㄢㄔㄠㄦ":"半抄兒",
"ㄅㄢㄔㄧㄣ":"半齒音",
"ㄅㄢㄔㄨㄟ":"般倕",
"ㄅㄢㄕㄔㄨ":"辦事處",
"ㄅㄢㄕㄨㄚ":"板刷",
"ㄅㄢㄕㄨㄟ":"版稅",
"ㄅㄢㄕㄩㄢ":"辦事員",
"ㄅㄢㄗㄨㄟ":"半醉/搬嘴",
"ㄅㄢㄘㄨㄛ":"板銼",
"ㄅㄢㄙㄨㄛ":"搬唆",
"ㄅㄢㄙㄨㄟ":"伴隨",
"ㄅㄢㄙㄨㄢ":"拌蒜",
"ㄅㄢㄙㄨㄥ":"伴送",
"ㄅㄣㄆㄧㄠ":"本票",
"ㄅㄣㄇㄧㄥ":"本名/奔命",
"ㄅㄣㄈㄣㄕ":"本分事",
"ㄅㄣㄉㄧㄗ":"本底子",
"ㄅㄣㄉㄨㄟ":"本對",
"ㄅㄣㄉㄨㄣ":"笨鈍",
"ㄅㄣㄌㄧㄡ":"本流/奔流",
"ㄅㄣㄌㄧㄥ":"本領",
"ㄅㄣㄌㄨㄣ":"本論",
"ㄅㄣㄍㄨㄛ":"本國",
"ㄅㄣㄍㄨㄢ":"本官/本貫",
"ㄅㄣㄍㄨㄥ":"坌工",
"ㄅㄣㄎㄨㄟ":"奔潰",
"ㄅㄣㄐㄧㄚ":"本家",
"ㄅㄣㄐㄧㄢ":"奔箭",
"ㄅㄣㄐㄧㄣ":"本金",
"ㄅㄣㄐㄧㄥ":"本境/奔競",
"ㄅㄣㄑㄧㄢ":"本錢",
"ㄅㄣㄑㄩㄢ":"奔泉",
"ㄅㄣㄒㄧㄝ":"奔瀉",
"ㄅㄣㄒㄧㄠ":"本銷",
"ㄅㄣㄒㄧㄣ":"本心/本薪",
"ㄅㄣㄒㄧㄤ":"本相",
"ㄅㄣㄒㄧㄥ":"本性/本姓",
"ㄅㄣㄓㄨㄛ":"笨拙",
"ㄅㄣㄓㄨㄥ":"笨重",
"ㄅㄣㄗㄨㄣ":"本尊",
"ㄅㄣㄘㄨㄢ":"奔竄",
"ㄅㄣㄙㄩㄢ":"本司院",
"ㄅㄣㄧㄅㄧ":"本益比",
"ㄅㄣㄧㄒㄧ":"苯乙烯",
"ㄅㄤㄅㄧㄝ":"幫別",
"ㄅㄤㄆㄧㄠ":"綁票",
"ㄅㄤㄆㄧㄣ":"幫貧",
"ㄅㄤㄈㄨㄕ":"蚌埠市",
"ㄅㄤㄉㄧㄢ":"邦甸",
"ㄅㄤㄉㄧㄥ":"幫丁",
"ㄅㄤㄉㄨㄥ":"幫冬",
"ㄅㄤㄊㄧㄝ":"幫帖/榜帖",
"ㄅㄤㄊㄨㄟ":"綁腿",
"ㄅㄤㄌㄧㄢ":"邦聯",
"ㄅㄤㄌㄨㄢ":"幫亂",
"ㄅㄤㄍㄨㄛ":"邦國",
"ㄅㄤㄍㄨㄟ":"綁鬼",
"ㄅㄤㄍㄨㄥ":"幫工",
"ㄅㄤㄏㄨㄚ":"榜花",
"ㄅㄤㄏㄨㄛ":"幫伙/幫夥",
"ㄅㄤㄏㄨㄟ":"幫會/謗毀",
"ㄅㄤㄐㄧㄚ":"綁架/邦家",
"ㄅㄤㄐㄧㄠ":"邦交/幫教",
"ㄅㄤㄐㄧㄣ":"傍今/邦禁",
"ㄅㄤㄑㄧㄡ":"棒球",
"ㄅㄤㄑㄧㄤ":"幫腔",
"ㄅㄤㄒㄧㄢ":"幫閑",
"ㄅㄤㄒㄧㄥ":"幫興",
"ㄅㄤㄒㄩㄥ":"幫凶",
"ㄅㄤㄔㄨㄟ":"棒槌",
"ㄅㄤㄔㄨㄤ":"棒瘡",
"ㄅㄤㄗㄕㄡ":"棒子手",
"ㄅㄤㄗㄨㄟ":"幫嘴",
"ㄅㄤㄘㄨㄣ":"幫寸",
"ㄅㄤㄦㄊㄡ":"梆兒頭",
"ㄅㄥㄅㄥㄦ":"蹦蹦兒",
"ㄅㄥㄉㄡㄦ":"迸豆兒",
"ㄅㄥㄉㄧㄠ":"蹦掉",
"ㄅㄥㄊㄧㄠ":"蹦跳/迸跳",
"ㄅㄥㄌㄧㄝ":"崩裂/繃裂",
"ㄅㄥㄌㄧㄡ":"迸流",
"ㄅㄥㄌㄧㄢ":"繃臉",
"ㄅㄥㄌㄨㄛ":"崩落",
"ㄅㄥㄌㄩㄝ":"榜掠",
"ㄅㄥㄍㄨㄥ":"繃弓",
"ㄅㄥㄎㄨㄟ":"崩潰",
"ㄅㄥㄏㄨㄤ":"繃簧",
"ㄅㄥㄐㄧㄚ":"繃價/崩駕",
"ㄅㄥㄐㄧㄝ":"崩解/繃接",
"ㄅㄥㄐㄧㄠ":"崩角",
"ㄅㄥㄐㄧㄣ":"繃緊",
"ㄅㄥㄒㄧㄝ":"崩瀉",
"ㄅㄥㄒㄧㄢ":"繃線/崩陷",
"ㄅㄥㄓㄨㄞ":"繃拽",
"ㄅㄥㄔㄨㄟ":"榜箠",
"ㄅㄥㄘㄨㄟ":"崩摧/迸脆",
"ㄅㄧㄅㄠㄓ":"壁報紙",
"ㄅㄧㄅㄧㄥ":"弊病/比並",
"ㄅㄧㄇㄧㄠ":"筆描",
"ㄅㄧㄇㄧㄡ":"秕謬",
"ㄅㄧㄇㄧㄢ":"避免/閉面",
"ㄅㄧㄇㄧㄥ":"筆名/畢命",
"ㄅㄧㄇㄨㄕ":"閉幕式",
"ㄅㄧㄇㄨㄩ":"比目魚",
"ㄅㄧㄉㄧㄠ":"筆調",
"ㄅㄧㄉㄧㄥ":"必定",
"ㄅㄧㄉㄨㄟ":"比對",
"ㄅㄧㄉㄨㄢ":"弊端/筆端",
"ㄅㄧㄊㄡㄦ":"筆頭兒",
"ㄅㄧㄊㄧㄢ":"蔽天/璧田",
"ㄅㄧㄊㄧㄥ":"筆挺/壁聽",
"ㄅㄧㄊㄨㄥ":"筆筒",
"ㄅㄧㄋㄧㄢ":"比年",
"ㄅㄧㄋㄧㄥ":"嬖佞",
"ㄅㄧㄌㄚㄦ":"畢拉爾",
"ㄅㄧㄌㄧㄔ":"比例尺",
"ㄅㄧㄌㄧㄣ":"比鄰/逼臨",
"ㄅㄧㄌㄧㄤ":"比量/比量",
"ㄅㄧㄌㄨㄛ":"壁落/碧落",
"ㄅㄧㄌㄨㄢ":"避亂",
"ㄅㄧㄌㄨㄣ":"筆論/鼻輪",
"ㄅㄧㄌㄨㄥ":"閉攏",
"ㄅㄧㄍㄨㄛ":"閉果/敝國",
"ㄅㄧㄍㄨㄞ":"避乖",
"ㄅㄧㄍㄨㄟ":"敝鬼",
"ㄅㄧㄍㄨㄢ":"閉關/鼻觀",
"ㄅㄧㄍㄨㄥ":"逼供/筆供",
"ㄅㄧㄎㄨㄣ":"鄙悃/梐梱",
"ㄅㄧㄎㄨㄤ":"比況",
"ㄅㄧㄎㄨㄥ":"鼻孔/碧空",
"ㄅㄧㄏㄨㄚ":"筆畫/比劃",
"ㄅㄧㄏㄨㄛ":"避禍",
"ㄅㄧㄏㄨㄞ":"鄙懷",
"ㄅㄧㄏㄨㄟ":"避諱/筆會",
"ㄅㄧㄏㄨㄢ":"璧還",
"ㄅㄧㄐㄧㄚ":"鄙家/筆架",
"ㄅㄧㄐㄧㄝ":"閉結/篦節",
"ㄅㄧㄐㄧㄠ":"比較/比校",
"ㄅㄧㄐㄧㄡ":"避就",
"ㄅㄧㄐㄧㄢ":"鄙見/陛見",
"ㄅㄧㄐㄧㄣ":"逼近/逼緊",
"ㄅㄧㄐㄧㄥ":"畢竟/閉經",
"ㄅㄧㄐㄩㄢ":"筆捲/敝眷",
"ㄅㄧㄑㄧㄝ":"婢妾/嬖妾",
"ㄅㄧㄑㄧㄡ":"壁球/比丘",
"ㄅㄧㄑㄧㄢ":"逼遣",
"ㄅㄧㄑㄧㄣ":"比親/避秦",
"ㄅㄧㄑㄧㄤ":"鼻腔",
"ㄅㄧㄑㄩㄢ":"毖泉",
"ㄅㄧㄒㄧㄚ":"陛下/筆下",
"ㄅㄧㄒㄧㄝ":"鼻血/辟邪",
"ㄅㄧㄒㄧㄠ":"鄙笑/畢肖",
"ㄅㄧㄒㄧㄡ":"必修/畢宿",
"ㄅㄧㄒㄧㄢ":"比先/避嫌",
"ㄅㄧㄒㄧㄣ":"比心/筆心",
"ㄅㄧㄒㄧㄤ":"臂香/比象",
"ㄅㄧㄒㄧㄥ":"詖行/比興",
"ㄅㄧㄒㄩㄝ":"筆削",
"ㄅㄧㄓㄓㄢ":"邲之戰",
"ㄅㄧㄓㄨㄣ":"鼻準",
"ㄅㄧㄓㄨㄥ":"比重/筆冢",
"ㄅㄧㄔㄨㄛ":"逼綽",
"ㄅㄧㄔㄨㄢ":"臂釧",
"ㄅㄧㄔㄨㄤ":"筆床",
"ㄅㄧㄕㄡㄕ":"比手式",
"ㄅㄧㄕㄨㄞ":"避衰",
"ㄅㄧㄕㄨㄣ":"筆順",
"ㄅㄧㄖㄨㄛ":"必若",
"ㄅㄧㄗㄊㄡ":"鼻子頭",
"ㄅㄧㄗㄨㄟ":"閉嘴",
"ㄅㄧㄗㄨㄥ":"比蹤",
"ㄅㄧㄙㄨㄛ":"閉鎖",
"ㄅㄧㄙㄨㄟ":"比歲",
"ㄅㄧㄙㄨㄢ":"筆算/鼻酸",
"ㄅㄧㄝㄅㄞ":"別白",
"ㄅㄧㄝㄅㄣ":"別本",
"ㄅㄧㄝㄇㄛ":"別墨",
"ㄅㄧㄝㄇㄣ":"鱉悶/憋悶",
"ㄅㄧㄝㄈㄚ":"別法",
"ㄅㄧㄝㄈㄤ":"別房",
"ㄅㄧㄝㄈㄨ":"別賦",
"ㄅㄧㄝㄉㄧ":"別第",
"ㄅㄧㄝㄊㄧ":"別體/別提",
"ㄅㄧㄝㄌㄞ":"別來",
"ㄅㄧㄝㄌㄧ":"別離",
"ㄅㄧㄝㄏㄜ":"別鶴",
"ㄅㄧㄝㄏㄠ":"別號",
"ㄅㄧㄝㄐㄧ":"別集",
"ㄅㄧㄝㄑㄧ":"蹩氣/彆氣",
"ㄅㄧㄝㄑㄩ":"別趣",
"ㄅㄧㄝㄒㄧ":"襒席",
"ㄅㄧㄝㄒㄩ":"別緒",
"ㄅㄧㄝㄓㄓ":"憋支支",
"ㄅㄧㄝㄓㄣ":"別針",
"ㄅㄧㄝㄔㄥ":"別稱",
"ㄅㄧㄝㄔㄨ":"別處",
"ㄅㄧㄝㄕㄚ":"鱉煞",
"ㄅㄧㄝㄕㄡ":"別手",
"ㄅㄧㄝㄕㄨ":"別墅",
"ㄅㄧㄝㄖㄣ":"別人",
"ㄅㄧㄝㄗㄠ":"鱉燥/憋噪",
"ㄅㄧㄝㄘㄞ":"別材/別裁",
"ㄅㄧㄝㄘㄠ":"鱉懆/憋懆",
"ㄅㄧㄝㄙㄚ":"蹩躠",
"ㄅㄧㄝㄙㄢ":"癟三",
"ㄅㄧㄝㄧㄝ":"別業",
"ㄅㄧㄝㄧㄢ":"別筵",
"ㄅㄧㄝㄧㄤ":"別樣",
"ㄅㄧㄝㄩㄢ":"別院",
"ㄅㄧㄠㄅㄚ":"標靶",
"ㄅㄧㄠㄅㄛ":"表襮/標撥",
"ㄅㄧㄠㄅㄞ":"表白",
"ㄅㄧㄠㄅㄟ":"表背/裱背",
"ㄅㄧㄠㄅㄣ":"標本/表本",
"ㄅㄧㄠㄅㄤ":"標榜/標牓",
"ㄅㄧㄠㄆㄞ":"摽牌",
"ㄅㄧㄠㄆㄢ":"表盤",
"ㄅㄧㄠㄆㄧ":"表皮",
"ㄅㄧㄠㄇㄞ":"標賣",
"ㄅㄧㄠㄇㄟ":"表妹",
"ㄅㄧㄠㄇㄢ":"臕滿",
"ㄅㄧㄠㄇㄣ":"表門",
"ㄅㄧㄠㄈㄣ":"俵分",
"ㄅㄧㄠㄈㄥ":"飆風/熛風",
"ㄅㄧㄠㄉㄚ":"表達",
"ㄅㄧㄠㄉㄜ":"表德",
"ㄅㄧㄠㄉㄞ":"錶帶",
"ㄅㄧㄠㄉㄢ":"標單",
"ㄅㄧㄠㄉㄧ":"表弟/標的",
"ㄅㄧㄠㄊㄞ":"表態",
"ㄅㄧㄠㄊㄢ":"熛炭",
"ㄅㄧㄠㄊㄤ":"鼻凹糖",
"ㄅㄧㄠㄊㄧ":"標題/表題",
"ㄅㄧㄠㄌㄧ":"表禮/表裡",
"ㄅㄧㄠㄌㄨ":"表露",
"ㄅㄧㄠㄍㄜ":"表哥/表格",
"ㄅㄧㄠㄍㄠ":"標高",
"ㄅㄧㄠㄍㄡ":"標購",
"ㄅㄧㄠㄍㄢ":"標杆/標竿",
"ㄅㄧㄠㄎㄜ":"鑣客/鏢客",
"ㄅㄧㄠㄏㄞ":"表海",
"ㄅㄧㄠㄏㄠ":"標號",
"ㄅㄧㄠㄏㄤ":"標行",
"ㄅㄧㄠㄏㄨ":"裱糊",
"ㄅㄧㄠㄐㄧ":"標記/表記",
"ㄅㄧㄠㄐㄩ":"標舉/鏢局",
"ㄅㄧㄠㄑㄧ":"熛起/鏢旗",
"ㄅㄧㄠㄓㄠ":"表照",
"ㄅㄧㄠㄓㄢ":"標占",
"ㄅㄧㄠㄓㄣ":"錶針",
"ㄅㄧㄠㄓㄤ":"表彰/表章",
"ㄅㄧㄠㄓㄥ":"表證/表徵",
"ㄅㄧㄠㄓㄨ":"標注",
"ㄅㄧㄠㄔㄜ":"飆車",
"ㄅㄧㄠㄔㄣ":"飆塵",
"ㄅㄧㄠㄔㄤ":"表唱",
"ㄅㄧㄠㄔㄥ":"標程",
"ㄅㄧㄠㄕㄡ":"標首/標售",
"ㄅㄧㄠㄕㄥ":"表甥",
"ㄅㄧㄠㄕㄨ":"表述/表叔",
"ㄅㄧㄠㄗㄡ":"表奏",
"ㄅㄧㄠㄘㄥ":"表層",
"ㄅㄧㄠㄙㄢ":"俵散",
"ㄅㄧㄠㄧㄢ":"表演",
"ㄅㄧㄠㄧㄤ":"表揚",
"ㄅㄧㄠㄨㄟ":"彪蔚",
"ㄅㄧㄠㄨㄣ":"表文",
"ㄅㄧㄢㄅㄚ":"便罷",
"ㄅㄧㄢㄅㄛ":"辯駁/貶駁",
"ㄅㄧㄢㄅㄞ":"辯白/辨白",
"ㄅㄧㄢㄅㄟ":"鞭背/編貝",
"ㄅㄧㄢㄅㄠ":"邊報/變暴",
"ㄅㄧㄢㄅㄢ":"編班",
"ㄅㄧㄢㄅㄣ":"辨本",
"ㄅㄧㄢㄅㄧ":"邊鄙/便祕",
"ㄅㄧㄢㄅㄨ":"便不/便步",
"ㄅㄧㄢㄆㄛ":"邊坡/褊迫",
"ㄅㄧㄢㄆㄞ":"編排/編派",
"ㄅㄧㄢㄆㄟ":"編配",
"ㄅㄧㄢㄆㄠ":"鞭炮",
"ㄅㄧㄢㄆㄣ":"便盆",
"ㄅㄧㄢㄆㄤ":"邊旁",
"ㄅㄧㄢㄆㄨ":"編蒲/鞭扑",
"ㄅㄧㄢㄇㄚ":"編碼",
"ㄅㄧㄢㄇㄞ":"變賣",
"ㄅㄧㄢㄇㄠ":"便帽/鞭毛",
"ㄅㄧㄢㄇㄣ":"邊門/便門",
"ㄅㄧㄢㄇㄥ":"編氓",
"ㄅㄧㄢㄇㄨ":"編目/弁目",
"ㄅㄧㄢㄈㄚ":"變法/編髮",
"ㄅㄧㄢㄈㄢ":"便飯",
"ㄅㄧㄢㄈㄤ":"邊方/邊防",
"ㄅㄧㄢㄈㄥ":"變風",
"ㄅㄧㄢㄈㄨ":"蝙蝠/便服",
"ㄅㄧㄢㄉㄚ":"鞭打/鞭韃",
"ㄅㄧㄢㄉㄜ":"便得",
"ㄅㄧㄢㄉㄞ":"便袋/便待",
"ㄅㄧㄢㄉㄟ":"便得",
"ㄅㄧㄢㄉㄠ":"編導/便道",
"ㄅㄧㄢㄉㄡ":"辯鬥/扁豆",
"ㄅㄧㄢㄉㄢ":"扁擔/匾擔",
"ㄅㄧㄢㄉㄤ":"便當/便當",
"ㄅㄧㄢㄉㄧ":"遍地/貶低",
"ㄅㄧㄢㄉㄨ":"便毒",
"ㄅㄧㄢㄊㄚ":"鞭撻/猵獺",
"ㄅㄧㄢㄊㄞ":"變態/變泰",
"ㄅㄧㄢㄊㄠ":"扁桃",
"ㄅㄧㄢㄊㄡ":"邊頭",
"ㄅㄧㄢㄊㄧ":"變體/遍體",
"ㄅㄧㄢㄊㄨ":"邊土",
"ㄅㄧㄢㄋㄢ":"辯難",
"ㄅㄧㄢㄋㄥ":"褊能",
"ㄅㄧㄢㄌㄞ":"辯賴",
"ㄅㄧㄢㄌㄟ":"編類",
"ㄅㄧㄢㄌㄡ":"褊陋",
"ㄅㄧㄢㄌㄢ":"邊欄/便覽",
"ㄅㄧㄢㄌㄧ":"便利/變例",
"ㄅㄧㄢㄌㄨ":"邊罏/便路",
"ㄅㄧㄢㄍㄜ":"變革",
"ㄅㄧㄢㄍㄠ":"遍告",
"ㄅㄧㄢㄍㄥ":"變更",
"ㄅㄧㄢㄍㄨ":"變故/邊鼓",
"ㄅㄧㄢㄎㄜ":"便可/邊客",
"ㄅㄧㄢㄏㄜ":"卞和/辨覈",
"ㄅㄧㄢㄏㄠ":"編號/便好",
"ㄅㄧㄢㄏㄢ":"便函",
"ㄅㄧㄢㄏㄨ":"辯護/便壺",
"ㄅㄧㄢㄐㄧ":"編輯/邊際",
"ㄅㄧㄢㄐㄩ":"編劇/變局",
"ㄅㄧㄢㄑㄧ":"便器/編緝",
"ㄅㄧㄢㄑㄩ":"編曲/邊區",
"ㄅㄧㄢㄒㄧ":"便席/辯析",
"ㄅㄧㄢㄒㄩ":"便須",
"ㄅㄧㄢㄓㄚ":"變詐/匾扎",
"ㄅㄧㄢㄓㄜ":"編者/辨折",
"ㄅㄧㄢㄓㄞ":"邊寨/匾窄",
"ㄅㄧㄢㄓㄡ":"汴州",
"ㄅㄧㄢㄓㄣ":"砭鍼",
"ㄅㄧㄢㄓㄤ":"便章/辯章",
"ㄅㄧㄢㄓㄥ":"邊政/辨正",
"ㄅㄧㄢㄓㄨ":"編著",
"ㄅㄧㄢㄔㄜ":"便車",
"ㄅㄧㄢㄔㄢ":"變產",
"ㄅㄧㄢㄔㄣ":"邊塵",
"ㄅㄧㄢㄔㄥ":"變成/辯稱",
"ㄅㄧㄢㄔㄨ":"邊儲/遍處",
"ㄅㄧㄢㄕㄡ":"抃手",
"ㄅㄧㄢㄕㄢ":"辯贍",
"ㄅㄧㄢㄕㄣ":"變身/便身",
"ㄅㄧㄢㄕㄥ":"變聲/邊聲",
"ㄅㄧㄢㄕㄨ":"變數/編書",
"ㄅㄧㄢㄖㄣ":"辨認/便人",
"ㄅㄧㄢㄗㄜ":"變則/貶責",
"ㄅㄧㄢㄗㄠ":"變造/編造",
"ㄅㄧㄢㄗㄡ":"便走",
"ㄅㄧㄢㄗㄢ":"扁簪",
"ㄅㄧㄢㄗㄨ":"編組",
"ㄅㄧㄢㄘㄜ":"鞭策",
"ㄅㄧㄢㄘㄞ":"便菜/辯才",
"ㄅㄧㄢㄘㄢ":"便餐",
"ㄅㄧㄢㄙㄜ":"變色/辨色",
"ㄅㄧㄢㄙㄞ":"邊塞",
"ㄅㄧㄢㄙㄨ":"變速",
"ㄅㄧㄢㄧㄘ":"貶義詞",
"ㄅㄧㄢㄧㄚ":"變雅",
"ㄅㄧㄢㄧㄝ":"遍野/便液",
"ㄅㄧㄢㄧㄡ":"便郵",
"ㄅㄧㄢㄧㄢ":"邊沿/變顏",
"ㄅㄧㄢㄧㄣ":"編印/變因",
"ㄅㄧㄢㄧㄤ":"變樣",
"ㄅㄧㄢㄧㄥ":"便應",
"ㄅㄧㄢㄨㄟ":"辨味",
"ㄅㄧㄢㄨㄣ":"變文/變溫",
"ㄅㄧㄢㄩㄝ":"變約/忭躍",
"ㄅㄧㄢㄩㄢ":"邊緣/邊遠",
"ㄅㄧㄢㄩㄥ":"便用/抃踴",
"ㄅㄧㄣㄅㄞ":"賓白",
"ㄅㄧㄣㄆㄥ":"賓朋",
"ㄅㄧㄣㄇㄠ":"鬢毛",
"ㄅㄧㄣㄇㄥ":"賓萌",
"ㄅㄧㄣㄈㄚ":"鬢髮",
"ㄅㄧㄣㄈㄣ":"繽紛",
"ㄅㄧㄣㄈㄥ":"豳風",
"ㄅㄧㄣㄈㄨ":"賓服",
"ㄅㄧㄣㄉㄞ":"賓待",
"ㄅㄧㄣㄌㄤ":"賓郎/檳榔",
"ㄅㄧㄣㄌㄧ":"賓禮",
"ㄅㄧㄣㄌㄩ":"賓旅",
"ㄅㄧㄣㄍㄨ":"髕骨",
"ㄅㄧㄣㄎㄜ":"賓客",
"ㄅㄧㄣㄏㄞ":"濱海",
"ㄅㄧㄣㄏㄢ":"賓漢",
"ㄅㄧㄣㄑㄧ":"擯棄/賓器",
"ㄅㄧㄣㄓㄡ":"邠州",
"ㄅㄧㄣㄓㄨ":"賓主",
"ㄅㄧㄣㄔㄜ":"殯車",
"ㄅㄧㄣㄔㄨ":"擯除/擯黜",
"ㄅㄧㄣㄕㄜ":"殯舍",
"ㄅㄧㄣㄖㄨ":"邠如",
"ㄅㄧㄣㄗㄤ":"殯葬",
"ㄅㄧㄣㄗㄨ":"臏足",
"ㄅㄧㄣㄧㄡ":"賓友",
"ㄅㄧㄣㄧㄢ":"玢岩",
"ㄅㄧㄣㄨㄞ":"濱外",
"ㄅㄧㄣㄨㄟ":"瀕危/賓位",
"ㄅㄧㄣㄩㄣ":"鬢雲",
"ㄅㄧㄥㄅㄚ":"掤扒",
"ㄅㄧㄥㄅㄛ":"冰檗/冰櫱",
"ㄅㄧㄥㄅㄞ":"稟白",
"ㄅㄧㄥㄅㄟ":"兵備",
"ㄅㄧㄥㄅㄠ":"稟報/并包",
"ㄅㄧㄥㄅㄤ":"冰棒/并邦",
"ㄅㄧㄥㄅㄥ":"鞞琫",
"ㄅㄧㄥㄅㄧ":"秉筆/炳賁",
"ㄅㄧㄥㄅㄨ":"兵部/丙部",
"ㄅㄧㄥㄆㄞ":"並排/冰排",
"ㄅㄧㄥㄆㄟ":"並轡",
"ㄅㄧㄥㄆㄢ":"冰盤/冰泮",
"ㄅㄧㄥㄇㄚ":"並馬/兵馬",
"ㄅㄧㄥㄇㄛ":"病魔/病歿",
"ㄅㄧㄥㄇㄟ":"病媒",
"ㄅㄧㄥㄇㄠ":"病貓/冰帽",
"ㄅㄧㄥㄇㄨ":"病母",
"ㄅㄧㄥㄈㄚ":"兵法/病發",
"ㄅㄧㄥㄈㄟ":"並非/兵費",
"ㄅㄧㄥㄈㄤ":"病房",
"ㄅㄧㄥㄈㄥ":"冰封/冰峰",
"ㄅㄧㄥㄈㄨ":"病夫/兵符",
"ㄅㄧㄥㄉㄞ":"冰袋",
"ㄅㄧㄥㄉㄠ":"冰刀/并刀",
"ㄅㄧㄥㄉㄡ":"冰斗",
"ㄅㄧㄥㄉㄤ":"屏當/併當",
"ㄅㄧㄥㄉㄧ":"並蒂",
"ㄅㄧㄥㄉㄨ":"病毒/病篤",
"ㄅㄧㄥㄊㄚ":"病榻",
"ㄅㄧㄥㄊㄞ":"病態",
"ㄅㄧㄥㄊㄡ":"並頭",
"ㄅㄧㄥㄊㄢ":"冰炭",
"ㄅㄧㄥㄊㄤ":"冰糖",
"ㄅㄧㄥㄊㄧ":"病體",
"ㄅㄧㄥㄋㄢ":"兵難",
"ㄅㄧㄥㄋㄥ":"稟能",
"ㄅㄧㄥㄌㄥ":"冰冷",
"ㄅㄧㄥㄌㄧ":"兵力/病例",
"ㄅㄧㄥㄌㄩ":"栟櫚",
"ㄅㄧㄥㄍㄜ":"兵革/兵戈",
"ㄅㄧㄥㄍㄠ":"稟告/冰糕",
"ㄅㄧㄥㄍㄡ":"併購",
"ㄅㄧㄥㄍㄢ":"餅乾/栟柑",
"ㄅㄧㄥㄍㄣ":"病根",
"ㄅㄧㄥㄍㄨ":"病故/病骨",
"ㄅㄧㄥㄎㄜ":"併科",
"ㄅㄧㄥㄎㄡ":"稟叩",
"ㄅㄧㄥㄎㄨ":"病苦",
"ㄅㄧㄥㄏㄜ":"冰河",
"ㄅㄧㄥㄏㄞ":"病害",
"ㄅㄧㄥㄏㄠ":"病好/病號",
"ㄅㄧㄥㄏㄡ":"病候",
"ㄅㄧㄥㄏㄨ":"冰壺",
"ㄅㄧㄥㄐㄧ":"兵機/並跡",
"ㄅㄧㄥㄐㄩ":"並舉/病劇",
"ㄅㄧㄥㄑㄧ":"兵器/摒棄",
"ㄅㄧㄥㄑㄩ":"病去/並驅",
"ㄅㄧㄥㄒㄧ":"冰戲/屏息",
"ㄅㄧㄥㄓㄠ":"病兆",
"ㄅㄧㄥㄓㄡ":"并州",
"ㄅㄧㄥㄓㄣ":"冰鎮/冰枕",
"ㄅㄧㄥㄓㄤ":"兵仗/兵杖",
"ㄅㄧㄥㄓㄥ":"病徵/病症",
"ㄅㄧㄥㄓㄨ":"秉燭/炳著",
"ㄅㄧㄥㄔㄣ":"稟陳/柄臣",
"ㄅㄧㄥㄔㄥ":"秉承/病程",
"ㄅㄧㄥㄔㄨ":"摒除/並出",
"ㄅㄧㄥㄕㄜ":"病舍/丙舍",
"ㄅㄧㄥㄕㄡ":"稟受/並壽",
"ㄅㄧㄥㄕㄢ":"冰山",
"ㄅㄧㄥㄕㄨ":"兵書",
"ㄅㄧㄥㄖㄢ":"並然/炳然",
"ㄅㄧㄥㄖㄣ":"病人/冰人",
"ㄅㄧㄥㄖㄨ":"併入",
"ㄅㄧㄥㄗㄞ":"兵災",
"ㄅㄧㄥㄗㄠ":"病灶",
"ㄅㄧㄥㄗㄨ":"兵卒",
"ㄅㄧㄥㄘㄡ":"並輳",
"ㄅㄧㄥㄘㄢ":"冰蠶",
"ㄅㄧㄥㄘㄤ":"冰藏",
"ㄅㄧㄥㄧㄓ":"冰翼紙",
"ㄅㄧㄥㄧㄝ":"丙夜",
"ㄅㄧㄥㄧㄠ":"兵要/炳曜",
"ㄅㄧㄥㄧㄢ":"冰岩",
"ㄅㄧㄥㄧㄣ":"病因/餅銀",
"ㄅㄧㄥㄧㄤ":"冰洋",
"ㄅㄧㄥㄧㄥ":"兵營/屏營",
"ㄅㄧㄥㄨㄟ":"並未/病危",
"ㄅㄧㄥㄨㄢ":"冰紈/丙烷",
"ㄅㄧㄥㄨㄣ":"秉文",
"ㄅㄧㄥㄨㄤ":"病亡/并網",
"ㄅㄧㄥㄨㄥ":"冰翁",
"ㄅㄧㄥㄩㄝ":"冰月/寎月",
"ㄅㄧㄥㄩㄢ":"病院/病原",
"ㄅㄧㄥㄩㄥ":"並用/柄用",
"ㄅㄧㄧㄝㄕ":"畢業式",
"ㄅㄧㄨㄚㄗ":"鼻窪子",
"ㄅㄧㄩㄗㄢ":"碧玉簪",
"ㄅㄧㄩㄣㄙ":"碧雲寺",
"ㄅㄨㄅㄧㄠ":"不表",
"ㄅㄨㄅㄧㄢ":"不便/不變",
"ㄅㄨㄅㄧㄥ":"步兵",
"ㄅㄨㄆㄧㄠ":"補票",
"ㄅㄨㄆㄧㄣ":"補品/步頻",
"ㄅㄨㄆㄧㄥ":"不平",
"ㄅㄨㄇㄧㄝ":"不滅",
"ㄅㄨㄇㄧㄠ":"不妙",
"ㄅㄨㄇㄧㄡ":"不繆",
"ㄅㄨㄇㄧㄢ":"不免",
"ㄅㄨㄇㄧㄣ":"不敏/不泯",
"ㄅㄨㄇㄧㄥ":"不明/不瞑",
"ㄅㄨㄈㄤㄕ":"不妨事",
"ㄅㄨㄈㄤㄧ":"不放逸",
"ㄅㄨㄉㄚㄕ":"布達式",
"ㄅㄨㄉㄜㄓ":"不得志",
"ㄅㄨㄉㄜㄧ":"不得已",
"ㄅㄨㄉㄢㄗ":"布撣子",
"ㄅㄨㄉㄥㄕ":"不等式",
"ㄅㄨㄉㄧㄕ":"不抵事",
"ㄅㄨㄉㄧㄝ":"不迭",
"ㄅㄨㄉㄧㄠ":"不凋/不弔",
"ㄅㄨㄉㄧㄥ":"不定/補丁",
"ㄅㄨㄉㄨㄕ":"不賭時",
"ㄅㄨㄉㄨㄛ":"不多/卜度",
"ㄅㄨㄉㄨㄟ":"部隊/不對",
"ㄅㄨㄉㄨㄢ":"不斷/不短",
"ㄅㄨㄉㄨㄥ":"不懂",
"ㄅㄨㄊㄧㄝ":"補貼",
"ㄅㄨㄊㄧㄠ":"不祧/布條",
"ㄅㄨㄊㄧㄢ":"不腆/步天",
"ㄅㄨㄊㄧㄥ":"不停/不庭",
"ㄅㄨㄊㄨㄛ":"不托/不妥",
"ㄅㄨㄊㄨㄟ":"不退",
"ㄅㄨㄊㄨㄥ":"不同/不通",
"ㄅㄨㄋㄧㄢ":"卜年/步輦",
"ㄅㄨㄋㄧㄥ":"不寧/不佞",
"ㄅㄨㄌㄧㄕ":"不利市",
"ㄅㄨㄌㄧㄝ":"部列/布列",
"ㄅㄨㄌㄧㄠ":"不了/不料",
"ㄅㄨㄌㄧㄡ":"不留/不流",
"ㄅㄨㄌㄧㄢ":"布帘",
"ㄅㄨㄌㄧㄣ":"不吝/卜鄰",
"ㄅㄨㄌㄧㄤ":"不良/不量",
"ㄅㄨㄌㄧㄥ":"不令/不靈",
"ㄅㄨㄌㄨㄛ":"部落/不落",
"ㄅㄨㄌㄨㄢ":"不亂",
"ㄅㄨㄌㄨㄣ":"不論/不倫",
"ㄅㄨㄍㄨㄚ":"卜卦",
"ㄅㄨㄍㄨㄛ":"不過/不果",
"ㄅㄨㄍㄨㄞ":"不乖",
"ㄅㄨㄍㄨㄟ":"不軌/不歸",
"ㄅㄨㄍㄨㄢ":"不管/不關",
"ㄅㄨㄍㄨㄣ":"補袞",
"ㄅㄨㄍㄨㄤ":"不光/步光",
"ㄅㄨㄍㄨㄥ":"不公/不供",
"ㄅㄨㄎㄜㄓ":"不可知",
"ㄅㄨㄎㄜㄧ":"不可以",
"ㄅㄨㄎㄨㄞ":"不快/步快",
"ㄅㄨㄎㄨㄟ":"不愧/不虧",
"ㄅㄨㄎㄨㄤ":"不匡",
"ㄅㄨㄎㄨㄥ":"不空/補空",
"ㄅㄨㄏㄜㄕ":"不合式",
"ㄅㄨㄏㄞㄕ":"不害事",
"ㄅㄨㄏㄨㄚ":"補花/佈劃",
"ㄅㄨㄏㄨㄛ":"捕獲/不獲",
"ㄅㄨㄏㄨㄟ":"不會/部會",
"ㄅㄨㄏㄨㄢ":"不還/不患",
"ㄅㄨㄏㄨㄣ":"布婚",
"ㄅㄨㄏㄨㄤ":"不遑",
"ㄅㄨㄐㄧㄕ":"不濟事",
"ㄅㄨㄐㄧㄚ":"不假/不佳",
"ㄅㄨㄐㄧㄝ":"不解/不接",
"ㄅㄨㄐㄧㄠ":"不叫/布教",
"ㄅㄨㄐㄧㄡ":"不久/補救",
"ㄅㄨㄐㄧㄢ":"部件/不見",
"ㄅㄨㄐㄧㄣ":"不禁/不盡",
"ㄅㄨㄐㄧㄤ":"不將/部將",
"ㄅㄨㄐㄧㄥ":"不經/不競",
"ㄅㄨㄐㄩㄝ":"不絕/不覺",
"ㄅㄨㄐㄩㄢ":"不倦",
"ㄅㄨㄐㄩㄣ":"不均/步軍",
"ㄅㄨㄑㄧㄝ":"步砌",
"ㄅㄨㄑㄧㄠ":"不巧/逋峭",
"ㄅㄨㄑㄧㄢ":"不淺/不遷",
"ㄅㄨㄑㄧㄤ":"步槍/不強",
"ㄅㄨㄑㄧㄥ":"不情/不清",
"ㄅㄨㄑㄩㄝ":"不確/補缺",
"ㄅㄨㄑㄩㄢ":"不全",
"ㄅㄨㄑㄩㄣ":"不群",
"ㄅㄨㄒㄧㄚ":"不下/部下",
"ㄅㄨㄒㄧㄝ":"不屑/補血",
"ㄅㄨㄒㄧㄠ":"不孝/不肖",
"ㄅㄨㄒㄧㄡ":"不朽/不修",
"ㄅㄨㄒㄧㄢ":"不限/不嫌",
"ㄅㄨㄒㄧㄣ":"不信",
"ㄅㄨㄒㄧㄤ":"不祥/不詳",
"ㄅㄨㄒㄧㄥ":"不幸/步行",
"ㄅㄨㄒㄩㄢ":"補選/不宣",
"ㄅㄨㄒㄩㄣ":"不遜",
"ㄅㄨㄓㄅㄨ":"不織布",
"ㄅㄨㄓㄉㄜ":"不值得",
"ㄅㄨㄓㄉㄠ":"不知道",
"ㄅㄨㄓㄉㄤ":"不值當",
"ㄅㄨㄓㄑㄩ":"不知趣",
"ㄅㄨㄓㄕㄕ":"不知世事",
"ㄅㄨㄓㄕㄨ":"不知世務",
"ㄅㄨㄓㄗㄨ":"不知足",
"ㄅㄨㄓㄠㄦ":"布招兒",
"ㄅㄨㄓㄣㄧ":"不知恩義",
"ㄅㄨㄓㄥㄓ":"不正知",
"ㄅㄨㄓㄥㄕ":"布政使",
"ㄅㄨㄓㄥㄙ":"布政司",
"ㄅㄨㄓㄨㄛ":"捕捉/不著",
"ㄅㄨㄓㄨㄟ":"不墜/不贅",
"ㄅㄨㄓㄨㄢ":"不專",
"ㄅㄨㄓㄨㄣ":"不准/不準",
"ㄅㄨㄓㄨㄤ":"不指望",
"ㄅㄨㄓㄨㄥ":"不忠/不中",
"ㄅㄨㄓㄨㄧ":"補助翼",
"ㄅㄨㄔㄔㄨ":"步踟躕",
"ㄅㄨㄔㄥㄓ":"不稱職",
"ㄅㄨㄔㄥㄗ":"不成字",
"ㄅㄨㄔㄨㄛ":"不輟/餔啜",
"ㄅㄨㄔㄨㄞ":"不揣",
"ㄅㄨㄔㄨㄢ":"不傳",
"ㄅㄨㄔㄨㄥ":"補充",
"ㄅㄨㄕㄅㄨ":"不是不",
"ㄅㄨㄕㄈㄣ":"不十分",
"ㄅㄨㄕㄊㄡ":"不是頭",
"ㄅㄨㄕㄌㄨ":"不是路",
"ㄅㄨㄕㄎㄡ":"不釋口",
"ㄅㄨㄕㄑㄧ":"不識氣",
"ㄅㄨㄕㄓㄧ":"不世之藝",
"ㄅㄨㄕㄓㄨ":"不識之無",
"ㄅㄨㄕㄔㄨ":"不世出",
"ㄅㄨㄕㄕㄨ":"不識時務",
"ㄅㄨㄕㄖㄣ":"不是人",
"ㄅㄨㄕㄥㄧ":"不勝衣",
"ㄅㄨㄕㄧㄢ":"不食言",
"ㄅㄨㄕㄨㄖ":"不數日",
"ㄅㄨㄕㄨㄞ":"不衰/不摔",
"ㄅㄨㄕㄨㄟ":"不失為",
"ㄅㄨㄕㄨㄣ":"不順",
"ㄅㄨㄕㄨㄤ":"不爽",
"ㄅㄨㄖㄨㄛ":"不若/不弱",
"ㄅㄨㄖㄨㄣ":"不潤",
"ㄅㄨㄖㄨㄥ":"不容",
"ㄅㄨㄖㄨㄦ":"不入耳",
"ㄅㄨㄖㄨㄧ":"不如意",
"ㄅㄨㄗㄖㄢ":"不自然",
"ㄅㄨㄗㄗㄞ":"不自在",
"ㄅㄨㄗㄞㄧ":"不在意",
"ㄅㄨㄗㄧㄚ":"不自涯",
"ㄅㄨㄗㄨㄔ":"不足齒",
"ㄅㄨㄗㄨㄛ":"不作",
"ㄅㄨㄗㄨㄞ":"不自外",
"ㄅㄨㄗㄨㄣ":"不遵",
"ㄅㄨㄗㄨㄧ":"不足以",
"ㄅㄨㄘㄨㄛ":"不錯",
"ㄅㄨㄘㄨㄣ":"不存",
"ㄅㄨㄘㄨㄥ":"不從/部從",
"ㄅㄨㄙㄕㄢ":"不死山",
"ㄅㄨㄙㄕㄨ":"不死樹",
"ㄅㄨㄙㄘㄠ":"不死草",
"ㄅㄨㄙㄢㄙ":"不三思",
"ㄅㄨㄙㄧㄠ":"不死藥",
"ㄅㄨㄙㄨㄛ":"不索",
"ㄅㄨㄙㄨㄟ":"不遂",
"ㄅㄨㄙㄨㄢ":"不算/布算",
"ㄅㄨㄢㄈㄣ":"不安分",
"ㄅㄨㄢㄒㄧ":"不安席",
"ㄅㄨㄢㄕㄥ":"不安生",
"ㄅㄨㄢㄕㄨ":"不諳事務",
"ㄅㄨㄢㄩㄕ":"不安於室",
"ㄅㄨㄦㄇㄣ":"不二門",
"ㄅㄨㄧㄗㄨ":"布依族",
"ㄅㄨㄧㄢㄩ":"不言語",
"ㄅㄨㄧㄥㄓ":"捕蠅紙",
"ㄅㄨㄧㄧㄗ":"不易一字",
"ㄅㄨㄧㄧㄤ":"不一樣",
"ㄅㄨㄧㄨㄟ":"不以為",
"ㄅㄨㄨㄢㄕ":"不侮暗室",
"ㄅㄨㄨㄣㄓ":"布紋紙",
"ㄅㄨㄩㄉㄞ":"哺育袋",
"ㄅㄨㄩㄐㄩ":"不踰矩",
"ㄅㄨㄩㄓㄩ":"不虞之譽",
"ㄅㄨㄩㄢㄧ":"不願意",
"ㄆㄚㄅㄧㄗ":"趴鼻子",
"ㄆㄚㄅㄨㄕ":"怕不是",
"ㄆㄚㄊㄨㄟ":"爬推/扒推",
"ㄆㄚㄌㄨㄛ":"爬羅",
"ㄆㄚㄍㄜㄗ":"爬格子",
"ㄆㄚㄏㄨㄟ":"爬灰/扒灰",
"ㄆㄚㄐㄧㄝ":"爬櫛",
"ㄆㄚㄑㄧㄝ":"扒竊",
"ㄆㄚㄒㄧㄚ":"爬下/趴下",
"ㄆㄚㄒㄧㄡ":"怕羞",
"ㄆㄚㄒㄧㄥ":"爬行",
"ㄆㄚㄔㄨㄢ":"爬船",
"ㄆㄚㄔㄨㄥ":"爬蟲",
"ㄆㄚㄖㄣㄗ":"怕人子",
"ㄆㄚㄘㄨㄛ":"爬蹉",
"ㄆㄚㄙㄎㄚ":"帕斯卡",
"ㄆㄛㄅㄧㄠ":"破錶",
"ㄆㄛㄅㄨㄗ":"破布子",
"ㄆㄛㄆㄧㄝ":"坡撇",
"ㄆㄛㄇㄧㄝ":"破滅",
"ㄆㄛㄇㄧㄠ":"破廟",
"ㄆㄛㄇㄧㄢ":"破面",
"ㄆㄛㄉㄧㄠ":"破掉",
"ㄆㄛㄉㄨㄥ":"魄動/破洞",
"ㄆㄛㄊㄧㄗ":"破體字",
"ㄆㄛㄊㄧㄢ":"潑天/坡田",
"ㄆㄛㄊㄨㄛ":"陂陀",
"ㄆㄛㄋㄧㄤ":"婆娘",
"ㄆㄛㄌㄧㄝ":"破裂",
"ㄆㄛㄌㄧㄢ":"破臉",
"ㄆㄛㄌㄧㄣ":"迫臨",
"ㄆㄛㄌㄧㄥ":"迫令",
"ㄆㄛㄌㄨㄛ":"破落/哱囉",
"ㄆㄛㄌㄨㄢ":"破亂",
"ㄆㄛㄌㄨㄥ":"破籠",
"ㄆㄛㄍㄨㄚ":"破瓜",
"ㄆㄛㄎㄨㄥ":"破空",
"ㄆㄛㄏㄨㄚ":"破話",
"ㄆㄛㄏㄨㄛ":"破獲/潑火",
"ㄆㄛㄏㄨㄞ":"破壞",
"ㄆㄛㄐㄧㄚ":"婆家/破家",
"ㄆㄛㄐㄧㄝ":"破解/破戒",
"ㄆㄛㄐㄧㄠ":"潑腳",
"ㄆㄛㄐㄧㄡ":"破舊",
"ㄆㄛㄐㄧㄢ":"破鑑/潑賤",
"ㄆㄛㄐㄧㄣ":"迫近",
"ㄆㄛㄐㄧㄤ":"迫降",
"ㄆㄛㄐㄧㄥ":"破鏡",
"ㄆㄛㄑㄧㄝ":"迫切",
"ㄆㄛㄑㄧㄢ":"破慳",
"ㄆㄛㄑㄧㄣ":"破親",
"ㄆㄛㄒㄧㄚ":"破夏",
"ㄆㄛㄒㄧㄝ":"破鞋/迫脅",
"ㄆㄛㄒㄧㄠ":"破曉/醱酵",
"ㄆㄛㄒㄧㄢ":"坡仙",
"ㄆㄛㄒㄧㄣ":"婆心/叵信",
"ㄆㄛㄒㄧㄤ":"破相",
"ㄆㄛㄓㄨㄢ":"破賺",
"ㄆㄛㄕㄢㄙ":"破山寺",
"ㄆㄛㄕㄨㄛ":"破說/潑說",
"ㄆㄛㄕㄨㄟ":"破水/潑水",
"ㄆㄛㄗㄨㄛ":"潑做",
"ㄆㄛㄙㄤㄗ":"破嗓子",
"ㄆㄛㄙㄨㄛ":"婆娑",
"ㄆㄛㄙㄨㄟ":"破碎",
"ㄆㄛㄙㄨㄣ":"破損",
"ㄆㄛㄦㄑㄧ":"婆兒氣",
"ㄆㄛㄧㄢㄩ":"潑言語",
"ㄆㄛㄧㄣㄗ":"破音字",
"ㄆㄛㄨㄊㄨ":"潑無徒",
"ㄆㄞㄅㄧㄝ":"派別",
"ㄆㄞㄅㄧㄢ":"牌匾/排便",
"ㄆㄞㄅㄧㄣ":"排擯",
"ㄆㄞㄅㄧㄥ":"派兵",
"ㄆㄞㄆㄧㄠ":"牌票",
"ㄆㄞㄆㄧㄢ":"拍片",
"ㄆㄞㄇㄧㄢ":"牌面",
"ㄆㄞㄇㄧㄥ":"排名",
"ㄆㄞㄉㄚㄗ":"牌搭子",
"ㄆㄞㄉㄧㄠ":"排調",
"ㄆㄞㄉㄧㄥ":"排定",
"ㄆㄞㄉㄨㄟ":"排隊/派對",
"ㄆㄞㄊㄡㄦ":"排頭兒",
"ㄆㄞㄊㄧㄠ":"排調",
"ㄆㄞㄊㄨㄛ":"拍拖",
"ㄆㄞㄊㄨㄟ":"拍腿",
"ㄆㄞㄋㄧㄝ":"排捏",
"ㄆㄞㄋㄧㄠ":"排尿",
"ㄆㄞㄋㄩㄝ":"俳謔",
"ㄆㄞㄌㄧㄝ":"排列",
"ㄆㄞㄌㄧㄢ":"排練",
"ㄆㄞㄌㄧㄣ":"排鄰",
"ㄆㄞㄌㄨㄢ":"排卵",
"ㄆㄞㄍㄨㄢ":"排灌",
"ㄆㄞㄏㄨㄞ":"徘徊/俳佪",
"ㄆㄞㄐㄧㄚ":"排家/牌價",
"ㄆㄞㄐㄧㄝ":"排解",
"ㄆㄞㄐㄧㄡ":"牌九",
"ㄆㄞㄐㄩㄣ":"排軍/牌軍",
"ㄆㄞㄑㄧㄡ":"排球",
"ㄆㄞㄑㄧㄢ":"派遣/排遣",
"ㄆㄞㄑㄧㄤ":"排槍",
"ㄆㄞㄒㄧㄝ":"排洩/排泄",
"ㄆㄞㄒㄧㄠ":"俳笑/排簫",
"ㄆㄞㄒㄩㄢ":"排揎",
"ㄆㄞㄓㄅㄨ":"拍紙簿",
"ㄆㄞㄓㄨㄛ":"拍桌",
"ㄆㄞㄕㄥㄘ":"派生詞",
"ㄆㄞㄗㄑㄩ":"牌子曲",
"ㄆㄞㄗㄔㄜ":"排子車",
"ㄆㄞㄗㄨㄛ":"排迮",
"ㄆㄞㄙㄨㄛ":"拍索",
"ㄆㄞㄧㄓㄣ":"排蟻陣",
"ㄆㄟㄅㄧㄥ":"胚柄",
"ㄆㄟㄅㄨㄕ":"陪不是",
"ㄆㄟㄆㄧㄠ":"配票",
"ㄆㄟㄉㄚㄦ":"配搭兒",
"ㄆㄟㄉㄧㄠ":"陪弔",
"ㄆㄟㄉㄧㄢ":"配電/配殿",
"ㄆㄟㄉㄨㄟ":"配對",
"ㄆㄟㄉㄨㄣ":"陪敦/培敦",
"ㄆㄟㄊㄨㄥ":"陪同",
"ㄆㄟㄌㄧㄠ":"配料/坯料",
"ㄆㄟㄌㄧㄡ":"配流",
"ㄆㄟㄌㄧㄢ":"陪奩",
"ㄆㄟㄍㄨㄛ":"沛國",
"ㄆㄟㄍㄨㄤ":"配光",
"ㄆㄟㄍㄨㄥ":"沛公",
"ㄆㄟㄎㄨㄢ":"賠款",
"ㄆㄟㄏㄨㄚ":"陪話/賠話",
"ㄆㄟㄏㄨㄛ":"配貨",
"ㄆㄟㄏㄨㄟ":"裴回",
"ㄆㄟㄏㄨㄢ":"佩環",
"ㄆㄟㄐㄧㄚ":"陪嫁/賠嫁",
"ㄆㄟㄐㄧㄡ":"陪酒",
"ㄆㄟㄐㄧㄢ":"配件/佩劍",
"ㄆㄟㄐㄧㄣ":"賠盡",
"ㄆㄟㄐㄧㄥ":"配景/陪京",
"ㄆㄟㄐㄩㄣ":"配軍",
"ㄆㄟㄑㄧㄢ":"賠錢",
"ㄆㄟㄑㄧㄤ":"呸搶",
"ㄆㄟㄒㄧㄠ":"陪笑/賠笑",
"ㄆㄟㄒㄧㄡ":"裴秀",
"ㄆㄟㄒㄧㄢ":"佩弦/沛縣",
"ㄆㄟㄒㄧㄤ":"配享/配饗",
"ㄆㄟㄒㄩㄣ":"培訓",
"ㄆㄟㄓㄨㄤ":"陪裝",
"ㄆㄟㄓㄨㄥ":"配種",
"ㄆㄟㄕㄊㄜ":"陪士特",
"ㄆㄟㄕㄣㄓ":"陪審制",
"ㄆㄟㄗㄊㄧ":"配子體",
"ㄆㄟㄗㄋㄤ":"配子囊",
"ㄆㄟㄗㄨㄛ":"陪坐",
"ㄆㄟㄗㄨㄟ":"陪罪/賠罪",
"ㄆㄟㄙㄨㄛ":"配所",
"ㄆㄟㄙㄨㄥ":"配送/陪送",
"ㄆㄟㄧㄠㄕ":"配藥師",
"ㄆㄠㄅㄧㄠ":"跑表",
"ㄆㄠㄅㄧㄥ":"炮兵",
"ㄆㄠㄆㄧㄝ":"拋撇",
"ㄆㄠㄆㄧㄠ":"跑票",
"ㄆㄠㄆㄧㄢ":"跑片",
"ㄆㄠㄇㄛㄩ":"泡沫浴",
"ㄆㄠㄇㄧㄢ":"泡麵/泡棉",
"ㄆㄠㄉㄠㄦ":"跑道兒",
"ㄆㄠㄉㄧㄠ":"拋掉",
"ㄆㄠㄉㄧㄢ":"跑電",
"ㄆㄠㄉㄧㄥ":"庖丁/庖鼎",
"ㄆㄠㄉㄨㄛ":"拋躲/拋嚲",
"ㄆㄠㄊㄞㄗ":"跑檯子",
"ㄆㄠㄊㄧㄥ":"炮艇",
"ㄆㄠㄊㄨㄗ":"跑兔子",
"ㄆㄠㄊㄨㄛ":"拋堶",
"ㄆㄠㄊㄨㄟ":"跑腿",
"ㄆㄠㄊㄨㄥ":"炮銅/泡桐",
"ㄆㄠㄋㄧㄡ":"泡妞",
"ㄆㄠㄌㄧㄢ":"拋臉/炮煉",
"ㄆㄠㄌㄨㄛ":"炮烙/泡螺",
"ㄆㄠㄍㄨㄚ":"匏瓜/袍褂",
"ㄆㄠㄍㄨㄣ":"泡滾",
"ㄆㄠㄍㄨㄤ":"拋光",
"ㄆㄠㄎㄨㄥ":"拋空",
"ㄆㄠㄏㄨㄛ":"炮火/泡貨",
"ㄆㄠㄏㄨㄟ":"炮灰",
"ㄆㄠㄏㄨㄢ":"泡幻",
"ㄆㄠㄏㄨㄤ":"拋荒",
"ㄆㄠㄏㄨㄥ":"炮轟",
"ㄆㄠㄐㄧㄝ":"跑街",
"ㄆㄠㄐㄧㄢ":"炮艦",
"ㄆㄠㄐㄩㄝ":"匏爵",
"ㄆㄠㄑㄧㄥ":"拋清",
"ㄆㄠㄑㄩㄝ":"拋卻",
"ㄆㄠㄒㄧㄝ":"跑鞋",
"ㄆㄠㄒㄧㄠ":"咆哮/咆哮",
"ㄆㄠㄒㄧㄡ":"袍袖",
"ㄆㄠㄒㄧㄢ":"颮線",
"ㄆㄠㄒㄧㄤ":"砲響/拋箱",
"ㄆㄠㄒㄩㄢ":"泡漩",
"ㄆㄠㄔㄨㄢ":"跑船",
"ㄆㄠㄔㄨㄥ":"炮銃",
"ㄆㄠㄕㄨㄞ":"拋摔",
"ㄆㄠㄕㄨㄟ":"泡水",
"ㄆㄠㄗㄨㄣ":"匏尊/匏樽",
"ㄆㄠㄙㄨㄛ":"拋梭",
"ㄆㄠㄨㄈㄤ":"跑五方",
"ㄆㄡㄅㄧㄝ":"剖別",
"ㄆㄡㄇㄧㄢ":"剖面",
"ㄆㄡㄇㄧㄥ":"剖明",
"ㄆㄡㄉㄨㄢ":"剖斷",
"ㄆㄡㄍㄨㄚ":"剖剮",
"ㄆㄡㄏㄨㄛ":"剖豁",
"ㄆㄡㄐㄧㄝ":"剖解",
"ㄆㄡㄒㄧㄣ":"剖心",
"ㄆㄡㄕㄊㄨ":"剖視圖",
"ㄆㄢㄅㄧㄝ":"判別",
"ㄆㄢㄅㄧㄢ":"叛變",
"ㄆㄢㄅㄧㄣ":"潘鬢",
"ㄆㄢㄇㄧㄢ":"盤面",
"ㄆㄢㄇㄧㄥ":"拚命/判明",
"ㄆㄢㄉㄧㄢ":"盤點/盤店",
"ㄆㄢㄉㄧㄥ":"判定",
"ㄆㄢㄉㄨㄟ":"盤堆",
"ㄆㄢㄉㄨㄢ":"判斷",
"ㄆㄢㄉㄨㄣ":"盤蹲",
"ㄆㄢㄊㄧㄥ":"槃停",
"ㄆㄢㄊㄨㄛ":"盤陀",
"ㄆㄢㄊㄨㄟ":"盤腿",
"ㄆㄢㄋㄨㄥ":"盤弄",
"ㄆㄢㄌㄧㄡ":"攀留/扳留",
"ㄆㄢㄌㄧㄢ":"攀戀",
"ㄆㄢㄌㄨㄢ":"叛亂",
"ㄆㄢㄌㄨㄥ":"盤龍/蟠龍",
"ㄆㄢㄍㄠㄓ":"攀高枝",
"ㄆㄢㄍㄤㄗ":"盤槓子",
"ㄆㄢㄍㄨㄛ":"叛國",
"ㄆㄢㄍㄨㄟ":"攀桂",
"ㄆㄢㄍㄨㄢ":"判官",
"ㄆㄢㄍㄨㄥ":"攀供/泮宮",
"ㄆㄢㄏㄨㄚ":"盤話/攀話",
"ㄆㄢㄏㄨㄛ":"盤貨/盤獲",
"ㄆㄢㄏㄨㄢ":"般桓/判渙",
"ㄆㄢㄐㄧㄝ":"盤結/盤街",
"ㄆㄢㄐㄧㄠ":"判教/盤攪",
"ㄆㄢㄐㄧㄡ":"盤究",
"ㄆㄢㄐㄧㄢ":"鞶鑑/鞶鑒",
"ㄆㄢㄐㄩㄝ":"判決/拚絕",
"ㄆㄢㄐㄩㄣ":"叛軍",
"ㄆㄢㄑㄧㄡ":"盤球",
"ㄆㄢㄑㄧㄢ":"盼倩",
"ㄆㄢㄑㄧㄣ":"攀親",
"ㄆㄢㄑㄩㄝ":"拚卻",
"ㄆㄢㄒㄧㄚ":"攀下",
"ㄆㄢㄒㄧㄤ":"盤香",
"ㄆㄢㄒㄧㄥ":"判刑",
"ㄆㄢㄒㄩㄝ":"盤踅",
"ㄆㄢㄒㄩㄢ":"盤旋/槃旋",
"ㄆㄢㄒㄩㄥ":"攀胸",
"ㄆㄢㄓㄨㄢ":"盤饌",
"ㄆㄢㄔㄨㄢ":"盤川",
"ㄆㄢㄔㄨㄣ":"蟠螭紋",
"ㄆㄢㄕㄓㄢ":"盤石之安",
"ㄆㄢㄕㄨㄟ":"盤水/泮水",
"ㄆㄢㄗㄊㄡ":"盤子頭",
"ㄆㄢㄗㄨㄛ":"盤坐",
"ㄆㄢㄗㄨㄟ":"判罪",
"ㄆㄢㄘㄨㄛ":"盤錯",
"ㄆㄢㄘㄨㄣ":"盤存",
"ㄆㄢㄙㄨㄢ":"盤算",
"ㄆㄢㄙㄨㄣ":"盤飧",
"ㄆㄢㄙㄨㄥ":"盤松",
"ㄆㄣㄉㄧㄠ":"盆吊/盆弔",
"ㄆㄣㄊㄨㄥ":"噴桶",
"ㄆㄣㄏㄨㄚ":"盆花",
"ㄆㄣㄏㄨㄛ":"噴火",
"ㄆㄣㄐㄧㄢ":"噴濺",
"ㄆㄣㄐㄧㄥ":"盆景",
"ㄆㄣㄑㄧㄤ":"盆腔",
"ㄆㄣㄑㄩㄢ":"噴泉",
"ㄆㄣㄒㄧㄚ":"盆下",
"ㄆㄣㄒㄧㄤ":"噴香",
"ㄆㄣㄒㄩㄝ":"噴雪",
"ㄆㄣㄔㄏㄜ":"噴赤河",
"ㄆㄣㄕㄨㄟ":"噴水",
"ㄆㄣㄗㄨㄟ":"噴嘴",
"ㄆㄣㄨㄐㄧ":"噴霧機",
"ㄆㄣㄨㄑㄧ":"噴霧器",
"ㄆㄤㄅㄧㄢ":"旁邊/傍邊",
"ㄆㄤㄊㄡㄩ":"胖頭魚",
"ㄆㄤㄊㄧㄥ":"旁聽",
"ㄆㄤㄊㄨㄛ":"滂沱",
"ㄆㄤㄊㄨㄥ":"旁通/傍通",
"ㄆㄤㄋㄧㄡ":"旁紐",
"ㄆㄤㄋㄨㄢ":"龐暖",
"ㄆㄤㄍㄨㄢ":"旁觀/傍觀",
"ㄆㄤㄍㄨㄤ":"膀胱",
"ㄆㄤㄍㄨㄥ":"龐公",
"ㄆㄤㄏㄨㄤ":"徬徨/旁皇",
"ㄆㄤㄐㄧㄤ":"滂江",
"ㄆㄤㄐㄩㄢ":"龐涓",
"ㄆㄤㄑㄧㄡ":"旁求",
"ㄆㄤㄒㄧㄝ":"螃蟹",
"ㄆㄤㄒㄧㄣ":"旁心",
"ㄆㄤㄒㄧㄥ":"旁行",
"ㄆㄤㄒㄩㄣ":"旁訓",
"ㄆㄤㄓㄨㄢ":"旁轉",
"ㄆㄤㄔㄚㄦ":"旁岔兒",
"ㄆㄤㄗㄨㄛ":"旁坐",
"ㄆㄤㄗㄨㄣ":"旁尊/傍尊",
"ㄆㄤㄙㄆㄛ":"旁死魄",
"ㄆㄥㄅㄧㄣ":"蓬鬢",
"ㄆㄥㄅㄧㄥ":"椪餅",
"ㄆㄥㄇㄧㄝ":"烹滅",
"ㄆㄥㄇㄧㄢ":"碰面",
"ㄆㄥㄇㄧㄥ":"烹茗",
"ㄆㄥㄊㄧㄠ":"烹調",
"ㄆㄥㄊㄨㄢ":"鵬摶",
"ㄆㄥㄌㄧㄝ":"棚裂",
"ㄆㄥㄌㄧㄠ":"朋僚",
"ㄆㄥㄌㄧㄥ":"碰鈴",
"ㄆㄥㄌㄨㄢ":"蓬亂",
"ㄆㄥㄍㄨㄟ":"棚規",
"ㄆㄥㄍㄨㄢ":"棚館",
"ㄆㄥㄍㄨㄥ":"弸弓",
"ㄆㄥㄎㄨㄣ":"鵬鯤",
"ㄆㄥㄏㄨㄕ":"蓬戶士",
"ㄆㄥㄏㄨㄚ":"捧花/蟛螖",
"ㄆㄥㄏㄨㄥ":"砰訇",
"ㄆㄥㄐㄧㄚ":"棚架",
"ㄆㄥㄐㄧㄠ":"朋交",
"ㄆㄥㄐㄧㄡ":"朋酒",
"ㄆㄥㄐㄧㄢ":"碰見/朋奸",
"ㄆㄥㄐㄧㄣ":"碰勁",
"ㄆㄥㄐㄧㄤ":"棚匠",
"ㄆㄥㄐㄩㄝ":"捧角",
"ㄆㄥㄐㄩㄢ":"棚圈",
"ㄆㄥㄑㄧㄠ":"碰巧",
"ㄆㄥㄒㄧㄢ":"彭咸/彭縣",
"ㄆㄥㄒㄧㄣ":"捧心",
"ㄆㄥㄓㄓㄨ":"棚蜘蛛",
"ㄆㄥㄓㄨㄢ":"蓬轉",
"ㄆㄥㄓㄨㄤ":"碰撞",
"ㄆㄥㄓㄨㄥ":"碰鐘",
"ㄆㄥㄔㄨㄢ":"篷船",
"ㄆㄥㄔㄨㄤ":"蓬窗/篷窗",
"ㄆㄥㄕㄐㄩ":"蓬室居",
"ㄆㄥㄖㄨㄥ":"蓬茸",
"ㄆㄥㄘㄨㄥ":"朋從",
"ㄆㄥㄙㄨㄢ":"硼酸",
"ㄆㄥㄙㄨㄥ":"蓬鬆/髼鬆",
"ㄆㄧㄅㄚㄗ":"批八字",
"ㄆㄧㄅㄧㄝ":"脾鱉/脾憋",
"ㄆㄧㄅㄧㄠ":"擗摽",
"ㄆㄧㄅㄧㄢ":"丕變/皮鞭",
"ㄆㄧㄅㄧㄥ":"披秉",
"ㄆㄧㄆㄚㄩ":"琵琶魚",
"ㄆㄧㄆㄧㄝ":"披撇",
"ㄆㄧㄆㄧㄥ":"批評",
"ㄆㄧㄇㄧㄠ":"披秒",
"ㄆㄧㄇㄧㄡ":"紕繆",
"ㄆㄧㄇㄧㄢ":"皮面/劈面",
"ㄆㄧㄇㄧㄥ":"批命",
"ㄆㄧㄉㄧㄠ":"皮雕",
"ㄆㄧㄉㄧㄢ":"批點/僻典",
"ㄆㄧㄉㄨㄢ":"疋端",
"ㄆㄧㄉㄨㄣ":"疲頓/疲鈍",
"ㄆㄧㄊㄡㄗ":"劈頭子",
"ㄆㄧㄊㄧㄠ":"皮條/批條",
"ㄆㄧㄊㄧㄢ":"闢田",
"ㄆㄧㄊㄨㄟ":"劈腿",
"ㄆㄧㄊㄨㄥ":"郫筒",
"ㄆㄧㄌㄧㄝ":"丕烈/圮裂",
"ㄆㄧㄌㄧㄢ":"毗連/皮臉",
"ㄆㄧㄌㄧㄣ":"毗鄰",
"ㄆㄧㄌㄧㄤ":"批量",
"ㄆㄧㄌㄧㄥ":"丕靈/毗陵",
"ㄆㄧㄌㄧㄨ":"霹靂舞",
"ㄆㄧㄌㄨㄥ":"罷癃/疲癃",
"ㄆㄧㄌㄩㄝ":"擗掠",
"ㄆㄧㄍㄨㄚ":"披掛",
"ㄆㄧㄍㄨㄛ":"皮果",
"ㄆㄧㄍㄨㄢ":"皮冠",
"ㄆㄧㄎㄨㄞ":"痞塊",
"ㄆㄧㄎㄨㄣ":"疲困",
"ㄆㄧㄎㄨㄥ":"劈空/霹空",
"ㄆㄧㄏㄞㄗ":"皮孩子",
"ㄆㄧㄏㄨㄚ":"屁話/皮花",
"ㄆㄧㄏㄨㄛ":"被禍/脾火",
"ㄆㄧㄏㄨㄞ":"披懷",
"ㄆㄧㄏㄨㄟ":"批回/批迴",
"ㄆㄧㄏㄨㄣ":"皮婚",
"ㄆㄧㄏㄨㄤ":"皮黃/皮簧",
"ㄆㄧㄏㄨㄥ":"披紅",
"ㄆㄧㄐㄧㄚ":"痞家/被甲",
"ㄆㄧㄐㄧㄝ":"疲竭",
"ㄆㄧㄐㄧㄠ":"批較",
"ㄆㄧㄐㄧㄡ":"啤酒",
"ㄆㄧㄐㄧㄢ":"僻見/皮件",
"ㄆㄧㄐㄧㄣ":"披巾/披襟",
"ㄆㄧㄐㄧㄤ":"裨將/皮匠",
"ㄆㄧㄐㄧㄥ":"僻靜/僻淨",
"ㄆㄧㄐㄩㄝ":"圮絕",
"ㄆㄧㄐㄩㄢ":"疲倦/披卷",
"ㄆㄧㄑㄧㄡ":"皮球/皮裘",
"ㄆㄧㄑㄧㄣ":"匹禽",
"ㄆㄧㄒㄧㄚ":"皮匣",
"ㄆㄧㄒㄧㄝ":"皮鞋/辟邪",
"ㄆㄧㄒㄧㄠ":"皮笑/皮硝",
"ㄆㄧㄒㄧㄡ":"貔貅",
"ㄆㄧㄒㄧㄢ":"丕顯/郫縣",
"ㄆㄧㄒㄧㄣ":"披心",
"ㄆㄧㄒㄧㄤ":"皮相/皮箱",
"ㄆㄧㄒㄧㄥ":"脾性/批行",
"ㄆㄧㄒㄩㄝ":"皮靴/批削",
"ㄆㄧㄒㄩㄢ":"皮軒/披軒",
"ㄆㄧㄒㄩㄥ":"批胸/劈胸",
"ㄆㄧㄓㄈㄛ":"辟支佛",
"ㄆㄧㄓㄙㄨ":"皮質素",
"ㄆㄧㄓㄤㄦ":"皮掌兒",
"ㄆㄧㄓㄨㄢ":"批轉/擗轉",
"ㄆㄧㄓㄨㄣ":"批准",
"ㄆㄧㄓㄨㄥ":"皮重/脾腫",
"ㄆㄧㄔㄨㄟ":"披垂",
"ㄆㄧㄔㄨㄢ":"皮船",
"ㄆㄧㄕㄨㄟ":"皮水",
"ㄆㄧㄕㄨㄤ":"砒霜",
"ㄆㄧㄖㄨㄛ":"疲弱",
"ㄆㄧㄖㄨㄟ":"皮瑞",
"ㄆㄧㄖㄨㄢ":"罷軟/疲軟",
"ㄆㄧㄗㄨㄛ":"毗佐",
"ㄆㄧㄗㄨㄟ":"被罪",
"ㄆㄧㄙㄨㄥ":"皮鬆",
"ㄆㄧㄝㄅㄚ":"撇罷",
"ㄆㄧㄝㄈㄥ":"撇風",
"ㄆㄧㄝㄉㄚ":"撇打",
"ㄆㄧㄝㄉㄜ":"撇地",
"ㄆㄧㄝㄉㄧ":"瞥地",
"ㄆㄧㄝㄉㄨ":"瞥睹",
"ㄆㄧㄝㄌㄞ":"撇賴",
"ㄆㄧㄝㄌㄢ":"撇蘭",
"ㄆㄧㄝㄎㄞ":"撇開",
"ㄆㄧㄝㄎㄤ":"撇抗",
"ㄆㄧㄝㄏㄜ":"撇呵",
"ㄆㄧㄝㄑㄧ":"撇棄",
"ㄆㄧㄝㄕㄢ":"撇閃",
"ㄆㄧㄝㄖㄢ":"撇然/瞥然",
"ㄆㄧㄝㄙㄚ":"撇撒",
"ㄆㄧㄝㄧㄡ":"撇油",
"ㄆㄧㄝㄧㄢ":"瞥眼/撇演",
"ㄆㄧㄝㄧㄤ":"撇漾",
"ㄆㄧㄠㄅㄛ":"飄泊/漂泊",
"ㄆㄧㄠㄅㄞ":"漂白",
"ㄆㄧㄠㄅㄧ":"票臂",
"ㄆㄧㄠㄅㄨ":"漂布",
"ㄆㄧㄠㄆㄛ":"漂潑/瓢潑",
"ㄆㄧㄠㄆㄥ":"飄蓬",
"ㄆㄧㄠㄇㄛ":"漂沒",
"ㄆㄧㄠㄇㄟ":"摽梅",
"ㄆㄧㄠㄇㄨ":"漂母",
"ㄆㄧㄠㄈㄟ":"票匪/剽匪",
"ㄆㄧㄠㄈㄢ":"飄泛/漂泛",
"ㄆㄧㄠㄈㄤ":"票房",
"ㄆㄧㄠㄈㄥ":"飄風",
"ㄆㄧㄠㄈㄨ":"飄浮/漂浮",
"ㄆㄧㄠㄉㄞ":"飄帶",
"ㄆㄧㄠㄉㄢ":"瓢簞",
"ㄆㄧㄠㄉㄤ":"飄蕩/漂蕩",
"ㄆㄧㄠㄉㄨ":"嫖賭",
"ㄆㄧㄠㄋㄤ":"縹囊",
"ㄆㄧㄠㄍㄣ":"票根",
"ㄆㄧㄠㄎㄜ":"嫖客",
"ㄆㄧㄠㄎㄞ":"飄開",
"ㄆㄧㄠㄏㄠ":"票號",
"ㄆㄧㄠㄏㄢ":"剽悍/慓悍",
"ㄆㄧㄠㄏㄨ":"飄忽",
"ㄆㄧㄠㄐㄧ":"嫖妓/飄疾",
"ㄆㄧㄠㄐㄩ":"票據/飄舉",
"ㄆㄧㄠㄑㄩ":"剽取",
"ㄆㄧㄠㄒㄧ":"票戲/漂洗",
"ㄆㄧㄠㄒㄩ":"漂絮",
"ㄆㄧㄠㄓㄤ":"漂帳",
"ㄆㄧㄠㄔㄣ":"飄塵",
"ㄆㄧㄠㄔㄤ":"嘌唱/嫖娼",
"ㄆㄧㄠㄕㄚ":"漂沙",
"ㄆㄧㄠㄕㄡ":"剽首",
"ㄆㄧㄠㄕㄨ":"票數",
"ㄆㄧㄠㄖㄢ":"漂染/飄然",
"ㄆㄧㄠㄗㄟ":"剽賊",
"ㄆㄧㄠㄙㄚ":"飄灑",
"ㄆㄧㄠㄙㄢ":"飄散",
"ㄆㄧㄠㄧㄠ":"票姚/飄搖",
"ㄆㄧㄠㄧㄡ":"票友/嫖友",
"ㄆㄧㄠㄧㄤ":"飄揚/飄洋",
"ㄆㄧㄠㄩㄢ":"票源",
"ㄆㄧㄢㄅㄟ":"偏背/偏倍",
"ㄆㄧㄢㄅㄧ":"駢比/便嬖",
"ㄆㄧㄢㄆㄛ":"偏頗",
"ㄆㄧㄢㄆㄟ":"偏陪",
"ㄆㄧㄢㄆㄤ":"偏旁",
"ㄆㄧㄢㄆㄧ":"偏僻/便辟",
"ㄆㄧㄢㄇㄚ":"騙馬",
"ㄆㄧㄢㄇㄣ":"偏門",
"ㄆㄧㄢㄇㄤ":"偏盲",
"ㄆㄧㄢㄇㄨ":"篇目",
"ㄆㄧㄢㄈㄟ":"偏廢",
"ㄆㄧㄢㄈㄢ":"偏反/翩翻",
"ㄆㄧㄢㄈㄤ":"偏方/偏房",
"ㄆㄧㄢㄈㄥ":"偏鋒",
"ㄆㄧㄢㄈㄨ":"篇幅/駢賦",
"ㄆㄧㄢㄉㄞ":"偏待",
"ㄆㄧㄢㄉㄤ":"偏宕",
"ㄆㄧㄢㄉㄧ":"偏低",
"ㄆㄧㄢㄊㄡ":"片頭",
"ㄆㄧㄢㄊㄢ":"偏袒/偏癱",
"ㄆㄧㄢㄊㄥ":"偏疼",
"ㄆㄧㄢㄊㄧ":"偏題/偏提",
"ㄆㄧㄢㄊㄨ":"騙徒",
"ㄆㄧㄢㄋㄢ":"楩楠",
"ㄆㄧㄢㄌㄚ":"偏拉",
"ㄆㄧㄢㄌㄠ":"偏勞",
"ㄆㄧㄢㄌㄢ":"偏濫",
"ㄆㄧㄢㄌㄧ":"偏離/駢儷",
"ㄆㄧㄢㄍㄜ":"偏格",
"ㄆㄧㄢㄍㄠ":"偏高",
"ㄆㄧㄢㄎㄜ":"片刻/偏科",
"ㄆㄧㄢㄎㄨ":"偏枯",
"ㄆㄧㄢㄏㄞ":"篇海",
"ㄆㄧㄢㄏㄠ":"偏好",
"ㄆㄧㄢㄏㄨ":"偏護",
"ㄆㄧㄢㄐㄧ":"偏激/遍及",
"ㄆㄧㄢㄐㄩ":"騙局/猵狙",
"ㄆㄧㄢㄑㄧ":"偏棲/駢枝",
"ㄆㄧㄢㄑㄩ":"騙取",
"ㄆㄧㄢㄒㄧ":"偏析",
"ㄆㄧㄢㄒㄩ":"片許/萹蓄",
"ㄆㄧㄢㄓㄡ":"扁舟",
"ㄆㄧㄢㄓㄣ":"偏振",
"ㄆㄧㄢㄓㄤ":"篇章/平章",
"ㄆㄧㄢㄔㄚ":"偏差",
"ㄆㄧㄢㄔㄤ":"片長/片場",
"ㄆㄧㄢㄔㄨ":"偏出/偏處",
"ㄆㄧㄢㄕㄚ":"片霎",
"ㄆㄧㄢㄕㄡ":"偏手",
"ㄆㄧㄢㄕㄢ":"片善/偏閃",
"ㄆㄧㄢㄕㄤ":"片商/片晌",
"ㄆㄧㄢㄕㄥ":"偏生",
"ㄆㄧㄢㄕㄨ":"騙術",
"ㄆㄧㄢㄖㄢ":"翩然",
"ㄆㄧㄢㄖㄣ":"騙人",
"ㄆㄧㄢㄘㄞ":"偏才/騙財",
"ㄆㄧㄢㄧㄚ":"偏壓",
"ㄆㄧㄢㄧㄝ":"篇頁",
"ㄆㄧㄢㄧㄠ":"偏要",
"ㄆㄧㄢㄧㄢ":"片言/便言",
"ㄆㄧㄢㄨㄣ":"駢文",
"ㄆㄧㄢㄩㄢ":"偏遠",
"ㄆㄧㄣㄅㄢ":"拼版",
"ㄆㄧㄣㄆㄚ":"拼扒",
"ㄆㄧㄣㄆㄛ":"頻婆",
"ㄆㄧㄣㄆㄞ":"品牌",
"ㄆㄧㄣㄆㄢ":"拼盤",
"ㄆㄧㄣㄇㄟ":"顰眉",
"ㄆㄧㄣㄇㄠ":"品貌",
"ㄆㄧㄣㄇㄨ":"品目/牝牡",
"ㄆㄧㄣㄈㄚ":"貧乏/拼法",
"ㄆㄧㄣㄈㄟ":"嬪妃",
"ㄆㄧㄣㄈㄢ":"頻繁/頻煩",
"ㄆㄧㄣㄈㄥ":"蘋風",
"ㄆㄧㄣㄈㄨ":"品服/嬪婦",
"ㄆㄧㄣㄉㄜ":"品德",
"ㄆㄧㄣㄉㄠ":"頻道/貧道",
"ㄆㄧㄣㄉㄧ":"品第",
"ㄆㄧㄣㄊㄞ":"貧胎",
"ㄆㄧㄣㄊㄡ":"姘頭",
"ㄆㄧㄣㄊㄧ":"品題",
"ㄆㄧㄣㄊㄨ":"拼圖",
"ㄆㄧㄣㄋㄢ":"貧難",
"ㄆㄧㄣㄌㄟ":"品類",
"ㄆㄧㄣㄌㄧ":"品理/品例",
"ㄆㄧㄣㄌㄩ":"頻率/品綠",
"ㄆㄧㄣㄍㄜ":"品格",
"ㄆㄧㄣㄍㄨ":"聘僱",
"ㄆㄧㄣㄎㄨ":"貧苦",
"ㄆㄧㄣㄏㄢ":"貧寒",
"ㄆㄧㄣㄏㄨ":"貧戶/牝戶",
"ㄆㄧㄣㄐㄧ":"品級/拼集",
"ㄆㄧㄣㄐㄩ":"姘居/貧窶",
"ㄆㄧㄣㄑㄧ":"貧氣",
"ㄆㄧㄣㄓㄠ":"聘召",
"ㄆㄧㄣㄓㄨ":"蠙珠",
"ㄆㄧㄣㄔㄚ":"品茶",
"ㄆㄧㄣㄔㄠ":"牝朝",
"ㄆㄧㄣㄔㄤ":"品嘗/品嚐",
"ㄆㄧㄣㄕㄣ":"顰伸/嚬呻",
"ㄆㄧㄣㄕㄨ":"聘書/品庶",
"ㄆㄧㄣㄖㄣ":"聘任",
"ㄆㄧㄣㄖㄥ":"頻仍",
"ㄆㄧㄣㄗㄠ":"品藻",
"ㄆㄧㄣㄗㄨ":"拼組",
"ㄆㄧㄣㄘㄡ":"拼湊",
"ㄆㄧㄣㄘㄨ":"顰蹙/嚬蹙",
"ㄆㄧㄣㄙㄥ":"貧僧",
"ㄆㄧㄣㄧㄝ":"蘋葉",
"ㄆㄧㄣㄧㄣ":"拼音",
"ㄆㄧㄣㄨㄟ":"品味/品位",
"ㄆㄧㄣㄨㄣ":"聘問",
"ㄆㄧㄣㄨㄤ":"品望",
"ㄆㄧㄣㄩㄝ":"品月",
"ㄆㄧㄣㄩㄥ":"聘用",
"ㄆㄧㄥㄅㄚ":"評詙/評跋",
"ㄆㄧㄥㄅㄛ":"評薄/評泊",
"ㄆㄧㄥㄅㄞ":"平白",
"ㄆㄧㄥㄅㄟ":"平輩",
"ㄆㄧㄥㄅㄠ":"平鉋",
"ㄆㄧㄥㄅㄢ":"平板/平半",
"ㄆㄧㄥㄅㄧ":"評比/屏蔽",
"ㄆㄧㄥㄅㄨ":"平補",
"ㄆㄧㄥㄆㄛ":"蘋婆",
"ㄆㄧㄥㄆㄢ":"評判",
"ㄆㄧㄥㄆㄤ":"乒乓",
"ㄆㄧㄥㄆㄥ":"屏蓬/萍蓬",
"ㄆㄧㄥㄆㄨ":"平鋪",
"ㄆㄧㄥㄇㄞ":"平脈/評脈",
"ㄆㄧㄥㄇㄣ":"屏門",
"ㄆㄧㄥㄇㄤ":"平莽",
"ㄆㄧㄥㄇㄥ":"帡幪",
"ㄆㄧㄥㄇㄨ":"屏幕",
"ㄆㄧㄥㄈㄢ":"平凡/平反",
"ㄆㄧㄥㄈㄣ":"評分/平分",
"ㄆㄧㄥㄈㄤ":"平方/平放",
"ㄆㄧㄥㄈㄥ":"屏風",
"ㄆㄧㄥㄈㄨ":"平伏/平復",
"ㄆㄧㄥㄉㄢ":"平淡/憑單",
"ㄆㄧㄥㄉㄥ":"平等",
"ㄆㄧㄥㄉㄧ":"平地/平底",
"ㄆㄧㄥㄊㄞ":"平抬/平臺",
"ㄆㄧㄥㄊㄡ":"平頭",
"ㄆㄧㄥㄊㄢ":"平坦/評彈",
"ㄆㄧㄥㄊㄤ":"平躺",
"ㄆㄧㄥㄌㄜ":"平樂",
"ㄆㄧㄥㄌㄢ":"憑欄/憑闌",
"ㄆㄧㄥㄌㄧ":"評理/鵧鷑",
"ㄆㄧㄥㄌㄨ":"平路/平陸",
"ㄆㄧㄥㄍㄠ":"憑高/平皋",
"ㄆㄧㄥㄍㄥ":"萍梗",
"ㄆㄧㄥㄍㄨ":"評估/平沽",
"ㄆㄧㄥㄎㄡ":"瓶口/平寇",
"ㄆㄧㄥㄎㄤ":"平康",
"ㄆㄧㄥㄏㄜ":"平和/馮河",
"ㄆㄧㄥㄏㄢ":"屏翰",
"ㄆㄧㄥㄏㄥ":"平衡",
"ㄆㄧㄥㄏㄨ":"平胡",
"ㄆㄧㄥㄐㄧ":"萍寄",
"ㄆㄧㄥㄐㄩ":"平劇/平局",
"ㄆㄧㄥㄒㄧ":"平息/平西",
"ㄆㄧㄥㄒㄩ":"憑虛/馮虛",
"ㄆㄧㄥㄓㄜ":"憑著/憑摺",
"ㄆㄧㄥㄓㄠ":"憑照",
"ㄆㄧㄥㄓㄢ":"平展",
"ㄆㄧㄥㄓㄤ":"屏障/平章",
"ㄆㄧㄥㄓㄥ":"平整/平正",
"ㄆㄧㄥㄓㄨ":"評注",
"ㄆㄧㄥㄔㄜ":"平車",
"ㄆㄧㄥㄔㄡ":"平疇",
"ㄆㄧㄥㄔㄤ":"平常/坪場",
"ㄆㄧㄥㄔㄨ":"平楚",
"ㄆㄧㄥㄕㄡ":"平手",
"ㄆㄧㄥㄕㄢ":"平善/屏山",
"ㄆㄧㄥㄕㄣ":"評審/平身",
"ㄆㄧㄥㄕㄥ":"平聲/平生",
"ㄆㄧㄥㄕㄨ":"評述/評書",
"ㄆㄧㄥㄖㄣ":"平人",
"ㄆㄧㄥㄖㄤ":"平壤",
"ㄆㄧㄥㄗㄜ":"平仄",
"ㄆㄧㄥㄗㄨ":"平足",
"ㄆㄧㄥㄘㄠ":"平槽",
"ㄆㄧㄥㄘㄡ":"軿湊",
"ㄆㄧㄥㄙㄜ":"平色",
"ㄆㄧㄥㄙㄞ":"瓶塞",
"ㄆㄧㄥㄙㄨ":"平素",
"ㄆㄧㄥㄧㄝ":"平野",
"ㄆㄧㄥㄧㄡ":"平郵",
"ㄆㄧㄥㄧㄢ":"平衍/憑驗",
"ㄆㄧㄥㄧㄤ":"平陽",
"ㄆㄧㄥㄧㄥ":"苹縈",
"ㄆㄧㄥㄨㄛ":"平臥",
"ㄆㄧㄥㄨㄟ":"屏幃",
"ㄆㄧㄥㄨㄣ":"平穩/平文",
"ㄆㄧㄥㄨㄤ":"平王",
"ㄆㄧㄥㄩㄝ":"平月/評閱",
"ㄆㄧㄥㄩㄢ":"平原",
"ㄆㄧㄥㄩㄣ":"平韻/平允",
"ㄆㄧㄥㄩㄥ":"平庸",
"ㄆㄧㄧㄇㄨ":"皮翼目",
"ㄆㄧㄧㄕㄡ":"皮翼獸",
"ㄆㄧㄧㄕㄨ":"皮以書",
"ㄆㄨㄅㄠㄦ":"蒲包兒",
"ㄆㄨㄅㄧㄠ":"譜表",
"ㄆㄨㄅㄧㄢ":"普遍/蒲鞭",
"ㄆㄨㄅㄧㄥ":"鋪兵",
"ㄆㄨㄆㄧㄥ":"鋪平",
"ㄆㄨㄇㄧㄝ":"撲滅",
"ㄆㄨㄇㄧㄢ":"撲面/鋪面",
"ㄆㄨㄇㄧㄩ":"普米語",
"ㄆㄨㄉㄥㄜ":"撲燈蛾",
"ㄆㄨㄉㄧㄝ":"撲跌/譜牒",
"ㄆㄨㄉㄧㄢ":"鋪墊",
"ㄆㄨㄉㄨㄟ":"撲堆",
"ㄆㄨㄉㄨㄣ":"樸鈍",
"ㄆㄨㄉㄨㄥ":"浦東/撲咚",
"ㄆㄨㄊㄠㄗ":"葡萄紫",
"ㄆㄨㄊㄧㄗ":"菩提子",
"ㄆㄨㄊㄨㄛ":"普陀",
"ㄆㄨㄊㄨㄢ":"蒲團",
"ㄆㄨㄊㄨㄥ":"普通/撲同",
"ㄆㄨㄌㄧㄡ":"蒲柳/浦柳",
"ㄆㄨㄌㄧㄢ":"撲臉",
"ㄆㄨㄌㄨㄕ":"鋪路石",
"ㄆㄨㄌㄨㄛ":"普羅/撲落",
"ㄆㄨㄌㄨㄣ":"蒲輪",
"ㄆㄨㄍㄨㄚ":"蒲瓜",
"ㄆㄨㄍㄨㄟ":"鋪規",
"ㄆㄨㄍㄨㄢ":"蒲關/鋪觀",
"ㄆㄨㄍㄨㄤ":"曝光/普光",
"ㄆㄨㄎㄨㄟ":"蒲葵",
"ㄆㄨㄎㄨㄥ":"撲空",
"ㄆㄨㄏㄨㄚ":"撲花",
"ㄆㄨㄏㄨㄛ":"鋪夥",
"ㄆㄨㄏㄨㄦ":"撲虎兒",
"ㄆㄨㄐㄧㄙ":"普濟寺",
"ㄆㄨㄐㄧㄚ":"鋪家/普浹",
"ㄆㄨㄐㄧㄝ":"鋪結/蒲節",
"ㄆㄨㄐㄧㄡ":"普救/撲救",
"ㄆㄨㄐㄧㄢ":"蒲劍",
"ㄆㄨㄐㄧㄣ":"蒲津",
"ㄆㄨㄐㄧㄤ":"普獎",
"ㄆㄨㄐㄩㄢ":"鋪捐",
"ㄆㄨㄑㄧㄥ":"普請",
"ㄆㄨㄒㄧㄝ":"譜寫/蒲鞋",
"ㄆㄨㄒㄧㄢ":"普賢/曝獻",
"ㄆㄨㄒㄧㄥ":"暴行",
"ㄆㄨㄒㄩㄝ":"譜學/樸學",
"ㄆㄨㄒㄩㄢ":"普選",
"ㄆㄨㄓㄨㄛ":"樸拙",
"ㄆㄨㄓㄨㄥ":"樸重/朴忠",
"ㄆㄨㄔㄨㄤ":"鋪床",
"ㄆㄨㄕㄊㄡ":"朴實頭",
"ㄆㄨㄕㄨㄛ":"撲朔",
"ㄆㄨㄕㄨㄟ":"撲水",
"ㄆㄨㄕㄩㄕ":"暴屍於市",
"ㄆㄨㄖㄨㄥ":"蒲絨",
"ㄆㄨㄗㄨㄥ":"僕從",
"ㄆㄨㄙㄨㄛ":"撲索",
"ㄆㄨㄢㄓㄡ":"普庵咒",
"ㄆㄨㄦㄔㄚ":"普洱茶",
"ㄆㄨㄨㄛㄗ":"蒲窩子",
"ㄇㄚㄅㄧㄝ":"馬鱉",
"ㄇㄚㄅㄧㄠ":"馬表",
"ㄇㄚㄅㄧㄢ":"馬扁/馬鞭",
"ㄇㄚㄅㄧㄥ":"馬兵/麻餅",
"ㄇㄚㄆㄞㄗ":"馬牌子",
"ㄇㄚㄆㄧㄠ":"馬票",
"ㄇㄚㄇㄧㄢ":"馬面",
"ㄇㄚㄇㄧㄥ":"罵名/馬鳴",
"ㄇㄚㄈㄢㄗ":"馬販子",
"ㄇㄚㄈㄣㄓ":"馬糞紙",
"ㄇㄚㄉㄚㄗ":"馬褡子",
"ㄇㄚㄉㄠㄧ":"馬道驛",
"ㄇㄚㄉㄧㄠ":"馬調/馬吊",
"ㄇㄚㄉㄧㄢ":"麻點/馬店",
"ㄇㄚㄉㄨㄟ":"馬隊",
"ㄇㄚㄊㄨㄢ":"麻團",
"ㄇㄚㄊㄨㄥ":"馬桶/馬通",
"ㄇㄚㄋㄠㄗ":"馬撓子",
"ㄇㄚㄌㄟㄗ":"麻雷子",
"ㄇㄚㄌㄧㄝ":"馬鬣",
"ㄇㄚㄌㄧㄡ":"馬流/馬留",
"ㄇㄚㄌㄧㄢ":"麻臉",
"ㄇㄚㄌㄧㄤ":"馬良",
"ㄇㄚㄌㄨㄛ":"馬騾",
"ㄇㄚㄍㄨㄚ":"馬褂",
"ㄇㄚㄍㄨㄢ":"馬關/馬冠",
"ㄇㄚㄎㄨㄞ":"馬快",
"ㄇㄚㄏㄨㄗ":"馬虎子",
"ㄇㄚㄏㄨㄚ":"麻花",
"ㄇㄚㄏㄨㄣ":"麻婚",
"ㄇㄚㄏㄨㄤ":"麻黃/馬蟥",
"ㄇㄚㄐㄧㄚ":"馬架/罵架",
"ㄇㄚㄐㄧㄝ":"罵街/麻秸",
"ㄇㄚㄐㄧㄠ":"馬腳",
"ㄇㄚㄐㄧㄡ":"馬廄",
"ㄇㄚㄐㄧㄤ":"麻將/馬將",
"ㄇㄚㄐㄩㄢ":"馬圈/馬蠲",
"ㄇㄚㄐㄩㄣ":"馬軍",
"ㄇㄚㄑㄧㄡ":"馬球",
"ㄇㄚㄑㄧㄤ":"馬槍",
"ㄇㄚㄑㄩㄝ":"麻雀",
"ㄇㄚㄒㄧㄝ":"麻鞋",
"ㄇㄚㄒㄧㄢ":"麻線/馬銜",
"ㄇㄚㄒㄩㄝ":"馬靴",
"ㄇㄚㄒㄩㄥ":"馬熊",
"ㄇㄚㄓㄚㄗ":"馬閘子",
"ㄇㄚㄓㄨㄤ":"馬樁",
"ㄇㄚㄓㄩㄢ":"馬致遠",
"ㄇㄚㄔㄉㄡ":"馬齒豆",
"ㄇㄚㄔㄔㄤ":"馬齒長",
"ㄇㄚㄔㄨㄟ":"麻槌",
"ㄇㄚㄔㄨㄢ":"馬船",
"ㄇㄚㄕㄉㄜ":"馬是得",
"ㄇㄚㄖㄨㄥ":"馬融",
"ㄇㄚㄗㄍㄞ":"馬子蓋",
"ㄇㄚㄗㄨㄛ":"罵坐",
"ㄇㄚㄗㄨㄟ":"麻醉/麻嘴",
"ㄇㄚㄗㄨㄥ":"馬鬃",
"ㄇㄚㄢㄊㄥ":"馬鞍藤",
"ㄇㄚㄢㄕㄢ":"馬鞍山",
"ㄇㄚㄧㄓㄞ":"麻衣債",
"ㄇㄚㄧㄨㄛ":"螞蟻窩",
"ㄇㄛㄅㄛㄕ":"磨博士",
"ㄇㄛㄅㄛㄗ":"抹脖子",
"ㄇㄛㄅㄧㄠ":"模表",
"ㄇㄛㄅㄨㄕ":"莫不是",
"ㄇㄛㄆㄧㄢ":"默片/抹片",
"ㄇㄛㄆㄧㄥ":"抹平/磨平",
"ㄇㄛㄇㄧㄝ":"磨滅/抹滅",
"ㄇㄛㄇㄧㄠ":"墨妙",
"ㄇㄛㄇㄧㄢ":"抹面",
"ㄇㄛㄇㄧㄣ":"末民",
"ㄇㄛㄇㄧㄥ":"莫名/末命",
"ㄇㄛㄉㄠㄕ":"磨刀石",
"ㄇㄛㄉㄡㄩ":"墨斗魚",
"ㄇㄛㄉㄤㄗ":"墨盪子",
"ㄇㄛㄉㄧㄝ":"墨蝶/墨絰",
"ㄇㄛㄉㄧㄡ":"抹丟/抹颩",
"ㄇㄛㄉㄧㄢ":"魔電",
"ㄇㄛㄉㄧㄥ":"沒頂/摩頂",
"ㄇㄛㄉㄨㄟ":"磨對/磨兌",
"ㄇㄛㄉㄨㄢ":"末端",
"ㄇㄛㄉㄨㄣ":"摩敦",
"ㄇㄛㄊㄜㄦ":"模特兒",
"ㄇㄛㄊㄧㄝ":"抹貼/摹帖",
"ㄇㄛㄊㄧㄢ":"摩天",
"ㄇㄛㄊㄨㄛ":"磨拖/魔駝",
"ㄇㄛㄋㄧㄢ":"末年/魔念",
"ㄇㄛㄋㄧㄤ":"麼娘/末娘",
"ㄇㄛㄋㄨㄥ":"摩弄",
"ㄇㄛㄌㄧㄠ":"末了/磨料",
"ㄇㄛㄌㄧㄡ":"末流",
"ㄇㄛㄌㄧㄢ":"磨練/抹臉",
"ㄇㄛㄌㄧㄣ":"抹鄰",
"ㄇㄛㄌㄧㄤ":"摸量",
"ㄇㄛㄌㄧㄥ":"抹零/秣陵",
"ㄇㄛㄌㄨㄛ":"沒落/魔羅",
"ㄇㄛㄌㄨㄢ":"沒亂",
"ㄇㄛㄍㄢㄕ":"莫敢是",
"ㄇㄛㄍㄨㄛ":"莫過/末過",
"ㄇㄛㄍㄨㄞ":"魔怪/莫怪",
"ㄇㄛㄍㄨㄟ":"魔鬼",
"ㄇㄛㄍㄨㄢ":"沒官/末官",
"ㄇㄛㄍㄨㄣ":"魔棍",
"ㄇㄛㄍㄨㄤ":"末光/磨光",
"ㄇㄛㄍㄨㄥ":"魔宮/末躬",
"ㄇㄛㄎㄨㄥ":"膜孔",
"ㄇㄛㄏㄨㄚ":"墨花",
"ㄇㄛㄏㄨㄟ":"摹繪",
"ㄇㄛㄏㄨㄢ":"魔幻",
"ㄇㄛㄐㄧㄚ":"墨家/摩戛",
"ㄇㄛㄐㄧㄝ":"末節",
"ㄇㄛㄐㄧㄠ":"莫教",
"ㄇㄛㄐㄧㄥ":"墨鏡/魔鏡",
"ㄇㄛㄐㄩㄝ":"末腳",
"ㄇㄛㄐㄩㄢ":"墨卷",
"ㄇㄛㄑㄧㄡ":"摸秋",
"ㄇㄛㄑㄧㄤ":"抹搶/抹鎗",
"ㄇㄛㄑㄧㄥ":"摸清",
"ㄇㄛㄒㄧㄚ":"沒下/摸瞎",
"ㄇㄛㄒㄧㄝ":"默寫/模寫",
"ㄇㄛㄒㄧㄠ":"模效/模傚",
"ㄇㄛㄒㄧㄢ":"墨線/莫顯",
"ㄇㄛㄒㄧㄤ":"默想/摹想",
"ㄇㄛㄒㄧㄥ":"模型/末行",
"ㄇㄛㄒㄩㄝ":"末學/磨削",
"ㄇㄛㄒㄩㄢ":"墨選",
"ㄇㄛㄒㄩㄥ":"抹胸",
"ㄇㄛㄓㄨㄚ":"魔爪",
"ㄇㄛㄓㄨㄢ":"磨轉/磨磚",
"ㄇㄛㄓㄨㄤ":"摹狀/墨妝",
"ㄇㄛㄔㄌㄟ":"膜翅類",
"ㄇㄛㄔㄨㄤ":"磨床/墨床",
"ㄇㄛㄕㄥㄘ":"摹聲詞",
"ㄇㄛㄕㄨㄕ":"魔術師",
"ㄇㄛㄕㄨㄛ":"莫說",
"ㄇㄛㄕㄨㄟ":"墨水/沒水",
"ㄇㄛㄖㄨㄛ":"莫若",
"ㄇㄛㄗㄠㄓ":"模造紙",
"ㄇㄛㄗㄨㄛ":"末作/默坐",
"ㄇㄛㄗㄨㄟ":"抹嘴/磨嘴",
"ㄇㄛㄘㄨㄣ":"默存",
"ㄇㄛㄙㄨㄛ":"摸索/摩挲",
"ㄇㄛㄙㄨㄢ":"默算",
"ㄇㄛㄙㄨㄣ":"磨損",
"ㄇㄛㄙㄨㄥ":"默誦",
"ㄇㄛㄦㄖㄣ":"摩爾人",
"ㄇㄞㄅㄣㄕ":"賣本事",
"ㄇㄞㄅㄧㄣ":"埋殯",
"ㄇㄞㄆㄧㄠ":"買票/賣票",
"ㄇㄞㄆㄧㄢ":"麥片",
"ㄇㄞㄇㄧㄠ":"麥苗",
"ㄇㄞㄇㄧㄥ":"賣命/埋名",
"ㄇㄞㄉㄧㄢ":"賣點",
"ㄇㄞㄉㄧㄥ":"買定",
"ㄇㄞㄉㄨㄢ":"買斷/賣斷",
"ㄇㄞㄉㄨㄥ":"賣動/脈動",
"ㄇㄞㄊㄧㄢ":"麥田",
"ㄇㄞㄊㄨㄥ":"買通",
"ㄇㄞㄋㄨㄥ":"賣弄",
"ㄇㄞㄌㄧㄢ":"買臉/賣臉",
"ㄇㄞㄌㄧㄣ":"買鄰",
"ㄇㄞㄌㄨㄛ":"脈絡",
"ㄇㄞㄌㄨㄣ":"埋輪",
"ㄇㄞㄌㄨㄥ":"麥隴",
"ㄇㄞㄍㄜㄗ":"麥個子",
"ㄇㄞㄍㄨㄚ":"買卦/賣卦",
"ㄇㄞㄍㄨㄛ":"賣國",
"ㄇㄞㄍㄨㄞ":"賣乖",
"ㄇㄞㄍㄨㄢ":"買官/賣官",
"ㄇㄞㄍㄨㄥ":"賣功",
"ㄇㄞㄎㄨㄞ":"買快",
"ㄇㄞㄎㄨㄤ":"賣狂",
"ㄇㄞㄎㄨㄥ":"賣空",
"ㄇㄞㄏㄨㄚ":"賣花",
"ㄇㄞㄏㄨㄟ":"霾晦",
"ㄇㄞㄏㄨㄣ":"賣婚",
"ㄇㄞㄐㄧㄚ":"買價/賣價",
"ㄇㄞㄐㄧㄝ":"賣解",
"ㄇㄞㄐㄧㄠ":"賣交/麥角",
"ㄇㄞㄐㄧㄡ":"麥酒",
"ㄇㄞㄐㄧㄢ":"賣奸/賣姦",
"ㄇㄞㄐㄧㄣ":"邁進/賣勁",
"ㄇㄞㄐㄧㄥ":"脈經/麥精",
"ㄇㄞㄐㄩㄝ":"賣絕/買爵",
"ㄇㄞㄑㄧㄠ":"賣俏",
"ㄇㄞㄑㄧㄡ":"買求/麥秋",
"ㄇㄞㄑㄧㄢ":"賣錢",
"ㄇㄞㄑㄧㄥ":"埋情",
"ㄇㄞㄒㄧㄠ":"買笑/賣笑",
"ㄇㄞㄒㄧㄡ":"買休/麥秀",
"ㄇㄞㄒㄧㄤ":"邁向/埋香",
"ㄇㄞㄓㄨㄢ":"買轉",
"ㄇㄞㄓㄨㄥ":"賣重",
"ㄇㄞㄔㄉㄞ":"賣痴獃",
"ㄇㄞㄔㄚㄦ":"麥碴兒",
"ㄇㄞㄔㄨㄢ":"買船",
"ㄇㄞㄔㄨㄣ":"買春/賣春",
"ㄇㄞㄔㄨㄥ":"脈衝",
"ㄇㄞㄕㄨㄟ":"買水",
"ㄇㄞㄖㄣㄕ":"買人事",
"ㄇㄞㄗㄏㄠ":"賣字號",
"ㄇㄞㄗㄨㄛ":"賣座",
"ㄇㄞㄗㄨㄟ":"買醉/買嘴",
"ㄇㄞㄘㄨㄥ":"賣蔥",
"ㄇㄞㄙㄨㄟ":"麥穗",
"ㄇㄞㄨㄌㄧ":"買物歷",
"ㄇㄞㄨㄔㄤ":"賣務場",
"ㄇㄟㄅㄣㄕ":"沒本事",
"ㄇㄟㄆㄧㄗ":"沒皮子",
"ㄇㄟㄇㄧㄠ":"美妙",
"ㄇㄟㄇㄧㄢ":"眉面/美眄",
"ㄇㄟㄇㄧㄥ":"美名/沒命",
"ㄇㄟㄈㄚㄗ":"沒法子",
"ㄇㄟㄈㄥㄦ":"沒縫兒",
"ㄇㄟㄉㄨㄗ":"沒肚子",
"ㄇㄟㄉㄨㄟ":"沒對",
"ㄇㄟㄉㄨㄣ":"美噸",
"ㄇㄟㄊㄡㄕ":"沒頭事",
"ㄇㄟㄊㄡㄜ":"沒頭鵝",
"ㄇㄟㄊㄧㄢ":"每天/梅天",
"ㄇㄟㄋㄞㄗ":"美乃滋",
"ㄇㄟㄋㄠㄗ":"沒腦子",
"ㄇㄟㄋㄢㄗ":"美男子",
"ㄇㄟㄋㄧㄝ":"媒孽",
"ㄇㄟㄋㄧㄢ":"每年",
"ㄇㄟㄋㄨㄥ":"美濃",
"ㄇㄟㄌㄠㄗ":"沒落子",
"ㄇㄟㄌㄠㄦ":"沒落兒",
"ㄇㄟㄌㄧㄢ":"沒臉",
"ㄇㄟㄌㄧㄥ":"梅嶺",
"ㄇㄟㄍㄨㄛ":"沒過",
"ㄇㄟㄍㄨㄟ":"玫瑰",
"ㄇㄟㄍㄨㄢ":"美觀/美官",
"ㄇㄟㄍㄨㄤ":"鎂光",
"ㄇㄟㄍㄨㄥ":"美工",
"ㄇㄟㄎㄡㄗ":"沒口子",
"ㄇㄟㄎㄨㄣ":"沒綑",
"ㄇㄟㄎㄨㄤ":"煤礦",
"ㄇㄟㄎㄨㄥ":"沒空",
"ㄇㄟㄏㄟㄗ":"煤黑子",
"ㄇㄟㄏㄨㄚ":"梅花/美化",
"ㄇㄟㄏㄨㄛ":"魅惑/媚惑",
"ㄇㄟㄏㄨㄟ":"每回",
"ㄇㄟㄐㄧㄚ":"眉甲",
"ㄇㄟㄐㄧㄝ":"媒介/眉睫",
"ㄇㄟㄐㄧㄡ":"美酒/梅酒",
"ㄇㄟㄐㄧㄢ":"眉尖/眉繭",
"ㄇㄟㄐㄧㄣ":"美金/美錦",
"ㄇㄟㄐㄧㄥ":"美景/煤精",
"ㄇㄟㄐㄩㄢ":"美眷",
"ㄇㄟㄐㄩㄣ":"黴菌",
"ㄇㄟㄑㄧㄡ":"煤球",
"ㄇㄟㄑㄧㄢ":"媒錢/美遷",
"ㄇㄟㄑㄩㄝ":"美缺",
"ㄇㄟㄒㄧㄚ":"昧下",
"ㄇㄟㄒㄧㄡ":"美秀/沒羞",
"ㄇㄟㄒㄧㄢ":"梅仙/梅縣",
"ㄇㄟㄒㄧㄣ":"沒心/眉心",
"ㄇㄟㄒㄧㄤ":"梅香/美祥",
"ㄇㄟㄒㄧㄥ":"昧行/媚行",
"ㄇㄟㄒㄩㄝ":"美學/沬血",
"ㄇㄟㄒㄩㄢ":"美選/眉軒",
"ㄇㄟㄓㄑㄧ":"沒志氣",
"ㄇㄟㄓㄚㄗ":"煤砟子",
"ㄇㄟㄓㄜㄓ":"沒摺至",
"ㄇㄟㄓㄤㄓ":"沒張智",
"ㄇㄟㄓㄨㄢ":"美饌/煤磚",
"ㄇㄟㄓㄨㄣ":"沒準",
"ㄇㄟㄓㄨㄤ":"沒指望",
"ㄇㄟㄓㄨㄦ":"沒主兒",
"ㄇㄟㄓㄨㄧ":"沒主意",
"ㄇㄟㄔㄨㄞ":"沒揣",
"ㄇㄟㄕㄏㄣ":"沒事狠",
"ㄇㄟㄕㄔㄥ":"沒十成",
"ㄇㄟㄕㄔㄨ":"沒是處",
"ㄇㄟㄕㄖㄣ":"沒事人",
"ㄇㄟㄕㄨㄕ":"美術史",
"ㄇㄟㄕㄨㄗ":"美術字",
"ㄇㄟㄕㄨㄚ":"眉刷",
"ㄇㄟㄕㄨㄛ":"媒妁",
"ㄇㄟㄕㄨㄣ":"每事問",
"ㄇㄟㄕㄨㄤ":"昧爽",
"ㄇㄟㄖㄣㄩ":"美人魚",
"ㄇㄟㄖㄨㄥ":"美容",
"ㄇㄟㄗㄅㄟ":"沒字碑",
"ㄇㄟㄗㄖㄡ":"脢子肉",
"ㄇㄟㄘㄨㄛ":"沒錯",
"ㄇㄟㄙㄢㄙ":"沒三思",
"ㄇㄟㄙㄨㄥ":"袂聳",
"ㄇㄟㄧㄊㄡ":"沒意頭",
"ㄇㄟㄧㄥㄗ":"沒影子",
"ㄇㄟㄧㄥㄦ":"沒影兒",
"ㄇㄟㄧㄩㄕ":"美衣玉食",
"ㄇㄠㄅㄧㄗ":"毛筆字",
"ㄇㄠㄅㄧㄥ":"毛病/錨冰",
"ㄇㄠㄆㄠㄦ":"冒泡兒",
"ㄇㄠㄆㄧㄢ":"毛片",
"ㄇㄠㄇㄠㄩ":"毛毛雨",
"ㄇㄠㄇㄧㄥ":"冒名/貿名",
"ㄇㄠㄉㄡㄗ":"帽兜子",
"ㄇㄠㄉㄧㄝ":"耄耋",
"ㄇㄠㄉㄧㄢ":"茂典/懋典",
"ㄇㄠㄉㄧㄥ":"鉚釘",
"ㄇㄠㄊㄞㄓ":"毛太紙",
"ㄇㄠㄊㄡㄓ":"毛頭紙",
"ㄇㄠㄊㄧㄠ":"茅蜩/髦髫",
"ㄇㄠㄊㄨㄗ":"毛兔子",
"ㄇㄠㄊㄨㄢ":"毛團",
"ㄇㄠㄋㄧㄠ":"貓溺",
"ㄇㄠㄋㄧㄡ":"旄牛",
"ㄇㄠㄋㄧㄢ":"茂年",
"ㄇㄠㄌㄧㄗ":"毛栗子",
"ㄇㄠㄌㄧㄠ":"毛料",
"ㄇㄠㄌㄧㄢ":"帽簾",
"ㄇㄠㄌㄧㄣ":"茂林",
"ㄇㄠㄌㄧㄥ":"冒領/耄齡",
"ㄇㄠㄌㄨㄢ":"瞀亂",
"ㄇㄠㄍㄨㄤ":"袤廣",
"ㄇㄠㄍㄨㄥ":"毛公",
"ㄇㄠㄎㄨㄟ":"帽盔/眊聵",
"ㄇㄠㄎㄨㄥ":"毛孔",
"ㄇㄠㄏㄞㄗ":"毛孩子",
"ㄇㄠㄏㄨㄚ":"帽花",
"ㄇㄠㄏㄨㄛ":"冒火",
"ㄇㄠㄏㄨㄟ":"帽徽",
"ㄇㄠㄏㄨㄤ":"耄荒",
"ㄇㄠㄐㄧㄝ":"鉚接/毛玠",
"ㄇㄠㄐㄧㄡ":"卯酒",
"ㄇㄠㄐㄧㄢ":"冒尖",
"ㄇㄠㄐㄧㄣ":"毛巾/卯勁",
"ㄇㄠㄐㄧㄤ":"毛薑/昴降",
"ㄇㄠㄐㄩㄣ":"髦俊",
"ㄇㄠㄑㄧㄡ":"旄丘",
"ㄇㄠㄑㄧㄢ":"毛錢/懋遷",
"ㄇㄠㄑㄧㄣ":"茂親/貌寢",
"ㄇㄠㄑㄧㄤ":"毛嬙",
"ㄇㄠㄑㄩㄣ":"毛群",
"ㄇㄠㄒㄧㄚ":"毛蝦",
"ㄇㄠㄒㄧㄝ":"毛血/毛蟹",
"ㄇㄠㄒㄧㄡ":"髦秀",
"ㄇㄠㄒㄧㄢ":"冒險/毛線",
"ㄇㄠㄒㄧㄣ":"毛心",
"ㄇㄠㄒㄧㄤ":"貌相/毛象",
"ㄇㄠㄒㄧㄥ":"茂行/茂興",
"ㄇㄠㄒㄩㄝ":"冒雪",
"ㄇㄠㄒㄩㄣ":"茂勛/懋勛",
"ㄇㄠㄒㄩㄥ":"貓熊",
"ㄇㄠㄓㄧㄣ":"冒支銀",
"ㄇㄠㄓㄨㄤ":"毛裝/冒撞",
"ㄇㄠㄓㄨㄥ":"毛重",
"ㄇㄠㄔㄨㄥ":"冒充/毛蟲",
"ㄇㄠㄕㄨㄟ":"卯睡",
"ㄇㄠㄖㄨㄥ":"毛茸",
"ㄇㄠㄗㄖㄣ":"毛子人",
"ㄇㄠㄗㄞㄗ":"毛崽子",
"ㄇㄠㄙㄎㄥ":"茅廁坑",
"ㄇㄠㄙㄨㄟ":"毛遂",
"ㄇㄠㄙㄨㄣ":"毛筍/卯榫",
"ㄇㄠㄙㄨㄥ":"茂松",
"ㄇㄠㄦㄊㄡ":"貓兒頭",
"ㄇㄠㄦㄒㄧ":"髦兒戲",
"ㄇㄠㄦㄧㄢ":"貓兒眼",
"ㄇㄠㄧㄈㄥ":"貿易風",
"ㄇㄠㄧㄑㄩ":"貿易區",
"ㄇㄠㄧㄔㄗ":"貿易赤字",
"ㄇㄠㄧㄕㄤ":"貿易商",
"ㄇㄠㄧㄢㄕ":"貓眼石",
"ㄇㄠㄨㄛㄗ":"毛窩子",
"ㄇㄡㄇㄧㄢ":"謀面",
"ㄇㄡㄌㄩㄝ":"謀略",
"ㄇㄡㄏㄨㄚ":"謀劃",
"ㄇㄡㄐㄧㄚ":"某家/某甲",
"ㄇㄡㄑㄧㄡ":"謀求",
"ㄇㄡㄒㄧㄝ":"某些",
"ㄇㄡㄓㄨㄢ":"繆篆",
"ㄇㄡㄙㄨㄢ":"謀算",
"ㄇㄢㄅㄧㄠ":"慢表",
"ㄇㄢㄆㄧㄢ":"瞞騙",
"ㄇㄢㄆㄧㄥ":"漫評",
"ㄇㄢㄇㄧㄝ":"漫滅",
"ㄇㄢㄇㄧㄠ":"曼妙",
"ㄇㄢㄇㄧㄢ":"滿面",
"ㄇㄢㄉㄧㄠ":"慢調",
"ㄇㄢㄉㄧㄥ":"滿頂",
"ㄇㄢㄉㄨㄛ":"慢惰",
"ㄇㄢㄊㄧㄢ":"滿天/漫天",
"ㄇㄢㄊㄧㄥ":"幔亭",
"ㄇㄢㄋㄠㄗ":"滿腦子",
"ㄇㄢㄋㄧㄡ":"蠻牛",
"ㄇㄢㄋㄨㄢ":"曼暖",
"ㄇㄢㄋㄨㄥ":"曼儂",
"ㄇㄢㄌㄧㄡ":"滿流/漫流",
"ㄇㄢㄌㄧㄢ":"慢臉/滿臉",
"ㄇㄢㄍㄨㄢ":"滿貫/漫灌",
"ㄇㄢㄍㄨㄥ":"慢功/滿弓",
"ㄇㄢㄎㄜㄧ":"滿可以",
"ㄇㄢㄎㄨㄥ":"漫空",
"ㄇㄢㄏㄨㄚ":"漫畫/滿話",
"ㄇㄢㄏㄨㄛ":"慢火/瞞貨",
"ㄇㄢㄏㄨㄞ":"滿懷",
"ㄇㄢㄏㄨㄢ":"漫漶",
"ㄇㄢㄏㄨㄤ":"蠻荒",
"ㄇㄢㄏㄨㄥ":"瞞哄",
"ㄇㄢㄐㄧㄝ":"滿街",
"ㄇㄢㄐㄧㄢ":"蠻牋/蠻箋",
"ㄇㄢㄐㄧㄤ":"蠻江",
"ㄇㄢㄐㄧㄥ":"鰻精/曼荊",
"ㄇㄢㄐㄩㄢ":"漫卷",
"ㄇㄢㄑㄧㄣ":"瞞親",
"ㄇㄢㄑㄧㄤ":"滿腔",
"ㄇㄢㄑㄧㄥ":"滿清",
"ㄇㄢㄑㄩㄝ":"瞞卻",
"ㄇㄢㄒㄧㄠ":"滿孝",
"ㄇㄢㄒㄧㄢ":"曼羨",
"ㄇㄢㄒㄧㄣ":"滿心",
"ㄇㄢㄒㄧㄥ":"慢性",
"ㄇㄢㄒㄩㄝ":"蠻靴",
"ㄇㄢㄓㄨㄤ":"滿裝",
"ㄇㄢㄔㄨㄢ":"滿船",
"ㄇㄢㄕㄨㄛ":"慢說/漫說",
"ㄇㄢㄗㄨㄛ":"滿座/蠻做",
"ㄇㄢㄗㄨㄟ":"滿嘴",
"ㄇㄢㄗㄨㄢ":"滿纂",
"ㄇㄢㄜㄧㄢ":"慢俄延",
"ㄇㄢㄧㄨㄟ":"滿以為",
"ㄇㄣㄅㄧㄠ":"悶錶",
"ㄇㄣㄆㄧㄠ":"門票",
"ㄇㄣㄇㄧㄢ":"門面",
"ㄇㄣㄉㄧㄥ":"門丁",
"ㄇㄣㄉㄨㄟ":"門對",
"ㄇㄣㄉㄨㄣ":"門墩",
"ㄇㄣㄉㄨㄥ":"門洞",
"ㄇㄣㄊㄧㄝ":"門帖",
"ㄇㄣㄊㄧㄥ":"門庭/門桯",
"ㄇㄣㄌㄡㄗ":"門樓子",
"ㄇㄣㄌㄧㄢ":"門聯/門臉",
"ㄇㄣㄌㄧㄥ":"門鈴",
"ㄇㄣㄌㄨㄢ":"悶亂",
"ㄇㄣㄍㄨㄟ":"門規",
"ㄇㄣㄍㄨㄢ":"門館",
"ㄇㄣㄍㄨㄣ":"悶棍",
"ㄇㄣㄍㄨㄤ":"門桄",
"ㄇㄣㄍㄨㄥ":"門公/門功",
"ㄇㄣㄎㄨㄟ":"悶虧",
"ㄇㄣㄎㄨㄤ":"門框",
"ㄇㄣㄐㄧㄡ":"門臼/悶酒",
"ㄇㄣㄐㄧㄣ":"門禁",
"ㄇㄣㄐㄧㄥ":"門警/門徑",
"ㄇㄣㄐㄩㄢ":"悶倦",
"ㄇㄣㄐㄩㄣ":"門軍",
"ㄇㄣㄑㄧㄠ":"門橋",
"ㄇㄣㄑㄧㄢ":"門前/門錢",
"ㄇㄣㄑㄧㄤ":"門牆/門鎗",
"ㄇㄣㄒㄧㄚ":"門下",
"ㄇㄣㄒㄧㄢ":"門限",
"ㄇㄣㄒㄧㄣ":"捫心/悶信",
"ㄇㄣㄒㄧㄤ":"悶香",
"ㄇㄣㄓㄨㄤ":"門狀",
"ㄇㄣㄔㄨㄤ":"門窗",
"ㄇㄣㄕㄨㄢ":"門閂/門栓",
"ㄇㄣㄖㄨㄕ":"門如市",
"ㄇㄣㄗㄔㄜ":"悶子車",
"ㄇㄣㄗㄨㄛ":"門祚/悶坐",
"ㄇㄣㄙㄨㄣ":"悶損/捫搎",
"ㄇㄤㄅㄧㄥ":"忙併",
"ㄇㄤㄉㄧㄢ":"盲點",
"ㄇㄤㄉㄨㄥ":"盲動",
"ㄇㄤㄊㄧㄠ":"莽跳",
"ㄇㄤㄌㄨㄛ":"鋩鑼",
"ㄇㄤㄌㄨㄢ":"忙亂",
"ㄇㄤㄍㄨㄚ":"哤聒",
"ㄇㄤㄍㄨㄛ":"芒果",
"ㄇㄤㄍㄨㄥ":"忙工",
"ㄇㄤㄏㄨㄛ":"忙活",
"ㄇㄤㄏㄨㄦ":"忙惚兒",
"ㄇㄤㄐㄧㄝ":"忙劫",
"ㄇㄤㄐㄧㄠ":"芒角",
"ㄇㄤㄐㄩㄝ":"芒屩",
"ㄇㄤㄒㄧㄝ":"芒鞋",
"ㄇㄤㄒㄧㄠ":"芒硝",
"ㄇㄤㄒㄧㄣ":"盲信",
"ㄇㄤㄓㄨㄤ":"莽撞",
"ㄇㄤㄓㄨㄥ":"芒種",
"ㄇㄤㄘㄨㄥ":"盲從",
"ㄇㄥㄅㄧㄠ":"甍標",
"ㄇㄥㄆㄧㄢ":"矇騙",
"ㄇㄥㄇㄧㄢ":"蒙面",
"ㄇㄥㄉㄧㄝ":"猛跌/夢蝶",
"ㄇㄥㄉㄨㄥ":"懵懂/萌動",
"ㄇㄥㄊㄧㄢ":"蒙恬",
"ㄇㄥㄊㄨㄥ":"蒙童/氋氃",
"ㄇㄥㄋㄧㄝ":"萌櫱",
"ㄇㄥㄌㄧㄝ":"猛烈",
"ㄇㄥㄌㄨㄛ":"蒙絡",
"ㄇㄥㄌㄨㄥ":"朦朧/矇矓",
"ㄇㄥㄍㄨㄛ":"盟國",
"ㄇㄥㄍㄨㄢ":"蒙館",
"ㄇㄥㄍㄨㄤ":"孟光",
"ㄇㄥㄍㄨㄥ":"孟公/猛攻",
"ㄇㄥㄍㄨㄦ":"蒙古兒",
"ㄇㄥㄍㄨㄩ":"蒙古語",
"ㄇㄥㄎㄨㄟ":"懵憒/矇聵",
"ㄇㄥㄏㄨㄚ":"夢話/夢華",
"ㄇㄥㄏㄨㄛ":"猛火/孟獲",
"ㄇㄥㄏㄨㄟ":"夢虺/雺晦",
"ㄇㄥㄏㄨㄢ":"夢幻",
"ㄇㄥㄏㄨㄣ":"矇混/夢魂",
"ㄇㄥㄏㄨㄥ":"蒙哄/濛鴻",
"ㄇㄥㄐㄧㄚ":"艋舺",
"ㄇㄥㄐㄧㄠ":"孟郊/朦狡",
"ㄇㄥㄐㄧㄡ":"猛糾",
"ㄇㄥㄐㄧㄢ":"夢見",
"ㄇㄥㄐㄧㄣ":"猛進/猛勁",
"ㄇㄥㄐㄧㄤ":"猛將",
"ㄇㄥㄐㄧㄥ":"夢境",
"ㄇㄥㄐㄩㄣ":"盟軍",
"ㄇㄥㄑㄧㄡ":"蒙求/孟秋",
"ㄇㄥㄑㄧㄣ":"猛禽",
"ㄇㄥㄒㄧㄚ":"孟夏",
"ㄇㄥㄒㄧㄡ":"蒙羞",
"ㄇㄥㄒㄧㄤ":"夢想/夢鄉",
"ㄇㄥㄒㄧㄥ":"猛醒/猛省",
"ㄇㄥㄒㄩㄝ":"蒙學",
"ㄇㄥㄒㄩㄣ":"孟荀",
"ㄇㄥㄒㄩㄥ":"夢熊",
"ㄇㄥㄓㄨㄛ":"萌茁",
"ㄇㄥㄓㄨㄤ":"蒙莊",
"ㄇㄥㄔㄨㄣ":"孟春",
"ㄇㄥㄔㄨㄥ":"猛衝/蒙衝",
"ㄇㄥㄖㄨㄥ":"蒙戎/尨茸",
"ㄇㄥㄗㄨㄥ":"孟宗",
"ㄇㄥㄙㄨㄥ":"雺淞/霿淞",
"ㄇㄥㄨㄌㄨ":"蒙霧露",
"ㄇㄧㄅㄧㄥ":"弭兵",
"ㄇㄧㄆㄧㄥ":"弭平/敉平",
"ㄇㄧㄇㄧㄢ":"謎面",
"ㄇㄧㄉㄧㄝ":"迷蝶",
"ㄇㄧㄉㄧㄢ":"密電/米顛",
"ㄇㄧㄉㄧㄥ":"嘧啶",
"ㄇㄧㄊㄧㄢ":"彌天",
"ㄇㄧㄊㄨㄢ":"謎團",
"ㄇㄧㄊㄨㄥ":"蜜筒",
"ㄇㄧㄋㄧㄠ":"泌尿",
"ㄇㄧㄋㄧㄢ":"彌年",
"ㄇㄧㄌㄧㄡ":"彌留",
"ㄇㄧㄌㄧㄢ":"迷戀",
"ㄇㄧㄌㄧㄤ":"米糧",
"ㄇㄧㄌㄧㄥ":"密令",
"ㄇㄧㄌㄨㄛ":"彌羅",
"ㄇㄧㄌㄨㄢ":"迷亂/弭亂",
"ㄇㄧㄌㄨㄣ":"密侖/彌綸",
"ㄇㄧㄍㄨㄛ":"米果",
"ㄇㄧㄍㄨㄥ":"迷宮/蜜供",
"ㄇㄧㄏㄨㄛ":"迷惑/蘼藿",
"ㄇㄧㄏㄨㄟ":"密會",
"ㄇㄧㄏㄨㄢ":"弭患",
"ㄇㄧㄏㄨㄣ":"迷魂",
"ㄇㄧㄐㄧㄝ":"密接/弭節",
"ㄇㄧㄐㄧㄡ":"米酒/蜜酒",
"ㄇㄧㄐㄧㄢ":"密件/蜜煎",
"ㄇㄧㄐㄧㄣ":"迷津",
"ㄇㄧㄐㄧㄤ":"米漿",
"ㄇㄧㄐㄧㄥ":"謐靜/彌敬",
"ㄇㄧㄐㄩㄝ":"祕訣",
"ㄇㄧㄑㄧㄝ":"密切",
"ㄇㄧㄑㄧㄡ":"泌丘",
"ㄇㄧㄑㄧㄣ":"密親",
"ㄇㄧㄒㄧㄙ":"密昔司",
"ㄇㄧㄒㄧㄚ":"米蝦",
"ㄇㄧㄒㄧㄝ":"密寫",
"ㄇㄧㄒㄧㄢ":"米線/蜜腺",
"ㄇㄧㄒㄧㄣ":"迷信/祕辛",
"ㄇㄧㄒㄧㄤ":"蜜香/米象",
"ㄇㄧㄒㄩㄝ":"謎學",
"ㄇㄧㄓㄨㄛ":"密捉",
"ㄇㄧㄓㄨㄟ":"靡追",
"ㄇㄧㄔㄨㄥ":"米蟲",
"ㄇㄧㄕㄨㄟ":"蜜水/泌水",
"ㄇㄧㄖㄨㄥ":"麋茸",
"ㄇㄧㄗㄨㄟ":"迷醉",
"ㄇㄧㄗㄨㄥ":"密宗",
"ㄇㄧㄘㄨㄛ":"迷錯",
"ㄇㄧㄘㄨㄥ":"弭從",
"ㄇㄧㄙㄊㄜ":"密斯特",
"ㄇㄧㄙㄨㄛ":"覓索",
"ㄇㄧㄙㄨㄥ":"密送",
"ㄇㄧㄝㄇㄛ":"滅沒",
"ㄇㄧㄝㄇㄣ":"滅門",
"ㄇㄧㄝㄇㄥ":"蔑蒙/蠛蠓",
"ㄇㄧㄝㄉㄨ":"滅度",
"ㄇㄧㄝㄎㄜ":"篾客",
"ㄇㄧㄝㄎㄡ":"滅口",
"ㄇㄧㄝㄐㄧ":"滅紀/滅跡",
"ㄇㄧㄝㄑㄧ":"蔑棄",
"ㄇㄧㄝㄒㄧ":"篾席",
"ㄇㄧㄝㄔㄚ":"滅茬",
"ㄇㄧㄝㄖㄨ":"蔑如",
"ㄇㄧㄝㄗㄨ":"滅族",
"ㄇㄧㄝㄧㄥ":"滅景",
"ㄇㄧㄝㄨㄤ":"滅亡",
"ㄇㄧㄠㄅㄧ":"妙筆",
"ㄇㄧㄠㄅㄨ":"描補",
"ㄇㄧㄠㄆㄟ":"描賠",
"ㄇㄧㄠㄆㄨ":"苗圃",
"ㄇㄧㄠㄇㄛ":"描摸/眇默",
"ㄇㄧㄠㄇㄢ":"淼漫",
"ㄇㄧㄠㄇㄤ":"渺茫/淼茫",
"ㄇㄧㄠㄇㄨ":"苗木",
"ㄇㄧㄠㄈㄚ":"妙法/藐法",
"ㄇㄧㄠㄈㄤ":"妙方",
"ㄇㄧㄠㄈㄥ":"眇風",
"ㄇㄧㄠㄉㄞ":"描黛",
"ㄇㄧㄠㄉㄠ":"妙道",
"ㄇㄧㄠㄉㄧ":"眇覿",
"ㄇㄧㄠㄊㄡ":"苗頭",
"ㄇㄧㄠㄊㄤ":"廟堂",
"ㄇㄧㄠㄊㄨ":"妙土/描圖",
"ㄇㄧㄠㄌㄧ":"妙理",
"ㄇㄧㄠㄏㄠ":"廟號",
"ㄇㄧㄠㄏㄨ":"秒忽/杪忽",
"ㄇㄧㄠㄐㄧ":"妙計",
"ㄇㄧㄠㄑㄩ":"妙趣/妙曲",
"ㄇㄧㄠㄓㄠ":"妙招",
"ㄇㄧㄠㄓㄣ":"秒針",
"ㄇㄧㄠㄓㄨ":"廟主/廟祝",
"ㄇㄧㄠㄔㄠ":"廟朝",
"ㄇㄧㄠㄔㄨ":"妙處",
"ㄇㄧㄠㄕㄡ":"妙手",
"ㄇㄧㄠㄕㄢ":"妙善",
"ㄇㄧㄠㄕㄥ":"妙聲",
"ㄇㄧㄠㄕㄨ":"描述",
"ㄇㄧㄠㄖㄢ":"渺然/藐然",
"ㄇㄧㄠㄖㄣ":"妙人/苗人",
"ㄇㄧㄠㄗㄨ":"苗族",
"ㄇㄧㄠㄘㄜ":"妙策",
"ㄇㄧㄠㄘㄞ":"妙才",
"ㄇㄧㄠㄧㄠ":"妙藥",
"ㄇㄧㄠㄧㄣ":"妙音",
"ㄇㄧㄠㄨㄣ":"苗文/妙聞",
"ㄇㄧㄠㄩㄥ":"妙用",
"ㄇㄧㄡㄇㄠ":"謬耄",
"ㄇㄧㄡㄗㄢ":"謬讚",
"ㄇㄧㄡㄧㄢ":"謬言",
"ㄇㄧㄡㄨㄤ":"謬妄",
"ㄇㄧㄢㄅㄛ":"綿薄",
"ㄇㄧㄢㄅㄟ":"棉被",
"ㄇㄧㄢㄅㄠ":"麵包",
"ㄇㄧㄢㄅㄢ":"面板",
"ㄇㄧㄢㄅㄧ":"面壁",
"ㄇㄧㄢㄅㄨ":"面部/棉布",
"ㄇㄧㄢㄆㄠ":"面皰/棉袍",
"ㄇㄧㄢㄆㄢ":"面般",
"ㄇㄧㄢㄆㄤ":"面龐",
"ㄇㄧㄢㄆㄥ":"面朋",
"ㄇㄧㄢㄆㄧ":"面皮",
"ㄇㄧㄢㄇㄠ":"面貌",
"ㄇㄧㄢㄇㄢ":"綿蠻",
"ㄇㄧㄢㄇㄣ":"面門",
"ㄇㄧㄢㄇㄧ":"綿密",
"ㄇㄧㄢㄇㄨ":"面目",
"ㄇㄧㄢㄈㄟ":"免費/麵肥",
"ㄇㄧㄢㄈㄣ":"面分/麵粉",
"ㄇㄧㄢㄈㄤ":"麵坊/棉紡",
"ㄇㄧㄢㄈㄨ":"面縛/冕服",
"ㄇㄧㄢㄉㄜ":"免得",
"ㄇㄧㄢㄉㄨ":"棉都/綿篤",
"ㄇㄧㄢㄊㄠ":"棉桃",
"ㄇㄧㄢㄊㄢ":"面談/免談",
"ㄇㄧㄢㄊㄤ":"面湯/麵湯",
"ㄇㄧㄢㄋㄣ":"面嫩",
"ㄇㄧㄢㄋㄧ":"眄睨",
"ㄇㄧㄢㄌㄞ":"眄睞",
"ㄇㄧㄢㄌㄧ":"勉勵/勉力",
"ㄇㄧㄢㄍㄠ":"面告",
"ㄇㄧㄢㄍㄡ":"面垢",
"ㄇㄧㄢㄍㄣ":"綿亙",
"ㄇㄧㄢㄏㄨ":"麵糊",
"ㄇㄧㄢㄐㄧ":"面積",
"ㄇㄧㄢㄐㄩ":"面具",
"ㄇㄧㄢㄒㄧ":"免席/娩息",
"ㄇㄧㄢㄒㄩ":"棉絮/綿絮",
"ㄇㄧㄢㄓㄜ":"面折",
"ㄇㄧㄢㄓㄠ":"面罩",
"ㄇㄧㄢㄓㄤ":"麵杖",
"ㄇㄧㄢㄔㄚ":"麵茶",
"ㄇㄧㄢㄔㄡ":"綿綢/綿紬",
"ㄇㄧㄢㄔㄢ":"綿纏",
"ㄇㄧㄢㄔㄣ":"面陳/面塵",
"ㄇㄧㄢㄔㄤ":"綿長",
"ㄇㄧㄢㄔㄥ":"面呈",
"ㄇㄧㄢㄔㄨ":"免除/娩出",
"ㄇㄧㄢㄕㄚ":"面紗/棉紗",
"ㄇㄧㄢㄕㄡ":"面首/面授",
"ㄇㄧㄢㄕㄢ":"面善/綿山",
"ㄇㄧㄢㄕㄣ":"免身",
"ㄇㄧㄢㄕㄤ":"面上/綿上",
"ㄇㄧㄢㄕㄥ":"面生/勉生",
"ㄇㄧㄢㄕㄨ":"面熟/面數",
"ㄇㄧㄢㄖㄢ":"面染",
"ㄇㄧㄢㄖㄨ":"免乳",
"ㄇㄧㄢㄗㄡ":"面奏",
"ㄇㄧㄢㄘㄢ":"免參",
"ㄇㄧㄢㄙㄜ":"面色",
"ㄇㄧㄢㄙㄤ":"免喪",
"ㄇㄧㄢㄙㄨ":"免俗/免訴",
"ㄇㄧㄢㄧㄝ":"面靨",
"ㄇㄧㄢㄧㄠ":"面邀",
"ㄇㄧㄢㄧㄢ":"綿延",
"ㄇㄧㄢㄧㄤ":"綿羊",
"ㄇㄧㄢㄨㄚ":"棉襪",
"ㄇㄧㄢㄩㄣ":"面允/眠雲",
"ㄇㄧㄣㄅㄠ":"民報",
"ㄇㄧㄣㄅㄣ":"閩本",
"ㄇㄧㄣㄇㄛ":"民瘼/泯沒",
"ㄇㄧㄣㄇㄨ":"民牧",
"ㄇㄧㄣㄈㄚ":"民法/抿髮",
"ㄇㄧㄣㄈㄣ":"民憤",
"ㄇㄧㄣㄈㄤ":"民房/民防",
"ㄇㄧㄣㄈㄥ":"民風",
"ㄇㄧㄣㄈㄨ":"民負/民夫",
"ㄇㄧㄣㄉㄜ":"民德",
"ㄇㄧㄣㄉㄞ":"民代",
"ㄇㄧㄣㄉㄡ":"敏豆",
"ㄇㄧㄣㄉㄨ":"民蠹",
"ㄇㄧㄣㄊㄡ":"抿頭",
"ㄇㄧㄣㄋㄢ":"閩南",
"ㄇㄧㄣㄌㄠ":"民勞",
"ㄇㄧㄣㄌㄧ":"民力",
"ㄇㄧㄣㄍㄜ":"民歌",
"ㄇㄧㄣㄍㄢ":"敏感",
"ㄇㄧㄣㄏㄜ":"民和",
"ㄇㄧㄣㄏㄞ":"民害",
"ㄇㄧㄣㄏㄡ":"閩侯",
"ㄇㄧㄣㄏㄤ":"民航",
"ㄇㄧㄣㄏㄨ":"笢笏",
"ㄇㄧㄣㄐㄧ":"民極/民籍",
"ㄇㄧㄣㄐㄩ":"民居/閩劇",
"ㄇㄧㄣㄑㄧ":"民氣/抿泣",
"ㄇㄧㄣㄒㄧ":"閔惜",
"ㄇㄧㄣㄒㄩ":"旻序/憫恤",
"ㄇㄧㄣㄓㄞ":"民宅",
"ㄇㄧㄣㄓㄣ":"民陣",
"ㄇㄧㄣㄓㄥ":"民政",
"ㄇㄧㄣㄓㄨ":"民主",
"ㄇㄧㄣㄔㄨ":"民初",
"ㄇㄧㄣㄕㄜ":"民社",
"ㄇㄧㄣㄕㄢ":"敏贍",
"ㄇㄧㄣㄕㄥ":"民生/民聲",
"ㄇㄧㄣㄖㄢ":"憫然/泯然",
"ㄇㄧㄣㄖㄣ":"民人",
"ㄇㄧㄣㄗㄟ":"民賊",
"ㄇㄧㄣㄗㄨ":"民族",
"ㄇㄧㄣㄘㄜ":"憫惻",
"ㄇㄧㄣㄙㄨ":"民俗",
"ㄇㄧㄣㄧㄝ":"民業",
"ㄇㄧㄣㄧㄠ":"民謠",
"ㄇㄧㄣㄧㄡ":"民有",
"ㄇㄧㄣㄧㄣ":"民隱",
"ㄇㄧㄣㄧㄥ":"民營",
"ㄇㄧㄣㄨㄤ":"民望",
"ㄇㄧㄣㄩㄢ":"民怨",
"ㄇㄧㄣㄩㄣ":"民運",
"ㄇㄧㄣㄩㄥ":"民用",
"ㄇㄧㄥㄅㄛ":"命薄/溟渤",
"ㄇㄧㄥㄅㄞ":"明白",
"ㄇㄧㄥㄅㄟ":"名輩",
"ㄇㄧㄥㄅㄠ":"冥報/冥寶",
"ㄇㄧㄥㄅㄢ":"明板",
"ㄇㄧㄥㄅㄤ":"名榜",
"ㄇㄧㄥㄅㄧ":"名筆/命筆",
"ㄇㄧㄥㄅㄨ":"名捕/名簿",
"ㄇㄧㄥㄆㄞ":"名牌/明牌",
"ㄇㄧㄥㄆㄟ":"銘佩",
"ㄇㄧㄥㄆㄠ":"鳴炮",
"ㄇㄧㄥㄆㄢ":"明盤",
"ㄇㄧㄥㄆㄨ":"茗圃",
"ㄇㄧㄥㄇㄚ":"明碼",
"ㄇㄧㄥㄇㄛ":"冥默/冥漠",
"ㄇㄧㄥㄇㄞ":"命脈",
"ㄇㄧㄥㄇㄟ":"明媚",
"ㄇㄧㄥㄇㄡ":"明眸",
"ㄇㄧㄥㄇㄣ":"名門/命門",
"ㄇㄧㄥㄇㄤ":"冥茫/溟漭",
"ㄇㄧㄥㄇㄥ":"冥蒙/冥濛",
"ㄇㄧㄥㄇㄧ":"明密",
"ㄇㄧㄥㄇㄨ":"名目/明目",
"ㄇㄧㄥㄈㄚ":"明發/明法",
"ㄇㄧㄥㄈㄟ":"明妃",
"ㄇㄧㄥㄈㄢ":"明範/明礬",
"ㄇㄧㄥㄈㄣ":"明分/名分",
"ㄇㄧㄥㄈㄤ":"茗坊",
"ㄇㄧㄥㄈㄥ":"鳴鳳",
"ㄇㄧㄥㄈㄨ":"明府/命服",
"ㄇㄧㄥㄉㄚ":"命大/明達",
"ㄇㄧㄥㄉㄜ":"明德",
"ㄇㄧㄥㄉㄠ":"明道/命道",
"ㄇㄧㄥㄉㄢ":"名單/明旦",
"ㄇㄧㄥㄉㄤ":"明當/明璫",
"ㄇㄧㄥㄉㄥ":"明燈/明鐙",
"ㄇㄧㄥㄉㄧ":"鳴笛/名第",
"ㄇㄧㄥㄉㄨ":"名都/明度",
"ㄇㄧㄥㄊㄜ":"名特",
"ㄇㄧㄥㄊㄞ":"明臺",
"ㄇㄧㄥㄊㄡ":"名頭",
"ㄇㄧㄥㄊㄢ":"名壇/鳴彈",
"ㄇㄧㄥㄊㄤ":"名堂/明堂",
"ㄇㄧㄥㄊㄥ":"螟螣",
"ㄇㄧㄥㄊㄧ":"命題/名體",
"ㄇㄧㄥㄊㄨ":"命途/冥途",
"ㄇㄧㄥㄌㄡ":"明樓",
"ㄇㄧㄥㄌㄤ":"明朗/明琅",
"ㄇㄧㄥㄌㄧ":"名利/命理",
"ㄇㄧㄥㄌㄨ":"名錄",
"ㄇㄧㄥㄍㄡ":"明溝",
"ㄇㄧㄥㄍㄢ":"明玕/銘感",
"ㄇㄧㄥㄍㄣ":"命根",
"ㄇㄧㄥㄍㄨ":"銘骨",
"ㄇㄧㄥㄎㄜ":"銘刻",
"ㄇㄧㄥㄎㄞ":"明開",
"ㄇㄧㄥㄎㄨ":"命苦",
"ㄇㄧㄥㄏㄜ":"明河/冥河",
"ㄇㄧㄥㄏㄞ":"螟害",
"ㄇㄧㄥㄏㄠ":"名號",
"ㄇㄧㄥㄏㄡ":"明後",
"ㄇㄧㄥㄐㄧ":"銘記/名籍",
"ㄇㄧㄥㄐㄩ":"明局/明據",
"ㄇㄧㄥㄑㄧ":"名氣/明器",
"ㄇㄧㄥㄑㄩ":"名曲",
"ㄇㄧㄥㄒㄧ":"明晰",
"ㄇㄧㄥㄓㄜ":"明者/明哲",
"ㄇㄧㄥㄓㄠ":"明朝/明詔",
"ㄇㄧㄥㄓㄡ":"洺州",
"ㄇㄧㄥㄓㄢ":"茗戰",
"ㄇㄧㄥㄓㄤ":"明杖",
"ㄇㄧㄥㄓㄥ":"明正/明證",
"ㄇㄧㄥㄓㄨ":"明珠/名著",
"ㄇㄧㄥㄔㄚ":"明察/茗茶",
"ㄇㄧㄥㄔㄜ":"明徹/明澈",
"ㄇㄧㄥㄔㄠ":"明朝/冥鈔",
"ㄇㄧㄥㄔㄢ":"明蟾/名產",
"ㄇㄧㄥㄔㄣ":"明晨/名臣",
"ㄇㄧㄥㄔㄤ":"明暢/名場",
"ㄇㄧㄥㄔㄥ":"名稱/名城",
"ㄇㄧㄥㄔㄨ":"明處/名廚",
"ㄇㄧㄥㄕㄚ":"鳴沙",
"ㄇㄧㄥㄕㄡ":"名手/冥壽",
"ㄇㄧㄥㄕㄢ":"名山",
"ㄇㄧㄥㄕㄣ":"明神/明慎",
"ㄇㄧㄥㄕㄥ":"名聲/名勝",
"ㄇㄧㄥㄕㄨ":"命數/明恕",
"ㄇㄧㄥㄖㄣ":"名人/明人",
"ㄇㄧㄥㄖㄨ":"名儒",
"ㄇㄧㄥㄗㄠ":"明早/鳴噪",
"ㄇㄧㄥㄗㄡ":"鳴騶",
"ㄇㄧㄥㄗㄨ":"名族",
"ㄇㄧㄥㄘㄜ":"名冊",
"ㄇㄧㄥㄘㄞ":"名菜",
"ㄇㄧㄥㄙㄜ":"明瑟/名色",
"ㄇㄧㄥㄙㄡ":"冥搜",
"ㄇㄧㄥㄙㄨ":"名宿/名素",
"ㄇㄧㄥㄧㄝ":"明夜",
"ㄇㄧㄥㄧㄡ":"名優/冥佑",
"ㄇㄧㄥㄧㄢ":"明言/名言",
"ㄇㄧㄥㄧㄥ":"命硬",
"ㄇㄧㄥㄨㄚ":"明挖/明瓦",
"ㄇㄧㄥㄨㄟ":"明威/名位",
"ㄇㄧㄥㄨㄢ":"冥頑",
"ㄇㄧㄥㄨㄣ":"明文/銘文",
"ㄇㄧㄥㄨㄤ":"名望/明王",
"ㄇㄧㄥㄩㄓ":"名譽職",
"ㄇㄧㄥㄩㄝ":"明月",
"ㄇㄧㄥㄩㄢ":"名媛/名原",
"ㄇㄧㄥㄩㄣ":"命運/明允",
"ㄇㄧㄧㄩㄕ":"靡衣玉食",
"ㄇㄧㄨㄢㄗ":"蜜丸子",
"ㄇㄨㄅㄧㄠ":"目標/墓表",
"ㄇㄨㄅㄧㄣ":"幕賓",
"ㄇㄨㄅㄧㄥ":"募兵",
"ㄇㄨㄆㄢㄗ":"木柈子",
"ㄇㄨㄆㄧㄢ":"木片",
"ㄇㄨㄇㄧㄢ":"木棉",
"ㄇㄨㄇㄧㄣ":"牧民/穆民",
"ㄇㄨㄇㄧㄥ":"慕名",
"ㄇㄨㄉㄧㄠ":"木雕",
"ㄇㄨㄉㄧㄨ":"目的物",
"ㄇㄨㄉㄨㄛ":"木鐸",
"ㄇㄨㄉㄨㄢ":"目斷",
"ㄇㄨㄊㄧㄠ":"木條",
"ㄇㄨㄊㄧㄢ":"木天/牧田",
"ㄇㄨㄊㄧㄥ":"目聽",
"ㄇㄨㄊㄨㄥ":"木通/牧童",
"ㄇㄨㄋㄞㄧ":"木乃伊",
"ㄇㄨㄋㄢㄖ":"母難日",
"ㄇㄨㄋㄧㄢ":"暮年",
"ㄇㄨㄌㄧㄠ":"幕僚/木料",
"ㄇㄨㄌㄧㄢ":"目連/木蓮",
"ㄇㄨㄌㄧㄣ":"慕藺/睦鄰",
"ㄇㄨㄌㄧㄤ":"拇量",
"ㄇㄨㄌㄨㄛ":"木落",
"ㄇㄨㄌㄨㄣ":"目論",
"ㄇㄨㄍㄨㄚ":"木瓜",
"ㄇㄨㄍㄨㄛ":"母國",
"ㄇㄨㄍㄨㄟ":"木桂",
"ㄇㄨㄍㄨㄣ":"木棍",
"ㄇㄨㄍㄨㄤ":"目光",
"ㄇㄨㄍㄨㄥ":"木工/木公",
"ㄇㄨㄎㄨㄢ":"募款",
"ㄇㄨㄏㄚㄔ":"母哈赤",
"ㄇㄨㄏㄨㄚ":"木化/慕化",
"ㄇㄨㄏㄨㄣ":"木婚",
"ㄇㄨㄐㄧㄝ":"暮節/木階",
"ㄇㄨㄐㄧㄠ":"母教",
"ㄇㄨㄐㄧㄡ":"母舅",
"ㄇㄨㄐㄧㄢ":"木簡/母艦",
"ㄇㄨㄐㄧㄣ":"目今/目禁",
"ㄇㄨㄐㄧㄤ":"木強/木漿",
"ㄇㄨㄐㄧㄥ":"目精/目鏡",
"ㄇㄨㄐㄩㄢ":"募捐",
"ㄇㄨㄑㄧㄡ":"木球/暮秋",
"ㄇㄨㄑㄧㄢ":"目前/幕前",
"ㄇㄨㄑㄧㄣ":"母親/木琴",
"ㄇㄨㄑㄧㄥ":"穆清",
"ㄇㄨㄑㄩㄢ":"母權/墓券",
"ㄇㄨㄒㄧㄚ":"目下/木匣",
"ㄇㄨㄒㄧㄝ":"木屑",
"ㄇㄨㄒㄧㄠ":"母校/目笑",
"ㄇㄨㄒㄧㄤ":"木香",
"ㄇㄨㄒㄧㄥ":"木星/母性",
"ㄇㄨㄒㄩㄝ":"墓穴",
"ㄇㄨㄒㄩㄢ":"目眩",
"ㄇㄨㄒㄩㄥ":"母兄",
"ㄇㄨㄓㄅㄨ":"木質部",
"ㄇㄨㄓㄨㄢ":"木磚",
"ㄇㄨㄓㄨㄤ":"木樁",
"ㄇㄨㄓㄨㄥ":"畝鍾",
"ㄇㄨㄔㄨㄣ":"莫春/暮春",
"ㄇㄨㄕㄥㄦ":"暮生兒",
"ㄇㄨㄖㄨㄥ":"慕容",
"ㄇㄨㄗㄨㄛ":"木作",
"ㄇㄨㄙㄨㄛ":"木索",
"ㄇㄨㄙㄨㄟ":"暮歲",
"ㄇㄨㄙㄨㄥ":"目送",
"ㄇㄨㄡㄒㄧ":"木偶戲",
"ㄇㄨㄡㄖㄣ":"木偶人",
"ㄇㄨㄧㄠㄖ":"木曜日",
"ㄇㄨㄨㄩㄗ":"目無餘子",
"ㄇㄨㄩㄕㄨ":"木魚書",
"ㄇㄨㄩㄧㄢ":"沐浴鹽",
"ㄈㄚㄅㄞㄗ":"發擺子",
"ㄈㄚㄅㄧㄠ":"發表/發標",
"ㄈㄚㄅㄧㄢ":"髮辮",
"ㄈㄚㄅㄧㄣ":"髮鬢",
"ㄈㄚㄅㄧㄥ":"發病/發兵",
"ㄈㄚㄆㄞㄨ":"法拍屋",
"ㄈㄚㄆㄧㄠ":"發票/發飄",
"ㄈㄚㄇㄣㄙ":"法門寺",
"ㄈㄚㄇㄧㄢ":"發麵",
"ㄈㄚㄇㄧㄥ":"發明/法名",
"ㄈㄚㄉㄧㄝ":"發牒",
"ㄈㄚㄉㄧㄠ":"髮雕",
"ㄈㄚㄉㄧㄢ":"發癲/發電",
"ㄈㄚㄉㄧㄥ":"法定",
"ㄈㄚㄉㄨㄢ":"發端",
"ㄈㄚㄉㄨㄣ":"乏頓",
"ㄈㄚㄉㄨㄥ":"發動",
"ㄈㄚㄊㄧㄝ":"法帖",
"ㄈㄚㄊㄧㄠ":"發條/法條",
"ㄈㄚㄊㄧㄥ":"法庭",
"ㄈㄚㄊㄨㄛ":"發脫",
"ㄈㄚㄊㄨㄥ":"法統/乏桶",
"ㄈㄚㄋㄧㄢ":"發黏",
"ㄈㄚㄌㄤㄓ":"琺瑯質",
"ㄈㄚㄌㄧㄕ":"發利市",
"ㄈㄚㄌㄧㄣ":"發廩",
"ㄈㄚㄌㄧㄤ":"發亮",
"ㄈㄚㄌㄧㄥ":"法令/法鈴",
"ㄈㄚㄌㄨㄛ":"發落/法螺",
"ㄈㄚㄌㄨㄣ":"法輪",
"ㄈㄚㄍㄜㄦ":"發個兒",
"ㄈㄚㄍㄨㄟ":"法規/發匱",
"ㄈㄚㄍㄨㄢ":"法官/法冠",
"ㄈㄚㄍㄨㄤ":"發光",
"ㄈㄚㄍㄨㄥ":"法宮",
"ㄈㄚㄎㄢㄘ":"發刊詞",
"ㄈㄚㄎㄨㄢ":"罰款",
"ㄈㄚㄎㄨㄣ":"乏困",
"ㄈㄚㄎㄨㄤ":"發狂",
"ㄈㄚㄏㄠㄔ":"發皓齒",
"ㄈㄚㄏㄨㄚ":"發話/發花",
"ㄈㄚㄏㄨㄛ":"發火/發貨",
"ㄈㄚㄏㄨㄟ":"發揮/法會",
"ㄈㄚㄏㄨㄢ":"發還/法環",
"ㄈㄚㄏㄨㄣ":"發昏",
"ㄈㄚㄏㄨㄤ":"發皇/發慌",
"ㄈㄚㄏㄨㄥ":"發洪",
"ㄈㄚㄐㄧㄚ":"發家/法家",
"ㄈㄚㄐㄧㄝ":"發解/法界",
"ㄈㄚㄐㄧㄠ":"髮腳/法教",
"ㄈㄚㄐㄧㄡ":"法酒/罰酒",
"ㄈㄚㄐㄧㄢ":"發監/發箭",
"ㄈㄚㄐㄧㄣ":"法禁/罰金",
"ㄈㄚㄐㄧㄤ":"發僵",
"ㄈㄚㄐㄧㄥ":"法經/法警",
"ㄈㄚㄐㄩㄝ":"發覺/發掘",
"ㄈㄚㄐㄩㄢ":"乏倦",
"ㄈㄚㄐㄩㄥ":"發窘",
"ㄈㄚㄑㄧㄝ":"發篋",
"ㄈㄚㄑㄧㄠ":"發喬/法橋",
"ㄈㄚㄑㄧㄡ":"發球/罰球",
"ㄈㄚㄑㄧㄢ":"發籤/發遣",
"ㄈㄚㄑㄧㄥ":"發情/發青",
"ㄈㄚㄑㄩㄢ":"法權",
"ㄈㄚㄒㄧㄙ":"法西斯",
"ㄈㄚㄒㄧㄝ":"發洩/發泄",
"ㄈㄚㄒㄧㄠ":"發酵/發笑",
"ㄈㄚㄒㄧㄢ":"發現/法線",
"ㄈㄚㄒㄧㄣ":"發信/發心",
"ㄈㄚㄒㄧㄤ":"法相/發祥",
"ㄈㄚㄒㄧㄥ":"髮型/發行",
"ㄈㄚㄒㄩㄝ":"法學",
"ㄈㄚㄒㄩㄢ":"發眩",
"ㄈㄚㄒㄩㄥ":"法兄",
"ㄈㄚㄓㄐㄩ":"法制局",
"ㄈㄚㄓㄨㄤ":"發莊",
"ㄈㄚㄓㄨㄥ":"發冢",
"ㄈㄚㄔㄨㄢ":"發喘/法船",
"ㄈㄚㄔㄨㄣ":"發春",
"ㄈㄚㄕㄔㄜ":"發石車",
"ㄈㄚㄕㄨㄟ":"發水/法水",
"ㄈㄚㄕㄨㄣ":"法順",
"ㄈㄚㄖㄨㄢ":"發軟",
"ㄈㄚㄖㄨㄥ":"法融",
"ㄈㄚㄗㄉㄨ":"伐子都",
"ㄈㄚㄗㄨㄛ":"發作/法坐",
"ㄈㄚㄗㄨㄟ":"伐罪",
"ㄈㄚㄘㄨㄣ":"發村",
"ㄈㄚㄙㄨㄟ":"發歲",
"ㄈㄚㄙㄨㄢ":"法算/發酸",
"ㄈㄚㄙㄨㄥ":"發送/發鬆",
"ㄈㄚㄧㄤㄗ":"乏樣子",
"ㄈㄚㄨㄅㄨ":"法務部",
"ㄈㄚㄩㄢㄙ":"法源寺",
"ㄈㄛㄉㄢㄖ":"佛誕日",
"ㄈㄛㄊㄨㄛ":"佛陀/佛馱",
"ㄈㄛㄍㄨㄛ":"佛果/佛國",
"ㄈㄛㄏㄨㄟ":"佛會",
"ㄈㄛㄐㄧㄚ":"佛家",
"ㄈㄛㄐㄧㄠ":"佛教",
"ㄈㄛㄐㄧㄥ":"佛經",
"ㄈㄛㄑㄧㄥ":"佛青",
"ㄈㄛㄒㄧㄤ":"佛像",
"ㄈㄛㄒㄧㄥ":"佛性",
"ㄈㄛㄒㄩㄝ":"佛學",
"ㄈㄛㄓㄨㄤ":"佛妝",
"ㄈㄛㄕㄥㄖ":"佛生日",
"ㄈㄟㄅㄧㄠ":"飛鏢/飛飆",
"ㄈㄟㄅㄧㄢ":"飛變/飛辯",
"ㄈㄟㄅㄧㄥ":"肺病",
"ㄈㄟㄆㄧㄠ":"飛票/廢票",
"ㄈㄟㄆㄧㄣ":"廢品/妃嬪",
"ㄈㄟㄇㄧㄥ":"朏明/非命",
"ㄈㄟㄉㄧㄝ":"飛碟/匪諜",
"ㄈㄟㄉㄧㄠ":"廢掉",
"ㄈㄟㄉㄧㄢ":"沸點/廢典",
"ㄈㄟㄉㄧㄥ":"沸鼎",
"ㄈㄟㄉㄨㄣ":"肥遁/肥遯",
"ㄈㄟㄊㄧㄝ":"廢鐵/飛帖",
"ㄈㄟㄊㄧㄠ":"蜚條",
"ㄈㄟㄊㄧㄢ":"飛天/肥田",
"ㄈㄟㄊㄧㄥ":"飛艇",
"ㄈㄟㄊㄨㄛ":"吠陀",
"ㄈㄟㄊㄨㄟ":"飛腿",
"ㄈㄟㄊㄨㄢ":"飛湍",
"ㄈㄟㄋㄚㄠ":"緋衲襖",
"ㄈㄟㄋㄧㄠ":"飛鳥",
"ㄈㄟㄋㄨㄛ":"肥喏",
"ㄈㄟㄌㄧㄠ":"肥料/廢料",
"ㄈㄟㄌㄧㄡ":"飛流/飛溜",
"ㄈㄟㄌㄧㄢ":"飛廉/蜚蠊",
"ㄈㄟㄌㄧㄣ":"菲林",
"ㄈㄟㄌㄧㄤ":"飛梁",
"ㄈㄟㄌㄧㄥ":"飛靈/飛軨",
"ㄈㄟㄌㄨㄣ":"非論/飛輪",
"ㄈㄟㄌㄨㄥ":"飛龍",
"ㄈㄟㄍㄨㄟ":"飛歸",
"ㄈㄟㄍㄨㄢ":"飛觀/飛官",
"ㄈㄟㄍㄨㄤ":"飛光",
"ㄈㄟㄍㄨㄥ":"非攻/匪躬",
"ㄈㄟㄎㄨㄞ":"飛快",
"ㄈㄟㄎㄨㄥ":"飛鞚",
"ㄈㄟㄏㄨㄚ":"廢話/費話",
"ㄈㄟㄏㄨㄛ":"肺火/飛禍",
"ㄈㄟㄏㄨㄟ":"非毀/怫恚",
"ㄈㄟㄏㄨㄤ":"飛黃/飛蝗",
"ㄈㄟㄏㄨㄥ":"緋紅/飛紅",
"ㄈㄟㄐㄧㄚ":"肥家",
"ㄈㄟㄐㄧㄝ":"費解",
"ㄈㄟㄐㄧㄢ":"飛濺/飛劍",
"ㄈㄟㄐㄧㄣ":"費勁",
"ㄈㄟㄐㄧㄤ":"飛將",
"ㄈㄟㄐㄧㄥ":"飛莖/菲敬",
"ㄈㄟㄐㄩㄝ":"匪爵",
"ㄈㄟㄑㄧㄠ":"飛橋",
"ㄈㄟㄑㄧㄢ":"飛錢/費錢",
"ㄈㄟㄑㄧㄣ":"飛禽",
"ㄈㄟㄑㄧㄤ":"肥強",
"ㄈㄟㄑㄧㄨ":"廢棄物",
"ㄈㄟㄑㄩㄝ":"肥缺",
"ㄈㄟㄑㄩㄢ":"飛券/飛泉",
"ㄈㄟㄒㄧㄚ":"飛霞",
"ㄈㄟㄒㄧㄝ":"匪懈",
"ㄈㄟㄒㄧㄠ":"非笑",
"ㄈㄟㄒㄧㄢ":"飛仙/肥鮮",
"ㄈㄟㄒㄧㄣ":"費心",
"ㄈㄟㄒㄧㄤ":"飛翔",
"ㄈㄟㄒㄧㄥ":"飛行",
"ㄈㄟㄒㄩㄝ":"廢學/飛雪",
"ㄈㄟㄒㄩㄢ":"飛軒",
"ㄈㄟㄒㄩㄥ":"非熊",
"ㄈㄟㄓㄥㄕ":"非正式",
"ㄈㄟㄓㄨㄚ":"飛抓/飛撾",
"ㄈㄟㄓㄨㄛ":"菲酌",
"ㄈㄟㄓㄨㄟ":"廢墜",
"ㄈㄟㄓㄨㄢ":"飛轉",
"ㄈㄟㄓㄨㄤ":"肥壯",
"ㄈㄟㄔㄓㄕ":"飛馳之勢",
"ㄈㄟㄔㄨㄢ":"飛船",
"ㄈㄟㄔㄨㄥ":"飛蟲/蜰蟲",
"ㄈㄟㄕㄓㄨ":"非時之物",
"ㄈㄟㄕㄕㄕ":"廢時失事",
"ㄈㄟㄕㄨㄟ":"廢水/肥水",
"ㄈㄟㄕㄨㄤ":"飛霜/霏霜",
"ㄈㄟㄖㄣㄕ":"菲人事",
"ㄈㄟㄖㄨㄥ":"飛榮",
"ㄈㄟㄗㄈㄣ":"痱子粉",
"ㄈㄟㄗㄠㄙ":"肥皂絲",
"ㄈㄟㄗㄨㄟ":"費嘴",
"ㄈㄟㄘㄨㄟ":"翡翠",
"ㄈㄟㄧㄜㄕ":"菲衣惡食",
"ㄈㄡㄉㄧㄥ":"否定",
"ㄈㄡㄐㄩㄝ":"否決",
"ㄈㄢㄅㄧㄥ":"犯病/翻餅",
"ㄈㄢㄅㄨㄕ":"犯不是",
"ㄈㄢㄅㄨㄙ":"反不似",
"ㄈㄢㄅㄨㄧ":"帆布椅",
"ㄈㄢㄆㄧㄠ":"飯票",
"ㄈㄢㄆㄧㄣ":"凡品",
"ㄈㄢㄆㄧㄥ":"藩屏",
"ㄈㄢㄇㄠㄦ":"翻毛兒",
"ㄈㄢㄇㄧㄢ":"反面/翻面",
"ㄈㄢㄇㄧㄣ":"凡民",
"ㄈㄢㄇㄧㄥ":"反命",
"ㄈㄢㄉㄧㄠ":"犯調/翻調",
"ㄈㄢㄉㄧㄢ":"飯店/反坫",
"ㄈㄢㄉㄨㄛ":"繁多",
"ㄈㄢㄉㄨㄟ":"反對/犯對",
"ㄈㄢㄉㄨㄥ":"反動/翻動",
"ㄈㄢㄊㄧㄗ":"繁體字",
"ㄈㄢㄊㄧㄢ":"翻天/梵天",
"ㄈㄢㄊㄧㄥ":"飯廳",
"ㄈㄢㄊㄨㄢ":"飯糰",
"ㄈㄢㄊㄨㄥ":"飯筒/飯桶",
"ㄈㄢㄋㄠㄙ":"煩惱絲",
"ㄈㄢㄋㄧㄡ":"反紐",
"ㄈㄢㄋㄧㄢ":"飯粘",
"ㄈㄢㄋㄨㄥ":"翻弄/販弄",
"ㄈㄢㄌㄧㄓ":"番荔枝",
"ㄈㄢㄌㄧㄗ":"反粒子",
"ㄈㄢㄌㄧㄙ":"凡立司",
"ㄈㄢㄌㄧㄠ":"燔燎",
"ㄈㄢㄌㄧㄢ":"反臉/翻臉",
"ㄈㄢㄌㄧㄣ":"犯鱗",
"ㄈㄢㄌㄧㄤ":"飯量",
"ㄈㄢㄌㄧㄥ":"翻領",
"ㄈㄢㄌㄨㄛ":"番羅/飯籮",
"ㄈㄢㄌㄨㄢ":"反亂/煩亂",
"ㄈㄢㄌㄨㄣ":"泛論/汎論",
"ㄈㄢㄌㄨㄥ":"樊籠",
"ㄈㄢㄌㄩㄢ":"蕃孌",
"ㄈㄢㄍㄨㄚ":"番瓜",
"ㄈㄢㄍㄨㄛ":"犯過/返國",
"ㄈㄢㄍㄨㄟ":"犯規",
"ㄈㄢㄍㄨㄢ":"反觀/飯館",
"ㄈㄢㄍㄨㄣ":"翻滾",
"ㄈㄢㄍㄨㄤ":"反光",
"ㄈㄢㄍㄨㄥ":"反攻/反共",
"ㄈㄢㄎㄜㄦ":"反磕兒",
"ㄈㄢㄎㄨㄞ":"樊噲",
"ㄈㄢㄎㄨㄟ":"反饋",
"ㄈㄢㄎㄨㄢ":"犯款/范寬",
"ㄈㄢㄎㄨㄥ":"翻空",
"ㄈㄢㄏㄨㄚ":"繁華/反話",
"ㄈㄢㄏㄨㄛ":"凡火",
"ㄈㄢㄏㄨㄟ":"反悔/返回",
"ㄈㄢㄏㄨㄣ":"返魂/藩溷",
"ㄈㄢㄏㄨㄥ":"泛紅",
"ㄈㄢㄐㄧㄚ":"梵筴",
"ㄈㄢㄐㄧㄝ":"反接/反詰",
"ㄈㄢㄐㄧㄠ":"反教/番椒",
"ㄈㄢㄐㄧㄢ":"凡間/犯賤",
"ㄈㄢㄐㄧㄣ":"凡近/范進",
"ㄈㄢㄐㄧㄤ":"反將",
"ㄈㄢㄐㄧㄥ":"反經/犯境",
"ㄈㄢㄐㄩㄣ":"販君",
"ㄈㄢㄑㄧㄝ":"番茄/反切",
"ㄈㄢㄑㄧㄢ":"飯錢/反潛",
"ㄈㄢㄑㄧㄣ":"繁欽",
"ㄈㄢㄑㄧㄤ":"翻腔/帆檣",
"ㄈㄢㄑㄧㄥ":"煩請/飯磬",
"ㄈㄢㄑㄩㄝ":"犯闕/繁缺",
"ㄈㄢㄒㄧㄠ":"煩囂/繁囂",
"ㄈㄢㄒㄧㄡ":"翻修",
"ㄈㄢㄒㄧㄣ":"翻新/煩心",
"ㄈㄢㄒㄧㄤ":"反向/反響",
"ㄈㄢㄒㄧㄥ":"反省/飯腥",
"ㄈㄢㄒㄩㄝ":"梵學",
"ㄈㄢㄒㄩㄣ":"反訓",
"ㄈㄢㄓㄨㄛ":"飯桌/犯拙",
"ㄈㄢㄓㄨㄢ":"反轉/翻轉",
"ㄈㄢㄓㄨㄥ":"繁重/梵眾",
"ㄈㄢㄔㄨㄢ":"反串/翻船",
"ㄈㄢㄔㄨㄣ":"反脣",
"ㄈㄢㄕㄨㄛ":"煩數",
"ㄈㄢㄕㄨㄣ":"犯順",
"ㄈㄢㄖㄨㄛ":"繁弱",
"ㄈㄢㄖㄨㄥ":"繁榮/煩冗",
"ㄈㄢㄗㄕㄡ":"番子手",
"ㄈㄢㄗㄨㄛ":"翻作/反坐",
"ㄈㄢㄗㄨㄟ":"犯罪",
"ㄈㄢㄘㄨㄛ":"犯錯",
"ㄈㄢㄙㄨㄛ":"繁瑣/反鎖",
"ㄈㄢㄙㄨㄟ":"犯歲",
"ㄈㄢㄙㄨㄥ":"翻鬆",
"ㄈㄢㄧㄚㄦ":"犯牙兒",
"ㄈㄢㄧㄩㄢ":"翻譯員",
"ㄈㄢㄨㄑㄧ":"樊於期",
"ㄈㄣㄅㄧㄝ":"分別",
"ㄈㄣㄅㄧㄠ":"分俵",
"ㄈㄣㄅㄧㄢ":"分辨/糞便",
"ㄈㄣㄅㄧㄥ":"粉餅/分兵",
"ㄈㄣㄇㄧㄠ":"分秒",
"ㄈㄣㄇㄧㄢ":"粉面/分娩",
"ㄈㄣㄇㄧㄥ":"分明/分命",
"ㄈㄣㄉㄧㄝ":"粉蝶",
"ㄈㄣㄉㄧㄢ":"分店/墳典",
"ㄈㄣㄉㄧㄥ":"分定/分定",
"ㄈㄣㄉㄨㄟ":"分隊",
"ㄈㄣㄉㄨㄢ":"分段",
"ㄈㄣㄊㄡㄦ":"分頭兒",
"ㄈㄣㄊㄧㄠ":"粉條",
"ㄈㄣㄊㄨㄣ":"紛屯",
"ㄈㄣㄊㄨㄥ":"分痛",
"ㄈㄣㄋㄟㄕ":"分內事",
"ㄈㄣㄋㄧㄝ":"分櫱",
"ㄈㄣㄌㄢㄩ":"芬蘭浴",
"ㄈㄣㄌㄧㄝ":"分裂/分列",
"ㄈㄣㄌㄧㄡ":"分流/分餾",
"ㄈㄣㄌㄧㄤ":"分量",
"ㄈㄣㄌㄧㄥ":"分另/分齡",
"ㄈㄣㄌㄨㄛ":"分落",
"ㄈㄣㄌㄨㄢ":"紛亂",
"ㄈㄣㄌㄨㄣ":"分論/焚輪",
"ㄈㄣㄌㄨㄥ":"分龍",
"ㄈㄣㄌㄩㄝ":"焚掠",
"ㄈㄣㄍㄨㄟ":"分規",
"ㄈㄣㄍㄨㄢ":"分關",
"ㄈㄣㄍㄨㄥ":"分工",
"ㄈㄣㄎㄨㄤ":"糞筐",
"ㄈㄣㄏㄨㄚ":"分化/焚化",
"ㄈㄣㄏㄨㄛ":"分豁/分夥",
"ㄈㄣㄏㄨㄟ":"焚毀/憤恚",
"ㄈㄣㄏㄨㄣ":"氛昏",
"ㄈㄣㄏㄨㄤ":"焚黃",
"ㄈㄣㄏㄨㄥ":"粉紅/分紅",
"ㄈㄣㄐㄧㄗ":"糞箕子",
"ㄈㄣㄐㄧㄚ":"分家",
"ㄈㄣㄐㄧㄝ":"分解/分界",
"ㄈㄣㄐㄧㄠ":"分教/分校",
"ㄈㄣㄐㄧㄡ":"汾酒",
"ㄈㄣㄐㄧㄣ":"分金/分襟",
"ㄈㄣㄐㄧㄥ":"分荊",
"ㄈㄣㄐㄩㄢ":"忿悁",
"ㄈㄣㄑㄧㄝ":"憤切",
"ㄈㄣㄑㄧㄡ":"糞球",
"ㄈㄣㄑㄧㄢ":"分錢/粉芡",
"ㄈㄣㄑㄧㄤ":"粉牆",
"ㄈㄣㄑㄩㄢ":"分權/濆泉",
"ㄈㄣㄒㄧㄝ":"氛邪",
"ㄈㄣㄒㄧㄠ":"分曉/分校",
"ㄈㄣㄒㄧㄡ":"焚修",
"ㄈㄣㄒㄧㄢ":"分限/分限",
"ㄈㄣㄒㄧㄣ":"分心/憤心",
"ㄈㄣㄒㄧㄤ":"分享/分相",
"ㄈㄣㄒㄧㄥ":"分形/僨興",
"ㄈㄣㄒㄧㄩ":"分析語",
"ㄈㄣㄒㄩㄢ":"紛喧",
"ㄈㄣㄒㄩㄣ":"分巡",
"ㄈㄣㄓㄨㄛ":"紛濁",
"ㄈㄣㄓㄨㄥ":"分鐘/墳塚",
"ㄈㄣㄔㄨㄟ":"分炊",
"ㄈㄣㄕㄨㄚ":"粉刷",
"ㄈㄣㄕㄨㄛ":"分說",
"ㄈㄣㄕㄨㄟ":"紛帨",
"ㄈㄣㄖㄨㄛ":"紛若",
"ㄈㄣㄖㄨㄣ":"分潤",
"ㄈㄣㄗㄌㄧ":"分子力",
"ㄈㄣㄗㄨㄟ":"粉嘴",
"ㄈㄣㄘㄨㄢ":"分爨",
"ㄈㄣㄘㄨㄣ":"分寸",
"ㄈㄣㄘㄨㄥ":"分蔥",
"ㄈㄣㄙㄨㄛ":"墳所",
"ㄈㄣㄙㄨㄟ":"粉碎/分歲",
"ㄈㄣㄙㄨㄥ":"分送",
"ㄈㄤㄅㄧㄢ":"方便",
"ㄈㄤㄅㄧㄥ":"放屏",
"ㄈㄤㄆㄧㄥ":"放平",
"ㄈㄤㄇㄧㄢ":"方面/放免",
"ㄈㄤㄇㄧㄥ":"方明/方名",
"ㄈㄤㄉㄢㄧ":"防彈衣",
"ㄈㄤㄉㄧㄝ":"防諜",
"ㄈㄤㄉㄧㄠ":"放刁",
"ㄈㄤㄉㄧㄢ":"放電/芳甸",
"ㄈㄤㄉㄧㄥ":"放定/方鼎",
"ㄈㄤㄉㄨㄟ":"放對",
"ㄈㄤㄉㄨㄥ":"房東",
"ㄈㄤㄊㄧㄝ":"房帖",
"ㄈㄤㄊㄧㄥ":"放停",
"ㄈㄤㄋㄧㄡ":"放牛",
"ㄈㄤㄋㄧㄢ":"方輦",
"ㄈㄤㄌㄧㄝ":"放獵/芳烈",
"ㄈㄤㄌㄧㄠ":"放療",
"ㄈㄤㄌㄧㄡ":"放溜",
"ㄈㄤㄌㄧㄢ":"方連/房奩",
"ㄈㄤㄌㄧㄣ":"芳林/芳鄰",
"ㄈㄤㄌㄧㄤ":"放量/放良",
"ㄈㄤㄌㄧㄥ":"放領/芳齡",
"ㄈㄤㄌㄨㄛ":"訪落",
"ㄈㄤㄌㄨㄥ":"房櫳",
"ㄈㄤㄌㄩㄝ":"方略",
"ㄈㄤㄍㄜㄓ":"方格紙",
"ㄈㄤㄍㄜㄗ":"放鴿子",
"ㄈㄤㄍㄨㄛ":"放過/方國",
"ㄈㄤㄍㄨㄞ":"放乖",
"ㄈㄤㄍㄨㄟ":"方軌/芳規",
"ㄈㄤㄍㄨㄢ":"方關/房官",
"ㄈㄤㄍㄨㄥ":"放工",
"ㄈㄤㄎㄨㄞ":"方塊",
"ㄈㄤㄎㄨㄢ":"放寬/放款",
"ㄈㄤㄎㄨㄤ":"方框/放曠",
"ㄈㄤㄎㄨㄥ":"放空/防空",
"ㄈㄤㄏㄜㄞ":"方何礙",
"ㄈㄤㄏㄨㄚ":"放話/方罫",
"ㄈㄤㄏㄨㄛ":"放火/防火",
"ㄈㄤㄏㄨㄞ":"放懷",
"ㄈㄤㄏㄨㄟ":"方會",
"ㄈㄤㄏㄨㄢ":"放緩",
"ㄈㄤㄏㄨㄣ":"芳魂",
"ㄈㄤㄏㄨㄤ":"放荒/房荒",
"ㄈㄤㄏㄨㄥ":"防洪",
"ㄈㄤㄐㄧㄚ":"放假/方家",
"ㄈㄤㄐㄧㄝ":"放解",
"ㄈㄤㄐㄧㄠ":"放腳",
"ㄈㄤㄐㄧㄢ":"坊間/房間",
"ㄈㄤㄐㄧㄣ":"方今/方近",
"ㄈㄤㄐㄧㄤ":"方將",
"ㄈㄤㄐㄩㄢ":"房捐",
"ㄈㄤㄑㄧㄝ":"方且",
"ㄈㄤㄑㄧㄡ":"訪求/防秋",
"ㄈㄤㄑㄧㄢ":"房錢",
"ㄈㄤㄑㄧㄥ":"方情/放青",
"ㄈㄤㄑㄩㄝ":"放缺",
"ㄈㄤㄑㄩㄢ":"仿圈",
"ㄈㄤㄒㄧㄚ":"放下/房下",
"ㄈㄤㄒㄧㄝ":"放血",
"ㄈㄤㄒㄧㄠ":"仿效/仿傚",
"ㄈㄤㄒㄧㄡ":"防鏽",
"ㄈㄤㄒㄧㄢ":"防線/放線",
"ㄈㄤㄒㄧㄣ":"放心/芳心",
"ㄈㄤㄒㄧㄤ":"方向/方向",
"ㄈㄤㄒㄧㄥ":"方形/放行",
"ㄈㄤㄒㄩㄝ":"放學",
"ㄈㄤㄒㄩㄢ":"方軒",
"ㄈㄤㄒㄩㄣ":"放勛/訪尋",
"ㄈㄤㄓㄐㄧ":"紡織機",
"ㄈㄤㄓㄔㄤ":"紡織廠",
"ㄈㄤㄓㄧㄝ":"紡織業",
"ㄈㄤㄓㄨㄛ":"方桌",
"ㄈㄤㄓㄨㄢ":"方磚/紡縳",
"ㄈㄤㄓㄨㄤ":"房狀",
"ㄈㄤㄓㄨㄥ":"放重",
"ㄈㄤㄔㄥㄕ":"方程式",
"ㄈㄤㄔㄨㄟ":"紡錘",
"ㄈㄤㄔㄨㄢ":"放船",
"ㄈㄤㄔㄨㄣ":"放春/芳春",
"ㄈㄤㄕㄐㄧ":"防蝕劑",
"ㄈㄤㄕㄨㄟ":"放水/防水",
"ㄈㄤㄖㄨㄟ":"芳蕤",
"ㄈㄤㄗㄨㄛ":"倣做/坊佐",
"ㄈㄤㄗㄨㄥ":"放縱/芳蹤",
"ㄈㄤㄘㄨㄣ":"方寸",
"ㄈㄤㄙㄨㄥ":"放鬆",
"ㄈㄤㄧㄚㄗ":"房牙子",
"ㄈㄤㄧㄢㄓ":"方言志",
"ㄈㄤㄧㄥㄕ":"放映室",
"ㄈㄤㄨㄟㄘ":"方位詞",
"ㄈㄤㄩㄅㄨ":"防雨布",
"ㄈㄤㄩㄓㄢ":"防禦戰",
"ㄈㄥㄅㄧㄝ":"奉別",
"ㄈㄥㄅㄧㄠ":"奉表/風標",
"ㄈㄥㄅㄧㄥ":"風病/瘋病",
"ㄈㄥㄆㄧㄠ":"風飄",
"ㄈㄥㄆㄧㄥ":"風評",
"ㄈㄥㄇㄣㄗ":"風門子",
"ㄈㄥㄇㄧㄢ":"封面/鋒面",
"ㄈㄥㄇㄧㄥ":"奉命",
"ㄈㄥㄈㄥㄦ":"風縫兒",
"ㄈㄥㄉㄧㄝ":"風蝶/鳳蝶",
"ㄈㄥㄉㄧㄠ":"風調/奉調",
"ㄈㄥㄉㄧㄢ":"瘋癲/俸點",
"ㄈㄥㄉㄧㄥ":"封頂",
"ㄈㄥㄉㄨㄛ":"風鐸",
"ㄈㄥㄉㄨㄥ":"風動/風洞",
"ㄈㄥㄊㄧㄠ":"封條",
"ㄈㄥㄊㄧㄢ":"葑田/豐腆",
"ㄈㄥㄊㄧㄥ":"風霆",
"ㄈㄥㄊㄨㄢ":"風團",
"ㄈㄥㄊㄨㄥ":"封筒",
"ㄈㄥㄋㄧㄠ":"蜂鳥",
"ㄈㄥㄋㄧㄢ":"豐年/鳳輦",
"ㄈㄥㄋㄧㄥ":"奉寧",
"ㄈㄥㄌㄚㄗ":"鳳辣子",
"ㄈㄥㄌㄧㄝ":"風烈",
"ㄈㄥㄌㄧㄡ":"風流",
"ㄈㄥㄌㄧㄢ":"鳳奩",
"ㄈㄥㄌㄧㄣ":"峰林/賵臨",
"ㄈㄥㄌㄧㄤ":"風量/風涼",
"ㄈㄥㄌㄧㄥ":"奉令/風領",
"ㄈㄥㄌㄧㄩ":"風裡語",
"ㄈㄥㄌㄨㄢ":"封巒/峰巒",
"ㄈㄥㄌㄨㄥ":"豐隆",
"ㄈㄥㄌㄩㄝ":"封略",
"ㄈㄥㄍㄨㄢ":"封關/封官",
"ㄈㄥㄍㄨㄤ":"風光",
"ㄈㄥㄍㄨㄥ":"奉公/縫工",
"ㄈㄥㄎㄨㄟ":"馮魁",
"ㄈㄥㄎㄨㄤ":"瘋狂",
"ㄈㄥㄏㄨㄗ":"風胡子",
"ㄈㄥㄏㄨㄚ":"風化/風話",
"ㄈㄥㄏㄨㄛ":"烽火/風火",
"ㄈㄥㄏㄨㄟ":"奉諱",
"ㄈㄥㄏㄨㄢ":"奉還/馮驩",
"ㄈㄥㄏㄨㄤ":"鳳凰/蜂黃",
"ㄈㄥㄐㄧㄚ":"風家/泛駕",
"ㄈㄥㄐㄧㄝ":"鳳姐/風節",
"ㄈㄥㄐㄧㄠ":"風教/風角",
"ㄈㄥㄐㄧㄡ":"奉酒",
"ㄈㄥㄐㄧㄢ":"封建/奉見",
"ㄈㄥㄐㄧㄣ":"俸金",
"ㄈㄥㄐㄧㄤ":"封疆",
"ㄈㄥㄐㄧㄥ":"風景/諷經",
"ㄈㄥㄐㄩㄝ":"封爵",
"ㄈㄥㄐㄩㄣ":"封君/逢君",
"ㄈㄥㄑㄧㄠ":"楓橋/鳳翹",
"ㄈㄥㄑㄧㄡ":"奉求",
"ㄈㄥㄑㄧㄢ":"風牽/風欠",
"ㄈㄥㄑㄧㄣ":"風琴",
"ㄈㄥㄑㄧㄥ":"風情/丰情",
"ㄈㄥㄑㄩㄝ":"鳳闕",
"ㄈㄥㄑㄩㄢ":"奉勸/風圈",
"ㄈㄥㄒㄧㄚ":"豐下",
"ㄈㄥㄒㄧㄝ":"風邪",
"ㄈㄥㄒㄧㄠ":"鳳簫",
"ㄈㄥㄒㄧㄢ":"風險/奉獻",
"ㄈㄥㄒㄧㄣ":"風信",
"ㄈㄥㄒㄧㄤ":"風向/楓香",
"ㄈㄥㄒㄧㄥ":"風行/奉行",
"ㄈㄥㄒㄩㄝ":"風穴/鳳穴",
"ㄈㄥㄒㄩㄢ":"馮諼",
"ㄈㄥㄒㄩㄣ":"風汛",
"ㄈㄥㄓㄨㄣ":"蜂準",
"ㄈㄥㄓㄨㄤ":"封裝/封樁",
"ㄈㄥㄔㄨㄟ":"鳳吹",
"ㄈㄥㄔㄨㄢ":"風傳/封船",
"ㄈㄥㄕㄖㄜ":"風溼熱",
"ㄈㄥㄕㄧㄝ":"風獅爺",
"ㄈㄥㄕㄨㄛ":"豐碩",
"ㄈㄥㄕㄨㄟ":"風水/灃水",
"ㄈㄥㄕㄨㄤ":"風霜",
"ㄈㄥㄖㄨㄣ":"豐潤",
"ㄈㄥㄖㄨㄥ":"丰茸",
"ㄈㄥㄘㄐㄩ":"諷刺劇",
"ㄈㄥㄘㄨㄛ":"風措/奉厝",
"ㄈㄥㄘㄨㄟ":"豐悴",
"ㄈㄥㄘㄨㄣ":"封存",
"ㄈㄥㄘㄨㄥ":"風從/奉從",
"ㄈㄥㄙㄨㄛ":"封鎖",
"ㄈㄥㄙㄨㄟ":"烽燧",
"ㄈㄥㄙㄨㄥ":"奉送/諷誦",
"ㄈㄥㄧㄢㄙ":"馮延巳",
"ㄈㄥㄨㄛㄦ":"蜂窩兒",
"ㄈㄥㄩㄉㄥ":"風雨燈",
"ㄈㄨㄅㄠㄕ":"傅抱石",
"ㄈㄨㄅㄧㄝ":"賦別",
"ㄈㄨㄅㄧㄠ":"浮標/附表",
"ㄈㄨㄅㄧㄢ":"服辯/伏辯",
"ㄈㄨㄅㄧㄥ":"伏兵/府兵",
"ㄈㄨㄅㄨㄕ":"伏不是",
"ㄈㄨㄆㄞㄗ":"複拍子",
"ㄈㄨㄆㄧㄠ":"浮漂",
"ㄈㄨㄆㄧㄢ":"負片/附片",
"ㄈㄨㄆㄧㄣ":"複頻",
"ㄈㄨㄆㄧㄥ":"浮萍/撫平",
"ㄈㄨㄇㄠㄩ":"拂毛羽",
"ㄈㄨㄇㄧㄝ":"覆滅",
"ㄈㄨㄇㄧㄠ":"祔廟",
"ㄈㄨㄇㄧㄢ":"負面/浮面",
"ㄈㄨㄇㄧㄣ":"膚敏",
"ㄈㄨㄇㄧㄥ":"復明/浮名",
"ㄈㄨㄉㄧㄝ":"蚨蝶",
"ㄈㄨㄉㄧㄠ":"浮吊/浮雕",
"ㄈㄨㄉㄧㄢ":"浮點/負電",
"ㄈㄨㄉㄧㄥ":"負鼎/覆鼎",
"ㄈㄨㄉㄨㄥ":"浮動/拂動",
"ㄈㄨㄊㄡㄕ":"浮頭食",
"ㄈㄨㄊㄤㄨ":"扶堂屋",
"ㄈㄨㄊㄧㄝ":"服貼/服帖",
"ㄈㄨㄊㄧㄠ":"輻條",
"ㄈㄨㄊㄧㄢ":"浮天/伏天",
"ㄈㄨㄊㄧㄥ":"弗庭",
"ㄈㄨㄊㄨㄛ":"付托/付託",
"ㄈㄨㄊㄨㄥ":"扶同/腹痛",
"ㄈㄨㄋㄧㄢ":"富年/俯念",
"ㄈㄨㄋㄧㄤ":"夫娘",
"ㄈㄨㄋㄨㄥ":"撫弄",
"ㄈㄨㄌㄧㄝ":"伏獵",
"ㄈㄨㄌㄧㄠ":"敷料/麩料",
"ㄈㄨㄌㄧㄡ":"伏流",
"ㄈㄨㄌㄧㄢ":"賦斂",
"ㄈㄨㄌㄧㄣ":"俯臨",
"ㄈㄨㄌㄧㄤ":"浮梁",
"ㄈㄨㄌㄧㄥ":"扶靈/茯苓",
"ㄈㄨㄌㄨㄛ":"伏落/拂落",
"ㄈㄨㄌㄨㄢ":"伏卵/扶鸞",
"ㄈㄨㄍㄞㄨ":"敷蓋物",
"ㄈㄨㄍㄨㄛ":"復國/複果",
"ㄈㄨㄍㄨㄟ":"富貴/賦歸",
"ㄈㄨㄍㄨㄢ":"服官/俯觀",
"ㄈㄨㄍㄨㄤ":"輔光",
"ㄈㄨㄍㄨㄥ":"膚公/復工",
"ㄈㄨㄎㄨㄚ":"浮誇",
"ㄈㄨㄎㄨㄛ":"膚廓",
"ㄈㄨㄎㄨㄞ":"府快",
"ㄈㄨㄎㄨㄟ":"負虧/鳧葵",
"ㄈㄨㄎㄨㄢ":"付款/附款",
"ㄈㄨㄎㄨㄤ":"富礦",
"ㄈㄨㄎㄨㄥ":"浮空",
"ㄈㄨㄏㄜㄘ":"複合詞",
"ㄈㄨㄏㄨㄚ":"腐化/孵化",
"ㄈㄨㄏㄨㄛ":"復活/俘獲",
"ㄈㄨㄏㄨㄞ":"腐壞",
"ㄈㄨㄏㄨㄟ":"府會/傅會",
"ㄈㄨㄏㄨㄢ":"浮環",
"ㄈㄨㄏㄨㄣ":"復婚",
"ㄈㄨㄏㄨㄥ":"浮紅/副虹",
"ㄈㄨㄐㄧㄚ":"附加/富家",
"ㄈㄨㄐㄧㄝ":"符節/赴節",
"ㄈㄨㄐㄧㄠ":"敷教/復交",
"ㄈㄨㄐㄧㄡ":"俯就/福酒",
"ㄈㄨㄐㄧㄢ":"伏見/膚見",
"ㄈㄨㄐㄧㄣ":"附近/縛緊",
"ㄈㄨㄐㄧㄤ":"福將/副將",
"ㄈㄨㄐㄧㄥ":"副淨/負荊",
"ㄈㄨㄐㄩㄝ":"複決/副角",
"ㄈㄨㄐㄩㄣ":"府君/覆軍",
"ㄈㄨㄑㄧㄠ":"浮橋",
"ㄈㄨㄑㄧㄡ":"敷求/俘囚",
"ㄈㄨㄑㄧㄢ":"膚淺/付錢",
"ㄈㄨㄑㄧㄣ":"父親/服勤",
"ㄈㄨㄑㄧㄤ":"富強/富彊",
"ㄈㄨㄑㄧㄥ":"付清",
"ㄈㄨㄑㄩㄝ":"服闋",
"ㄈㄨㄑㄩㄢ":"復權/父權",
"ㄈㄨㄑㄩㄥ":"扶筇",
"ㄈㄨㄒㄧㄕ":"伏犧氏",
"ㄈㄨㄒㄧㄚ":"伏下",
"ㄈㄨㄒㄧㄝ":"腹瀉/複寫",
"ㄈㄨㄒㄧㄠ":"附小/服孝",
"ㄈㄨㄒㄧㄡ":"腐朽/拂袖",
"ㄈㄨㄒㄧㄢ":"浮現/副線",
"ㄈㄨㄒㄧㄣ":"負心/腐心",
"ㄈㄨㄒㄧㄤ":"浮想/伏想",
"ㄈㄨㄒㄧㄥ":"復興/服刑",
"ㄈㄨㄒㄩㄝ":"府學/復學",
"ㄈㄨㄒㄩㄢ":"複選/輔選",
"ㄈㄨㄒㄩㄣ":"伏汛/拊循",
"ㄈㄨㄒㄩㄥ":"父兄/婦兄",
"ㄈㄨㄓㄅㄟ":"父執輩",
"ㄈㄨㄓㄊㄨ":"腐植土",
"ㄈㄨㄓㄍㄨ":"跗蹠骨",
"ㄈㄨㄓㄡㄕ":"福州市",
"ㄈㄨㄓㄨㄛ":"附著/祓濯",
"ㄈㄨㄓㄨㄢ":"符篆",
"ㄈㄨㄓㄨㄤ":"服裝/伏狀",
"ㄈㄨㄓㄨㄥ":"服中/附中",
"ㄈㄨㄔㄣㄗ":"浮塵子",
"ㄈㄨㄔㄥㄕ":"副程式",
"ㄈㄨㄔㄨㄛ":"拂綽",
"ㄈㄨㄔㄨㄤ":"扶床",
"ㄈㄨㄔㄨㄥ":"俯衝",
"ㄈㄨㄕㄉㄜ":"浮士德",
"ㄈㄨㄕㄐㄧ":"腐蝕劑",
"ㄈㄨㄕㄕㄢ":"富士山",
"ㄈㄨㄕㄨㄓ":"氟樹脂",
"ㄈㄨㄕㄨㄛ":"浮說",
"ㄈㄨㄕㄨㄟ":"賦稅/符水",
"ㄈㄨㄕㄨㄤ":"富孀",
"ㄈㄨㄕㄨㄨ":"附屬物",
"ㄈㄨㄖㄨㄟ":"符瑞",
"ㄈㄨㄖㄨㄢ":"服軟",
"ㄈㄨㄖㄨㄥ":"婦容/浮榮",
"ㄈㄨㄗㄕㄨ":"負子鼠",
"ㄈㄨㄗㄨㄛ":"趺坐/府佐",
"ㄈㄨㄗㄨㄟ":"服罪/負罪",
"ㄈㄨㄗㄨㄣ":"府尊",
"ㄈㄨㄗㄨㄥ":"覆宗",
"ㄈㄨㄘㄨㄛ":"浮厝",
"ㄈㄨㄘㄨㄟ":"浮脆",
"ㄈㄨㄘㄨㄢ":"伏竄",
"ㄈㄨㄘㄨㄣ":"撫存",
"ㄈㄨㄘㄨㄥ":"服從",
"ㄈㄨㄙㄨㄟ":"富歲/撫綏",
"ㄈㄨㄙㄨㄥ":"附送/浮鬆",
"ㄈㄨㄧㄅㄛ":"付衣缽",
"ㄈㄨㄧㄓㄤ":"副議長",
"ㄈㄨㄧㄣㄘ":"複音詞",
"ㄈㄨㄨㄊㄞ":"服務臺",
"ㄈㄨㄨㄓㄢ":"服務站",
"ㄈㄨㄨㄓㄥ":"服務證",
"ㄈㄨㄨㄔㄨ":"服務處",
"ㄈㄨㄨㄕㄜ":"服務社",
"ㄈㄨㄨㄕㄥ":"服務生",
"ㄈㄨㄨㄧㄝ":"服務業",
"ㄈㄨㄨㄩㄢ":"服務員",
"ㄈㄨㄩㄓㄢ":"覆盂之安",
"ㄈㄨㄩㄕㄨ":"腹語術",
"ㄈㄨㄩㄢㄗ":"浮圓子",
"ㄈㄨㄩㄧㄢ":"副語言",
"ㄉㄚㄅㄚㄕ":"打把式",
"ㄉㄚㄅㄛㄗ":"大脖子",
"ㄉㄚㄅㄞㄗ":"大伯子",
"ㄉㄚㄅㄟㄜ":"打悲阿",
"ㄉㄚㄅㄢㄗ":"打板子",
"ㄉㄚㄅㄤㄗ":"打梆子",
"ㄉㄚㄅㄧㄝ":"大別",
"ㄉㄚㄅㄧㄠ":"達標",
"ㄉㄚㄅㄧㄢ":"大便/答辯",
"ㄉㄚㄅㄧㄣ":"大賓",
"ㄉㄚㄅㄧㄥ":"大病/大兵",
"ㄉㄚㄅㄨㄕ":"大不是",
"ㄉㄚㄅㄨㄢ":"大不安",
"ㄉㄚㄅㄨㄧ":"大不易",
"ㄉㄚㄆㄞㄗ":"打拍子",
"ㄉㄚㄆㄧㄝ":"打撇",
"ㄉㄚㄆㄧㄣ":"打拼",
"ㄉㄚㄆㄧㄥ":"打平",
"ㄉㄚㄇㄛㄧ":"達摩衣",
"ㄉㄚㄇㄞㄗ":"大麥籽",
"ㄉㄚㄇㄠㄗ":"大帽子",
"ㄉㄚㄇㄣㄗ":"打門子",
"ㄉㄚㄇㄥㄗ":"打猛子",
"ㄉㄚㄇㄧㄝ":"打滅",
"ㄉㄚㄇㄧㄠ":"大妙",
"ㄉㄚㄇㄧㄢ":"大面/搭面",
"ㄉㄚㄇㄧㄥ":"大命/大明",
"ㄉㄚㄇㄨㄓ":"大拇指",
"ㄉㄚㄇㄨㄗ":"大姆子",
"ㄉㄚㄈㄚㄕ":"大法師",
"ㄉㄚㄉㄠㄗ":"打道子",
"ㄉㄚㄉㄢㄗ":"打彈子",
"ㄉㄚㄉㄧㄗ":"打底子",
"ㄉㄚㄉㄧㄝ":"大爹/大耋",
"ㄉㄚㄉㄧㄠ":"大調/搭調",
"ㄉㄚㄉㄧㄢ":"大典/大顛",
"ㄉㄚㄉㄧㄥ":"大定/打定",
"ㄉㄚㄉㄨㄕ":"大睹是",
"ㄉㄚㄉㄨㄗ":"大肚子",
"ㄉㄚㄉㄨㄛ":"大多/打奪",
"ㄉㄚㄉㄨㄟ":"大隊/大對",
"ㄉㄚㄉㄨㄢ":"打斷/大端",
"ㄉㄚㄉㄨㄣ":"打躉/打盹",
"ㄉㄚㄉㄨㄥ":"打動/大東",
"ㄉㄚㄉㄨㄩ":"大肚魚",
"ㄉㄚㄊㄡㄗ":"大頭子",
"ㄉㄚㄊㄡㄦ":"大頭兒",
"ㄉㄚㄊㄧㄗ":"搭梯子",
"ㄉㄚㄊㄧㄝ":"打鐵",
"ㄉㄚㄊㄧㄠ":"大挑/打調",
"ㄉㄚㄊㄧㄢ":"大田",
"ㄉㄚㄊㄧㄥ":"大廳/打聽",
"ㄉㄚㄊㄨㄛ":"打脫",
"ㄉㄚㄊㄨㄟ":"大腿/打退",
"ㄉㄚㄊㄨㄢ":"打團",
"ㄉㄚㄊㄨㄥ":"打通/大同",
"ㄉㄚㄋㄧㄝ":"打捏",
"ㄉㄚㄋㄧㄢ":"大年",
"ㄉㄚㄋㄧㄤ":"大娘",
"ㄉㄚㄋㄨㄛ":"大儺",
"ㄉㄚㄋㄨㄢ":"打暖",
"ㄉㄚㄋㄨㄥ":"大農",
"ㄉㄚㄌㄚㄔ":"大拉翅",
"ㄉㄚㄌㄚㄗ":"搭剌子",
"ㄉㄚㄌㄟㄩ":"大雷雨",
"ㄉㄚㄌㄠㄦ":"打老兒",
"ㄉㄚㄌㄧㄕ":"大理石",
"ㄉㄚㄌㄧㄙ":"大理寺",
"ㄉㄚㄌㄧㄝ":"打獵",
"ㄉㄚㄌㄧㄠ":"大料/大僚",
"ㄉㄚㄌㄧㄢ":"大連/大斂",
"ㄉㄚㄌㄧㄤ":"大量/打量",
"ㄉㄚㄌㄧㄥ":"大令/打令",
"ㄉㄚㄌㄨㄛ":"大落/大鑼",
"ㄉㄚㄌㄨㄢ":"大亂/大臠",
"ㄉㄚㄌㄨㄣ":"大倫",
"ㄉㄚㄌㄨㄥ":"打攏",
"ㄉㄚㄌㄩㄝ":"大略/大掠",
"ㄉㄚㄍㄜㄗ":"大個子",
"ㄉㄚㄍㄜㄦ":"大個兒",
"ㄉㄚㄍㄨㄗ":"大姑子",
"ㄉㄚㄍㄨㄚ":"大褂/打卦",
"ㄉㄚㄍㄨㄛ":"大過/大國",
"ㄉㄚㄍㄨㄞ":"打乖",
"ㄉㄚㄍㄨㄟ":"大歸/打鬼",
"ㄉㄚㄍㄨㄢ":"大關/大觀",
"ㄉㄚㄍㄨㄣ":"打滾",
"ㄉㄚㄍㄨㄤ":"打光",
"ㄉㄚㄍㄨㄥ":"打工/大公",
"ㄉㄚㄎㄨㄚ":"打垮",
"ㄉㄚㄎㄨㄛ":"大闊",
"ㄉㄚㄎㄨㄞ":"大快/大塊",
"ㄉㄚㄎㄨㄟ":"大魁",
"ㄉㄚㄎㄨㄥ":"打空/打孔",
"ㄉㄚㄏㄢㄗ":"打漢子",
"ㄉㄚㄏㄨㄗ":"大鬍子",
"ㄉㄚㄏㄨㄚ":"大話/答話",
"ㄉㄚㄏㄨㄛ":"打和/打火",
"ㄉㄚㄏㄨㄞ":"大壞",
"ㄉㄚㄏㄨㄟ":"大會/搭會",
"ㄉㄚㄏㄨㄢ":"打換/打喚",
"ㄉㄚㄏㄨㄣ":"大昏/打混",
"ㄉㄚㄏㄨㄤ":"大皇/大荒",
"ㄉㄚㄏㄨㄥ":"大紅/打哄",
"ㄉㄚㄐㄧㄙ":"大祭司",
"ㄉㄚㄐㄧㄚ":"大家/大甲",
"ㄉㄚㄐㄧㄝ":"打劫/大街",
"ㄉㄚㄐㄧㄠ":"打交/打攪",
"ㄉㄚㄐㄧㄡ":"打救/打就",
"ㄉㄚㄐㄧㄢ":"大件/打尖",
"ㄉㄚㄐㄧㄣ":"大進/大盡",
"ㄉㄚㄐㄧㄤ":"大將/大江",
"ㄉㄚㄐㄧㄥ":"大經/大驚",
"ㄉㄚㄐㄩㄝ":"大覺/大爵",
"ㄉㄚㄐㄩㄣ":"大軍/大君",
"ㄉㄚㄑㄧㄠ":"大喬/搭橋",
"ㄉㄚㄑㄧㄡ":"大球/大秋",
"ㄉㄚㄑㄧㄢ":"大錢/大千",
"ㄉㄚㄑㄧㄣ":"大秦/大侵",
"ㄉㄚㄑㄧㄤ":"打槍/答腔",
"ㄉㄚㄑㄧㄥ":"大青/大慶",
"ㄉㄚㄑㄩㄢ":"大權/打圈",
"ㄉㄚㄒㄧㄕ":"大喜事",
"ㄉㄚㄒㄧㄚ":"大廈/大俠",
"ㄉㄚㄒㄧㄝ":"答謝/大寫",
"ㄉㄚㄒㄧㄠ":"大小/打消",
"ㄉㄚㄒㄧㄡ":"大修/大袖",
"ㄉㄚㄒㄧㄢ":"大仙/大限",
"ㄉㄚㄒㄧㄣ":"達心/答信",
"ㄉㄚㄒㄧㄤ":"大象/大祥",
"ㄉㄚㄒㄧㄥ":"大型/大行",
"ㄉㄚㄒㄩㄝ":"大學/大雪",
"ㄉㄚㄒㄩㄢ":"大選/打選",
"ㄉㄚㄒㄩㄣ":"答詢",
"ㄉㄚㄒㄩㄥ":"大兄/大雄",
"ㄉㄚㄓㄕㄤ":"大致上",
"ㄉㄚㄓㄜㄦ":"大摺兒",
"ㄉㄚㄓㄠㄙ":"大招寺",
"ㄉㄚㄓㄡㄗ":"大軸子",
"ㄉㄚㄓㄢㄕ":"大戰詩",
"ㄉㄚㄓㄨㄕ":"大柱史",
"ㄉㄚㄓㄨㄛ":"搭桌",
"ㄉㄚㄓㄨㄢ":"打轉/大專",
"ㄉㄚㄓㄨㄤ":"打樁/大妝",
"ㄉㄚㄓㄨㄥ":"大眾/大中",
"ㄉㄚㄓㄨㄧ":"打主意",
"ㄉㄚㄓㄨㄨ":"打竹舞",
"ㄉㄚㄔㄅㄛ":"打赤膊",
"ㄉㄚㄔㄐㄩ":"打遲局",
"ㄉㄚㄔㄚㄦ":"答岔兒",
"ㄉㄚㄔㄜㄗ":"打車子",
"ㄉㄚㄔㄠㄗ":"打吵子",
"ㄉㄚㄔㄡㄕ":"打搊拾",
"ㄉㄚㄔㄢㄩ":"大單于",
"ㄉㄚㄔㄨㄛ":"打齪",
"ㄉㄚㄔㄨㄟ":"大吹",
"ㄉㄚㄔㄨㄢ":"搭船/大船",
"ㄉㄚㄔㄨㄣ":"大春/打春",
"ㄉㄚㄔㄨㄤ":"大瘡",
"ㄉㄚㄔㄨㄥ":"大衝/大沖",
"ㄉㄚㄕㄈㄨ":"大師傅",
"ㄉㄚㄕㄐㄧ":"大事記",
"ㄉㄚㄕㄙㄢ":"打十三",
"ㄉㄚㄕㄠㄗ":"打哨子",
"ㄉㄚㄕㄡㄕ":"打手式",
"ㄉㄚㄕㄧㄝ":"大事業",
"ㄉㄚㄕㄨㄞ":"大率/大帥",
"ㄉㄚㄕㄨㄟ":"打水/大水",
"ㄉㄚㄕㄨㄢ":"打拴",
"ㄉㄚㄕㄨㄣ":"大順/大舜",
"ㄉㄚㄖㄣㄨ":"大人物",
"ㄉㄚㄖㄨㄟ":"大蕊",
"ㄉㄚㄖㄨㄢ":"大阮",
"ㄉㄚㄖㄨㄥ":"大戎",
"ㄉㄚㄗㄅㄠ":"大字報",
"ㄉㄚㄗㄅㄣ":"大字本",
"ㄉㄚㄗㄉㄞ":"打字帶",
"ㄉㄚㄗㄐㄧ":"打字機",
"ㄉㄚㄗㄖㄢ":"大自然",
"ㄉㄚㄗㄨㄛ":"大作/打坐",
"ㄉㄚㄗㄨㄟ":"搭嘴/大醉",
"ㄉㄚㄗㄨㄣ":"達尊",
"ㄉㄚㄗㄨㄥ":"大宗/打總",
"ㄉㄚㄗㄩㄢ":"打字員",
"ㄉㄚㄘㄠㄦ":"打草兒",
"ㄉㄚㄘㄨㄢ":"打攛",
"ㄉㄚㄘㄨㄥ":"打從/大蔥",
"ㄉㄚㄙㄇㄚ":"大司馬",
"ㄉㄚㄙㄊㄨ":"大司徒",
"ㄉㄚㄙㄎㄜ":"大司客",
"ㄉㄚㄙㄒㄧ":"大四喜",
"ㄉㄚㄙㄠㄗ":"大嫂子",
"ㄉㄚㄙㄨㄛ":"大索",
"ㄉㄚㄙㄨㄟ":"打碎",
"ㄉㄚㄙㄨㄢ":"打算/大蒜",
"ㄉㄚㄚㄌㄠ":"打阿老",
"ㄉㄚㄚㄍㄜ":"大阿哥",
"ㄉㄚㄢㄏㄠ":"打暗號",
"ㄉㄚㄢㄒㄧ":"大安溪",
"ㄉㄚㄣㄖㄣ":"大恩人",
"ㄉㄚㄦㄐㄧ":"打耳祭",
"ㄉㄚㄦㄧㄣ":"打耳喑",
"ㄉㄚㄧㄚㄦ":"打牙兒",
"ㄉㄚㄧㄝㄕ":"打野食",
"ㄉㄚㄧㄡㄕ":"打油詩",
"ㄉㄚㄧㄣㄗ":"打印子",
"ㄉㄚㄧㄤㄦ":"打佯兒",
"ㄉㄚㄨㄢㄡ":"大玩偶",
"ㄉㄚㄨㄨㄟ":"大無畏",
"ㄉㄚㄩㄌㄤ":"打魚郎",
"ㄉㄚㄩㄝㄓ":"大月氏",
"ㄉㄚㄩㄢㄕ":"大掾史",
"ㄉㄜㄅㄧㄠ":"得標",
"ㄉㄜㄅㄧㄥ":"得病",
"ㄉㄜㄆㄧㄠ":"得票",
"ㄉㄜㄇㄧㄣ":"得民",
"ㄉㄜㄇㄧㄥ":"得名/得命",
"ㄉㄜㄋㄧㄢ":"得年",
"ㄉㄜㄌㄧㄠ":"得了",
"ㄉㄜㄌㄧㄢ":"得臉",
"ㄉㄜㄌㄧㄥ":"得令",
"ㄉㄜㄍㄨㄛ":"得過/得果",
"ㄉㄜㄍㄨㄤ":"德廣",
"ㄉㄜㄎㄨㄥ":"得空",
"ㄉㄜㄏㄨㄚ":"德化",
"ㄉㄜㄏㄨㄟ":"德惠",
"ㄉㄜㄐㄧㄝ":"得解",
"ㄉㄜㄐㄧㄡ":"得救",
"ㄉㄜㄐㄧㄢ":"得間",
"ㄉㄜㄐㄧㄣ":"得勁",
"ㄉㄜㄐㄧㄤ":"得獎",
"ㄉㄜㄐㄩㄣ":"得雋",
"ㄉㄜㄑㄧㄠ":"得竅",
"ㄉㄜㄑㄧㄣ":"德禽",
"ㄉㄜㄑㄩㄝ":"得缺",
"ㄉㄜㄒㄧㄢ":"得閒",
"ㄉㄜㄒㄧㄥ":"德行/得幸",
"ㄉㄜㄒㄩㄥ":"得雄",
"ㄉㄜㄓㄨㄥ":"得衷",
"ㄉㄜㄔㄨㄥ":"得寵",
"ㄉㄜㄖㄣㄧ":"得人意",
"ㄉㄜㄗㄨㄟ":"得罪",
"ㄉㄜㄙㄨㄛ":"得所",
"ㄉㄜㄤㄗㄨ":"德昂族",
"ㄉㄜㄧㄓㄕ":"得意之事",
"ㄉㄜㄧㄓㄩ":"得意之餘",
"ㄉㄜㄧㄤㄦ":"得樣兒",
"ㄉㄜㄧㄩㄢ":"得意緣",
"ㄉㄞㄅㄚㄗ":"帶把子",
"ㄉㄞㄅㄧㄠ":"代表",
"ㄉㄞㄅㄧㄥ":"帶兵",
"ㄉㄞㄇㄠㄗ":"戴帽子",
"ㄉㄞㄇㄧㄢ":"代面",
"ㄉㄞㄇㄧㄥ":"待命/歹命",
"ㄉㄞㄉㄧㄢ":"代電/帶電",
"ㄉㄞㄉㄨㄗ":"帶肚子",
"ㄉㄞㄉㄨㄛ":"怠惰",
"ㄉㄞㄉㄨㄥ":"帶動/代東",
"ㄉㄞㄊㄧㄢ":"戴天",
"ㄉㄞㄊㄧㄨ":"代替物",
"ㄉㄞㄊㄨㄥ":"帶同",
"ㄉㄞㄋㄧㄠ":"呆鳥",
"ㄉㄞㄋㄧㄢ":"待年",
"ㄉㄞㄌㄧㄡ":"代留",
"ㄉㄞㄌㄧㄤ":"玳梁",
"ㄉㄞㄌㄧㄥ":"帶領/代領",
"ㄉㄞㄌㄨㄛ":"黛螺",
"ㄉㄞㄍㄣㄗ":"獃根子",
"ㄉㄞㄍㄨㄚ":"呆瓜",
"ㄉㄞㄍㄨㄢ":"代官/代管",
"ㄉㄞㄍㄨㄥ":"怠工/代工",
"ㄉㄞㄎㄨㄟ":"戴逵",
"ㄉㄞㄎㄨㄢ":"貸款",
"ㄉㄞㄏㄞㄗ":"帶孩子",
"ㄉㄞㄏㄨㄚ":"歹話/獃話",
"ㄉㄞㄏㄨㄛ":"呆貨",
"ㄉㄞㄏㄨㄢ":"怠緩/黛鬟",
"ㄉㄞㄐㄧㄚ":"代價/帶甲",
"ㄉㄞㄐㄧㄝ":"貸借",
"ㄉㄞㄐㄧㄠ":"帶教",
"ㄉㄞㄐㄧㄢ":"代見/待見",
"ㄉㄞㄐㄧㄣ":"代金/帶勁",
"ㄉㄞㄐㄩㄢ":"怠倦",
"ㄉㄞㄐㄩㄣ":"帶菌",
"ㄉㄞㄑㄧㄝ":"帶挈",
"ㄉㄞㄒㄧㄚ":"逮下",
"ㄉㄞㄒㄧㄝ":"代寫/代謝",
"ㄉㄞㄒㄧㄠ":"帶笑/呆笑",
"ㄉㄞㄒㄧㄣ":"歹心/帶信",
"ㄉㄞㄒㄧㄤ":"呆想/獃想",
"ㄉㄞㄒㄧㄥ":"代興/獃性",
"ㄉㄞㄒㄩㄥ":"袋熊",
"ㄉㄞㄓㄨㄤ":"帶狀",
"ㄉㄞㄓㄨㄥ":"待中",
"ㄉㄞㄔㄢㄕ":"待產室",
"ㄉㄞㄕㄊㄡ":"歹事頭",
"ㄉㄞㄕㄡㄦ":"帶手兒",
"ㄉㄞㄕㄣㄗ":"帶身子",
"ㄉㄞㄕㄨㄕ":"代數式",
"ㄉㄞㄕㄨㄟ":"帶水",
"ㄉㄞㄗㄨㄛ":"呆坐",
"ㄉㄞㄗㄨㄟ":"待罪",
"ㄉㄞㄗㄨㄥ":"岱宗",
"ㄉㄞㄙㄨㄥ":"戴嵩",
"ㄉㄞㄢㄋㄚ":"黛安娜",
"ㄉㄠㄅㄚㄦ":"刀把兒",
"ㄉㄠㄅㄧㄝ":"道別",
"ㄉㄠㄅㄧㄠ":"導標",
"ㄉㄠㄅㄧㄥ":"倒兵/刀兵",
"ㄉㄠㄅㄨㄕ":"倒不是",
"ㄉㄠㄆㄧㄢ":"刀片/倒片",
"ㄉㄠㄇㄧㄠ":"稻苗",
"ㄉㄠㄇㄧㄣ":"島民",
"ㄉㄠㄉㄧㄠ":"道調/倒吊",
"ㄉㄠㄉㄧㄢ":"道店/導電",
"ㄉㄠㄉㄧㄥ":"道定/道釘",
"ㄉㄠㄉㄨㄟ":"搗碓",
"ㄉㄠㄉㄨㄢ":"道斷/倒斷",
"ㄉㄠㄉㄨㄣ":"倒頓",
"ㄉㄠㄊㄧㄝ":"倒貼",
"ㄉㄠㄊㄧㄢ":"稻田",
"ㄉㄠㄊㄨㄟ":"倒退",
"ㄉㄠㄊㄨㄣ":"倒褪",
"ㄉㄠㄊㄨㄥ":"道統/道童",
"ㄉㄠㄋㄧㄠ":"導尿",
"ㄉㄠㄋㄧㄢ":"悼念/叨念",
"ㄉㄠㄋㄨㄥ":"稻農/盜弄",
"ㄉㄠㄌㄧㄝ":"盜獵",
"ㄉㄠㄌㄧㄡ":"倒流",
"ㄉㄠㄌㄧㄣ":"到臨/盜林",
"ㄉㄠㄌㄧㄤ":"道糧/稻粱",
"ㄉㄠㄌㄧㄥ":"盜領",
"ㄉㄠㄌㄨㄙ":"倒路死",
"ㄉㄠㄌㄨㄛ":"搗羅",
"ㄉㄠㄌㄨㄢ":"搗亂/倒亂",
"ㄉㄠㄌㄨㄣ":"導論",
"ㄉㄠㄍㄨㄛ":"島國/道果",
"ㄉㄠㄍㄨㄟ":"搗鬼/刀圭",
"ㄉㄠㄍㄨㄢ":"導管/道觀",
"ㄉㄠㄍㄨㄤ":"道光/刀光",
"ㄉㄠㄎㄨㄟ":"道揆/盜魁",
"ㄉㄠㄏㄨㄚ":"道話",
"ㄉㄠㄏㄨㄟ":"倒會/搗毀",
"ㄉㄠㄏㄨㄢ":"倒換/刀環",
"ㄉㄠㄏㄨㄤ":"搗謊",
"ㄉㄠㄐㄧㄚ":"到家/道家",
"ㄉㄠㄐㄧㄝ":"盜劫",
"ㄉㄠㄐㄧㄠ":"道教/倒嚼",
"ㄉㄠㄐㄧㄢ":"道鑒/刀劍",
"ㄉㄠㄐㄧㄣ":"道殣",
"ㄉㄠㄐㄧㄥ":"道經",
"ㄉㄠㄐㄩㄣ":"道君",
"ㄉㄠㄑㄧㄝ":"盜竊",
"ㄉㄠㄑㄧㄡ":"禱求",
"ㄉㄠㄑㄧㄢ":"道歉/倒錢",
"ㄉㄠㄑㄧㄤ":"刀槍/倒嗆",
"ㄉㄠㄑㄧㄥ":"道情",
"ㄉㄠㄑㄩㄢ":"盜泉",
"ㄉㄠㄒㄧㄚ":"倒下",
"ㄉㄠㄒㄧㄝ":"道謝/倒瀉",
"ㄉㄠㄒㄧㄢ":"導線/捯線",
"ㄉㄠㄒㄧㄣ":"道心/倒心",
"ㄉㄠㄒㄧㄤ":"導向/稻香",
"ㄉㄠㄒㄧㄥ":"蹈行",
"ㄉㄠㄒㄩㄝ":"道學",
"ㄉㄠㄒㄩㄢ":"道宣/倒懸",
"ㄉㄠㄓㄈㄚ":"倒置法",
"ㄉㄠㄓㄨㄟ":"刀錐",
"ㄉㄠㄓㄨㄢ":"倒轉",
"ㄉㄠㄓㄨㄤ":"倒裝",
"ㄉㄠㄓㄨㄥ":"稻種/盜鐘",
"ㄉㄠㄔㄚㄗ":"搗叉子",
"ㄉㄠㄔㄨㄛ":"道綽",
"ㄉㄠㄕㄥㄓ":"導生制",
"ㄉㄠㄕㄨㄟ":"導水",
"ㄉㄠㄗㄏㄠ":"道字號",
"ㄉㄠㄗㄨㄛ":"道左/稻作",
"ㄉㄠㄗㄨㄣ":"道尊",
"ㄉㄠㄘㄨㄛ":"倒錯",
"ㄉㄠㄘㄨㄢ":"蹈爨",
"ㄉㄠㄙㄤㄗ":"搗嗓子",
"ㄉㄠㄙㄨㄟ":"搗碎/稻穗",
"ㄉㄠㄙㄨㄢ":"搗蒜",
"ㄉㄠㄧㄔㄨ":"搗衣杵",
"ㄉㄠㄨㄤㄕ":"悼亡詩",
"ㄉㄠㄩㄇㄚ":"盜御馬",
"ㄉㄠㄩㄓㄡ":"島宇宙",
"ㄉㄡㄅㄧㄠ":"斗杓",
"ㄉㄡㄅㄧㄥ":"豆餅/斗柄",
"ㄉㄡㄇㄣㄗ":"鬥悶子",
"ㄉㄡㄇㄧㄠ":"豆苗/痘苗",
"ㄉㄡㄈㄣㄗ":"鬥分子",
"ㄉㄡㄈㄨㄧ":"豆腐衣",
"ㄉㄡㄉㄧㄝ":"鬥疊",
"ㄉㄡㄉㄧㄢ":"逗點/斗店",
"ㄉㄡㄉㄧㄥ":"鬥飣/餖飣",
"ㄉㄡㄉㄨㄣ":"斗頓/陡頓",
"ㄉㄡㄉㄨㄥ":"抖動",
"ㄉㄡㄉㄨㄦ":"兜肚兒",
"ㄉㄡㄊㄧㄠ":"豆條",
"ㄉㄡㄊㄨㄛ":"兜拖",
"ㄉㄡㄊㄨㄟ":"抖腿",
"ㄉㄡㄋㄧㄡ":"鬥牛/斗牛",
"ㄉㄡㄋㄧㄤ":"豆娘",
"ㄉㄡㄋㄨㄥ":"逗弄",
"ㄉㄡㄌㄧㄡ":"逗留/逗遛",
"ㄉㄡㄌㄧㄢ":"兜臉",
"ㄉㄡㄌㄨㄛ":"兜羅",
"ㄉㄡㄌㄨㄥ":"兜籠/逗攏",
"ㄉㄡㄍㄨㄥ":"斗拱/斗栱",
"ㄉㄡㄎㄡㄔ":"鬥口齒",
"ㄉㄡㄎㄨㄟ":"斗魁",
"ㄉㄡㄏㄨㄚ":"鬥話/豆花",
"ㄉㄡㄏㄨㄞ":"豆槐",
"ㄉㄡㄏㄨㄟ":"抖灰",
"ㄉㄡㄏㄨㄥ":"鬥鬨",
"ㄉㄡㄐㄧㄚ":"豆莢",
"ㄉㄡㄐㄧㄠ":"豆角/鬥腳",
"ㄉㄡㄐㄧㄡ":"斗酒",
"ㄉㄡㄐㄧㄢ":"鬥艦",
"ㄉㄡㄐㄧㄣ":"鬥勁",
"ㄉㄡㄐㄧㄤ":"豆漿/豆醬",
"ㄉㄡㄐㄩㄝ":"斗絕",
"ㄉㄡㄐㄩㄣ":"陡峻",
"ㄉㄡㄑㄧㄠ":"鬥巧/陡峭",
"ㄉㄡㄑㄧㄢ":"豆簽",
"ㄉㄡㄑㄧㄥ":"豆青",
"ㄉㄡㄑㄩㄢ":"鬥犬",
"ㄉㄡㄒㄧㄝ":"陡斜",
"ㄉㄡㄒㄧㄠ":"逗笑/兜銷",
"ㄉㄡㄒㄧㄢ":"竇憲",
"ㄉㄡㄒㄧㄤ":"斗香/豆象",
"ㄉㄡㄒㄩㄥ":"兜胸",
"ㄉㄡㄔㄧㄡ":"豆豉油",
"ㄉㄡㄔㄨㄤ":"痘瘡",
"ㄉㄡㄕㄠㄧ":"斗筲役",
"ㄉㄡㄖㄨㄥ":"竇融/豆蓉",
"ㄉㄡㄗㄨㄟ":"鬥嘴/兜嘴",
"ㄉㄡㄜㄩㄢ":"竇娥冤",
"ㄉㄡㄦㄉㄚ":"豆兒大",
"ㄉㄡㄧㄝㄗ":"鬥葉子",
"ㄉㄢㄅㄞㄓ":"蛋白質",
"ㄉㄢㄅㄠㄗ":"單孢子",
"ㄉㄢㄅㄧㄥ":"蛋餅",
"ㄉㄢㄅㄨㄕ":"擔不是",
"ㄉㄢㄆㄞㄗ":"單拍子",
"ㄉㄢㄆㄧㄠ":"簞瓢",
"ㄉㄢㄆㄧㄢ":"彈片",
"ㄉㄢㄆㄧㄥ":"單憑/膽瓶",
"ㄉㄢㄇㄞㄩ":"丹麥語",
"ㄉㄢㄇㄧㄢ":"擔免/耽湎",
"ㄉㄢㄇㄧㄣ":"但民/蜑民",
"ㄉㄢㄇㄧㄥ":"旦明/單名",
"ㄉㄢㄉㄚㄧ":"單打一",
"ㄉㄢㄉㄧㄠ":"單調",
"ㄉㄢㄉㄧㄥ":"單丁/丹鼎",
"ㄉㄢㄊㄧㄗ":"單體字",
"ㄉㄢㄊㄧㄠ":"單挑",
"ㄉㄢㄊㄧㄢ":"丹田",
"ㄉㄢㄋㄧㄠ":"丹鳥",
"ㄉㄢㄋㄧㄥ":"單寧",
"ㄉㄢㄋㄩㄝ":"癉瘧",
"ㄉㄢㄌㄧㄡ":"丹溜",
"ㄉㄢㄌㄧㄢ":"單戀",
"ㄉㄢㄌㄧㄤ":"膽量",
"ㄉㄢㄌㄨㄛ":"膽落",
"ㄉㄢㄌㄨㄣ":"丹輪",
"ㄉㄢㄌㄩㄝ":"膽略",
"ㄉㄢㄍㄜㄦ":"單個兒",
"ㄉㄢㄍㄨㄚ":"單褂",
"ㄉㄢㄍㄨㄟ":"單跪/單軌",
"ㄉㄢㄍㄨㄥ":"彈弓",
"ㄉㄢㄎㄨㄢ":"丹款",
"ㄉㄢㄏㄨㄚ":"淡話/澹話",
"ㄉㄢㄏㄨㄛ":"丹雘",
"ㄉㄢㄏㄨㄟ":"旦會/撣灰",
"ㄉㄢㄏㄨㄢ":"氮環",
"ㄉㄢㄏㄨㄤ":"蛋黃/丹黃",
"ㄉㄢㄐㄧㄚ":"蜑家/單家",
"ㄉㄢㄐㄧㄠ":"淡交/擔腳",
"ㄉㄢㄐㄧㄡ":"耽酒",
"ㄉㄢㄐㄧㄢ":"單間/擔肩",
"ㄉㄢㄐㄧㄣ":"殫盡/丹禁",
"ㄉㄢㄐㄧㄤ":"淡江",
"ㄉㄢㄐㄧㄥ":"丹經/單徑",
"ㄉㄢㄐㄩㄝ":"旦角/丹訣",
"ㄉㄢㄐㄩㄢ":"蛋卷",
"ㄉㄢㄑㄧㄡ":"丹丘",
"ㄉㄢㄑㄧㄢ":"丹鉛",
"ㄉㄢㄑㄧㄣ":"單衾",
"ㄉㄢㄑㄧㄥ":"丹青/蛋清",
"ㄉㄢㄑㄩㄝ":"膽怯/丹闕",
"ㄉㄢㄒㄧㄚ":"丹霞",
"ㄉㄢㄒㄧㄠ":"膽小/丹霄",
"ㄉㄢㄒㄧㄢ":"單線/單弦",
"ㄉㄢㄒㄧㄣ":"擔心/丹心",
"ㄉㄢㄒㄧㄤ":"單向/單相",
"ㄉㄢㄒㄧㄥ":"單行",
"ㄉㄢㄒㄩㄝ":"丹穴",
"ㄉㄢㄒㄩㄢ":"單選",
"ㄉㄢㄓㄨㄤ":"淡妝/膽壯",
"ㄉㄢㄓㄨㄥ":"膻中/丹衷",
"ㄉㄢㄓㄨㄦ":"丹珠爾",
"ㄉㄢㄔㄨㄢ":"單傳",
"ㄉㄢㄔㄨㄣ":"單純/丹脣",
"ㄉㄢㄕㄨㄛ":"單說",
"ㄉㄢㄕㄨㄟ":"淡水/擔水",
"ㄉㄢㄖㄨㄛ":"單弱",
"ㄉㄢㄗㄈㄤ":"彈子房",
"ㄉㄢㄗㄊㄞ":"彈子檯",
"ㄉㄢㄗㄜㄕ":"單則是",
"ㄉㄢㄘㄨㄛ":"擔錯",
"ㄉㄢㄙㄜㄘ":"單色瓷",
"ㄉㄢㄧㄣㄘ":"單音詞",
"ㄉㄢㄧㄣㄩ":"單音語",
"ㄉㄢㄨㄟㄘ":"單位詞",
"ㄉㄤㄅㄧㄢ":"黨鞭/當便",
"ㄉㄤㄅㄧㄥ":"當兵",
"ㄉㄤㄆㄧㄠ":"當票",
"ㄉㄤㄆㄧㄥ":"蕩平",
"ㄉㄤㄇㄧㄢ":"當面",
"ㄉㄤㄇㄧㄥ":"檔名",
"ㄉㄤㄉㄧㄠ":"當掉",
"ㄉㄤㄊㄧㄢ":"當天/蕩田",
"ㄉㄤㄊㄨㄢ":"黨團",
"ㄉㄤㄋㄧㄢ":"當年",
"ㄉㄤㄌㄧㄥ":"當令/黨齡",
"ㄉㄤㄌㄨㄗ":"當路子",
"ㄉㄤㄌㄨㄣ":"黨論/讜論",
"ㄉㄤㄍㄨㄛ":"當國/黨國",
"ㄉㄤㄍㄨㄟ":"當歸",
"ㄉㄤㄍㄨㄢ":"當關/當官",
"ㄉㄤㄍㄨㄣ":"黨棍",
"ㄉㄤㄎㄨㄟ":"黨魁",
"ㄉㄤㄎㄨㄥ":"當空",
"ㄉㄤㄏㄥㄦ":"擋橫兒",
"ㄉㄤㄏㄨㄚ":"當花",
"ㄉㄤㄏㄨㄛ":"黨禍",
"ㄉㄤㄏㄨㄟ":"黨徽",
"ㄉㄤㄐㄧㄚ":"當家/擋駕",
"ㄉㄤㄐㄧㄝ":"當街",
"ㄉㄤㄐㄧㄡ":"盪酒",
"ㄉㄤㄐㄧㄢ":"當間/黨見",
"ㄉㄤㄐㄧㄣ":"當今/當緊",
"ㄉㄤㄐㄩㄝ":"蕩潏",
"ㄉㄤㄑㄧㄢ":"當前",
"ㄉㄤㄑㄧㄤ":"擋搶/攩戧",
"ㄉㄤㄑㄩㄢ":"當權",
"ㄉㄤㄒㄧㄚ":"當下",
"ㄉㄤㄒㄧㄠ":"黨校",
"ㄉㄤㄒㄧㄡ":"擋修",
"ㄉㄤㄒㄧㄢ":"當先",
"ㄉㄤㄒㄧㄣ":"當心/蕩心",
"ㄉㄤㄒㄧㄤ":"党項",
"ㄉㄤㄒㄧㄥ":"黨性",
"ㄉㄤㄒㄩㄢ":"當選",
"ㄉㄤㄒㄩㄣ":"當巡",
"ㄉㄤㄓㄉㄜ":"當直的",
"ㄉㄤㄓㄨㄥ":"當中/當眾",
"ㄉㄤㄔㄨㄢ":"蕩船",
"ㄉㄤㄕㄓㄜ":"當事者",
"ㄉㄤㄕㄖㄣ":"當事人",
"ㄉㄤㄗㄅㄢ":"檔子班",
"ㄉㄤㄗㄨㄛ":"當做/當作",
"ㄉㄤㄘㄨㄣ":"當村",
"ㄉㄥㄇㄧㄠ":"燈苗",
"ㄉㄥㄉㄧㄢ":"登殿",
"ㄉㄥㄉㄨㄟ":"登對",
"ㄉㄥㄉㄨㄥ":"登東",
"ㄉㄥㄊㄧㄢ":"登天",
"ㄉㄥㄊㄨㄗ":"登徒子",
"ㄉㄥㄊㄨㄛ":"蹬脫",
"ㄉㄥㄊㄨㄥ":"等同/鄧通",
"ㄉㄥㄌㄧㄣ":"登臨",
"ㄉㄥㄌㄧㄤ":"等量",
"ㄉㄥㄌㄨㄣ":"等倫",
"ㄉㄥㄌㄨㄥ":"登龍/登壟",
"ㄉㄥㄍㄠㄓ":"登高枝",
"ㄉㄥㄍㄨㄚ":"燈掛",
"ㄉㄥㄍㄨㄢ":"燈管",
"ㄉㄥㄍㄨㄤ":"燈光",
"ㄉㄥㄎㄨㄥ":"燈控",
"ㄉㄥㄏㄨㄚ":"燈花",
"ㄉㄥㄏㄨㄛ":"燈火",
"ㄉㄥㄏㄨㄟ":"燈會",
"ㄉㄥㄐㄧㄕ":"登機室",
"ㄉㄥㄐㄧㄚ":"等價",
"ㄉㄥㄐㄧㄝ":"燈節",
"ㄉㄥㄐㄧㄠ":"蹬腳",
"ㄉㄥㄐㄧㄣ":"登進",
"ㄉㄥㄑㄧㄡ":"燈毬",
"ㄉㄥㄑㄧㄥ":"澄清/燈檠",
"ㄉㄥㄒㄧㄚ":"燈下/登遐",
"ㄉㄥㄒㄧㄠ":"燈宵",
"ㄉㄥㄒㄧㄢ":"等閒/登仙",
"ㄉㄥㄒㄧㄣ":"燈心/燈芯",
"ㄉㄥㄔㄨㄢ":"燈船",
"ㄉㄥㄔㄨㄥ":"登崇",
"ㄉㄥㄞㄐㄧ":"鄧艾吃",
"ㄉㄧㄅㄧㄠ":"地標/地表",
"ㄉㄧㄅㄧㄢ":"地變/底邊",
"ㄉㄧㄅㄧㄥ":"底冰",
"ㄉㄧㄆㄧㄢ":"底片",
"ㄉㄧㄆㄧㄥ":"地坪/低平",
"ㄉㄧㄇㄧㄢ":"地面/底面",
"ㄉㄧㄇㄧㄥ":"帝命/抵命",
"ㄉㄧㄈㄤㄓ":"地方志",
"ㄉㄧㄉㄧㄠ":"低調",
"ㄉㄧㄉㄧㄢ":"地點/低點",
"ㄉㄧㄉㄧㄥ":"地丁/底定",
"ㄉㄧㄉㄨㄟ":"敵對/抵對",
"ㄉㄧㄉㄨㄢ":"地段",
"ㄉㄧㄉㄨㄥ":"地動/地洞",
"ㄉㄧㄊㄢㄕ":"地毯式",
"ㄉㄧㄊㄤㄓ":"嫡堂姪",
"ㄉㄧㄊㄧㄝ":"地鐵",
"ㄉㄧㄊㄧㄠ":"糴糶",
"ㄉㄧㄊㄧㄥ":"諦聽/帝庭",
"ㄉㄧㄋㄥㄦ":"低能兒",
"ㄉㄧㄋㄧㄥ":"底寧",
"ㄉㄧㄌㄧㄕ":"地理師",
"ㄉㄧㄌㄧㄝ":"低劣",
"ㄉㄧㄌㄧㄡ":"滴流/遞流",
"ㄉㄧㄌㄧㄢ":"低廉",
"ㄉㄧㄌㄧㄣ":"地鄰",
"ㄉㄧㄌㄧㄤ":"藋粱",
"ㄉㄧㄌㄧㄥ":"地靈",
"ㄉㄧㄌㄨㄛ":"低落/滴落",
"ㄉㄧㄌㄨㄥ":"地龍",
"ㄉㄧㄍㄨㄚ":"地瓜",
"ㄉㄧㄍㄨㄛ":"帝國/敵國",
"ㄉㄧㄍㄨㄢ":"滴管",
"ㄉㄧㄍㄨㄣ":"地棍",
"ㄉㄧㄍㄨㄥ":"地宮/底工",
"ㄉㄧㄎㄨㄥ":"低空",
"ㄉㄧㄏㄨㄚ":"地滑/棣華",
"ㄉㄧㄏㄨㄛ":"底火",
"ㄉㄧㄏㄨㄟ":"詆毀/低回",
"ㄉㄧㄏㄨㄢ":"低鬟/抵換",
"ㄉㄧㄏㄨㄣ":"帝閽",
"ㄉㄧㄏㄨㄤ":"地黃/地荒",
"ㄉㄧㄐㄧㄚ":"底價/低價",
"ㄉㄧㄐㄧㄝ":"地界/遞解",
"ㄉㄧㄐㄧㄠ":"地角/地腳",
"ㄉㄧㄐㄧㄢ":"低賤/遞減",
"ㄉㄧㄐㄧㄣ":"低進/底襟",
"ㄉㄧㄐㄧㄥ":"地經/敵境",
"ㄉㄧㄐㄩㄝ":"的決",
"ㄉㄧㄐㄩㄣ":"敵軍/帝君",
"ㄉㄧㄑㄧㄠ":"詆誚",
"ㄉㄧㄑㄧㄡ":"地球/敵酋",
"ㄉㄧㄑㄧㄢ":"地塹/低淺",
"ㄉㄧㄑㄧㄣ":"地勤/嫡親",
"ㄉㄧㄑㄧㄤ":"氐羌",
"ㄉㄧㄑㄧㄥ":"敵情",
"ㄉㄧㄑㄩㄝ":"的確/底缺",
"ㄉㄧㄑㄩㄢ":"地權/地圈",
"ㄉㄧㄒㄧㄚ":"地下/地下",
"ㄉㄧㄒㄧㄝ":"的些/滴血",
"ㄉㄧㄒㄧㄠ":"抵銷/抵消",
"ㄉㄧㄒㄧㄢ":"底線/地陷",
"ㄉㄧㄒㄧㄣ":"地心/底薪",
"ㄉㄧㄒㄧㄤ":"帝鄉",
"ㄉㄧㄒㄧㄥ":"地形/帝星",
"ㄉㄧㄒㄩㄣ":"遞訓",
"ㄉㄧㄒㄩㄥ":"低胸/弟兄",
"ㄉㄧㄓㄣㄧ":"地震儀",
"ㄉㄧㄓㄤㄗ":"嫡長子",
"ㄉㄧㄓㄨㄛ":"地著",
"ㄉㄧㄓㄨㄢ":"地磚",
"ㄉㄧㄓㄨㄤ":"抵樁",
"ㄉㄧㄔㄥㄓ":"遞呈紙",
"ㄉㄧㄔㄨㄟ":"低垂",
"ㄉㄧㄔㄨㄢ":"嫡傳",
"ㄉㄧㄔㄨㄣ":"遞春",
"ㄉㄧㄔㄨㄤ":"締創",
"ㄉㄧㄕㄤㄨ":"地上物",
"ㄉㄧㄕㄨㄟ":"滴水/抵稅",
"ㄉㄧㄗㄅㄟ":"翟子悲",
"ㄉㄧㄗㄊㄞ":"低姿態",
"ㄉㄧㄗㄤㄙ":"地藏寺",
"ㄉㄧㄗㄨㄛ":"底座/帝祚",
"ㄉㄧㄗㄨㄟ":"抵罪",
"ㄉㄧㄗㄨㄥ":"敵蹤",
"ㄉㄧㄗㄩㄢ":"弟子員",
"ㄉㄧㄙㄊㄞ":"第四臺",
"ㄉㄧㄙㄎㄜ":"迪斯可",
"ㄉㄧㄙㄕㄣ":"第四審",
"ㄉㄧㄙㄨㄣ":"適孫/嫡孫",
"ㄉㄧㄙㄨㄥ":"遞送",
"ㄉㄧㄝㄅㄟ":"疊被",
"ㄉㄧㄝㄅㄠ":"諜報",
"ㄉㄧㄝㄅㄢ":"迭辦",
"ㄉㄧㄝㄆㄛ":"跌破",
"ㄉㄧㄝㄆㄟ":"迭配",
"ㄉㄧㄝㄆㄨ":"跌扑",
"ㄉㄧㄝㄇㄚ":"爹媽",
"ㄉㄧㄝㄇㄥ":"蝶夢",
"ㄉㄧㄝㄈㄚ":"牒發",
"ㄉㄧㄝㄈㄨ":"跌幅",
"ㄉㄧㄝㄉㄚ":"跌打",
"ㄉㄧㄝㄉㄞ":"迭代",
"ㄉㄧㄝㄉㄠ":"跌倒",
"ㄉㄧㄝㄉㄤ":"跌蕩/跌宕",
"ㄉㄧㄝㄉㄧ":"爹地",
"ㄉㄧㄝㄊㄡ":"迭頭",
"ㄉㄧㄝㄌㄟ":"蝶類",
"ㄉㄧㄝㄎㄜ":"跌磕",
"ㄉㄧㄝㄐㄧ":"牒記/牒籍",
"ㄉㄧㄝㄐㄩ":"疊句",
"ㄉㄧㄝㄑㄧ":"迭起/疊棋",
"ㄉㄧㄝㄓㄤ":"疊嶂",
"ㄉㄧㄝㄔㄥ":"迭乘",
"ㄉㄧㄝㄕㄚ":"碟煞",
"ㄉㄧㄝㄗㄨ":"跌足/蹀足",
"ㄉㄧㄝㄙㄨ":"牒訴",
"ㄉㄧㄝㄧㄢ":"疊巘",
"ㄉㄧㄝㄧㄣ":"疊印/跌窨",
"ㄉㄧㄝㄧㄥ":"疊映",
"ㄉㄧㄝㄨㄣ":"牒文",
"ㄉㄧㄝㄩㄣ":"疊韻",
"ㄉㄧㄝㄩㄥ":"蝶泳/蝶蛹",
"ㄉㄧㄠㄅㄛ":"調撥",
"ㄉㄧㄠㄅㄞ":"凋敗",
"ㄉㄧㄠㄅㄠ":"碉堡/調包",
"ㄉㄧㄠㄅㄢ":"調班/雕版",
"ㄉㄧㄠㄅㄧ":"掉臂/凋弊",
"ㄉㄧㄠㄅㄨ":"調補",
"ㄉㄧㄠㄆㄞ":"調派",
"ㄉㄧㄠㄆㄟ":"調配",
"ㄉㄧㄠㄆㄥ":"釣篷",
"ㄉㄧㄠㄆㄧ":"掉皮/刁皮",
"ㄉㄧㄠㄆㄨ":"吊鋪",
"ㄉㄧㄠㄇㄠ":"屌毛/吊毛",
"ㄉㄧㄠㄇㄢ":"刁蠻",
"ㄉㄧㄠㄇㄣ":"調門",
"ㄉㄧㄠㄈㄚ":"調發/吊伐",
"ㄉㄧㄠㄈㄤ":"調防/碉房",
"ㄉㄧㄠㄈㄥ":"刁風",
"ㄉㄧㄠㄉㄞ":"吊帶",
"ㄉㄧㄠㄉㄠ":"掉刀",
"ㄉㄧㄠㄉㄡ":"刁斗",
"ㄉㄧㄠㄉㄤ":"調當/貂璫",
"ㄉㄧㄠㄉㄥ":"吊燈/刁蹬",
"ㄉㄧㄠㄉㄨ":"調度",
"ㄉㄧㄠㄊㄞ":"掉胎",
"ㄉㄧㄠㄊㄡ":"掉頭/調頭",
"ㄉㄧㄠㄊㄧ":"雕題",
"ㄉㄧㄠㄊㄨ":"釣徒/刁徒",
"ㄉㄧㄠㄋㄢ":"刁難",
"ㄉㄧㄠㄌㄟ":"調類",
"ㄉㄧㄠㄌㄡ":"吊樓/碉樓",
"ㄉㄧㄠㄌㄢ":"雕欄",
"ㄉㄧㄠㄌㄧ":"琱麗/彫勵",
"ㄉㄧㄠㄍㄜ":"雕戈",
"ㄉㄧㄠㄍㄡ":"吊鉤/釣鉤",
"ㄉㄧㄠㄍㄢ":"釣竿",
"ㄉㄧㄠㄍㄨ":"弔古",
"ㄉㄧㄠㄎㄜ":"雕刻/彫刻",
"ㄉㄧㄠㄎㄞ":"調開/掉開",
"ㄉㄧㄠㄎㄠ":"弔拷",
"ㄉㄧㄠㄎㄡ":"吊扣/弔扣",
"ㄉㄧㄠㄎㄢ":"調坎",
"ㄉㄧㄠㄏㄜ":"弔鶴",
"ㄉㄧㄠㄏㄠ":"調號",
"ㄉㄧㄠㄏㄡ":"調喉/弔喉",
"ㄉㄧㄠㄏㄢ":"雕悍/刁悍",
"ㄉㄧㄠㄐㄧ":"調集/弔祭",
"ㄉㄧㄠㄐㄩ":"釣具",
"ㄉㄧㄠㄑㄧ":"雕砌/雕漆",
"ㄉㄧㄠㄑㄩ":"弔取",
"ㄉㄧㄠㄓㄚ":"刁詐",
"ㄉㄧㄠㄓㄣ":"調陣",
"ㄉㄧㄠㄓㄨ":"調駐",
"ㄉㄧㄠㄔㄚ":"調查",
"ㄉㄧㄠㄔㄜ":"吊車",
"ㄉㄧㄠㄔㄞ":"調差",
"ㄉㄧㄠㄔㄢ":"貂蟬",
"ㄉㄧㄠㄔㄤ":"吊場/弔場",
"ㄉㄧㄠㄕㄜ":"掉舌",
"ㄉㄧㄠㄕㄠ":"吊稍",
"ㄉㄧㄠㄕㄢ":"吊扇",
"ㄉㄧㄠㄕㄨ":"弔書/凋疏",
"ㄉㄧㄠㄖㄣ":"鳥人/釣人",
"ㄉㄧㄠㄘㄢ":"凋殘/彫殘",
"ㄉㄧㄠㄘㄨ":"雕簇",
"ㄉㄧㄠㄙㄚ":"弔撒",
"ㄉㄧㄠㄙㄜ":"掉色/雕色",
"ㄉㄧㄠㄙㄞ":"調賽",
"ㄉㄧㄠㄙㄠ":"刁騷/彫騷",
"ㄉㄧㄠㄙㄡ":"釣叟/彫颼",
"ㄉㄧㄠㄙㄤ":"調嗓/弔喪",
"ㄉㄧㄠㄙㄨ":"雕塑",
"ㄉㄧㄠㄧㄡ":"釣友",
"ㄉㄧㄠㄧㄢ":"掉眼/調演",
"ㄉㄧㄠㄧㄤ":"掉鞅",
"ㄉㄧㄠㄧㄥ":"弔影",
"ㄉㄧㄠㄨㄞ":"調歪",
"ㄉㄧㄠㄨㄟ":"弔慰/凋萎",
"ㄉㄧㄠㄨㄢ":"刁頑",
"ㄉㄧㄠㄨㄣ":"掉文/調問",
"ㄉㄧㄠㄨㄤ":"凋亡",
"ㄉㄧㄠㄩㄝ":"調閱",
"ㄉㄧㄠㄩㄢ":"窵遠",
"ㄉㄧㄠㄩㄣ":"調運/雕雲",
"ㄉㄧㄠㄩㄥ":"調用",
"ㄉㄧㄡㄇㄛ":"丟抹/颩抹",
"ㄉㄧㄡㄉㄚ":"丟搭",
"ㄉㄧㄡㄎㄞ":"丟開",
"ㄉㄧㄡㄑㄧ":"丟棄",
"ㄉㄧㄡㄔㄡ":"丟醜",
"ㄉㄧㄡㄕㄡ":"丟手",
"ㄉㄧㄡㄕㄥ":"丟生",
"ㄉㄧㄡㄖㄣ":"丟人",
"ㄉㄧㄢㄅㄚ":"點把",
"ㄉㄧㄢㄅㄛ":"電波/顛簸",
"ㄉㄧㄢㄅㄟ":"墊被/墊背",
"ㄉㄧㄢㄅㄠ":"電報/店保",
"ㄉㄧㄢㄅㄢ":"殿版/墊板",
"ㄉㄧㄢㄅㄣ":"殿本",
"ㄉㄧㄢㄅㄤ":"點榜/電棒",
"ㄉㄧㄢㄅㄨ":"點補/墊補",
"ㄉㄧㄢㄆㄛ":"點破",
"ㄉㄧㄢㄆㄟ":"顛沛",
"ㄉㄧㄢㄆㄨ":"典鋪/顛仆",
"ㄉㄧㄢㄇㄚ":"電碼",
"ㄉㄧㄢㄇㄛ":"典沒/墊沒",
"ㄉㄧㄢㄇㄞ":"典賣",
"ㄉㄧㄢㄇㄠ":"點卯/顛毛",
"ㄉㄧㄢㄇㄣ":"電門",
"ㄉㄧㄢㄇㄨ":"電母",
"ㄉㄧㄢㄈㄚ":"點發/墊發",
"ㄉㄧㄢㄈㄟ":"電費",
"ㄉㄧㄢㄈㄢ":"典範",
"ㄉㄧㄢㄈㄣ":"澱粉/典墳",
"ㄉㄧㄢㄈㄥ":"巔峰/顛風",
"ㄉㄧㄢㄈㄨ":"顛覆/電覆",
"ㄉㄧㄢㄉㄚ":"電大",
"ㄉㄧㄢㄉㄞ":"鈿帶",
"ㄉㄧㄢㄉㄠ":"顛倒/電導",
"ㄉㄧㄢㄉㄤ":"典當/墊檔",
"ㄉㄧㄢㄉㄥ":"電燈/點燈",
"ㄉㄧㄢㄉㄧ":"點滴/墊底",
"ㄉㄧㄢㄉㄨ":"奠都/電鍍",
"ㄉㄧㄢㄊㄞ":"點苔/電臺",
"ㄉㄧㄢㄊㄡ":"點頭/店頭",
"ㄉㄧㄢㄊㄢ":"電毯",
"ㄉㄧㄢㄊㄤ":"殿堂/點湯",
"ㄉㄧㄢㄊㄧ":"電梯/點題",
"ㄉㄧㄢㄋㄠ":"電腦",
"ㄉㄧㄢㄋㄥ":"電能",
"ㄉㄧㄢㄌㄟ":"奠酹",
"ㄉㄧㄢㄌㄢ":"電纜/靛藍",
"ㄉㄧㄢㄌㄧ":"典禮/電力",
"ㄉㄧㄢㄌㄨ":"電路/電爐",
"ㄉㄧㄢㄍㄜ":"點歌/殿閣",
"ㄉㄧㄢㄍㄠ":"電告",
"ㄉㄧㄢㄍㄢ":"電杆/電桿",
"ㄉㄧㄢㄍㄨ":"典故/墊股",
"ㄉㄧㄢㄎㄢ":"點勘",
"ㄉㄧㄢㄏㄜ":"電荷/電賀",
"ㄉㄧㄢㄏㄞ":"墊害",
"ㄉㄧㄢㄏㄠ":"點號",
"ㄉㄧㄢㄏㄡ":"殿後/甸後",
"ㄉㄧㄢㄏㄢ":"電銲",
"ㄉㄧㄢㄏㄨ":"電壺/佃戶",
"ㄉㄧㄢㄐㄧ":"典籍/惦記",
"ㄉㄧㄢㄐㄩ":"電鋸/典據",
"ㄉㄧㄢㄑㄧ":"電器/點漆",
"ㄉㄧㄢㄒㄧ":"點戲/墊戲",
"ㄉㄧㄢㄓㄚ":"點扎/點閘",
"ㄉㄧㄢㄓㄜ":"掂折/拈折",
"ㄉㄧㄢㄓㄠ":"點著/點照",
"ㄉㄧㄢㄓㄡ":"電帚",
"ㄉㄧㄢㄓㄣ":"電震/電針",
"ㄉㄧㄢㄓㄤ":"店長/典章",
"ㄉㄧㄢㄓㄥ":"鈿箏",
"ㄉㄧㄢㄓㄨ":"店主/點主",
"ㄉㄧㄢㄔㄚ":"點茶",
"ㄉㄧㄢㄔㄜ":"電車/電掣",
"ㄉㄧㄢㄔㄞ":"點差",
"ㄉㄧㄢㄔㄤ":"點唱/電場",
"ㄉㄧㄢㄔㄨ":"點出",
"ㄉㄧㄢㄕㄡ":"點收/點手",
"ㄉㄧㄢㄕㄢ":"電扇",
"ㄉㄧㄢㄕㄣ":"典身",
"ㄉㄧㄢㄕㄤ":"電傷",
"ㄉㄧㄢㄖㄜ":"電熱",
"ㄉㄧㄢㄖㄢ":"點燃/點染",
"ㄉㄧㄢㄖㄨ":"玷辱",
"ㄉㄧㄢㄗㄨ":"電阻/佃租",
"ㄉㄧㄢㄘㄜ":"典策",
"ㄉㄧㄢㄘㄞ":"點菜",
"ㄉㄧㄢㄘㄤ":"典藏",
"ㄉㄧㄢㄙㄨ":"點酥/鈿粟",
"ㄉㄧㄢㄧㄚ":"典雅/電壓",
"ㄉㄧㄢㄧㄝ":"電冶/典業",
"ㄉㄧㄢㄧㄞ":"巔崖",
"ㄉㄧㄢㄧㄠ":"典要/電窯",
"ㄉㄧㄢㄧㄡ":"靛油",
"ㄉㄧㄢㄧㄢ":"點眼/點驗",
"ㄉㄧㄢㄧㄣ":"顛飲",
"ㄉㄧㄢㄧㄥ":"電影",
"ㄉㄧㄢㄨㄟ":"阽危",
"ㄉㄧㄢㄨㄢ":"電玩/電碗",
"ㄉㄧㄢㄨㄣ":"電文",
"ㄉㄧㄢㄨㄤ":"電網",
"ㄉㄧㄢㄩㄝ":"點閱/顛越",
"ㄉㄧㄢㄩㄢ":"店員/電源",
"ㄉㄧㄢㄩㄣ":"顛隕",
"ㄉㄧㄢㄩㄥ":"墊用",
"ㄉㄧㄥㄅㄚ":"丁八/丁壩",
"ㄉㄧㄥㄅㄞ":"頂拜",
"ㄉㄧㄥㄅㄣ":"定本",
"ㄉㄧㄥㄆㄚ":"釘耙",
"ㄉㄧㄥㄆㄢ":"頂盤",
"ㄉㄧㄥㄆㄥ":"頂棚",
"ㄉㄧㄥㄇㄣ":"頂門",
"ㄉㄧㄥㄇㄥ":"定盟/訂盟",
"ㄉㄧㄥㄈㄟ":"訂費/鼎沸",
"ㄉㄧㄥㄈㄣ":"定分",
"ㄉㄧㄥㄈㄥ":"頂峰/頂風",
"ㄉㄧㄥㄈㄨ":"丁賦/鼎輔",
"ㄉㄧㄥㄉㄚ":"頂搭",
"ㄉㄧㄥㄉㄞ":"頂戴",
"ㄉㄧㄥㄉㄠ":"丁倒",
"ㄉㄧㄥㄉㄡ":"飣餖",
"ㄉㄧㄥㄉㄢ":"訂單/丁單",
"ㄉㄧㄥㄉㄤ":"叮噹/叮噹",
"ㄉㄧㄥㄉㄨ":"定都",
"ㄉㄧㄥㄊㄡ":"頂頭/釘頭",
"ㄉㄧㄥㄊㄧ":"頂替/定體",
"ㄉㄧㄥㄋㄞ":"鼎鼐",
"ㄉㄧㄥㄋㄢ":"丁男",
"ㄉㄧㄥㄋㄩ":"丁女",
"ㄉㄧㄥㄌㄠ":"頂老/丁老",
"ㄉㄧㄥㄌㄡ":"頂樓",
"ㄉㄧㄥㄌㄧ":"定理/定力",
"ㄉㄧㄥㄌㄩ":"定律/定率",
"ㄉㄧㄥㄍㄜ":"定格/頂格",
"ㄉㄧㄥㄍㄠ":"定稿",
"ㄉㄧㄥㄍㄡ":"訂購",
"ㄉㄧㄥㄍㄤ":"頂缸",
"ㄉㄧㄥㄍㄥ":"定更/定庚",
"ㄉㄧㄥㄎㄡ":"丁口",
"ㄉㄧㄥㄏㄞ":"定害",
"ㄉㄧㄥㄏㄠ":"頂好",
"ㄉㄧㄥㄏㄨ":"訂戶/鼎湖",
"ㄉㄧㄥㄐㄧ":"定計/頂技",
"ㄉㄧㄥㄐㄩ":"定居/定局",
"ㄉㄧㄥㄑㄧ":"定期/定器",
"ㄉㄧㄥㄒㄧ":"鼎席",
"ㄉㄧㄥㄓㄣ":"頂真/頂針",
"ㄉㄧㄥㄓㄤ":"定章",
"ㄉㄧㄥㄓㄥ":"訂正",
"ㄉㄧㄥㄓㄨ":"叮囑/頂珠",
"ㄉㄧㄥㄔㄞ":"丁差",
"ㄉㄧㄥㄔㄢ":"定產/丁產",
"ㄉㄧㄥㄔㄣ":"鼎臣",
"ㄉㄧㄥㄕㄠ":"盯梢",
"ㄉㄧㄥㄕㄡ":"頂首",
"ㄉㄧㄥㄕㄣ":"定神",
"ㄉㄧㄥㄕㄤ":"頂上",
"ㄉㄧㄥㄕㄥ":"鼎盛",
"ㄉㄧㄥㄕㄨ":"定數/頂數",
"ㄉㄧㄥㄖㄢ":"定然",
"ㄉㄧㄥㄖㄣ":"盯人",
"ㄉㄧㄥㄖㄤ":"頂讓",
"ㄉㄧㄥㄗㄔ":"丁字尺",
"ㄉㄧㄥㄗㄜ":"定則",
"ㄉㄧㄥㄗㄞ":"定在",
"ㄉㄧㄥㄗㄠ":"訂造",
"ㄉㄧㄥㄗㄨ":"鼎足/鼎族",
"ㄉㄧㄥㄘㄜ":"定策",
"ㄉㄧㄥㄙㄤ":"頂顙",
"ㄉㄧㄥㄧㄝ":"丁夜",
"ㄉㄧㄥㄧㄠ":"定窯",
"ㄉㄧㄥㄧㄡ":"丁憂",
"ㄉㄧㄥㄧㄢ":"定讞",
"ㄉㄧㄥㄧㄣ":"頂癮/定銀",
"ㄉㄧㄥㄧㄥ":"定應",
"ㄉㄧㄥㄨㄟ":"定位/訂位",
"ㄉㄧㄥㄨㄣ":"叮問/釘問",
"ㄉㄧㄥㄩㄝ":"訂閱/定約",
"ㄉㄧㄥㄩㄢ":"鼎元",
"ㄉㄧㄥㄩㄥ":"頂用",
"ㄉㄧㄦㄉㄞ":"第二代",
"ㄉㄧㄧㄅㄛ":"第一波",
"ㄉㄧㄧㄅㄢ":"第一版",
"ㄉㄧㄧㄅㄨ":"第一步",
"ㄉㄧㄧㄆㄠ":"第一炮",
"ㄉㄧㄧㄉㄥ":"第一等",
"ㄉㄧㄧㄍㄜ":"第一個",
"ㄉㄧㄧㄕㄡ":"第一手",
"ㄉㄧㄧㄖㄣ":"第一人",
"ㄉㄧㄧㄗㄠ":"第一遭",
"ㄉㄧㄧㄣㄗ":"地窨子",
"ㄉㄧㄧㄤㄦ":"底樣兒",
"ㄉㄧㄧㄧㄢ":"第一眼",
"ㄉㄨㄅㄚㄕ":"都把勢",
"ㄉㄨㄆㄧㄣ":"毒品/賭品",
"ㄉㄨㄇㄧㄠ":"讀秒",
"ㄉㄨㄇㄧㄥ":"度命",
"ㄉㄨㄉㄧㄝ":"度牒",
"ㄉㄨㄉㄧㄥ":"篤定",
"ㄉㄨㄉㄨㄟ":"賭對",
"ㄉㄨㄉㄨㄢ":"獨斷",
"ㄉㄨㄊㄧㄠ":"獨挑",
"ㄉㄨㄊㄨㄛ":"度脫",
"ㄉㄨㄊㄨㄣ":"獨吞",
"ㄉㄨㄋㄤㄦ":"肚囊兒",
"ㄉㄨㄋㄧㄢ":"篤念",
"ㄉㄨㄋㄨㄥ":"嘟噥",
"ㄉㄨㄌㄧㄝ":"毒烈",
"ㄉㄨㄌㄧㄡ":"毒瘤",
"ㄉㄨㄌㄧㄤ":"度量/肚量",
"ㄉㄨㄌㄧㄥ":"杜陵",
"ㄉㄨㄌㄧㄩ":"獨立語",
"ㄉㄨㄌㄨㄣ":"篤論/渡輪",
"ㄉㄨㄌㄨㄥ":"毒龍",
"ㄉㄨㄍㄨㄛ":"度過/渡過",
"ㄉㄨㄍㄨㄟ":"都鬼",
"ㄉㄨㄍㄨㄢ":"都管",
"ㄉㄨㄍㄨㄣ":"賭棍",
"ㄉㄨㄍㄨㄥ":"督工",
"ㄉㄨㄏㄨㄚ":"毒化/讀畫",
"ㄉㄨㄏㄨㄟ":"都會/讀會",
"ㄉㄨㄐㄧㄚ":"獨家/度假",
"ㄉㄨㄐㄧㄠ":"賭腳",
"ㄉㄨㄐㄧㄡ":"毒酒",
"ㄉㄨㄐㄧㄢ":"獨見/蠹簡",
"ㄉㄨㄐㄧㄣ":"鍍金",
"ㄉㄨㄐㄧㄤ":"都講/渡江",
"ㄉㄨㄐㄧㄥ":"讀經",
"ㄉㄨㄐㄩㄝ":"杜絕/獨覺",
"ㄉㄨㄐㄩㄢ":"杜鵑/獨捐",
"ㄉㄨㄐㄩㄣ":"督軍",
"ㄉㄨㄑㄧㄢ":"賭錢/渡錢",
"ㄉㄨㄑㄧㄤ":"堵牆",
"ㄉㄨㄒㄧㄚ":"都轄",
"ㄉㄨㄒㄧㄝ":"讀寫",
"ㄉㄨㄒㄧㄠ":"毒梟",
"ㄉㄨㄒㄧㄡ":"獨秀",
"ㄉㄨㄒㄧㄢ":"毒腺",
"ㄉㄨㄒㄧㄣ":"篤信/堵心",
"ㄉㄨㄒㄧㄤ":"獨享",
"ㄉㄨㄒㄧㄥ":"毒性/獨行",
"ㄉㄨㄒㄩㄝ":"篤學/督學",
"ㄉㄨㄓㄨㄛ":"獨酌",
"ㄉㄨㄓㄨㄢ":"杜撰",
"ㄉㄨㄓㄨㄥ":"都中/獨鍾",
"ㄉㄨㄔㄗㄜ":"獨吃自痾",
"ㄉㄨㄔㄧㄧ":"獨持異議",
"ㄉㄨㄔㄨㄢ":"渡船",
"ㄉㄨㄔㄨㄤ":"獨創",
"ㄉㄨㄔㄨㄥ":"毒蟲/蠹蟲",
"ㄉㄨㄕㄥㄗ":"獨生子",
"ㄉㄨㄕㄨㄞ":"督率",
"ㄉㄨㄕㄨㄟ":"渡水",
"ㄉㄨㄖㄊㄡ":"毒日頭",
"ㄉㄨㄖㄣㄕ":"都人士",
"ㄉㄨㄖㄨㄛ":"讀若",
"ㄉㄨㄗㄊㄥ":"肚子疼",
"ㄉㄨㄗㄍㄜ":"獨自個",
"ㄉㄨㄗㄨㄟ":"度晬/嘟嘴",
"ㄉㄨㄗㄨㄢ":"都纂",
"ㄉㄨㄘㄨㄟ":"督催",
"ㄉㄨㄙㄕㄨ":"讀死書",
"ㄉㄨㄙㄨㄟ":"度歲",
"ㄉㄨㄛㄅㄢ":"多半/多般",
"ㄉㄨㄛㄅㄧ":"躲避",
"ㄉㄨㄛㄅㄨ":"踱步",
"ㄉㄨㄛㄆㄛ":"奪魄",
"ㄉㄨㄛㄇㄚ":"墮馬",
"ㄉㄨㄛㄇㄜ":"多麼",
"ㄉㄨㄛㄇㄢ":"多慢/惰慢",
"ㄉㄨㄛㄇㄨ":"奪目",
"ㄉㄨㄛㄈㄣ":"多分",
"ㄉㄨㄛㄈㄤ":"多方",
"ㄉㄨㄛㄈㄨ":"墮負",
"ㄉㄨㄛㄉㄚ":"多大",
"ㄉㄨㄛㄉㄜ":"多得",
"ㄉㄨㄛㄉㄧ":"墮地/奪嫡",
"ㄉㄨㄛㄊㄞ":"墮胎",
"ㄉㄨㄛㄊㄡ":"多頭",
"ㄉㄨㄛㄊㄤ":"多糖",
"ㄉㄨㄛㄋㄢ":"多難",
"ㄉㄨㄛㄌㄚ":"踱拉",
"ㄉㄨㄛㄌㄟ":"多累/墮淚",
"ㄉㄨㄛㄌㄢ":"躲懶/多婪",
"ㄉㄨㄛㄌㄧ":"多禮",
"ㄉㄨㄛㄌㄨ":"多露/奪路",
"ㄉㄨㄛㄌㄩ":"多慮",
"ㄉㄨㄛㄍㄨ":"多故",
"ㄉㄨㄛㄎㄜ":"多可",
"ㄉㄨㄛㄎㄞ":"躲開",
"ㄉㄨㄛㄎㄡ":"多口",
"ㄉㄨㄛㄐㄧ":"多忌/垛積",
"ㄉㄨㄛㄐㄩ":"垛句",
"ㄉㄨㄛㄑㄧ":"奪氣",
"ㄉㄨㄛㄑㄩ":"奪取",
"ㄉㄨㄛㄓㄞ":"躲債",
"ㄉㄨㄛㄔㄜ":"墮車",
"ㄉㄨㄛㄔㄥ":"多承",
"ㄉㄨㄛㄕㄚ":"躲煞",
"ㄉㄨㄛㄕㄠ":"多少",
"ㄉㄨㄛㄕㄡ":"奪手/舵手",
"ㄉㄨㄛㄕㄢ":"躲閃",
"ㄉㄨㄛㄕㄥ":"多生",
"ㄉㄨㄛㄕㄨ":"多數",
"ㄉㄨㄛㄖㄣ":"奪人",
"ㄉㄨㄛㄗㄜ":"多則",
"ㄉㄨㄛㄗㄨ":"跺足",
"ㄉㄨㄛㄘㄞ":"多才/奪彩",
"ㄉㄨㄛㄘㄤ":"躲藏",
"ㄉㄨㄛㄧㄘ":"多義詞",
"ㄉㄨㄛㄧㄝ":"墮業",
"ㄉㄨㄛㄧㄞ":"墮崖",
"ㄉㄨㄛㄧㄢ":"多言",
"ㄉㄨㄛㄧㄣ":"多因",
"ㄉㄨㄛㄨㄟ":"多違",
"ㄉㄨㄛㄨㄣ":"多文/多聞",
"ㄉㄨㄛㄩㄣ":"朵雲",
"ㄉㄨㄟㄅㄞ":"對白",
"ㄉㄨㄟㄅㄠ":"對保",
"ㄉㄨㄟㄅㄢ":"對半",
"ㄉㄨㄟㄅㄣ":"對本",
"ㄉㄨㄟㄅㄧ":"對比",
"ㄉㄨㄟㄅㄨ":"對簿/隊部",
"ㄉㄨㄟㄆㄞ":"對牌",
"ㄉㄨㄟㄆㄢ":"敦槃",
"ㄉㄨㄟㄇㄢ":"堆滿",
"ㄉㄨㄟㄇㄣ":"對門",
"ㄉㄨㄟㄈㄟ":"堆肥",
"ㄉㄨㄟㄈㄣ":"對分",
"ㄉㄨㄟㄈㄤ":"對方/碓房",
"ㄉㄨㄟㄈㄨ":"對付/對副",
"ㄉㄨㄟㄉㄚ":"對打/對答",
"ㄉㄨㄟㄉㄞ":"對待",
"ㄉㄨㄟㄉㄤ":"對當",
"ㄉㄨㄟㄉㄥ":"對等",
"ㄉㄨㄟㄉㄧ":"對敵",
"ㄉㄨㄟㄉㄨ":"對堵",
"ㄉㄨㄟㄊㄡ":"對頭/對頭",
"ㄉㄨㄟㄊㄢ":"對談",
"ㄉㄨㄟㄊㄧ":"對題",
"ㄉㄨㄟㄌㄞ":"對賴",
"ㄉㄨㄟㄌㄟ":"對壘",
"ㄉㄨㄟㄌㄧ":"對立/對理",
"ㄉㄨㄟㄌㄨ":"對路",
"ㄉㄨㄟㄍㄜ":"對歌",
"ㄉㄨㄟㄎㄞ":"對開",
"ㄉㄨㄟㄎㄡ":"對口/對扣",
"ㄉㄨㄟㄎㄢ":"對看/對勘",
"ㄉㄨㄟㄎㄤ":"對抗",
"ㄉㄨㄟㄏㄜ":"對合",
"ㄉㄨㄟㄏㄠ":"對號",
"ㄉㄨㄟㄐㄧ":"堆積/堆集",
"ㄉㄨㄟㄐㄩ":"對句/對局",
"ㄉㄨㄟㄑㄧ":"堆砌/對棋",
"ㄉㄨㄟㄒㄧ":"對席/對膝",
"ㄉㄨㄟㄓㄜ":"對折",
"ㄉㄨㄟㄓㄠ":"對照",
"ㄉㄨㄟㄓㄢ":"堆棧",
"ㄉㄨㄟㄓㄣ":"對陣/對針",
"ㄉㄨㄟㄓㄤ":"隊長/對仗",
"ㄉㄨㄟㄓㄥ":"對證/對症",
"ㄉㄨㄟㄓㄨ":"隊主/堆貯",
"ㄉㄨㄟㄔㄠ":"對吵",
"ㄉㄨㄟㄔㄣ":"對襯",
"ㄉㄨㄟㄔㄤ":"對唱",
"ㄉㄨㄟㄕㄡ":"對手",
"ㄉㄨㄟㄕㄤ":"對上",
"ㄉㄨㄟㄕㄥ":"對生/碓聲",
"ㄉㄨㄟㄕㄨ":"對數",
"ㄉㄨㄟㄗㄠ":"對造",
"ㄉㄨㄟㄘㄜ":"對策",
"ㄉㄨㄟㄙㄨ":"對訴",
"ㄉㄨㄟㄧㄚ":"堆鴉",
"ㄉㄨㄟㄧㄡ":"隊友",
"ㄉㄨㄟㄧㄢ":"對眼",
"ㄉㄨㄟㄧㄣ":"對飲",
"ㄉㄨㄟㄧㄥ":"對應",
"ㄉㄨㄟㄨㄟ":"對位/對味",
"ㄉㄨㄟㄩㄢ":"隊員/對圓",
"ㄉㄨㄟㄩㄣ":"兌運",
"ㄉㄨㄟㄩㄥ":"兌用",
"ㄉㄨㄢㄅㄛ":"短波/短撥",
"ㄉㄨㄢㄅㄧ":"短筆",
"ㄉㄨㄢㄆㄟ":"斷配",
"ㄉㄨㄢㄆㄠ":"短跑",
"ㄉㄨㄢㄆㄢ":"短盤",
"ㄉㄨㄢㄇㄣ":"端門",
"ㄉㄨㄢㄇㄨ":"端木",
"ㄉㄨㄢㄈㄚ":"短髮",
"ㄉㄨㄢㄈㄟ":"斷非",
"ㄉㄨㄢㄈㄤ":"端方",
"ㄉㄨㄢㄉㄚ":"短打",
"ㄉㄨㄢㄉㄞ":"斷代/緞帶",
"ㄉㄨㄢㄉㄠ":"短刀",
"ㄉㄨㄢㄉㄤ":"斷檔",
"ㄉㄨㄢㄉㄧ":"端的/短遞",
"ㄉㄨㄢㄊㄡ":"斷頭/段頭",
"ㄉㄨㄢㄊㄨ":"短途/斷屠",
"ㄉㄨㄢㄋㄞ":"斷奶",
"ㄉㄨㄢㄋㄧ":"端倪",
"ㄉㄨㄢㄌㄞ":"端賴",
"ㄉㄨㄢㄌㄡ":"短漏",
"ㄉㄨㄢㄌㄧ":"短禮/端麗",
"ㄉㄨㄢㄌㄨ":"短路/斷路",
"ㄉㄨㄢㄍㄣ":"斷根",
"ㄉㄨㄢㄎㄠ":"段考",
"ㄉㄨㄢㄎㄡ":"斷口",
"ㄉㄨㄢㄎㄨ":"短褲",
"ㄉㄨㄢㄏㄜ":"斷喝/短褐",
"ㄉㄨㄢㄏㄟ":"斷黑",
"ㄉㄨㄢㄏㄡ":"斷後/短後",
"ㄉㄨㄢㄏㄨ":"斷乎",
"ㄉㄨㄢㄐㄧ":"短計/短戟",
"ㄉㄨㄢㄐㄩ":"斷句/短句",
"ㄉㄨㄢㄑㄧ":"短期/斷氣",
"ㄉㄨㄢㄒㄧ":"斷席/端溪",
"ㄉㄨㄢㄒㄩ":"斷續/短序",
"ㄉㄨㄢㄓㄜ":"短折",
"ㄉㄨㄢㄓㄠ":"端兆",
"ㄉㄨㄢㄓㄢ":"短暫",
"ㄉㄨㄢㄓㄣ":"短針",
"ㄉㄨㄢㄓㄤ":"斷掌",
"ㄉㄨㄢㄓㄥ":"端正/端整",
"ㄉㄨㄢㄔㄣ":"短趁",
"ㄉㄨㄢㄔㄤ":"短長/斷腸",
"ㄉㄨㄢㄔㄥ":"短程/短秤",
"ㄉㄨㄢㄔㄨ":"短處/斷除",
"ㄉㄨㄢㄕㄠ":"短少/鍛燒",
"ㄉㄨㄢㄕㄨ":"短書/段數",
"ㄉㄨㄢㄖㄢ":"斷然/端然",
"ㄉㄨㄢㄖㄣ":"端人",
"ㄉㄨㄢㄗㄠ":"鍛造",
"ㄉㄨㄢㄘㄥ":"斷層",
"ㄉㄨㄢㄘㄨ":"短促",
"ㄉㄨㄢㄙㄨ":"端肅",
"ㄉㄨㄢㄧㄚ":"端雅",
"ㄉㄨㄢㄧㄞ":"斷崖",
"ㄉㄨㄢㄧㄢ":"斷言/斷煙",
"ㄉㄨㄢㄧㄣ":"短音",
"ㄉㄨㄢㄧㄤ":"端陽",
"ㄉㄨㄢㄨㄣ":"短文",
"ㄉㄨㄣㄅㄟ":"遁北",
"ㄉㄨㄣㄅㄧ":"頓筆",
"ㄉㄨㄣㄅㄨ":"燉補/墩布",
"ㄉㄨㄣㄆㄞ":"盾牌",
"ㄉㄨㄣㄆㄟ":"頓轡",
"ㄉㄨㄣㄆㄧ":"躉批",
"ㄉㄨㄣㄆㄨ":"敦樸",
"ㄉㄨㄣㄇㄞ":"躉賣",
"ㄉㄨㄣㄇㄠ":"鈍眊",
"ㄉㄨㄣㄇㄡ":"頓牟",
"ㄉㄨㄣㄇㄨ":"敦睦",
"ㄉㄨㄣㄈㄤ":"頓放",
"ㄉㄨㄣㄈㄨ":"蹲俯",
"ㄉㄨㄣㄉㄚ":"惇大/敦打",
"ㄉㄨㄣㄉㄤ":"躉當",
"ㄉㄨㄣㄉㄧ":"頓地",
"ㄉㄨㄣㄊㄞ":"墩臺",
"ㄉㄨㄣㄊㄠ":"遁逃",
"ㄉㄨㄣㄊㄡ":"鈍頭",
"ㄉㄨㄣㄌㄨ":"頓碌",
"ㄉㄨㄣㄍㄣ":"鈍根",
"ㄉㄨㄣㄎㄞ":"頓開",
"ㄉㄨㄣㄏㄜ":"頓河",
"ㄉㄨㄣㄏㄠ":"頓號",
"ㄉㄨㄣㄏㄡ":"敦厚",
"ㄉㄨㄣㄏㄢ":"鈍漢",
"ㄉㄨㄣㄐㄧ":"遁跡",
"ㄉㄨㄣㄐㄩ":"蹲踞",
"ㄉㄨㄣㄑㄧ":"鈍器",
"ㄉㄨㄣㄒㄧ":"頓息",
"ㄉㄨㄣㄒㄩ":"敦序/惇敘",
"ㄉㄨㄣㄓㄨ":"燉煮/敦囑",
"ㄉㄨㄣㄕㄡ":"頓首",
"ㄉㄨㄣㄕㄣ":"遁身",
"ㄉㄨㄣㄖㄢ":"頓然",
"ㄉㄨㄣㄖㄣ":"敦仁",
"ㄉㄨㄣㄗㄡ":"遁走",
"ㄉㄨㄣㄗㄨ":"頓足/頓捽",
"ㄉㄨㄣㄘㄤ":"遁藏",
"ㄉㄨㄣㄘㄨ":"敦促",
"ㄉㄨㄣㄙㄤ":"頓顙",
"ㄉㄨㄣㄨㄟ":"噸位",
"ㄉㄨㄥㄅㄟ":"東北",
"ㄉㄨㄥㄅㄧ":"動筆/東壁",
"ㄉㄨㄥㄅㄨ":"東部",
"ㄉㄨㄥㄆㄛ":"動魄/東坡",
"ㄉㄨㄥㄇㄞ":"動脈/冬麥",
"ㄉㄨㄥㄇㄣ":"東門/洞門",
"ㄉㄨㄥㄇㄥ":"東蒙",
"ㄉㄨㄥㄇㄨ":"動目",
"ㄉㄨㄥㄈㄟ":"東非",
"ㄉㄨㄥㄈㄢ":"動煩",
"ㄉㄨㄥㄈㄣ":"冬粉",
"ㄉㄨㄥㄈㄤ":"東方/洞房",
"ㄉㄨㄥㄈㄥ":"東風/冬風",
"ㄉㄨㄥㄈㄨ":"洞府",
"ㄉㄨㄥㄉㄚ":"洞達",
"ㄉㄨㄥㄉㄜ":"懂得/東德",
"ㄉㄨㄥㄉㄠ":"東道/動刀",
"ㄉㄨㄥㄉㄢ":"動憚/動撣",
"ㄉㄨㄥㄉㄤ":"動蕩/動盪",
"ㄉㄨㄥㄉㄨ":"東都/東渡",
"ㄉㄨㄥㄊㄞ":"動態",
"ㄉㄨㄥㄊㄢ":"動彈",
"ㄉㄨㄥㄊㄧ":"胴體",
"ㄉㄨㄥㄊㄨ":"動土/東土",
"ㄉㄨㄥㄋㄟ":"凍餒",
"ㄉㄨㄥㄋㄠ":"動腦",
"ㄉㄨㄥㄋㄢ":"東南",
"ㄉㄨㄥㄋㄥ":"動能",
"ㄉㄨㄥㄋㄨ":"動怒",
"ㄉㄨㄥㄌㄠ":"動勞",
"ㄉㄨㄥㄌㄧ":"動力/東籬",
"ㄉㄨㄥㄌㄨ":"東路",
"ㄉㄨㄥㄍㄜ":"東閣",
"ㄉㄨㄥㄍㄠ":"東皋",
"ㄉㄨㄥㄍㄢ":"動感/東干",
"ㄉㄨㄥㄍㄨ":"冬菇/東顧",
"ㄉㄨㄥㄎㄞ":"洞開",
"ㄉㄨㄥㄎㄡ":"動口",
"ㄉㄨㄥㄎㄨ":"洞窟",
"ㄉㄨㄥㄏㄜ":"恫嚇/恫喝",
"ㄉㄨㄥㄏㄞ":"東海/凍害",
"ㄉㄨㄥㄏㄢ":"東漢/凍寒",
"ㄉㄨㄥㄏㄤ":"懂行",
"ㄉㄨㄥㄏㄨ":"東胡/洞戶",
"ㄉㄨㄥㄐㄧ":"動機/冬季",
"ㄉㄨㄥㄐㄩ":"侗劇/洞居",
"ㄉㄨㄥㄑㄧ":"動氣/氡氣",
"ㄉㄨㄥㄑㄩ":"東區",
"ㄉㄨㄥㄒㄧ":"東西/東西",
"ㄉㄨㄥㄓㄜ":"動輒",
"ㄉㄨㄥㄓㄡ":"東周",
"ㄉㄨㄥㄓㄣ":"冬賑",
"ㄉㄨㄥㄓㄥ":"董正/東征",
"ㄉㄨㄥㄓㄨ":"動植物",
"ㄉㄨㄥㄔㄚ":"洞察",
"ㄉㄨㄥㄔㄜ":"洞徹",
"ㄉㄨㄥㄔㄠ":"東朝",
"ㄉㄨㄥㄔㄢ":"動產",
"ㄉㄨㄥㄔㄤ":"東廠",
"ㄉㄨㄥㄔㄨ":"冬初/冬儲",
"ㄉㄨㄥㄕㄜ":"動舌",
"ㄉㄨㄥㄕㄡ":"動手/東首",
"ㄉㄨㄥㄕㄢ":"東山",
"ㄉㄨㄥㄕㄣ":"動身",
"ㄉㄨㄥㄕㄤ":"凍傷",
"ㄉㄨㄥㄕㄥ":"東昇/凍省",
"ㄉㄨㄥㄖㄜ":"動熱",
"ㄉㄨㄥㄖㄢ":"洞然",
"ㄉㄨㄥㄖㄣ":"動人/東人",
"ㄉㄨㄥㄗㄨ":"侗族",
"ㄉㄨㄥㄘㄜ":"東廁/東側",
"ㄉㄨㄥㄘㄤ":"冬藏",
"ㄉㄨㄥㄘㄨ":"動粗",
"ㄉㄨㄥㄧㄚ":"東亞",
"ㄉㄨㄥㄧㄠ":"動搖",
"ㄉㄨㄥㄧㄢ":"懂眼",
"ㄉㄨㄥㄧㄣ":"動因",
"ㄉㄨㄥㄧㄤ":"東洋",
"ㄉㄨㄥㄧㄥ":"東瀛",
"ㄉㄨㄥㄨㄟ":"東魏",
"ㄉㄨㄥㄨㄣ":"動問",
"ㄉㄨㄥㄨㄤ":"東望",
"ㄉㄨㄥㄨㄥ":"東翁",
"ㄉㄨㄥㄩㄝ":"東越/冬月",
"ㄉㄨㄥㄩㄢ":"動員/凍原",
"ㄉㄨㄥㄩㄥ":"動用/董永",
"ㄉㄨㄧㄨㄦ":"獨一無二",
"ㄉㄨㄨㄚㄙ":"毒瓦斯",
"ㄊㄚㄅㄧㄗ":"塌鼻子",
"ㄊㄚㄆㄧㄢ":"拓片",
"ㄊㄚㄆㄧㄥ":"踏平",
"ㄊㄚㄇㄥㄗ":"踏猛子",
"ㄊㄚㄉㄧㄠ":"塔吊",
"ㄊㄚㄉㄨㄟ":"踏碓",
"ㄊㄚㄉㄨㄢ":"他端",
"ㄊㄚㄉㄨㄣ":"塔墩",
"ㄊㄚㄉㄨㄥ":"他動",
"ㄊㄚㄊㄨㄛ":"沓拖/涾沱",
"ㄊㄚㄋㄧㄢ":"他年",
"ㄊㄚㄋㄨㄛ":"闒懦",
"ㄊㄚㄌㄚㄦ":"趿拉兒",
"ㄊㄚㄌㄤㄦ":"踏浪兒",
"ㄊㄚㄌㄧㄤ":"他倆",
"ㄊㄚㄍㄨㄛ":"他國",
"ㄊㄚㄏㄨㄟ":"塔灰",
"ㄊㄚㄐㄧㄚ":"他家/塌架",
"ㄊㄚㄐㄧㄠ":"踏腳/蹋腳",
"ㄊㄚㄐㄧㄢ":"踏肩/踏踐",
"ㄊㄚㄐㄧㄩ":"獺祭魚",
"ㄊㄚㄑㄧㄠ":"踏蹺",
"ㄊㄚㄑㄧㄢ":"他遷",
"ㄊㄚㄑㄧㄥ":"踏青",
"ㄊㄚㄒㄧㄢ":"塌陷",
"ㄊㄚㄒㄧㄣ":"他心/塌心",
"ㄊㄚㄒㄧㄤ":"他鄉",
"ㄊㄚㄒㄧㄥ":"他姓",
"ㄊㄚㄒㄩㄕ":"他敘式",
"ㄊㄚㄓㄨㄥ":"塌中",
"ㄊㄚㄔㄨㄤ":"踏床/榻床",
"ㄊㄚㄕㄨㄛ":"他說",
"ㄊㄚㄕㄨㄟ":"漯水",
"ㄊㄚㄖㄨㄥ":"闒茸",
"ㄊㄚㄗㄨㄟ":"塌嘴",
"ㄊㄚㄘㄨㄢ":"踏爨",
"ㄊㄚㄙㄕㄜ":"塔斯社",
"ㄊㄚㄧㄤㄦ":"塌秧兒",
"ㄊㄜㄅㄧㄝ":"特別",
"ㄊㄜㄇㄟㄧ":"忒沒意",
"ㄊㄜㄇㄧㄥ":"特命",
"ㄊㄜㄉㄧㄢ":"特點",
"ㄊㄜㄉㄧㄥ":"特定",
"ㄊㄜㄌㄧㄥ":"特令",
"ㄊㄜㄍㄨㄛ":"忒過",
"ㄊㄜㄍㄨㄥ":"特工",
"ㄊㄜㄏㄨㄟ":"特惠",
"ㄊㄜㄐㄧㄚ":"特價",
"ㄊㄜㄐㄧㄠ":"特教",
"ㄊㄜㄐㄧㄤ":"特獎",
"ㄊㄜㄐㄧㄥ":"特警",
"ㄊㄜㄑㄧㄢ":"特遣",
"ㄊㄜㄑㄧㄣ":"特勤",
"ㄊㄜㄑㄧㄤ":"特強",
"ㄊㄜㄑㄩㄢ":"特權",
"ㄊㄜㄒㄧㄝ":"特寫",
"ㄊㄜㄒㄧㄠ":"特效",
"ㄊㄜㄒㄧㄥ":"特性",
"ㄊㄜㄒㄩㄢ":"特選",
"ㄊㄜㄓㄨㄣ":"特准",
"ㄊㄜㄓㄨㄥ":"特種",
"ㄊㄜㄦㄌㄡ":"忒兒嘍",
"ㄊㄞㄅㄟㄕ":"臺北市",
"ㄊㄞㄅㄧㄢ":"胎便",
"ㄊㄞㄆㄧㄥ":"太平/泰平",
"ㄊㄞㄇㄧㄠ":"大廟/太廟",
"ㄊㄞㄇㄧㄢ":"檯面",
"ㄊㄞㄇㄧㄥ":"臺命",
"ㄊㄞㄈㄨㄗ":"太夫子",
"ㄊㄞㄉㄧㄝ":"抬迭",
"ㄊㄞㄉㄧㄢ":"臺電",
"ㄊㄞㄉㄧㄥ":"泰定",
"ㄊㄞㄉㄨㄢ":"臺端",
"ㄊㄞㄉㄨㄣ":"臺墩",
"ㄊㄞㄉㄨㄥ":"胎動",
"ㄊㄞㄊㄧㄝ":"抬貼/抬帖",
"ㄊㄞㄋㄢㄕ":"臺南市",
"ㄊㄞㄋㄧㄥ":"太寧",
"ㄊㄞㄌㄠㄕ":"太老師",
"ㄊㄞㄌㄧㄢ":"臺簾",
"ㄊㄞㄌㄧㄤ":"臺兩",
"ㄊㄞㄍㄨㄛ":"太過/泰國",
"ㄊㄞㄍㄨㄤ":"臺光",
"ㄊㄞㄍㄨㄥ":"太公",
"ㄊㄞㄎㄨㄥ":"太空",
"ㄊㄞㄏㄤㄕ":"抬行市",
"ㄊㄞㄏㄨㄕ":"太湖石",
"ㄊㄞㄏㄨㄞ":"臺槐",
"ㄊㄞㄏㄨㄟ":"胎諱/臺諱",
"ㄊㄞㄏㄨㄢ":"汰換",
"ㄊㄞㄏㄨㄤ":"泰皇",
"ㄊㄞㄐㄧㄚ":"太甲/抬價",
"ㄊㄞㄐㄧㄝ":"太階/泰階",
"ㄊㄞㄐㄧㄠ":"抬轎/胎教",
"ㄊㄞㄐㄧㄢ":"太監/太建",
"ㄊㄞㄐㄧㄣ":"臺斤",
"ㄊㄞㄐㄩㄣ":"太君",
"ㄊㄞㄑㄧㄙ":"泰綺絲",
"ㄊㄞㄑㄧㄡ":"檯球",
"ㄊㄞㄑㄧㄢ":"太謙/臺錢",
"ㄊㄞㄑㄧㄥ":"太清/泰清",
"ㄊㄞㄒㄧㄝ":"臺榭",
"ㄊㄞㄒㄧㄢ":"臺銜/苔蘚",
"ㄊㄞㄒㄧㄤ":"胎象",
"ㄊㄞㄒㄧㄥ":"太興/臺型",
"ㄊㄞㄒㄩㄝ":"大學/太學",
"ㄊㄞㄒㄩㄢ":"太玄",
"ㄊㄞㄓㄨㄥ":"臺中/太重",
"ㄊㄞㄔㄤㄙ":"太常寺",
"ㄊㄞㄔㄨㄥ":"太沖",
"ㄊㄞㄕㄇㄨ":"太師母",
"ㄊㄞㄕㄨㄟ":"泰水",
"ㄊㄞㄖㄨㄥ":"太容",
"ㄊㄞㄗㄉㄤ":"太子黨",
"ㄊㄞㄗㄨㄛ":"太座/臺座",
"ㄊㄞㄗㄨㄣ":"太尊",
"ㄊㄞㄗㄨㄥ":"太宗",
"ㄊㄞㄙㄨㄟ":"太歲",
"ㄊㄞㄙㄨㄣ":"太孫",
"ㄊㄞㄧㄋㄨ":"臺意怒",
"ㄊㄞㄧㄕㄨ":"太乙數",
"ㄊㄞㄧㄝㄔ":"太液池",
"ㄊㄞㄧㄤㄕ":"太陽時",
"ㄊㄞㄧㄤㄖ":"太陽日",
"ㄊㄞㄧㄩㄢ":"太醫院",
"ㄊㄞㄨㄕㄢ":"太武山",
"ㄊㄞㄩㄢㄕ":"太原市",
"ㄊㄠㄅㄧㄥ":"逃兵",
"ㄊㄠㄆㄧㄠ":"套票",
"ㄊㄠㄆㄧㄥ":"討平",
"ㄊㄠㄇㄧㄥ":"逃命/逃名",
"ㄊㄠㄉㄧㄠ":"套調",
"ㄊㄠㄉㄧㄢ":"討店",
"ㄊㄠㄉㄨㄛ":"逃躲",
"ㄊㄠㄉㄨㄣ":"逃遁",
"ㄊㄠㄊㄤㄕ":"陶唐氏",
"ㄊㄠㄊㄧㄝ":"叨貼/饕餮",
"ㄊㄠㄊㄧㄠ":"討笤",
"ㄊㄠㄊㄧㄢ":"滔天/叨餂",
"ㄊㄠㄊㄨㄛ":"逃脫",
"ㄊㄠㄋㄩㄝ":"逃瘧",
"ㄊㄠㄌㄧㄥ":"討令",
"ㄊㄠㄌㄨㄛ":"淘籮",
"ㄊㄠㄌㄨㄣ":"討論",
"ㄊㄠㄌㄩㄝ":"韜略",
"ㄊㄠㄍㄨㄞ":"討乖",
"ㄊㄠㄍㄨㄤ":"叨光/韜光",
"ㄊㄠㄍㄨㄥ":"韜弓",
"ㄊㄠㄎㄤㄗ":"套炕子",
"ㄊㄠㄎㄨㄟ":"討愧",
"ㄊㄠㄎㄨㄥ":"淘空/掏空",
"ㄊㄠㄏㄨㄚ":"桃花/套話",
"ㄊㄠㄏㄨㄟ":"逃回/套匯",
"ㄊㄠㄏㄨㄢ":"淘換",
"ㄊㄠㄏㄨㄣ":"逃婚",
"ㄊㄠㄏㄨㄤ":"逃荒",
"ㄊㄠㄏㄨㄥ":"桃紅/陶泓",
"ㄊㄠㄐㄧㄚ":"逃家/饕家",
"ㄊㄠㄐㄧㄠ":"討教/叨教",
"ㄊㄠㄐㄧㄡ":"討究/討救",
"ㄊㄠㄐㄧㄢ":"套間/討賤",
"ㄊㄠㄐㄧㄣ":"淘金",
"ㄊㄠㄐㄧㄥ":"淘井",
"ㄊㄠㄐㄩㄣ":"逃軍/陶鈞",
"ㄊㄠㄑㄧㄝ":"叨竊",
"ㄊㄠㄑㄧㄠ":"討巧/討俏",
"ㄊㄠㄑㄧㄢ":"掏錢/陶潛",
"ㄊㄠㄑㄧㄣ":"討親",
"ㄊㄠㄑㄧㄥ":"討情",
"ㄊㄠㄒㄧㄝ":"陶寫/淘寫",
"ㄊㄠㄒㄧㄡ":"套袖/討休",
"ㄊㄠㄒㄧㄢ":"討嫌",
"ㄊㄠㄒㄧㄣ":"掏心/討信",
"ㄊㄠㄒㄧㄤ":"套項/饕饗",
"ㄊㄠㄒㄧㄥ":"逃刑",
"ㄊㄠㄒㄩㄝ":"逃學",
"ㄊㄠㄒㄩㄣ":"桃汛",
"ㄊㄠㄓㄨㄤ":"套裝",
"ㄊㄠㄓㄨㄥ":"套種",
"ㄊㄠㄔㄨㄥ":"絛蟲",
"ㄊㄠㄕㄥㄗ":"逃生子",
"ㄊㄠㄕㄨㄟ":"逃稅",
"ㄊㄠㄖㄨㄥ":"陶鎔",
"ㄊㄠㄗㄨㄟ":"陶醉/討嘴",
"ㄊㄠㄘㄑㄧ":"陶瓷器",
"ㄊㄠㄘㄨㄢ":"逃竄",
"ㄊㄠㄙㄨㄛ":"套索",
"ㄊㄠㄩㄢㄕ":"桃園市",
"ㄊㄡㄅㄚㄦ":"頭把兒",
"ㄊㄡㄅㄧㄠ":"投標",
"ㄊㄡㄅㄧㄢ":"投鞭",
"ㄊㄡㄆㄧㄠ":"投票",
"ㄊㄡㄇㄧㄢ":"頭面",
"ㄊㄡㄇㄧㄥ":"透明/頭明",
"ㄊㄡㄉㄧㄥ":"頭頂/透頂",
"ㄊㄡㄉㄨㄛ":"偷墮/媮惰",
"ㄊㄡㄉㄨㄟ":"頭對",
"ㄊㄡㄊㄧㄠ":"頭條/頭挑",
"ㄊㄡㄊㄧㄢ":"頭天",
"ㄊㄡㄊㄧㄥ":"頭廳",
"ㄊㄡㄊㄨㄛ":"頭陀/投托",
"ㄊㄡㄊㄨㄥ":"頭痛",
"ㄊㄡㄋㄧㄢ":"頭年",
"ㄊㄡㄌㄧㄢ":"頭臉",
"ㄊㄡㄌㄧㄣ":"投賃",
"ㄊㄡㄌㄧㄤ":"透亮",
"ㄊㄡㄌㄧㄥ":"頭領",
"ㄊㄡㄍㄨㄚ":"投瓜",
"ㄊㄡㄍㄨㄛ":"透過",
"ㄊㄡㄍㄨㄟ":"頭櫃",
"ㄊㄡㄍㄨㄢ":"頭管",
"ㄊㄡㄍㄨㄤ":"透光",
"ㄊㄡㄍㄨㄥ":"頭功/投供",
"ㄊㄡㄍㄨㄦ":"頭箍兒",
"ㄊㄡㄎㄨㄟ":"頭盔/偷窺",
"ㄊㄡㄎㄨㄤ":"黈纊",
"ㄊㄡㄎㄨㄥ":"偷空",
"ㄊㄡㄏㄢㄗ":"偷漢子",
"ㄊㄡㄏㄨㄛ":"偷活",
"ㄊㄡㄏㄨㄟ":"頭回",
"ㄊㄡㄏㄨㄢ":"偷換/投繯",
"ㄊㄡㄏㄨㄣ":"頭昏",
"ㄊㄡㄏㄨㄤ":"投荒",
"ㄊㄡㄐㄧㄚ":"頭家",
"ㄊㄡㄐㄧㄝ":"投解",
"ㄊㄡㄐㄧㄡ":"投酒",
"ㄊㄡㄐㄧㄢ":"投監",
"ㄊㄡㄐㄧㄣ":"頭巾",
"ㄊㄡㄐㄧㄤ":"頭獎/投江",
"ㄊㄡㄐㄧㄥ":"頭頸/透鏡",
"ㄊㄡㄐㄩㄣ":"投軍",
"ㄊㄡㄑㄧㄝ":"偷竊",
"ㄊㄡㄑㄧㄠ":"偷巧",
"ㄊㄡㄑㄧㄢ":"頭前/頭錢",
"ㄊㄡㄑㄧㄣ":"投親",
"ㄊㄡㄑㄧㄤ":"投槍",
"ㄊㄡㄑㄧㄥ":"偷情",
"ㄊㄡㄑㄩㄥ":"投瓊",
"ㄊㄡㄒㄧㄚ":"投轄/投洽",
"ㄊㄡㄒㄧㄠ":"偷笑/投效",
"ㄊㄡㄒㄧㄢ":"頭先/頭銜",
"ㄊㄡㄒㄧㄣ":"投心/頭信",
"ㄊㄡㄒㄧㄤ":"投降/頭香",
"ㄊㄡㄒㄧㄥ":"頭行/偷腥",
"ㄊㄡㄒㄩㄢ":"頭眩",
"ㄊㄡㄓㄉㄜ":"投至得",
"ㄊㄡㄓㄕㄤ":"頭直上",
"ㄊㄡㄓㄡㄗ":"頭肘子",
"ㄊㄡㄔㄨㄣ":"偷春",
"ㄊㄡㄕㄊㄨ":"透視圖",
"ㄊㄡㄕㄣㄓ":"投身紙",
"ㄊㄡㄕㄥㄧ":"頭繩衣",
"ㄊㄡㄕㄨㄟ":"投水/偷稅",
"ㄊㄡㄕㄨㄣ":"投順",
"ㄊㄡㄖㄨㄥ":"偷榮",
"ㄊㄡㄗㄖㄣ":"投資人",
"ㄊㄡㄗㄨㄟ":"偷嘴",
"ㄊㄡㄘㄨㄣ":"頭寸",
"ㄊㄡㄙㄨㄛ":"投梭",
"ㄊㄡㄧㄊㄡ":"酘一酘",
"ㄊㄢㄅㄧㄥ":"探病/痰病",
"ㄊㄢㄅㄨㄦ":"彈布爾",
"ㄊㄢㄆㄧㄥ":"坦平/攤平",
"ㄊㄢㄉㄧㄢ":"壇坫",
"ㄊㄢㄊㄧㄠ":"彈跳",
"ㄊㄢㄊㄧㄢ":"談天",
"ㄊㄢㄊㄧㄥ":"探聽",
"ㄊㄢㄊㄨㄛ":"談妥/潭沱",
"ㄊㄢㄊㄨㄟ":"彈腿/潭腿",
"ㄊㄢㄊㄨㄥ":"痰桶",
"ㄊㄢㄊㄨㄩ":"彈塗魚",
"ㄊㄢㄋㄧㄢ":"貪念",
"ㄊㄢㄌㄜㄕ":"攤了事",
"ㄊㄢㄌㄡㄗ":"炭簍子",
"ㄊㄢㄌㄧㄢ":"貪戀",
"ㄊㄢㄌㄧㄣ":"檀林",
"ㄊㄢㄌㄨㄛ":"坍落",
"ㄊㄢㄌㄨㄢ":"曇鸞/檀欒",
"ㄊㄢㄌㄨㄣ":"談論",
"ㄊㄢㄌㄨㄥ":"彈弄/談攏",
"ㄊㄢㄍㄨㄢ":"貪官/彈冠",
"ㄊㄢㄍㄨㄥ":"檀弓",
"ㄊㄢㄎㄨㄟ":"歎喟",
"ㄊㄢㄎㄨㄤ":"探礦",
"ㄊㄢㄏㄨㄚ":"談話/炭化",
"ㄊㄢㄏㄨㄛ":"炭火/痰火",
"ㄊㄢㄏㄨㄞ":"坦懷/坍壞",
"ㄊㄢㄏㄨㄟ":"貪賄/坍毀",
"ㄊㄢㄏㄨㄢ":"癱瘓/攤還",
"ㄊㄢㄏㄨㄤ":"彈簧/灘黃",
"ㄊㄢㄐㄧㄚ":"彈鋏",
"ㄊㄢㄐㄧㄝ":"歎嗟",
"ㄊㄢㄐㄧㄡ":"探究/貪酒",
"ㄊㄢㄐㄧㄢ":"探監/探檢",
"ㄊㄢㄐㄧㄥ":"炭精/炭敬",
"ㄊㄢㄐㄩㄝ":"痰厥",
"ㄊㄢㄐㄩㄣ":"探軍",
"ㄊㄢㄑㄧㄡ":"探求/貪求",
"ㄊㄢㄑㄧㄢ":"攤錢",
"ㄊㄢㄑㄧㄣ":"探親/彈琴",
"ㄊㄢㄑㄧㄥ":"貪情/貪青",
"ㄊㄢㄒㄧㄝ":"談屑",
"ㄊㄢㄒㄧㄠ":"談笑",
"ㄊㄢㄒㄧㄢ":"探險/探先",
"ㄊㄢㄒㄧㄣ":"貪心/談心",
"ㄊㄢㄒㄧㄤ":"檀香",
"ㄊㄢㄒㄧㄥ":"彈性/談興",
"ㄊㄢㄒㄩㄢ":"談玄",
"ㄊㄢㄒㄩㄣ":"探尋/探詢",
"ㄊㄢㄒㄩㄥ":"袒胸",
"ㄊㄢㄔㄨㄢ":"灘船/痰喘",
"ㄊㄢㄔㄨㄣ":"探春",
"ㄊㄢㄕㄖㄣ":"探事人",
"ㄊㄢㄕㄣㄗ":"探身子",
"ㄊㄢㄕㄥㄧ":"談生意",
"ㄊㄢㄕㄨㄞ":"坦率",
"ㄊㄢㄕㄨㄟ":"貪睡",
"ㄊㄢㄖㄨㄢ":"癱軟",
"ㄊㄢㄗㄨㄟ":"貪嘴",
"ㄊㄢㄗㄨㄥ":"談宗",
"ㄊㄢㄘㄨㄥ":"談叢",
"ㄊㄢㄙㄨㄛ":"探索",
"ㄊㄢㄙㄨㄢ":"碳酸",
"ㄊㄢㄩㄨㄧ":"貪慾無藝",
"ㄊㄤㄅㄧㄢ":"溏便",
"ㄊㄤㄅㄧㄥ":"湯餅",
"ㄊㄤㄆㄛㄗ":"湯婆子",
"ㄊㄤㄆㄧㄥ":"躺平/湯瓶",
"ㄊㄤㄇㄧㄢ":"湯麵/燙麵",
"ㄊㄤㄇㄧㄥ":"堂名",
"ㄊㄤㄇㄨㄧ":"湯沐邑",
"ㄊㄤㄉㄧㄥ":"湯鼎",
"ㄊㄤㄊㄧㄝ":"堂帖",
"ㄊㄤㄊㄧㄠ":"螗蜩",
"ㄊㄤㄊㄨㄢ":"湯糰",
"ㄊㄤㄌㄨㄛ":"鐋鑼",
"ㄊㄤㄍㄨㄚ":"糖瓜",
"ㄊㄤㄍㄨㄛ":"糖果/湯鍋",
"ㄊㄤㄍㄨㄢ":"堂官/堂倌",
"ㄊㄤㄍㄨㄣ":"躺棍",
"ㄊㄤㄎㄨㄞ":"帑廥",
"ㄊㄤㄎㄨㄥ":"鏜孔",
"ㄊㄤㄏㄨㄚ":"唐話/糖化",
"ㄊㄤㄏㄨㄛ":"倘或/湯火",
"ㄊㄤㄏㄨㄟ":"堂會",
"ㄊㄤㄏㄨㄣ":"糖婚",
"ㄊㄤㄏㄨㄤ":"堂皇/戃慌",
"ㄊㄤㄐㄧㄠ":"湯腳",
"ㄊㄤㄐㄧㄡ":"燙酒",
"ㄊㄤㄐㄧㄣ":"燙金/唐巾",
"ㄊㄤㄐㄧㄤ":"糖漿",
"ㄊㄤㄐㄧㄥ":"糖精",
"ㄊㄤㄑㄧㄢ":"堂前",
"ㄊㄤㄑㄩㄢ":"湯泉",
"ㄊㄤㄒㄧㄚ":"躺下/淌下",
"ㄊㄤㄒㄧㄢ":"膛線",
"ㄊㄤㄒㄧㄣ":"溏心",
"ㄊㄤㄒㄧㄤ":"帑項",
"ㄊㄤㄒㄩㄢ":"堂萱",
"ㄊㄤㄒㄩㄥ":"堂兄",
"ㄊㄤㄓㄨㄢ":"堂饌",
"ㄊㄤㄓㄨㄤ":"唐裝",
"ㄊㄤㄔㄞㄕ":"搪差使",
"ㄊㄤㄔㄨㄢ":"躺船",
"ㄊㄤㄔㄨㄤ":"鏜床",
"ㄊㄤㄕㄓㄥ":"唐氏症",
"ㄊㄤㄕㄢㄕ":"唐山市",
"ㄊㄤㄕㄨㄟ":"糖水/湯水",
"ㄊㄤㄕㄨㄤ":"糖霜",
"ㄊㄤㄖㄨㄛ":"倘若/儻若",
"ㄊㄤㄗㄨㄣ":"堂尊",
"ㄊㄥㄊㄧㄠ":"藤條",
"ㄊㄥㄊㄨㄥ":"疼痛",
"ㄊㄥㄋㄨㄛ":"騰那/騰挪",
"ㄊㄥㄌㄧㄡ":"滕六",
"ㄊㄥㄌㄨㄛ":"藤蘿",
"ㄊㄥㄌㄨㄣ":"藤輪",
"ㄊㄥㄍㄨㄟ":"騰貴",
"ㄊㄥㄎㄨㄥ":"騰空",
"ㄊㄥㄏㄨㄢ":"騰歡",
"ㄊㄥㄏㄨㄤ":"騰黃/藤黃",
"ㄊㄥㄐㄧㄚ":"藤架",
"ㄊㄥㄐㄧㄝ":"騰捷",
"ㄊㄥㄑㄧㄡ":"藤球",
"ㄊㄥㄑㄧㄥ":"謄清",
"ㄊㄥㄒㄧㄝ":"謄寫",
"ㄊㄥㄒㄧㄠ":"騰笑",
"ㄊㄥㄒㄧㄢ":"騰掀",
"ㄊㄥㄒㄧㄤ":"騰驤",
"ㄊㄥㄓㄨㄛ":"騰踔",
"ㄊㄥㄓㄨㄤ":"騰裝",
"ㄊㄧㄅㄧㄥ":"提兵",
"ㄊㄧㄆㄧㄠ":"提票",
"ㄊㄧㄆㄧㄣ":"題品/替聘",
"ㄊㄧㄇㄧㄢ":"體面",
"ㄊㄧㄇㄧㄥ":"提名/題名",
"ㄊㄧㄉㄧㄠ":"體調/提調",
"ㄊㄧㄉㄨㄛ":"提掇",
"ㄊㄧㄉㄨㄟ":"梯隊",
"ㄊㄧㄉㄨㄢ":"體段",
"ㄊㄧㄊㄚㄨ":"踢踏舞",
"ㄊㄧㄊㄡㄦ":"替頭兒",
"ㄊㄧㄊㄧㄝ":"體貼",
"ㄊㄧㄊㄧㄢ":"梯田",
"ㄊㄧㄊㄧㄥ":"逖聽",
"ㄊㄧㄊㄨㄛ":"踢脫",
"ㄊㄧㄊㄨㄟ":"踢腿",
"ㄊㄧㄊㄨㄥ":"體統/提筒",
"ㄊㄧㄋㄧㄢ":"題念/體念",
"ㄊㄧㄋㄨㄥ":"踢弄",
"ㄊㄧㄌㄧㄢ":"提煉",
"ㄊㄧㄌㄧㄤ":"體諒/提梁",
"ㄊㄧㄌㄧㄥ":"替另/涕零",
"ㄊㄧㄍㄨㄛ":"提過",
"ㄊㄧㄍㄨㄢ":"提罐/提灌",
"ㄊㄧㄍㄨㄤ":"梯桄",
"ㄊㄧㄍㄨㄥ":"提供/替工",
"ㄊㄧㄎㄨㄢ":"提款/題款",
"ㄊㄧㄎㄨㄥ":"提控",
"ㄊㄧㄏㄨㄚ":"提花/蹄花",
"ㄊㄧㄏㄨㄟ":"體會",
"ㄊㄧㄏㄨㄢ":"替換",
"ㄊㄧㄏㄨㄥ":"剔紅",
"ㄊㄧㄐㄧㄚ":"提價",
"ㄊㄧㄐㄧㄝ":"題解/體解",
"ㄊㄧㄐㄧㄠ":"提交/啼叫",
"ㄊㄧㄐㄧㄡ":"體究/殢酒",
"ㄊㄧㄐㄧㄢ":"體檢",
"ㄊㄧㄐㄧㄣ":"蹄筋",
"ㄊㄧㄐㄩㄝ":"剔抉/鵜鴃",
"ㄊㄧㄐㄩㄢ":"啼鵑",
"ㄊㄧㄑㄧㄝ":"提挈",
"ㄊㄧㄑㄧㄠ":"梯橋",
"ㄊㄧㄑㄧㄡ":"踢球/踢毬",
"ㄊㄧㄑㄧㄢ":"提前/題簽",
"ㄊㄧㄑㄧㄣ":"提琴/題親",
"ㄊㄧㄑㄧㄤ":"體腔",
"ㄊㄧㄑㄧㄥ":"提請",
"ㄊㄧㄑㄩㄢ":"蹄筌",
"ㄊㄧㄒㄧㄚ":"體下",
"ㄊㄧㄒㄧㄝ":"提鞋",
"ㄊㄧㄒㄧㄡ":"體臭",
"ㄊㄧㄒㄧㄢ":"體現/提線",
"ㄊㄧㄒㄧㄣ":"體信",
"ㄊㄧㄒㄧㄤ":"提香",
"ㄊㄧㄒㄧㄥ":"提醒/體型",
"ㄊㄧㄒㄩㄝ":"提學",
"ㄊㄧㄒㄩㄢ":"提選",
"ㄊㄧㄒㄩㄣ":"提訊",
"ㄊㄧㄓㄨㄥ":"體重",
"ㄊㄧㄔㄥㄩ":"提稱語",
"ㄊㄧㄔㄨㄥ":"梯衝",
"ㄊㄧㄕㄖㄣ":"提示人",
"ㄊㄧㄘㄨㄣ":"提存",
"ㄊㄧㄝㄅㄟ":"貼背",
"ㄊㄧㄝㄅㄢ":"跕班/鐵板",
"ㄊㄧㄝㄅㄣ":"貼本",
"ㄊㄧㄝㄅㄤ":"鐵棒",
"ㄊㄧㄝㄅㄧ":"鐵筆/鐵壁",
"ㄊㄧㄝㄅㄨ":"貼補",
"ㄊㄧㄝㄆㄞ":"鐵牌",
"ㄊㄧㄝㄆㄧ":"鐵皮",
"ㄊㄧㄝㄇㄚ":"鐵馬",
"ㄊㄧㄝㄇㄠ":"鐵貓/鐵錨",
"ㄊㄧㄝㄇㄣ":"鐵門",
"ㄊㄧㄝㄇㄨ":"鐵幕",
"ㄊㄧㄝㄈㄟ":"鐵肺",
"ㄊㄧㄝㄈㄤ":"貼放",
"ㄊㄧㄝㄈㄨ":"貼夫/帖服",
"ㄊㄧㄝㄉㄚ":"鐵搭",
"ㄊㄧㄝㄉㄠ":"鐵道",
"ㄊㄧㄝㄉㄢ":"貼旦",
"ㄊㄧㄝㄉㄨ":"鐵都",
"ㄊㄧㄝㄊㄚ":"鐵塔",
"ㄊㄧㄝㄊㄧ":"貼題/鐵蹄",
"ㄊㄧㄝㄌㄨ":"鐵路",
"ㄊㄧㄝㄍㄡ":"鐵鉤",
"ㄊㄧㄝㄍㄨ":"鐵骨",
"ㄊㄧㄝㄎㄡ":"鐵口",
"ㄊㄧㄝㄏㄡ":"貼後",
"ㄊㄧㄝㄏㄢ":"鐵漢",
"ㄊㄧㄝㄏㄨ":"貼戶",
"ㄊㄧㄝㄐㄧ":"貼己/鐵騎",
"ㄊㄧㄝㄑㄧ":"鐵器/帖騎",
"ㄊㄧㄝㄒㄧ":"貼息/帖息",
"ㄊㄧㄝㄓㄥ":"鐵證",
"ㄊㄧㄝㄕㄗ":"鐵十字",
"ㄊㄧㄝㄕㄚ":"鐵砂",
"ㄊㄧㄝㄕㄢ":"鐵山/鐵杉",
"ㄊㄧㄝㄕㄣ":"貼身",
"ㄊㄧㄝㄕㄨ":"貼書/鐵樹",
"ㄊㄧㄝㄖㄡ":"貼肉",
"ㄊㄧㄝㄖㄢ":"帖然",
"ㄊㄧㄝㄖㄣ":"鐵人",
"ㄊㄧㄝㄗㄘ":"帖子詞",
"ㄊㄧㄝㄗㄜ":"鐵則",
"ㄊㄧㄝㄧㄝ":"鐵葉",
"ㄊㄧㄝㄨㄢ":"鐵腕",
"ㄊㄧㄠㄅㄛ":"挑撥/條播",
"ㄊㄧㄠㄅㄞ":"調白",
"ㄊㄧㄠㄅㄢ":"跳班/跳板",
"ㄊㄧㄠㄅㄧ":"調詖",
"ㄊㄧㄠㄆㄟ":"調配",
"ㄊㄧㄠㄆㄧ":"調皮",
"ㄊㄧㄠㄇㄚ":"條碼/跳馬",
"ㄊㄧㄠㄇㄨ":"條目",
"ㄊㄧㄠㄈㄚ":"調發",
"ㄊㄧㄠㄈㄟ":"蜩沸",
"ㄊㄧㄠㄈㄢ":"調犯/調泛",
"ㄊㄧㄠㄈㄥ":"條風/跳風",
"ㄊㄧㄠㄈㄨ":"條幅/調服",
"ㄊㄧㄠㄉㄚ":"條達/調達",
"ㄊㄧㄠㄉㄠ":"條道/挑刀",
"ㄊㄧㄠㄉㄡ":"挑逗/跳豆",
"ㄊㄧㄠㄉㄢ":"跳彈/挑擔",
"ㄊㄧㄠㄉㄥ":"條凳/挑燈",
"ㄊㄧㄠㄉㄧ":"調低/迢遞",
"ㄊㄧㄠㄊㄚ":"佻達/跳蹋",
"ㄊㄧㄠㄊㄞ":"跳臺/眺臺",
"ㄊㄧㄠㄊㄡ":"挑頭/跳投",
"ㄊㄧㄠㄊㄢ":"眺探",
"ㄊㄧㄠㄊㄧ":"挑剔",
"ㄊㄧㄠㄊㄨ":"挑土",
"ㄊㄧㄠㄌㄧ":"條例/條理",
"ㄊㄧㄠㄌㄨ":"調露",
"ㄊㄧㄠㄍㄜ":"鞗革",
"ㄊㄧㄠㄍㄠ":"跳高/調高",
"ㄊㄧㄠㄍㄥ":"調羹",
"ㄊㄧㄠㄎㄢ":"調侃",
"ㄊㄧㄠㄏㄜ":"調和/調合",
"ㄊㄧㄠㄏㄠ":"跳號",
"ㄊㄧㄠㄏㄤ":"跳行",
"ㄊㄧㄠㄏㄨ":"調護",
"ㄊㄧㄠㄐㄧ":"調劑/條記",
"ㄊㄧㄠㄐㄩ":"條據",
"ㄊㄧㄠㄑㄧ":"調氣/跳棋",
"ㄊㄧㄠㄑㄩ":"挑取/挑趣",
"ㄊㄧㄠㄒㄧ":"調戲/調息",
"ㄊㄧㄠㄒㄩ":"祧緒",
"ㄊㄧㄠㄓㄡ":"笤帚/苕帚",
"ㄊㄧㄠㄓㄢ":"挑戰",
"ㄊㄧㄠㄓㄤ":"調漲",
"ㄊㄧㄠㄓㄥ":"調整",
"ㄊㄧㄠㄔㄣ":"條陳/齠齔",
"ㄊㄧㄠㄔㄤ":"條暢",
"ㄊㄧㄠㄔㄨ":"跳出/糶出",
"ㄊㄧㄠㄕㄜ":"調攝",
"ㄊㄧㄠㄕㄢ":"挑閃",
"ㄊㄧㄠㄕㄣ":"跳神",
"ㄊㄧㄠㄕㄥ":"調升/跳繩",
"ㄊㄧㄠㄕㄨ":"跳鼠",
"ㄊㄧㄠㄖㄣ":"調人",
"ㄊㄧㄠㄗㄠ":"跳蚤",
"ㄊㄧㄠㄗㄡ":"條奏",
"ㄊㄧㄠㄘㄠ":"跳槽/挑槽",
"ㄊㄧㄠㄙㄜ":"調色",
"ㄊㄧㄠㄙㄢ":"跳傘",
"ㄊㄧㄠㄧㄚ":"挑牙",
"ㄊㄧㄠㄧㄠ":"迢遙/岧嶢",
"ㄊㄧㄠㄧㄢ":"挑眼/窕言",
"ㄊㄧㄠㄧㄣ":"調音/調引",
"ㄊㄧㄠㄧㄤ":"調養",
"ㄊㄧㄠㄨㄚ":"挑挖",
"ㄊㄧㄠㄨㄟ":"調味",
"ㄊㄧㄠㄨㄢ":"跳丸",
"ㄊㄧㄠㄨㄣ":"條文/條紋",
"ㄊㄧㄠㄨㄤ":"眺望",
"ㄊㄧㄠㄩㄝ":"條約/跳躍",
"ㄊㄧㄠㄩㄢ":"跳遠",
"ㄊㄧㄠㄩㄣ":"調勻",
"ㄊㄧㄢㄅㄠ":"天報/天保",
"ㄊㄧㄢㄅㄣ":"添本",
"ㄊㄧㄢㄅㄧ":"掭筆",
"ㄊㄧㄢㄅㄨ":"填補/天步",
"ㄊㄧㄢㄆㄣ":"添盆",
"ㄊㄧㄢㄆㄥ":"天棚",
"ㄊㄧㄢㄇㄚ":"天馬/天麻",
"ㄊㄧㄢㄇㄛ":"天末",
"ㄊㄧㄢㄇㄟ":"甜美",
"ㄊㄧㄢㄇㄠ":"靦冒",
"ㄊㄧㄢㄇㄢ":"填滿",
"ㄊㄧㄢㄇㄣ":"天門",
"ㄊㄧㄢㄇㄤ":"添忙",
"ㄊㄧㄢㄇㄧ":"甜蜜/恬謐",
"ㄊㄧㄢㄇㄨ":"天幕",
"ㄊㄧㄢㄈㄚ":"填發/天罰",
"ㄊㄧㄢㄈㄟ":"天飛/天妃",
"ㄊㄧㄢㄈㄣ":"天分/添墳",
"ㄊㄧㄢㄈㄤ":"天方/天放",
"ㄊㄧㄢㄈㄨ":"天賦/天父",
"ㄊㄧㄢㄉㄚ":"天大",
"ㄊㄧㄢㄉㄜ":"天德",
"ㄊㄧㄢㄉㄠ":"天道/天道",
"ㄊㄧㄢㄉㄡ":"甜豆",
"ㄊㄧㄢㄉㄢ":"天膽/田單",
"ㄊㄧㄢㄉㄥ":"天燈",
"ㄊㄧㄢㄉㄧ":"天地/田地",
"ㄊㄧㄢㄉㄨ":"天都",
"ㄊㄧㄢㄊㄞ":"天台/天臺",
"ㄊㄧㄢㄊㄡ":"甜頭/田頭",
"ㄊㄧㄢㄊㄢ":"天壇/舔舕",
"ㄊㄧㄢㄊㄤ":"天堂",
"ㄊㄧㄢㄊㄧ":"天體/天梯",
"ㄊㄧㄢㄋㄧ":"天倪/甜膩",
"ㄊㄧㄢㄋㄨ":"天怒",
"ㄊㄧㄢㄋㄩ":"天女",
"ㄊㄧㄢㄌㄜ":"天樂",
"ㄊㄧㄢㄌㄞ":"天籟",
"ㄊㄧㄢㄌㄟ":"天雷/忝累",
"ㄊㄧㄢㄌㄠ":"天老/天牢",
"ㄊㄧㄢㄌㄤ":"天狼",
"ㄊㄧㄢㄌㄧ":"天理/田里",
"ㄊㄧㄢㄌㄨ":"天路/天祿",
"ㄊㄧㄢㄍㄜ":"天戈",
"ㄊㄧㄢㄍㄠ":"天高",
"ㄊㄧㄢㄍㄡ":"天狗/天溝",
"ㄊㄧㄢㄍㄢ":"天干/甜甘",
"ㄊㄧㄢㄍㄤ":"天綱/天罡",
"ㄊㄧㄢㄍㄥ":"田埂",
"ㄊㄧㄢㄍㄨ":"天骨/田穀",
"ㄊㄧㄢㄎㄜ":"田客",
"ㄊㄧㄢㄏㄜ":"天和/天河",
"ㄊㄧㄢㄏㄞ":"填海",
"ㄊㄧㄢㄏㄟ":"天黑",
"ㄊㄧㄢㄏㄡ":"天后/天候",
"ㄊㄧㄢㄏㄢ":"天漢/天寒",
"ㄊㄧㄢㄏㄥ":"田橫",
"ㄊㄧㄢㄏㄨ":"田戶",
"ㄊㄧㄢㄐㄧ":"天際/田雞",
"ㄊㄧㄢㄑㄧ":"天氣/天啟",
"ㄊㄧㄢㄑㄩ":"天趣/天衢",
"ㄊㄧㄢㄒㄧ":"天喜/田畦",
"ㄊㄧㄢㄓㄡ":"天軸",
"ㄊㄧㄢㄓㄣ":"天真",
"ㄊㄧㄢㄓㄤ":"天章",
"ㄊㄧㄢㄓㄨ":"天主/天柱",
"ㄊㄧㄢㄔㄠ":"天朝",
"ㄊㄧㄢㄔㄡ":"田疇",
"ㄊㄧㄢㄔㄢ":"天產/田產",
"ㄊㄧㄢㄔㄤ":"天常",
"ㄊㄧㄢㄔㄥ":"天成/田塍",
"ㄊㄧㄢㄔㄨ":"天廚",
"ㄊㄧㄢㄕㄜ":"田舍",
"ㄊㄧㄢㄕㄡ":"天授/添壽",
"ㄊㄧㄢㄕㄢ":"天山",
"ㄊㄧㄢㄕㄣ":"天神",
"ㄊㄧㄢㄕㄤ":"天上",
"ㄊㄧㄢㄕㄥ":"天生/天聲",
"ㄊㄧㄢㄕㄨ":"天書/天數",
"ㄊㄧㄢㄕㄩ":"天使魚",
"ㄊㄧㄢㄖㄢ":"天然/填然",
"ㄊㄧㄢㄖㄣ":"天人",
"ㄊㄧㄢㄖㄤ":"天壤",
"ㄊㄧㄢㄗㄜ":"天擇/天澤",
"ㄊㄧㄢㄗㄞ":"天災",
"ㄊㄧㄢㄗㄠ":"天造/添造",
"ㄊㄧㄢㄗㄤ":"天葬",
"ㄊㄧㄢㄗㄥ":"添增/腆贈",
"ㄊㄧㄢㄗㄨ":"天足/天阻",
"ㄊㄧㄢㄘㄞ":"天才/甜菜",
"ㄊㄧㄢㄘㄠ":"天曹",
"ㄊㄧㄢㄘㄢ":"天殘/天蠶",
"ㄊㄧㄢㄘㄤ":"添倉/填倉",
"ㄊㄧㄢㄙㄙ":"甜絲絲",
"ㄊㄧㄢㄙㄜ":"天色/填塞",
"ㄊㄧㄢㄙㄞ":"田賽",
"ㄊㄧㄢㄧㄚ":"天涯/填鴨",
"ㄊㄧㄢㄧㄝ":"田野/填咽",
"ㄊㄧㄢㄧㄡ":"天祐",
"ㄊㄧㄢㄧㄢ":"天眼/天演",
"ㄊㄧㄢㄧㄤ":"天樣",
"ㄊㄧㄢㄨㄞ":"天外",
"ㄊㄧㄢㄨㄟ":"天位/天威",
"ㄊㄧㄢㄨㄣ":"天文/天問",
"ㄊㄧㄢㄨㄤ":"天王/天網",
"ㄊㄧㄢㄩㄝ":"天樂",
"ㄊㄧㄢㄩㄢ":"天緣/天淵",
"ㄊㄧㄢㄩㄣ":"天運/天韻",
"ㄊㄧㄣㄊㄧ":"梯恩梯",
"ㄊㄧㄥㄅㄚ":"停罷/挺拔",
"ㄊㄧㄥㄅㄛ":"停泊",
"ㄊㄧㄥㄅㄞ":"停擺",
"ㄊㄧㄥㄅㄟ":"停杯",
"ㄊㄧㄥㄅㄢ":"停辦/停版",
"ㄊㄧㄥㄅㄧ":"停筆/停閉",
"ㄊㄧㄥㄅㄨ":"停步",
"ㄊㄧㄥㄆㄞ":"聽牌",
"ㄊㄧㄥㄇㄠ":"挺矛",
"ㄊㄧㄥㄈㄣ":"停分",
"ㄊㄧㄥㄈㄤ":"停放/廳房",
"ㄊㄧㄥㄈㄥ":"停俸",
"ㄊㄧㄥㄈㄨ":"庭府",
"ㄊㄧㄥㄉㄞ":"停待",
"ㄊㄧㄥㄉㄤ":"停當",
"ㄊㄧㄥㄉㄥ":"桯凳",
"ㄊㄧㄥㄉㄧ":"停睇",
"ㄊㄧㄥㄉㄨ":"亭毒",
"ㄊㄧㄥㄊㄞ":"亭臺",
"ㄊㄧㄥㄊㄡ":"聽頭/停頭",
"ㄊㄧㄥㄊㄤ":"廳堂",
"ㄊㄧㄥㄋㄥ":"聽能",
"ㄊㄧㄥㄌㄠ":"聽老/停潦",
"ㄊㄧㄥㄌㄧ":"聽力/挺立",
"ㄊㄧㄥㄍㄜ":"停格",
"ㄊㄧㄥㄍㄠ":"庭誥/亭皋",
"ㄊㄧㄥㄍㄨ":"聽骨/聽鼓",
"ㄊㄧㄥㄎㄜ":"停課/聽課",
"ㄊㄧㄥㄎㄠ":"停靠",
"ㄊㄧㄥㄎㄢ":"停刊",
"ㄊㄧㄥㄏㄚ":"聽哈",
"ㄊㄧㄥㄏㄜ":"聽喝",
"ㄊㄧㄥㄏㄡ":"聽候",
"ㄊㄧㄥㄏㄤ":"停航",
"ㄊㄧㄥㄏㄨ":"庭戶",
"ㄊㄧㄥㄐㄧ":"霆擊",
"ㄊㄧㄥㄐㄩ":"挺舉",
"ㄊㄧㄥㄑㄩ":"聽取/汀曲",
"ㄊㄧㄥㄒㄧ":"聽戲/停息",
"ㄊㄧㄥㄓㄡ":"汀洲/汀州",
"ㄊㄧㄥㄓㄢ":"停戰",
"ㄊㄧㄥㄓㄣ":"聽診",
"ㄊㄧㄥㄓㄤ":"聽障/廳長",
"ㄊㄧㄥㄓㄥ":"聽政/聽證",
"ㄊㄧㄥㄓㄨ":"停住/挺住",
"ㄊㄧㄥㄔㄜ":"停車",
"ㄊㄧㄥㄔㄞ":"聽差",
"ㄊㄧㄥㄔㄢ":"停產/聽讒",
"ㄊㄧㄥㄔㄣ":"聽沉",
"ㄊㄧㄥㄔㄤ":"亭場",
"ㄊㄧㄥㄔㄨ":"聽除/庭除",
"ㄊㄧㄥㄕㄜ":"廳舍",
"ㄊㄧㄥㄕㄡ":"聽手/聽受",
"ㄊㄧㄥㄕㄣ":"挺身/聽審",
"ㄊㄧㄥㄕㄤ":"庭上",
"ㄊㄧㄥㄕㄥ":"挺生",
"ㄊㄧㄥㄕㄨ":"聽書",
"ㄊㄧㄥㄖㄢ":"挺然",
"ㄊㄧㄥㄖㄣ":"聽人/聽任",
"ㄊㄧㄥㄖㄨ":"廷辱",
"ㄊㄧㄥㄘㄞ":"亭彩",
"ㄊㄧㄥㄘㄢ":"停驂",
"ㄊㄧㄥㄙㄤ":"停喪",
"ㄊㄧㄥㄧㄝ":"停業",
"ㄊㄧㄥㄧㄠ":"挺腰",
"ㄊㄧㄥㄧㄡ":"聽友",
"ㄊㄧㄥㄧㄥ":"汀瀅",
"ㄊㄧㄥㄨㄟ":"庭闈/廷尉",
"ㄊㄧㄥㄨㄣ":"聽聞",
"ㄊㄧㄥㄩㄢ":"庭園/庭院",
"ㄊㄧㄥㄩㄣ":"停雲/停勻",
"ㄊㄧㄥㄩㄥ":"聽用/停用",
"ㄊㄧㄧㄤㄦ":"體樣兒",
"ㄊㄧㄩㄔㄤ":"體育場",
"ㄊㄨㄅㄛㄕ":"土博士",
"ㄊㄨㄅㄠㄗ":"土包子",
"ㄊㄨㄅㄧㄠ":"圖表",
"ㄊㄨㄅㄧㄢ":"突變",
"ㄊㄨㄅㄧㄥ":"土兵/徒兵",
"ㄊㄨㄆㄧㄢ":"圖片",
"ㄊㄨㄆㄧㄥ":"土平",
"ㄊㄨㄇㄧㄝ":"屠滅",
"ㄊㄨㄈㄣㄗ":"土粉子",
"ㄊㄨㄈㄤㄗ":"土房子",
"ㄊㄨㄈㄥㄨ":"土風舞",
"ㄊㄨㄉㄧㄠ":"土調",
"ㄊㄨㄉㄧㄥ":"圖釘/禿頂",
"ㄊㄨㄉㄨㄛ":"圖度",
"ㄊㄨㄉㄨㄟ":"土堆",
"ㄊㄨㄉㄨㄣ":"土遁",
"ㄊㄨㄊㄧㄠ":"兔跳",
"ㄊㄨㄊㄨㄛ":"兔脫",
"ㄊㄨㄊㄨㄟ":"吐退",
"ㄊㄨㄊㄨㄣ":"土豚",
"ㄊㄨㄋㄧㄡ":"土牛",
"ㄊㄨㄌㄠㄦ":"土老兒",
"ㄊㄨㄌㄧㄠ":"塗料/荼蓼",
"ㄊㄨㄌㄧㄡ":"吐溜",
"ㄊㄨㄌㄧㄤ":"吐良",
"ㄊㄨㄌㄨㄥ":"土龍/屠龍",
"ㄊㄨㄍㄠㄗ":"兔羔子",
"ㄊㄨㄍㄨㄗ":"屠沽子",
"ㄊㄨㄍㄨㄢ":"土官",
"ㄊㄨㄍㄨㄣ":"土棍",
"ㄊㄨㄍㄨㄥ":"圖工/土貢",
"ㄊㄨㄍㄨㄦ":"屠沽兒",
"ㄊㄨㄎㄜㄗ":"禿科子",
"ㄊㄨㄎㄨㄞ":"土塊",
"ㄊㄨㄎㄨㄟ":"菟葵",
"ㄊㄨㄎㄨㄥ":"土空",
"ㄊㄨㄏㄨㄚ":"土話/圖畫",
"ㄊㄨㄏㄨㄛ":"突火/吐火",
"ㄊㄨㄏㄨㄟ":"土灰/圖繪",
"ㄊㄨㄏㄨㄤ":"土黃/土蝗",
"ㄊㄨㄐㄧㄚ":"屠家",
"ㄊㄨㄐㄧㄝ":"圖解/土芥",
"ㄊㄨㄐㄧㄡ":"禿鷲",
"ㄊㄨㄐㄧㄢ":"圖鑑",
"ㄊㄨㄐㄧㄣ":"突進/塗金",
"ㄊㄨㄐㄧㄥ":"途徑/途經",
"ㄊㄨㄐㄩㄝ":"突厥",
"ㄊㄨㄑㄧㄠ":"土橋",
"ㄊㄨㄑㄧㄡ":"土丘/禿鶖",
"ㄊㄨㄑㄧㄤ":"土牆/土腔",
"ㄊㄨㄑㄩㄝ":"兔缺",
"ㄊㄨㄒㄧㄝ":"圖寫/塗寫",
"ㄊㄨㄒㄧㄢ":"凸顯/突顯",
"ㄊㄨㄒㄧㄤ":"圖像/土香",
"ㄊㄨㄒㄧㄥ":"土星/徒行",
"ㄊㄨㄒㄩㄝ":"土穴",
"ㄊㄨㄓㄨㄤ":"凸狀",
"ㄊㄨㄓㄨㄥ":"途中/徒眾",
"ㄊㄨㄔㄨㄣ":"兔脣",
"ㄊㄨㄔㄨㄤ":"禿瘡",
"ㄊㄨㄕㄨㄕ":"圖書室",
"ㄊㄨㄕㄨㄚ":"塗刷",
"ㄊㄨㄕㄨㄛ":"圖說/塗說",
"ㄊㄨㄗㄞㄗ":"兔崽子",
"ㄊㄨㄗㄨㄛ":"土作",
"ㄊㄨㄗㄨㄩ":"土族語",
"ㄊㄨㄘㄨㄛ":"土銼",
"ㄊㄨㄘㄨㄟ":"瘏悴",
"ㄊㄨㄘㄨㄣ":"圖存",
"ㄊㄨㄙㄨㄟ":"吐穗",
"ㄊㄨㄛㄅㄚ":"拖把/脫靶",
"ㄊㄨㄛㄅㄛ":"拖駁/脫膊",
"ㄊㄨㄛㄅㄟ":"駝背",
"ㄊㄨㄛㄅㄢ":"拖班/脫班",
"ㄊㄨㄛㄅㄧ":"橐筆/託庇",
"ㄊㄨㄛㄅㄨ":"拖步",
"ㄊㄨㄛㄆㄢ":"托盤",
"ㄊㄨㄛㄆㄧ":"脫皮",
"ㄊㄨㄛㄇㄚ":"唾罵/馱馬",
"ㄊㄨㄛㄇㄛ":"拖磨/托墨",
"ㄊㄨㄛㄇㄠ":"脫毛/脫帽",
"ㄊㄨㄛㄇㄣ":"它們/脫悶",
"ㄊㄨㄛㄇㄥ":"托夢/託夢",
"ㄊㄨㄛㄈㄚ":"脫髮",
"ㄊㄨㄛㄈㄢ":"拖翻/駝販",
"ㄊㄨㄛㄈㄥ":"託諷/駝峰",
"ㄊㄨㄛㄈㄨ":"托福/託福",
"ㄊㄨㄛㄉㄚ":"托大/託大",
"ㄊㄨㄛㄉㄞ":"拖帶",
"ㄊㄨㄛㄉㄡ":"拖逗",
"ㄊㄨㄛㄉㄢ":"托膽",
"ㄊㄨㄛㄉㄤ":"妥當/脫黨",
"ㄊㄨㄛㄉㄧ":"拖地/托地",
"ㄊㄨㄛㄊㄚ":"拖沓",
"ㄊㄨㄛㄊㄞ":"脫胎/托胎",
"ㄊㄨㄛㄊㄠ":"脫逃",
"ㄊㄨㄛㄊㄧ":"脫體",
"ㄊㄨㄛㄊㄨ":"脫兔",
"ㄊㄨㄛㄋㄢ":"脫難",
"ㄊㄨㄛㄌㄚ":"拖拉/脫蠟",
"ㄊㄨㄛㄌㄞ":"托賴/託賴",
"ㄊㄨㄛㄌㄟ":"拖累/拖雷",
"ㄊㄨㄛㄌㄡ":"脫漏",
"ㄊㄨㄛㄌㄢ":"脫懶",
"ㄊㄨㄛㄌㄧ":"脫離/脫粒",
"ㄊㄨㄛㄌㄨ":"駝鹿",
"ㄊㄨㄛㄍㄠ":"脫稿",
"ㄊㄨㄛㄍㄡ":"拖鉤",
"ㄊㄨㄛㄍㄤ":"脫肛",
"ㄊㄨㄛㄍㄥ":"鼉更",
"ㄊㄨㄛㄍㄨ":"託故/鼉鼓",
"ㄊㄨㄛㄎㄜ":"脫殼",
"ㄊㄨㄛㄎㄠ":"妥靠",
"ㄊㄨㄛㄎㄡ":"脫口",
"ㄊㄨㄛㄏㄢ":"脫汗",
"ㄊㄨㄛㄏㄨ":"唾壺",
"ㄊㄨㄛㄐㄧ":"脫籍/託跡",
"ㄊㄨㄛㄑㄧ":"唾棄/脫期",
"ㄊㄨㄛㄒㄧ":"脫屣/蘀兮",
"ㄊㄨㄛㄒㄩ":"脫序",
"ㄊㄨㄛㄓㄢ":"拓展",
"ㄊㄨㄛㄔㄚ":"沱茶",
"ㄊㄨㄛㄔㄜ":"拖車/拖扯",
"ㄊㄨㄛㄔㄡ":"脫臭",
"ㄊㄨㄛㄔㄢ":"脫產",
"ㄊㄨㄛㄔㄤ":"脫腸",
"ㄊㄨㄛㄔㄨ":"脫除",
"ㄊㄨㄛㄕㄡ":"脫手/唾手",
"ㄊㄨㄛㄕㄢ":"妥善",
"ㄊㄨㄛㄕㄣ":"脫身/託身",
"ㄊㄨㄛㄕㄥ":"托生/託生",
"ㄊㄨㄛㄕㄨ":"托熟",
"ㄊㄨㄛㄖㄢ":"脫然",
"ㄊㄨㄛㄗㄨ":"托足/託足",
"ㄊㄨㄛㄙㄜ":"脫色/托色",
"ㄊㄨㄛㄙㄞ":"托腮",
"ㄊㄨㄛㄙㄨ":"脫俗/脫粟",
"ㄊㄨㄛㄧㄝ":"拖曳/拖拽",
"ㄊㄨㄛㄧㄡ":"托幼",
"ㄊㄨㄛㄧㄢ":"拖延/託言",
"ㄊㄨㄛㄧㄣ":"脫陰",
"ㄊㄨㄛㄧㄤ":"脫陽/脫氧",
"ㄊㄨㄛㄧㄥ":"脫穎",
"ㄊㄨㄛㄨㄟ":"脫位",
"ㄊㄨㄛㄨㄣ":"脫文",
"ㄊㄨㄛㄨㄤ":"拖網",
"ㄊㄨㄛㄩㄝ":"橐籥",
"ㄊㄨㄛㄩㄢ":"妥員/駝員",
"ㄊㄨㄛㄩㄣ":"託運",
"ㄊㄨㄛㄩㄥ":"脫蛹",
"ㄊㄨㄟㄅㄛ":"頹波",
"ㄊㄨㄟㄅㄞ":"頹敗",
"ㄊㄨㄟㄅㄠ":"退保/推鉋",
"ㄊㄨㄟㄅㄧ":"退筆/退避",
"ㄊㄨㄟㄅㄨ":"退步/推步",
"ㄊㄨㄟㄆㄞ":"推派",
"ㄊㄨㄟㄆㄟ":"退賠",
"ㄊㄨㄟㄆㄧ":"蛻皮/頹圮",
"ㄊㄨㄟㄇㄛ":"推磨",
"ㄊㄨㄟㄇㄧ":"頹靡",
"ㄊㄨㄟㄈㄚ":"腿法",
"ㄊㄨㄟㄈㄟ":"頹廢/退費",
"ㄊㄨㄟㄈㄢ":"推翻",
"ㄊㄨㄟㄈㄤ":"頹放/退房",
"ㄊㄨㄟㄈㄥ":"頹風",
"ㄊㄨㄟㄈㄨ":"推服",
"ㄊㄨㄟㄉㄞ":"腿帶/推戴",
"ㄊㄨㄟㄉㄠ":"推倒/推導",
"ㄊㄨㄟㄉㄤ":"推宕",
"ㄊㄨㄟㄉㄧ":"退敵",
"ㄊㄨㄟㄊㄠ":"腿套",
"ㄊㄨㄟㄊㄡ":"推頭",
"ㄊㄨㄟㄊㄤ":"推搪/退堂",
"ㄊㄨㄟㄋㄚ":"推拿",
"ㄊㄨㄟㄌㄠ":"退老",
"ㄊㄨㄟㄌㄧ":"推理/推力",
"ㄊㄨㄟㄌㄨ":"退路",
"ㄊㄨㄟㄍㄠ":"退稿",
"ㄊㄨㄟㄍㄢ":"推桿",
"ㄊㄨㄟㄍㄨ":"退股/推故",
"ㄊㄨㄟㄎㄞ":"推開",
"ㄊㄨㄟㄎㄢ":"推勘",
"ㄊㄨㄟㄏㄡ":"退後",
"ㄊㄨㄟㄐㄧ":"推及/推激",
"ㄊㄨㄟㄐㄩ":"退居/推舉",
"ㄊㄨㄟㄑㄧ":"頹氣",
"ㄊㄨㄟㄒㄧ":"退息/退席",
"ㄊㄨㄟㄒㄩ":"推許",
"ㄊㄨㄟㄓㄢ":"推展",
"ㄊㄨㄟㄓㄣ":"退針",
"ㄊㄨㄟㄔㄜ":"推車",
"ㄊㄨㄟㄔㄠ":"退潮",
"ㄊㄨㄟㄔㄤ":"退場",
"ㄊㄨㄟㄔㄥ":"推稱",
"ㄊㄨㄟㄔㄨ":"推出/退出",
"ㄊㄨㄟㄕㄠ":"退燒",
"ㄊㄨㄟㄕㄡ":"推手/退守",
"ㄊㄨㄟㄕㄢ":"推山",
"ㄊㄨㄟㄖㄜ":"退熱",
"ㄊㄨㄟㄖㄢ":"頹然/隤然",
"ㄊㄨㄟㄖㄣ":"頹人/推刃",
"ㄊㄨㄟㄖㄤ":"退讓/推讓",
"ㄊㄨㄟㄗㄡ":"退走",
"ㄊㄨㄟㄗㄨ":"推阻/退租",
"ㄊㄨㄟㄘㄜ":"推測",
"ㄊㄨㄟㄙㄜ":"退色",
"ㄊㄨㄟㄙㄤ":"頹喪",
"ㄊㄨㄟㄧㄢ":"推演/推驗",
"ㄊㄨㄟㄧㄣ":"推因/退隱",
"ㄊㄨㄟㄧㄤ":"頹陽",
"ㄊㄨㄟㄨㄟ":"退位/推委",
"ㄊㄨㄟㄨㄢ":"推挽/推輓",
"ㄊㄨㄟㄨㄣ":"推問",
"ㄊㄨㄟㄩㄣ":"頹運/隤隕",
"ㄊㄨㄢㄅㄛ":"團剝",
"ㄊㄨㄢㄅㄞ":"團拜",
"ㄊㄨㄢㄆㄞ":"團牌",
"ㄊㄨㄢㄈㄟ":"團匪",
"ㄊㄨㄢㄈㄢ":"摶飯",
"ㄊㄨㄢㄈㄥ":"摶風",
"ㄊㄨㄢㄊㄡ":"團頭",
"ㄊㄨㄢㄊㄧ":"團體",
"ㄊㄨㄢㄌㄞ":"湍瀨",
"ㄊㄨㄢㄌㄧ":"團粒",
"ㄊㄨㄢㄎㄤ":"團康",
"ㄊㄨㄢㄏㄜ":"團荷",
"ㄊㄨㄢㄏㄤ":"團行",
"ㄊㄨㄢㄐㄧ":"湍急",
"ㄊㄨㄢㄐㄩ":"團聚",
"ㄊㄨㄢㄑㄧ":"團契/團臍",
"ㄊㄨㄢㄓㄜ":"團著",
"ㄊㄨㄢㄓㄤ":"團長",
"ㄊㄨㄢㄓㄨ":"團主",
"ㄊㄨㄢㄔㄚ":"團茶",
"ㄊㄨㄢㄕㄚ":"摶沙",
"ㄊㄨㄢㄕㄜ":"團社",
"ㄊㄨㄢㄕㄢ":"團扇",
"ㄊㄨㄢㄕㄨ":"團書",
"ㄊㄨㄢㄗㄠ":"團藻",
"ㄊㄨㄢㄧㄣ":"團音",
"ㄊㄨㄢㄨㄟ":"團圍",
"ㄊㄨㄢㄩㄢ":"團圓/團員",
"ㄊㄨㄢㄩㄥ":"團勇",
"ㄊㄨㄣㄅㄛ":"吞剝/褪膊",
"ㄊㄨㄣㄅㄨ":"臀部",
"ㄊㄨㄣㄆㄛ":"豚拍",
"ㄊㄨㄣㄇㄛ":"吞沒",
"ㄊㄨㄣㄇㄠ":"褪毛",
"ㄊㄨㄣㄈㄨ":"吞服",
"ㄊㄨㄣㄊㄚ":"囤塌",
"ㄊㄨㄣㄊㄡ":"屯頭",
"ㄊㄨㄣㄊㄢ":"吞炭/涒灘",
"ㄊㄨㄣㄊㄨ":"吞吐",
"ㄊㄨㄣㄎㄡ":"褪口",
"ㄊㄨㄣㄎㄣ":"屯墾",
"ㄊㄨㄣㄏㄣ":"吞恨",
"ㄊㄨㄣㄐㄧ":"囤積/屯積",
"ㄊㄨㄣㄐㄩ":"囤聚/屯聚",
"ㄊㄨㄣㄑㄧ":"吞氣/臀鰭",
"ㄊㄨㄣㄑㄩ":"褪去",
"ㄊㄨㄣㄓㄚ":"屯紮/豚柵",
"ㄊㄨㄣㄓㄡ":"吞舟",
"ㄊㄨㄣㄓㄨ":"屯駐",
"ㄊㄨㄣㄔㄜ":"軘車",
"ㄊㄨㄣㄕㄜ":"吞舌",
"ㄊㄨㄣㄕㄥ":"吞聲",
"ㄊㄨㄣㄕㄨ":"豚鼠",
"ㄊㄨㄣㄙㄜ":"褪色",
"ㄊㄨㄣㄧㄡ":"臀疣",
"ㄊㄨㄣㄧㄢ":"吞煙/吞嚥",
"ㄊㄨㄣㄨㄟ":"臀圍",
"ㄊㄨㄥㄅㄚ":"銅鈸",
"ㄊㄨㄥㄅㄟ":"同輩",
"ㄊㄨㄥㄅㄠ":"同胞/通報",
"ㄊㄨㄥㄅㄢ":"同班/同伴",
"ㄊㄨㄥㄅㄤ":"同榜",
"ㄊㄨㄥㄅㄧ":"銅幣/童婢",
"ㄊㄨㄥㄅㄨ":"同步/橦布",
"ㄊㄨㄥㄆㄞ":"銅牌",
"ㄊㄨㄥㄆㄠ":"同袍",
"ㄊㄨㄥㄆㄢ":"通判/通盤",
"ㄊㄨㄥㄆㄥ":"通膨",
"ㄊㄨㄥㄆㄨ":"同譜/通鋪",
"ㄊㄨㄥㄇㄚ":"痛罵",
"ㄊㄨㄥㄇㄛ":"銅模",
"ㄊㄨㄥㄇㄡ":"同謀/通謀",
"ㄊㄨㄥㄇㄢ":"統鏝",
"ㄊㄨㄥㄇㄣ":"同門",
"ㄊㄨㄥㄇㄥ":"同盟/同夢",
"ㄊㄨㄥㄇㄨ":"同母",
"ㄊㄨㄥㄈㄣ":"通分/銅粉",
"ㄊㄨㄥㄈㄤ":"同房/同方",
"ㄊㄨㄥㄈㄥ":"痛風/通風",
"ㄊㄨㄥㄉㄚ":"痛打/通達",
"ㄊㄨㄥㄉㄜ":"同德",
"ㄊㄨㄥㄉㄞ":"銅黛",
"ㄊㄨㄥㄉㄠ":"通道/同道",
"ㄊㄨㄥㄉㄤ":"同黨",
"ㄊㄨㄥㄉㄥ":"同等",
"ㄊㄨㄥㄉㄧ":"通敵/痛詆",
"ㄊㄨㄥㄉㄨ":"通讀/統獨",
"ㄊㄨㄥㄊㄚ":"同榻",
"ㄊㄨㄥㄊㄞ":"通泰",
"ㄊㄨㄥㄊㄠ":"通套",
"ㄊㄨㄥㄊㄡ":"通透/通頭",
"ㄊㄨㄥㄊㄤ":"同堂",
"ㄊㄨㄥㄊㄧ":"通體",
"ㄊㄨㄥㄋㄢ":"童男",
"ㄊㄨㄥㄋㄩ":"童女",
"ㄊㄨㄥㄌㄜ":"同樂",
"ㄊㄨㄥㄌㄟ":"同類",
"ㄊㄨㄥㄌㄢ":"通欄",
"ㄊㄨㄥㄌㄤ":"通朗",
"ㄊㄨㄥㄌㄧ":"同理/通理",
"ㄊㄨㄥㄌㄨ":"同路/通路",
"ㄊㄨㄥㄌㄩ":"銅綠",
"ㄊㄨㄥㄍㄠ":"通告",
"ㄊㄨㄥㄍㄡ":"統購",
"ㄊㄨㄥㄍㄢ":"同感/痛感",
"ㄊㄨㄥㄍㄣ":"同根",
"ㄊㄨㄥㄍㄥ":"同庚",
"ㄊㄨㄥㄍㄨ":"銅鼓",
"ㄊㄨㄥㄎㄜ":"同科",
"ㄊㄨㄥㄎㄞ":"捅開",
"ㄊㄨㄥㄎㄠ":"通考",
"ㄊㄨㄥㄎㄡ":"通口/統口",
"ㄊㄨㄥㄎㄨ":"痛苦/痛哭",
"ㄊㄨㄥㄏㄜ":"統合/痛喝",
"ㄊㄨㄥㄏㄠ":"同好/通好",
"ㄊㄨㄥㄏㄢ":"通函/銅銲",
"ㄊㄨㄥㄏㄣ":"痛恨",
"ㄊㄨㄥㄏㄤ":"同行/通航",
"ㄊㄨㄥㄏㄨ":"銅壺",
"ㄊㄨㄥㄐㄧ":"統計/同級",
"ㄊㄨㄥㄐㄩ":"同居",
"ㄊㄨㄥㄑㄧ":"同期/通氣",
"ㄊㄨㄥㄑㄩ":"通衢/童趣",
"ㄊㄨㄥㄒㄧ":"同喜/同席",
"ㄊㄨㄥㄓㄢ":"統戰",
"ㄊㄨㄥㄓㄣ":"童真/童貞",
"ㄊㄨㄥㄓㄨ":"銅柱",
"ㄊㄨㄥㄔㄜ":"通車/同車",
"ㄊㄨㄥㄔㄞ":"同儕",
"ㄊㄨㄥㄔㄡ":"同仇/統籌",
"ㄊㄨㄥㄔㄢ":"同產",
"ㄊㄨㄥㄔㄣ":"同塵/通陳",
"ㄊㄨㄥㄔㄤ":"通常/通暢",
"ㄊㄨㄥㄔㄥ":"統稱/通稱",
"ㄊㄨㄥㄔㄨ":"痛楚/痛處",
"ㄊㄨㄥㄕㄚ":"通殺",
"ㄊㄨㄥㄕㄡ":"銅獸",
"ㄊㄨㄥㄕㄢ":"童山/銅山",
"ㄊㄨㄥㄕㄣ":"通身/通神",
"ㄊㄨㄥㄕㄤ":"同上/通商",
"ㄊㄨㄥㄕㄥ":"同聲/同生",
"ㄊㄨㄥㄕㄨ":"通書/通疏",
"ㄊㄨㄥㄖㄣ":"同人/同仁",
"ㄊㄨㄥㄖㄨ":"通儒",
"ㄊㄨㄥㄗㄕ":"童子試",
"ㄊㄨㄥㄗㄜ":"通則",
"ㄊㄨㄥㄗㄢ":"痛贊",
"ㄊㄨㄥㄗㄨ":"同族",
"ㄊㄨㄥㄘㄞ":"通才/通財",
"ㄊㄨㄥㄘㄠ":"通草",
"ㄊㄨㄥㄘㄢ":"同參",
"ㄊㄨㄥㄘㄤ":"統艙",
"ㄊㄨㄥㄙㄜ":"通塞",
"ㄊㄨㄥㄙㄨ":"通俗/同宿",
"ㄊㄨㄥㄧㄘ":"同義詞",
"ㄊㄨㄥㄧㄚ":"通雅",
"ㄊㄨㄥㄧㄝ":"同業/通夜",
"ㄊㄨㄥㄧㄠ":"童謠",
"ㄊㄨㄥㄧㄡ":"通郵/桐油",
"ㄊㄨㄥㄧㄢ":"童顏/同硯",
"ㄊㄨㄥㄧㄣ":"痛飲/同寅",
"ㄊㄨㄥㄧㄤ":"同樣/痛癢",
"ㄊㄨㄥㄨㄚ":"銅瓦",
"ㄊㄨㄥㄨㄟ":"彤闈",
"ㄊㄨㄥㄨㄢ":"童玩",
"ㄊㄨㄥㄨㄣ":"同文/通問",
"ㄊㄨㄥㄨㄤ":"通往",
"ㄊㄨㄥㄩㄢ":"同源/同願",
"ㄊㄨㄥㄩㄣ":"通運/通韻",
"ㄊㄨㄥㄩㄥ":"通用",
"ㄊㄨㄦㄧㄝ":"兔兒爺",
"ㄊㄨㄧㄠㄗ":"土窯子",
"ㄊㄨㄨㄟㄗ":"土圍子",
"ㄋㄚㄆㄧㄣ":"納聘",
"ㄋㄚㄇㄣㄗ":"哪門子",
"ㄋㄚㄇㄧㄥ":"納命",
"ㄋㄚㄉㄤㄦ":"那當兒",
"ㄋㄚㄉㄧㄥ":"拿定",
"ㄋㄚㄊㄡㄦ":"那頭兒",
"ㄋㄚㄊㄧㄢ":"那天",
"ㄋㄚㄊㄨㄟ":"拿腿",
"ㄋㄚㄋㄧㄝ":"拿捏/拿捻",
"ㄋㄚㄌㄧㄗ":"鈉離子",
"ㄋㄚㄌㄧㄡ":"納流",
"ㄋㄚㄌㄧㄢ":"衲臉",
"ㄋㄚㄌㄧㄤ":"納涼",
"ㄋㄚㄍㄨㄞ":"拿拐",
"ㄋㄚㄍㄨㄢ":"納官",
"ㄋㄚㄍㄨㄥ":"納貢",
"ㄋㄚㄎㄨㄢ":"拿款/納款",
"ㄋㄚㄎㄨㄤ":"那匡",
"ㄋㄚㄎㄨㄥ":"拏空",
"ㄋㄚㄏㄚㄦ":"那哈兒",
"ㄋㄚㄏㄨㄚ":"拿話/拿滑",
"ㄋㄚㄏㄨㄛ":"拿獲",
"ㄋㄚㄏㄨㄟ":"納賄",
"ㄋㄚㄏㄨㄢ":"納還",
"ㄋㄚㄐㄧㄚ":"納甲",
"ㄋㄚㄐㄧㄝ":"納節",
"ㄋㄚㄐㄧㄠ":"納交/拿腳",
"ㄋㄚㄐㄧㄡ":"那就",
"ㄋㄚㄐㄧㄢ":"納諫",
"ㄋㄚㄑㄧㄝ":"納妾",
"ㄋㄚㄑㄧㄠ":"拿翹/拿喬",
"ㄋㄚㄑㄧㄥ":"拿情",
"ㄋㄚㄑㄩㄢ":"拿權",
"ㄋㄚㄒㄧㄚ":"那下/拿下",
"ㄋㄚㄒㄧㄝ":"那些/那些",
"ㄋㄚㄒㄧㄢ":"納獻",
"ㄋㄚㄒㄧㄤ":"納降",
"ㄋㄚㄒㄧㄩ":"納西語",
"ㄋㄚㄓㄨㄤ":"拿樁",
"ㄋㄚㄓㄨㄧ":"拿主意",
"ㄋㄚㄔㄚㄦ":"拿岔兒",
"ㄋㄚㄔㄨㄥ":"納寵",
"ㄋㄚㄕㄨㄟ":"納稅/納水",
"ㄋㄚㄕㄨㄣ":"納順",
"ㄋㄚㄗㄨㄥ":"拿總",
"ㄋㄚㄘㄨㄟ":"納粹",
"ㄋㄚㄙㄕㄜ":"拿死蛇",
"ㄋㄚㄜㄊㄡ":"拿訛頭",
"ㄋㄚㄧㄅㄚ":"拿一把",
"ㄋㄚㄧㄚㄗ":"拿鴨子",
"ㄋㄚㄧㄤㄗ":"那樣子",
"ㄋㄞㄅㄧㄥ":"奶餅",
"ㄋㄞㄆㄤㄗ":"奶膀子",
"ㄋㄞㄆㄧㄣ":"奶品",
"ㄋㄞㄆㄧㄥ":"奶瓶",
"ㄋㄞㄇㄧㄥ":"奶名",
"ㄋㄞㄋㄧㄤ":"奶娘",
"ㄋㄞㄌㄨㄛ":"奶酪",
"ㄋㄞㄌㄨㄥ":"耐龍",
"ㄋㄞㄍㄨㄥ":"奶公/乃公",
"ㄋㄞㄐㄧㄡ":"耐久/奶酒",
"ㄋㄞㄐㄧㄣ":"乃今",
"ㄋㄞㄐㄧㄥ":"奶精",
"ㄋㄞㄐㄩㄢ":"奶捲/乃眷",
"ㄋㄞㄒㄧㄣ":"耐心",
"ㄋㄞㄒㄧㄤ":"奈向",
"ㄋㄞㄒㄧㄥ":"耐性",
"ㄋㄞㄓㄨㄤ":"耐撞",
"ㄋㄞㄕㄨㄟ":"奶水",
"ㄋㄞㄖㄨㄛ":"乃若",
"ㄋㄞㄗㄨㄟ":"奶嘴",
"ㄋㄟㄅㄧㄠ":"內標",
"ㄋㄟㄅㄧㄢ":"內變",
"ㄋㄟㄅㄧㄣ":"內賓",
"ㄋㄟㄅㄧㄥ":"餒病",
"ㄋㄟㄇㄧㄢ":"內面",
"ㄋㄟㄇㄧㄥ":"內命",
"ㄋㄟㄉㄧㄠ":"內調",
"ㄋㄟㄉㄧㄢ":"內典",
"ㄋㄟㄉㄧㄥ":"內定",
"ㄋㄟㄊㄤㄨ":"內堂屋",
"ㄋㄟㄊㄧㄥ":"內廷",
"ㄋㄟㄌㄧㄢ":"內斂",
"ㄋㄟㄌㄨㄢ":"內亂",
"ㄋㄟㄍㄜㄓ":"內閣制",
"ㄋㄟㄍㄨㄟ":"內規",
"ㄋㄟㄍㄨㄢ":"內觀/內官",
"ㄋㄟㄍㄨㄥ":"內功",
"ㄋㄟㄎㄨㄣ":"內壼",
"ㄋㄟㄏㄨㄞ":"內懷",
"ㄋㄟㄏㄨㄟ":"內慧/內諱",
"ㄋㄟㄏㄨㄢ":"內患",
"ㄋㄟㄏㄨㄥ":"內鬨/內訌",
"ㄋㄟㄐㄧㄚ":"內家",
"ㄋㄟㄐㄧㄝ":"內姊",
"ㄋㄟㄐㄧㄠ":"內教/內角",
"ㄋㄟㄐㄧㄡ":"內疚",
"ㄋㄟㄐㄧㄢ":"內奸/內間",
"ㄋㄟㄐㄧㄥ":"內景",
"ㄋㄟㄐㄩㄢ":"內眷",
"ㄋㄟㄑㄧㄢ":"內遷/內縴",
"ㄋㄟㄑㄧㄣ":"內親/內勤",
"ㄋㄟㄑㄧㄥ":"內情",
"ㄋㄟㄑㄩㄝ":"餒怯",
"ㄋㄟㄒㄧㄠ":"內銷",
"ㄋㄟㄒㄧㄢ":"內線",
"ㄋㄟㄒㄧㄣ":"內心",
"ㄋㄟㄒㄧㄤ":"內向/內相",
"ㄋㄟㄒㄧㄥ":"內行/內省",
"ㄋㄟㄒㄩㄝ":"內學",
"ㄋㄟㄒㄩㄣ":"內訓",
"ㄋㄟㄒㄩㄥ":"內兄",
"ㄋㄟㄓㄋㄩ":"內姪女",
"ㄋㄟㄓㄨㄢ":"內轉/內傳",
"ㄋㄟㄓㄨㄥ":"內中",
"ㄋㄟㄔㄨㄥ":"內寵",
"ㄋㄟㄖㄨㄥ":"內容",
"ㄋㄟㄙㄨㄣ":"內孫",
"ㄋㄟㄦㄧㄢ":"內耳炎",
"ㄋㄟㄧㄎㄨ":"內衣褲",
"ㄋㄠㄅㄧㄥ":"鬧病",
"ㄋㄠㄉㄨㄗ":"鬧肚子",
"ㄋㄠㄉㄨㄥ":"鬧動",
"ㄋㄠㄋㄧㄥ":"淖濘",
"ㄋㄠㄌㄧㄡ":"腦瘤",
"ㄋㄠㄌㄧㄤ":"腦量",
"ㄋㄠㄌㄨㄢ":"惱亂",
"ㄋㄠㄍㄨㄚ":"惱聒",
"ㄋㄠㄍㄨㄟ":"鬧鬼",
"ㄋㄠㄏㄠㄗ":"鬧耗子",
"ㄋㄠㄏㄨㄛ":"惱火",
"ㄋㄠㄏㄨㄟ":"惱悔",
"ㄋㄠㄏㄨㄤ":"鬧荒",
"ㄋㄠㄏㄨㄥ":"鬧鬨",
"ㄋㄠㄐㄧㄚ":"鬧架",
"ㄋㄠㄐㄧㄝ":"撓節",
"ㄋㄠㄐㄧㄡ":"鬧酒/腦揪",
"ㄋㄠㄐㄧㄣ":"腦筋",
"ㄋㄠㄐㄧㄤ":"腦漿",
"ㄋㄠㄑㄧㄠ":"腦橋",
"ㄋㄠㄓㄨㄤ":"鬧裝",
"ㄋㄠㄓㄨㄥ":"鬧鐘/孬種",
"ㄋㄠㄔㄨㄟ":"鐃吹",
"ㄋㄠㄕㄠㄗ":"腦杓子",
"ㄋㄠㄗㄠㄗ":"腦鑿子",
"ㄋㄠㄗㄨㄓ":"腦組織",
"ㄋㄠㄘㄨㄛ":"鬧錯",
"ㄋㄠㄙㄤㄗ":"鬧嗓子",
"ㄋㄠㄙㄨㄟ":"腦髓",
"ㄋㄢㄅㄢㄗ":"南班子",
"ㄋㄢㄅㄤㄗ":"南梆子",
"ㄋㄢㄅㄧㄢ":"南邊",
"ㄋㄢㄇㄢㄗ":"南蠻子",
"ㄋㄢㄇㄧㄢ":"難免/南面",
"ㄋㄢㄇㄧㄣ":"難民",
"ㄋㄢㄇㄧㄥ":"南明",
"ㄋㄢㄉㄧㄢ":"難點",
"ㄋㄢㄉㄧㄥ":"男丁",
"ㄋㄢㄉㄨㄢ":"南端",
"ㄋㄢㄉㄨㄣ":"南頓",
"ㄋㄢㄊㄧㄝ":"南帖",
"ㄋㄢㄊㄧㄥ":"難聽",
"ㄋㄢㄊㄨㄛ":"難陀",
"ㄋㄢㄊㄨㄟ":"南腿",
"ㄋㄢㄊㄨㄥ":"男童",
"ㄋㄢㄌㄧㄡ":"楠榴",
"ㄋㄢㄌㄧㄤ":"南涼",
"ㄋㄢㄌㄧㄥ":"南嶺",
"ㄋㄢㄍㄨㄚ":"南瓜",
"ㄋㄢㄍㄨㄛ":"難過/南國",
"ㄋㄢㄍㄨㄞ":"難怪",
"ㄋㄢㄍㄨㄢ":"難關/難關",
"ㄋㄢㄍㄨㄥ":"南宮",
"ㄋㄢㄎㄨㄟ":"赧愧",
"ㄋㄢㄏㄞㄗ":"男孩子",
"ㄋㄢㄏㄨㄛ":"南貨",
"ㄋㄢㄏㄨㄟ":"南迴",
"ㄋㄢㄐㄧㄚ":"男家",
"ㄋㄢㄐㄧㄝ":"難解",
"ㄋㄢㄐㄧㄠ":"南郊",
"ㄋㄢㄐㄧㄡ":"南酒",
"ㄋㄢㄐㄧㄢ":"南箋",
"ㄋㄢㄐㄧㄣ":"南金",
"ㄋㄢㄐㄧㄥ":"南京",
"ㄋㄢㄐㄩㄝ":"男爵",
"ㄋㄢㄑㄧㄝ":"男妾",
"ㄋㄢㄑㄧㄠ":"難僑",
"ㄋㄢㄑㄩㄢ":"男權/南拳",
"ㄋㄢㄒㄧㄚ":"南下",
"ㄋㄢㄒㄧㄠ":"男校",
"ㄋㄢㄒㄧㄢ":"南絃",
"ㄋㄢㄒㄧㄥ":"男性/難行",
"ㄋㄢㄒㄩㄝ":"南學",
"ㄋㄢㄒㄩㄣ":"南巡/南薰",
"ㄋㄢㄓㄨㄢ":"南饌",
"ㄋㄢㄓㄨㄤ":"男裝",
"ㄋㄢㄓㄨㄥ":"南中",
"ㄋㄢㄔㄨㄟ":"南垂",
"ㄋㄢㄕㄨㄛ":"難說",
"ㄋㄢㄖㄨㄢ":"南阮",
"ㄋㄢㄖㄨㄥ":"難容/南榮",
"ㄋㄢㄗㄏㄢ":"男子漢",
"ㄋㄢㄗㄨㄥ":"南宗",
"ㄋㄢㄙㄨㄥ":"南宋",
"ㄋㄢㄨㄚㄗ":"南瓦子",
"ㄋㄣㄇㄧㄠ":"嫩苗",
"ㄋㄣㄌㄧㄢ":"嫩臉",
"ㄋㄣㄌㄧㄤ":"嫩涼",
"ㄋㄣㄏㄨㄤ":"嫩黃",
"ㄋㄣㄐㄧㄤ":"嫩江",
"ㄋㄣㄑㄧㄥ":"嫩晴",
"ㄋㄤㄉㄧㄓ":"囊底智",
"ㄋㄤㄊㄨㄛ":"囊橐",
"ㄋㄤㄍㄨㄚ":"囊括",
"ㄋㄤㄐㄧㄚ":"囊家",
"ㄋㄤㄓㄨㄤ":"囊裝",
"ㄋㄤㄔㄨㄞ":"囊揣",
"ㄋㄥㄆㄧㄣ":"能品",
"ㄋㄥㄇㄧㄥ":"能名",
"ㄋㄥㄉㄨㄥ":"能動",
"ㄋㄥㄌㄧㄤ":"能量",
"ㄋㄧㄇㄛㄦ":"膩抹兒",
"ㄋㄧㄇㄧㄥ":"匿名/逆命",
"ㄋㄧㄉㄧㄥ":"擬定/擬訂",
"ㄋㄧㄉㄨㄟ":"泥堆",
"ㄋㄧㄊㄨㄛ":"擬妥",
"ㄋㄧㄊㄨㄟ":"泥腿",
"ㄋㄧㄋㄧㄡ":"泥牛",
"ㄋㄧㄋㄧㄢ":"匿年",
"ㄋㄧㄋㄧㄥ":"泥濘",
"ㄋㄧㄌㄧㄠ":"呢料/逆料",
"ㄋㄧㄌㄧㄡ":"逆流/泥流",
"ㄋㄧㄌㄧㄣ":"逆鱗",
"ㄋㄧㄌㄨㄢ":"逆亂",
"ㄋㄧㄌㄨㄣ":"逆倫",
"ㄋㄧㄌㄨㄥ":"尼龍/泥龍",
"ㄋㄧㄍㄨㄞ":"匿拐",
"ㄋㄧㄍㄨㄢ":"尼姑庵",
"ㄋㄧㄍㄨㄤ":"逆光",
"ㄋㄧㄍㄨㄥ":"泥工/匿功",
"ㄋㄧㄎㄨㄢ":"兒寬",
"ㄋㄧㄏㄨㄚ":"膩滑",
"ㄋㄧㄏㄨㄟ":"泥灰",
"ㄋㄧㄏㄨㄥ":"霓虹",
"ㄋㄧㄐㄧㄚ":"你家",
"ㄋㄧㄐㄧㄝ":"逆接",
"ㄋㄧㄐㄧㄠ":"昵交/泥腳",
"ㄋㄧㄐㄧㄡ":"擬就/泥酒",
"ㄋㄧㄐㄧㄣ":"泥金",
"ㄋㄧㄐㄧㄤ":"泥漿",
"ㄋㄧㄐㄧㄥ":"逆境/擬經",
"ㄋㄧㄑㄧㄡ":"泥鰍/麑裘",
"ㄋㄧㄑㄧㄤ":"泥牆",
"ㄋㄧㄑㄧㄥ":"匿情/擬請",
"ㄋㄧㄒㄧㄠ":"匿笑",
"ㄋㄧㄒㄧㄣ":"溺信",
"ㄋㄧㄒㄧㄤ":"逆向/膩香",
"ㄋㄧㄒㄧㄥ":"逆行",
"ㄋㄧㄓㄨㄢ":"逆轉/泥磚",
"ㄋㄧㄓㄨㄥ":"泥中",
"ㄋㄧㄔㄨㄤ":"泥窗",
"ㄋㄧㄕㄥㄘ":"擬聲詞",
"ㄋㄧㄕㄨㄟ":"泥水/逆水",
"ㄋㄧㄖㄨㄥ":"呢絨",
"ㄋㄧㄗㄨㄛ":"擬作/猊座",
"ㄋㄧㄗㄨㄟ":"泥醉",
"ㄋㄧㄘㄨㄢ":"匿竄",
"ㄋㄧㄝㄅㄞ":"涅白",
"ㄋㄧㄝㄅㄠ":"捏報",
"ㄋㄧㄝㄅㄧ":"捏鼻/捻鼻",
"ㄋㄧㄝㄆㄟ":"捏坏",
"ㄋㄧㄝㄆㄢ":"涅槃",
"ㄋㄧㄝㄉㄤ":"孽黨",
"ㄋㄧㄝㄍㄣ":"孽根",
"ㄋㄧㄝㄍㄤ":"鎳鋼",
"ㄋㄧㄝㄏㄜ":"捏合/捻合",
"ㄋㄧㄝㄐㄧ":"捏積",
"ㄋㄧㄝㄓㄤ":"孽障",
"ㄋㄧㄝㄓㄥ":"聶政",
"ㄋㄧㄝㄔㄨ":"孽畜",
"ㄋㄧㄝㄕㄜ":"捏舌/捻舌",
"ㄋㄧㄝㄖㄨ":"囁嚅",
"ㄋㄧㄝㄗㄠ":"捏造",
"ㄋㄧㄝㄗㄨ":"躡足",
"ㄋㄧㄝㄙㄨ":"捏塑/捻塑",
"ㄋㄧㄝㄧㄥ":"躡景",
"ㄋㄧㄝㄩㄢ":"孽緣/孽冤",
"ㄋㄧㄠㄅㄨ":"尿布/鳥卜",
"ㄋㄧㄠㄈㄟ":"尿肥",
"ㄋㄧㄠㄈㄤ":"鳥房",
"ㄋㄧㄠㄉㄞ":"尿袋",
"ㄋㄧㄠㄉㄠ":"尿道/鳥道",
"ㄋㄧㄠㄋㄠ":"嬲惱",
"ㄋㄧㄠㄋㄤ":"尿囊",
"ㄋㄧㄠㄌㄟ":"鳥類",
"ㄋㄧㄠㄌㄧ":"鳥梨",
"ㄋㄧㄠㄎㄢ":"鳥瞰",
"ㄋㄧㄠㄎㄤ":"尿炕",
"ㄋㄧㄠㄏㄨ":"尿壺",
"ㄋㄧㄠㄐㄧ":"尿急",
"ㄋㄧㄠㄐㄩ":"鳥舉",
"ㄋㄧㄠㄑㄧ":"溺器",
"ㄋㄧㄠㄔㄠ":"鳥巢",
"ㄋㄧㄠㄕㄡ":"鳥獸",
"ㄋㄧㄠㄖㄠ":"裊繞",
"ㄋㄧㄠㄖㄣ":"鳥人",
"ㄋㄧㄠㄗㄤ":"鳥葬",
"ㄋㄧㄠㄙㄢ":"鳥散",
"ㄋㄧㄠㄙㄨ":"尿素",
"ㄋㄧㄠㄧㄝ":"尿液",
"ㄋㄧㄠㄨㄛ":"鳥窩",
"ㄋㄧㄠㄨㄣ":"鳥紋",
"ㄋㄧㄠㄨㄤ":"鳥王",
"ㄋㄧㄠㄩㄢ":"鳥園",
"ㄋㄧㄡㄅㄢ":"鈕絆",
"ㄋㄧㄡㄅㄤ":"牛蒡",
"ㄋㄧㄡㄆㄞ":"牛排",
"ㄋㄧㄡㄆㄥ":"牛棚",
"ㄋㄧㄡㄆㄧ":"牛皮",
"ㄋㄧㄡㄇㄚ":"牛馬",
"ㄋㄧㄡㄇㄠ":"牛毛",
"ㄋㄧㄡㄇㄣ":"紐門",
"ㄋㄧㄡㄇㄥ":"牛虻",
"ㄋㄧㄡㄉㄚ":"扭打/扭搭",
"ㄋㄧㄡㄉㄞ":"紐帶",
"ㄋㄧㄡㄉㄠ":"牛刀",
"ㄋㄧㄡㄉㄡ":"牛痘",
"ㄋㄧㄡㄉㄨ":"牛犢",
"ㄋㄧㄡㄊㄡ":"牛頭",
"ㄋㄧㄡㄋㄞ":"牛奶",
"ㄋㄧㄡㄋㄢ":"牛腩",
"ㄋㄧㄡㄋㄧ":"忸怩",
"ㄋㄧㄡㄋㄩ":"牛女",
"ㄋㄧㄡㄌㄢ":"牛欄",
"ㄋㄧㄡㄌㄤ":"牛郎",
"ㄋㄧㄡㄌㄧ":"扭力/牛犁",
"ㄋㄧㄡㄎㄞ":"扭開",
"ㄋㄧㄡㄎㄡ":"鈕扣/紐扣",
"ㄋㄧㄡㄏㄡ":"牛後",
"ㄋㄧㄡㄑㄧ":"牛氣",
"ㄋㄧㄡㄑㄩ":"扭曲",
"ㄋㄧㄡㄒㄧ":"狃習",
"ㄋㄧㄡㄔㄜ":"牛車",
"ㄋㄧㄡㄕㄣ":"扭身",
"ㄋㄧㄡㄕㄤ":"扭傷",
"ㄋㄧㄡㄖㄡ":"牛肉",
"ㄋㄧㄡㄖㄣ":"牛人",
"ㄋㄧㄡㄖㄨ":"牛乳",
"ㄋㄧㄡㄗㄚ":"牛雜",
"ㄋㄧㄡㄗㄨ":"紐捽",
"ㄋㄧㄡㄙㄡ":"扭搜",
"ㄋㄧㄡㄧㄠ":"牛腰/扭腰",
"ㄋㄧㄡㄧㄡ":"牛油",
"ㄋㄧㄡㄧㄢ":"牛眼",
"ㄋㄧㄡㄧㄣ":"牛飲",
"ㄋㄧㄡㄧㄤ":"牛鞅",
"ㄋㄧㄡㄧㄥ":"牛蠅",
"ㄋㄧㄡㄨㄚ":"牛蛙",
"ㄋㄧㄡㄨㄣ":"牛瘟",
"ㄋㄧㄢㄅㄚ":"年把",
"ㄋㄧㄢㄅㄛ":"年伯",
"ㄋㄧㄢㄅㄞ":"念白/唸白",
"ㄋㄧㄢㄅㄟ":"年輩",
"ㄋㄧㄢㄅㄠ":"年報",
"ㄋㄧㄢㄅㄢ":"年班",
"ㄋㄧㄢㄅㄧ":"拈筆",
"ㄋㄧㄢㄅㄨ":"粘補",
"ㄋㄧㄢㄆㄢ":"碾盤",
"ㄋㄧㄢㄆㄨ":"年譜",
"ㄋㄧㄢㄇㄛ":"黏膜",
"ㄋㄧㄢㄇㄞ":"年邁",
"ㄋㄧㄢㄇㄠ":"年貌",
"ㄋㄧㄢㄇㄧ":"碾米",
"ㄋㄧㄢㄈㄛ":"念佛",
"ㄋㄧㄢㄈㄟ":"年費/捻匪",
"ㄋㄧㄢㄈㄢ":"年飯/捻泛",
"ㄋㄧㄢㄈㄣ":"年分",
"ㄋㄧㄢㄈㄤ":"碾房/碾坊",
"ㄋㄧㄢㄈㄨ":"黏附/輦夫",
"ㄋㄧㄢㄉㄞ":"年代",
"ㄋㄧㄢㄉㄠ":"輦道/念叨",
"ㄋㄧㄢㄉㄥ":"年登",
"ㄋㄧㄢㄉㄧ":"年底/年弟",
"ㄋㄧㄢㄉㄨ":"年度/黏度",
"ㄋㄧㄢㄊㄞ":"年臺",
"ㄋㄧㄢㄊㄡ":"年頭/念頭",
"ㄋㄧㄢㄊㄨ":"黏土",
"ㄋㄧㄢㄋㄟ":"年內",
"ㄋㄧㄢㄌㄚ":"年臘",
"ㄋㄧㄢㄌㄞ":"年來/拈來",
"ㄋㄧㄢㄌㄠ":"年老/年勞",
"ㄋㄧㄢㄌㄧ":"念力/年例",
"ㄋㄧㄢㄌㄨ":"輦路",
"ㄋㄧㄢㄌㄩ":"念慮",
"ㄋㄧㄢㄍㄜ":"念歌",
"ㄋㄧㄢㄍㄠ":"年糕/年高",
"ㄋㄧㄢㄍㄢ":"蔫甘/粘竿",
"ㄋㄧㄢㄍㄥ":"年庚",
"ㄋㄧㄢㄍㄨ":"年穀/輦轂",
"ㄋㄧㄢㄎㄢ":"年刊",
"ㄋㄧㄢㄏㄜ":"黏合",
"ㄋㄧㄢㄏㄠ":"年號",
"ㄋㄧㄢㄏㄨ":"黏糊",
"ㄋㄧㄢㄐㄧ":"年紀/年級",
"ㄋㄧㄢㄒㄧ":"年息/輦席",
"ㄋㄧㄢㄓㄜ":"粘著",
"ㄋㄧㄢㄓㄡ":"念咒/唸咒",
"ㄋㄧㄢㄓㄣ":"捻針",
"ㄋㄧㄢㄓㄤ":"年長",
"ㄋㄧㄢㄓㄨ":"黏住/念珠",
"ㄋㄧㄢㄔㄡ":"黏稠/粘稠",
"ㄋㄧㄢㄔㄥ":"年成/年程",
"ㄋㄧㄢㄔㄨ":"年初",
"ㄋㄧㄢㄕㄠ":"年少",
"ㄋㄧㄢㄕㄨ":"念書/年數",
"ㄋㄧㄢㄖㄣ":"黏人",
"ㄋㄧㄢㄗㄗ":"念孜孜",
"ㄋㄧㄢㄗㄞ":"輦載",
"ㄋㄧㄢㄗㄡ":"攆走",
"ㄋㄧㄢㄘㄞ":"年菜",
"ㄋㄧㄢㄧㄝ":"黏液/年夜",
"ㄋㄧㄢㄧㄡ":"年幼/黏油",
"ㄋㄧㄢㄧㄤ":"念秧",
"ㄋㄧㄢㄨㄟ":"年尾",
"ㄋㄧㄢㄨㄢ":"年晚",
"ㄋㄧㄢㄩㄝ":"年月",
"ㄋㄧㄢㄩㄣ":"年運",
"ㄋㄧㄣㄋㄚ":"您納/您哪",
"ㄋㄧㄣㄌㄠ":"您老",
"ㄋㄧㄤㄆㄛ":"娘婆",
"ㄋㄧㄤㄇㄧ":"釀蜜",
"ㄋㄧㄤㄉㄜ":"娘的",
"ㄋㄧㄤㄊㄞ":"娘胎",
"ㄋㄧㄤㄑㄧ":"釀器",
"ㄋㄧㄤㄔㄥ":"釀成",
"ㄋㄧㄤㄗㄠ":"釀造",
"ㄋㄧㄥㄇㄡ":"凝眸",
"ㄋㄧㄥㄇㄥ":"檸檬",
"ㄋㄧㄥㄇㄧ":"寧謐",
"ㄋㄧㄥㄇㄨ":"凝目",
"ㄋㄧㄥㄈㄛ":"佞佛",
"ㄋㄧㄥㄉㄧ":"凝睇",
"ㄋㄧㄥㄊㄞ":"凝態",
"ㄋㄧㄥㄋㄞ":"寧奈",
"ㄋㄧㄥㄋㄠ":"濘淖",
"ㄋㄧㄥㄌㄜ":"擰了",
"ㄋㄧㄥㄌㄨ":"佞祿",
"ㄋㄧㄥㄌㄩ":"凝慮",
"ㄋㄧㄥㄍㄜ":"寧個",
"ㄋㄧㄥㄍㄢ":"擰乾",
"ㄋㄧㄥㄍㄨ":"凝固",
"ㄋㄧㄥㄎㄜ":"寧可",
"ㄋㄧㄥㄎㄞ":"擰開",
"ㄋㄧㄥㄎㄡ":"佞口",
"ㄋㄧㄥㄏㄢ":"凝寒",
"ㄋㄧㄥㄏㄨ":"凝冱",
"ㄋㄧㄥㄐㄧ":"凝集",
"ㄋㄧㄥㄐㄩ":"凝聚",
"ㄋㄧㄥㄒㄧ":"寧息",
"ㄋㄧㄥㄒㄩ":"寧許",
"ㄋㄧㄥㄓㄨ":"凝佇",
"ㄋㄧㄥㄔㄡ":"寧綢",
"ㄋㄧㄥㄔㄣ":"佞臣",
"ㄋㄧㄥㄕㄜ":"佞舌",
"ㄋㄧㄥㄕㄣ":"凝神/寧神",
"ㄋㄧㄥㄖㄢ":"凝然",
"ㄋㄧㄥㄖㄣ":"佞人",
"ㄋㄧㄥㄖㄨ":"凝乳",
"ㄋㄧㄥㄧㄝ":"凝咽",
"ㄋㄧㄥㄧㄢ":"佞言",
"ㄋㄧㄥㄨㄤ":"凝望/凝網",
"ㄋㄧㄥㄩㄢ":"寧願",
"ㄋㄨㄉㄨㄣ":"駑鈍",
"ㄋㄨㄍㄨㄥ":"奴工/弩弓",
"ㄋㄨㄏㄨㄚ":"奴化",
"ㄋㄨㄏㄨㄛ":"怒火",
"ㄋㄨㄐㄧㄚ":"奴家",
"ㄋㄨㄐㄧㄢ":"弩箭",
"ㄋㄨㄐㄧㄤ":"怒江",
"ㄋㄨㄑㄧㄢ":"駑鉛",
"ㄋㄨㄒㄧㄚ":"駑下",
"ㄋㄨㄒㄧㄥ":"奴性",
"ㄋㄨㄓㄨㄛ":"努著",
"ㄋㄨㄔㄢㄗ":"奴產子",
"ㄋㄨㄗㄨㄟ":"努嘴",
"ㄋㄨㄘㄨㄥ":"奴從",
"ㄋㄨㄛㄅㄨ":"那步",
"ㄋㄨㄛㄇㄧ":"糯米",
"ㄋㄨㄛㄈㄨ":"懦夫",
"ㄋㄨㄛㄉㄠ":"糯稻",
"ㄋㄨㄛㄊㄞ":"挪抬",
"ㄋㄨㄛㄎㄞ":"挪開",
"ㄋㄨㄛㄏㄢ":"搦翰",
"ㄋㄨㄛㄓㄚ":"哪吒",
"ㄋㄨㄛㄓㄢ":"搦戰",
"ㄋㄨㄛㄕㄣ":"儺神",
"ㄋㄨㄛㄘㄡ":"挪湊",
"ㄋㄨㄛㄧㄚ":"諾亞",
"ㄋㄨㄛㄧㄢ":"諾言",
"ㄋㄨㄛㄩㄥ":"挪用",
"ㄋㄨㄢㄆㄢ":"餪盤",
"ㄋㄨㄢㄇㄠ":"暖帽",
"ㄋㄨㄢㄈㄤ":"暖房",
"ㄋㄨㄢㄈㄥ":"暖風/暖鋒",
"ㄋㄨㄢㄉㄢ":"暖蛋",
"ㄋㄨㄢㄋㄩ":"暖女/餪女",
"ㄋㄨㄢㄌㄨ":"暖爐",
"ㄋㄨㄢㄍㄜ":"暖閣",
"ㄋㄨㄢㄎㄤ":"暖炕",
"ㄋㄨㄢㄏㄨ":"暖壺",
"ㄋㄨㄢㄑㄧ":"暖氣",
"ㄋㄨㄢㄔㄣ":"暖塵",
"ㄋㄨㄢㄔㄤ":"暖廠",
"ㄋㄨㄢㄕㄡ":"暖壽",
"ㄋㄨㄢㄕㄣ":"暖身",
"ㄋㄨㄢㄧㄢ":"暖眼",
"ㄋㄨㄥㄅㄠ":"農保/膿包",
"ㄋㄨㄥㄅㄧ":"弄筆",
"ㄋㄨㄥㄆㄛ":"弄破",
"ㄋㄨㄥㄆㄥ":"弄甏",
"ㄋㄨㄥㄆㄨ":"農圃",
"ㄋㄨㄥㄇㄛ":"濃抹/濃墨",
"ㄋㄨㄥㄇㄟ":"濃眉",
"ㄋㄨㄥㄇㄤ":"農忙",
"ㄋㄨㄥㄇㄧ":"濃密",
"ㄋㄨㄥㄇㄨ":"農牧",
"ㄋㄨㄥㄈㄚ":"弄法",
"ㄋㄨㄥㄈㄢ":"弄飯",
"ㄋㄨㄥㄈㄨ":"農夫/農婦",
"ㄋㄨㄥㄉㄠ":"弄倒/弄刀",
"ㄋㄨㄥㄉㄢ":"濃淡",
"ㄋㄨㄥㄉㄧ":"農地",
"ㄋㄨㄥㄉㄨ":"濃度",
"ㄋㄨㄥㄊㄠ":"弄濤",
"ㄋㄨㄥㄊㄤ":"弄堂/濃湯",
"ㄋㄨㄥㄋㄨ":"農奴",
"ㄋㄨㄥㄌㄧ":"農曆",
"ㄋㄨㄥㄍㄜ":"農歌",
"ㄋㄨㄥㄍㄥ":"農耕",
"ㄋㄨㄥㄎㄜ":"農科",
"ㄋㄨㄥㄎㄡ":"弄口",
"ㄋㄨㄥㄎㄣ":"農墾",
"ㄋㄨㄥㄏㄟ":"濃黑",
"ㄋㄨㄥㄏㄠ":"弄好",
"ㄋㄨㄥㄏㄡ":"濃厚",
"ㄋㄨㄥㄏㄢ":"弄翰",
"ㄋㄨㄥㄏㄨ":"農戶",
"ㄋㄨㄥㄐㄧ":"農機/農技",
"ㄋㄨㄥㄐㄩ":"弄局/農具",
"ㄋㄨㄥㄒㄧ":"農隙",
"ㄋㄨㄥㄓㄞ":"農宅",
"ㄋㄨㄥㄓㄢ":"農戰",
"ㄋㄨㄥㄓㄤ":"弄獐/弄璋",
"ㄋㄨㄥㄓㄥ":"農政",
"ㄋㄨㄥㄔㄚ":"濃茶/弄岔",
"ㄋㄨㄥㄔㄠ":"弄潮",
"ㄋㄨㄥㄔㄢ":"農產",
"ㄋㄨㄥㄔㄣ":"弄臣",
"ㄋㄨㄥㄔㄤ":"農場",
"ㄋㄨㄥㄕㄜ":"農舍",
"ㄋㄨㄥㄕㄣ":"農神",
"ㄋㄨㄥㄖㄣ":"農人/儂人",
"ㄋㄨㄥㄗㄤ":"弄髒",
"ㄋㄨㄥㄙㄤ":"農桑",
"ㄋㄨㄥㄧㄝ":"農業",
"ㄋㄨㄥㄧㄠ":"農藥",
"ㄋㄨㄥㄧㄡ":"農友/弄優",
"ㄋㄨㄥㄧㄢ":"濃煙/濃豔",
"ㄋㄨㄥㄧㄣ":"濃蔭",
"ㄋㄨㄥㄧㄥ":"弄影",
"ㄋㄨㄥㄨㄚ":"弄瓦",
"ㄋㄨㄥㄨㄢ":"弄丸",
"ㄋㄨㄥㄨㄣ":"弄文",
"ㄋㄨㄥㄩㄝ":"農月/弄月",
"ㄋㄨㄥㄩㄣ":"農運",
"ㄋㄩㄅㄛㄕ":"女博士",
"ㄋㄩㄅㄧㄥ":"女兵",
"ㄋㄩㄉㄧㄗ":"女弟子",
"ㄋㄩㄊㄨㄥ":"女童",
"ㄋㄩㄋㄧㄤ":"女娘",
"ㄋㄩㄌㄠㄗ":"女落子",
"ㄋㄩㄌㄧㄡ":"女流",
"ㄋㄩㄌㄧㄥ":"女伶",
"ㄋㄩㄌㄨㄛ":"女蘿",
"ㄋㄩㄍㄨㄛ":"女國",
"ㄋㄩㄍㄨㄢ":"女官/女冠",
"ㄋㄩㄍㄨㄥ":"女紅/女公",
"ㄋㄩㄏㄞㄗ":"女孩子",
"ㄋㄩㄏㄨㄚ":"女花",
"ㄋㄩㄏㄨㄛ":"女禍",
"ㄋㄩㄏㄨㄤ":"女皇",
"ㄋㄩㄐㄧㄚ":"女家",
"ㄋㄩㄐㄧㄝ":"女誡",
"ㄋㄩㄐㄧㄠ":"女轎",
"ㄋㄩㄐㄧㄡ":"女酒",
"ㄋㄩㄐㄧㄢ":"女監",
"ㄋㄩㄐㄧㄤ":"女將",
"ㄋㄩㄐㄧㄥ":"女警",
"ㄋㄩㄐㄩㄝ":"女角",
"ㄋㄩㄐㄩㄢ":"女眷",
"ㄋㄩㄐㄩㄣ":"女君",
"ㄋㄩㄑㄧㄢ":"女錢",
"ㄋㄩㄑㄧㄤ":"女牆",
"ㄋㄩㄑㄩㄢ":"女權",
"ㄋㄩㄒㄧㄝ":"女鞋",
"ㄋㄩㄒㄧㄠ":"女校",
"ㄋㄩㄒㄧㄡ":"女宿",
"ㄋㄩㄒㄧㄢ":"女先",
"ㄋㄩㄒㄧㄥ":"女性/女星",
"ㄋㄩㄒㄩㄝ":"女學",
"ㄋㄩㄒㄩㄥ":"女兄",
"ㄋㄩㄓㄨㄤ":"女裝",
"ㄋㄩㄓㄨㄥ":"女中",
"ㄋㄩㄔㄨㄤ":"女床",
"ㄋㄩㄖㄨㄥ":"女戎",
"ㄋㄩㄗㄨㄥ":"女宗",
"ㄋㄩㄙㄕㄨ":"女四書",
"ㄋㄩㄙㄨㄛ":"恧縮",
"ㄋㄩㄙㄨㄣ":"女孫",
"ㄋㄩㄝㄉㄞ":"虐待",
"ㄋㄩㄝㄌㄤ":"謔浪",
"ㄋㄩㄝㄐㄧ":"虐疾/瘧疾",
"ㄋㄩㄝㄓㄥ":"虐政",
"ㄋㄩㄝㄔㄥ":"謔稱",
"ㄋㄩㄝㄕㄚ":"虐殺",
"ㄋㄩㄝㄨㄣ":"瘧蚊",
"ㄋㄩㄦㄊㄞ":"女兒態",
"ㄋㄩㄦㄑㄧ":"女兒氣",
"ㄋㄩㄦㄔㄚ":"女兒茶",
"ㄋㄩㄦㄕㄣ":"女兒身",
"ㄌㄚㄆㄧㄠ":"拉票",
"ㄌㄚㄆㄧㄥ":"拉平",
"ㄌㄚㄇㄧㄡ":"剌謬",
"ㄌㄚㄇㄧㄢ":"拉麵",
"ㄌㄚㄉㄨㄗ":"拉肚子",
"ㄌㄚㄊㄡㄦ":"蠟頭兒",
"ㄌㄚㄌㄧㄠ":"蠟療",
"ㄌㄚㄌㄧㄢ":"拉鍊/拉練",
"ㄌㄚㄌㄨㄥ":"拉攏/拉弄",
"ㄌㄚㄍㄢㄗ":"拉桿子",
"ㄌㄚㄍㄨㄛ":"蠟果",
"ㄌㄚㄎㄡㄗ":"剌口子",
"ㄌㄚㄎㄨㄥ":"落空/拉孔",
"ㄌㄚㄏㄜㄗ":"拉合子",
"ㄌㄚㄏㄨㄚ":"落話/蠟花",
"ㄌㄚㄏㄨㄛ":"辣貨",
"ㄌㄚㄏㄨㄢ":"拉環",
"ㄌㄚㄏㄨㄤ":"蠟黃",
"ㄌㄚㄏㄨㄩ":"拉祜語",
"ㄌㄚㄐㄧㄚ":"拉架",
"ㄌㄚㄐㄧㄠ":"辣椒/拉腳",
"ㄌㄚㄐㄧㄡ":"臘酒",
"ㄌㄚㄐㄧㄢ":"蠟箋",
"ㄌㄚㄐㄧㄣ":"拉近/臘盡",
"ㄌㄚㄐㄧㄤ":"辣醬",
"ㄌㄚㄑㄧㄢ":"拉牽/拉縴",
"ㄌㄚㄑㄧㄣ":"拉親",
"ㄌㄚㄑㄧㄤ":"拉槍",
"ㄌㄚㄑㄩㄢ":"拉勸",
"ㄌㄚㄒㄧㄚ":"落下/拉夏",
"ㄌㄚㄒㄧㄡ":"拉朽",
"ㄌㄚㄒㄧㄢ":"拉線/拉閑",
"ㄌㄚㄒㄧㄤ":"蠟像",
"ㄌㄚㄔㄤㄗ":"拉場子",
"ㄌㄚㄔㄨㄤ":"拉床",
"ㄌㄚㄔㄨㄥ":"蠟蟲",
"ㄌㄚㄕㄥㄧ":"拉生意",
"ㄌㄚㄗㄨㄟ":"喇嘴/蠟嘴",
"ㄌㄚㄙㄨㄛ":"拉索/拉鎖",
"ㄌㄚㄞㄇㄞ":"拉愛麥",
"ㄌㄚㄧㄅㄚ":"拉一把",
"ㄌㄚㄧㄥㄕ":"拉硬屎",
"ㄌㄜㄅㄧㄥ":"勒兵",
"ㄌㄜㄇㄧㄥ":"勒銘",
"ㄌㄜㄊㄧㄢ":"樂天",
"ㄌㄜㄊㄧㄥ":"勒停",
"ㄌㄜㄊㄨㄛ":"樂託",
"ㄌㄜㄌㄧㄥ":"勒令",
"ㄌㄜㄍㄨㄛ":"樂國",
"ㄌㄜㄍㄨㄢ":"樂觀",
"ㄌㄜㄎㄜㄙ":"勒克司",
"ㄌㄜㄏㄨㄛ":"樂禍",
"ㄌㄜㄐㄧㄝ":"勒借/勒戒",
"ㄌㄜㄐㄧㄠ":"勒交",
"ㄌㄜㄐㄧㄣ":"肋筋",
"ㄌㄜㄐㄧㄥ":"樂境",
"ㄌㄜㄐㄩㄢ":"樂捐",
"ㄌㄜㄒㄧㄡ":"勒休",
"ㄌㄜㄒㄧㄢ":"勒限/勒銜",
"ㄌㄜㄓㄨㄤ":"勒狀",
"ㄌㄜㄙㄨㄛ":"勒索",
"ㄌㄜㄙㄨㄟ":"樂歲",
"ㄌㄞㄅㄧㄣ":"來賓",
"ㄌㄞㄆㄧㄣ":"賚品/騋牝",
"ㄌㄞㄈㄨㄖ":"來復日",
"ㄌㄞㄉㄧㄢ":"來電",
"ㄌㄞㄋㄧㄢ":"來年",
"ㄌㄞㄌㄧㄣ":"來臨",
"ㄌㄞㄌㄨㄥ":"來龍",
"ㄌㄞㄍㄨㄚ":"癩瓜",
"ㄌㄞㄍㄨㄛ":"來果",
"ㄌㄞㄍㄨㄟ":"來歸",
"ㄌㄞㄎㄨㄤ":"來貺",
"ㄌㄞㄏㄨㄟ":"來回",
"ㄌㄞㄏㄨㄣ":"賴婚",
"ㄌㄞㄐㄧㄢ":"來件",
"ㄌㄞㄐㄧㄣ":"來今/來勁",
"ㄌㄞㄐㄧㄤ":"賚獎",
"ㄌㄞㄑㄧㄥ":"來情",
"ㄌㄞㄒㄧㄣ":"來信",
"ㄌㄞㄒㄧㄤ":"來享",
"ㄌㄞㄒㄩㄝ":"來學/賴學",
"ㄌㄞㄔㄨㄤ":"賴床",
"ㄌㄞㄙㄨㄟ":"來歲",
"ㄌㄞㄙㄨㄣ":"來孫/徠孫",
"ㄌㄟㄅㄧㄝ":"類別",
"ㄌㄟㄅㄧㄢ":"類編/雷鞭",
"ㄌㄟㄅㄧㄥ":"羸兵",
"ㄌㄟㄆㄧㄢ":"類篇",
"ㄌㄟㄇㄧㄥ":"類名/雷鳴",
"ㄌㄟㄉㄧㄝ":"類疊",
"ㄌㄟㄉㄧㄢ":"雷電/淚點",
"ㄌㄟㄉㄨㄟ":"雷堆/纍堆",
"ㄌㄟㄉㄨㄣ":"羸頓",
"ㄌㄟㄉㄨㄥ":"雷動",
"ㄌㄟㄊㄧㄠ":"肋條",
"ㄌㄟㄊㄧㄥ":"雷霆",
"ㄌㄟㄊㄨㄟ":"類推",
"ㄌㄟㄊㄨㄥ":"類同/雷同",
"ㄌㄟㄋㄧㄠ":"雷鳥",
"ㄌㄟㄋㄧㄢ":"累年",
"ㄌㄟㄌㄧㄝ":"羸劣",
"ㄌㄟㄌㄨㄛ":"磊落/磊砢",
"ㄌㄟㄌㄨㄢ":"累卵",
"ㄌㄟㄌㄨㄥ":"雷龍",
"ㄌㄟㄍㄨㄢ":"淚管/雷管",
"ㄌㄟㄍㄨㄤ":"淚光",
"ㄌㄟㄍㄨㄥ":"雷公/雷汞",
"ㄌㄟㄎㄜㄦ":"壘窠兒",
"ㄌㄟㄎㄨㄚ":"累垮",
"ㄌㄟㄎㄨㄞ":"壘塊/磊塊",
"ㄌㄟㄎㄨㄟ":"礧磈",
"ㄌㄟㄎㄨㄥ":"礨空",
"ㄌㄟㄏㄨㄚ":"類化/淚花",
"ㄌㄟㄏㄨㄛ":"雷火",
"ㄌㄟㄏㄨㄞ":"累壞",
"ㄌㄟㄐㄧㄚ":"擂家/累加",
"ㄌㄟㄐㄧㄝ":"纇節",
"ㄌㄟㄐㄧㄣ":"累進/勒緊",
"ㄌㄟㄐㄧㄥ":"類經",
"ㄌㄟㄑㄧㄡ":"壘球/纍囚",
"ㄌㄟㄑㄧㄢ":"累遷/壘塹",
"ㄌㄟㄑㄧㄣ":"雷琴",
"ㄌㄟㄑㄧㄤ":"累牆",
"ㄌㄟㄒㄧㄝ":"累榭/累紲",
"ㄌㄟㄒㄧㄢ":"淚腺",
"ㄌㄟㄒㄧㄣ":"累心",
"ㄌㄟㄒㄧㄥ":"類型",
"ㄌㄟㄓㄣㄩ":"雷陣雨",
"ㄌㄟㄓㄨㄟ":"累贅/累墜",
"ㄌㄟㄓㄨㄤ":"淚妝",
"ㄌㄟㄓㄨㄥ":"累重",
"ㄌㄟㄔㄨㄟ":"擂槌",
"ㄌㄟㄔㄨㄥ":"累重",
"ㄌㄟㄕㄨㄟ":"淚水",
"ㄌㄟㄖㄨㄛ":"羸弱",
"ㄌㄟㄖㄨㄥ":"淚容",
"ㄌㄟㄗㄥㄗ":"累增字",
"ㄌㄟㄩㄅㄠ":"雷雨胞",
"ㄌㄠㄅㄚㄕ":"老把式",
"ㄌㄠㄅㄞㄕ":"落白事",
"ㄌㄠㄅㄤㄗ":"老梆子",
"ㄌㄠㄅㄧㄠ":"老表",
"ㄌㄠㄅㄧㄥ":"老病/老兵",
"ㄌㄠㄅㄨㄕ":"落不是",
"ㄌㄠㄅㄨㄙ":"老不死",
"ㄌㄠㄆㄛㄗ":"老婆子",
"ㄌㄠㄆㄛㄦ":"老婆兒",
"ㄌㄠㄆㄞㄗ":"老牌子",
"ㄌㄠㄇㄚㄗ":"老媽子",
"ㄌㄠㄇㄠㄗ":"老毛子",
"ㄌㄠㄇㄧㄢ":"撈麵",
"ㄌㄠㄇㄧㄣ":"勞民/勞民",
"ㄌㄠㄇㄧㄥ":"老命",
"ㄌㄠㄈㄚㄗ":"老法子",
"ㄌㄠㄈㄟㄨ":"老廢物",
"ㄌㄠㄈㄤㄗ":"老方子",
"ㄌㄠㄈㄨㄗ":"老夫子",
"ㄌㄠㄉㄚㄦ":"老大兒",
"ㄌㄠㄉㄧㄗ":"老底子",
"ㄌㄠㄉㄧㄝ":"老爹",
"ㄌㄠㄉㄧㄠ":"老調",
"ㄌㄠㄉㄧㄢ":"老店",
"ㄌㄠㄉㄧㄥ":"牢定",
"ㄌㄠㄉㄨㄣ":"勞頓",
"ㄌㄠㄉㄨㄥ":"勞動/勞動",
"ㄌㄠㄊㄡㄗ":"老頭子",
"ㄌㄠㄊㄡㄦ":"老頭兒",
"ㄌㄠㄊㄧㄝ":"烙鐵",
"ㄌㄠㄊㄧㄢ":"老天",
"ㄌㄠㄋㄧㄠ":"老鳥",
"ㄌㄠㄋㄧㄢ":"老年",
"ㄌㄠㄋㄧㄤ":"老娘/老娘",
"ㄌㄠㄋㄨㄥ":"老農/勞農",
"ㄌㄠㄌㄞㄗ":"老萊子",
"ㄌㄠㄌㄤㄢ":"老郎庵",
"ㄌㄠㄌㄧㄡ":"老溜",
"ㄌㄠㄌㄧㄢ":"老練/老臉",
"ㄌㄠㄌㄧㄣ":"老林",
"ㄌㄠㄌㄧㄥ":"老齡/撈鈴",
"ㄌㄠㄌㄨㄛ":"老落/勞落",
"ㄌㄠㄌㄨㄥ":"勞籠/牢籠",
"ㄌㄠㄍㄨㄚ":"老鴰",
"ㄌㄠㄍㄨㄢ":"老官",
"ㄌㄠㄍㄨㄥ":"老公/老公",
"ㄌㄠㄎㄨㄣ":"勞困",
"ㄌㄠㄏㄜㄦ":"老合兒",
"ㄌㄠㄏㄤㄨ":"老行伍",
"ㄌㄠㄏㄨㄚ":"老化/老話",
"ㄌㄠㄏㄨㄛ":"老貨",
"ㄌㄠㄐㄧㄚ":"老家/落架",
"ㄌㄠㄐㄧㄝ":"老街/勞結",
"ㄌㄠㄐㄧㄠ":"老腳",
"ㄌㄠㄐㄧㄡ":"老酒/老舊",
"ㄌㄠㄐㄧㄢ":"老繭",
"ㄌㄠㄐㄧㄣ":"老緊/勞金",
"ㄌㄠㄐㄧㄤ":"老將",
"ㄌㄠㄐㄧㄥ":"老景/老境",
"ㄌㄠㄐㄩㄢ":"勞倦",
"ㄌㄠㄐㄩㄣ":"老君/勞軍",
"ㄌㄠㄑㄧㄢ":"老千/老慳",
"ㄌㄠㄑㄧㄣ":"老親",
"ㄌㄠㄑㄧㄤ":"老槍",
"ㄌㄠㄑㄩㄝ":"勞怯",
"ㄌㄠㄑㄩㄢ":"老拳",
"ㄌㄠㄒㄧㄠ":"老小",
"ㄌㄠㄒㄧㄡ":"老朽",
"ㄌㄠㄒㄧㄢ":"老先/老弦",
"ㄌㄠㄒㄧㄣ":"勞心",
"ㄌㄠㄒㄧㄤ":"老相/老鄉",
"ㄌㄠㄒㄧㄥ":"勞形",
"ㄌㄠㄒㄩㄝ":"老學",
"ㄌㄠㄒㄩㄥ":"老兄",
"ㄌㄠㄓㄨㄚ":"老撾",
"ㄌㄠㄓㄨㄛ":"老拙",
"ㄌㄠㄓㄨㄤ":"老莊",
"ㄌㄠㄔㄨㄥ":"癆蟲",
"ㄌㄠㄕㄈㄨ":"老師父",
"ㄌㄠㄕㄊㄡ":"老實頭",
"ㄌㄠㄕㄖㄣ":"老實人",
"ㄌㄠㄕㄥㄦ":"老生兒",
"ㄌㄠㄕㄧㄢ":"老視眼",
"ㄌㄠㄕㄨㄢ":"牢拴",
"ㄌㄠㄖㄨㄛ":"老弱",
"ㄌㄠㄗㄏㄠ":"老字號",
"ㄌㄠㄗㄨㄛ":"落作/勞作",
"ㄌㄠㄗㄨㄟ":"撈嘴",
"ㄌㄠㄗㄨㄣ":"老尊",
"ㄌㄠㄗㄨㄥ":"老總",
"ㄌㄠㄗㄨㄦ":"老祖兒",
"ㄌㄠㄘㄨㄟ":"勞瘁",
"ㄌㄠㄘㄨㄥ":"老蔥",
"ㄌㄠㄚㄇㄚ":"老阿媽",
"ㄌㄠㄚㄉㄞ":"老阿呆",
"ㄌㄠㄚㄓㄜ":"老阿者",
"ㄌㄠㄧㄅㄚ":"撈一把",
"ㄌㄠㄧㄝㄗ":"老爺子",
"ㄌㄠㄧㄡㄗ":"老油子",
"ㄌㄠㄧㄢㄨ":"老厭物",
"ㄌㄠㄧㄤㄗ":"老樣子",
"ㄌㄠㄧㄥㄦ":"老營兒",
"ㄌㄡㄅㄠㄦ":"摟包兒",
"ㄌㄡㄅㄧㄥ":"鏤冰",
"ㄌㄡㄇㄧㄠ":"露苗",
"ㄌㄡㄇㄧㄢ":"露面",
"ㄌㄡㄉㄧㄠ":"漏掉",
"ㄌㄡㄉㄧㄢ":"漏電",
"ㄌㄡㄉㄧㄥ":"樓頂",
"ㄌㄡㄉㄨㄥ":"漏洞",
"ㄌㄡㄊㄧㄢ":"漏天",
"ㄌㄡㄊㄨㄛ":"漏脫",
"ㄌㄡㄌㄧㄝ":"漏列/陋劣",
"ㄌㄡㄌㄧㄢ":"露臉",
"ㄌㄡㄌㄨㄛ":"嘍囉/漏落",
"ㄌㄡㄍㄡㄗ":"摟溝子",
"ㄌㄡㄍㄨㄛ":"螻蟈",
"ㄌㄡㄍㄨㄟ":"陋規",
"ㄌㄡㄍㄨㄢ":"漏管",
"ㄌㄡㄍㄨㄤ":"漏光",
"ㄌㄡㄎㄨㄤ":"簍筐",
"ㄌㄡㄎㄨㄥ":"露空/鏤空",
"ㄌㄡㄏㄨㄚ":"鏤花",
"ㄌㄡㄏㄨㄛ":"摟貨",
"ㄌㄡㄏㄨㄢ":"樓緩",
"ㄌㄡㄐㄧㄢ":"陋見/漏箭",
"ㄌㄡㄐㄧㄣ":"漏盡",
"ㄌㄡㄐㄧㄤ":"婁江",
"ㄌㄡㄐㄧㄥ":"漏精",
"ㄌㄡㄑㄧㄢ":"摟錢",
"ㄌㄡㄑㄩㄝ":"露怯",
"ㄌㄡㄒㄧㄚ":"樓下",
"ㄌㄡㄒㄧㄝ":"漏洩/漏泄",
"ㄌㄡㄒㄧㄤ":"露相/陋巷",
"ㄌㄡㄒㄧㄥ":"僂行/露形",
"ㄌㄡㄔㄨㄢ":"樓船",
"ㄌㄡㄔㄨㄤ":"樓窗/漏窗",
"ㄌㄡㄕㄉㄜ":"婁師德",
"ㄌㄡㄕㄨㄟ":"漏稅/漏水",
"ㄌㄡㄧㄕㄡ":"露一手",
"ㄌㄡㄧㄕㄤ":"摟衣裳",
"ㄌㄡㄨㄤㄩ":"漏網魚",
"ㄌㄢㄅㄠㄕ":"藍寶石",
"ㄌㄢㄅㄧㄥ":"懶病",
"ㄌㄢㄆㄧㄢ":"爛片",
"ㄌㄢㄉㄧㄠ":"藍調/濫調",
"ㄌㄢㄉㄧㄢ":"藍靛",
"ㄌㄢㄉㄨㄛ":"懶惰",
"ㄌㄢㄉㄨㄟ":"籃隊",
"ㄌㄢㄊㄢㄗ":"爛攤子",
"ㄌㄢㄊㄧㄢ":"藍天/藍田",
"ㄌㄢㄊㄧㄥ":"蘭亭",
"ㄌㄢㄌㄧㄠ":"爛料",
"ㄌㄢㄌㄧㄥ":"藍領/藍翎",
"ㄌㄢㄍㄡㄕ":"攬狗屎",
"ㄌㄢㄍㄨㄟ":"蘭桂/蘭閨",
"ㄌㄢㄍㄨㄢ":"藍關/攔關",
"ㄌㄢㄍㄨㄥ":"攬工",
"ㄌㄢㄎㄨㄤ":"籃框",
"ㄌㄢㄏㄨㄗ":"藍鬍子",
"ㄌㄢㄏㄨㄚ":"蘭花/爛化",
"ㄌㄢㄏㄨㄛ":"攬貨/濫貨",
"ㄌㄢㄏㄨㄞ":"蘭槐",
"ㄌㄢㄐㄧㄚ":"懶架/欄架",
"ㄌㄢㄐㄧㄝ":"攔截/闌街",
"ㄌㄢㄐㄧㄠ":"濫交/蘭交",
"ㄌㄢㄐㄧㄣ":"蘭襟/蘭燼",
"ㄌㄢㄐㄧㄤ":"爛醬",
"ㄌㄢㄐㄧㄥ":"攬鏡/藍鯨",
"ㄌㄢㄑㄧㄠ":"藍橋",
"ㄌㄢㄑㄧㄡ":"籃球/蘭秋",
"ㄌㄢㄑㄧㄥ":"濫情/藍青",
"ㄌㄢㄑㄩㄢ":"攬權",
"ㄌㄢㄑㄩㄣ":"襴裙",
"ㄌㄢㄒㄧㄚ":"攔下",
"ㄌㄢㄒㄧㄝ":"籃協",
"ㄌㄢㄒㄧㄡ":"攬秀/蘭臭",
"ㄌㄢㄒㄧㄢ":"纜線",
"ㄌㄢㄒㄧㄤ":"蘭香",
"ㄌㄢㄒㄧㄥ":"濫行/濫刑",
"ㄌㄢㄒㄩㄣ":"蘭訊",
"ㄌㄢㄓㄓㄕ":"蘭芷之室",
"ㄌㄢㄓㄡㄕ":"蘭州市",
"ㄌㄢㄔㄤㄗ":"爛腸子",
"ㄌㄢㄔㄨㄥ":"懶蟲",
"ㄌㄢㄕㄨㄣ":"欄楯",
"ㄌㄢㄖㄨㄟ":"蘭蕊",
"ㄌㄢㄗㄨㄟ":"爛醉",
"ㄌㄢㄗㄨㄥ":"攬總/攔縱",
"ㄌㄢㄙㄨㄛ":"婪索",
"ㄌㄢㄙㄨㄣ":"蘭蓀",
"ㄌㄤㄅㄚㄦ":"朗巴兒",
"ㄌㄤㄅㄨㄕ":"狼卜食",
"ㄌㄤㄇㄧㄠ":"廊廟",
"ㄌㄤㄉㄤㄗ":"浪蕩子",
"ㄌㄤㄍㄨㄢ":"郎官",
"ㄌㄤㄏㄨㄚ":"浪花",
"ㄌㄤㄏㄨㄢ":"狼獾",
"ㄌㄤㄐㄧㄡ":"郎舅",
"ㄌㄤㄐㄧㄢ":"朗鑒",
"ㄌㄤㄐㄩㄣ":"郎君",
"ㄌㄤㄑㄧㄩ":"狼鰭魚",
"ㄌㄤㄑㄩㄢ":"狼犬",
"ㄌㄤㄒㄧㄣ":"狼心",
"ㄌㄤㄓㄨㄥ":"郎中",
"ㄌㄤㄔㄨㄟ":"榔搥",
"ㄌㄤㄔㄨㄢ":"浪船",
"ㄌㄤㄔㄨㄤ":"狼瘡",
"ㄌㄤㄗㄞㄗ":"狼崽子",
"ㄌㄤㄙㄨㄥ":"朗誦",
"ㄌㄤㄦㄇㄣ":"浪兒門",
"ㄌㄤㄧㄤㄦ":"浪樣兒",
"ㄌㄥㄅㄧㄠ":"冷鼻凹",
"ㄌㄥㄇㄧㄢ":"冷面/冷麵",
"ㄌㄥㄉㄧㄝ":"冷碟",
"ㄌㄥㄉㄧㄥ":"冷丁",
"ㄌㄥㄉㄨㄥ":"冷凍",
"ㄌㄥㄊㄧㄢ":"冷天",
"ㄌㄥㄋㄧㄥ":"冷凝",
"ㄌㄥㄋㄨㄢ":"冷暖",
"ㄌㄥㄌㄧㄝ":"冷冽",
"ㄌㄥㄌㄧㄠ":"冷療",
"ㄌㄥㄌㄨㄛ":"冷落",
"ㄌㄥㄍㄨㄢ":"冷官",
"ㄌㄥㄍㄨㄤ":"冷光",
"ㄌㄥㄍㄨㄥ":"冷宮",
"ㄌㄥㄏㄨㄚ":"冷話/愣話",
"ㄌㄥㄏㄨㄛ":"冷貨",
"ㄌㄥㄏㄨㄣ":"冷葷",
"ㄌㄥㄐㄧㄝ":"冷節",
"ㄌㄥㄐㄧㄠ":"稜角",
"ㄌㄥㄐㄧㄢ":"冷箭",
"ㄌㄥㄐㄧㄣ":"冷金/冷勁",
"ㄌㄥㄐㄧㄥ":"冷靜/冷徑",
"ㄌㄥㄐㄩㄝ":"冷覺",
"ㄌㄥㄐㄩㄣ":"冷峻",
"ㄌㄥㄑㄧㄠ":"冷峭/崚峭",
"ㄌㄥㄑㄧㄤ":"冷槍",
"ㄌㄥㄑㄧㄥ":"冷清",
"ㄌㄥㄑㄩㄝ":"冷卻",
"ㄌㄥㄑㄩㄢ":"冷泉",
"ㄌㄥㄒㄧㄝ":"冷血",
"ㄌㄥㄒㄧㄠ":"冷笑",
"ㄌㄥㄒㄧㄢ":"稜線",
"ㄌㄥㄒㄧㄣ":"冷心",
"ㄌㄥㄒㄧㄤ":"冷項/冷巷",
"ㄌㄥㄓㄨㄟ":"稜錐",
"ㄌㄥㄔㄨㄤ":"冷床",
"ㄌㄥㄕㄨㄟ":"冷水",
"ㄌㄥㄗㄧㄢ":"楞子眼",
"ㄌㄥㄘㄤㄕ":"冷藏室",
"ㄌㄥㄘㄨㄥ":"愣蔥",
"ㄌㄧㄅㄚㄦ":"力巴兒",
"ㄌㄧㄅㄞㄙ":"禮拜寺",
"ㄌㄧㄅㄧㄝ":"離別",
"ㄌㄧㄅㄧㄢ":"裡邊/隸變",
"ㄌㄧㄅㄧㄥ":"禮餅/罹病",
"ㄌㄧㄅㄨㄕ":"禮部試",
"ㄌㄧㄆㄧㄢ":"歷遍",
"ㄌㄧㄆㄧㄣ":"禮品/立品",
"ㄌㄧㄇㄧㄠ":"黎苗",
"ㄌㄧㄇㄧㄢ":"裡面/剺面",
"ㄌㄧㄇㄧㄣ":"黎民/里民",
"ㄌㄧㄇㄧㄥ":"黎明/立名",
"ㄌㄧㄈㄚㄕ":"理髮師",
"ㄌㄧㄉㄜㄩ":"李德裕",
"ㄌㄧㄉㄧㄢ":"力點",
"ㄌㄧㄉㄧㄥ":"釐定/立定",
"ㄌㄧㄉㄨㄛ":"利多",
"ㄌㄧㄉㄨㄟ":"離隊",
"ㄌㄧㄉㄨㄢ":"理短/立斷",
"ㄌㄧㄉㄨㄣ":"利鈍",
"ㄌㄧㄉㄨㄥ":"黎峒/立冬",
"ㄌㄧㄊㄧㄢ":"力田/麗天",
"ㄌㄧㄊㄧㄥ":"鯉庭/犁庭",
"ㄌㄧㄊㄨㄛ":"離脫/攦脫",
"ㄌㄧㄋㄧㄝ":"沴孽",
"ㄌㄧㄋㄧㄠ":"利尿",
"ㄌㄧㄋㄧㄡ":"氂牛/犛牛",
"ㄌㄧㄋㄧㄢ":"理念/歷年",
"ㄌㄧㄋㄨㄥ":"隸農",
"ㄌㄧㄌㄧㄝ":"栗烈/慄冽",
"ㄌㄧㄌㄧㄠ":"理療",
"ㄌㄧㄌㄧㄢ":"歷練/裡廉",
"ㄌㄧㄌㄧㄣ":"蒞臨",
"ㄌㄧㄌㄧㄤ":"力量/利亮",
"ㄌㄧㄌㄧㄥ":"李陵",
"ㄌㄧㄌㄨㄛ":"俐落/利落",
"ㄌㄧㄌㄨㄢ":"理亂/離亂",
"ㄌㄧㄌㄨㄣ":"理論/立論",
"ㄌㄧㄌㄨㄦ":"禮路兒",
"ㄌㄧㄍㄜㄦ":"離格兒",
"ㄌㄧㄍㄢㄕ":"里幹事",
"ㄌㄧㄍㄨㄛ":"立國",
"ㄌㄧㄍㄨㄟ":"厲鬼/例規",
"ㄌㄧㄍㄨㄢ":"理官/禮官",
"ㄌㄧㄍㄨㄤ":"李廣",
"ㄌㄧㄍㄨㄥ":"理工/立功",
"ㄌㄧㄍㄨㄦ":"離股兒",
"ㄌㄧㄎㄨㄞ":"利快",
"ㄌㄧㄎㄨㄟ":"理虧/李悝",
"ㄌㄧㄎㄨㄥ":"利空",
"ㄌㄧㄏㄜㄕ":"離合詩",
"ㄌㄧㄏㄨㄗ":"理鬍子",
"ㄌㄧㄏㄨㄚ":"理化/禮花",
"ㄌㄧㄏㄨㄛ":"藜藿",
"ㄌㄧㄏㄨㄟ":"理會/例會",
"ㄌㄧㄏㄨㄢ":"罹患",
"ㄌㄧㄏㄨㄣ":"離婚",
"ㄌㄧㄏㄨㄤ":"蠣黃/鸝黃",
"ㄌㄧㄐㄧㄚ":"理家/例假",
"ㄌㄧㄐㄧㄝ":"理解/禮節",
"ㄌㄧㄐㄧㄠ":"理教/立教",
"ㄌㄧㄐㄧㄡ":"歷久/瀝酒",
"ㄌㄧㄐㄧㄢ":"離間/裡間",
"ㄌㄧㄐㄧㄣ":"禮金/黎錦",
"ㄌㄧㄐㄧㄤ":"漓江/灕江",
"ㄌㄧㄐㄧㄥ":"離境/歷經",
"ㄌㄧㄐㄩㄝ":"立決/藜蕨",
"ㄌㄧㄐㄩㄣ":"里君",
"ㄌㄧㄑㄧㄡ":"力求/立秋",
"ㄌㄧㄑㄧㄢ":"力錢/利錢",
"ㄌㄧㄑㄧㄤ":"力強",
"ㄌㄧㄑㄧㄥ":"釐清/瀝青",
"ㄌㄧㄑㄩㄝ":"離缺",
"ㄌㄧㄑㄩㄢ":"力勸/利權",
"ㄌㄧㄑㄩㄣ":"離群",
"ㄌㄧㄒㄧㄚ":"立夏/立下",
"ㄌㄧㄒㄧㄠ":"離校/立效",
"ㄌㄧㄒㄧㄡ":"離休",
"ㄌㄧㄒㄧㄢ":"立憲/黎獻",
"ㄌㄧㄒㄧㄣ":"離心/栗薪",
"ㄌㄧㄒㄧㄤ":"理想/離鄉",
"ㄌㄧㄒㄧㄥ":"理性/例行",
"ㄌㄧㄒㄩㄝ":"力學/理學",
"ㄌㄧㄒㄩㄢ":"粒選",
"ㄌㄧㄓㄋㄨ":"荔枝奴",
"ㄌㄧㄓㄤㄗ":"立帳子",
"ㄌㄧㄓㄨㄛ":"蠡酌",
"ㄌㄧㄓㄨㄢ":"隸篆",
"ㄌㄧㄓㄨㄤ":"理妝",
"ㄌㄧㄔㄨㄣ":"立春",
"ㄌㄧㄔㄨㄤ":"藜床",
"ㄌㄧㄕㄐㄩ":"歷史劇",
"ㄌㄧㄕㄓㄣ":"李時珍",
"ㄌㄧㄕㄓㄤ":"理事長",
"ㄌㄧㄕㄜㄩ":"犁舌獄",
"ㄌㄧㄕㄡㄦ":"立手兒",
"ㄌㄧㄕㄨㄞ":"力衰",
"ㄌㄧㄕㄨㄟ":"麗水/瀝水",
"ㄌㄧㄕㄨㄣ":"理順",
"ㄌㄧㄕㄩㄘ":"歷史語詞",
"ㄌㄧㄖㄨㄛ":"力弱",
"ㄌㄧㄖㄨㄣ":"利潤",
"ㄌㄧㄖㄨㄥ":"理容/麗絨",
"ㄌㄧㄗㄔㄥ":"李自成",
"ㄌㄧㄗㄨㄛ":"力作/栗鑿",
"ㄌㄧㄘㄨㄛ":"力挫",
"ㄌㄧㄝㄅㄚ":"劣把",
"ㄌㄧㄝㄅㄛ":"裂帛",
"ㄌㄧㄝㄅㄞ":"劣敗",
"ㄌㄧㄝㄅㄠ":"獵豹",
"ㄌㄧㄝㄅㄧ":"劣比/劣筆",
"ㄌㄧㄝㄅㄨ":"列布",
"ㄌㄧㄝㄇㄚ":"劣馬",
"ㄌㄧㄝㄇㄟ":"列眉",
"ㄌㄧㄝㄈㄥ":"烈風/裂縫",
"ㄌㄧㄝㄈㄨ":"烈婦",
"ㄌㄧㄝㄉㄠ":"列島/獵刀",
"ㄌㄧㄝㄉㄢ":"裂膽",
"ㄌㄧㄝㄉㄤ":"列當",
"ㄌㄧㄝㄉㄥ":"劣等/躐等",
"ㄌㄧㄝㄉㄧ":"裂地",
"ㄌㄧㄝㄉㄨ":"烈度",
"ㄌㄧㄝㄊㄨ":"列土/裂土",
"ㄌㄧㄝㄋㄩ":"列女/烈女",
"ㄌㄧㄝㄍㄡ":"獵狗/鬣狗",
"ㄌㄧㄝㄍㄨ":"裂谷",
"ㄌㄧㄝㄎㄞ":"裂開",
"ㄌㄧㄝㄎㄡ":"裂口",
"ㄌㄧㄝㄏㄡ":"列侯",
"ㄌㄧㄝㄏㄣ":"裂痕",
"ㄌㄧㄝㄏㄨ":"獵戶/躐胡",
"ㄌㄧㄝㄐㄧ":"劣跡",
"ㄌㄧㄝㄐㄩ":"列舉/獵具",
"ㄌㄧㄝㄑㄧ":"獵奇",
"ㄌㄧㄝㄑㄩ":"獵取",
"ㄌㄧㄝㄒㄧ":"列席/躐席",
"ㄌㄧㄝㄓㄣ":"列陣",
"ㄌㄧㄝㄓㄨ":"劣株",
"ㄌㄧㄝㄔㄜ":"列車",
"ㄌㄧㄝㄔㄤ":"獵場",
"ㄌㄧㄝㄕㄚ":"獵殺",
"ㄌㄧㄝㄕㄜ":"獵涉",
"ㄌㄧㄝㄕㄡ":"獵手",
"ㄌㄧㄝㄕㄣ":"劣紳",
"ㄌㄧㄝㄕㄥ":"列聖",
"ㄌㄧㄝㄕㄨ":"列述/烈暑",
"ㄌㄧㄝㄖㄣ":"獵人",
"ㄌㄧㄝㄖㄨ":"列入",
"ㄌㄧㄝㄗㄨ":"烈祖",
"ㄌㄧㄝㄘㄜ":"列冊",
"ㄌㄧㄝㄧㄠ":"劣藥",
"ㄌㄧㄝㄧㄢ":"烈焰/裂眼",
"ㄌㄧㄝㄧㄣ":"列印",
"ㄌㄧㄝㄧㄤ":"烈陽",
"ㄌㄧㄝㄧㄥ":"獵鷹",
"ㄌㄧㄝㄨㄟ":"列位/烈味",
"ㄌㄧㄝㄨㄣ":"烈文/裂紋",
"ㄌㄧㄠㄅㄛ":"撩撥",
"ㄌㄧㄠㄅㄧ":"了畢",
"ㄌㄧㄠㄅㄨ":"療補",
"ㄌㄧㄠㄆㄠ":"潦泡/燎泡",
"ㄌㄧㄠㄆㄧ":"撂皮",
"ㄌㄧㄠㄇㄛ":"料莫",
"ㄌㄧㄠㄇㄠ":"料毛/燎毛",
"ㄌㄧㄠㄈㄚ":"療法/燎髮",
"ㄌㄧㄠㄈㄤ":"療方",
"ㄌㄧㄠㄉㄜ":"了得",
"ㄌㄧㄠㄉㄞ":"料袋",
"ㄌㄧㄠㄉㄠ":"潦倒/料到",
"ㄌㄧㄠㄉㄡ":"撩鬥/撩逗",
"ㄌㄧㄠㄉㄤ":"了當",
"ㄌㄧㄠㄉㄧ":"撂地/料敵",
"ㄌㄧㄠㄋㄚ":"了納",
"ㄌㄧㄠㄌㄜ":"撂了",
"ㄌㄧㄠㄌㄞ":"聊賴",
"ㄌㄧㄠㄌㄟ":"漻淚",
"ㄌㄧㄠㄌㄢ":"蓼藍",
"ㄌㄧㄠㄌㄤ":"遼朗/燎朗",
"ㄌㄧㄠㄌㄧ":"料理/寥戾",
"ㄌㄧㄠㄌㄨ":"鐐鑪",
"ㄌㄧㄠㄎㄜ":"蓼科",
"ㄌㄧㄠㄎㄞ":"撂開",
"ㄌㄧㄠㄎㄠ":"鐐銬",
"ㄌㄧㄠㄏㄜ":"遼河",
"ㄌㄧㄠㄐㄧ":"僚機/料及",
"ㄌㄧㄠㄐㄩ":"了局",
"ㄌㄧㄠㄑㄧ":"撩起/了訖",
"ㄌㄧㄠㄒㄧ":"遼西",
"ㄌㄧㄠㄓㄞ":"了債",
"ㄌㄧㄠㄓㄤ":"了帳/了賬",
"ㄌㄧㄠㄔㄠ":"料鈔/遼朝",
"ㄌㄧㄠㄔㄥ":"療程",
"ㄌㄧㄠㄕㄡ":"了手/撂手",
"ㄌㄧㄠㄕㄤ":"療傷",
"ㄌㄧㄠㄕㄥ":"聊生",
"ㄌㄧㄠㄕㄨ":"僚屬",
"ㄌㄧㄠㄖㄠ":"繚繞",
"ㄌㄧㄠㄖㄢ":"了然/料然",
"ㄌㄧㄠㄖㄣ":"撩人",
"ㄌㄧㄠㄘㄞ":"寮寀",
"ㄌㄧㄠㄘㄠ":"潦草/料草",
"ㄌㄧㄠㄧㄚ":"獠牙",
"ㄌㄧㄠㄧㄡ":"僚友",
"ㄌㄧㄠㄧㄤ":"療養",
"ㄌㄧㄠㄧㄥ":"料應",
"ㄌㄧㄠㄨㄤ":"瞭望",
"ㄌㄧㄠㄩㄢ":"了願/燎原",
"ㄌㄧㄡㄅㄛ":"流波/流播",
"ㄌㄧㄡㄅㄞ":"留白",
"ㄌㄧㄡㄅㄟ":"劉備/流輩",
"ㄌㄧㄡㄅㄤ":"劉邦",
"ㄌㄧㄡㄅㄧ":"流弊",
"ㄌㄧㄡㄅㄨ":"六部/留步",
"ㄌㄧㄡㄆㄞ":"流派",
"ㄌㄧㄡㄆㄟ":"流配",
"ㄌㄧㄡㄆㄠ":"榴炮",
"ㄌㄧㄡㄆㄢ":"流盼",
"ㄌㄧㄡㄇㄚ":"遛馬",
"ㄌㄧㄡㄇㄛ":"流沫",
"ㄌㄧㄡㄇㄞ":"六脈",
"ㄌㄧㄡㄇㄟ":"柳眉",
"ㄌㄧㄡㄇㄢ":"流漫",
"ㄌㄧㄡㄇㄣ":"留門",
"ㄌㄧㄡㄇㄤ":"流氓",
"ㄌㄧㄡㄇㄨ":"流目",
"ㄌㄧㄡㄈㄚ":"六法",
"ㄌㄧㄡㄈㄢ":"留飯",
"ㄌㄧㄡㄈㄤ":"流放/流芳",
"ㄌㄧㄡㄈㄥ":"流風",
"ㄌㄧㄡㄈㄨ":"六腑/劉復",
"ㄌㄧㄡㄉㄚ":"溜達/遛達",
"ㄌㄧㄡㄉㄜ":"溜的",
"ㄌㄧㄡㄉㄞ":"留待",
"ㄌㄧㄡㄉㄠ":"六道",
"ㄌㄧㄡㄉㄢ":"流彈/榴彈",
"ㄌㄧㄡㄉㄤ":"流蕩/流宕",
"ㄌㄧㄡㄉㄧ":"流遞",
"ㄌㄧㄡㄉㄨ":"六度/流毒",
"ㄌㄧㄡㄊㄠ":"六韜",
"ㄌㄧㄡㄊㄡ":"留頭",
"ㄌㄧㄡㄊㄤ":"六堂",
"ㄌㄧㄡㄊㄧ":"流體/留題",
"ㄌㄧㄡㄋㄢ":"留難",
"ㄌㄧㄡㄌㄟ":"流淚",
"ㄌㄧㄡㄌㄢ":"瀏覽/流覽",
"ㄌㄧㄡㄌㄤ":"流浪",
"ㄌㄧㄡㄌㄧ":"流利/流離",
"ㄌㄧㄡㄌㄨ":"流露",
"ㄌㄧㄡㄌㄩ":"六律/六呂",
"ㄌㄧㄡㄍㄡ":"遛狗",
"ㄌㄧㄡㄍㄣ":"留根/六根",
"ㄌㄧㄡㄍㄨ":"柳骨",
"ㄌㄧㄡㄎㄜ":"六科",
"ㄌㄧㄡㄎㄞ":"柳開",
"ㄌㄧㄡㄎㄡ":"流寇",
"ㄌㄧㄡㄏㄜ":"六合/六翮",
"ㄌㄧㄡㄏㄞ":"劉海/留海",
"ㄌㄧㄡㄏㄠ":"溜號",
"ㄌㄧㄡㄏㄡ":"留侯",
"ㄌㄧㄡㄏㄢ":"流汗",
"ㄌㄧㄡㄐㄧ":"六極/留級",
"ㄌㄧㄡㄑㄧ":"流氣/六氣",
"ㄌㄧㄡㄑㄩ":"留取/六區",
"ㄌㄧㄡㄒㄧ":"流徙/劉熙",
"ㄌㄧㄡㄒㄩ":"六虛/溜鬚",
"ㄌㄧㄡㄓㄠ":"六詔",
"ㄌㄧㄡㄓㄣ":"劉楨",
"ㄌㄧㄡㄓㄨ":"留住/流注",
"ㄌㄧㄡㄔㄜ":"劉徹",
"ㄌㄧㄡㄔㄠ":"六朝",
"ㄌㄧㄡㄔㄢ":"流產/劉禪",
"ㄌㄧㄡㄔㄣ":"六陳/六塵",
"ㄌㄧㄡㄔㄤ":"流暢",
"ㄌㄧㄡㄔㄥ":"流程/留成",
"ㄌㄧㄡㄔㄨ":"六出/六畜",
"ㄌㄧㄡㄕㄚ":"流沙",
"ㄌㄧㄡㄕㄡ":"留守",
"ㄌㄧㄡㄕㄢ":"柳杉",
"ㄌㄧㄡㄕㄣ":"留神/留身",
"ㄌㄧㄡㄕㄤ":"流觴",
"ㄌㄧㄡㄕㄨ":"六書/柳樹",
"ㄌㄧㄡㄖㄣ":"流人/留任",
"ㄌㄧㄡㄖㄨ":"六如",
"ㄌㄧㄡㄗㄜ":"流澤",
"ㄌㄧㄡㄗㄟ":"六賊",
"ㄌㄧㄡㄗㄡ":"溜走",
"ㄌㄧㄡㄗㄨ":"六祖",
"ㄌㄧㄡㄘㄞ":"六彩",
"ㄌㄧㄡㄘㄠ":"溜槽",
"ㄌㄧㄡㄙㄚ":"溜撒",
"ㄌㄧㄡㄙㄢ":"流散",
"ㄌㄧㄡㄙㄨ":"流速/流俗",
"ㄌㄧㄡㄧㄚ":"流亞/柳衙",
"ㄌㄧㄡㄧㄝ":"柳葉",
"ㄌㄧㄡㄧㄠ":"六么/六爻",
"ㄌㄧㄡㄧㄢ":"留言/流言",
"ㄌㄧㄡㄧㄣ":"六淫/劉隱",
"ㄌㄧㄡㄧㄤ":"留洋",
"ㄌㄧㄡㄧㄥ":"流鶯/留影",
"ㄌㄧㄡㄨㄞ":"流外",
"ㄌㄧㄡㄨㄟ":"六位/六味",
"ㄌㄧㄡㄨㄢ":"流丸",
"ㄌㄧㄡㄨㄤ":"流亡/流網",
"ㄌㄧㄡㄩㄝ":"六月/六樂",
"ㄌㄧㄡㄩㄢ":"六院/溜圓",
"ㄌㄧㄡㄩㄣ":"流雲",
"ㄌㄧㄡㄩㄥ":"柳永/劉墉",
"ㄌㄧㄢㄅㄚ":"連霸",
"ㄌㄧㄢㄅㄛ":"廉白/臉波",
"ㄌㄧㄢㄅㄠ":"聯保",
"ㄌㄧㄢㄅㄤ":"聯邦/蓮邦",
"ㄌㄧㄢㄅㄧ":"連比/連璧",
"ㄌㄧㄢㄅㄨ":"臉部/蓮步",
"ㄌㄧㄢㄆㄚ":"臉帕",
"ㄌㄧㄢㄆㄛ":"廉頗",
"ㄌㄧㄢㄆㄟ":"聯轡",
"ㄌㄧㄢㄆㄣ":"臉盆",
"ㄌㄧㄢㄆㄤ":"臉龐",
"ㄌㄧㄢㄆㄥ":"蓮蓬",
"ㄌㄧㄢㄆㄧ":"臉皮",
"ㄌㄧㄢㄆㄨ":"臉譜",
"ㄌㄧㄢㄇㄟ":"連袂/聯袂",
"ㄌㄧㄢㄇㄢ":"臉慢/簾幔",
"ㄌㄧㄢㄇㄤ":"連忙",
"ㄌㄧㄢㄇㄥ":"聯盟",
"ㄌㄧㄢㄇㄨ":"戀慕/蓮幕",
"ㄌㄧㄢㄈㄢ":"連番",
"ㄌㄧㄢㄈㄤ":"蓮房/奩房",
"ㄌㄧㄢㄈㄥ":"廉俸",
"ㄌㄧㄢㄈㄨ":"蓮府",
"ㄌㄧㄢㄉㄚ":"聯大/臉大",
"ㄌㄧㄢㄉㄞ":"連帶/聯貸",
"ㄌㄧㄢㄉㄠ":"臉道/鐮刀",
"ㄌㄧㄢㄉㄢ":"聯單/臉蛋",
"ㄌㄧㄢㄉㄨ":"鍊度",
"ㄌㄧㄢㄊㄞ":"連臺/蓮臺",
"ㄌㄧㄢㄊㄢ":"聯彈",
"ㄌㄧㄢㄊㄨ":"練土",
"ㄌㄧㄢㄋㄠ":"臉腦",
"ㄌㄧㄢㄋㄣ":"臉嫩",
"ㄌㄧㄢㄋㄥ":"廉能",
"ㄌㄧㄢㄌㄟ":"連累/連類",
"ㄌㄧㄢㄌㄧ":"連理/廉吏",
"ㄌㄧㄢㄍㄜ":"戀歌",
"ㄌㄧㄢㄍㄡ":"聯購/蓮鉤",
"ㄌㄧㄢㄍㄣ":"連亙",
"ㄌㄧㄢㄍㄤ":"煉鋼",
"ㄌㄧㄢㄎㄠ":"聯考",
"ㄌㄧㄢㄎㄢ":"蓮龕",
"ㄌㄧㄢㄏㄜ":"聯合/連合",
"ㄌㄧㄢㄏㄠ":"連號",
"ㄌㄧㄢㄏㄥ":"連橫/連衡",
"ㄌㄧㄢㄐㄧ":"連擊/臉急",
"ㄌㄧㄢㄐㄩ":"連句/聯句",
"ㄌㄧㄢㄑㄧ":"鍊氣/奩器",
"ㄌㄧㄢㄑㄩ":"戀曲",
"ㄌㄧㄢㄒㄧ":"練習/連繫",
"ㄌㄧㄢㄒㄩ":"連續/憐恤",
"ㄌㄧㄢㄓㄜ":"斂著",
"ㄌㄧㄢㄓㄠ":"聯招",
"ㄌㄧㄢㄓㄢ":"戀棧/戀戰",
"ㄌㄧㄢㄓㄣ":"連真/戀枕",
"ㄌㄧㄢㄓㄤ":"連長",
"ㄌㄧㄢㄓㄥ":"廉政/廉正",
"ㄌㄧㄢㄓㄨ":"連屬/連珠",
"ㄌㄧㄢㄔㄤ":"聯唱/練唱",
"ㄌㄧㄢㄔㄥ":"連城",
"ㄌㄧㄢㄕㄓ":"連史紙",
"ㄌㄧㄢㄕㄜ":"連射/蓮社",
"ㄌㄧㄢㄕㄡ":"聯手/連手",
"ㄌㄧㄢㄕㄢ":"煉山",
"ㄌㄧㄢㄕㄣ":"連身",
"ㄌㄧㄢㄕㄤ":"臉上",
"ㄌㄧㄢㄕㄥ":"連聲",
"ㄌㄧㄢㄕㄨ":"連屬/連署",
"ㄌㄧㄢㄖㄜ":"臉熱",
"ㄌㄧㄢㄖㄣ":"戀人/連任",
"ㄌㄧㄢㄖㄤ":"廉讓",
"ㄌㄧㄢㄖㄨ":"煉乳/漣洳",
"ㄌㄧㄢㄗㄞ":"連載",
"ㄌㄧㄢㄗㄡ":"聯奏",
"ㄌㄧㄢㄗㄨ":"斂足",
"ㄌㄧㄢㄘㄞ":"憐才/斂財",
"ㄌㄧㄢㄘㄤ":"斂藏/鐮倉",
"ㄌㄧㄢㄙㄓ":"連四紙",
"ㄌㄧㄢㄙㄜ":"臉色",
"ㄌㄧㄢㄙㄞ":"聯賽",
"ㄌㄧㄢㄧㄝ":"連夜/蓮葉",
"ㄌㄧㄢㄧㄠ":"練要",
"ㄌㄧㄢㄧㄡ":"煉油",
"ㄌㄧㄢㄧㄢ":"連延/瀲灩",
"ㄌㄧㄢㄧㄣ":"連陰/連姻",
"ㄌㄧㄢㄧㄥ":"聯營/臉硬",
"ㄌㄧㄢㄨㄟ":"簾帷",
"ㄌㄧㄢㄩㄢ":"斂怨",
"ㄌㄧㄢㄩㄣ":"連雲/聯運",
"ㄌㄧㄢㄩㄥ":"連用",
"ㄌㄧㄣㄅㄚ":"淋巴",
"ㄌㄧㄣㄅㄛ":"林薄/鱗波",
"ㄌㄧㄣㄅㄟ":"鱗被",
"ㄌㄧㄣㄅㄢ":"林班/璘斑",
"ㄌㄧㄣㄅㄣ":"臨本",
"ㄌㄧㄣㄅㄤ":"鄰邦",
"ㄌㄧㄣㄅㄧ":"鄰比/鱗比",
"ㄌㄧㄣㄅㄨ":"林逋",
"ㄌㄧㄣㄆㄞ":"遴派",
"ㄌㄧㄣㄆㄢ":"瞵盼",
"ㄌㄧㄣㄆㄣ":"臨盆",
"ㄌㄧㄣㄇㄛ":"臨摹",
"ㄌㄧㄣㄇㄠ":"鱗毛",
"ㄌㄧㄣㄇㄣ":"臨門",
"ㄌㄧㄣㄇㄤ":"林莽",
"ㄌㄧㄣㄇㄨ":"林木/鄰睦",
"ㄌㄧㄣㄈㄟ":"磷肥",
"ㄌㄧㄣㄈㄢ":"臨凡",
"ㄌㄧㄣㄈㄤ":"賃房",
"ㄌㄧㄣㄈㄥ":"臨風/麟鳳",
"ㄌㄧㄣㄉㄞ":"林帶",
"ㄌㄧㄣㄉㄠ":"臨到",
"ㄌㄧㄣㄉㄧ":"林地/鄰敵",
"ㄌㄧㄣㄊㄚ":"臨榻",
"ㄌㄧㄣㄊㄞ":"麟臺",
"ㄌㄧㄣㄊㄠ":"林濤",
"ㄌㄧㄣㄊㄡ":"臨頭/林投",
"ㄌㄧㄣㄋㄢ":"臨難",
"ㄌㄧㄣㄌㄞ":"林籟",
"ㄌㄧㄣㄌㄤ":"林浪/林郎",
"ㄌㄧㄣㄌㄧ":"林立/鄰里",
"ㄌㄧㄣㄌㄨ":"林麓",
"ㄌㄧㄣㄍㄜ":"麟閣",
"ㄌㄧㄣㄍㄢ":"臨感",
"ㄌㄧㄣㄎㄡ":"林口",
"ㄌㄧㄣㄐㄧ":"臨機/鱗集",
"ㄌㄧㄣㄐㄩ":"鄰居",
"ㄌㄧㄣㄑㄧ":"臨期/臨歧",
"ㄌㄧㄣㄑㄩ":"林區/鄰曲",
"ㄌㄧㄣㄒㄧ":"吝惜",
"ㄌㄧㄣㄓㄓ":"麟之趾",
"ㄌㄧㄣㄓㄠ":"臨照",
"ㄌㄧㄣㄓㄣ":"臨陣",
"ㄌㄧㄣㄓㄤ":"鄰長",
"ㄌㄧㄣㄓㄥ":"淋證",
"ㄌㄧㄣㄔㄠ":"臨朝",
"ㄌㄧㄣㄔㄢ":"林產",
"ㄌㄧㄣㄔㄤ":"臨場/林場",
"ㄌㄧㄣㄕㄜ":"鄰舍",
"ㄌㄧㄣㄕㄠ":"林梢/廩稍",
"ㄌㄧㄣㄕㄢ":"廩膳",
"ㄌㄧㄣㄕㄤ":"鱗傷",
"ㄌㄧㄣㄕㄥ":"廩生",
"ㄌㄧㄣㄕㄨ":"臨書/林紓",
"ㄌㄧㄣㄖㄢ":"凜然/懍然",
"ㄌㄧㄣㄖㄣ":"鄰人",
"ㄌㄧㄣㄖㄨ":"臨蓐",
"ㄌㄧㄣㄗㄡ":"臨走",
"ㄌㄧㄣㄘㄞ":"臨財",
"ㄌㄧㄣㄙㄜ":"吝嗇/吝色",
"ㄌㄧㄣㄙㄡ":"林藪/淋溲",
"ㄌㄧㄣㄙㄣ":"林森",
"ㄌㄧㄣㄙㄨ":"廩粟",
"ㄌㄧㄣㄧㄝ":"林業",
"ㄌㄧㄣㄧㄡ":"鄰右/鱗游",
"ㄌㄧㄣㄧㄣ":"林蔭",
"ㄌㄧㄣㄨㄟ":"臨危",
"ㄌㄧㄣㄨㄣ":"臨文/臨問",
"ㄌㄧㄣㄩㄝ":"臨月",
"ㄌㄧㄣㄩㄢ":"林園/林苑",
"ㄌㄧㄣㄩㄥ":"臨雍",
"ㄌㄧㄤㄅㄟ":"量杯",
"ㄌㄧㄤㄅㄢ":"良伴/涼拌",
"ㄌㄧㄤㄅㄤ":"兩榜",
"ㄌㄧㄤㄅㄧ":"諒必/良弼",
"ㄌㄧㄤㄆㄠ":"良庖",
"ㄌㄧㄤㄆㄤ":"兩旁",
"ㄌㄧㄤㄆㄥ":"良朋/涼棚",
"ㄌㄧㄤㄇㄚ":"良馬",
"ㄌㄧㄤㄇㄛ":"糧秣",
"ㄌㄧㄤㄇㄟ":"良媒",
"ㄌㄧㄤㄇㄠ":"涼帽",
"ㄌㄧㄤㄇㄡ":"良謀",
"ㄌㄧㄤㄇㄥ":"梁孟",
"ㄌㄧㄤㄈㄢ":"量販",
"ㄌㄧㄤㄈㄣ":"涼粉",
"ㄌㄧㄤㄈㄤ":"良方",
"ㄌㄧㄤㄈㄥ":"涼風",
"ㄌㄧㄤㄈㄨ":"亮富",
"ㄌㄧㄤㄉㄚ":"量大",
"ㄌㄧㄤㄉㄜ":"涼德",
"ㄌㄧㄤㄉㄠ":"糧道",
"ㄌㄧㄤㄉㄢ":"糧單",
"ㄌㄧㄤㄉㄤ":"裲襠",
"ㄌㄧㄤㄉㄧ":"兩抵",
"ㄌㄧㄤㄉㄨ":"兩都/亮度",
"ㄌㄧㄤㄊㄚ":"涼榻",
"ㄌㄧㄤㄊㄞ":"涼臺",
"ㄌㄧㄤㄊㄡ":"兩頭",
"ㄌㄧㄤㄊㄢ":"兩袒",
"ㄌㄧㄤㄊㄤ":"亮堂",
"ㄌㄧㄤㄊㄨ":"良圖",
"ㄌㄧㄤㄋㄢ":"兩難",
"ㄌㄧㄤㄋㄥ":"良能",
"ㄌㄧㄤㄌㄜ":"涼了",
"ㄌㄧㄤㄌㄠ":"兩老",
"ㄌㄧㄤㄌㄢ":"亮藍",
"ㄌㄧㄤㄌㄧ":"亮麗/兩利",
"ㄌㄧㄤㄍㄢ":"晾乾/量感",
"ㄌㄧㄤㄎㄜ":"兩可",
"ㄌㄧㄤㄎㄞ":"梁楷",
"ㄌㄧㄤㄎㄡ":"兩口",
"ㄌㄧㄤㄏㄠ":"良好",
"ㄌㄧㄤㄏㄢ":"兩漢",
"ㄌㄧㄤㄏㄤ":"糧行",
"ㄌㄧㄤㄏㄨ":"兩湖/糧戶",
"ㄌㄧㄤㄐㄧ":"良機/兩極",
"ㄌㄧㄤㄐㄩ":"量具/良駒",
"ㄌㄧㄤㄑㄧ":"輛起/兩歧",
"ㄌㄧㄤㄒㄧ":"兩犀/涼蓆",
"ㄌㄧㄤㄓㄜ":"兩浙",
"ㄌㄧㄤㄓㄞ":"量窄",
"ㄌㄧㄤㄓㄡ":"梁州/梁輈",
"ㄌㄧㄤㄓㄢ":"糧站/糧棧",
"ㄌㄧㄤㄓㄤ":"糧長",
"ㄌㄧㄤㄓㄨ":"梁祝/梁柱",
"ㄌㄧㄤㄔㄚ":"涼茶/亮察",
"ㄌㄧㄤㄔㄜ":"糧車",
"ㄌㄧㄤㄔㄡ":"良儔/亮醜",
"ㄌㄧㄤㄔㄢ":"量產",
"ㄌㄧㄤㄔㄣ":"良臣/良辰",
"ㄌㄧㄤㄕㄚ":"亮紗",
"ㄌㄧㄤㄕㄜ":"兩舌",
"ㄌㄧㄤㄕㄡ":"兩手",
"ㄌㄧㄤㄕㄢ":"梁山/良善",
"ㄌㄧㄤㄕㄣ":"良深",
"ㄌㄧㄤㄕㄨ":"梁書/晾書",
"ㄌㄧㄤㄖㄡ":"粱肉",
"ㄌㄧㄤㄖㄣ":"良人",
"ㄌㄧㄤㄗㄠ":"兩造/良造",
"ㄌㄧㄤㄘㄜ":"兩側/量測",
"ㄌㄧㄤㄘㄞ":"良材",
"ㄌㄧㄤㄘㄠ":"糧草",
"ㄌㄧㄤㄘㄤ":"糧倉",
"ㄌㄧㄤㄙㄙ":"涼絲絲",
"ㄌㄧㄤㄧㄚ":"量雅",
"ㄌㄧㄤㄧㄝ":"良夜/良冶",
"ㄌㄧㄤㄧㄠ":"兩曜/良藥",
"ㄌㄧㄤㄧㄡ":"良友/涼友",
"ㄌㄧㄤㄧㄢ":"兩眼/兩言",
"ㄌㄧㄤㄧㄤ":"兩樣",
"ㄌㄧㄤㄩㄝ":"兩粵/兩越",
"ㄌㄧㄤㄩㄢ":"良緣/梁園",
"ㄌㄧㄤㄩㄣ":"良醞",
"ㄌㄧㄤㄩㄥ":"兩用",
"ㄌㄧㄥㄅㄛ":"凌波",
"ㄌㄧㄥㄅㄠ":"靈保/凌暴",
"ㄌㄧㄥㄅㄢ":"領班/另般",
"ㄌㄧㄥㄅㄥ":"凌迸",
"ㄌㄧㄥㄅㄧ":"凌逼",
"ㄌㄧㄥㄅㄨ":"零布",
"ㄌㄧㄥㄆㄞ":"令牌/靈牌",
"ㄌㄧㄥㄆㄧ":"瓴甓",
"ㄌㄧㄥㄇㄚ":"零碼",
"ㄌㄧㄥㄇㄛ":"領抹",
"ㄌㄧㄥㄇㄞ":"零賣",
"ㄌㄧㄥㄇㄟ":"令妹/靈媒",
"ㄌㄧㄥㄇㄠ":"靈貓/翎毛",
"ㄌㄧㄥㄇㄢ":"靈幔",
"ㄌㄧㄥㄇㄨ":"令母/陵墓",
"ㄌㄧㄥㄈㄚ":"領罰",
"ㄌㄧㄥㄈㄟ":"靈妃",
"ㄌㄧㄥㄈㄢ":"靈旛/陵犯",
"ㄌㄧㄥㄈㄣ":"靈氛",
"ㄌㄧㄥㄈㄥ":"凌風/泠風",
"ㄌㄧㄥㄈㄨ":"靈府",
"ㄌㄧㄥㄉㄜ":"令德",
"ㄌㄧㄥㄉㄞ":"領帶",
"ㄌㄧㄥㄉㄠ":"領導/領道",
"ㄌㄧㄥㄉㄢ":"領單/靈丹",
"ㄌㄧㄥㄉㄤ":"鈴鐺",
"ㄌㄧㄥㄉㄧ":"領地/令弟",
"ㄌㄧㄥㄊㄞ":"領檯/靈臺",
"ㄌㄧㄥㄊㄡ":"零頭/領頭",
"ㄌㄧㄥㄊㄢ":"令坦",
"ㄌㄧㄥㄊㄤ":"靈堂/令堂",
"ㄌㄧㄥㄊㄧ":"零替/凌替",
"ㄌㄧㄥㄊㄨ":"領土",
"ㄌㄧㄥㄋㄢ":"嶺南",
"ㄌㄧㄥㄌㄟ":"另類/零淚",
"ㄌㄧㄥㄌㄤ":"令郎/玲琅",
"ㄌㄧㄥㄌㄧ":"凌厲/伶俐",
"ㄌㄧㄥㄌㄨ":"領路/酃淥",
"ㄌㄧㄥㄍㄜ":"令閣/靈歌",
"ㄌㄧㄥㄍㄞ":"靈蓋",
"ㄌㄧㄥㄍㄢ":"靈感",
"ㄌㄧㄥㄍㄤ":"領港",
"ㄌㄧㄥㄍㄨ":"鈴鼓/陵谷",
"ㄌㄧㄥㄎㄡ":"領口",
"ㄌㄧㄥㄏㄜ":"零和",
"ㄌㄧㄥㄏㄞ":"領海",
"ㄌㄧㄥㄏㄤ":"領航",
"ㄌㄧㄥㄏㄨ":"令狐/陵忽",
"ㄌㄧㄥㄐㄧ":"靈機/綾機",
"ㄌㄧㄥㄑㄧ":"靈氣/令器",
"ㄌㄧㄥㄑㄩ":"領取/另娶",
"ㄌㄧㄥㄒㄧ":"領洗/靈犀",
"ㄌㄧㄥㄒㄩ":"令婿/凌虛",
"ㄌㄧㄥㄓㄜ":"陵折",
"ㄌㄧㄥㄓㄤ":"領章",
"ㄌㄧㄥㄓㄥ":"令正",
"ㄌㄧㄥㄓㄨ":"領主",
"ㄌㄧㄥㄔㄜ":"靈車",
"ㄌㄧㄥㄔㄣ":"凌晨/令辰",
"ㄌㄧㄥㄔㄤ":"領唱",
"ㄌㄧㄥㄕㄡ":"零售/領受",
"ㄌㄧㄥㄕㄢ":"靈山",
"ㄌㄧㄥㄕㄤ":"領賞/聆賞",
"ㄌㄧㄥㄕㄥ":"靈聖",
"ㄌㄧㄥㄕㄨ":"零數/領屬",
"ㄌㄧㄥㄖㄢ":"泠然",
"ㄌㄧㄥㄖㄣ":"令人/靈人",
"ㄌㄧㄥㄖㄨ":"凌辱",
"ㄌㄧㄥㄗㄜ":"領責",
"ㄌㄧㄥㄗㄞ":"凌災",
"ㄌㄧㄥㄗㄡ":"領走",
"ㄌㄧㄥㄗㄤ":"領贓",
"ㄌㄧㄥㄗㄨ":"齡組",
"ㄌㄧㄥㄙㄜ":"令色",
"ㄌㄧㄥㄙㄢ":"零散",
"ㄌㄧㄥㄧㄚ":"陵壓",
"ㄌㄧㄥㄧㄝ":"靈液",
"ㄌㄧㄥㄧㄠ":"靈藥",
"ㄌㄧㄥㄧㄡ":"領有/另有",
"ㄌㄧㄥㄧㄢ":"靈驗/另眼",
"ㄌㄧㄥㄧㄣ":"令尹/凌陰",
"ㄌㄧㄥㄧㄤ":"領養/羚羊",
"ㄌㄧㄥㄧㄥ":"靈應/靈景",
"ㄌㄧㄥㄨㄚ":"綾襪",
"ㄌㄧㄥㄨㄞ":"另外",
"ㄌㄧㄥㄨㄟ":"靈位",
"ㄌㄧㄥㄨㄣ":"令聞",
"ㄌㄧㄥㄨㄤ":"令望",
"ㄌㄧㄥㄩㄗ":"零餘子",
"ㄌㄧㄥㄩㄝ":"令月/令岳",
"ㄌㄧㄥㄩㄢ":"鴒原/令媛",
"ㄌㄧㄥㄩㄣ":"凌雲",
"ㄌㄧㄥㄩㄥ":"零用",
"ㄌㄧㄦㄨㄤ":"李爾王",
"ㄌㄧㄧㄐㄧ":"酈食其",
"ㄌㄧㄧㄕㄢ":"李義山",
"ㄌㄧㄧㄞㄢ":"立崖岸",
"ㄌㄧㄩㄕㄨ":"李漁叔",
"ㄌㄨㄅㄢㄔ":"魯般尺",
"ㄌㄨㄅㄧㄠ":"路標",
"ㄌㄨㄅㄧㄥ":"爐餅",
"ㄌㄨㄇㄧㄠ":"露苗",
"ㄌㄨㄇㄧㄢ":"路面",
"ㄌㄨㄇㄧㄥ":"祿命/鹿鳴",
"ㄌㄨㄉㄧㄢ":"露點/露電",
"ㄌㄨㄉㄧㄥ":"鹿頂/盝頂",
"ㄌㄨㄉㄨㄟ":"路隊",
"ㄌㄨㄉㄨㄢ":"路段",
"ㄌㄨㄉㄨㄣ":"魯鈍/鹵鈍",
"ㄌㄨㄊㄧㄠ":"路條/爐條",
"ㄌㄨㄊㄧㄢ":"露天/陸田",
"ㄌㄨㄊㄨㄥ":"盧同",
"ㄌㄨㄋㄢㄗ":"魯男子",
"ㄌㄨㄌㄧㄝ":"臚列",
"ㄌㄨㄌㄧㄢ":"魯連/蘆簾",
"ㄌㄨㄌㄧㄤ":"陸梁/祿糧",
"ㄌㄨㄌㄧㄥ":"盧令",
"ㄌㄨㄌㄨㄣ":"魯論/盧綸",
"ㄌㄨㄌㄩㄝ":"擄掠",
"ㄌㄨㄍㄢㄕ":"爐甘石",
"ㄌㄨㄍㄨㄛ":"路過/魯國",
"ㄌㄨㄍㄨㄟ":"路軌",
"ㄌㄨㄍㄨㄢ":"蘆管/錄灌",
"ㄌㄨㄍㄨㄥ":"爐公/錄供",
"ㄌㄨㄎㄨㄞ":"鱸鱠",
"ㄌㄨㄎㄨㄟ":"露葵",
"ㄌㄨㄎㄨㄤ":"路況",
"ㄌㄨㄏㄨㄚ":"蘆花/露華",
"ㄌㄨㄏㄨㄛ":"爐火/虜獲",
"ㄌㄨㄏㄨㄟ":"爐灰/蘆薈",
"ㄌㄨㄏㄨㄢ":"臚驩",
"ㄌㄨㄐㄧㄚ":"陸架/陸賈",
"ㄌㄨㄐㄧㄝ":"路劫",
"ㄌㄨㄐㄧㄠ":"路角/鹿角",
"ㄌㄨㄐㄧㄡ":"魯酒",
"ㄌㄨㄐㄧㄢ":"路檢/路肩",
"ㄌㄨㄐㄧㄣ":"鹿巾",
"ㄌㄨㄐㄧㄤ":"潞江/櫓槳",
"ㄌㄨㄐㄧㄥ":"路徑/路警",
"ㄌㄨㄐㄩㄣ":"陸軍",
"ㄌㄨㄑㄧㄠ":"陸橋/爐橋",
"ㄌㄨㄑㄧㄡ":"錄囚",
"ㄌㄨㄑㄧㄢ":"盧前/路籤",
"ㄌㄨㄑㄧㄣ":"魯禽",
"ㄌㄨㄑㄧㄤ":"顱腔",
"ㄌㄨㄑㄩㄢ":"盧犬",
"ㄌㄨㄒㄧㄚ":"滷蝦",
"ㄌㄨㄒㄧㄢ":"路線",
"ㄌㄨㄒㄧㄣ":"爐心",
"ㄌㄨㄒㄧㄤ":"祿相",
"ㄌㄨㄒㄧㄥ":"陸行",
"ㄌㄨㄒㄩㄣ":"魯迅",
"ㄌㄨㄓㄨㄥ":"路中",
"ㄌㄨㄔㄨㄟ":"鑪捶",
"ㄌㄨㄔㄨㄢ":"臚傳/麓川",
"ㄌㄨㄕㄨㄟ":"露水/鹵水",
"ㄌㄨㄖㄨㄥ":"鹿茸",
"ㄌㄨㄗㄨㄛ":"祿祚",
"ㄌㄨㄗㄨㄟ":"擄嘴",
"ㄌㄨㄙㄨㄣ":"蘆筍",
"ㄌㄨㄙㄨㄥ":"魯頌",
"ㄌㄨㄛㄅㄚ":"洛巴",
"ㄌㄨㄛㄅㄛ":"蘿蔔/落薄",
"ㄌㄨㄛㄅㄞ":"落敗/羅拜",
"ㄌㄨㄛㄅㄟ":"螺杯/螺貝",
"ㄌㄨㄛㄅㄠ":"落保",
"ㄌㄨㄛㄅㄣ":"裸奔",
"ㄌㄨㄛㄅㄤ":"落榜",
"ㄌㄨㄛㄅㄧ":"落筆",
"ㄌㄨㄛㄅㄨ":"羅布/羅捕",
"ㄌㄨㄛㄆㄚ":"羅帕",
"ㄌㄨㄛㄆㄟ":"羅帔",
"ㄌㄨㄛㄆㄢ":"羅盤/落盤",
"ㄌㄨㄛㄇㄚ":"落馬/駱馬",
"ㄌㄨㄛㄇㄛ":"落寞/落莫",
"ㄌㄨㄛㄇㄞ":"落脈/裸麥",
"ㄌㄨㄛㄇㄠ":"羅帽/螺帽",
"ㄌㄨㄛㄇㄨ":"落幕/螺母",
"ㄌㄨㄛㄈㄚ":"落髮",
"ㄌㄨㄛㄈㄨ":"騾夫/羅敷",
"ㄌㄨㄛㄉㄜ":"落得",
"ㄌㄨㄛㄉㄞ":"螺黛",
"ㄌㄨㄛㄉㄢ":"落單/落膽",
"ㄌㄨㄛㄉㄤ":"洛黨",
"ㄌㄨㄛㄉㄧ":"落地/落第",
"ㄌㄨㄛㄊㄞ":"落臺",
"ㄌㄨㄛㄊㄠ":"落套",
"ㄌㄨㄛㄊㄡ":"絡頭",
"ㄌㄨㄛㄊㄧ":"裸體/落題",
"ㄌㄨㄛㄋㄢ":"落難",
"ㄌㄨㄛㄋㄨ":"酪奴",
"ㄌㄨㄛㄋㄩ":"裸女",
"ㄌㄨㄛㄌㄜ":"羅勒",
"ㄌㄨㄛㄌㄟ":"落淚/落雷",
"ㄌㄨㄛㄌㄧ":"酪梨",
"ㄌㄨㄛㄌㄨ":"裸露/落路",
"ㄌㄨㄛㄌㄩ":"羅縷/覶縷",
"ㄌㄨㄛㄍㄡ":"落彀",
"ㄌㄨㄛㄍㄤ":"騾綱",
"ㄌㄨㄛㄍㄨ":"鑼鼓",
"ㄌㄨㄛㄎㄡ":"羅口",
"ㄌㄨㄛㄏㄜ":"漯河",
"ㄌㄨㄛㄏㄠ":"螺號",
"ㄌㄨㄛㄏㄡ":"落後",
"ㄌㄨㄛㄏㄢ":"羅漢",
"ㄌㄨㄛㄏㄨ":"落戶",
"ㄌㄨㄛㄐㄧ":"邏輯/落籍",
"ㄌㄨㄛㄐㄩ":"落局/螺距",
"ㄌㄨㄛㄑㄧ":"羅綺",
"ㄌㄨㄛㄒㄩ":"絡續",
"ㄌㄨㄛㄓㄞ":"羅齋",
"ㄌㄨㄛㄓㄠ":"落照/裸照",
"ㄌㄨㄛㄔㄗ":"落翅仔",
"ㄌㄨㄛㄔㄚ":"落差/羅叉",
"ㄌㄨㄛㄔㄜ":"騾車",
"ㄌㄨㄛㄔㄠ":"落潮/落鈔",
"ㄌㄨㄛㄔㄣ":"落塵",
"ㄌㄨㄛㄔㄥ":"落成/裸裎",
"ㄌㄨㄛㄕㄡ":"落手",
"ㄌㄨㄛㄕㄢ":"羅扇",
"ㄌㄨㄛㄕㄣ":"裸身/洛神",
"ㄌㄨㄛㄕㄥ":"落生",
"ㄌㄨㄛㄕㄨ":"洛書",
"ㄌㄨㄛㄖㄨ":"羅襦/酪乳",
"ㄌㄨㄛㄗㄠ":"裸藻",
"ㄌㄨㄛㄗㄤ":"臝葬",
"ㄌㄨㄛㄗㄨ":"落足/羅祖",
"ㄌㄨㄛㄘㄠ":"落草",
"ㄌㄨㄛㄙㄜ":"落色",
"ㄌㄨㄛㄙㄨ":"囉囌/酪素",
"ㄌㄨㄛㄧㄚ":"裸芽",
"ㄌㄨㄛㄧㄝ":"落葉/裸葉",
"ㄌㄨㄛㄧㄣ":"羅隱",
"ㄌㄨㄛㄧㄤ":"洛陽",
"ㄌㄨㄛㄧㄥ":"落英/羅纓",
"ㄌㄨㄛㄨㄚ":"羅襪",
"ㄌㄨㄛㄨㄟ":"絡緯/羅幃",
"ㄌㄨㄛㄨㄢ":"螺烷",
"ㄌㄨㄛㄨㄣ":"落文/羅紋",
"ㄌㄨㄛㄨㄤ":"落網/羅網",
"ㄌㄨㄢㄅㄞ":"卵白",
"ㄌㄨㄢㄅㄤ":"亂邦",
"ㄌㄨㄢㄆㄠ":"亂跑/卵泡",
"ㄌㄨㄢㄇㄚ":"亂碼/亂麻",
"ㄌㄨㄢㄈㄥ":"鸞鳳",
"ㄌㄨㄢㄉㄞ":"卵袋",
"ㄌㄨㄢㄉㄠ":"亂道/亂刀",
"ㄌㄨㄢㄉㄡ":"亂抖",
"ㄌㄨㄢㄉㄤ":"亂黨",
"ㄌㄨㄢㄊㄡ":"亂頭",
"ㄌㄨㄢㄊㄢ":"亂彈",
"ㄌㄨㄢㄊㄧ":"亂啼",
"ㄌㄨㄢㄋㄠ":"亂鬧",
"ㄌㄨㄢㄋㄤ":"卵囊",
"ㄌㄨㄢㄋㄧ":"亂逆",
"ㄌㄨㄢㄌㄞ":"亂來",
"ㄌㄨㄢㄌㄧ":"亂離",
"ㄌㄨㄢㄌㄨ":"鸞路",
"ㄌㄨㄢㄍㄜ":"臠割",
"ㄌㄨㄢㄍㄞ":"亂蓋",
"ㄌㄨㄢㄏㄜ":"鸞和/卵核",
"ㄌㄨㄢㄐㄧ":"亂機/亂紀",
"ㄌㄨㄢㄐㄩ":"亂局",
"ㄌㄨㄢㄓㄢ":"亂戰",
"ㄌㄨㄢㄓㄣ":"亂真",
"ㄌㄨㄢㄔㄜ":"鸞車",
"ㄌㄨㄢㄔㄠ":"卵巢",
"ㄌㄨㄢㄔㄢ":"亂顫",
"ㄌㄨㄢㄔㄣ":"亂臣",
"ㄌㄨㄢㄕㄡ":"亂首",
"ㄌㄨㄢㄕㄥ":"卵生/孿生",
"ㄌㄨㄢㄕㄨ":"欒樹",
"ㄌㄨㄢㄗㄚ":"亂雜",
"ㄌㄨㄢㄙㄨ":"亂俗",
"ㄌㄨㄢㄧㄢ":"亂言",
"ㄌㄨㄢㄩㄢ":"亂源",
"ㄌㄨㄢㄩㄥ":"亂用",
"ㄌㄨㄣㄅㄚ":"倫巴",
"ㄌㄨㄣㄅㄞ":"淪敗",
"ㄌㄨㄣㄅㄢ":"輪班",
"ㄌㄨㄣㄅㄧ":"倫比",
"ㄌㄨㄣㄆㄢ":"輪盤",
"ㄌㄨㄣㄆㄧ":"倫匹",
"ㄌㄨㄣㄇㄥ":"論孟",
"ㄌㄨㄣㄇㄨ":"輪牧",
"ㄌㄨㄣㄈㄟ":"綸扉",
"ㄌㄨㄣㄈㄢ":"輪番",
"ㄌㄨㄣㄈㄨ":"輪輻/綸綍",
"ㄌㄨㄣㄉㄞ":"輪帶",
"ㄌㄨㄣㄉㄠ":"論道",
"ㄌㄨㄣㄉㄧ":"論敵/輪遞",
"ㄌㄨㄣㄉㄨ":"輪渡",
"ㄌㄨㄣㄊㄞ":"輪胎/輪臺",
"ㄌㄨㄣㄊㄡ":"論頭",
"ㄌㄨㄣㄊㄢ":"論壇",
"ㄌㄨㄣㄊㄧ":"論題/輪替",
"ㄌㄨㄣㄋㄢ":"論難",
"ㄌㄨㄣㄌㄟ":"倫類",
"ㄌㄨㄣㄌㄧ":"倫理/論理",
"ㄌㄨㄣㄍㄠ":"論告",
"ㄌㄨㄣㄍㄡ":"輪鉤",
"ㄌㄨㄣㄍㄨ":"輪轂",
"ㄌㄨㄣㄎㄡ":"論口",
"ㄌㄨㄣㄏㄥ":"論衡",
"ㄌㄨㄣㄏㄨ":"輪互",
"ㄌㄨㄣㄐㄧ":"輪機/倫紀",
"ㄌㄨㄣㄐㄩ":"論據/輪距",
"ㄌㄨㄣㄑㄧ":"淪棄",
"ㄌㄨㄣㄒㄧ":"輪系",
"ㄌㄨㄣㄒㄩ":"倫序/淪胥",
"ㄌㄨㄣㄓㄚ":"輪鍘",
"ㄌㄨㄣㄓㄡ":"輪軸",
"ㄌㄨㄣㄓㄢ":"論戰",
"ㄌㄨㄣㄓㄥ":"論政/論證",
"ㄌㄨㄣㄓㄨ":"論著",
"ㄌㄨㄣㄔㄜ":"輪掣",
"ㄌㄨㄣㄔㄡ":"倫儔",
"ㄌㄨㄣㄔㄤ":"倫常/輪唱",
"ㄌㄨㄣㄔㄨ":"論處",
"ㄌㄨㄣㄕㄥ":"輪生",
"ㄌㄨㄣㄕㄨ":"論述",
"ㄌㄨㄣㄖㄣ":"輪人",
"ㄌㄨㄣㄗㄞ":"輪栽",
"ㄌㄨㄣㄗㄠ":"輪藻",
"ㄌㄨㄣㄗㄢ":"論贊",
"ㄌㄨㄣㄗㄤ":"論藏",
"ㄌㄨㄣㄘㄞ":"掄材",
"ㄌㄨㄣㄙㄤ":"淪喪",
"ㄌㄨㄣㄧㄣ":"綸音",
"ㄌㄨㄣㄨㄣ":"論文",
"ㄌㄨㄣㄨㄤ":"淪亡/輪王",
"ㄌㄨㄣㄩㄢ":"掄元",
"ㄌㄨㄥㄅㄛ":"龍柏",
"ㄌㄨㄥㄅㄠ":"聾胞",
"ㄌㄨㄥㄅㄢ":"籠絆",
"ㄌㄨㄥㄅㄧ":"癃閉/隆鼻",
"ㄌㄨㄥㄆㄞ":"龍牌",
"ㄌㄨㄥㄆㄠ":"龍袍",
"ㄌㄨㄥㄇㄚ":"龍馬",
"ㄌㄨㄥㄇㄛ":"礱磨",
"ㄌㄨㄥㄇㄞ":"龍脈",
"ㄌㄨㄥㄇㄟ":"龍媒",
"ㄌㄨㄥㄇㄣ":"龍門",
"ㄌㄨㄥㄇㄨ":"龍目/壟墓",
"ㄌㄨㄥㄈㄟ":"龍飛",
"ㄌㄨㄥㄈㄥ":"龍鳳",
"ㄌㄨㄥㄈㄨ":"瀧夫",
"ㄌㄨㄥㄉㄜ":"隆德",
"ㄌㄨㄥㄉㄢ":"龍膽",
"ㄌㄨㄥㄉㄥ":"龍燈",
"ㄌㄨㄥㄉㄧ":"龍邸",
"ㄌㄨㄥㄊㄚ":"龍榻",
"ㄌㄨㄥㄊㄠ":"龍套/龍韜",
"ㄌㄨㄥㄊㄡ":"龍頭/籠頭",
"ㄌㄨㄥㄊㄢ":"龍潭",
"ㄌㄨㄥㄊㄧ":"龍體/隆替",
"ㄌㄨㄥㄊㄨ":"龍圖",
"ㄌㄨㄥㄋㄠ":"龍腦",
"ㄌㄨㄥㄋㄩ":"龍女",
"ㄌㄨㄥㄌㄡ":"龍樓",
"ㄌㄨㄥㄌㄧ":"籠利/龍漦",
"ㄌㄨㄥㄌㄨ":"瀧漉",
"ㄌㄨㄥㄍㄞ":"籠蓋",
"ㄌㄨㄥㄍㄡ":"壟溝",
"ㄌㄨㄥㄍㄨ":"龍骨",
"ㄌㄨㄥㄎㄡ":"龍口",
"ㄌㄨㄥㄎㄤ":"礱糠",
"ㄌㄨㄥㄏㄢ":"隆寒",
"ㄌㄨㄥㄏㄨ":"龍虎/嚨胡",
"ㄌㄨㄥㄐㄧ":"隆極/龍集",
"ㄌㄨㄥㄐㄩ":"龍具/龍駒",
"ㄌㄨㄥㄑㄧ":"隆起/龍旗",
"ㄌㄨㄥㄒㄩ":"龍鬚",
"ㄌㄨㄥㄓㄠ":"籠罩/龍爪",
"ㄌㄨㄥㄓㄡ":"龍舟",
"ㄌㄨㄥㄓㄢ":"龍戰",
"ㄌㄨㄥㄓㄤ":"龍章/籠仗",
"ㄌㄨㄥㄓㄥ":"隆正",
"ㄌㄨㄥㄓㄨ":"龍珠",
"ㄌㄨㄥㄔㄤ":"隆昌",
"ㄌㄨㄥㄔㄥ":"龍城",
"ㄌㄨㄥㄕㄚ":"龍沙",
"ㄌㄨㄥㄕㄜ":"龍蛇/龍麝",
"ㄌㄨㄥㄕㄡ":"龍首",
"ㄌㄨㄥㄕㄢ":"龍山",
"ㄌㄨㄥㄕㄣ":"攏身/龍神",
"ㄌㄨㄥㄕㄥ":"隆盛",
"ㄌㄨㄥㄕㄨ":"龍樹/龍疏",
"ㄌㄨㄥㄖㄣ":"聾人",
"ㄌㄨㄥㄖㄨ":"隆乳",
"ㄌㄨㄥㄗㄤ":"龍藏",
"ㄌㄨㄥㄙㄨ":"聾俗",
"ㄌㄨㄥㄧㄚ":"龍牙/聾啞",
"ㄌㄨㄥㄧㄠ":"隆窯",
"ㄌㄨㄥㄧㄢ":"龍眼/龍顏",
"ㄌㄨㄥㄧㄣ":"龍吟",
"ㄌㄨㄥㄧㄤ":"龍陽/龍洋",
"ㄌㄨㄥㄨㄛ":"隆渥",
"ㄌㄨㄥㄨㄟ":"龍位/龍威",
"ㄌㄨㄥㄨㄣ":"龍文/龍紋",
"ㄌㄨㄥㄨㄤ":"龍王",
"ㄌㄨㄥㄩㄢ":"龍淵",
"ㄌㄨㄥㄩㄣ":"隆運",
"ㄌㄨㄧㄍㄨ":"魯義姑",
"ㄌㄨㄧㄣㄕ":"錄音室",
"ㄌㄨㄨㄕㄧ":"路無拾遺",
"ㄌㄩㄅㄠㄕ":"綠寶石",
"ㄌㄩㄅㄧㄣ":"綠鬢",
"ㄌㄩㄅㄧㄥ":"履冰",
"ㄌㄩㄇㄠㄗ":"綠帽子",
"ㄌㄩㄉㄧㄢ":"旅店",
"ㄌㄩㄉㄨㄢ":"履端",
"ㄌㄩㄉㄨㄥ":"律動",
"ㄌㄩㄊㄧㄠ":"律條",
"ㄌㄩㄋㄧㄕ":"綠泥石",
"ㄌㄩㄋㄧㄢ":"屢年/驢年",
"ㄌㄩㄌㄧㄓ":"律曆志",
"ㄌㄩㄌㄧㄝ":"膂烈/褸裂",
"ㄌㄩㄌㄧㄢ":"驢臉",
"ㄌㄩㄌㄧㄣ":"綠林",
"ㄌㄩㄌㄧㄥ":"律令",
"ㄌㄩㄌㄨㄛ":"驢騾",
"ㄌㄩㄌㄨㄣ":"氯綸",
"ㄌㄩㄍㄨㄟ":"履癸",
"ㄌㄩㄍㄨㄢ":"旅館/鋁罐",
"ㄌㄩㄍㄨㄤ":"呂光",
"ㄌㄩㄎㄨㄟ":"律魁",
"ㄌㄩㄎㄨㄥ":"屢空",
"ㄌㄩㄏㄨㄚ":"綠化/氯化",
"ㄌㄩㄏㄨㄢ":"慮患",
"ㄌㄩㄐㄧㄝ":"縷解",
"ㄌㄩㄐㄧㄥ":"呂靜",
"ㄌㄩㄐㄩㄗ":"驢駒子",
"ㄌㄩㄑㄧㄝ":"律切",
"ㄌㄩㄑㄧㄢ":"綠錢",
"ㄌㄩㄑㄧㄣ":"綠琴",
"ㄌㄩㄑㄩㄢ":"氯醛",
"ㄌㄩㄒㄧㄢ":"綠線",
"ㄌㄩㄒㄧㄣ":"濾心/履新",
"ㄌㄩㄒㄧㄤ":"呂相/閭巷",
"ㄌㄩㄒㄧㄥ":"旅行/履行",
"ㄌㄩㄓㄨㄕ":"綠柱石",
"ㄌㄩㄓㄨㄥ":"郘鐘",
"ㄌㄩㄔㄨㄤ":"鋁窗/綠窗",
"ㄌㄩㄕㄨㄟ":"綠水/氯水",
"ㄌㄩㄕㄨㄣ":"旅順",
"ㄌㄩㄕㄨㄤ":"履霜",
"ㄌㄩㄗㄨㄛ":"閭左",
"ㄌㄩㄗㄨㄟ":"濾嘴",
"ㄌㄩㄗㄨㄥ":"律宗",
"ㄌㄩㄙㄨㄢ":"氯酸",
"ㄌㄩㄝㄇㄞ":"略賣",
"ㄌㄩㄝㄇㄟ":"掠美",
"ㄌㄩㄝㄉㄧ":"略地",
"ㄌㄩㄝㄊㄡ":"掠頭",
"ㄌㄩㄝㄊㄨ":"略圖",
"ㄌㄩㄝㄍㄨ":"略估",
"ㄌㄩㄝㄑㄩ":"掠取",
"ㄌㄩㄝㄕㄚ":"掠殺",
"ㄌㄩㄝㄧㄡ":"略誘",
"ㄌㄩㄝㄧㄥ":"掠影",
"ㄌㄩㄝㄨㄟ":"略微",
"ㄌㄩㄢㄅㄧ":"攣躄",
"ㄌㄩㄢㄨㄢ":"攣踠",
"ㄌㄩㄧㄒㄧ":"氯乙烯",
"ㄌㄩㄩㄓㄤ":"綠玉杖",
"ㄍㄚㄅㄚㄦ":"嘎巴兒",
"ㄍㄚㄉㄧㄠ":"嘎調",
"ㄍㄚㄌㄚㄦ":"旮旯兒",
"ㄍㄚㄍㄚㄦ":"嘎嘎兒",
"ㄍㄚㄐㄧㄣ":"軋進",
"ㄍㄚㄒㄧㄚ":"噶廈",
"ㄍㄚㄓㄚㄦ":"嘎渣兒",
"ㄍㄜㄅㄛㄦ":"隔帛兒",
"ㄍㄜㄅㄧㄝ":"個別/各別",
"ㄍㄜㄇㄧㄢ":"革面",
"ㄍㄜㄇㄧㄥ":"革命/革名",
"ㄍㄜㄉㄚㄕ":"疙瘩事",
"ㄍㄜㄉㄜㄕ":"哥德式",
"ㄍㄜㄉㄡㄦ":"虼蚪兒",
"ㄍㄜㄉㄥㄦ":"格磴兒",
"ㄍㄜㄉㄧㄠ":"格調/歌調",
"ㄍㄜㄉㄨㄢ":"隔斷/割斷",
"ㄍㄜㄊㄧㄢ":"隔天",
"ㄍㄜㄊㄧㄥ":"歌廳",
"ㄍㄜㄊㄨㄟ":"革退",
"ㄍㄜㄌㄚㄗ":"肐拉子",
"ㄍㄜㄌㄧㄝ":"割裂",
"ㄍㄜㄌㄧㄡ":"革留/割綹",
"ㄍㄜㄌㄧㄣ":"隔鄰",
"ㄍㄜㄌㄨㄛ":"閣落/紇絡",
"ㄍㄜㄌㄨㄣ":"噶倫",
"ㄍㄜㄍㄨㄛ":"各國",
"ㄍㄜㄎㄨㄟ":"閣揆",
"ㄍㄜㄏㄨㄛ":"隔火",
"ㄍㄜㄏㄨㄟ":"蛤灰",
"ㄍㄜㄏㄨㄥ":"葛洪",
"ㄍㄜㄐㄧㄝ":"各界/蛤蚧",
"ㄍㄜㄐㄧㄠ":"割膠",
"ㄍㄜㄐㄧㄡ":"革舊/肐揪",
"ㄍㄜㄐㄧㄢ":"隔間",
"ㄍㄜㄐㄧㄣ":"葛巾/割襟",
"ㄍㄜㄐㄩㄝ":"隔絕/歌訣",
"ㄍㄜㄑㄧㄝ":"割切",
"ㄍㄜㄑㄧㄢ":"擱淺/擱錢",
"ㄍㄜㄑㄧㄥ":"割情",
"ㄍㄜㄒㄧㄚ":"閣下/擱下",
"ㄍㄜㄒㄧㄣ":"革新/格心",
"ㄍㄜㄒㄧㄤ":"各相/各項",
"ㄍㄜㄒㄧㄥ":"個性/歌星",
"ㄍㄜㄒㄩㄢ":"葛玄",
"ㄍㄜㄓㄚㄦ":"疙渣兒",
"ㄍㄜㄓㄧㄘ":"各執一詞",
"ㄍㄜㄓㄨㄛ":"胳肢窩",
"ㄍㄜㄓㄨㄥ":"各種/個中",
"ㄍㄜㄔㄤㄗ":"割腸子",
"ㄍㄜㄔㄨㄟ":"歌吹",
"ㄍㄜㄕㄏㄚ":"戈什哈",
"ㄍㄜㄖㄖㄜ":"隔日熱",
"ㄍㄜㄖㄨㄥ":"革容",
"ㄍㄜㄗㄅㄨ":"格子布",
"ㄍㄜㄗㄒㄧ":"歌仔戲",
"ㄍㄜㄗㄧㄢ":"格子眼",
"ㄍㄜㄗㄨㄛ":"隔座",
"ㄍㄜㄘㄨㄥ":"隔從/茖蔥",
"ㄍㄜㄙㄨㄥ":"歌頌/歌誦",
"ㄍㄜㄦㄇㄣ":"哥兒們",
"ㄍㄜㄧㄣㄓ":"隔音紙",
"ㄍㄜㄧㄣㄕ":"隔音室",
"ㄍㄜㄨㄎㄨ":"歌五褲",
"ㄍㄜㄨㄐㄧ":"歌舞伎",
"ㄍㄜㄨㄐㄩ":"歌舞劇",
"ㄍㄜㄨㄓㄓ":"格物致知",
"ㄍㄜㄨㄔㄤ":"歌舞場",
"ㄍㄞㄅㄧㄢ":"改變/改編",
"ㄍㄞㄆㄧㄢ":"鈣片",
"ㄍㄞㄇㄧㄥ":"改名",
"ㄍㄞㄉㄧㄠ":"改掉",
"ㄍㄞㄉㄧㄢ":"改點/戤典",
"ㄍㄞㄉㄧㄥ":"改定/改訂",
"ㄍㄞㄉㄨㄥ":"改動",
"ㄍㄞㄊㄧㄢ":"改天",
"ㄍㄞㄊㄨㄥ":"該通",
"ㄍㄞㄋㄧㄢ":"概念",
"ㄍㄞㄌㄧㄢ":"蓋臉",
"ㄍㄞㄌㄧㄤ":"改良",
"ㄍㄞㄌㄨㄣ":"概論",
"ㄍㄞㄌㄩㄝ":"概略",
"ㄍㄞㄍㄨㄚ":"概括/賅括",
"ㄍㄞㄍㄨㄛ":"改過",
"ㄍㄞㄍㄨㄢ":"改觀/該管",
"ㄍㄞㄎㄨㄤ":"概況",
"ㄍㄞㄏㄨㄛ":"蓋火",
"ㄍㄞㄏㄨㄟ":"改悔",
"ㄍㄞㄏㄨㄢ":"改換",
"ㄍㄞㄐㄧㄚ":"改嫁",
"ㄍㄞㄐㄧㄝ":"改節",
"ㄍㄞㄐㄧㄠ":"改醮",
"ㄍㄞㄐㄧㄡ":"該究",
"ㄍㄞㄐㄧㄢ":"改建",
"ㄍㄞㄐㄧㄣ":"改進",
"ㄍㄞㄑㄧㄢ":"垓前/蓋愆",
"ㄍㄞㄑㄩㄝ":"蓋闕",
"ㄍㄞㄒㄧㄚ":"該下/垓下",
"ㄍㄞㄒㄧㄝ":"改寫",
"ㄍㄞㄒㄧㄢ":"蓋仙",
"ㄍㄞㄒㄧㄣ":"垓心",
"ㄍㄞㄒㄩㄝ":"改削",
"ㄍㄞㄒㄩㄢ":"改選/蓋喧",
"ㄍㄞㄓㄨㄤ":"改裝/改妝",
"ㄍㄞㄕㄨㄛ":"概說",
"ㄍㄞㄖㄨㄥ":"改容",
"ㄍㄞㄗㄨㄟ":"改嘴",
"ㄍㄞㄘㄨㄛ":"改錯",
"ㄍㄞㄘㄨㄢ":"改竄",
"ㄍㄞㄙㄨㄟ":"改歲",
"ㄍㄞㄙㄨㄢ":"概算",
"ㄍㄟㄌㄧㄢ":"給臉",
"ㄍㄟㄐㄧㄚ":"給價",
"ㄍㄠㄅㄧㄝ":"告別",
"ㄍㄠㄅㄧㄠ":"高標",
"ㄍㄠㄅㄧㄢ":"告便/告窆",
"ㄍㄠㄅㄧㄥ":"告病/告稟",
"ㄍㄠㄆㄧㄠ":"高票",
"ㄍㄠㄆㄧㄣ":"高品/高頻",
"ㄍㄠㄆㄧㄥ":"高平",
"ㄍㄠㄇㄛㄦ":"高末兒",
"ㄍㄠㄇㄠㄗ":"高帽子",
"ㄍㄠㄇㄧㄠ":"高妙/告廟",
"ㄍㄠㄇㄧㄢ":"高棉",
"ㄍㄠㄇㄧㄥ":"高明/高名",
"ㄍㄠㄉㄧㄠ":"高調",
"ㄍㄠㄉㄧㄡ":"搞丟",
"ㄍㄠㄉㄧㄢ":"糕點",
"ㄍㄠㄉㄧㄥ":"搞定/郜鼎",
"ㄍㄠㄉㄨㄢ":"高段",
"ㄍㄠㄉㄨㄥ":"搞懂",
"ㄍㄠㄊㄧㄝ":"高鐵",
"ㄍㄠㄊㄧㄠ":"高調",
"ㄍㄠㄊㄧㄢ":"告天",
"ㄍㄠㄊㄨㄛ":"搞妥/告託",
"ㄍㄠㄊㄨㄟ":"告退",
"ㄍㄠㄋㄧㄢ":"高年",
"ㄍㄠㄋㄨㄥ":"高農",
"ㄍㄠㄌㄧㄓ":"高麗紙",
"ㄍㄠㄌㄧㄕ":"高力士",
"ㄍㄠㄌㄧㄤ":"高粱/膏粱",
"ㄍㄠㄌㄧㄥ":"高齡/高領",
"ㄍㄠㄌㄨㄢ":"搞亂",
"ㄍㄠㄌㄨㄣ":"高論",
"ㄍㄠㄍㄜㄗ":"高個子",
"ㄍㄠㄍㄜㄦ":"高個兒",
"ㄍㄠㄍㄤㄦ":"高岡兒",
"ㄍㄠㄍㄨㄞ":"搞怪",
"ㄍㄠㄍㄨㄟ":"高貴/搞鬼",
"ㄍㄠㄍㄨㄢ":"高官/告官",
"ㄍㄠㄍㄨㄥ":"高工/高功",
"ㄍㄠㄎㄨㄤ":"高曠",
"ㄍㄠㄎㄨㄥ":"高空",
"ㄍㄠㄏㄨㄛ":"搞活/膏火",
"ㄍㄠㄏㄨㄟ":"高會",
"ㄍㄠㄏㄨㄢ":"高喚",
"ㄍㄠㄏㄨㄤ":"膏肓",
"ㄍㄠㄐㄧㄚ":"高價/告假",
"ㄍㄠㄐㄧㄝ":"高階/告解",
"ㄍㄠㄐㄧㄠ":"高教",
"ㄍㄠㄐㄧㄡ":"高就",
"ㄍㄠㄐㄧㄢ":"高見/稿件",
"ㄍㄠㄐㄧㄥ":"告警",
"ㄍㄠㄐㄩㄝ":"告絕",
"ㄍㄠㄐㄩㄢ":"膏捐",
"ㄍㄠㄐㄩㄣ":"高峻/告竣",
"ㄍㄠㄑㄧㄠ":"高蹺/高峭",
"ㄍㄠㄑㄧㄡ":"羔裘",
"ㄍㄠㄑㄧㄤ":"高強/高腔",
"ㄍㄠㄑㄧㄥ":"高情/告罄",
"ㄍㄠㄑㄩㄢ":"高顴",
"ㄍㄠㄒㄧㄚ":"高下",
"ㄍㄠㄒㄧㄠ":"搞笑/高校",
"ㄍㄠㄒㄧㄢ":"高賢",
"ㄍㄠㄒㄧㄣ":"高薪/高心",
"ㄍㄠㄒㄧㄤ":"高香/告饗",
"ㄍㄠㄒㄧㄥ":"高興/高行",
"ㄍㄠㄒㄩㄝ":"膏血",
"ㄍㄠㄒㄩㄢ":"高懸/高軒",
"ㄍㄠㄓㄠㄦ":"高著兒",
"ㄍㄠㄓㄧㄨ":"告知義務",
"ㄍㄠㄓㄨㄛ":"高卓",
"ㄍㄠㄓㄨㄤ":"告狀/高壯",
"ㄍㄠㄓㄨㄥ":"高中/高中",
"ㄍㄠㄓㄨㄩ":"告珠玉",
"ㄍㄠㄕㄓㄥ":"高氏症",
"ㄍㄠㄕㄢㄩ":"高山語",
"ㄍㄠㄕㄨㄤ":"高爽",
"ㄍㄠㄖㄨㄣ":"膏潤",
"ㄍㄠㄗㄨㄛ":"高作/高坐",
"ㄍㄠㄗㄨㄟ":"告罪",
"ㄍㄠㄗㄨㄥ":"高蹤",
"ㄍㄠㄙㄕㄥ":"高四生",
"ㄍㄠㄙㄨㄥ":"高聳/告送",
"ㄍㄠㄦㄈㄨ":"高爾夫",
"ㄍㄠㄧㄔㄡ":"高一籌",
"ㄍㄡㄅㄧㄢ":"鉤邊",
"ㄍㄡㄅㄧㄥ":"詬病/構兵",
"ㄍㄡㄆㄧㄠ":"購票",
"ㄍㄡㄇㄧㄢ":"垢面/苟免",
"ㄍㄡㄇㄧㄣ":"覯閔/遘閔",
"ㄍㄡㄇㄧㄥ":"狗命",
"ㄍㄡㄉㄡㄗ":"狗豆子",
"ㄍㄡㄉㄨㄢ":"勾斷",
"ㄍㄡㄉㄨㄥ":"狗洞",
"ㄍㄡㄊㄨㄟ":"狗腿",
"ㄍㄡㄊㄨㄥ":"溝通/苟同",
"ㄍㄡㄌㄧㄡ":"勾留",
"ㄍㄡㄌㄧㄢ":"句廉/勾連",
"ㄍㄡㄍㄢㄗ":"鉤竿子",
"ㄍㄡㄍㄨㄢ":"狗官/勾管",
"ㄍㄡㄍㄨㄥ":"構工",
"ㄍㄡㄏㄨㄚ":"勾畫",
"ㄍㄡㄏㄨㄛ":"苟活/篝火",
"ㄍㄡㄏㄨㄟ":"構會",
"ㄍㄡㄏㄨㄢ":"勾喚",
"ㄍㄡㄏㄨㄣ":"勾魂",
"ㄍㄡㄐㄧㄚ":"構架",
"ㄍㄡㄐㄧㄝ":"勾結",
"ㄍㄡㄐㄧㄠ":"鉤校",
"ㄍㄡㄐㄧㄢ":"構建/句踐",
"ㄍㄡㄐㄧㄣ":"夠勁",
"ㄍㄡㄐㄧㄥ":"構精",
"ㄍㄡㄐㄩㄝ":"勾決",
"ㄍㄡㄐㄩㄣ":"勾軍",
"ㄍㄡㄑㄧㄗ":"枸杞子",
"ㄍㄡㄑㄧㄝ":"苟且",
"ㄍㄡㄑㄧㄡ":"購求",
"ㄍㄡㄑㄧㄢ":"溝塹/勾牽",
"ㄍㄡㄑㄧㄤ":"夠嗆",
"ㄍㄡㄑㄧㄥ":"勾情",
"ㄍㄡㄑㄩㄢ":"苟全",
"ㄍㄡㄒㄧㄠ":"勾消/勾銷",
"ㄍㄡㄒㄧㄢ":"構陷/搆陷",
"ㄍㄡㄒㄧㄣ":"構釁/搆釁",
"ㄍㄡㄒㄧㄤ":"構想",
"ㄍㄡㄒㄧㄥ":"構形",
"ㄍㄡㄒㄩㄢ":"鉤玄",
"ㄍㄡㄒㄩㄥ":"狗熊",
"ㄍㄡㄓㄨㄛ":"勾捉",
"ㄍㄡㄓㄨㄞ":"勾拽",
"ㄍㄡㄓㄨㄟ":"勾追",
"ㄍㄡㄓㄨㄥ":"彀中",
"ㄍㄡㄔㄨㄢ":"勾串",
"ㄍㄡㄔㄨㄥ":"鉤蟲",
"ㄍㄡㄖㄨㄥ":"苟容",
"ㄍㄡㄘㄈㄚ":"構詞法",
"ㄍㄡㄘㄨㄣ":"苟存",
"ㄍㄡㄙㄖㄣ":"勾死人",
"ㄍㄡㄙㄨㄛ":"鉤索",
"ㄍㄡㄙㄨㄟ":"詬誶",
"ㄍㄡㄢㄧㄩ":"苟安一隅",
"ㄍㄡㄧㄑㄧ":"夠義氣",
"ㄍㄡㄨㄉㄞ":"購物袋",
"ㄍㄢㄅㄥㄦ":"乾繃兒",
"ㄍㄢㄅㄧㄝ":"乾癟",
"ㄍㄢㄅㄧㄥ":"乾冰",
"ㄍㄢㄆㄧㄢ":"乾片",
"ㄍㄢㄇㄛㄕ":"敢莫是",
"ㄍㄢㄇㄧㄢ":"乾麵",
"ㄍㄢㄉㄧㄝ":"乾爹",
"ㄍㄢㄉㄧㄠ":"幹掉",
"ㄍㄢㄉㄧㄢ":"感電/紺殿",
"ㄍㄢㄉㄨㄥ":"感動",
"ㄍㄢㄊㄢㄘ":"感嘆詞",
"ㄍㄢㄊㄤㄦ":"趕趟兒",
"ㄍㄢㄊㄧㄢ":"甘甜",
"ㄍㄢㄊㄨㄥ":"感通",
"ㄍㄢㄋㄧㄠ":"干鳥",
"ㄍㄢㄋㄧㄢ":"趕年/感念",
"ㄍㄢㄋㄧㄤ":"乾娘",
"ㄍㄢㄋㄨㄢ":"乾暖",
"ㄍㄢㄋㄩㄦ":"乾女兒",
"ㄍㄢㄌㄢㄓ":"橄欖枝",
"ㄍㄢㄌㄧㄝ":"乾裂/甘冽",
"ㄍㄢㄌㄧㄠ":"幹了/乾料",
"ㄍㄢㄌㄧㄡ":"幹流/乾餾",
"ㄍㄢㄌㄧㄢ":"干連/幹練",
"ㄍㄢㄌㄧㄣ":"甘霖",
"ㄍㄢㄌㄧㄤ":"乾糧/乾量",
"ㄍㄢㄌㄨㄗ":"甘露子",
"ㄍㄢㄌㄨㄛ":"趕羅/甘羅",
"ㄍㄢㄌㄩㄝ":"幹略",
"ㄍㄢㄍㄨㄛ":"乾果/柑果",
"ㄍㄢㄍㄨㄢ":"感官/乾館",
"ㄍㄢㄍㄨㄤ":"感光",
"ㄍㄢㄍㄨㄥ":"趕工",
"ㄍㄢㄎㄨㄞ":"趕快",
"ㄍㄢㄎㄨㄟ":"感喟",
"ㄍㄢㄏㄨㄚ":"感化/乾花",
"ㄍㄢㄏㄨㄛ":"乾貨/幹活",
"ㄍㄢㄏㄨㄞ":"感懷",
"ㄍㄢㄏㄨㄟ":"趕會",
"ㄍㄢㄐㄧㄚ":"幹家",
"ㄍㄢㄐㄧㄝ":"乾結/甘結",
"ㄍㄢㄐㄧㄠ":"趕腳/甘蕉",
"ㄍㄢㄐㄧㄡ":"感舊",
"ㄍㄢㄐㄧㄣ":"趕緊/幹勁",
"ㄍㄢㄐㄧㄤ":"幹將/干將",
"ㄍㄢㄐㄧㄥ":"乾淨/乾井",
"ㄍㄢㄐㄩㄝ":"感覺",
"ㄍㄢㄐㄩㄣ":"桿菌",
"ㄍㄢㄑㄧㄝ":"敢且",
"ㄍㄢㄑㄧㄠ":"趕巧/乾喬",
"ㄍㄢㄑㄧㄡ":"干求",
"ㄍㄢㄑㄧㄢ":"乾虔",
"ㄍㄢㄑㄧㄣ":"乾親",
"ㄍㄢㄑㄧㄥ":"感情/敢情",
"ㄍㄢㄑㄩㄝ":"幹缺",
"ㄍㄢㄑㄩㄢ":"甘泉",
"ㄍㄢㄒㄧㄝ":"感謝",
"ㄍㄢㄒㄧㄠ":"乾笑",
"ㄍㄢㄒㄧㄡ":"甘休/干休",
"ㄍㄢㄒㄧㄢ":"幹線/甘限",
"ㄍㄢㄒㄧㄣ":"甘心/乾薪",
"ㄍㄢㄒㄧㄤ":"感想",
"ㄍㄢㄒㄧㄥ":"感性/感興",
"ㄍㄢㄒㄩㄣ":"感訓",
"ㄍㄢㄓㄌㄚ":"乾支剌",
"ㄍㄢㄓㄨㄟ":"桿錐",
"ㄍㄢㄓㄨㄢ":"感篆",
"ㄍㄢㄓㄨㄦ":"甘珠爾",
"ㄍㄢㄔㄤㄗ":"趕場子",
"ㄍㄢㄔㄨㄤ":"感愴",
"ㄍㄢㄕㄇㄠ":"趕時髦",
"ㄍㄢㄕㄌㄧ":"趕勢利",
"ㄍㄢㄕㄥㄗ":"乾生子",
"ㄍㄢㄕㄨㄟ":"甘水/泔水",
"ㄍㄢㄕㄨㄤ":"乾爽",
"ㄍㄢㄕㄩㄧ":"甘食褕衣",
"ㄍㄢㄗㄊㄡ":"杆子頭",
"ㄍㄢㄗㄊㄨ":"坩子土",
"ㄍㄢㄗㄘㄞ":"甘紫菜",
"ㄍㄢㄗㄜㄕ":"敢則是",
"ㄍㄢㄗㄨㄟ":"趕嘴",
"ㄍㄢㄘㄌㄚ":"乾茨臘",
"ㄍㄢㄘㄨㄟ":"乾脆/甘脆",
"ㄍㄢㄚㄋㄞ":"乾阿嬭",
"ㄍㄢㄧㄐㄧ":"乾衣機",
"ㄍㄢㄨㄤㄦ":"趕網兒",
"ㄍㄣㄇㄧㄠ":"根苗",
"ㄍㄣㄉㄧㄥ":"跟定",
"ㄍㄣㄌㄧㄡ":"根瘤",
"ㄍㄣㄍㄨㄢ":"跟官",
"ㄍㄣㄏㄨㄟ":"跟會",
"ㄍㄣㄐㄧㄠ":"根腳/跟腳",
"ㄍㄣㄐㄧㄡ":"根究",
"ㄍㄣㄐㄧㄢ":"跟監",
"ㄍㄣㄐㄧㄣ":"跟進",
"ㄍㄣㄐㄧㄥ":"根莖",
"ㄍㄣㄐㄩㄝ":"根絕",
"ㄍㄣㄑㄧㄢ":"跟前",
"ㄍㄣㄑㄧㄥ":"根情",
"ㄍㄣㄒㄧㄥ":"根性",
"ㄍㄣㄒㄩㄣ":"根尋",
"ㄍㄣㄓㄕㄨ":"根指數",
"ㄍㄣㄕㄨㄚ":"根刷",
"ㄍㄣㄗㄨㄥ":"跟蹤",
"ㄍㄣㄘㄨㄥ":"跟從",
"ㄍㄣㄙㄨㄟ":"跟隨",
"ㄍㄤㄆㄧㄢ":"港片",
"ㄍㄤㄉㄧㄥ":"鋼錠/扛鼎",
"ㄍㄤㄊㄧㄝ":"鋼鐵",
"ㄍㄤㄊㄧㄠ":"鋼條",
"ㄍㄤㄊㄧㄥ":"崗亭",
"ㄍㄤㄌㄧㄝ":"剛烈/肛裂",
"ㄍㄤㄌㄧㄤ":"杠梁",
"ㄍㄤㄌㄧㄥ":"綱領/槓鈴",
"ㄍㄤㄌㄨㄢ":"岡巒",
"ㄍㄤㄍㄨㄟ":"鋼軌/槓龜",
"ㄍㄤㄍㄨㄢ":"鋼管",
"ㄍㄤㄎㄨㄟ":"鋼盔",
"ㄍㄤㄏㄨㄣ":"鋼婚",
"ㄍㄤㄐㄧㄚ":"鋼架",
"ㄍㄤㄐㄧㄡ":"港九",
"ㄍㄤㄐㄧㄢ":"剛健/鋼剪",
"ㄍㄤㄐㄧㄣ":"鋼筋",
"ㄍㄤㄐㄧㄥ":"港警/崗警",
"ㄍㄤㄑㄧㄝ":"剛切",
"ㄍㄤㄑㄧㄠ":"剛巧",
"ㄍㄤㄑㄧㄣ":"鋼琴",
"ㄍㄤㄑㄧㄤ":"剛強",
"ㄍㄤㄒㄧㄥ":"港星/剛性",
"ㄍㄤㄓㄨㄥ":"鋼種",
"ㄍㄤㄕㄨㄟ":"鋼水",
"ㄍㄤㄗㄊㄡ":"槓子頭",
"ㄍㄤㄗㄨㄛ":"綱佐",
"ㄍㄤㄗㄨㄥ":"綱宗",
"ㄍㄤㄙㄐㄩ":"鋼絲鋸",
"ㄍㄤㄙㄨㄛ":"鋼索",
"ㄍㄤㄨㄐㄩ":"港務局",
"ㄍㄥㄅㄧㄢ":"更變",
"ㄍㄥㄅㄧㄥ":"耿餅",
"ㄍㄥㄇㄧㄥ":"更名/耿命",
"ㄍㄥㄉㄧㄝ":"更迭",
"ㄍㄥㄉㄧㄠ":"更調",
"ㄍㄥㄉㄧㄢ":"更點",
"ㄍㄥㄉㄧㄥ":"更定/更訂",
"ㄍㄥㄉㄨㄢ":"更端",
"ㄍㄥㄉㄨㄥ":"更動",
"ㄍㄥㄊㄧㄝ":"庚帖",
"ㄍㄥㄊㄧㄢ":"耕田",
"ㄍㄥㄋㄧㄡ":"耕牛",
"ㄍㄥㄋㄧㄢ":"更年",
"ㄍㄥㄍㄨㄟ":"庚癸",
"ㄍㄥㄎㄨㄢ":"耕寬",
"ㄍㄥㄏㄨㄚ":"更化",
"ㄍㄥㄏㄨㄢ":"更換",
"ㄍㄥㄐㄧㄚ":"更加/耕稼",
"ㄍㄥㄐㄧㄝ":"哽結/耿介",
"ㄍㄥㄑㄧㄝ":"更且",
"ㄍㄥㄑㄧㄠ":"緪橋",
"ㄍㄥㄑㄧㄢ":"更籤",
"ㄍㄥㄑㄧㄤ":"羹牆",
"ㄍㄥㄒㄧㄡ":"更休",
"ㄍㄥㄒㄧㄣ":"更新/庚信",
"ㄍㄥㄒㄧㄤ":"更相",
"ㄍㄥㄒㄧㄥ":"更行/更姓",
"ㄍㄥㄓㄨㄥ":"耕種",
"ㄍㄥㄗㄓㄧ":"庚子之役",
"ㄍㄥㄗㄨㄛ":"更做/耕作",
"ㄍㄥㄩㄌㄨ":"耕御路",
"ㄍㄨㄅㄧㄝ":"古憋",
"ㄍㄨㄅㄧㄠ":"姑表/孤標",
"ㄍㄨㄆㄛㄩ":"姑婆芋",
"ㄍㄨㄆㄧㄠ":"股票",
"ㄍㄨㄆㄧㄣ":"孤貧",
"ㄍㄨㄇㄧㄠ":"古廟/古邈",
"ㄍㄨㄇㄧㄢ":"顧眄/孤眠",
"ㄍㄨㄇㄧㄥ":"沽名/顧命",
"ㄍㄨㄉㄧㄝ":"姑爹",
"ㄍㄨㄉㄧㄢ":"古典/故典",
"ㄍㄨㄉㄧㄥ":"固定/孤丁",
"ㄍㄨㄉㄨㄛ":"骨朵/骨柮",
"ㄍㄨㄉㄨㄟ":"孤堆",
"ㄍㄨㄉㄨㄥ":"股東/古董",
"ㄍㄨㄉㄨㄦ":"骨都兒",
"ㄍㄨㄊㄡㄦ":"骨頭兒",
"ㄍㄨㄊㄧㄕ":"古體詩",
"ㄍㄨㄊㄨㄟ":"故退",
"ㄍㄨㄊㄨㄥ":"古銅/箍桶",
"ㄍㄨㄋㄧㄠ":"孤鳥",
"ㄍㄨㄋㄧㄡ":"牯牛",
"ㄍㄨㄋㄧㄢ":"顧念",
"ㄍㄨㄋㄧㄤ":"姑娘/姑娘",
"ㄍㄨㄋㄨㄥ":"僱農/雇農",
"ㄍㄨㄌㄤㄩ":"鼓浪嶼",
"ㄍㄨㄌㄧㄢ":"顧臉/顧戀",
"ㄍㄨㄌㄧㄣ":"故林/古鄰",
"ㄍㄨㄌㄧㄤ":"估量/菰粱",
"ㄍㄨㄌㄧㄥ":"鼓令/孤另",
"ㄍㄨㄌㄧㄩ":"孤立語",
"ㄍㄨㄌㄨㄢ":"汩亂/孤鸞",
"ㄍㄨㄌㄨㄥ":"咕隆",
"ㄍㄨㄌㄨㄦ":"轂轆兒",
"ㄍㄨㄍㄨㄚ":"孤寡",
"ㄍㄨㄍㄨㄛ":"故國",
"ㄍㄨㄍㄨㄞ":"古怪/孤拐",
"ㄍㄨㄍㄨㄟ":"穀圭",
"ㄍㄨㄍㄨㄢ":"孤館",
"ㄍㄨㄍㄨㄤ":"孤光",
"ㄍㄨㄍㄨㄥ":"故宮/姑公",
"ㄍㄨㄎㄞㄓ":"顧愷之",
"ㄍㄨㄎㄨㄞ":"賈儈",
"ㄍㄨㄏㄨㄚ":"古話/骨化",
"ㄍㄨㄏㄨㄛ":"蠱惑/鼓惑",
"ㄍㄨㄏㄨㄞ":"顧懷",
"ㄍㄨㄏㄨㄟ":"骨灰",
"ㄍㄨㄏㄨㄢ":"顧喚",
"ㄍㄨㄏㄨㄣ":"孤魂",
"ㄍㄨㄏㄨㄤ":"鼓簧",
"ㄍㄨㄐㄧㄚ":"股價/骨架",
"ㄍㄨㄐㄧㄝ":"固結/固接",
"ㄍㄨㄐㄧㄠ":"故交/骨膠",
"ㄍㄨㄐㄧㄡ":"古舊/故舊",
"ㄍㄨㄐㄧㄢ":"故劍/孤賤",
"ㄍㄨㄐㄧㄣ":"古今/箍緊",
"ㄍㄨㄐㄧㄥ":"古井",
"ㄍㄨㄐㄩㄝ":"孤絕/鼓角",
"ㄍㄨㄐㄩㄣ":"孤軍",
"ㄍㄨㄐㄩㄥ":"孤迥",
"ㄍㄨㄑㄧㄝ":"姑且/故且",
"ㄍㄨㄑㄧㄠ":"孤峭",
"ㄍㄨㄑㄧㄢ":"古錢/古槧",
"ㄍㄨㄑㄧㄣ":"古琴/鼓琴",
"ㄍㄨㄑㄧㄥ":"固請/僱請",
"ㄍㄨㄑㄩㄢ":"顧全/股權",
"ㄍㄨㄑㄩㄥ":"固窮/孤窮",
"ㄍㄨㄒㄧㄚ":"鼓下/轂下",
"ㄍㄨㄒㄧㄝ":"古寫/故歇",
"ㄍㄨㄒㄧㄡ":"顧繡",
"ㄍㄨㄒㄧㄢ":"姑洗/古賢",
"ㄍㄨㄒㄧㄤ":"故鄉/骨相",
"ㄍㄨㄒㄧㄥ":"孤行/鼓興",
"ㄍㄨㄒㄩㄝ":"古學",
"ㄍㄨㄒㄩㄣ":"古訓/固遜",
"ㄍㄨㄓㄨㄛ":"古拙",
"ㄍㄨㄓㄨㄢ":"古磚",
"ㄍㄨㄓㄨㄤ":"古裝/古妝",
"ㄍㄨㄓㄨㄥ":"估中/鼓鍾",
"ㄍㄨㄔㄨㄟ":"鼓吹/鼓吹",
"ㄍㄨㄔㄨㄢ":"僱船",
"ㄍㄨㄔㄨㄤ":"鼓床",
"ㄍㄨㄕㄐㄧ":"古事記",
"ㄍㄨㄕㄕㄨ":"故事書",
"ㄍㄨㄕㄙㄨ":"固時俗",
"ㄍㄨㄕㄥㄨ":"古生物",
"ㄍㄨㄕㄨㄗ":"賈豎子",
"ㄍㄨㄕㄨㄛ":"瞽說/告朔",
"ㄍㄨㄕㄨㄟ":"估稅",
"ㄍㄨㄕㄨㄤ":"孤霜",
"ㄍㄨㄖㄨㄛ":"孤弱",
"ㄍㄨㄖㄨㄥ":"咕容",
"ㄍㄨㄗㄌㄧ":"骨子裡",
"ㄍㄨㄗㄑㄩ":"鼓子曲",
"ㄍㄨㄗㄨㄓ":"骨組織",
"ㄍㄨㄗㄨㄛ":"古作",
"ㄍㄨㄗㄨㄥ":"故縱/估倧",
"ㄍㄨㄘㄨㄛ":"古厝",
"ㄍㄨㄙㄨㄛ":"故所",
"ㄍㄨㄙㄨㄟ":"骨髓",
"ㄍㄨㄙㄨㄢ":"估算",
"ㄍㄨㄚㄅㄨ":"刮補",
"ㄍㄨㄚㄆㄞ":"掛牌/挂牌",
"ㄍㄨㄚㄆㄥ":"瓜棚",
"ㄍㄨㄚㄇㄚ":"騧馬",
"ㄍㄨㄚㄇㄛ":"刮摩",
"ㄍㄨㄚㄇㄡ":"寡謀",
"ㄍㄨㄚㄇㄨ":"寡母",
"ㄍㄨㄚㄈㄚ":"括髮",
"ㄍㄨㄚㄈㄣ":"瓜分",
"ㄍㄨㄚㄈㄥ":"刮風/颳風",
"ㄍㄨㄚㄈㄨ":"寡夫/寡婦",
"ㄍㄨㄚㄉㄚ":"掛搭/括搭",
"ㄍㄨㄚㄉㄞ":"瓜代",
"ㄍㄨㄚㄉㄡ":"掛斗",
"ㄍㄨㄚㄉㄢ":"掛單",
"ㄍㄨㄚㄉㄥ":"掛燈",
"ㄍㄨㄚㄉㄨ":"括毒/刮毒",
"ㄍㄨㄚㄊㄡ":"寡頭/刮頭",
"ㄍㄨㄚㄊㄢ":"掛毯",
"ㄍㄨㄚㄊㄤ":"寡糖",
"ㄍㄨㄚㄊㄥ":"瓜藤",
"ㄍㄨㄚㄊㄨ":"掛圖",
"ㄍㄨㄚㄋㄤ":"括囊",
"ㄍㄨㄚㄋㄩ":"寡女",
"ㄍㄨㄚㄌㄚ":"刮喇/刮拉",
"ㄍㄨㄚㄌㄟ":"掛累",
"ㄍㄨㄚㄌㄡ":"栝樓/掛漏",
"ㄍㄨㄚㄌㄧ":"卦禮",
"ㄍㄨㄚㄌㄨ":"蝸廬",
"ㄍㄨㄚㄌㄩ":"掛慮/挂慮",
"ㄍㄨㄚㄍㄜ":"瓜葛",
"ㄍㄨㄚㄍㄠ":"掛告",
"ㄍㄨㄚㄍㄡ":"掛鉤",
"ㄍㄨㄚㄍㄨ":"刮骨/卦姑",
"ㄍㄨㄚㄎㄜ":"瓜科",
"ㄍㄨㄚㄎㄡ":"挂口",
"ㄍㄨㄚㄏㄜ":"寡合/寡鶴",
"ㄍㄨㄚㄏㄠ":"括號/掛號",
"ㄍㄨㄚㄏㄣ":"刮痕",
"ㄍㄨㄚㄏㄨ":"括弧/寡鵠",
"ㄍㄨㄚㄐㄧ":"掛記/罣記",
"ㄍㄨㄚㄐㄩ":"寡居/蝸居",
"ㄍㄨㄚㄑㄧ":"掛氣/聒氣",
"ㄍㄨㄚㄒㄩ":"聒絮",
"ㄍㄨㄚㄓㄞ":"蝸窄",
"ㄍㄨㄚㄓㄠ":"刮著",
"ㄍㄨㄚㄓㄡ":"掛軸/瓜洲",
"ㄍㄨㄚㄓㄢ":"寡占",
"ㄍㄨㄚㄓㄤ":"掛賬",
"ㄍㄨㄚㄓㄨ":"掛柱",
"ㄍㄨㄚㄔㄜ":"掛車",
"ㄍㄨㄚㄔㄨ":"掛出/掛鋤",
"ㄍㄨㄚㄕㄚ":"刮痧",
"ㄍㄨㄚㄕㄜ":"蝸舍",
"ㄍㄨㄚㄕㄤ":"刮上/刮傷",
"ㄍㄨㄚㄖㄠ":"聒擾",
"ㄍㄨㄚㄖㄣ":"寡人/瓜仁",
"ㄍㄨㄚㄖㄤ":"瓜瓤",
"ㄍㄨㄚㄗㄠ":"刮躁/聒躁",
"ㄍㄨㄚㄘㄞ":"掛彩",
"ㄍㄨㄚㄧㄢ":"寡言/掛眼",
"ㄍㄨㄚㄧㄤ":"瓜秧",
"ㄍㄨㄚㄧㄥ":"卦影",
"ㄍㄨㄚㄩㄝ":"瓜月",
"ㄍㄨㄛㄅㄚ":"鍋巴",
"ㄍㄨㄛㄅㄠ":"國寶/果報",
"ㄍㄨㄛㄅㄢ":"過班/過半",
"ㄍㄨㄛㄅㄣ":"國本",
"ㄍㄨㄛㄅㄤ":"過磅",
"ㄍㄨㄛㄅㄧ":"國幣",
"ㄍㄨㄛㄅㄨ":"國步",
"ㄍㄨㄛㄆㄛ":"國破",
"ㄍㄨㄛㄆㄞ":"過排",
"ㄍㄨㄛㄆㄢ":"果盤",
"ㄍㄨㄛㄆㄧ":"果皮",
"ㄍㄨㄛㄆㄨ":"郭璞",
"ㄍㄨㄛㄇㄛ":"裹抹",
"ㄍㄨㄛㄇㄞ":"過賣/國脈",
"ㄍㄨㄛㄇㄠ":"國貿",
"ㄍㄨㄛㄇㄢ":"過漫",
"ㄍㄨㄛㄇㄣ":"過門/國門",
"ㄍㄨㄛㄇㄥ":"過蒙",
"ㄍㄨㄛㄇㄨ":"過目/果木",
"ㄍㄨㄛㄈㄚ":"國法",
"ㄍㄨㄛㄈㄟ":"過費/幗匪",
"ㄍㄨㄛㄈㄢ":"過飯/過犯",
"ㄍㄨㄛㄈㄣ":"過分/果粉",
"ㄍㄨㄛㄈㄤ":"過房/過訪",
"ㄍㄨㄛㄈㄥ":"過風/國風",
"ㄍㄨㄛㄈㄨ":"國父/過福",
"ㄍㄨㄛㄉㄚ":"國大",
"ㄍㄨㄛㄉㄞ":"國代",
"ㄍㄨㄛㄉㄠ":"過到/過道",
"ㄍㄨㄛㄉㄤ":"過當",
"ㄍㄨㄛㄉㄨ":"過度/過渡",
"ㄍㄨㄛㄊㄞ":"國太/鍋臺",
"ㄍㄨㄛㄊㄡ":"過頭/裹頭",
"ㄍㄨㄛㄊㄤ":"過堂/果糖",
"ㄍㄨㄛㄊㄧ":"國體",
"ㄍㄨㄛㄊㄨ":"國土",
"ㄍㄨㄛㄋㄟ":"國內",
"ㄍㄨㄛㄋㄢ":"國難",
"ㄍㄨㄛㄋㄥ":"國能",
"ㄍㄨㄛㄌㄜ":"過了",
"ㄍㄨㄛㄌㄞ":"過來",
"ㄍㄨㄛㄌㄠ":"國老",
"ㄍㄨㄛㄌㄧ":"國力/過歷",
"ㄍㄨㄛㄌㄨ":"過路/過錄",
"ㄍㄨㄛㄌㄩ":"過濾/過慮",
"ㄍㄨㄛㄍㄜ":"國歌/國格",
"ㄍㄨㄛㄍㄞ":"鍋蓋",
"ㄍㄨㄛㄍㄡ":"國狗/鍋垢",
"ㄍㄨㄛㄍㄢ":"果敢/果乾",
"ㄍㄨㄛㄍㄨ":"國故",
"ㄍㄨㄛㄎㄜ":"過客",
"ㄍㄨㄛㄎㄡ":"過口/渦口",
"ㄍㄨㄛㄎㄨ":"國庫",
"ㄍㄨㄛㄏㄜ":"果核/果盒",
"ㄍㄨㄛㄏㄞ":"果海",
"ㄍㄨㄛㄏㄠ":"國號",
"ㄍㄨㄛㄏㄡ":"過後",
"ㄍㄨㄛㄏㄨ":"過戶",
"ㄍㄨㄛㄐㄧ":"國際/國籍",
"ㄍㄨㄛㄐㄩ":"國劇/郭巨",
"ㄍㄨㄛㄑㄧ":"過期/國旗",
"ㄍㄨㄛㄑㄩ":"過去/過去",
"ㄍㄨㄛㄒㄧ":"過細/過隙",
"ㄍㄨㄛㄒㄩ":"國恤",
"ㄍㄨㄛㄓㄚ":"鍋渣",
"ㄍㄨㄛㄓㄞ":"國債/國宅",
"ㄍㄨㄛㄓㄠ":"過招",
"ㄍㄨㄛㄓㄣ":"果真",
"ㄍㄨㄛㄓㄤ":"過帳/國丈",
"ㄍㄨㄛㄓㄥ":"國政",
"ㄍㄨㄛㄓㄦ":"國之貳",
"ㄍㄨㄛㄓㄨ":"裹住/國主",
"ㄍㄨㄛㄔㄚ":"過茶",
"ㄍㄨㄛㄔㄠ":"國朝",
"ㄍㄨㄛㄔㄡ":"國讎",
"ㄍㄨㄛㄔㄢ":"國產/鍋鏟",
"ㄍㄨㄛㄔㄤ":"過場",
"ㄍㄨㄛㄔㄥ":"過程/過稱",
"ㄍㄨㄛㄔㄨ":"過處/國初",
"ㄍㄨㄛㄕㄜ":"過舌",
"ㄍㄨㄛㄕㄡ":"過手/國手",
"ㄍㄨㄛㄕㄢ":"過山/崞山",
"ㄍㄨㄛㄕㄣ":"過身/過甚",
"ㄍㄨㄛㄕㄤ":"過晌/國殤",
"ㄍㄨㄛㄕㄥ":"過剩",
"ㄍㄨㄛㄕㄨ":"過數/果樹",
"ㄍㄨㄛㄖㄗ":"過日子",
"ㄍㄨㄛㄖㄡ":"果肉",
"ㄍㄨㄛㄖㄢ":"果然",
"ㄍㄨㄛㄖㄣ":"國人/過人",
"ㄍㄨㄛㄖㄨ":"過如",
"ㄍㄨㄛㄗㄞ":"過載",
"ㄍㄨㄛㄗㄟ":"國賊",
"ㄍㄨㄛㄗㄤ":"國葬",
"ㄍㄨㄛㄗㄧ":"郭子儀",
"ㄍㄨㄛㄗㄨ":"國族/果足",
"ㄍㄨㄛㄘㄜ":"國策",
"ㄍㄨㄛㄘㄞ":"果菜",
"ㄍㄨㄛㄘㄨ":"果醋",
"ㄍㄨㄛㄙㄜ":"國色",
"ㄍㄨㄛㄙㄤ":"國喪",
"ㄍㄨㄛㄧㄝ":"過夜",
"ㄍㄨㄛㄧㄠ":"國藥",
"ㄍㄨㄛㄧㄡ":"國有/國憂",
"ㄍㄨㄛㄧㄢ":"過眼/過言",
"ㄍㄨㄛㄧㄣ":"過癮/過陰",
"ㄍㄨㄛㄧㄥ":"過硬/果蠅",
"ㄍㄨㄛㄨㄞ":"國外",
"ㄍㄨㄛㄨㄟ":"國維/國威",
"ㄍㄨㄛㄨㄣ":"國文/過問",
"ㄍㄨㄛㄨㄤ":"過往/國王",
"ㄍㄨㄛㄩㄝ":"國樂",
"ㄍㄨㄛㄩㄢ":"果園",
"ㄍㄨㄛㄩㄣ":"國運",
"ㄍㄨㄛㄩㄥ":"國用",
"ㄍㄨㄞㄅㄨ":"拐步",
"ㄍㄨㄞㄆㄠ":"拐跑",
"ㄍㄨㄞㄆㄧ":"怪癖/怪僻",
"ㄍㄨㄞㄇㄞ":"拐賣",
"ㄍㄨㄞㄇㄣ":"拐門",
"ㄍㄨㄞㄈㄣ":"乖分",
"ㄍㄨㄞㄉㄞ":"拐帶",
"ㄍㄨㄞㄉㄠ":"怪道/拐盜",
"ㄍㄨㄞㄉㄢ":"怪誕",
"ㄍㄨㄞㄉㄧ":"怪底",
"ㄍㄨㄞㄊㄜ":"怪特",
"ㄍㄨㄞㄊㄞ":"怪胎",
"ㄍㄨㄞㄊㄡ":"怪頭",
"ㄍㄨㄞㄊㄢ":"怪談",
"ㄍㄨㄞㄌㄧ":"乖離/乖戾",
"ㄍㄨㄞㄍㄜ":"乖隔",
"ㄍㄨㄞㄍㄨ":"拐孤",
"ㄍㄨㄞㄏㄣ":"怪恨",
"ㄍㄨㄞㄓㄡ":"拐肘",
"ㄍㄨㄞㄓㄣ":"拐枕",
"ㄍㄨㄞㄓㄤ":"拐杖/枴杖",
"ㄍㄨㄞㄓㄥ":"乖爭",
"ㄍㄨㄞㄔㄨ":"怪處",
"ㄍㄨㄞㄕㄡ":"怪獸/怪手",
"ㄍㄨㄞㄕㄥ":"怪生/怪聲",
"ㄍㄨㄞㄖㄣ":"怪人/乖人",
"ㄍㄨㄞㄗㄜ":"怪責",
"ㄍㄨㄞㄗㄞ":"怪哉",
"ㄍㄨㄞㄗㄨ":"乖阻",
"ㄍㄨㄞㄧㄡ":"拐誘",
"ㄍㄨㄞㄧㄢ":"乖眼",
"ㄍㄨㄞㄨㄟ":"乖違",
"ㄍㄨㄞㄨㄢ":"拐彎",
"ㄍㄨㄞㄩㄥ":"乖慵",
"ㄍㄨㄟㄅㄞ":"跪拜",
"ㄍㄨㄟㄅㄟ":"龜貝",
"ㄍㄨㄟㄅㄠ":"瑰寶",
"ㄍㄨㄟㄅㄢ":"龜版",
"ㄍㄨㄟㄅㄣ":"歸本",
"ㄍㄨㄟㄅㄧ":"鬼筆/規避",
"ㄍㄨㄟㄅㄨ":"龜卜",
"ㄍㄨㄟㄆㄚ":"跪爬",
"ㄍㄨㄟㄆㄛ":"龜婆/桂魄",
"ㄍㄨㄟㄆㄞ":"鬼牌",
"ㄍㄨㄟㄆㄧ":"桂皮",
"ㄍㄨㄟㄇㄛ":"規模/軌模",
"ㄍㄨㄟㄇㄞ":"瑰邁",
"ㄍㄨㄟㄇㄟ":"鬼魅",
"ㄍㄨㄟㄇㄡ":"規謀",
"ㄍㄨㄟㄇㄣ":"鬼門/跪門",
"ㄍㄨㄟㄇㄧ":"詭祕",
"ㄍㄨㄟㄇㄨ":"鬼母/鮭目",
"ㄍㄨㄟㄈㄟ":"貴妃/規費",
"ㄍㄨㄟㄈㄢ":"規範/歸帆",
"ㄍㄨㄟㄈㄤ":"鬼方/歸房",
"ㄍㄨㄟㄈㄥ":"規諷",
"ㄍㄨㄟㄈㄨ":"貴府/歸服",
"ㄍㄨㄟㄉㄜ":"貴德",
"ㄍㄨㄟㄉㄠ":"軌道/鬼道",
"ㄍㄨㄟㄉㄡ":"圭竇",
"ㄍㄨㄟㄉㄢ":"詭誕",
"ㄍㄨㄟㄉㄤ":"歸檔",
"ㄍㄨㄟㄉㄧ":"貴地",
"ㄍㄨㄟㄉㄨ":"規度/軌度",
"ㄍㄨㄟㄊㄚ":"閨闥",
"ㄍㄨㄟㄊㄜ":"詭特",
"ㄍㄨㄟㄊㄞ":"鬼胎/櫃檯",
"ㄍㄨㄟㄊㄡ":"鬼頭/龜頭",
"ㄍㄨㄟㄊㄢ":"規談",
"ㄍㄨㄟㄊㄤ":"桂堂",
"ㄍㄨㄟㄊㄧ":"貴體",
"ㄍㄨㄟㄊㄨ":"歸途",
"ㄍㄨㄟㄋㄚ":"歸納/規那",
"ㄍㄨㄟㄋㄨ":"龜奴",
"ㄍㄨㄟㄋㄩ":"閨女/歸女",
"ㄍㄨㄟㄌㄞ":"歸來",
"ㄍㄨㄟㄌㄟ":"歸類/詭雷",
"ㄍㄨㄟㄌㄠ":"歸老",
"ㄍㄨㄟㄌㄡ":"軌漏",
"ㄍㄨㄟㄌㄧ":"瑰麗/鬼力",
"ㄍㄨㄟㄌㄨ":"歸路/鬼錄",
"ㄍㄨㄟㄌㄩ":"規律",
"ㄍㄨㄟㄍㄜ":"規格/軌革",
"ㄍㄨㄟㄍㄢ":"貴幹",
"ㄍㄨㄟㄍㄣ":"歸根",
"ㄍㄨㄟㄍㄥ":"歸耕/貴庚",
"ㄍㄨㄟㄍㄨ":"詭故/歸骨",
"ㄍㄨㄟㄎㄜ":"貴客/晷刻",
"ㄍㄨㄟㄎㄢ":"歸勘",
"ㄍㄨㄟㄎㄨ":"桂窟",
"ㄍㄨㄟㄏㄜ":"媯河/龜鶴",
"ㄍㄨㄟㄏㄞ":"桂海",
"ㄍㄨㄟㄏㄠ":"歸號",
"ㄍㄨㄟㄏㄢ":"匭函",
"ㄍㄨㄟㄏㄨ":"鬼狐",
"ㄍㄨㄟㄐㄧ":"軌跡/詭計",
"ㄍㄨㄟㄐㄩ":"規矩/桂劇",
"ㄍㄨㄟㄑㄧ":"歸期/歸齊",
"ㄍㄨㄟㄑㄩ":"歸去/歸趨",
"ㄍㄨㄟㄒㄧ":"歸西/貴細",
"ㄍㄨㄟㄒㄩ":"歸墟/貴婿",
"ㄍㄨㄟㄓㄚ":"詭詐",
"ㄍㄨㄟㄓㄠ":"歸趙/歸棹",
"ㄍㄨㄟㄓㄡ":"貴州/貴胄",
"ㄍㄨㄟㄓㄣ":"歸真/軌枕",
"ㄍㄨㄟㄓㄤ":"規章/圭璋",
"ㄍㄨㄟㄓㄥ":"規正/歸正",
"ㄍㄨㄟㄓㄨ":"貴主/桂竹",
"ㄍㄨㄟㄔㄜ":"鬼車/鬼扯",
"ㄍㄨㄟㄔㄠ":"歸巢",
"ㄍㄨㄟㄔㄣ":"歸忱",
"ㄍㄨㄟㄔㄥ":"規程/歸程",
"ㄍㄨㄟㄔㄨ":"貴處/歸除",
"ㄍㄨㄟㄕㄗ":"鬼市子",
"ㄍㄨㄟㄕㄚ":"歸煞",
"ㄍㄨㄟㄕㄜ":"貴社",
"ㄍㄨㄟㄕㄡ":"歸首/龜綬",
"ㄍㄨㄟㄕㄢ":"歸山/龜山",
"ㄍㄨㄟㄕㄣ":"鬼神/皈身",
"ㄍㄨㄟㄕㄤ":"貴上/櫃上",
"ㄍㄨㄟㄕㄥ":"規繩",
"ㄍㄨㄟㄕㄨ":"歸屬/桂樹",
"ㄍㄨㄟㄖㄢ":"傀然",
"ㄍㄨㄟㄖㄣ":"貴人/歸人",
"ㄍㄨㄟㄖㄤ":"禬禳",
"ㄍㄨㄟㄖㄨ":"跪乳/瑰儒",
"ㄍㄨㄟㄗㄜ":"規則/軌則",
"ㄍㄨㄟㄗㄠ":"貴造",
"ㄍㄨㄟㄗㄤ":"歸葬",
"ㄍㄨㄟㄗㄨ":"貴族/鬼卒",
"ㄍㄨㄟㄘㄜ":"龜策/龜筴",
"ㄍㄨㄟㄘㄞ":"鬼才",
"ㄍㄨㄟㄘㄤ":"歸藏",
"ㄍㄨㄟㄙㄜ":"詭色",
"ㄍㄨㄟㄙㄨ":"歸俗/歸宿",
"ㄍㄨㄟㄦㄗ":"龜兒子",
"ㄍㄨㄟㄧㄠ":"貴要",
"ㄍㄨㄟㄧㄡ":"閨友",
"ㄍㄨㄟㄧㄢ":"歸煙",
"ㄍㄨㄟㄧㄣ":"歸隱/歸因",
"ㄍㄨㄟㄧㄤ":"貴陽/貴恙",
"ㄍㄨㄟㄧㄥ":"鬼影",
"ㄍㄨㄟㄨㄟ":"歸位/傀偉",
"ㄍㄨㄟㄨㄣ":"龜文/龜紋",
"ㄍㄨㄟㄨㄤ":"桂王",
"ㄍㄨㄟㄩㄝ":"鬼月/桂月",
"ㄍㄨㄟㄩㄢ":"規院/歸元",
"ㄍㄨㄢㄅㄚ":"官罷",
"ㄍㄨㄢㄅㄛ":"關白/貫伯",
"ㄍㄨㄢㄅㄞ":"官拜",
"ㄍㄨㄢㄅㄠ":"關報/關堡",
"ㄍㄨㄢㄅㄢ":"官辦/館伴",
"ㄍㄨㄢㄅㄣ":"官本",
"ㄍㄨㄢㄅㄤ":"官謗",
"ㄍㄨㄢㄅㄧ":"關閉/官婢",
"ㄍㄨㄢㄅㄨ":"官捕",
"ㄍㄨㄢㄆㄞ":"官派",
"ㄍㄨㄢㄆㄟ":"冠帔",
"ㄍㄨㄢㄆㄨ":"關撲",
"ㄍㄨㄢㄇㄚ":"官馬",
"ㄍㄨㄢㄇㄛ":"觀摩",
"ㄍㄨㄢㄇㄞ":"關脈/官賣",
"ㄍㄨㄢㄇㄟ":"觀美/官媒",
"ㄍㄨㄢㄇㄠ":"官帽/冠毛",
"ㄍㄨㄢㄇㄣ":"關門",
"ㄍㄨㄢㄇㄨ":"關目/關牡",
"ㄍㄨㄢㄈㄚ":"官法/官閥",
"ㄍㄨㄢㄈㄟ":"官費/慣匪",
"ㄍㄨㄢㄈㄢ":"慣犯",
"ㄍㄨㄢㄈㄤ":"官方/關防",
"ㄍㄨㄢㄈㄥ":"關風/關俸",
"ㄍㄨㄢㄈㄨ":"官府/鰥夫",
"ㄍㄨㄢㄉㄚ":"摜打",
"ㄍㄨㄢㄉㄞ":"管帶/冠帶",
"ㄍㄨㄢㄉㄠ":"關刀/官道",
"ㄍㄨㄢㄉㄥ":"官等/關燈",
"ㄍㄨㄢㄉㄧ":"關帝/館地",
"ㄍㄨㄢㄉㄨ":"關渡/官渡",
"ㄍㄨㄢㄊㄞ":"觀臺",
"ㄍㄨㄢㄊㄡ":"關頭/罐頭",
"ㄍㄨㄢㄊㄤ":"官堂/官塘",
"ㄍㄨㄢㄊㄧ":"關提",
"ㄍㄨㄢㄋㄟ":"關內",
"ㄍㄨㄢㄋㄤ":"官囊",
"ㄍㄨㄢㄋㄥ":"官能",
"ㄍㄨㄢㄋㄨ":"官奴",
"ㄍㄨㄢㄌㄢ":"關覽/觀覽",
"ㄍㄨㄢㄌㄧ":"管理/慣例",
"ㄍㄨㄢㄌㄨ":"官路/管路",
"ㄍㄨㄢㄌㄩ":"官綠/冠履",
"ㄍㄨㄢㄍㄜ":"館閣/貫革",
"ㄍㄨㄢㄍㄞ":"冠蓋/灌溉",
"ㄍㄨㄢㄍㄠ":"官告/官誥",
"ㄍㄨㄢㄍㄡ":"管勾",
"ㄍㄨㄢㄍㄢ":"觀感/管幹",
"ㄍㄨㄢㄍㄨ":"官股/管顧",
"ㄍㄨㄢㄎㄚ":"關卡",
"ㄍㄨㄢㄎㄜ":"官客/館客",
"ㄍㄨㄢㄎㄡ":"關口",
"ㄍㄨㄢㄎㄢ":"觀看",
"ㄍㄨㄢㄎㄨ":"管庫",
"ㄍㄨㄢㄏㄜ":"關合/關河",
"ㄍㄨㄢㄏㄞ":"觀海",
"ㄍㄨㄢㄏㄨ":"關乎/觀護",
"ㄍㄨㄢㄐㄧ":"關機/管記",
"ㄍㄨㄢㄐㄩ":"關雎/觀局",
"ㄍㄨㄢㄑㄧ":"官氣/貫氣",
"ㄍㄨㄢㄑㄩ":"關取/管區",
"ㄍㄨㄢㄒㄧ":"關係/關西",
"ㄍㄨㄢㄓㄜ":"冠者",
"ㄍㄨㄢㄓㄞ":"貫寨",
"ㄍㄨㄢㄓㄠ":"關照/關召",
"ㄍㄨㄢㄓㄢ":"觀戰/關棧",
"ㄍㄨㄢㄓㄣ":"官箴",
"ㄍㄨㄢㄓㄤ":"關張/官長",
"ㄍㄨㄢㄓㄥ":"關政/觀政",
"ㄍㄨㄢㄓㄨ":"關注/灌注",
"ㄍㄨㄢㄔㄚ":"觀察",
"ㄍㄨㄢㄔㄜ":"貫徹/管車",
"ㄍㄨㄢㄔㄞ":"官差",
"ㄍㄨㄢㄔㄢ":"官產",
"ㄍㄨㄢㄔㄤ":"官場/慣常",
"ㄍㄨㄢㄔㄥ":"管成/官程",
"ㄍㄨㄢㄕㄚ":"關煞/官紗",
"ㄍㄨㄢㄕㄜ":"關涉/官舍",
"ㄍㄨㄢㄕㄡ":"官守/關手",
"ㄍㄨㄢㄕㄢ":"關山",
"ㄍㄨㄢㄕㄣ":"官身/官紳",
"ㄍㄨㄢㄕㄤ":"觀賞/官商",
"ㄍㄨㄢㄕㄥ":"官生/官聲",
"ㄍㄨㄢㄕㄨ":"灌輸/管束",
"ㄍㄨㄢㄖㄣ":"官人/關人",
"ㄍㄨㄢㄗㄟ":"慣賊",
"ㄍㄨㄢㄗㄨ":"管足/官租",
"ㄍㄨㄢㄗㄩ":"罐子玉",
"ㄍㄨㄢㄘㄜ":"觀測",
"ㄍㄨㄢㄘㄞ":"棺材/觀採",
"ㄍㄨㄢㄘㄤ":"官艙",
"ㄍㄨㄢㄙㄜ":"觀色",
"ㄍㄨㄢㄙㄞ":"關塞/觀賽",
"ㄍㄨㄢㄙㄡ":"盥漱",
"ㄍㄨㄢㄧㄚ":"關押/管押",
"ㄍㄨㄢㄧㄝ":"官業",
"ㄍㄨㄢㄧㄠ":"官窯",
"ㄍㄨㄢㄧㄢ":"官鹽/管晏",
"ㄍㄨㄢㄧㄣ":"觀音/關尹",
"ㄍㄨㄢㄧㄤ":"官樣",
"ㄍㄨㄢㄧㄥ":"貫盈",
"ㄍㄨㄢㄧㄨ":"管夷吾",
"ㄍㄨㄢㄨㄞ":"關外",
"ㄍㄨㄢㄨㄟ":"官位",
"ㄍㄨㄢㄨㄢ":"觀翫",
"ㄍㄨㄢㄨㄣ":"關文",
"ㄍㄨㄢㄨㄤ":"觀望/關亡",
"ㄍㄨㄢㄩㄝ":"管樂/關岳",
"ㄍㄨㄢㄩㄢ":"官員/關元",
"ㄍㄨㄢㄩㄣ":"官運/官韻",
"ㄍㄨㄢㄩㄥ":"官用/管用",
"ㄍㄨㄣㄅㄞ":"滾白",
"ㄍㄨㄣㄅㄤ":"棍棒",
"ㄍㄨㄣㄈㄢ":"滾翻",
"ㄍㄨㄣㄈㄨ":"袞服",
"ㄍㄨㄣㄉㄞ":"緄帶",
"ㄍㄨㄣㄉㄠ":"滾刀",
"ㄍㄨㄣㄉㄢ":"滾蛋",
"ㄍㄨㄣㄉㄨ":"滾肚",
"ㄍㄨㄣㄊㄤ":"滾燙",
"ㄍㄨㄣㄊㄨ":"棍徒",
"ㄍㄨㄣㄌㄧ":"滾利",
"ㄍㄨㄣㄎㄞ":"滾開",
"ㄍㄨㄣㄔㄢ":"袞纏",
"ㄍㄨㄣㄔㄤ":"滾唱",
"ㄍㄨㄣㄖㄜ":"滾熱",
"ㄍㄨㄣㄧㄚ":"輥軋",
"ㄍㄨㄣㄩㄢ":"滾圓",
"ㄍㄨㄤㄅㄚ":"光巴",
"ㄍㄨㄤㄅㄛ":"廣播/光波",
"ㄍㄨㄤㄅㄢ":"光斑",
"ㄍㄨㄤㄅㄧ":"光筆",
"ㄍㄨㄤㄅㄨ":"廣布/廣捕",
"ㄍㄨㄤㄆㄨ":"光譜",
"ㄍㄨㄤㄇㄛ":"廣漠",
"ㄍㄨㄤㄇㄠ":"廣袤",
"ㄍㄨㄤㄇㄤ":"光芒",
"ㄍㄨㄤㄇㄨ":"廣牧",
"ㄍㄨㄤㄈㄢ":"廣泛",
"ㄍㄨㄤㄈㄥ":"光風",
"ㄍㄨㄤㄈㄨ":"光復",
"ㄍㄨㄤㄉㄚ":"廣大/光大",
"ㄍㄨㄤㄉㄜ":"廣德",
"ㄍㄨㄤㄉㄞ":"光帶",
"ㄍㄨㄤㄉㄠ":"廣島",
"ㄍㄨㄤㄉㄢ":"光蛋",
"ㄍㄨㄤㄉㄤ":"逛蕩",
"ㄍㄨㄤㄉㄥ":"逛燈",
"ㄍㄨㄤㄉㄧ":"獷敵",
"ㄍㄨㄤㄉㄨ":"光度/廣度",
"ㄍㄨㄤㄊㄞ":"光苔",
"ㄍㄨㄤㄊㄡ":"光頭",
"ㄍㄨㄤㄊㄤ":"光燙/光趟",
"ㄍㄨㄤㄊㄨ":"光禿",
"ㄍㄨㄤㄋㄟ":"廣內",
"ㄍㄨㄤㄋㄥ":"光能",
"ㄍㄨㄤㄌㄠ":"光老",
"ㄍㄨㄤㄌㄢ":"光闌",
"ㄍㄨㄤㄌㄤ":"桄榔",
"ㄍㄨㄤㄌㄧ":"廣裡/光麗",
"ㄍㄨㄤㄌㄨ":"廣陸",
"ㄍㄨㄤㄍㄠ":"廣告",
"ㄍㄨㄤㄍㄥ":"廣耕",
"ㄍㄨㄤㄍㄨ":"光顧",
"ㄍㄨㄤㄎㄞ":"廣欬",
"ㄍㄨㄤㄏㄞ":"光害",
"ㄍㄨㄤㄏㄢ":"廣寒/獷悍",
"ㄍㄨㄤㄑㄧ":"光氣/光啟",
"ㄍㄨㄤㄒㄧ":"光熙",
"ㄍㄨㄤㄒㄩ":"光緒",
"ㄍㄨㄤㄓㄓ":"光緻緻",
"ㄍㄨㄤㄓㄚ":"光柵",
"ㄍㄨㄤㄓㄜ":"光宅",
"ㄍㄨㄤㄓㄠ":"光照/光昭",
"ㄍㄨㄤㄓㄡ":"光軸",
"ㄍㄨㄤㄔㄚ":"光差",
"ㄍㄨㄤㄔㄣ":"光塵",
"ㄍㄨㄤㄔㄤ":"廣場/光昌",
"ㄍㄨㄤㄕㄜ":"光攝/廣捨",
"ㄍㄨㄤㄕㄥ":"廣盛",
"ㄍㄨㄤㄕㄨ":"光束",
"ㄍㄨㄤㄗㄜ":"光澤",
"ㄍㄨㄤㄗㄠ":"光藻",
"ㄍㄨㄤㄘㄞ":"光彩/光采",
"ㄍㄨㄤㄘㄢ":"光燦",
"ㄍㄨㄤㄙㄜ":"光色",
"ㄍㄨㄤㄙㄨ":"光速/獷俗",
"ㄍㄨㄤㄧㄚ":"光壓/廣雅",
"ㄍㄨㄤㄧㄠ":"光耀/廣窯",
"ㄍㄨㄤㄧㄡ":"光有",
"ㄍㄨㄤㄧㄢ":"光豔/光燄",
"ㄍㄨㄤㄧㄣ":"光陰",
"ㄍㄨㄤㄧㄤ":"光洋/洸洋",
"ㄍㄨㄤㄧㄥ":"光影",
"ㄍㄨㄤㄨㄣ":"廣文",
"ㄍㄨㄤㄩㄢ":"光源",
"ㄍㄨㄤㄩㄣ":"光暈/廣韻",
"ㄍㄨㄥㄅㄚ":"貢八/拱把",
"ㄍㄨㄥㄅㄛ":"公伯",
"ㄍㄨㄥㄅㄟ":"弓背/拱北",
"ㄍㄨㄥㄅㄠ":"公報/公保",
"ㄍㄨㄥㄅㄣ":"工本/恭本",
"ㄍㄨㄥㄅㄧ":"公比/工筆",
"ㄍㄨㄥㄅㄨ":"公布/工部",
"ㄍㄨㄥㄆㄛ":"攻破/公婆",
"ㄍㄨㄥㄆㄞ":"功牌",
"ㄍㄨㄥㄆㄢ":"公判/供攀",
"ㄍㄨㄥㄆㄥ":"工棚",
"ㄍㄨㄥㄆㄨ":"公普/公僕",
"ㄍㄨㄥㄇㄚ":"弓馬",
"ㄍㄨㄥㄇㄛ":"工模/拱默",
"ㄍㄨㄥㄇㄞ":"公賣",
"ㄍㄨㄥㄇㄟ":"宮眉",
"ㄍㄨㄥㄇㄡ":"共謀",
"ㄍㄨㄥㄇㄣ":"拱門/公門",
"ㄍㄨㄥㄇㄤ":"公忙",
"ㄍㄨㄥㄇㄨ":"公墓/公畝",
"ㄍㄨㄥㄈㄚ":"公法/攻伐",
"ㄍㄨㄥㄈㄛ":"供佛",
"ㄍㄨㄥㄈㄟ":"公費",
"ㄍㄨㄥㄈㄢ":"共犯",
"ㄍㄨㄥㄈㄣ":"公分/共分",
"ㄍㄨㄥㄈㄤ":"公方/工房",
"ㄍㄨㄥㄈㄥ":"供奉/公俸",
"ㄍㄨㄥㄈㄨ":"功夫/工夫",
"ㄍㄨㄥㄉㄚ":"攻打",
"ㄍㄨㄥㄉㄜ":"功德/公德",
"ㄍㄨㄥㄉㄠ":"公道/公道",
"ㄍㄨㄥㄉㄡ":"公斗",
"ㄍㄨㄥㄉㄢ":"公擔/公石",
"ㄍㄨㄥㄉㄥ":"共登/宮燈",
"ㄍㄨㄥㄉㄧ":"公敵/公地",
"ㄍㄨㄥㄉㄨ":"攻讀/共度",
"ㄍㄨㄥㄊㄚ":"宮闥",
"ㄍㄨㄥㄊㄠ":"弓套",
"ㄍㄨㄥㄊㄡ":"工頭",
"ㄍㄨㄥㄊㄢ":"公攤/共談",
"ㄍㄨㄥㄊㄤ":"公堂/公帑",
"ㄍㄨㄥㄊㄧ":"宮體/攻踢",
"ㄍㄨㄥㄊㄨ":"工徒/供吐",
"ㄍㄨㄥㄋㄥ":"功能",
"ㄍㄨㄥㄋㄨ":"弓弩",
"ㄍㄨㄥㄋㄩ":"工女/紅女",
"ㄍㄨㄥㄌㄠ":"功勞/公姥",
"ㄍㄨㄥㄌㄡ":"宮漏",
"ㄍㄨㄥㄌㄢ":"攻籃",
"ㄍㄨㄥㄌㄤ":"公郎/拱廊",
"ㄍㄨㄥㄌㄧ":"公里/功利",
"ㄍㄨㄥㄌㄨ":"公路",
"ㄍㄨㄥㄌㄩ":"功率/工率",
"ㄍㄨㄥㄍㄜ":"公合",
"ㄍㄨㄥㄍㄠ":"公告/功高",
"ㄍㄨㄥㄍㄡ":"功狗",
"ㄍㄨㄥㄍㄢ":"公幹",
"ㄍㄨㄥㄍㄣ":"公根",
"ㄍㄨㄥㄍㄥ":"躬耕",
"ㄍㄨㄥㄍㄨ":"鞏固/公姑",
"ㄍㄨㄥㄎㄜ":"功課/工科",
"ㄍㄨㄥㄎㄞ":"公開/龔開",
"ㄍㄨㄥㄎㄨ":"公庫/攻苦",
"ㄍㄨㄥㄏㄜ":"恭賀/共和",
"ㄍㄨㄥㄏㄞ":"公海/公害",
"ㄍㄨㄥㄏㄠ":"公毫",
"ㄍㄨㄥㄏㄡ":"恭候/拱候",
"ㄍㄨㄥㄏㄢ":"公函",
"ㄍㄨㄥㄏㄥ":"公衡",
"ㄍㄨㄥㄏㄨ":"公忽/公戶",
"ㄍㄨㄥㄐㄧ":"攻擊/功績",
"ㄍㄨㄥㄐㄩ":"工具/共聚",
"ㄍㄨㄥㄑㄧ":"工期/公器",
"ㄍㄨㄥㄑㄩ":"工區/攻取",
"ㄍㄨㄥㄒㄧ":"恭喜/工細",
"ㄍㄨㄥㄒㄩ":"供需",
"ㄍㄨㄥㄓㄞ":"公債",
"ㄍㄨㄥㄓㄠ":"供招/弓招",
"ㄍㄨㄥㄓㄢ":"攻戰/攻占",
"ㄍㄨㄥㄓㄣ":"共振/工賑",
"ㄍㄨㄥㄓㄤ":"公章/公丈",
"ㄍㄨㄥㄓㄥ":"公正/工整",
"ㄍㄨㄥㄓㄨ":"公主/公諸",
"ㄍㄨㄥㄔㄚ":"公差/供茶",
"ㄍㄨㄥㄔㄜ":"公車/工尺",
"ㄍㄨㄥㄔㄞ":"公差",
"ㄍㄨㄥㄔㄠ":"工潮/公朝",
"ㄍㄨㄥㄔㄢ":"公產",
"ㄍㄨㄥㄔㄣ":"功臣/拱辰",
"ㄍㄨㄥㄔㄤ":"工廠/工場",
"ㄍㄨㄥㄔㄥ":"工程/功成",
"ㄍㄨㄥㄔㄨ":"共處/公出",
"ㄍㄨㄥㄕㄜ":"公社/弓蛇",
"ㄍㄨㄥㄕㄡ":"拱手/弓手",
"ㄍㄨㄥㄕㄢ":"公善/攻山",
"ㄍㄨㄥㄕㄣ":"公審/躬身",
"ㄍㄨㄥㄕㄤ":"工傷/宮商",
"ㄍㄨㄥㄕㄥ":"公升/共生",
"ㄍㄨㄥㄕㄨ":"公署/公輸",
"ㄍㄨㄥㄖㄢ":"公然",
"ㄍㄨㄥㄖㄣ":"工人/公認",
"ㄍㄨㄥㄗㄜ":"公則",
"ㄍㄨㄥㄗㄨ":"公族/公祖",
"ㄍㄨㄥㄘㄜ":"公廁",
"ㄍㄨㄥㄘㄞ":"公財",
"ㄍㄨㄥㄘㄠ":"功曹",
"ㄍㄨㄥㄙㄤ":"躬桑",
"ㄍㄨㄥㄙㄨ":"公訴",
"ㄍㄨㄥㄧㄚ":"宮鴉",
"ㄍㄨㄥㄧㄝ":"工業/公爺",
"ㄍㄨㄥㄧㄠ":"宮腰/弓腰",
"ㄍㄨㄥㄧㄡ":"共有/工友",
"ㄍㄨㄥㄧㄢ":"公演/貢煙",
"ㄍㄨㄥㄧㄣ":"公引",
"ㄍㄨㄥㄧㄤ":"供養/宮樣",
"ㄍㄨㄥㄧㄥ":"供應/供應",
"ㄍㄨㄥㄧㄨ":"公義務",
"ㄍㄨㄥㄨㄚ":"宮娃",
"ㄍㄨㄥㄨㄟ":"恭維/恭維",
"ㄍㄨㄥㄨㄢ":"貢丸/供碗",
"ㄍㄨㄥㄨㄣ":"公文",
"ㄍㄨㄥㄨㄤ":"躬往",
"ㄍㄨㄥㄩㄝ":"公約/共閱",
"ㄍㄨㄥㄩㄢ":"公園/公元",
"ㄍㄨㄥㄩㄣ":"公允/工運",
"ㄍㄨㄥㄩㄥ":"功用/共用",
"ㄍㄨㄦㄩㄢ":"孤兒院",
"ㄍㄨㄧㄆㄨ":"估衣鋪",
"ㄍㄨㄧㄢㄨ":"顧炎武",
"ㄍㄨㄨㄣㄗ":"古文字",
"ㄎㄚㄆㄧㄢ":"卡片",
"ㄎㄚㄊㄨㄥ":"卡通",
"ㄎㄚㄋㄨㄥ":"卡農",
"ㄎㄚㄌㄨㄣ":"卡倫",
"ㄎㄚㄑㄧㄢ":"卡鉗",
"ㄎㄚㄒㄧㄝ":"咳血",
"ㄎㄚㄗㄨㄛ":"卡座",
"ㄎㄜㄅㄧㄝ":"科別",
"ㄎㄜㄅㄧㄠ":"課表",
"ㄎㄜㄅㄧㄢ":"可便/客邊",
"ㄎㄜㄅㄧㄥ":"渴病",
"ㄎㄜㄅㄨㄕ":"可不是",
"ㄎㄜㄆㄥㄦ":"磕碰兒",
"ㄎㄜㄆㄧㄠ":"客票",
"ㄎㄜㄇㄧㄣ":"恪敏",
"ㄎㄜㄇㄧㄥ":"克明/科名",
"ㄎㄜㄈㄣㄗ":"克分子",
"ㄎㄜㄉㄧㄢ":"客店",
"ㄎㄜㄉㄧㄥ":"科定/克定",
"ㄎㄜㄉㄨㄟ":"客隊",
"ㄎㄜㄉㄨㄢ":"科斷/科段",
"ㄎㄜㄉㄨㄣ":"可敦",
"ㄎㄜㄊㄡㄦ":"磕頭兒",
"ㄎㄜㄊㄧㄠ":"科條",
"ㄎㄜㄊㄧㄥ":"客廳",
"ㄎㄜㄊㄨㄛ":"咳唾",
"ㄎㄜㄋㄧㄢ":"可念/渴念",
"ㄎㄜㄋㄧㄥ":"克寧",
"ㄎㄜㄋㄩㄝ":"苛虐",
"ㄎㄜㄌㄧㄢ":"可憐/科斂",
"ㄎㄜㄌㄧㄥ":"科令",
"ㄎㄜㄌㄨㄛ":"剋落",
"ㄎㄜㄌㄨㄣ":"客輪",
"ㄎㄜㄍㄨㄛ":"殼果",
"ㄎㄜㄍㄨㄞ":"可怪",
"ㄎㄜㄍㄨㄟ":"可貴",
"ㄎㄜㄍㄨㄢ":"客觀/可觀",
"ㄎㄜㄍㄨㄥ":"刻工",
"ㄎㄜㄏㄨㄚ":"刻劃/刻畫",
"ㄎㄜㄏㄨㄛ":"客火",
"ㄎㄜㄏㄨㄞ":"渴懷",
"ㄎㄜㄏㄨㄢ":"科幻",
"ㄎㄜㄏㄨㄣ":"科諢",
"ㄎㄜㄐㄧㄚ":"客家/可加",
"ㄎㄜㄐㄧㄝ":"克捷",
"ㄎㄜㄐㄧㄡ":"可就/窠臼",
"ㄎㄜㄐㄧㄢ":"可見",
"ㄎㄜㄐㄧㄣ":"課金/可矜",
"ㄎㄜㄐㄧㄥ":"可敬",
"ㄎㄜㄐㄩㄢ":"課卷",
"ㄎㄜㄑㄧㄝ":"苛切",
"ㄎㄜㄑㄧㄠ":"可巧/刻峭",
"ㄎㄜㄑㄧㄡ":"渴求/苛求",
"ㄎㄜㄑㄧㄢ":"課錢",
"ㄎㄜㄑㄧㄣ":"可親/可欽",
"ㄎㄜㄑㄧㄥ":"客情/客卿",
"ㄎㄜㄑㄩㄢ":"課勸",
"ㄎㄜㄒㄧㄚ":"刻下",
"ㄎㄜㄒㄧㄝ":"喀血/溘謝",
"ㄎㄜㄒㄧㄠ":"可笑",
"ㄎㄜㄒㄧㄢ":"可嫌",
"ㄎㄜㄒㄧㄣ":"可信/可心",
"ㄎㄜㄒㄧㄤ":"渴想",
"ㄎㄜㄒㄧㄥ":"可行/剋星",
"ㄎㄜㄒㄩㄝ":"科學/刻削",
"ㄎㄜㄓㄉㄠ":"可知道",
"ㄎㄜㄓㄤㄙ":"客帳司",
"ㄎㄜㄓㄥㄕ":"可正是",
"ㄎㄜㄓㄨㄥ":"客中",
"ㄎㄜㄔㄨㄢ":"客串/客船",
"ㄎㄜㄕㄌㄞ":"可是來",
"ㄎㄜㄕㄨㄟ":"課稅/科稅",
"ㄎㄜㄖㄢㄨ":"可燃物",
"ㄎㄜㄖㄣㄧ":"可人意",
"ㄎㄜㄗㄨㄛ":"可作/客作",
"ㄎㄜㄗㄨㄟ":"可罪/科罪",
"ㄎㄜㄗㄨㄣ":"恪遵",
"ㄎㄜㄙㄨㄟ":"客歲/苛碎",
"ㄎㄜㄙㄨㄢ":"課算",
"ㄎㄜㄧㄖㄣ":"可意人",
"ㄎㄜㄨㄉㄜ":"可兀的",
"ㄎㄜㄩㄢㄗ":"克原子",
"ㄎㄞㄅㄧㄠ":"開標",
"ㄎㄞㄆㄢㄗ":"開盤子",
"ㄎㄞㄆㄧㄠ":"開票",
"ㄎㄞㄆㄧㄢ":"開片/開篇",
"ㄎㄞㄇㄧㄠ":"開廟",
"ㄎㄞㄇㄧㄢ":"開面",
"ㄎㄞㄇㄧㄣ":"開敏",
"ㄎㄞㄇㄧㄥ":"開明",
"ㄎㄞㄇㄨㄕ":"開幕式",
"ㄎㄞㄈㄥㄕ":"開封市",
"ㄎㄞㄉㄧㄠ":"開弔",
"ㄎㄞㄉㄧㄢ":"開店",
"ㄎㄞㄉㄨㄢ":"開端",
"ㄎㄞㄉㄨㄥ":"開動/開冬",
"ㄎㄞㄊㄧㄥ":"開庭",
"ㄎㄞㄊㄨㄛ":"開拓/開脫",
"ㄎㄞㄊㄨㄥ":"開通/開通",
"ㄎㄞㄋㄧㄢ":"開年",
"ㄎㄞㄌㄧㄝ":"開列",
"ㄎㄞㄌㄧㄡ":"開溜",
"ㄎㄞㄌㄧㄢ":"開臉",
"ㄎㄞㄌㄨㄛ":"開鑼/開落",
"ㄎㄞㄍㄨㄛ":"開國",
"ㄎㄞㄍㄨㄢ":"開關/開館",
"ㄎㄞㄍㄨㄤ":"開光",
"ㄎㄞㄍㄨㄥ":"開工/開弓",
"ㄎㄞㄎㄡㄗ":"開口子",
"ㄎㄞㄎㄨㄛ":"開闊",
"ㄎㄞㄎㄨㄤ":"開礦/開曠",
"ㄎㄞㄏㄨㄚ":"開花/開話",
"ㄎㄞㄏㄨㄛ":"開火/開伙",
"ㄎㄞㄏㄨㄞ":"開懷",
"ㄎㄞㄏㄨㄟ":"開會",
"ㄎㄞㄏㄨㄣ":"開葷",
"ㄎㄞㄏㄨㄤ":"開荒",
"ㄎㄞㄏㄨㄥ":"開鬨",
"ㄎㄞㄐㄧㄚ":"開架/開價",
"ㄎㄞㄐㄧㄝ":"開解/開戒",
"ㄎㄞㄐㄧㄠ":"開交",
"ㄎㄞㄐㄧㄡ":"開酒",
"ㄎㄞㄐㄧㄢ":"開間",
"ㄎㄞㄐㄧㄣ":"開金/開禁",
"ㄎㄞㄐㄧㄤ":"開講/開江",
"ㄎㄞㄐㄧㄥ":"開鏡/開靜",
"ㄎㄞㄐㄩㄝ":"開掘",
"ㄎㄞㄐㄩㄢ":"開卷",
"ㄎㄞㄐㄩㄣ":"開濬",
"ㄎㄞㄑㄧㄝ":"剴切",
"ㄎㄞㄑㄧㄠ":"開竅",
"ㄎㄞㄑㄧㄡ":"開球/開秋",
"ㄎㄞㄑㄧㄤ":"開槍/開腔",
"ㄎㄞㄑㄩㄝ":"開缺",
"ㄎㄞㄒㄧㄠ":"開消/開銷",
"ㄎㄞㄒㄧㄢ":"開線",
"ㄎㄞㄒㄧㄣ":"開心",
"ㄎㄞㄒㄧㄤ":"慨想",
"ㄎㄞㄒㄧㄥ":"開行",
"ㄎㄞㄒㄩㄝ":"開學",
"ㄎㄞㄒㄩㄢ":"凱旋",
"ㄎㄞㄒㄩㄣ":"開訓",
"ㄎㄞㄔㄨㄢ":"開船",
"ㄎㄞㄔㄨㄣ":"開春",
"ㄎㄞㄔㄨㄤ":"開創",
"ㄎㄞㄕㄨㄟ":"開水",
"ㄎㄞㄗㄨㄛ":"開鑿",
"ㄎㄞㄗㄨㄟ":"開罪",
"ㄎㄞㄙㄇㄣ":"開私門",
"ㄎㄞㄙㄇㄧ":"開司米",
"ㄎㄞㄙㄨㄟ":"開歲",
"ㄎㄠㄅㄟㄓ":"拷貝紙",
"ㄎㄠㄅㄧㄢ":"靠邊",
"ㄎㄠㄆㄧㄥ":"考評",
"ㄎㄠㄉㄧㄢ":"靠墊",
"ㄎㄠㄉㄧㄥ":"考訂",
"ㄎㄠㄊㄧㄢ":"靠天",
"ㄎㄠㄊㄧㄥ":"考亭",
"ㄎㄠㄌㄧㄠ":"銬鐐",
"ㄎㄠㄌㄧㄤ":"考量",
"ㄎㄠㄌㄨㄣ":"考論",
"ㄎㄠㄌㄨㄥ":"靠攏",
"ㄎㄠㄌㄩㄝ":"考掠",
"ㄎㄠㄍㄨㄢ":"考官",
"ㄎㄠㄍㄨㄥ":"考功",
"ㄎㄠㄏㄨㄛ":"烤火",
"ㄎㄠㄐㄧㄠ":"考校/栲膠",
"ㄎㄠㄐㄧㄡ":"考究/考究",
"ㄎㄠㄐㄧㄢ":"考檢",
"ㄎㄠㄐㄧㄣ":"靠近",
"ㄎㄠㄐㄧㄥ":"考竟/考鏡",
"ㄎㄠㄐㄩㄢ":"考卷",
"ㄎㄠㄐㄩㄣ":"犒軍",
"ㄎㄠㄑㄧㄡ":"考求",
"ㄎㄠㄑㄧㄣ":"考勤",
"ㄎㄠㄒㄧㄤ":"烤箱",
"ㄎㄠㄒㄩㄢ":"考選",
"ㄎㄠㄒㄩㄣ":"拷訊",
"ㄎㄠㄓㄨㄥ":"考中/考終",
"ㄎㄠㄕㄩㄢ":"考試院",
"ㄎㄠㄗㄨㄛ":"尻坐",
"ㄎㄡㄅㄧㄢ":"扣邊/口辯",
"ㄎㄡㄇㄠㄗ":"扣帽子",
"ㄎㄡㄉㄧㄠ":"扣掉",
"ㄎㄡㄉㄨㄣ":"口鈍",
"ㄎㄡㄊㄡㄩ":"口頭語",
"ㄎㄡㄊㄧㄠ":"口條",
"ㄎㄡㄊㄧㄥ":"扣廳",
"ㄎㄡㄊㄧㄧ":"口蹄疫",
"ㄎㄡㄌㄧㄡ":"扣留",
"ㄎㄡㄌㄧㄤ":"口糧",
"ㄎㄡㄌㄧㄥ":"口令",
"ㄎㄡㄌㄨㄢ":"寇亂",
"ㄎㄡㄍㄨㄛ":"口過",
"ㄎㄡㄍㄨㄢ":"叩關",
"ㄎㄡㄍㄨㄥ":"口供",
"ㄎㄡㄎㄨㄞ":"口快",
"ㄎㄡㄏㄨㄚ":"口滑",
"ㄎㄡㄏㄨㄟ":"口惠",
"ㄎㄡㄏㄨㄢ":"彄環",
"ㄎㄡㄏㄨㄣ":"叩閽",
"ㄎㄡㄏㄨㄥ":"口紅",
"ㄎㄡㄐㄧㄠ":"口角/扣繳",
"ㄎㄡㄐㄧㄢ":"叩見",
"ㄎㄡㄐㄧㄣ":"口緊/口勁",
"ㄎㄡㄐㄧㄥ":"口徑/口靜",
"ㄎㄡㄐㄩㄝ":"口角/口訣",
"ㄎㄡㄑㄧㄡ":"扣球",
"ㄎㄡㄑㄧㄢ":"口錢",
"ㄎㄡㄑㄧㄣ":"口琴",
"ㄎㄡㄑㄧㄤ":"口腔/口強",
"ㄎㄡㄑㄧㄥ":"口輕",
"ㄎㄡㄒㄧㄝ":"叩謝",
"ㄎㄡㄒㄧㄢ":"扣舷",
"ㄎㄡㄒㄧㄣ":"口信",
"ㄎㄡㄒㄧㄥ":"口形",
"ㄎㄡㄒㄩㄢ":"口宣",
"ㄎㄡㄒㄩㄣ":"寇恂",
"ㄎㄡㄓㄨㄛ":"口拙",
"ㄎㄡㄓㄨㄣ":"寇準",
"ㄎㄡㄓㄨㄥ":"口重",
"ㄎㄡㄔㄨㄜ":"扣除額",
"ㄎㄡㄔㄨㄢ":"口傳",
"ㄎㄡㄔㄨㄤ":"口瘡",
"ㄎㄡㄕㄨㄟ":"口水",
"ㄎㄡㄕㄨㄣ":"口順",
"ㄎㄡㄗㄕㄥ":"扣子繩",
"ㄎㄡㄗㄨㄟ":"口嘴",
"ㄎㄢㄅㄧㄢ":"看扁",
"ㄎㄢㄅㄧㄥ":"看病",
"ㄎㄢㄈㄤㄗ":"看房子",
"ㄎㄢㄉㄧㄥ":"刊定",
"ㄎㄢㄉㄨㄢ":"砍斷",
"ㄎㄢㄌㄨㄛ":"刊落",
"ㄎㄢㄌㄨㄢ":"戡亂",
"ㄎㄢㄍㄨㄢ":"看管/看官",
"ㄎㄢㄏㄨㄣ":"勘婚",
"ㄎㄢㄐㄧㄚ":"看家",
"ㄎㄢㄐㄧㄠ":"看轎",
"ㄎㄢㄐㄧㄡ":"看酒",
"ㄎㄢㄐㄧㄢ":"看見/勘檢",
"ㄎㄢㄐㄧㄥ":"坎阱",
"ㄎㄢㄑㄧㄠ":"看俏",
"ㄎㄢㄑㄧㄥ":"看輕/看青",
"ㄎㄢㄒㄧㄠ":"看小",
"ㄎㄢㄒㄧㄤ":"看相",
"ㄎㄢㄒㄧㄥ":"刊行",
"ㄎㄢㄓㄨㄣ":"看準",
"ㄎㄢㄓㄨㄥ":"看中/看重",
"ㄎㄢㄔㄨㄢ":"看穿",
"ㄎㄢㄘㄨㄛ":"看錯",
"ㄎㄢㄧㄕㄥ":"看醫生",
"ㄎㄢㄧㄤㄗ":"看樣子",
"ㄎㄢㄩㄕㄨ":"堪輿術",
"ㄎㄣㄉㄧㄥ":"肯定",
"ㄎㄣㄊㄧㄢ":"墾田",
"ㄎㄣㄊㄨㄛ":"懇託",
"ㄎㄣㄎㄨㄣ":"懇悃",
"ㄎㄣㄏㄨㄤ":"墾荒",
"ㄎㄣㄐㄧㄡ":"肯酒",
"ㄎㄣㄑㄧㄝ":"懇切",
"ㄎㄣㄑㄧㄡ":"懇求",
"ㄎㄣㄑㄧㄣ":"懇親",
"ㄎㄣㄑㄧㄥ":"懇請/肯綮",
"ㄎㄤㄅㄧㄢ":"抗辯",
"ㄎㄤㄇㄧㄥ":"抗命",
"ㄎㄤㄉㄨㄥ":"炕洞",
"ㄎㄤㄋㄧㄢ":"康年",
"ㄎㄤㄋㄧㄥ":"康寧",
"ㄎㄤㄌㄧㄤ":"康梁/抗糧",
"ㄎㄤㄌㄨㄗ":"炕爐子",
"ㄎㄤㄌㄨㄣ":"抗論",
"ㄎㄤㄎㄤㄨ":"康康舞",
"ㄎㄤㄏㄨㄛ":"扛活",
"ㄎㄤㄏㄨㄟ":"康回",
"ㄎㄤㄐㄧㄝ":"抗節",
"ㄎㄤㄐㄧㄢ":"康健/伉健",
"ㄎㄤㄐㄧㄣ":"亢進",
"ㄎㄤㄐㄩㄝ":"康爵",
"ㄎㄤㄑㄧㄤ":"康強",
"ㄎㄤㄑㄧㄥ":"康青",
"ㄎㄤㄒㄧㄚ":"糠蝦/伉俠",
"ㄎㄤㄒㄧㄥ":"抗行",
"ㄎㄤㄓㄨㄤ":"康莊",
"ㄎㄤㄔㄨㄤ":"匟床",
"ㄎㄤㄕㄨㄟ":"抗稅",
"ㄎㄤㄗㄨㄥ":"亢宗",
"ㄎㄤㄙㄨㄟ":"糠穗",
"ㄎㄤㄧㄕㄨ":"抗議書",
"ㄎㄥㄆㄧㄢ":"坑騙",
"ㄎㄥㄉㄨㄥ":"坑洞",
"ㄎㄥㄐㄧㄚ":"傾家",
"ㄎㄥㄑㄧㄢ":"坑塹",
"ㄎㄥㄑㄧㄤ":"鏗鏘",
"ㄎㄥㄒㄧㄢ":"坑陷",
"ㄎㄨㄅㄧㄥ":"庫兵",
"ㄎㄨㄆㄧㄥ":"庫平",
"ㄎㄨㄇㄧㄥ":"苦命",
"ㄎㄨㄉㄚㄕ":"庫大使",
"ㄎㄨㄉㄧㄠ":"哭調",
"ㄎㄨㄉㄨㄛ":"苦多",
"ㄎㄨㄉㄨㄢ":"庫緞",
"ㄎㄨㄊㄨㄟ":"褲腿",
"ㄎㄨㄊㄨㄥ":"苦痛",
"ㄎㄨㄋㄠㄗ":"苦腦子",
"ㄎㄨㄋㄩㄝ":"酷虐",
"ㄎㄨㄌㄧㄝ":"酷烈",
"ㄎㄨㄌㄧㄡ":"苦留",
"ㄎㄨㄌㄧㄢ":"苦練",
"ㄎㄨㄌㄧㄣ":"哭臨",
"ㄎㄨㄌㄨㄣ":"庫倫/庫侖",
"ㄎㄨㄌㄨㄥ":"窟窿",
"ㄎㄨㄍㄨㄚ":"苦瓜",
"ㄎㄨㄍㄨㄛ":"苦果",
"ㄎㄨㄍㄨㄢ":"褲管",
"ㄎㄨㄍㄨㄥ":"苦功/苦工",
"ㄎㄨㄎㄨㄥ":"苦空",
"ㄎㄨㄏㄨㄗ":"苦瓠子",
"ㄎㄨㄏㄨㄤ":"枯黃",
"ㄎㄨㄐㄧㄝ":"苦節/枯竭",
"ㄎㄨㄐㄧㄠ":"褲腳/窟窖",
"ㄎㄨㄐㄧㄢ":"苦諫",
"ㄎㄨㄐㄧㄥ":"苦境/枯井",
"ㄎㄨㄐㄩㄥ":"枯窘",
"ㄎㄨㄑㄧㄡ":"苦求",
"ㄎㄨㄑㄧㄤ":"哭牆",
"ㄎㄨㄑㄩㄢ":"苦勸/庫券",
"ㄎㄨㄑㄩㄣ":"褲裙",
"ㄎㄨㄑㄩㄥ":"哭窮",
"ㄎㄨㄒㄧㄚ":"苦夏",
"ㄎㄨㄒㄧㄠ":"苦笑/酷肖",
"ㄎㄨㄒㄧㄡ":"苦修/枯朽",
"ㄎㄨㄒㄧㄣ":"苦心/苦辛",
"ㄎㄨㄒㄧㄤ":"苦相",
"ㄎㄨㄒㄧㄥ":"酷刑/苦行",
"ㄎㄨㄒㄩㄝ":"褲靴/窟穴",
"ㄎㄨㄓㄨㄤ":"褲裝/枯樁",
"ㄎㄨㄓㄨㄥ":"苦衷",
"ㄎㄨㄔㄚㄦ":"褲叉兒",
"ㄎㄨㄔㄞㄕ":"苦差事",
"ㄎㄨㄔㄨㄢ":"苦船",
"ㄎㄨㄕㄨㄟ":"苦水",
"ㄎㄨㄖㄨㄥ":"枯榮",
"ㄎㄨㄗㄨㄛ":"枯坐",
"ㄎㄨㄘㄨㄣ":"庫存",
"ㄎㄨㄙㄨㄛ":"枯索",
"ㄎㄨㄚㄅㄨ":"跨步",
"ㄎㄨㄚㄆㄧ":"夸毗",
"ㄎㄨㄚㄈㄨ":"夸父",
"ㄎㄨㄚㄉㄚ":"誇大",
"ㄎㄨㄚㄉㄠ":"跨刀",
"ㄎㄨㄚㄉㄢ":"誇誕/夸誕",
"ㄎㄨㄚㄉㄤ":"跨黨",
"ㄎㄨㄚㄉㄨ":"跨度",
"ㄎㄨㄚㄊㄜ":"夸特",
"ㄎㄨㄚㄊㄞ":"垮臺",
"ㄎㄨㄚㄌㄢ":"跨欄",
"ㄎㄨㄚㄌㄧ":"夸麗",
"ㄎㄨㄚㄍㄨ":"胯骨/跨鼓",
"ㄎㄨㄚㄎㄜ":"夸克",
"ㄎㄨㄚㄎㄡ":"誇口",
"ㄎㄨㄚㄏㄜ":"跨鶴",
"ㄎㄨㄚㄏㄞ":"跨海",
"ㄎㄨㄚㄏㄤ":"跨行",
"ㄎㄨㄚㄒㄩ":"誇詡",
"ㄎㄨㄚㄓㄤ":"誇張",
"ㄎㄨㄚㄖㄣ":"夸人",
"ㄎㄨㄚㄗㄠ":"跨灶",
"ㄎㄨㄚㄗㄢ":"誇讚",
"ㄎㄨㄚㄢㄗ":"跨鞍子",
"ㄎㄨㄚㄧㄝ":"跨頁",
"ㄎㄨㄚㄧㄠ":"誇耀",
"ㄎㄨㄚㄩㄝ":"跨越",
"ㄎㄨㄛㄅㄨ":"闊步",
"ㄎㄨㄛㄈㄣ":"擴分",
"ㄎㄨㄛㄉㄚ":"擴大/闊大",
"ㄎㄨㄛㄊㄨ":"廓土",
"ㄎㄨㄛㄌㄠ":"闊老",
"ㄎㄨㄛㄐㄧ":"擴及/彉騎",
"ㄎㄨㄛㄑㄧ":"闊氣",
"ㄎㄨㄛㄓㄢ":"擴展",
"ㄎㄨㄛㄓㄤ":"擴張",
"ㄎㄨㄛㄕㄠ":"闊少",
"ㄎㄨㄛㄖㄣ":"闊人",
"ㄎㄨㄛㄗㄥ":"擴增",
"ㄎㄨㄛㄙㄢ":"擴散",
"ㄎㄨㄛㄧㄣ":"擴音/擴印",
"ㄎㄨㄞㄅㄛ":"檜柏",
"ㄎㄨㄞㄅㄠ":"快報",
"ㄎㄨㄞㄅㄢ":"快板",
"ㄎㄨㄞㄆㄠ":"快炮",
"ㄎㄨㄞㄇㄣ":"快門",
"ㄎㄨㄞㄇㄨ":"檜木",
"ㄎㄨㄞㄉㄤ":"快當",
"ㄎㄨㄞㄉㄧ":"快遞",
"ㄎㄨㄞㄊㄡ":"塊頭",
"ㄎㄨㄞㄌㄜ":"快樂",
"ㄎㄨㄞㄌㄟ":"塊磊",
"ㄎㄨㄞㄌㄢ":"快覽",
"ㄎㄨㄞㄍㄢ":"快感",
"ㄎㄨㄞㄍㄣ":"塊根",
"ㄎㄨㄞㄎㄜ":"快克",
"ㄎㄨㄞㄐㄧ":"會計",
"ㄎㄨㄞㄒㄩ":"快婿",
"ㄎㄨㄞㄓㄠ":"快照",
"ㄎㄨㄞㄔㄜ":"快車",
"ㄎㄨㄞㄕㄡ":"快手",
"ㄎㄨㄞㄕㄨ":"快書",
"ㄎㄨㄞㄖㄢ":"塊然",
"ㄎㄨㄞㄖㄣ":"快人",
"ㄎㄨㄞㄘㄢ":"快餐",
"ㄎㄨㄞㄙㄨ":"快速",
"ㄎㄨㄞㄧㄠ":"快要",
"ㄎㄨㄞㄧㄣ":"快印",
"ㄎㄨㄞㄨㄟ":"快慰",
"ㄎㄨㄟㄅㄞ":"潰敗",
"ㄎㄨㄟㄅㄣ":"虧本",
"ㄎㄨㄟㄅㄨ":"跬步/蹞步",
"ㄎㄨㄟㄇㄠ":"憒眊",
"ㄎㄨㄟㄇㄥ":"匱盟/潰盟",
"ㄎㄨㄟㄈㄚ":"匱乏",
"ㄎㄨㄟㄈㄨ":"愧服/虧負",
"ㄎㄨㄟㄉㄜ":"虧得",
"ㄎㄨㄟㄉㄞ":"虧待",
"ㄎㄨㄟㄊㄠ":"潰逃",
"ㄎㄨㄟㄊㄡ":"盔頭/魁頭",
"ㄎㄨㄟㄊㄢ":"窺探/喟嘆",
"ㄎㄨㄟㄊㄧ":"奎蹄",
"ㄎㄨㄟㄊㄨ":"虧圖/窺圖",
"ㄎㄨㄟㄋㄢ":"愧赧",
"ㄎㄨㄟㄋㄩ":"愧恧",
"ㄎㄨㄟㄌㄟ":"傀儡/虧累",
"ㄎㄨㄟㄌㄢ":"潰爛",
"ㄎㄨㄟㄌㄨ":"饋路",
"ㄎㄨㄟㄍㄨ":"睽孤",
"ㄎㄨㄟㄏㄜ":"暌合",
"ㄎㄨㄟㄏㄠ":"虧耗",
"ㄎㄨㄟㄏㄢ":"愧汗",
"ㄎㄨㄟㄏㄣ":"愧恨",
"ㄎㄨㄟㄐㄧ":"窺基/窺覬",
"ㄎㄨㄟㄒㄧ":"揆席",
"ㄎㄨㄟㄓㄜ":"虧折",
"ㄎㄨㄟㄓㄤ":"奎章",
"ㄎㄨㄟㄕㄚ":"虧殺",
"ㄎㄨㄟㄕㄜ":"窺涉",
"ㄎㄨㄟㄕㄡ":"魁手/魁首",
"ㄎㄨㄟㄕㄢ":"葵扇",
"ㄎㄨㄟㄕㄨ":"虧輸",
"ㄎㄨㄟㄖㄢ":"喟然/巋然",
"ㄎㄨㄟㄖㄣ":"饋人",
"ㄎㄨㄟㄗㄥ":"饋贈/餽贈",
"ㄎㄨㄟㄘㄜ":"窺測",
"ㄎㄨㄟㄙㄜ":"愧色",
"ㄎㄨㄟㄙㄢ":"潰散",
"ㄎㄨㄟㄧㄤ":"潰瘍",
"ㄎㄨㄟㄨㄟ":"潰圍/魁偉",
"ㄎㄨㄟㄨㄣ":"夔紋",
"ㄎㄨㄟㄩㄥ":"潰癰",
"ㄎㄨㄢㄅㄛ":"寬博",
"ㄎㄨㄢㄅㄨ":"款步",
"ㄎㄨㄢㄆㄧ":"寬譬",
"ㄎㄨㄢㄇㄢ":"款慢",
"ㄎㄨㄢㄇㄣ":"款門",
"ㄎㄨㄢㄇㄧ":"款密",
"ㄎㄨㄢㄇㄨ":"款目",
"ㄎㄨㄢㄈㄢ":"寬泛",
"ㄎㄨㄢㄈㄨ":"款服/款附",
"ㄎㄨㄢㄉㄚ":"寬大/款打",
"ㄎㄨㄢㄉㄞ":"款待/寬待",
"ㄎㄨㄢㄉㄢ":"款單",
"ㄎㄨㄢㄉㄨ":"寬度",
"ㄎㄨㄢㄊㄚ":"款踏",
"ㄎㄨㄢㄊㄧ":"款題",
"ㄎㄨㄢㄍㄨ":"髖骨",
"ㄎㄨㄢㄎㄜ":"款客",
"ㄎㄨㄢㄏㄜ":"寬和",
"ㄎㄨㄢㄏㄡ":"寬厚",
"ㄎㄨㄢㄑㄩ":"款曲",
"ㄎㄨㄢㄓㄞ":"寬窄",
"ㄎㄨㄢㄓㄢ":"寬展",
"ㄎㄨㄢㄓㄨ":"款住/寬住",
"ㄎㄨㄢㄔㄤ":"寬敞/寬暢",
"ㄎㄨㄢㄕㄨ":"寬恕/寬舒",
"ㄎㄨㄢㄖㄠ":"寬饒",
"ㄎㄨㄢㄖㄤ":"寬讓",
"ㄎㄨㄢㄙㄞ":"款塞",
"ㄎㄨㄢㄧㄠ":"款要",
"ㄎㄨㄢㄧㄡ":"寬宥",
"ㄎㄨㄢㄧㄢ":"款宴",
"ㄎㄨㄢㄧㄣ":"款引",
"ㄎㄨㄢㄨㄟ":"寬慰",
"ㄎㄨㄣㄅㄟ":"困憊",
"ㄎㄨㄣㄅㄤ":"綑綁/捆綁",
"ㄎㄨㄣㄅㄧ":"悃愊",
"ㄎㄨㄣㄅㄨ":"昆布",
"ㄎㄨㄣㄆㄥ":"鯤鵬",
"ㄎㄨㄣㄈㄚ":"髡髮/困乏",
"ㄎㄨㄣㄈㄢ":"坤範/壼範",
"ㄎㄨㄣㄈㄥ":"綑風",
"ㄎㄨㄣㄉㄜ":"坤德",
"ㄎㄨㄣㄉㄡ":"困鬥",
"ㄎㄨㄣㄉㄤ":"褌襠",
"ㄎㄨㄣㄉㄧ":"昆弟",
"ㄎㄨㄣㄊㄧ":"騉蹄",
"ㄎㄨㄣㄊㄨ":"騉駼",
"ㄎㄨㄣㄋㄢ":"困難",
"ㄎㄨㄣㄍㄤ":"崑岡",
"ㄎㄨㄣㄎㄨ":"困苦",
"ㄎㄨㄣㄐㄧ":"坤極/閫寄",
"ㄎㄨㄣㄐㄩ":"困局/崑劇",
"ㄎㄨㄣㄑㄩ":"崑曲",
"ㄎㄨㄣㄒㄧ":"坤戲",
"ㄎㄨㄣㄓㄞ":"坤宅",
"ㄎㄨㄣㄓㄡ":"坤軸",
"ㄎㄨㄣㄓㄥ":"壼政",
"ㄎㄨㄣㄓㄨ":"困住",
"ㄎㄨㄣㄔㄥ":"悃誠",
"ㄎㄨㄣㄔㄨ":"困處",
"ㄎㄨㄣㄕㄡ":"困守/困獸",
"ㄎㄨㄣㄕㄢ":"崑山",
"ㄎㄨㄣㄕㄣ":"鯤鯓",
"ㄎㄨㄣㄕㄨ":"坤書",
"ㄎㄨㄣㄖㄠ":"困擾",
"ㄎㄨㄣㄖㄣ":"困人",
"ㄎㄨㄣㄗㄚ":"綑紮",
"ㄎㄨㄣㄗㄞ":"稛載",
"ㄎㄨㄣㄗㄠ":"坤造",
"ㄎㄨㄣㄗㄨ":"困阻",
"ㄎㄨㄣㄘㄢ":"髡殘",
"ㄎㄨㄣㄘㄨ":"困蹙",
"ㄎㄨㄣㄧㄝ":"昆邪",
"ㄎㄨㄣㄧㄠ":"焜燿",
"ㄎㄨㄣㄧㄤ":"昆陽",
"ㄎㄨㄣㄨㄟ":"坤維/閫威",
"ㄎㄨㄣㄩㄢ":"坤元",
"ㄎㄨㄤㄅㄟ":"狂悖",
"ㄎㄨㄤㄅㄠ":"狂暴",
"ㄎㄨㄤㄅㄣ":"狂奔",
"ㄎㄨㄤㄇㄞ":"礦脈",
"ㄎㄨㄤㄇㄧ":"狂迷",
"ㄎㄨㄤㄈㄟ":"曠費/曠廢",
"ㄎㄨㄤㄈㄤ":"狂放/曠放",
"ㄎㄨㄤㄈㄥ":"狂風",
"ㄎㄨㄤㄈㄨ":"狂夫/匡復",
"ㄎㄨㄤㄉㄚ":"曠達",
"ㄎㄨㄤㄉㄞ":"曠代",
"ㄎㄨㄤㄉㄢ":"狂誕/誑誕",
"ㄎㄨㄤㄉㄤ":"匡當/狂蕩",
"ㄎㄨㄤㄉㄥ":"礦燈",
"ㄎㄨㄤㄉㄨ":"曠度",
"ㄎㄨㄤㄊㄞ":"狂態",
"ㄎㄨㄤㄊㄧ":"礦體",
"ㄎㄨㄤㄊㄨ":"框圖/狂徒",
"ㄎㄨㄤㄋㄞ":"況乃/況迺",
"ㄎㄨㄤㄌㄢ":"狂瀾",
"ㄎㄨㄤㄌㄤ":"壙埌",
"ㄎㄨㄤㄍㄜ":"狂歌",
"ㄎㄨㄤㄍㄨ":"曠古/狂瞽",
"ㄎㄨㄤㄎㄜ":"曠課/狂客",
"ㄎㄨㄤㄎㄥ":"礦坑",
"ㄎㄨㄤㄏㄜ":"匡合",
"ㄎㄨㄤㄏㄞ":"恇駭",
"ㄎㄨㄤㄏㄠ":"狂嗥",
"ㄎㄨㄤㄏㄥ":"匡衡",
"ㄎㄨㄤㄏㄨ":"況乎",
"ㄎㄨㄤㄐㄧ":"狂疾/匡濟",
"ㄎㄨㄤㄐㄩ":"狂且/恇懼",
"ㄎㄨㄤㄑㄧ":"狂氣",
"ㄎㄨㄤㄑㄩ":"礦區",
"ㄎㄨㄤㄒㄧ":"狂喜",
"ㄎㄨㄤㄓㄥ":"匡正",
"ㄎㄨㄤㄓㄨ":"匡助",
"ㄎㄨㄤㄔㄠ":"狂潮",
"ㄎㄨㄤㄔㄢ":"礦產",
"ㄎㄨㄤㄔㄣ":"礦塵",
"ㄎㄨㄤㄔㄤ":"礦場",
"ㄎㄨㄤㄔㄥ":"誑稱",
"ㄎㄨㄤㄕㄚ":"礦砂",
"ㄎㄨㄤㄕㄢ":"礦山",
"ㄎㄨㄤㄕㄤ":"誑上",
"ㄎㄨㄤㄕㄥ":"狂生",
"ㄎㄨㄤㄖㄜ":"狂熱",
"ㄎㄨㄤㄖㄠ":"恇擾",
"ㄎㄨㄤㄖㄢ":"曠然",
"ㄎㄨㄤㄖㄣ":"狂人",
"ㄎㄨㄤㄖㄤ":"劻勷",
"ㄎㄨㄤㄗㄟ":"狂賊",
"ㄎㄨㄤㄘㄠ":"狂草",
"ㄎㄨㄤㄘㄤ":"礦藏",
"ㄎㄨㄤㄘㄥ":"礦層",
"ㄎㄨㄤㄧㄝ":"狂野/曠野",
"ㄎㄨㄤㄧㄠ":"狂藥",
"ㄎㄨㄤㄧㄡ":"礦油/貺祐",
"ㄎㄨㄤㄧㄢ":"狂言/誑言",
"ㄎㄨㄤㄧㄣ":"狂飲",
"ㄎㄨㄤㄨㄓ":"礦物質",
"ㄎㄨㄤㄨㄟ":"況味",
"ㄎㄨㄤㄨㄤ":"狂妄",
"ㄎㄨㄤㄩㄢ":"壙遠/曠遠",
"ㄎㄨㄥㄅㄛ":"空撥",
"ㄎㄨㄥㄅㄞ":"空白",
"ㄎㄨㄥㄅㄢ":"孔版",
"ㄎㄨㄥㄅㄨ":"恐怖",
"ㄎㄨㄥㄆㄚ":"恐怕",
"ㄎㄨㄥㄆㄛ":"孔坡",
"ㄎㄨㄥㄆㄠ":"空跑",
"ㄎㄨㄥㄇㄚ":"控馬",
"ㄎㄨㄥㄇㄣ":"空門/孔門",
"ㄎㄨㄥㄇㄥ":"空濛/孔孟",
"ㄎㄨㄥㄇㄨ":"孔目",
"ㄎㄨㄥㄈㄚ":"空乏",
"ㄎㄨㄥㄈㄛ":"孔佛",
"ㄎㄨㄥㄈㄟ":"空費",
"ㄎㄨㄥㄈㄢ":"空泛",
"ㄎㄨㄥㄈㄤ":"孔方/空房",
"ㄎㄨㄥㄈㄨ":"空腹/孔鮒",
"ㄎㄨㄥㄉㄚ":"空大",
"ㄎㄨㄥㄉㄜ":"孔德",
"ㄎㄨㄥㄉㄠ":"孔道",
"ㄎㄨㄥㄉㄤ":"空檔/空蕩",
"ㄎㄨㄥㄉㄥ":"空等",
"ㄎㄨㄥㄉㄧ":"空地",
"ㄎㄨㄥㄊㄡ":"空頭/空投",
"ㄎㄨㄥㄊㄢ":"空談",
"ㄎㄨㄥㄊㄤ":"空堂",
"ㄎㄨㄥㄋㄢ":"空難",
"ㄎㄨㄥㄌㄠ":"空勞",
"ㄎㄨㄥㄌㄨ":"空廬",
"ㄎㄨㄥㄌㄩ":"恐慮",
"ㄎㄨㄥㄍㄠ":"控告",
"ㄎㄨㄥㄍㄨ":"空谷",
"ㄎㄨㄥㄎㄡ":"空口",
"ㄎㄨㄥㄏㄜ":"恐嚇/控鶴",
"ㄎㄨㄥㄏㄞ":"空海",
"ㄎㄨㄥㄏㄠ":"空號",
"ㄎㄨㄥㄏㄡ":"箜篌",
"ㄎㄨㄥㄏㄢ":"空喊/空函",
"ㄎㄨㄥㄐㄧ":"空際/孔急",
"ㄎㄨㄥㄐㄩ":"恐懼",
"ㄎㄨㄥㄑㄧ":"空氣",
"ㄎㄨㄥㄒㄧ":"空隙/空襲",
"ㄎㄨㄥㄒㄩ":"空虛",
"ㄎㄨㄥㄓㄕ":"控制室",
"ㄎㄨㄥㄓㄢ":"空戰",
"ㄎㄨㄥㄓㄨ":"空竹",
"ㄎㄨㄥㄔㄤ":"空腸",
"ㄎㄨㄥㄔㄥ":"空城",
"ㄎㄨㄥㄕㄡ":"空手/空首",
"ㄎㄨㄥㄕㄢ":"空山",
"ㄎㄨㄥㄕㄣ":"空身",
"ㄎㄨㄥㄕㄨ":"空疏",
"ㄎㄨㄥㄖㄢ":"悾然",
"ㄎㄨㄥㄗㄠ":"空造",
"ㄎㄨㄥㄙㄤ":"空桑",
"ㄎㄨㄥㄙㄨ":"控訴",
"ㄎㄨㄥㄧㄡ":"恐憂",
"ㄎㄨㄥㄧㄢ":"空言/孔雁",
"ㄎㄨㄥㄨㄟ":"空位",
"ㄎㄨㄥㄨㄤ":"空王",
"ㄎㄨㄥㄩㄣ":"空運",
"ㄎㄨㄦㄏㄜ":"庫爾河",
"ㄎㄨㄩㄈㄥ":"哭雨風",
"ㄎㄨㄩㄓㄙ":"枯魚之肆",
"ㄏㄚㄇㄚㄗ":"蝦蟆子",
"ㄏㄚㄌㄚㄗ":"哈喇子",
"ㄏㄚㄌㄨㄛ":"哈囉",
"ㄏㄚㄏㄚㄦ":"哈哈兒",
"ㄏㄚㄏㄨㄚ":"哈話",
"ㄏㄚㄑㄧㄢ":"哈欠",
"ㄏㄚㄕㄇㄚ":"哈士蟆",
"ㄏㄜㄅㄚㄗ":"合八字",
"ㄏㄜㄅㄟㄗ":"河貝子",
"ㄏㄜㄅㄧㄠ":"賀表",
"ㄏㄜㄅㄧㄢ":"河邊/合變",
"ㄏㄜㄅㄧㄣ":"河濱",
"ㄏㄜㄅㄧㄥ":"合併/合并",
"ㄏㄜㄆㄧㄥ":"和平",
"ㄏㄜㄇㄢㄗ":"何滿子",
"ㄏㄜㄇㄧㄠ":"禾苗",
"ㄏㄜㄇㄧㄢ":"合面/河面",
"ㄏㄜㄇㄧㄥ":"喝命/和鳴",
"ㄏㄜㄈㄟㄕ":"褐飛蝨",
"ㄏㄜㄉㄧㄠ":"鶴弔",
"ㄏㄜㄉㄧㄢ":"核電/賀電",
"ㄏㄜㄉㄧㄥ":"核定",
"ㄏㄜㄉㄨㄛ":"喝掇",
"ㄏㄜㄉㄨㄟ":"核對",
"ㄏㄜㄉㄨㄥ":"河東/呵凍",
"ㄏㄜㄊㄧㄠ":"和調",
"ㄏㄜㄊㄨㄣ":"河豚",
"ㄏㄜㄊㄨㄥ":"合同/和同",
"ㄏㄜㄋㄧㄢ":"賀年",
"ㄏㄜㄋㄨㄢ":"和暖",
"ㄏㄜㄌㄧㄝ":"鶴列",
"ㄏㄜㄌㄧㄡ":"合流/河流",
"ㄏㄜㄌㄧㄣ":"和林/鶴林",
"ㄏㄜㄌㄧㄤ":"河梁/豁亮",
"ㄏㄜㄌㄧㄥ":"喝令/鶴齡",
"ㄏㄜㄌㄨㄛ":"和囉/喝囉",
"ㄏㄜㄌㄨㄢ":"和鸞",
"ㄏㄜㄌㄨㄥ":"合龍/合攏",
"ㄏㄜㄍㄨㄛ":"核果/闔國",
"ㄏㄜㄍㄨㄢ":"何關/鶡冠",
"ㄏㄜㄍㄨㄤ":"河廣",
"ㄏㄜㄍㄨㄥ":"河工/賀功",
"ㄏㄜㄎㄨㄢ":"合款",
"ㄏㄜㄎㄨㄣ":"賀悃",
"ㄏㄜㄎㄨㄤ":"何況",
"ㄏㄜㄏㄜㄖ":"和合日",
"ㄏㄜㄏㄨㄚ":"荷花",
"ㄏㄜㄏㄨㄛ":"合夥/合伙",
"ㄏㄜㄏㄨㄟ":"和會/呵會",
"ㄏㄜㄏㄨㄢ":"合歡/和緩",
"ㄏㄜㄏㄨㄣ":"合婚/合昏",
"ㄏㄜㄏㄨㄥ":"和哄",
"ㄏㄜㄐㄧㄚ":"闔家/合家",
"ㄏㄜㄐㄧㄝ":"和解/賀節",
"ㄏㄜㄐㄧㄢ":"和姦/核減",
"ㄏㄜㄐㄧㄣ":"合金/何進",
"ㄏㄜㄐㄧㄥ":"合鏡/和景",
"ㄏㄜㄐㄩㄢ":"禾絹",
"ㄏㄜㄑㄧㄚ":"和洽",
"ㄏㄜㄑㄧㄡ":"合球/訶求",
"ㄏㄜㄑㄧㄢ":"呵欠/訶譴",
"ㄏㄜㄑㄧㄣ":"和親",
"ㄏㄜㄑㄧㄥ":"河清",
"ㄏㄜㄑㄩㄢ":"合券/和勸",
"ㄏㄜㄑㄩㄣ":"合群",
"ㄏㄜㄒㄧㄚ":"合下/河蝦",
"ㄏㄜㄒㄧㄝ":"和諧/河蟹",
"ㄏㄜㄒㄧㄠ":"何消/核銷",
"ㄏㄜㄒㄧㄡ":"何休/和袖",
"ㄏㄜㄒㄧㄢ":"和弦/合弦",
"ㄏㄜㄒㄧㄣ":"核心",
"ㄏㄜㄒㄧㄥ":"核型",
"ㄏㄜㄒㄩㄕ":"赫胥氏",
"ㄏㄜㄒㄩㄣ":"何遜",
"ㄏㄜㄒㄩㄥ":"和熊",
"ㄏㄜㄓㄓㄤ":"賀知章",
"ㄏㄜㄓㄓㄥ":"合指症",
"ㄏㄜㄓㄨㄛ":"何焯",
"ㄏㄜㄓㄨㄢ":"合傳",
"ㄏㄜㄓㄨㄣ":"合準/核准",
"ㄏㄜㄓㄨㄥ":"何種/荷重",
"ㄏㄜㄔㄚㄗ":"河汊子",
"ㄏㄜㄔㄥㄘ":"合成詞",
"ㄏㄜㄔㄨㄢ":"河川",
"ㄏㄜㄔㄨㄤ":"河床",
"ㄏㄜㄔㄨㄥ":"禾蟲",
"ㄏㄜㄕㄅㄧ":"和氏璧",
"ㄏㄜㄕㄌㄠ":"和事老",
"ㄏㄜㄕㄡㄨ":"何首烏",
"ㄏㄜㄕㄨㄛ":"合朔/河朔",
"ㄏㄜㄕㄨㄣ":"和順",
"ㄏㄜㄖㄣㄙ":"何人斯",
"ㄏㄜㄖㄨㄛ":"何若",
"ㄏㄜㄖㄨㄣ":"河潤",
"ㄏㄜㄖㄨㄥ":"和戎/貉絨",
"ㄏㄜㄗㄆㄠ":"盒子炮",
"ㄏㄜㄗㄋㄥ":"核子能",
"ㄏㄜㄗㄌㄧ":"核子力",
"ㄏㄜㄗㄘㄞ":"盒子菜",
"ㄏㄜㄗㄨㄛ":"合作",
"ㄏㄜㄗㄨㄟ":"喝醉",
"ㄏㄜㄗㄨㄥ":"合從/合縱",
"ㄏㄜㄘㄨㄥ":"何從",
"ㄏㄜㄙㄨㄛ":"荷蓑",
"ㄏㄜㄙㄨㄟ":"賀歲",
"ㄏㄜㄙㄨㄢ":"合算/核算",
"ㄏㄜㄦㄇㄥ":"荷爾蒙",
"ㄏㄜㄨㄑㄧ":"核武器",
"ㄏㄜㄨㄖㄢ":"核汙染",
"ㄏㄜㄩㄣㄕ":"和韻詩",
"ㄏㄞㄅㄧㄠ":"海表",
"ㄏㄞㄅㄧㄢ":"海邊",
"ㄏㄞㄅㄧㄣ":"海濱",
"ㄏㄞㄅㄧㄥ":"海病/害病",
"ㄏㄞㄆㄠㄕ":"海泡石",
"ㄏㄞㄇㄧㄢ":"海綿/海面",
"ㄏㄞㄇㄧㄣ":"害民",
"ㄏㄞㄇㄧㄥ":"害命",
"ㄏㄞㄉㄧㄙ":"海地斯",
"ㄏㄞㄉㄧㄠ":"海釣",
"ㄏㄞㄉㄨㄗ":"害肚子",
"ㄏㄞㄊㄧㄥ":"駭聽",
"ㄏㄞㄊㄨㄟ":"海退",
"ㄏㄞㄊㄨㄣ":"海豚",
"ㄏㄞㄊㄨㄥ":"孩童",
"ㄏㄞㄋㄧㄠ":"害鳥",
"ㄏㄞㄋㄧㄡ":"海牛",
"ㄏㄞㄋㄧㄥ":"海寧",
"ㄏㄞㄌㄚㄦ":"海拉爾",
"ㄏㄞㄌㄧㄡ":"海流",
"ㄏㄞㄌㄧㄤ":"海量",
"ㄏㄞㄌㄧㄥ":"海嶺",
"ㄏㄞㄌㄨㄛ":"海螺",
"ㄏㄞㄌㄨㄣ":"海輪/海倫",
"ㄏㄞㄌㄨㄥ":"海龍",
"ㄏㄞㄌㄩㄕ":"海綠石",
"ㄏㄞㄍㄨㄛ":"海國",
"ㄏㄞㄍㄨㄞ":"駭怪",
"ㄏㄞㄍㄨㄟ":"海龜",
"ㄏㄞㄍㄨㄢ":"海關",
"ㄏㄞㄎㄜㄧ":"還可以",
"ㄏㄞㄎㄡㄕ":"海口市",
"ㄏㄞㄎㄨㄟ":"海葵",
"ㄏㄞㄏㄨㄚ":"海話",
"ㄏㄞㄏㄨㄛ":"海貨",
"ㄏㄞㄏㄨㄤ":"害慌",
"ㄏㄞㄐㄧㄚ":"海岬",
"ㄏㄞㄐㄧㄠ":"海角/海嶠",
"ㄏㄞㄐㄧㄡ":"害酒",
"ㄏㄞㄐㄧㄢ":"海監",
"ㄏㄞㄐㄧㄣ":"海禁",
"ㄏㄞㄐㄧㄤ":"海疆",
"ㄏㄞㄐㄧㄥ":"海鏡/海京",
"ㄏㄞㄐㄩㄣ":"海軍",
"ㄏㄞㄑㄧㄠ":"海鞘",
"ㄏㄞㄑㄧㄥ":"海青",
"ㄏㄞㄒㄧㄚ":"海峽/海蝦",
"ㄏㄞㄒㄧㄝ":"海蟹",
"ㄏㄞㄒㄧㄠ":"海嘯",
"ㄏㄞㄒㄧㄡ":"害羞",
"ㄏㄞㄒㄧㄢ":"海鮮/海線",
"ㄏㄞㄒㄧㄣ":"害心",
"ㄏㄞㄒㄧㄤ":"海象",
"ㄏㄞㄒㄧㄥ":"海行/海星",
"ㄏㄞㄒㄩㄥ":"海熊",
"ㄏㄞㄓㄓㄨ":"海蜘蛛",
"ㄏㄞㄔㄨㄤ":"海床",
"ㄏㄞㄔㄨㄥ":"害蟲",
"ㄏㄞㄕㄇㄣ":"海蝕門",
"ㄏㄞㄕㄓㄨ":"海蝕柱",
"ㄏㄞㄕㄚㄨ":"海砂屋",
"ㄏㄞㄕㄧㄞ":"海蝕崖",
"ㄏㄞㄕㄨㄟ":"海水",
"ㄏㄞㄖㄨㄛ":"海若",
"ㄏㄞㄖㄨㄟ":"海瑞",
"ㄏㄞㄗㄊㄡ":"孩子頭",
"ㄏㄞㄗㄑㄧ":"孩子氣",
"ㄏㄞㄗㄨㄤ":"孩子王",
"ㄏㄞㄘㄨㄛ":"海錯",
"ㄏㄞㄙㄖㄣ":"害死人",
"ㄏㄞㄙㄨㄣ":"海損",
"ㄏㄞㄙㄨㄥ":"海松",
"ㄏㄞㄦㄇㄣ":"孩兒們",
"ㄏㄟㄇㄧㄢ":"黑麵",
"ㄏㄟㄈㄤㄗ":"黑房子",
"ㄏㄟㄉㄠㄖ":"黑道日",
"ㄏㄟㄉㄧㄠ":"黑貂",
"ㄏㄟㄉㄧㄢ":"黑點/黑店",
"ㄏㄟㄉㄨㄥ":"黑洞",
"ㄏㄟㄊㄧㄝ":"黑帖",
"ㄏㄟㄊㄧㄢ":"黑天/黑甜",
"ㄏㄟㄌㄧㄢ":"黑臉",
"ㄏㄟㄌㄨㄣ":"黑輪",
"ㄏㄟㄍㄡㄗ":"黑狗子",
"ㄏㄟㄍㄨㄛ":"黑鍋",
"ㄏㄟㄍㄨㄢ":"黑官/黑管",
"ㄏㄟㄏㄜㄗ":"黑盒子",
"ㄏㄟㄏㄨㄚ":"黑話",
"ㄏㄟㄏㄨㄛ":"黑貨",
"ㄏㄟㄐㄧㄢ":"黑鍵",
"ㄏㄟㄐㄧㄣ":"黑金",
"ㄏㄟㄐㄧㄥ":"黑晶",
"ㄏㄟㄑㄧㄢ":"黑錢",
"ㄏㄟㄑㄧㄤ":"黑槍",
"ㄏㄟㄒㄧㄚ":"黑下",
"ㄏㄟㄒㄧㄣ":"黑心/黑信",
"ㄏㄟㄒㄧㄤ":"黑鄉",
"ㄏㄟㄒㄩㄥ":"黑熊",
"ㄏㄟㄓㄨㄥ":"黑種",
"ㄏㄟㄔㄏㄟ":"黑吃黑",
"ㄏㄟㄕㄨㄟ":"黑水",
"ㄏㄟㄙㄨㄥ":"黑松",
"ㄏㄟㄧㄢㄗ":"黑黶子",
"ㄏㄟㄨㄌㄟ":"黑五類",
"ㄏㄠㄅㄚㄕ":"好把式",
"ㄏㄠㄅㄧㄢ":"好辯",
"ㄏㄠㄅㄧㄥ":"號兵",
"ㄏㄠㄆㄧㄥ":"好評",
"ㄏㄠㄇㄧㄠ":"毫秒/浩渺",
"ㄏㄠㄇㄧㄥ":"好名/好名",
"ㄏㄠㄈㄚㄗ":"好法子",
"ㄏㄠㄉㄧㄥ":"號頂",
"ㄏㄠㄉㄨㄛ":"好多/豪奪",
"ㄏㄠㄉㄨㄢ":"毫端",
"ㄏㄠㄉㄨㄥ":"好動",
"ㄏㄠㄊㄧㄝ":"號帖",
"ㄏㄠㄊㄧㄢ":"好天/昊天",
"ㄏㄠㄊㄧㄥ":"好聽",
"ㄏㄠㄊㄨㄥ":"號筒",
"ㄏㄠㄋㄧㄠ":"好鳥",
"ㄏㄠㄌㄧㄤ":"濠梁",
"ㄏㄠㄌㄧㄥ":"號令/號令",
"ㄏㄠㄍㄨㄛ":"好過",
"ㄏㄠㄍㄨㄟ":"耗鬼",
"ㄏㄠㄍㄨㄢ":"好官",
"ㄏㄠㄍㄨㄤ":"毫光",
"ㄏㄠㄍㄨㄥ":"蒿宮",
"ㄏㄠㄎㄨㄛ":"豪闊",
"ㄏㄠㄏㄨㄚ":"好話/豪華",
"ㄏㄠㄏㄨㄛ":"好貨/好貨",
"ㄏㄠㄏㄨㄞ":"好壞",
"ㄏㄠㄐㄧㄝ":"浩劫/豪傑",
"ㄏㄠㄐㄧㄠ":"號角",
"ㄏㄠㄐㄧㄡ":"好酒/好酒",
"ㄏㄠㄐㄧㄢ":"號箭",
"ㄏㄠㄐㄧㄣ":"耗盡",
"ㄏㄠㄐㄧㄥ":"好景/鎬京",
"ㄏㄠㄐㄩㄝ":"好絕/好爵",
"ㄏㄠㄐㄩㄣ":"豪俊",
"ㄏㄠㄑㄧㄠ":"好喬",
"ㄏㄠㄑㄧㄡ":"好球/好逑",
"ㄏㄠㄑㄧㄢ":"好籤/壕塹",
"ㄏㄠㄑㄧㄤ":"好強/豪強",
"ㄏㄠㄑㄧㄥ":"好情/豪情",
"ㄏㄠㄑㄩㄝ":"好缺",
"ㄏㄠㄑㄩㄥ":"顥穹",
"ㄏㄠㄒㄧㄚ":"豪俠",
"ㄏㄠㄒㄧㄝ":"好些/好些",
"ㄏㄠㄒㄧㄠ":"好笑",
"ㄏㄠㄒㄧㄢ":"好險/耗羨",
"ㄏㄠㄒㄧㄣ":"好心",
"ㄏㄠㄒㄧㄤ":"好像",
"ㄏㄠㄒㄧㄥ":"豪興",
"ㄏㄠㄒㄩㄝ":"好學/好學",
"ㄏㄠㄒㄩㄥ":"豪雄",
"ㄏㄠㄓㄉㄥ":"號誌燈",
"ㄏㄠㄓㄨㄢ":"好轉",
"ㄏㄠㄓㄨㄤ":"豪壯",
"ㄏㄠㄓㄨㄦ":"好主兒",
"ㄏㄠㄓㄨㄧ":"好主意",
"ㄏㄠㄕㄓㄜ":"好事者",
"ㄏㄠㄕㄥㄧ":"好生意",
"ㄏㄠㄕㄨㄛ":"好說",
"ㄏㄠㄕㄨㄤ":"豪爽",
"ㄏㄠㄖㄣㄨ":"好人物",
"ㄏㄠㄖㄨㄛ":"好若/好弱",
"ㄏㄠㄗㄗㄞ":"好自在",
"ㄏㄠㄗㄨㄥ":"豪縱",
"ㄏㄠㄙㄨㄣ":"耗損/好愻",
"ㄏㄠㄧㄓㄣ":"好一陣",
"ㄏㄠㄧㄖㄦ":"好一日兒",
"ㄏㄠㄧㄤㄗ":"好樣子",
"ㄏㄠㄨㄦㄓ":"毫無二致",
"ㄏㄠㄨㄧㄧ":"毫無異義",
"ㄏㄠㄩㄨㄓ":"好諛惡直",
"ㄏㄡㄅㄢㄕ":"後半世",
"ㄏㄡㄅㄧㄢ":"後邊",
"ㄏㄡㄆㄧㄢ":"喉片",
"ㄏㄡㄇㄧㄢ":"後面",
"ㄏㄡㄇㄧㄥ":"後命",
"ㄏㄡㄈㄤㄩ":"侯方域",
"ㄏㄡㄉㄧㄝ":"後爹",
"ㄏㄡㄉㄧㄠ":"後凋",
"ㄏㄡㄉㄧㄢ":"侯甸",
"ㄏㄡㄉㄨㄣ":"後盾",
"ㄏㄡㄊㄧㄢ":"後天",
"ㄏㄡㄊㄧㄥ":"後庭",
"ㄏㄡㄊㄨㄟ":"後退/後腿",
"ㄏㄡㄋㄧㄠ":"候鳥",
"ㄏㄡㄋㄧㄢ":"後年",
"ㄏㄡㄋㄧㄤ":"後娘",
"ㄏㄡㄌㄧㄤ":"後梁/後涼",
"ㄏㄡㄌㄨㄥ":"喉嚨",
"ㄏㄡㄍㄨㄛ":"後果",
"ㄏㄡㄍㄨㄢ":"喉管/候館",
"ㄏㄡㄍㄨㄤ":"候光",
"ㄏㄡㄍㄨㄥ":"後宮",
"ㄏㄡㄎㄨㄣ":"後昆",
"ㄏㄡㄎㄨㄤ":"厚貺",
"ㄏㄡㄏㄨㄚ":"後話",
"ㄏㄡㄏㄨㄟ":"後悔",
"ㄏㄡㄏㄨㄢ":"後患",
"ㄏㄡㄏㄨㄤ":"后皇",
"ㄏㄡㄐㄧㄕ":"候機室",
"ㄏㄡㄐㄧㄚ":"後嫁",
"ㄏㄡㄐㄧㄝ":"後街/喉結",
"ㄏㄡㄐㄧㄠ":"後腳/吼叫",
"ㄏㄡㄐㄧㄣ":"後勁/後進",
"ㄏㄡㄐㄧㄥ":"後勁/後景",
"ㄏㄡㄐㄩㄝ":"後覺",
"ㄏㄡㄑㄧㄗ":"後起字",
"ㄏㄡㄑㄧㄣ":"後勤/後秦",
"ㄏㄡㄑㄧㄥ":"厚情",
"ㄏㄡㄑㄩㄝ":"候缺",
"ㄏㄡㄒㄧㄠ":"後效",
"ㄏㄡㄒㄧㄣ":"後心/后辛",
"ㄏㄡㄒㄧㄤ":"後像/後項",
"ㄏㄡㄒㄩㄝ":"後學",
"ㄏㄡㄒㄩㄢ":"候選",
"ㄏㄡㄓㄣㄕ":"候診室",
"ㄏㄡㄓㄨㄟ":"後綴",
"ㄏㄡㄓㄨㄥ":"後重/厚重",
"ㄏㄡㄔㄜㄕ":"候車室",
"ㄏㄡㄔㄨㄥ":"候蟲",
"ㄏㄡㄕㄡㄦ":"後手兒",
"ㄏㄡㄗㄞㄗ":"猴崽子",
"ㄏㄡㄗㄨㄛ":"後座/后座",
"ㄏㄡㄙㄨㄣ":"猴猻",
"ㄏㄡㄙㄨㄥ":"後送",
"ㄏㄡㄧㄒㄧ":"後醫系",
"ㄏㄡㄧㄓㄥ":"後遺症",
"ㄏㄢㄅㄞㄩ":"漢白玉",
"ㄏㄢㄅㄧㄠ":"寒飆",
"ㄏㄢㄅㄧㄢ":"酣邊",
"ㄏㄢㄆㄧㄣ":"含嚬",
"ㄏㄢㄇㄛㄓ":"翰墨志",
"ㄏㄢㄇㄧㄠ":"旱苗",
"ㄏㄢㄇㄧㄣ":"漢民",
"ㄏㄢㄈㄟㄗ":"韓非子",
"ㄏㄢㄉㄧㄠ":"漢調",
"ㄏㄢㄉㄧㄢ":"函電",
"ㄏㄢㄉㄨㄣ":"撼頓",
"ㄏㄢㄉㄨㄥ":"撼動/寒冬",
"ㄏㄢㄊㄚㄦ":"汗褟兒",
"ㄏㄢㄊㄧㄠ":"焊條/寒蜩",
"ㄏㄢㄊㄧㄢ":"寒天/旱天",
"ㄏㄢㄊㄨㄟ":"寒腿",
"ㄏㄢㄋㄩㄝ":"寒瘧",
"ㄏㄢㄌㄠㄦ":"汗絡兒",
"ㄏㄢㄌㄧㄠ":"焊料",
"ㄏㄢㄌㄧㄡ":"寒流/韓柳",
"ㄏㄢㄌㄧㄢ":"含臉/頷聯",
"ㄏㄢㄌㄧㄣ":"寒林/翰林",
"ㄏㄢㄌㄧㄤ":"含量/寒涼",
"ㄏㄢㄍㄨㄚ":"含括/寒瓜",
"ㄏㄢㄍㄨㄛ":"韓國",
"ㄏㄢㄍㄨㄢ":"寒官/涵管",
"ㄏㄢㄍㄨㄤ":"漢廣/含光",
"ㄏㄢㄍㄨㄥ":"焊工",
"ㄏㄢㄎㄡㄕ":"漢口市",
"ㄏㄢㄏㄨㄚ":"喊話/漢化",
"ㄏㄢㄏㄨㄛ":"汗火",
"ㄏㄢㄏㄨㄟ":"寒灰",
"ㄏㄢㄏㄨㄢ":"酣豢",
"ㄏㄢㄏㄨㄣ":"含混/含渾",
"ㄏㄢㄏㄨㄥ":"含弘/韓翃",
"ㄏㄢㄐㄧㄚ":"寒假/漢家",
"ㄏㄢㄐㄧㄝ":"焊接/銲接",
"ㄏㄢㄐㄧㄠ":"喊叫",
"ㄏㄢㄐㄧㄢ":"罕見/漢奸",
"ㄏㄢㄐㄧㄣ":"寒禁/汗巾",
"ㄏㄢㄐㄧㄤ":"悍將/漢江",
"ㄏㄢㄐㄧㄥ":"寒荊/旱井",
"ㄏㄢㄐㄩㄝ":"寒厥",
"ㄏㄢㄐㄩㄣ":"漢軍/旱軍",
"ㄏㄢㄑㄧㄠ":"寒峭",
"ㄏㄢㄑㄧㄡ":"寒秋",
"ㄏㄢㄑㄧㄢ":"憨錢/焊鉗",
"ㄏㄢㄑㄧㄣ":"憨寢",
"ㄏㄢㄑㄧㄤ":"焊槍",
"ㄏㄢㄑㄧㄥ":"含情/旱情",
"ㄏㄢㄑㄩㄝ":"寒怯",
"ㄏㄢㄑㄩㄥ":"寒蛩",
"ㄏㄢㄒㄧㄚ":"汗下/函夏",
"ㄏㄢㄒㄧㄠ":"含笑/憨笑",
"ㄏㄢㄒㄧㄡ":"含羞/含秀",
"ㄏㄢㄒㄧㄢ":"汗腺",
"ㄏㄢㄒㄧㄣ":"寒心/韓信",
"ㄏㄢㄒㄧㄤ":"寒相/旱象",
"ㄏㄢㄒㄧㄥ":"寒星/漢姓",
"ㄏㄢㄒㄩㄝ":"漢學",
"ㄏㄢㄒㄩㄢ":"寒暄",
"ㄏㄢㄒㄩㄣ":"寒訓",
"ㄏㄢㄓㄨㄗ":"汗珠子",
"ㄏㄢㄓㄨㄛ":"寒浞",
"ㄏㄢㄓㄨㄥ":"漢中",
"ㄏㄢㄔㄨㄢ":"旱船",
"ㄏㄢㄔㄨㄣ":"寒蠢",
"ㄏㄢㄔㄨㄤ":"寒窗",
"ㄏㄢㄕㄢㄗ":"寒山子",
"ㄏㄢㄕㄢㄙ":"寒山寺",
"ㄏㄢㄕㄨㄟ":"汗水/漢水",
"ㄏㄢㄕㄨㄤ":"含霜/寒霜",
"ㄏㄢㄖㄨㄥ":"涵容",
"ㄏㄢㄗㄨㄟ":"酣醉",
"ㄏㄢㄙㄤㄗ":"喊嗓子",
"ㄏㄢㄙㄨㄗ":"寒粟子",
"ㄏㄢㄙㄨㄢ":"寒酸/含酸",
"ㄏㄢㄧㄚㄗ":"旱鴨子",
"ㄏㄢㄨㄉㄧ":"漢武帝",
"ㄏㄢㄨㄐㄧ":"寒武紀",
"ㄏㄢㄨㄒㄧ":"寒武系",
"ㄏㄣㄇㄧㄥ":"狠命",
"ㄏㄣㄌㄧㄝ":"狠劣",
"ㄏㄣㄐㄩㄝ":"恨絕",
"ㄏㄣㄒㄧㄣ":"狠心",
"ㄏㄤㄅㄧㄠ":"航標",
"ㄏㄤㄉㄨㄥ":"行東",
"ㄏㄤㄌㄧㄝ":"行列",
"ㄏㄤㄍㄨㄟ":"行規",
"ㄏㄤㄎㄨㄢ":"行款",
"ㄏㄤㄎㄨㄥ":"航空",
"ㄏㄤㄏㄨㄚ":"行話",
"ㄏㄤㄏㄨㄛ":"行貨/夯貨",
"ㄏㄤㄏㄨㄟ":"行會",
"ㄏㄤㄐㄧㄚ":"行家",
"ㄏㄤㄐㄧㄢ":"行間/杭剪",
"ㄏㄤㄑㄧㄥ":"行情",
"ㄏㄤㄑㄩㄢ":"航權",
"ㄏㄤㄒㄧㄝ":"沆瀣",
"ㄏㄤㄒㄧㄢ":"航線",
"ㄏㄤㄒㄧㄤ":"航向",
"ㄏㄤㄒㄧㄥ":"航行",
"ㄏㄤㄓㄡㄕ":"杭州市",
"ㄏㄤㄔㄨㄢ":"航船",
"ㄏㄤㄞㄕㄢ":"杭愛山",
"ㄏㄤㄧㄝㄩ":"行業語",
"ㄏㄥㄅㄧㄢ":"橫匾",
"ㄏㄥㄅㄧㄣ":"橫濱",
"ㄏㄥㄆㄧㄥ":"衡平",
"ㄏㄥㄇㄧㄥ":"衡命",
"ㄏㄥㄉㄥㄕ":"恆等式",
"ㄏㄥㄉㄧㄥ":"恆定",
"ㄏㄥㄉㄨㄟ":"橫隊",
"ㄏㄥㄉㄨㄢ":"橫斷",
"ㄏㄥㄊㄧㄠ":"桁條",
"ㄏㄥㄊㄨㄥ":"亨通",
"ㄏㄥㄌㄧㄝ":"橫列",
"ㄏㄥㄌㄧㄡ":"橫流",
"ㄏㄥㄌㄧㄤ":"衡量/恆量",
"ㄏㄥㄍㄨㄢ":"橫貫",
"ㄏㄥㄎㄨㄚ":"橫跨",
"ㄏㄥㄎㄨㄥ":"橫空",
"ㄏㄥㄏㄨㄚ":"橫話",
"ㄏㄥㄏㄨㄛ":"橫禍",
"ㄏㄥㄐㄧㄚ":"桁架/橫加",
"ㄏㄥㄐㄧㄡ":"恆久",
"ㄏㄥㄐㄧㄢ":"衡鑑",
"ㄏㄥㄐㄧㄣ":"橫勁",
"ㄏㄥㄐㄩㄝ":"桁桷",
"ㄏㄥㄑㄧㄝ":"橫切",
"ㄏㄥㄑㄧㄡ":"橫秋",
"ㄏㄥㄑㄧㄥ":"恆情",
"ㄏㄥㄒㄧㄝ":"橫寫",
"ㄏㄥㄒㄧㄢ":"橫線",
"ㄏㄥㄒㄧㄣ":"恆心/橫心",
"ㄏㄥㄒㄧㄤ":"橫向",
"ㄏㄥㄒㄧㄥ":"橫行/恆星",
"ㄏㄥㄔㄨㄟ":"橫吹",
"ㄏㄥㄙㄧㄢ":"橫死眼",
"ㄏㄥㄧㄤㄕ":"衡陽市",
"ㄏㄨㄅㄛㄙ":"胡撥四",
"ㄏㄨㄅㄧㄠ":"糊裱",
"ㄏㄨㄅㄧㄢ":"虎變",
"ㄏㄨㄅㄧㄥ":"護兵/胡餅",
"ㄏㄨㄅㄨㄨ":"狐步舞",
"ㄏㄨㄆㄨㄢ":"胡樸安",
"ㄏㄨㄇㄟㄗ":"狐媚子",
"ㄏㄨㄇㄧㄢ":"鵠面/互勉",
"ㄏㄨㄇㄧㄥ":"戶名/糊名",
"ㄏㄨㄈㄨㄩ":"虎負嵎",
"ㄏㄨㄉㄧㄝ":"蝴蝶",
"ㄏㄨㄉㄧㄠ":"互調",
"ㄏㄨㄉㄧㄥ":"互定/曶鼎",
"ㄏㄨㄉㄨㄗ":"護犢子",
"ㄏㄨㄉㄨㄢ":"護短",
"ㄏㄨㄉㄨㄥ":"互動",
"ㄏㄨㄊㄧㄠ":"虎跳",
"ㄏㄨㄊㄧㄢ":"壺天/湖田",
"ㄏㄨㄊㄧㄥ":"戶庭",
"ㄏㄨㄊㄨㄟ":"護腿",
"ㄏㄨㄊㄨㄥ":"胡同/互通",
"ㄏㄨㄊㄨㄦ":"護禿兒",
"ㄏㄨㄋㄧㄥ":"胡寧",
"ㄏㄨㄋㄨㄥ":"糊弄",
"ㄏㄨㄌㄚㄨ":"呼拉舞",
"ㄏㄨㄌㄧㄕ":"護理師",
"ㄏㄨㄌㄧㄢ":"瑚璉",
"ㄏㄨㄌㄧㄥ":"胡伶/鶻鴒",
"ㄏㄨㄌㄨㄛ":"瓠落",
"ㄏㄨㄌㄨㄢ":"胡亂/怙亂",
"ㄏㄨㄌㄨㄣ":"鶻淪/囫圇",
"ㄏㄨㄌㄨㄦ":"葫蘆兒",
"ㄏㄨㄌㄩㄝ":"忽略",
"ㄏㄨㄍㄨㄚ":"胡瓜/瓠瓜",
"ㄏㄨㄍㄨㄛ":"瓠果",
"ㄏㄨㄍㄨㄟ":"護軌",
"ㄏㄨㄍㄨㄤ":"湖光/弧光",
"ㄏㄨㄍㄨㄥ":"壺公",
"ㄏㄨㄏㄨㄚ":"胡話",
"ㄏㄨㄏㄨㄛ":"狐惑",
"ㄏㄨㄏㄨㄟ":"互惠",
"ㄏㄨㄏㄨㄢ":"呼喚/互換",
"ㄏㄨㄏㄨㄣ":"胡混",
"ㄏㄨㄏㄨㄤ":"忽荒/惚恍",
"ㄏㄨㄐㄧㄚ":"護駕/胡笳",
"ㄏㄨㄐㄧㄠ":"呼叫/胡椒",
"ㄏㄨㄐㄧㄡ":"呼救/虎舅",
"ㄏㄨㄐㄧㄢ":"忽見/互見",
"ㄏㄨㄐㄧㄣ":"虎勁",
"ㄏㄨㄐㄧㄤ":"胡講/虎將",
"ㄏㄨㄐㄧㄥ":"糊精",
"ㄏㄨㄐㄩㄝ":"槲蕨",
"ㄏㄨㄐㄩㄢ":"虎圈",
"ㄏㄨㄐㄩㄣ":"戶均/弧菌",
"ㄏㄨㄑㄧㄠ":"胡敲",
"ㄏㄨㄑㄧㄡ":"虎丘",
"ㄏㄨㄑㄧㄢ":"護前/虎鉗",
"ㄏㄨㄑㄧㄣ":"胡秦/胡琴",
"ㄏㄨㄑㄩㄢ":"胡銓",
"ㄏㄨㄒㄧㄚ":"虎嚇/虎諕",
"ㄏㄨㄒㄧㄠ":"呼嘯/虎嘯",
"ㄏㄨㄒㄧㄢ":"弧線/戶限",
"ㄏㄨㄒㄧㄣ":"互信/昒昕",
"ㄏㄨㄒㄧㄤ":"互相/胡想",
"ㄏㄨㄒㄧㄥ":"胡行/弧形",
"ㄏㄨㄒㄩㄝ":"虎穴/湖學",
"ㄏㄨㄒㄩㄢ":"互選",
"ㄏㄨㄒㄩㄣ":"互訓",
"ㄏㄨㄒㄩㄥ":"護胸",
"ㄏㄨㄓㄡㄕ":"湖州市",
"ㄏㄨㄓㄨㄥ":"怙終",
"ㄏㄨㄔㄨㄤ":"胡床/虎床",
"ㄏㄨㄔㄨㄥ":"怙寵",
"ㄏㄨㄕㄓㄤ":"護士長",
"ㄏㄨㄕㄢㄕ":"湖山石",
"ㄏㄨㄕㄣㄨ":"護身物",
"ㄏㄨㄕㄨㄚ":"糊刷",
"ㄏㄨㄕㄨㄛ":"胡說/戶說",
"ㄏㄨㄕㄨㄟ":"戽水",
"ㄏㄨㄕㄨㄤ":"昒爽",
"ㄏㄨㄗㄚㄧ":"鬍子阿姨",
"ㄏㄨㄗㄨㄛ":"護佐",
"ㄏㄨㄗㄨㄥ":"護從/扈從",
"ㄏㄨㄙㄔㄢ":"胡廝纏",
"ㄏㄨㄙㄨㄟ":"胡荽",
"ㄏㄨㄙㄨㄣ":"壺飧/猢猻",
"ㄏㄨㄙㄨㄥ":"護送",
"ㄏㄨㄚㄅㄚ":"話把/話靶",
"ㄏㄨㄚㄅㄛ":"劃撥/猾伯",
"ㄏㄨㄚㄅㄞ":"花白",
"ㄏㄨㄚㄅㄟ":"花被/華北",
"ㄏㄨㄚㄅㄠ":"花報/畫報",
"ㄏㄨㄚㄅㄢ":"花瓣/滑板",
"ㄏㄨㄚㄅㄣ":"話本",
"ㄏㄨㄚㄅㄤ":"花棒",
"ㄏㄨㄚㄅㄨ":"華埠/花部",
"ㄏㄨㄚㄆㄛ":"花破/滑坡",
"ㄏㄨㄚㄆㄞ":"花牌/畫派",
"ㄏㄨㄚㄆㄠ":"花炮",
"ㄏㄨㄚㄆㄢ":"花判",
"ㄏㄨㄚㄆㄣ":"花盆",
"ㄏㄨㄚㄆㄧ":"畫皮",
"ㄏㄨㄚㄆㄨ":"花圃/畫譜",
"ㄏㄨㄚㄇㄟ":"畫眉/話梅",
"ㄏㄨㄚㄇㄠ":"花帽/畫卯",
"ㄏㄨㄚㄇㄢ":"華鬘",
"ㄏㄨㄚㄇㄣ":"花門",
"ㄏㄨㄚㄇㄥ":"花虻",
"ㄏㄨㄚㄇㄧ":"花蜜/華靡",
"ㄏㄨㄚㄇㄨ":"花木/樺木",
"ㄏㄨㄚㄈㄚ":"畫法/華髮",
"ㄏㄨㄚㄈㄟ":"花費/花費",
"ㄏㄨㄚㄈㄣ":"劃分/化分",
"ㄏㄨㄚㄈㄤ":"花房/畫舫",
"ㄏㄨㄚㄈㄥ":"畫風/話風",
"ㄏㄨㄚㄈㄨ":"華府/畫符",
"ㄏㄨㄚㄉㄠ":"花道",
"ㄏㄨㄚㄉㄢ":"花旦/華誕",
"ㄏㄨㄚㄉㄤ":"花蕩",
"ㄏㄨㄚㄉㄥ":"花燈/華燈",
"ㄏㄨㄚㄉㄧ":"畫荻",
"ㄏㄨㄚㄉㄨ":"花都/化度",
"ㄏㄨㄚㄊㄞ":"花臺",
"ㄏㄨㄚㄊㄡ":"話頭/滑頭",
"ㄏㄨㄚㄊㄢ":"化痰/花壇",
"ㄏㄨㄚㄊㄤ":"畫堂/畫糖",
"ㄏㄨㄚㄊㄧ":"話題/滑梯",
"ㄏㄨㄚㄊㄨ":"畫圖",
"ㄏㄨㄚㄋㄢ":"華南",
"ㄏㄨㄚㄋㄧ":"滑膩",
"ㄏㄨㄚㄌㄚ":"嘩啦/滑喇",
"ㄏㄨㄚㄌㄟ":"花蕾/滑壘",
"ㄏㄨㄚㄌㄠ":"話癆",
"ㄏㄨㄚㄌㄡ":"畫樓",
"ㄏㄨㄚㄌㄢ":"花籃",
"ㄏㄨㄚㄌㄤ":"畫廊/花郎",
"ㄏㄨㄚㄌㄧ":"華麗/花利",
"ㄏㄨㄚㄌㄨ":"花露",
"ㄏㄨㄚㄌㄩ":"花櫚",
"ㄏㄨㄚㄍㄜ":"花蛤/畫閣",
"ㄏㄨㄚㄍㄞ":"華蓋",
"ㄏㄨㄚㄍㄠ":"花糕/畫稿",
"ㄏㄨㄚㄍㄢ":"滑竿",
"ㄏㄨㄚㄍㄣ":"花根",
"ㄏㄨㄚㄍㄥ":"花梗",
"ㄏㄨㄚㄍㄨ":"花鼓/花姑",
"ㄏㄨㄚㄎㄜ":"花棵",
"ㄏㄨㄚㄎㄡ":"譁釦",
"ㄏㄨㄚㄎㄢ":"畫刊",
"ㄏㄨㄚㄏㄜ":"化合/化鶴",
"ㄏㄨㄚㄏㄞ":"花海",
"ㄏㄨㄚㄏㄢ":"華翰",
"ㄏㄨㄚㄏㄤ":"滑航",
"ㄏㄨㄚㄏㄨ":"花戶/畫壺",
"ㄏㄨㄚㄐㄧ":"花機/花季",
"ㄏㄨㄚㄐㄩ":"話劇/畫具",
"ㄏㄨㄚㄑㄧ":"花期/花器",
"ㄏㄨㄚㄑㄩ":"化去",
"ㄏㄨㄚㄒㄧ":"花息/華西",
"ㄏㄨㄚㄒㄩ":"花絮/花序",
"ㄏㄨㄚㄓㄞ":"化齋",
"ㄏㄨㄚㄓㄠ":"花朝/花招",
"ㄏㄨㄚㄓㄡ":"花軸/華胄",
"ㄏㄨㄚㄓㄢ":"畫展",
"ㄏㄨㄚㄓㄣ":"畫真",
"ㄏㄨㄚㄓㄤ":"花帳",
"ㄏㄨㄚㄓㄨ":"花柱/花燭",
"ㄏㄨㄚㄔㄚ":"話差/話碴",
"ㄏㄨㄚㄔㄜ":"花車/滑車",
"ㄏㄨㄚㄔㄞ":"花釵",
"ㄏㄨㄚㄔㄤ":"滑唱",
"ㄏㄨㄚㄔㄨ":"滑出/化除",
"ㄏㄨㄚㄕㄗ":"畫十字",
"ㄏㄨㄚㄕㄜ":"花舌",
"ㄏㄨㄚㄕㄠ":"花稍/花哨",
"ㄏㄨㄚㄕㄢ":"花衫/華山",
"ㄏㄨㄚㄕㄣ":"化身/花神",
"ㄏㄨㄚㄕㄥ":"花生/化生",
"ㄏㄨㄚㄕㄨ":"滑鼠/滑熟",
"ㄏㄨㄚㄖㄢ":"劃然/譁然",
"ㄏㄨㄚㄖㄣ":"華人",
"ㄏㄨㄚㄖㄨ":"花乳",
"ㄏㄨㄚㄗㄜ":"猾賊",
"ㄏㄨㄚㄗㄟ":"花賊",
"ㄏㄨㄚㄗㄠ":"花蚤/譁噪",
"ㄏㄨㄚㄗㄡ":"滑奏",
"ㄏㄨㄚㄗㄢ":"華簪",
"ㄏㄨㄚㄗㄨ":"華族",
"ㄏㄨㄚㄘㄚ":"滑擦",
"ㄏㄨㄚㄘㄜ":"畫策/畫冊",
"ㄏㄨㄚㄘㄞ":"花材",
"ㄏㄨㄚㄘㄠ":"花草/滑草",
"ㄏㄨㄚㄙㄜ":"花色",
"ㄏㄨㄚㄙㄨ":"化俗",
"ㄏㄨㄚㄦㄗ":"華爾滋",
"ㄏㄨㄚㄧㄗ":"畫一字",
"ㄏㄨㄚㄧㄚ":"花押/畫押",
"ㄏㄨㄚㄧㄝ":"滑液",
"ㄏㄨㄚㄧㄠ":"花葯",
"ㄏㄨㄚㄧㄢ":"話眼/化驗",
"ㄏㄨㄚㄧㄣ":"話音/花銀",
"ㄏㄨㄚㄧㄤ":"花樣",
"ㄏㄨㄚㄨㄞ":"化外",
"ㄏㄨㄚㄨㄣ":"華文/花紋",
"ㄏㄨㄚㄨㄤ":"花王",
"ㄏㄨㄚㄩㄝ":"花約/華岳",
"ㄏㄨㄚㄩㄢ":"花園/化緣",
"ㄏㄨㄚㄩㄥ":"花用",
"ㄏㄨㄛㄅㄚ":"火把",
"ㄏㄨㄛㄅㄟ":"火備",
"ㄏㄨㄛㄅㄠ":"火爆/活寶",
"ㄏㄨㄛㄅㄢ":"伙伴/夥伴",
"ㄏㄨㄛㄅㄤ":"火棒",
"ㄏㄨㄛㄅㄧ":"貨幣",
"ㄏㄨㄛㄅㄨ":"貨布/貨卜",
"ㄏㄨㄛㄆㄛ":"活潑",
"ㄏㄨㄛㄆㄞ":"火牌",
"ㄏㄨㄛㄆㄠ":"火炮",
"ㄏㄨㄛㄆㄢ":"火判",
"ㄏㄨㄛㄆㄣ":"火盆",
"ㄏㄨㄛㄆㄥ":"鑊烹",
"ㄏㄨㄛㄆㄨ":"火鋪",
"ㄏㄨㄛㄇㄛ":"和墨",
"ㄏㄨㄛㄇㄞ":"活埋",
"ㄏㄨㄛㄇㄠ":"火冒",
"ㄏㄨㄛㄇㄣ":"活門/火門",
"ㄏㄨㄛㄇㄨ":"禍母",
"ㄏㄨㄛㄈㄚ":"活法/貨罰",
"ㄏㄨㄛㄈㄛ":"活佛",
"ㄏㄨㄛㄈㄢ":"活泛",
"ㄏㄨㄛㄈㄣ":"活分",
"ㄏㄨㄛㄈㄤ":"火房/伙房",
"ㄏㄨㄛㄈㄨ":"火夫/禍福",
"ㄏㄨㄛㄉㄚ":"豁達/火大",
"ㄏㄨㄛㄉㄜ":"獲得/火德",
"ㄏㄨㄛㄉㄠ":"火刀",
"ㄏㄨㄛㄉㄡ":"火斗",
"ㄏㄨㄛㄉㄢ":"火癉",
"ㄏㄨㄛㄉㄤ":"豁蕩",
"ㄏㄨㄛㄉㄥ":"豁鄧",
"ㄏㄨㄛㄉㄧ":"豁地/霍地",
"ㄏㄨㄛㄊㄚ":"火踏",
"ㄏㄨㄛㄊㄞ":"禍胎",
"ㄏㄨㄛㄊㄠ":"活套",
"ㄏㄨㄛㄊㄡ":"火頭",
"ㄏㄨㄛㄊㄢ":"火炭",
"ㄏㄨㄛㄊㄤ":"火塘/火燙",
"ㄏㄨㄛㄊㄧ":"或體/禍梯",
"ㄏㄨㄛㄊㄨ":"惑突",
"ㄏㄨㄛㄋㄧ":"火泥/惑溺",
"ㄏㄨㄛㄌㄚ":"火辣",
"ㄏㄨㄛㄌㄤ":"貨郎/豁朗",
"ㄏㄨㄛㄌㄧ":"活力/獲利",
"ㄏㄨㄛㄌㄨ":"火爐/活路",
"ㄏㄨㄛㄍㄞ":"活該",
"ㄏㄨㄛㄍㄡ":"夥夠",
"ㄏㄨㄛㄍㄣ":"禍根",
"ㄏㄨㄛㄍㄥ":"火耕",
"ㄏㄨㄛㄍㄨ":"壑谷",
"ㄏㄨㄛㄎㄠ":"火烤",
"ㄏㄨㄛㄎㄡ":"活口",
"ㄏㄨㄛㄎㄤ":"火炕",
"ㄏㄨㄛㄎㄥ":"火坑",
"ㄏㄨㄛㄎㄨ":"火窟",
"ㄏㄨㄛㄏㄞ":"火海/禍害",
"ㄏㄨㄛㄏㄠ":"火耗",
"ㄏㄨㄛㄏㄡ":"火候",
"ㄏㄨㄛㄏㄨ":"火狐",
"ㄏㄨㄛㄐㄧ":"伙計/火雞",
"ㄏㄨㄛㄐㄩ":"火炬/活句",
"ㄏㄨㄛㄑㄧ":"火氣/活期",
"ㄏㄨㄛㄑㄩ":"獲取/蠖屈",
"ㄏㄨㄛㄒㄧ":"獲悉",
"ㄏㄨㄛㄒㄩ":"或許",
"ㄏㄨㄛㄓㄜ":"或者/火者",
"ㄏㄨㄛㄓㄞ":"火宅",
"ㄏㄨㄛㄓㄠ":"活著",
"ㄏㄨㄛㄓㄢ":"貨棧",
"ㄏㄨㄛㄓㄤ":"火長",
"ㄏㄨㄛㄓㄥ":"火政/火症",
"ㄏㄨㄛㄓㄨ":"火燭/火主",
"ㄏㄨㄛㄔㄜ":"火車/貨車",
"ㄏㄨㄛㄔㄞ":"火柴",
"ㄏㄨㄛㄔㄣ":"活襯",
"ㄏㄨㄛㄔㄤ":"火場",
"ㄏㄨㄛㄔㄨ":"活儲",
"ㄏㄨㄛㄕㄜ":"火舌",
"ㄏㄨㄛㄕㄠ":"火燒/火燒",
"ㄏㄨㄛㄕㄡ":"禍首",
"ㄏㄨㄛㄕㄢ":"火山/霍閃",
"ㄏㄨㄛㄕㄣ":"火神",
"ㄏㄨㄛㄕㄥ":"獲勝/貨聲",
"ㄏㄨㄛㄕㄨ":"惑術",
"ㄏㄨㄛㄖㄜ":"火熱",
"ㄏㄨㄛㄖㄢ":"豁然/霍然",
"ㄏㄨㄛㄖㄣ":"或人",
"ㄏㄨㄛㄗㄜ":"或則/嚄唶",
"ㄏㄨㄛㄗㄞ":"火災",
"ㄏㄨㄛㄗㄤ":"火葬",
"ㄏㄨㄛㄘㄤ":"貨倉/貨艙",
"ㄏㄨㄛㄙㄜ":"貨色/火色",
"ㄏㄨㄛㄙㄞ":"活塞",
"ㄏㄨㄛㄙㄢ":"火傘",
"ㄏㄨㄛㄙㄨ":"火速",
"ㄏㄨㄛㄧㄝ":"活頁",
"ㄏㄨㄛㄧㄠ":"火藥/火曜",
"ㄏㄨㄛㄧㄡ":"或有/火油",
"ㄏㄨㄛㄧㄢ":"火焰/火眼",
"ㄏㄨㄛㄧㄤ":"貨樣/禍殃",
"ㄏㄨㄛㄨㄟ":"或謂",
"ㄏㄨㄛㄨㄤ":"火網",
"ㄏㄨㄛㄩㄝ":"活躍/或曰",
"ㄏㄨㄛㄩㄢ":"火院/貨源",
"ㄏㄨㄛㄩㄣ":"火雲/貨運",
"ㄏㄨㄛㄩㄥ":"活用",
"ㄏㄨㄞㄅㄟ":"淮北",
"ㄏㄨㄞㄅㄠ":"懷抱",
"ㄏㄨㄞㄈㄨ":"懷服/壞腹",
"ㄏㄨㄞㄉㄡ":"槐豆",
"ㄏㄨㄞㄉㄢ":"壞蛋/懷耽",
"ㄏㄨㄞㄊㄞ":"懷胎",
"ㄏㄨㄞㄊㄨ":"懷土",
"ㄏㄨㄞㄋㄢ":"淮南",
"ㄏㄨㄞㄌㄜ":"壞了",
"ㄏㄨㄞㄍㄢ":"懷感",
"ㄏㄨㄞㄍㄨ":"懷古/懷顧",
"ㄏㄨㄞㄏㄜ":"淮河",
"ㄏㄨㄞㄏㄣ":"懷恨",
"ㄏㄨㄞㄐㄩ":"淮劇/懷橘",
"ㄏㄨㄞㄑㄧ":"懷奇",
"ㄏㄨㄞㄒㄧ":"淮西",
"ㄏㄨㄞㄓㄤ":"壞帳",
"ㄏㄨㄞㄓㄥ":"壞證",
"ㄏㄨㄞㄔㄠ":"壞鈔",
"ㄏㄨㄞㄔㄡ":"懷愁",
"ㄏㄨㄞㄔㄨ":"壞處",
"ㄏㄨㄞㄕㄚ":"懷沙",
"ㄏㄨㄞㄕㄥ":"懷生",
"ㄏㄨㄞㄕㄨ":"槐樹",
"ㄏㄨㄞㄖㄡ":"懷柔",
"ㄏㄨㄞㄖㄣ":"壞人",
"ㄏㄨㄞㄖㄤ":"懷讓",
"ㄏㄨㄞㄙㄨ":"懷素",
"ㄏㄨㄞㄧㄡ":"懷憂",
"ㄏㄨㄞㄧㄢ":"淮鹽",
"ㄏㄨㄞㄩㄝ":"槐月",
"ㄏㄨㄞㄩㄢ":"懷遠/懷怨",
"ㄏㄨㄞㄩㄣ":"懷孕/壞運",
"ㄏㄨㄟㄅㄛ":"回波/回駁",
"ㄏㄨㄟㄅㄞ":"灰白/回拜",
"ㄏㄨㄟㄅㄟ":"回背",
"ㄏㄨㄟㄅㄠ":"回報/匯報",
"ㄏㄨㄟㄅㄤ":"毀謗",
"ㄏㄨㄟㄅㄧ":"迴避/回避",
"ㄏㄨㄟㄅㄨ":"回部",
"ㄏㄨㄟㄆㄞ":"揮拍/詼俳",
"ㄏㄨㄟㄆㄢ":"回盤",
"ㄏㄨㄟㄆㄥ":"灰棚",
"ㄏㄨㄟㄇㄚ":"回馬/穢罵",
"ㄏㄨㄟㄇㄛ":"灰沒/徽墨",
"ㄏㄨㄟㄇㄞ":"回買",
"ㄏㄨㄟㄇㄟ":"揮袂/晦昧",
"ㄏㄨㄟㄇㄣ":"會門/回門",
"ㄏㄨㄟㄇㄤ":"晦盲",
"ㄏㄨㄟㄇㄥ":"會盟/晦蒙",
"ㄏㄨㄟㄇㄨ":"慧目/灰木",
"ㄏㄨㄟㄈㄚ":"揮發",
"ㄏㄨㄟㄈㄟ":"會費/翬飛",
"ㄏㄨㄟㄈㄢ":"會飯/燴飯",
"ㄏㄨㄟㄈㄣ":"恚憤",
"ㄏㄨㄟㄈㄤ":"會房/回防",
"ㄏㄨㄟㄈㄥ":"回風/回奉",
"ㄏㄨㄟㄈㄨ":"回復/回覆",
"ㄏㄨㄟㄉㄚ":"回答/毀打",
"ㄏㄨㄟㄉㄜ":"會得/穢德",
"ㄏㄨㄟㄉㄞ":"迴帶",
"ㄏㄨㄟㄉㄠ":"誨盜",
"ㄏㄨㄟㄉㄢ":"恢誕",
"ㄏㄨㄟㄉㄤ":"會黨/回檔",
"ㄏㄨㄟㄉㄥ":"回燈",
"ㄏㄨㄟㄉㄧ":"翬翟",
"ㄏㄨㄟㄊㄞ":"恢臺",
"ㄏㄨㄟㄊㄠ":"灰陶",
"ㄏㄨㄟㄊㄡ":"回頭/回頭",
"ㄏㄨㄟㄊㄢ":"會談/回灘",
"ㄏㄨㄟㄊㄤ":"會堂/回塘",
"ㄏㄨㄟㄊㄨ":"隳突/繪圖",
"ㄏㄨㄟㄋㄚ":"回納",
"ㄏㄨㄟㄋㄢ":"慧南",
"ㄏㄨㄟㄋㄤ":"穢囊",
"ㄏㄨㄟㄋㄥ":"慧能",
"ㄏㄨㄟㄋㄨ":"恚怒",
"ㄏㄨㄟㄌㄞ":"回來/悔賴",
"ㄏㄨㄟㄌㄟ":"揮淚",
"ㄏㄨㄟㄌㄢ":"回闌/蕙蘭",
"ㄏㄨㄟㄌㄤ":"迴廊",
"ㄏㄨㄟㄌㄧ":"回禮/回曆",
"ㄏㄨㄟㄌㄨ":"賄賂/回路",
"ㄏㄨㄟㄌㄩ":"匯率",
"ㄏㄨㄟㄍㄜ":"揮戈",
"ㄏㄨㄟㄍㄞ":"悔改/麾蓋",
"ㄏㄨㄟㄍㄠ":"會稿/回告",
"ㄏㄨㄟㄍㄢ":"揮杆",
"ㄏㄨㄟㄍㄣ":"慧根",
"ㄏㄨㄟㄍㄨ":"回顧/會鼓",
"ㄏㄨㄟㄎㄜ":"會客/會課",
"ㄏㄨㄟㄎㄠ":"會考",
"ㄏㄨㄟㄎㄡ":"回扣",
"ㄏㄨㄟㄎㄢ":"彙刊",
"ㄏㄨㄟㄏㄜ":"回合/會合",
"ㄏㄨㄟㄏㄞ":"會海/慧海",
"ㄏㄨㄟㄏㄠ":"隳好/揮毫",
"ㄏㄨㄟㄏㄡ":"回候",
"ㄏㄨㄟㄏㄢ":"回函/揮汗",
"ㄏㄨㄟㄏㄣ":"悔恨/恚恨",
"ㄏㄨㄟㄏㄤ":"回航",
"ㄏㄨㄟㄏㄨ":"回護/回鶻",
"ㄏㄨㄟㄐㄧ":"會集/會籍",
"ㄏㄨㄟㄐㄩ":"匯聚/徽劇",
"ㄏㄨㄟㄑㄧ":"晦氣/會期",
"ㄏㄨㄟㄑㄩ":"回去",
"ㄏㄨㄟㄒㄧ":"回戲/回席",
"ㄏㄨㄟㄒㄩ":"會須/惠恤",
"ㄏㄨㄟㄓㄠ":"會朝/慧沼",
"ㄏㄨㄟㄓㄢ":"會戰/匯展",
"ㄏㄨㄟㄓㄣ":"會真/會診",
"ㄏㄨㄟㄓㄤ":"會長/會章",
"ㄏㄨㄟㄓㄥ":"惠政/彙整",
"ㄏㄨㄟㄓㄨ":"揮麈/蕙炷",
"ㄏㄨㄟㄔㄜ":"迴車",
"ㄏㄨㄟㄔㄠ":"會朝/會鈔",
"ㄏㄨㄟㄔㄣ":"灰塵",
"ㄏㄨㄟㄔㄤ":"會場/回腸",
"ㄏㄨㄟㄔㄥ":"回程/會呈",
"ㄏㄨㄟㄕㄚ":"回煞",
"ㄏㄨㄟㄕㄜ":"會社",
"ㄏㄨㄟㄕㄠ":"會哨",
"ㄏㄨㄟㄕㄡ":"揮手/回收",
"ㄏㄨㄟㄕㄣ":"會審/回身",
"ㄏㄨㄟㄕㄤ":"會商/毀傷",
"ㄏㄨㄟㄕㄥ":"回升/會聖",
"ㄏㄨㄟㄕㄨ":"回書/回贖",
"ㄏㄨㄟㄖㄠ":"迴繞",
"ㄏㄨㄟㄖㄢ":"惠然",
"ㄏㄨㄟㄗㄚ":"迴匝",
"ㄏㄨㄟㄗㄜ":"惠澤",
"ㄏㄨㄟㄗㄞ":"晦在",
"ㄏㄨㄟㄗㄤ":"會葬",
"ㄏㄨㄟㄗㄥ":"回贈/惠贈",
"ㄏㄨㄟㄗㄨ":"回族",
"ㄏㄨㄟㄘㄠ":"會操/穢草",
"ㄏㄨㄟㄘㄢ":"會餐",
"ㄏㄨㄟㄙㄚ":"揮灑",
"ㄏㄨㄟㄙㄜ":"灰色/晦澀",
"ㄏㄨㄟㄙㄨ":"回溯",
"ㄏㄨㄟㄧㄕ":"會議室",
"ㄏㄨㄟㄧㄝ":"慧業",
"ㄏㄨㄟㄧㄠ":"會要/輝耀",
"ㄏㄨㄟㄧㄡ":"會友/回郵",
"ㄏㄨㄟㄧㄢ":"諱言/會演",
"ㄏㄨㄟㄧㄣ":"回音/會陰",
"ㄏㄨㄟㄧㄥ":"回應/輝映",
"ㄏㄨㄟㄨㄛ":"迴斡",
"ㄏㄨㄟㄨㄟ":"回味/彗尾",
"ㄏㄨㄟㄨㄢ":"迴腕",
"ㄏㄨㄟㄨㄣ":"會文/回文",
"ㄏㄨㄟㄨㄤ":"恚望",
"ㄏㄨㄟㄩㄝ":"毀約",
"ㄏㄨㄟㄩㄢ":"會員/會元",
"ㄏㄨㄟㄩㄥ":"回用/回佣",
"ㄏㄨㄢㄅㄛ":"澴波",
"ㄏㄨㄢㄅㄞ":"環拜",
"ㄏㄨㄢㄅㄠ":"環保/環抱",
"ㄏㄨㄢㄅㄢ":"換班",
"ㄏㄨㄢㄅㄣ":"還本",
"ㄏㄨㄢㄅㄧ":"還辟",
"ㄏㄨㄢㄅㄨ":"緩步",
"ㄏㄨㄢㄆㄟ":"環佩/環珮",
"ㄏㄨㄢㄆㄠ":"幻泡",
"ㄏㄨㄢㄇㄢ":"緩慢",
"ㄏㄨㄢㄇㄣ":"歡門/宦門",
"ㄏㄨㄢㄇㄥ":"幻夢",
"ㄏㄨㄢㄇㄨ":"還目",
"ㄏㄨㄢㄈㄚ":"煥發/幻法",
"ㄏㄨㄢㄈㄤ":"換防",
"ㄏㄨㄢㄈㄨ":"驩附/萑苻",
"ㄏㄨㄢㄉㄚ":"宦達",
"ㄏㄨㄢㄉㄞ":"緩帶",
"ㄏㄨㄢㄉㄠ":"環島",
"ㄏㄨㄢㄉㄢ":"還丹",
"ㄏㄨㄢㄉㄥ":"幻燈",
"ㄏㄨㄢㄉㄧ":"浣滌",
"ㄏㄨㄢㄉㄨ":"還都/歡度",
"ㄏㄨㄢㄊㄡ":"換頭/喚頭",
"ㄏㄨㄢㄊㄢ":"桓譚",
"ㄏㄨㄢㄊㄥ":"歡騰",
"ㄏㄨㄢㄊㄨ":"宦途",
"ㄏㄨㄢㄋㄟ":"寰內",
"ㄏㄨㄢㄋㄢ":"患難",
"ㄏㄨㄢㄋㄤ":"宦囊",
"ㄏㄨㄢㄋㄩ":"宦女",
"ㄏㄨㄢㄌㄜ":"歡樂",
"ㄏㄨㄢㄌㄞ":"還來",
"ㄏㄨㄢㄌㄧ":"還禮",
"ㄏㄨㄢㄍㄡ":"獾狗",
"ㄏㄨㄢㄍㄨ":"環顧",
"ㄏㄨㄢㄎㄜ":"歡客",
"ㄏㄨㄢㄎㄨ":"患苦",
"ㄏㄨㄢㄏㄜ":"緩和/煥赫",
"ㄏㄨㄢㄏㄞ":"環海/宦海",
"ㄏㄨㄢㄏㄢ":"還翰/渙汗",
"ㄏㄨㄢㄏㄨ":"歡呼/渙乎",
"ㄏㄨㄢㄐㄧ":"還擊/還忌",
"ㄏㄨㄢㄐㄩ":"歡聚",
"ㄏㄨㄢㄑㄧ":"喚起/換氣",
"ㄏㄨㄢㄑㄩ":"換取/寰區",
"ㄏㄨㄢㄒㄧ":"歡喜/還席",
"ㄏㄨㄢㄓㄜ":"患者/轘磔",
"ㄏㄨㄢㄓㄞ":"還債",
"ㄏㄨㄢㄓㄠ":"緩召",
"ㄏㄨㄢㄓㄤ":"還帳",
"ㄏㄨㄢㄓㄥ":"還政/緩徵",
"ㄏㄨㄢㄔㄚ":"換茬",
"ㄏㄨㄢㄔㄞ":"鐶釵",
"ㄏㄨㄢㄔㄣ":"幻塵",
"ㄏㄨㄢㄔㄤ":"歡暢/歡唱",
"ㄏㄨㄢㄔㄨ":"患處",
"ㄏㄨㄢㄕㄡ":"還手/換手",
"ㄏㄨㄢㄕㄣ":"環深",
"ㄏㄨㄢㄕㄥ":"歡聲",
"ㄏㄨㄢㄕㄨ":"幻術/逭暑",
"ㄏㄨㄢㄖㄠ":"環繞",
"ㄏㄨㄢㄖㄢ":"奐然/渙然",
"ㄏㄨㄢㄖㄣ":"幻人/宦人",
"ㄏㄨㄢㄙㄢ":"渙散",
"ㄏㄨㄢㄙㄨ":"還俗",
"ㄏㄨㄢㄧㄚ":"換牙",
"ㄏㄨㄢㄧㄠ":"換藥",
"ㄏㄨㄢㄧㄡ":"環遊/宦遊",
"ㄏㄨㄢㄧㄢ":"還言/歡顏",
"ㄏㄨㄢㄧㄤ":"還陽/豢養",
"ㄏㄨㄢㄧㄥ":"歡迎/幻影",
"ㄏㄨㄢㄨㄟ":"環衛",
"ㄏㄨㄢㄨㄣ":"換文/桓文",
"ㄏㄨㄢㄩㄝ":"換約/歡躍",
"ㄏㄨㄢㄩㄢ":"還原/還願",
"ㄏㄨㄢㄩㄣ":"環暈",
"ㄏㄨㄣㄅㄟ":"昏憊/惛憊",
"ㄏㄨㄣㄅㄠ":"昏暴",
"ㄏㄨㄣㄆㄛ":"魂魄",
"ㄏㄨㄣㄆㄟ":"婚配",
"ㄏㄨㄣㄆㄨ":"渾樸",
"ㄏㄨㄣㄇㄞ":"昏邁",
"ㄏㄨㄣㄇㄟ":"昏昧",
"ㄏㄨㄣㄇㄠ":"昏眊/昏瞀",
"ㄏㄨㄣㄇㄥ":"惛懵",
"ㄏㄨㄣㄇㄧ":"昏迷",
"ㄏㄨㄣㄈㄚ":"婚閥",
"ㄏㄨㄣㄈㄤ":"混紡",
"ㄏㄨㄣㄉㄚ":"混打",
"ㄏㄨㄣㄉㄠ":"昏倒",
"ㄏㄨㄣㄉㄢ":"渾蛋",
"ㄏㄨㄣㄉㄧ":"昏第",
"ㄏㄨㄣㄊㄡ":"昏頭",
"ㄏㄨㄣㄊㄤ":"混堂/混糖",
"ㄏㄨㄣㄋㄠ":"混鬧",
"ㄏㄨㄣㄋㄧ":"昏逆",
"ㄏㄨㄣㄌㄞ":"混賴",
"ㄏㄨㄣㄌㄧ":"婚禮",
"ㄏㄨㄣㄍㄜ":"渾箇",
"ㄏㄨㄣㄍㄞ":"渾蓋",
"ㄏㄨㄣㄏㄜ":"混合/混和",
"ㄏㄨㄣㄏㄟ":"昏黑",
"ㄏㄨㄣㄏㄠ":"混號/渾號",
"ㄏㄨㄣㄏㄡ":"渾厚",
"ㄏㄨㄣㄏㄢ":"渾涵",
"ㄏㄨㄣㄐㄧ":"混跡",
"ㄏㄨㄣㄐㄩ":"混居",
"ㄏㄨㄣㄑㄧ":"婚期/諢砌",
"ㄏㄨㄣㄓㄜ":"閽者",
"ㄏㄨㄣㄓㄢ":"混戰",
"ㄏㄨㄣㄓㄤ":"混帳/渾賬",
"ㄏㄨㄣㄓㄨ":"昏主",
"ㄏㄨㄣㄔㄜ":"魂車",
"ㄏㄨㄣㄔㄠ":"昏鈔",
"ㄏㄨㄣㄔㄢ":"混纏",
"ㄏㄨㄣㄔㄣ":"昏沉",
"ㄏㄨㄣㄔㄥ":"混成/渾成",
"ㄏㄨㄣㄕㄚ":"婚紗/溷殺",
"ㄏㄨㄣㄕㄣ":"渾身",
"ㄏㄨㄣㄕㄨ":"鼲鼠",
"ㄏㄨㄣㄖㄗ":"混日子",
"ㄏㄨㄣㄖㄢ":"渾然/惛然",
"ㄏㄨㄣㄖㄣ":"混人/渾人",
"ㄏㄨㄣㄖㄨ":"混入",
"ㄏㄨㄣㄗㄚ":"混雜",
"ㄏㄨㄣㄗㄜ":"昏昃",
"ㄏㄨㄣㄘㄞ":"混猜/葷菜",
"ㄏㄨㄣㄙㄚ":"昏撒",
"ㄏㄨㄣㄧㄠ":"混淆/渾殽",
"ㄏㄨㄣㄧㄢ":"婚宴/渾言",
"ㄏㄨㄣㄧㄣ":"婚姻/混音",
"ㄏㄨㄣㄩㄝ":"婚約",
"ㄏㄨㄣㄩㄢ":"混元/渾圓",
"ㄏㄨㄣㄩㄥ":"昏庸",
"ㄏㄨㄤㄅㄚ":"黃霸",
"ㄏㄨㄤㄅㄛ":"黃柏/黃蘗",
"ㄏㄨㄤㄅㄞ":"黃白",
"ㄏㄨㄤㄅㄠ":"謊報",
"ㄏㄨㄤㄅㄢ":"黃斑/艎板",
"ㄏㄨㄤㄅㄤ":"黃榜",
"ㄏㄨㄤㄅㄧ":"皇辟/皇妣",
"ㄏㄨㄤㄅㄨ":"惶怖",
"ㄏㄨㄤㄆㄛ":"黃婆",
"ㄏㄨㄤㄆㄞ":"黃牌",
"ㄏㄨㄤㄆㄠ":"黃袍",
"ㄏㄨㄤㄆㄤ":"黃胖",
"ㄏㄨㄤㄆㄧ":"荒僻",
"ㄏㄨㄤㄇㄚ":"黃麻",
"ㄏㄨㄤㄇㄛ":"荒漠",
"ㄏㄨㄤㄇㄟ":"黃梅",
"ㄏㄨㄤㄇㄠ":"黃帽",
"ㄏㄨㄤㄇㄣ":"黃門",
"ㄏㄨㄤㄇㄤ":"慌忙/荒忙",
"ㄏㄨㄤㄇㄧ":"黃米",
"ㄏㄨㄤㄈㄚ":"黃髮",
"ㄏㄨㄤㄈㄟ":"荒廢/黃扉",
"ㄏㄨㄤㄈㄣ":"皇墳",
"ㄏㄨㄤㄈㄥ":"黃蜂/黃風",
"ㄏㄨㄤㄈㄨ":"荒服/皇甫",
"ㄏㄨㄤㄉㄞ":"荒怠",
"ㄏㄨㄤㄉㄠ":"荒島/黃道",
"ㄏㄨㄤㄉㄡ":"黃豆",
"ㄏㄨㄤㄉㄢ":"荒誕/黃疸",
"ㄏㄨㄤㄉㄤ":"晃蕩",
"ㄏㄨㄤㄉㄥ":"黃燈",
"ㄏㄨㄤㄉㄧ":"皇帝/荒地",
"ㄏㄨㄤㄉㄨ":"皇都",
"ㄏㄨㄤㄊㄤ":"荒唐/黃堂",
"ㄏㄨㄤㄊㄧ":"黃體",
"ㄏㄨㄤㄊㄨ":"黃土/皇圖",
"ㄏㄨㄤㄋㄠ":"晃腦",
"ㄏㄨㄤㄋㄢ":"蝗蝻",
"ㄏㄨㄤㄌㄚ":"黃蠟",
"ㄏㄨㄤㄌㄠ":"黃老",
"ㄏㄨㄤㄌㄡ":"黃樓",
"ㄏㄨㄤㄌㄢ":"皇覽",
"ㄏㄨㄤㄌㄤ":"晃朗",
"ㄏㄨㄤㄌㄧ":"黃曆/黃鸝",
"ㄏㄨㄤㄌㄨ":"黃壚",
"ㄏㄨㄤㄍㄜ":"黃閣",
"ㄏㄨㄤㄍㄞ":"黃蓋",
"ㄏㄨㄤㄍㄤ":"皇綱",
"ㄏㄨㄤㄍㄨ":"黃姑/皇姑",
"ㄏㄨㄤㄎㄠ":"皇考",
"ㄏㄨㄤㄎㄡ":"黃口",
"ㄏㄨㄤㄎㄢ":"黃侃/皇侃",
"ㄏㄨㄤㄏㄜ":"黃河/黃禾",
"ㄏㄨㄤㄏㄞ":"黃海",
"ㄏㄨㄤㄏㄡ":"皇后",
"ㄏㄨㄤㄏㄢ":"惶汗/荒旱",
"ㄏㄨㄤㄏㄨ":"黃鵠/荒忽",
"ㄏㄨㄤㄐㄧ":"皇極/荒雞",
"ㄏㄨㄤㄐㄩ":"荒居/惶遽",
"ㄏㄨㄤㄒㄩ":"黃鬚/黃馘",
"ㄏㄨㄤㄓㄚ":"謊詐",
"ㄏㄨㄤㄓㄡ":"黃州/皇州",
"ㄏㄨㄤㄓㄤ":"慌張",
"ㄏㄨㄤㄓㄥ":"荒政",
"ㄏㄨㄤㄓㄨ":"篁竹",
"ㄏㄨㄤㄔㄠ":"皇朝/黃巢",
"ㄏㄨㄤㄔㄣ":"黃塵",
"ㄏㄨㄤㄔㄤ":"黃腸",
"ㄏㄨㄤㄔㄥ":"皇城",
"ㄏㄨㄤㄔㄨ":"皇儲",
"ㄏㄨㄤㄕㄡ":"黃瘦",
"ㄏㄨㄤㄕㄢ":"荒山/黃山",
"ㄏㄨㄤㄕㄣ":"慌神",
"ㄏㄨㄤㄕㄤ":"皇上",
"ㄏㄨㄤㄕㄨ":"黃熟/皇叔",
"ㄏㄨㄤㄖㄢ":"恍然/惶然",
"ㄏㄨㄤㄖㄤ":"黃壤",
"ㄏㄨㄤㄖㄨ":"恍如",
"ㄏㄨㄤㄗㄞ":"蝗災",
"ㄏㄨㄤㄗㄡ":"荒陬",
"ㄏㄨㄤㄗㄨ":"皇族/皇祖",
"ㄏㄨㄤㄘㄜ":"黃冊",
"ㄏㄨㄤㄘㄠ":"荒草",
"ㄏㄨㄤㄘㄤ":"荒傖",
"ㄏㄨㄤㄙㄜ":"黃色",
"ㄏㄨㄤㄙㄨ":"慌速",
"ㄏㄨㄤㄧㄚ":"黃芽",
"ㄏㄨㄤㄧㄝ":"荒野/黃頁",
"ㄏㄨㄤㄧㄠ":"晃搖/晃耀",
"ㄏㄨㄤㄧㄡ":"黃油/黃鼬",
"ㄏㄨㄤㄧㄢ":"謊言",
"ㄏㄨㄤㄧㄣ":"荒銀/荒淫",
"ㄏㄨㄤㄧㄤ":"潢洋/滉漾",
"ㄏㄨㄤㄧㄥ":"黃鶯",
"ㄏㄨㄤㄨㄞ":"荒外",
"ㄏㄨㄤㄨㄟ":"皇位/皇威",
"ㄏㄨㄤㄨㄣ":"黃吻",
"ㄏㄨㄤㄨㄤ":"荒亡",
"ㄏㄨㄤㄩㄝ":"荒月/黃鉞",
"ㄏㄨㄤㄩㄢ":"荒原",
"ㄏㄨㄥㄅㄛ":"洪波/鴻博",
"ㄏㄨㄥㄅㄟ":"烘焙",
"ㄏㄨㄥㄅㄠ":"紅包",
"ㄏㄨㄥㄅㄤ":"紅幫/洪幫",
"ㄏㄨㄥㄅㄧ":"鴻筆",
"ㄏㄨㄥㄆㄞ":"紅牌",
"ㄏㄨㄥㄆㄢ":"紅盤",
"ㄏㄨㄥㄆㄧ":"紅砒",
"ㄏㄨㄥㄇㄛ":"紅墨/虹膜",
"ㄏㄨㄥㄇㄞ":"洪邁",
"ㄏㄨㄥㄇㄟ":"紅煤",
"ㄏㄨㄥㄇㄠ":"紅毛/鴻毛",
"ㄏㄨㄥㄇㄣ":"洪門/鴻門",
"ㄏㄨㄥㄇㄥ":"鴻蒙/澒濛",
"ㄏㄨㄥㄇㄨ":"紅木",
"ㄏㄨㄥㄈㄚ":"弘法",
"ㄏㄨㄥㄈㄟ":"鴻飛",
"ㄏㄨㄥㄈㄢ":"洪範/鴻範",
"ㄏㄨㄥㄈㄣ":"紅粉",
"ㄏㄨㄥㄈㄤ":"宏放",
"ㄏㄨㄥㄈㄥ":"洪峰",
"ㄏㄨㄥㄈㄨ":"紅拂/洪福",
"ㄏㄨㄥㄉㄚ":"洪大/宏大",
"ㄏㄨㄥㄉㄠ":"弘道",
"ㄏㄨㄥㄉㄡ":"紅豆",
"ㄏㄨㄥㄉㄢ":"紅蛋/閎誕",
"ㄏㄨㄥㄉㄥ":"紅燈",
"ㄏㄨㄥㄉㄧ":"轟的",
"ㄏㄨㄥㄉㄨ":"鴻都",
"ㄏㄨㄥㄊㄞ":"哄抬",
"ㄏㄨㄥㄊㄠ":"紅陶/洪濤",
"ㄏㄨㄥㄊㄡ":"紅頭",
"ㄏㄨㄥㄊㄢ":"紅毯",
"ㄏㄨㄥㄊㄤ":"紅糖/哄堂",
"ㄏㄨㄥㄊㄨ":"紅土/鴻圖",
"ㄏㄨㄥㄋㄧ":"虹蜺/虹霓",
"ㄏㄨㄥㄌㄟ":"紅淚",
"ㄏㄨㄥㄌㄡ":"紅樓",
"ㄏㄨㄥㄌㄢ":"宏覽",
"ㄏㄨㄥㄌㄧ":"紅利/宏麗",
"ㄏㄨㄥㄌㄨ":"洪爐/烘爐",
"ㄏㄨㄥㄍㄡ":"鴻溝/宏構",
"ㄏㄨㄥㄍㄢ":"烘乾",
"ㄏㄨㄥㄍㄤ":"洪剛/宏綱",
"ㄏㄨㄥㄎㄠ":"烘烤",
"ㄏㄨㄥㄏㄜ":"紅河/紅鶴",
"ㄏㄨㄥㄏㄨ":"鴻鵠",
"ㄏㄨㄥㄐㄧ":"洪基/鴻基",
"ㄏㄨㄥㄑㄧ":"紅旗/紅契",
"ㄏㄨㄥㄒㄧ":"鴻禧",
"ㄏㄨㄥㄒㄩ":"鴻緒",
"ㄏㄨㄥㄓㄚ":"轟炸",
"ㄏㄨㄥㄓㄠ":"鴻爪",
"ㄏㄨㄥㄔㄚ":"紅茶",
"ㄏㄨㄥㄔㄠ":"紅潮/烘炒",
"ㄏㄨㄥㄔㄣ":"紅塵/轟沉",
"ㄏㄨㄥㄔㄤ":"宏敞",
"ㄏㄨㄥㄔㄥ":"泓澄",
"ㄏㄨㄥㄕㄚ":"紅沙",
"ㄏㄨㄥㄕㄜ":"黌舍",
"ㄏㄨㄥㄕㄠ":"紅燒",
"ㄏㄨㄥㄕㄢ":"紅杉",
"ㄏㄨㄥㄕㄣ":"弘深",
"ㄏㄨㄥㄕㄥ":"紅生/洪生",
"ㄏㄨㄥㄕㄨ":"紅樹",
"ㄏㄨㄥㄖㄢ":"轟然/哄然",
"ㄏㄨㄥㄖㄣ":"紅人/哄人",
"ㄏㄨㄥㄖㄤ":"紅壤",
"ㄏㄨㄥㄖㄨ":"鴻儒",
"ㄏㄨㄥㄗㄞ":"洪災",
"ㄏㄨㄥㄗㄠ":"紅棗/紅藻",
"ㄏㄨㄥㄗㄡ":"轟走",
"ㄏㄨㄥㄗㄨ":"訌阻",
"ㄏㄨㄥㄘㄞ":"虹彩/鴻裁",
"ㄏㄨㄥㄙㄜ":"紅色",
"ㄏㄨㄥㄙㄨ":"紅素",
"ㄏㄨㄥㄧㄗ":"紅椅子",
"ㄏㄨㄥㄧㄚ":"紅牙",
"ㄏㄨㄥㄧㄝ":"紅葉/洪業",
"ㄏㄨㄥㄧㄠ":"紅藥",
"ㄏㄨㄥㄧㄡ":"紅油/鴻猷",
"ㄏㄨㄥㄧㄢ":"紅顏/紅眼",
"ㄏㄨㄥㄧㄣ":"洪音/轟飲",
"ㄏㄨㄥㄧㄤ":"弘揚/紅樣",
"ㄏㄨㄥㄨㄟ":"宏偉/洪惟",
"ㄏㄨㄥㄨㄣ":"鴻文",
"ㄏㄨㄥㄩㄢ":"宏願/弘遠",
"ㄏㄨㄥㄩㄣ":"紅運/紅雲",
"ㄏㄨㄦㄘㄠ":"虎耳草",
"ㄏㄨㄧㄢㄕ":"虎眼石",
"ㄐㄧㄅㄧㄝ":"級別",
"ㄐㄧㄅㄧㄢ":"機變/即便",
"ㄐㄧㄅㄧㄣ":"罽賓",
"ㄐㄧㄅㄧㄥ":"疾病/機兵",
"ㄐㄧㄆㄧㄠ":"機票/計票",
"ㄐㄧㄆㄧㄢ":"咭片",
"ㄐㄧㄆㄧㄣ":"極品/濟貧",
"ㄐㄧㄆㄧㄥ":"譏評",
"ㄐㄧㄇㄧㄝ":"擊滅/寂滅",
"ㄐㄧㄇㄧㄣ":"飢民/機敏",
"ㄐㄧㄇㄧㄥ":"雞鳴/記名",
"ㄐㄧㄉㄧㄠ":"基調/祭弔",
"ㄐㄧㄉㄧㄢ":"極點/祭典",
"ㄐㄧㄉㄧㄥ":"既定/吉丁",
"ㄐㄧㄉㄨㄛ":"幾多",
"ㄐㄧㄉㄨㄟ":"擠對/擠兌",
"ㄐㄧㄉㄨㄢ":"極端/機斷",
"ㄐㄧㄉㄨㄣ":"幾頓/寄頓",
"ㄐㄧㄉㄨㄥ":"激動/機動",
"ㄐㄧㄊㄧㄝ":"吉帖",
"ㄐㄧㄊㄧㄠ":"鯽跳",
"ㄐㄧㄊㄧㄢ":"祭天/籍田",
"ㄐㄧㄊㄨㄛ":"寄託/擊柝",
"ㄐㄧㄊㄨㄟ":"擊退",
"ㄐㄧㄊㄨㄢ":"集團/急湍",
"ㄐㄧㄊㄨㄣ":"急吞",
"ㄐㄧㄊㄨㄥ":"繼統/激筒",
"ㄐㄧㄋㄢㄕ":"濟南市",
"ㄐㄧㄋㄧㄢ":"紀念/記念",
"ㄐㄧㄋㄧㄥ":"薺薴",
"ㄐㄧㄋㄨㄥ":"唧噥",
"ㄐㄧㄌㄧㄝ":"激烈",
"ㄐㄧㄌㄧㄠ":"寂寥",
"ㄐㄧㄌㄧㄡ":"羈留/激流",
"ㄐㄧㄌㄧㄢ":"畸戀",
"ㄐㄧㄌㄧㄣ":"吉林/棘林",
"ㄐㄧㄌㄧㄤ":"劑量/技倆",
"ㄐㄧㄌㄧㄥ":"機靈/即令",
"ㄐㄧㄌㄨㄛ":"擊落",
"ㄐㄧㄌㄨㄢ":"雞卵",
"ㄐㄧㄌㄨㄣ":"激論/棘輪",
"ㄐㄧㄌㄨㄥ":"雞籠",
"ㄐㄧㄌㄩㄝ":"計略/紀略",
"ㄐㄧㄍㄨㄚ":"記掛/機括",
"ㄐㄧㄍㄨㄛ":"記過",
"ㄐㄧㄍㄨㄟ":"繼軌",
"ㄐㄧㄍㄨㄢ":"機關/雞冠",
"ㄐㄧㄍㄨㄤ":"激光/極光",
"ㄐㄧㄍㄨㄥ":"技工/機工",
"ㄐㄧㄎㄨㄞ":"計會/疾快",
"ㄐㄧㄎㄨㄟ":"擊潰",
"ㄐㄧㄎㄨㄢ":"寄款",
"ㄐㄧㄎㄨㄣ":"飢困",
"ㄐㄧㄎㄨㄥ":"祭孔",
"ㄐㄧㄏㄨㄚ":"計劃/激話",
"ㄐㄧㄏㄨㄛ":"即或/飢火",
"ㄐㄧㄏㄨㄞ":"寄懷/羈懷",
"ㄐㄧㄏㄨㄟ":"機會/集會",
"ㄐㄧㄏㄨㄢ":"疾患/羈宦",
"ㄐㄧㄏㄨㄤ":"饑荒/急慌",
"ㄐㄧㄐㄧㄚ":"幾家/計價",
"ㄐㄧㄐㄧㄝ":"季節/集結",
"ㄐㄧㄐㄧㄠ":"計較/急腳",
"ㄐㄧㄐㄧㄡ":"急救/急就",
"ㄐㄧㄐㄧㄢ":"雞姦/機件",
"ㄐㄧㄐㄧㄣ":"基金/激進",
"ㄐㄧㄐㄧㄤ":"即將/激將",
"ㄐㄧㄐㄧㄥ":"寂靜/機警",
"ㄐㄧㄐㄩㄝ":"肌覺/繼絕",
"ㄐㄧㄐㄩㄣ":"季軍",
"ㄐㄧㄐㄩㄥ":"基扃",
"ㄐㄧㄑㄧㄝ":"急切/急且",
"ㄐㄧㄑㄧㄠ":"技巧/機巧",
"ㄐㄧㄑㄧㄡ":"冀求/擊毬",
"ㄐㄧㄑㄧㄢ":"忌前/積欠",
"ㄐㄧㄑㄧㄣ":"繼親",
"ㄐㄧㄑㄧㄤ":"機槍",
"ㄐㄧㄑㄧㄥ":"激情/寄情",
"ㄐㄧㄑㄩㄝ":"急缺",
"ㄐㄧㄑㄩㄢ":"極權/極圈",
"ㄐㄧㄑㄩㄣ":"機群",
"ㄐㄧㄑㄩㄥ":"技窮/計窮",
"ㄐㄧㄒㄧㄚ":"寄下/稷下",
"ㄐㄧㄒㄧㄝ":"機械/計偕",
"ㄐㄧㄒㄧㄠ":"譏笑/績效",
"ㄐㄧㄒㄧㄢ":"極限/機先",
"ㄐㄧㄒㄧㄣ":"機心/記心",
"ㄐㄧㄒㄧㄤ":"跡象/吉祥",
"ㄐㄧㄒㄧㄥ":"記性/急性",
"ㄐㄧㄒㄩㄝ":"績學/積雪",
"ㄐㄧㄒㄩㄢ":"極選",
"ㄐㄧㄒㄩㄣ":"集訓",
"ㄐㄧㄒㄩㄥ":"雞胸/吉凶",
"ㄐㄧㄓㄨㄛ":"祭桌/祭酌",
"ㄐㄧㄓㄨㄟ":"脊椎",
"ㄐㄧㄓㄨㄣ":"基準",
"ㄐㄧㄓㄨㄤ":"急裝",
"ㄐㄧㄓㄨㄥ":"集中/幾眾",
"ㄐㄧㄔㄨㄣ":"季春",
"ㄐㄧㄔㄨㄤ":"機床/雞窗",
"ㄐㄧㄕㄅㄣ":"記事本",
"ㄐㄧㄕㄊㄥ":"雞屎藤",
"ㄐㄧㄕㄥㄨ":"寄生物",
"ㄐㄧㄕㄨㄟ":"積水/濟水",
"ㄐㄧㄖㄣㄓ":"級任制",
"ㄐㄧㄖㄦㄙ":"計日而俟",
"ㄐㄧㄖㄨㄛ":"積弱",
"ㄐㄧㄖㄨㄥ":"即戎",
"ㄐㄧㄗㄠㄦ":"即早兒",
"ㄐㄧㄗㄨㄛ":"積作/基座",
"ㄐㄧㄗㄨㄟ":"既醉/忌嘴",
"ㄐㄧㄗㄨㄥ":"騎從",
"ㄐㄧㄘㄨㄛ":"擠撮",
"ㄐㄧㄘㄨㄣ":"積存/集村",
"ㄐㄧㄙㄆㄛ":"既死魄",
"ㄐㄧㄙㄞㄦ":"吉賽爾",
"ㄐㄧㄙㄨㄛ":"脊索",
"ㄐㄧㄙㄨㄟ":"幾歲/期歲",
"ㄐㄧㄙㄨㄢ":"計算/肌酸",
"ㄐㄧㄙㄨㄥ":"記誦/偈頌",
"ㄐㄧㄚㄅㄛ":"家缽",
"ㄐㄧㄚㄅㄞ":"俠拜",
"ㄐㄧㄚㄅㄟ":"加倍/夾被",
"ㄐㄧㄚㄅㄢ":"加班/假扮",
"ㄐㄧㄚㄅㄣ":"佳本/甲苯",
"ㄐㄧㄚㄅㄤ":"家邦/甲榜",
"ㄐㄧㄚㄅㄥ":"駕崩",
"ㄐㄧㄚㄅㄧ":"價比/夾壁",
"ㄐㄧㄚㄆㄞ":"加派",
"ㄐㄧㄚㄆㄠ":"夾袍",
"ㄐㄧㄚㄆㄨ":"家譜/家僕",
"ㄐㄧㄚㄇㄚ":"加碼/價碼",
"ㄐㄧㄚㄇㄛ":"夾磨/嘉謨",
"ㄐㄧㄚㄇㄟ":"假寐",
"ㄐㄧㄚㄇㄠ":"假冒",
"ㄐㄧㄚㄇㄣ":"家門/甲門",
"ㄐㄧㄚㄇㄥ":"加盟",
"ㄐㄧㄚㄇㄨ":"家母/價目",
"ㄐㄧㄚㄈㄚ":"家法/假髮",
"ㄐㄧㄚㄈㄟ":"鉀肥",
"ㄐㄧㄚㄈㄣ":"甲酚",
"ㄐㄧㄚㄈㄤ":"家訪",
"ㄐㄧㄚㄈㄥ":"夾縫/家風",
"ㄐㄧㄚㄈㄨ":"家父/假父",
"ㄐㄧㄚㄉㄜ":"家的",
"ㄐㄧㄚㄉㄞ":"夾帶/假貸",
"ㄐㄧㄚㄉㄠ":"家道/假道",
"ㄐㄧㄚㄉㄢ":"價單/夾單",
"ㄐㄧㄚㄉㄤ":"家當",
"ㄐㄧㄚㄉㄥ":"甲等",
"ㄐㄧㄚㄉㄧ":"家弟/甲第",
"ㄐㄧㄚㄉㄨ":"家督",
"ㄐㄧㄚㄊㄚ":"假榻",
"ㄐㄧㄚㄊㄡ":"甲頭/駕頭",
"ㄐㄧㄚㄊㄤ":"家堂",
"ㄐㄧㄚㄊㄧ":"梜提",
"ㄐㄧㄚㄊㄨ":"家兔",
"ㄐㄧㄚㄋㄠ":"夾腦",
"ㄐㄧㄚㄋㄤ":"頰囊",
"ㄐㄧㄚㄋㄨ":"家奴",
"ㄐㄧㄚㄋㄩ":"假女",
"ㄐㄧㄚㄌㄜ":"假樂",
"ㄐㄧㄚㄌㄞ":"家來",
"ㄐㄧㄚㄌㄟ":"家累/家累",
"ㄐㄧㄚㄌㄠ":"家老",
"ㄐㄧㄚㄌㄢ":"迦藍",
"ㄐㄧㄚㄌㄧ":"佳麗/家例",
"ㄐㄧㄚㄌㄨ":"夾路/賈魯",
"ㄐㄧㄚㄍㄜ":"價格/家鴿",
"ㄐㄧㄚㄍㄡ":"架構",
"ㄐㄧㄚㄍㄢ":"夾肝",
"ㄐㄧㄚㄍㄣ":"假根",
"ㄐㄧㄚㄍㄨ":"胛骨/嘉穀",
"ㄐㄧㄚㄎㄜ":"家客/嘉客",
"ㄐㄧㄚㄎㄡ":"家口",
"ㄐㄧㄚㄏㄜ":"嘉禾",
"ㄐㄧㄚㄏㄞ":"加害",
"ㄐㄧㄚㄏㄠ":"加號/枷號",
"ㄐㄧㄚㄏㄡ":"賈后",
"ㄐㄧㄚㄏㄢ":"家寒",
"ㄐㄧㄚㄏㄨ":"加護",
"ㄐㄧㄚㄐㄧ":"夾擊/佳績",
"ㄐㄧㄚㄐㄩ":"家居/家具",
"ㄐㄧㄚㄑㄧ":"假期/佳氣",
"ㄐㄧㄚㄑㄩ":"家去/佳趣",
"ㄐㄧㄚㄒㄧ":"加息/家系",
"ㄐㄧㄚㄒㄩ":"嘉許/佳婿",
"ㄐㄧㄚㄓㄠ":"駕照",
"ㄐㄧㄚㄓㄡ":"加州/甲冑",
"ㄐㄧㄚㄓㄣ":"家珍",
"ㄐㄧㄚㄓㄤ":"家長/甲長",
"ㄐㄧㄚㄓㄥ":"家政",
"ㄐㄧㄚㄓㄨ":"加注/家主",
"ㄐㄧㄚㄔㄜ":"駕車",
"ㄐㄧㄚㄔㄠ":"假鈔",
"ㄐㄧㄚㄔㄡ":"家醜",
"ㄐㄧㄚㄔㄢ":"家產/家蠶",
"ㄐㄧㄚㄔㄣ":"家臣/浹辰",
"ㄐㄧㄚㄔㄤ":"家常/甲裳",
"ㄐㄧㄚㄔㄥ":"加成/假稱",
"ㄐㄧㄚㄔㄨ":"家畜/夏楚",
"ㄐㄧㄚㄕㄚ":"夾殺/袈裟",
"ㄐㄧㄚㄕㄜ":"假設/架設",
"ㄐㄧㄚㄕㄠ":"枷梢",
"ㄐㄧㄚㄕㄡ":"假手",
"ㄐㄧㄚㄕㄢ":"家山/假山",
"ㄐㄧㄚㄕㄣ":"加深",
"ㄐㄧㄚㄕㄤ":"家商/嘉尚",
"ㄐㄧㄚㄕㄥ":"家聲/家生",
"ㄐㄧㄚㄕㄨ":"家書/家屬",
"ㄐㄧㄚㄖㄜ":"加熱",
"ㄐㄧㄚㄖㄠ":"假饒",
"ㄐㄧㄚㄖㄢ":"戛然",
"ㄐㄧㄚㄖㄣ":"家人/嫁人",
"ㄐㄧㄚㄖㄨ":"加入/假如",
"ㄐㄧㄚㄗㄚ":"夾雜",
"ㄐㄧㄚㄗㄟ":"家賊",
"ㄐㄧㄚㄗㄠ":"假造/鉀皂",
"ㄐㄧㄚㄗㄡ":"架走",
"ㄐㄧㄚㄗㄨ":"家族/家祖",
"ㄐㄧㄚㄘㄞ":"家財",
"ㄐㄧㄚㄘㄥ":"夾層",
"ㄐㄧㄚㄙㄜ":"稼穡",
"ㄐㄧㄚㄙㄠ":"家嫂",
"ㄐㄧㄚㄙㄦ":"假廝兒",
"ㄐㄧㄚㄙㄨ":"加速",
"ㄐㄧㄚㄧㄕ":"嘉義市",
"ㄐㄧㄚㄧㄚ":"家鴨/加壓",
"ㄐㄧㄚㄧㄝ":"家業/家頁",
"ㄐㄧㄚㄧㄠ":"佳餚/嘉殽",
"ㄐㄧㄚㄧㄡ":"加油/嘉祐",
"ㄐㄧㄚㄧㄢ":"家嚴/家宴",
"ㄐㄧㄚㄧㄣ":"佳音",
"ㄐㄧㄚㄧㄥ":"家蠅",
"ㄐㄧㄚㄨㄕ":"家務事",
"ㄐㄧㄚㄨㄟ":"價位/葭葦",
"ㄐㄧㄚㄨㄢ":"甲烷",
"ㄐㄧㄚㄨㄣ":"家問/家蚊",
"ㄐㄧㄚㄩㄝ":"家樂",
"ㄐㄧㄚㄩㄢ":"家園/家緣",
"ㄐㄧㄚㄩㄣ":"戛雲",
"ㄐㄧㄚㄩㄥ":"家用",
"ㄐㄧㄜㄊㄡ":"擠訛頭",
"ㄐㄧㄝㄅㄚ":"結巴",
"ㄐㄧㄝㄅㄛ":"接駁",
"ㄐㄧㄝㄅㄞ":"結拜/潔白",
"ㄐㄧㄝㄅㄟ":"戒備/界碑",
"ㄐㄧㄝㄅㄠ":"捷報",
"ㄐㄧㄝㄅㄢ":"結伴/接班",
"ㄐㄧㄝㄅㄣ":"節本",
"ㄐㄧㄝㄅㄤ":"接棒/揭榜",
"ㄐㄧㄝㄅㄧ":"結筆/櫛比",
"ㄐㄧㄝㄅㄨ":"揭布",
"ㄐㄧㄝㄆㄛ":"揭破",
"ㄐㄧㄝㄆㄞ":"節拍/界牌",
"ㄐㄧㄝㄆㄟ":"解佩",
"ㄐㄧㄝㄆㄡ":"解剖",
"ㄐㄧㄝㄆㄧ":"潔癖/解批",
"ㄐㄧㄝㄇㄚ":"解碼",
"ㄐㄧㄝㄇㄛ":"結末/結磨",
"ㄐㄧㄝㄇㄞ":"結脈",
"ㄐㄧㄝㄇㄟ":"姐妹",
"ㄐㄧㄝㄇㄠ":"睫毛/結茅",
"ㄐㄧㄝㄇㄢ":"屆滿",
"ㄐㄧㄝㄇㄣ":"解悶/街門",
"ㄐㄧㄝㄇㄥ":"結盟/解夢",
"ㄐㄧㄝㄇㄧ":"解謎/揭祕",
"ㄐㄧㄝㄇㄨ":"節目/揭幕",
"ㄐㄧㄝㄈㄚ":"揭發/解發",
"ㄐㄧㄝㄈㄟ":"劫匪",
"ㄐㄧㄝㄈㄣ":"界分/解紛",
"ㄐㄧㄝㄈㄤ":"街坊/解放",
"ㄐㄧㄝㄈㄥ":"結風/接風",
"ㄐㄧㄝㄈㄨ":"姐夫/解縛",
"ㄐㄧㄝㄉㄚ":"解答",
"ㄐㄧㄝㄉㄞ":"接待/借代",
"ㄐㄧㄝㄉㄠ":"街道/解道",
"ㄐㄧㄝㄉㄡ":"楬豆",
"ㄐㄧㄝㄉㄢ":"借單/節蛋",
"ㄐㄧㄝㄉㄤ":"解當/結黨",
"ㄐㄧㄝㄉㄥ":"街燈",
"ㄐㄧㄝㄉㄧ":"芥蒂/接地",
"ㄐㄧㄝㄉㄨ":"解讀/解毒",
"ㄐㄧㄝㄊㄜ":"介特",
"ㄐㄧㄝㄊㄠ":"解套",
"ㄐㄧㄝㄊㄡ":"街頭/接頭",
"ㄐㄧㄝㄊㄢ":"接談/戒壇",
"ㄐㄧㄝㄊㄧ":"階梯/解體",
"ㄐㄧㄝㄊㄨ":"戒塗",
"ㄐㄧㄝㄋㄚ":"接納/結納",
"ㄐㄧㄝㄋㄢ":"劫難/解難",
"ㄐㄧㄝㄋㄤ":"解囊",
"ㄐㄧㄝㄋㄥ":"節能",
"ㄐㄧㄝㄋㄧ":"桀溺",
"ㄐㄧㄝㄋㄩ":"節女",
"ㄐㄧㄝㄌㄚ":"戒臘",
"ㄐㄧㄝㄌㄜ":"結了",
"ㄐㄧㄝㄌㄞ":"疥癩",
"ㄐㄧㄝㄌㄟ":"街壘",
"ㄐㄧㄝㄌㄠ":"劫牢",
"ㄐㄧㄝㄌㄢ":"解纜/結攬",
"ㄐㄧㄝㄌㄤ":"潔郎",
"ㄐㄧㄝㄌㄧ":"竭力/接力",
"ㄐㄧㄝㄌㄨ":"節錄/揭露",
"ㄐㄧㄝㄌㄩ":"戒律/節律",
"ㄐㄧㄝㄍㄞ":"節概",
"ㄐㄧㄝㄍㄠ":"訐告/截稿",
"ㄐㄧㄝㄍㄡ":"結構/解構",
"ㄐㄧㄝㄍㄢ":"揭竿",
"ㄐㄧㄝㄍㄣ":"結根",
"ㄐㄧㄝㄍㄥ":"桔梗",
"ㄐㄧㄝㄍㄨ":"借故/解僱",
"ㄐㄧㄝㄎㄜ":"接客/解渴",
"ㄐㄧㄝㄎㄞ":"解開/揭開",
"ㄐㄧㄝㄎㄡ":"藉口/街口",
"ㄐㄧㄝㄎㄤ":"拮抗",
"ㄐㄧㄝㄎㄨ":"解庫",
"ㄐㄧㄝㄏㄜ":"結合/解和",
"ㄐㄧㄝㄏㄠ":"結好",
"ㄐㄧㄝㄏㄡ":"節候/結喉",
"ㄐㄧㄝㄏㄣ":"解恨",
"ㄐㄧㄝㄏㄨ":"嗟乎/戒護",
"ㄐㄧㄝㄐㄧ":"階級/接濟",
"ㄐㄧㄝㄐㄩ":"結局/結聚",
"ㄐㄧㄝㄑㄧ":"節氣/解氣",
"ㄐㄧㄝㄑㄩ":"街衢/截取",
"ㄐㄧㄝㄒㄧ":"解析/解攜",
"ㄐㄧㄝㄒㄩ":"接續/嗟吁",
"ㄐㄧㄝㄓㄜ":"接著/揭折",
"ㄐㄧㄝㄓㄞ":"借債/揭債",
"ㄐㄧㄝㄓㄠ":"接招",
"ㄐㄧㄝㄓㄡ":"介冑/桀紂",
"ㄐㄧㄝㄓㄢ":"接戰",
"ㄐㄧㄝㄓㄤ":"結帳/接掌",
"ㄐㄧㄝㄓㄥ":"結正",
"ㄐㄧㄝㄓㄨ":"借助/借住",
"ㄐㄧㄝㄔㄞ":"解差/解拆",
"ㄐㄧㄝㄔㄠ":"解嘲",
"ㄐㄧㄝㄔㄡ":"解愁/結仇",
"ㄐㄧㄝㄔㄢ":"解饞",
"ㄐㄧㄝㄔㄤ":"結腸",
"ㄐㄧㄝㄔㄥ":"解呈/解酲",
"ㄐㄧㄝㄔㄨ":"接觸/解除",
"ㄐㄧㄝㄕㄚ":"接殺/結煞",
"ㄐㄧㄝㄕㄜ":"結社/結舌",
"ㄐㄧㄝㄕㄠ":"介紹",
"ㄐㄧㄝㄕㄡ":"接受/接收",
"ㄐㄧㄝㄕㄢ":"接衫/介山",
"ㄐㄧㄝㄕㄣ":"接神/潔身",
"ㄐㄧㄝㄕㄤ":"街上/界上",
"ㄐㄧㄝㄕㄥ":"節省/接生",
"ㄐㄧㄝㄕㄨ":"結束/解數",
"ㄐㄧㄝㄖㄢ":"介然/皆然",
"ㄐㄧㄝㄖㄣ":"接任/解人",
"ㄐㄧㄝㄖㄤ":"接壤",
"ㄐㄧㄝㄖㄨ":"介入",
"ㄐㄧㄝㄗㄚ":"結紮",
"ㄐㄧㄝㄗㄜ":"詰責/介幘",
"ㄐㄧㄝㄗㄠ":"接灶",
"ㄐㄧㄝㄗㄡ":"節奏",
"ㄐㄧㄝㄗㄨ":"解組/街卒",
"ㄐㄧㄝㄘㄞ":"芥菜/結彩",
"ㄐㄧㄝㄘㄠ":"結草/節操",
"ㄐㄧㄝㄘㄥ":"階層",
"ㄐㄧㄝㄘㄨ":"接簇",
"ㄐㄧㄝㄙㄢ":"解散/接三",
"ㄐㄧㄝㄙㄨ":"借宿/解素",
"ㄐㄧㄝㄧㄚ":"嗟呀/結押",
"ㄐㄧㄝㄧㄝ":"結業",
"ㄐㄧㄝㄧㄠ":"節要/解藥",
"ㄐㄧㄝㄧㄡ":"結友/解憂",
"ㄐㄧㄝㄧㄢ":"戒嚴/解嚴",
"ㄐㄧㄝㄧㄣ":"結印/接引",
"ㄐㄧㄝㄧㄤ":"訐揚",
"ㄐㄧㄝㄧㄥ":"接應/結營",
"ㄐㄧㄝㄨㄞ":"界外",
"ㄐㄧㄝㄨㄟ":"結尾/解圍",
"ㄐㄧㄝㄨㄣ":"接吻/節文",
"ㄐㄧㄝㄨㄤ":"解網",
"ㄐㄧㄝㄩㄝ":"節約/解約",
"ㄐㄧㄝㄩㄢ":"結緣/結怨",
"ㄐㄧㄝㄩㄣ":"解運/解慍",
"ㄐㄧㄝㄩㄥ":"節用/借用",
"ㄐㄧㄠㄅㄛ":"嬌波/澆薄",
"ㄐㄧㄠㄅㄞ":"茭白/交拜",
"ㄐㄧㄠㄅㄟ":"交杯/腳背",
"ㄐㄧㄠㄅㄠ":"交保",
"ㄐㄧㄠㄅㄢ":"腳板/攪拌",
"ㄐㄧㄠㄅㄣ":"教本/校本",
"ㄐㄧㄠㄅㄤ":"交棒",
"ㄐㄧㄠㄅㄧ":"交臂",
"ㄐㄧㄠㄅㄨ":"腳步/膠布",
"ㄐㄧㄠㄆㄛ":"腳婆/交迫",
"ㄐㄧㄠㄆㄞ":"教派/交派",
"ㄐㄧㄠㄆㄟ":"交配",
"ㄐㄧㄠㄆㄢ":"交盤/絞盤",
"ㄐㄧㄠㄆㄣ":"腳盆",
"ㄐㄧㄠㄆㄧ":"膠皮",
"ㄐㄧㄠㄇㄚ":"叫罵/蕉麻",
"ㄐㄧㄠㄇㄛ":"角膜",
"ㄐㄧㄠㄇㄞ":"叫賣",
"ㄐㄧㄠㄇㄟ":"嬌美/姣美",
"ㄐㄧㄠㄇㄢ":"驕慢",
"ㄐㄧㄠㄇㄣ":"叫門/教門",
"ㄐㄧㄠㄇㄨ":"膠木",
"ㄐㄧㄠㄈㄚ":"教法/交發",
"ㄐㄧㄠㄈㄟ":"繳費/窖肥",
"ㄐㄧㄠㄈㄣ":"交分/狡憤",
"ㄐㄧㄠㄈㄤ":"教坊/椒房",
"ㄐㄧㄠㄈㄥ":"交鋒",
"ㄐㄧㄠㄈㄨ":"教父/交付",
"ㄐㄧㄠㄉㄞ":"交代/交代",
"ㄐㄧㄠㄉㄠ":"教導/交道",
"ㄐㄧㄠㄉㄡ":"鐎斗",
"ㄐㄧㄠㄉㄢ":"矯誕",
"ㄐㄧㄠㄉㄥ":"腳燈",
"ㄐㄧㄠㄉㄧ":"腳底/角觝",
"ㄐㄧㄠㄉㄨ":"角度/教讀",
"ㄐㄧㄠㄊㄚ":"腳踏",
"ㄐㄧㄠㄊㄞ":"交態/交泰",
"ㄐㄧㄠㄊㄠ":"噭咷",
"ㄐㄧㄠㄊㄡ":"叫頭/教頭",
"ㄐㄧㄠㄊㄢ":"交談/焦炭",
"ㄐㄧㄠㄊㄤ":"教堂/焦糖",
"ㄐㄧㄠㄊㄧ":"交替",
"ㄐㄧㄠㄊㄨ":"教徒/焦土",
"ㄐㄧㄠㄋㄚ":"繳納",
"ㄐㄧㄠㄋㄠ":"角腦/攪惱",
"ㄐㄧㄠㄋㄣ":"嬌嫩",
"ㄐㄧㄠㄋㄤ":"膠囊",
"ㄐㄧㄠㄋㄧ":"膠泥",
"ㄐㄧㄠㄋㄩ":"嬌女",
"ㄐㄧㄠㄌㄞ":"狡賴",
"ㄐㄧㄠㄌㄟ":"焦雷",
"ㄐㄧㄠㄌㄠ":"焦勞",
"ㄐㄧㄠㄌㄡ":"角樓",
"ㄐㄧㄠㄌㄢ":"燋爛/椒蘭",
"ㄐㄧㄠㄌㄥ":"焦棱",
"ㄐㄧㄠㄌㄧ":"校理/腳力",
"ㄐㄧㄠㄌㄨ":"腳路/腳爐",
"ㄐㄧㄠㄌㄩ":"焦慮/叫驢",
"ㄐㄧㄠㄍㄜ":"交割/膠葛",
"ㄐㄧㄠㄍㄞ":"校改",
"ㄐㄧㄠㄍㄟ":"交給",
"ㄐㄧㄠㄍㄠ":"校稿",
"ㄐㄧㄠㄍㄡ":"交媾",
"ㄐㄧㄠㄍㄢ":"交感",
"ㄐㄧㄠㄍㄣ":"腳跟",
"ㄐㄧㄠㄍㄤ":"角鋼",
"ㄐㄧㄠㄍㄨ":"膠固/嚼穀",
"ㄐㄧㄠㄎㄜ":"叫渴/嬌客",
"ㄐㄧㄠㄎㄡ":"交口/角口",
"ㄐㄧㄠㄎㄢ":"校勘",
"ㄐㄧㄠㄎㄤ":"矯亢",
"ㄐㄧㄠㄎㄨ":"叫苦/焦枯",
"ㄐㄧㄠㄏㄜ":"交合",
"ㄐㄧㄠㄏㄟ":"焦黑",
"ㄐㄧㄠㄏㄠ":"叫好/交好",
"ㄐㄧㄠㄏㄡ":"叫吼/交厚",
"ㄐㄧㄠㄏㄢ":"叫喊/嬌寒",
"ㄐㄧㄠㄏㄤ":"腳行",
"ㄐㄧㄠㄏㄥ":"交橫/驕橫",
"ㄐㄧㄠㄏㄨ":"腳戶/焦穫",
"ㄐㄧㄠㄐㄧ":"焦急/交際",
"ㄐㄧㄠㄐㄩ":"叫局/教具",
"ㄐㄧㄠㄑㄧ":"嬌氣/驕氣",
"ㄐㄧㄠㄑㄩ":"郊區/叫屈",
"ㄐㄧㄠㄒㄧ":"教習/教席",
"ㄐㄧㄠㄒㄩ":"膠續/膠序",
"ㄐㄧㄠㄓㄚ":"狡詐",
"ㄐㄧㄠㄓㄜ":"交謫",
"ㄐㄧㄠㄓㄠ":"矯詔",
"ㄐㄧㄠㄓㄢ":"交戰/驕戰",
"ㄐㄧㄠㄓㄣ":"叫陣",
"ㄐㄧㄠㄓㄤ":"交帳",
"ㄐㄧㄠㄓㄥ":"校正/矯正",
"ㄐㄧㄠㄓㄨ":"教主/較著",
"ㄐㄧㄠㄔㄚ":"交叉/腳叉",
"ㄐㄧㄠㄔㄜ":"轎車/叫車",
"ㄐㄧㄠㄔㄞ":"交差",
"ㄐㄧㄠㄔㄡ":"校讎/澆愁",
"ㄐㄧㄠㄔㄢ":"絞纏",
"ㄐㄧㄠㄔㄣ":"嬌嗔",
"ㄐㄧㄠㄔㄤ":"教場/校場",
"ㄐㄧㄠㄔㄥ":"教程/腳程",
"ㄐㄧㄠㄔㄨ":"交出/剿除",
"ㄐㄧㄠㄕㄚ":"絞殺/剿殺",
"ㄐㄧㄠㄕㄜ":"交涉/郊社",
"ㄐㄧㄠㄕㄠ":"腳稍",
"ㄐㄧㄠㄕㄡ":"教授/交手",
"ㄐㄧㄠㄕㄢ":"蕉扇",
"ㄐㄧㄠㄕㄥ":"叫聲",
"ㄐㄧㄠㄕㄨ":"教書/校書",
"ㄐㄧㄠㄖㄠ":"攪擾/嬌嬈",
"ㄐㄧㄠㄖㄡ":"嬌柔/矯揉",
"ㄐㄧㄠㄖㄢ":"較然/皭然",
"ㄐㄧㄠㄖㄣ":"校人/驕人",
"ㄐㄧㄠㄖㄤ":"叫嚷",
"ㄐㄧㄠㄖㄨ":"皦如",
"ㄐㄧㄠㄗㄚ":"交匝",
"ㄐㄧㄠㄗㄜ":"教澤",
"ㄐㄧㄠㄗㄠ":"焦躁/噭噪",
"ㄐㄧㄠㄗㄨ":"交租",
"ㄐㄧㄠㄘㄞ":"教材/叫菜",
"ㄐㄧㄠㄘㄤ":"窖藏",
"ㄐㄧㄠㄙㄚ":"攪撒",
"ㄐㄧㄠㄙㄜ":"腳色/驕色",
"ㄐㄧㄠㄙㄢ":"攪散",
"ㄐㄧㄠㄙㄨ":"矯俗",
"ㄐㄧㄠㄧㄝ":"郊野/交謁",
"ㄐㄧㄠㄧㄠ":"僬僥",
"ㄐㄧㄠㄧㄡ":"交友/郊遊",
"ㄐㄧㄠㄧㄢ":"礁岩/嬌豔",
"ㄐㄧㄠㄧㄣ":"交引/腳印",
"ㄐㄧㄠㄧㄤ":"教養/校樣",
"ㄐㄧㄠㄧㄥ":"噭應/交映",
"ㄐㄧㄠㄧㄨ":"交誼舞",
"ㄐㄧㄠㄨㄚ":"嬌娃",
"ㄐㄧㄠㄨㄞ":"郊外/徼外",
"ㄐㄧㄠㄨㄟ":"交尾/校尉",
"ㄐㄧㄠㄨㄤ":"交往",
"ㄐㄧㄠㄩㄕ":"教育史",
"ㄐㄧㄠㄩㄝ":"焦月/校閱",
"ㄐㄧㄠㄩㄞ":"教育愛",
"ㄐㄧㄠㄩㄢ":"教員/膠原",
"ㄐㄧㄠㄩㄣ":"交運",
"ㄐㄧㄠㄩㄥ":"嚼用/蹻勇",
"ㄐㄧㄡㄅㄚ":"酒吧/救拔",
"ㄐㄧㄡㄅㄞ":"九百",
"ㄐㄧㄡㄅㄟ":"酒杯",
"ㄐㄧㄡㄅㄠ":"酒保",
"ㄐㄧㄡㄅㄢ":"究辦",
"ㄐㄧㄡㄅㄤ":"舊邦",
"ㄐㄧㄡㄆㄟ":"酒旆",
"ㄐㄧㄡㄆㄨ":"酒鋪",
"ㄐㄧㄡㄇㄚ":"舅媽",
"ㄐㄧㄡㄇㄡ":"蝤蛑",
"ㄐㄧㄡㄇㄣ":"九門",
"ㄐㄧㄡㄇㄨ":"就木/酒母",
"ㄐㄧㄡㄈㄚ":"灸法",
"ㄐㄧㄡㄈㄟ":"廄肥",
"ㄐㄧㄡㄈㄢ":"就範/酒飯",
"ㄐㄧㄡㄈㄣ":"糾紛/九分",
"ㄐㄧㄡㄈㄤ":"九方/酒坊",
"ㄐㄧㄡㄈㄥ":"酒瘋",
"ㄐㄧㄡㄈㄨ":"舅父/九服",
"ㄐㄧㄡㄉㄚ":"揪打/救搭",
"ㄐㄧㄡㄉㄜ":"酒德/舊德",
"ㄐㄧㄡㄉㄞ":"就逮/久待",
"ㄐㄧㄡㄉㄟ":"就得",
"ㄐㄧㄡㄉㄠ":"就道/舊道",
"ㄐㄧㄡㄉㄧ":"就地/舊地",
"ㄐㄧㄡㄉㄨ":"就讀/舊都",
"ㄐㄧㄡㄊㄢ":"九歎/糾彈",
"ㄐㄧㄡㄊㄧ":"酒提",
"ㄐㄧㄡㄊㄨ":"九土/酒徒",
"ㄐㄧㄡㄋㄢ":"救難",
"ㄐㄧㄡㄌㄡ":"酒樓",
"ㄐㄧㄡㄌㄢ":"酒闌",
"ㄐㄧㄡㄌㄤ":"酒廊",
"ㄐㄧㄡㄌㄧ":"舊曆/就禮",
"ㄐㄧㄡㄌㄨ":"就戮",
"ㄐㄧㄡㄍㄜ":"九歌/糾葛",
"ㄐㄧㄡㄍㄞ":"九垓",
"ㄐㄧㄡㄍㄠ":"酒誥/九皋",
"ㄐㄧㄡㄍㄨ":"舊故/九穀",
"ㄐㄧㄡㄎㄜ":"酒客/久客",
"ㄐㄧㄡㄏㄜ":"就和/九合",
"ㄐㄧㄡㄏㄠ":"舊好",
"ㄐㄧㄡㄏㄡ":"久候",
"ㄐㄧㄡㄏㄢ":"酒酣",
"ㄐㄧㄡㄏㄣ":"舊恨",
"ㄐㄧㄡㄏㄨ":"救護/酒胡",
"ㄐㄧㄡㄐㄧ":"救急/鳩集",
"ㄐㄧㄡㄐㄩ":"舊居/鳩居",
"ㄐㄧㄡㄑㄧ":"酒器/酒旗",
"ㄐㄧㄡㄑㄩ":"酒曲/救取",
"ㄐㄧㄡㄒㄧ":"酒席/舊習",
"ㄐㄧㄡㄒㄩ":"就緒",
"ㄐㄧㄡㄓㄡ":"九州",
"ㄐㄧㄡㄓㄢ":"酒盞",
"ㄐㄧㄡㄓㄣ":"就診/就枕",
"ㄐㄧㄡㄓㄤ":"九章/舊帳",
"ㄐㄧㄡㄓㄥ":"就正/糾正",
"ㄐㄧㄡㄓㄨ":"救助",
"ㄐㄧㄡㄔㄚ":"究查/糾察",
"ㄐㄧㄡㄔㄜ":"揪扯",
"ㄐㄧㄡㄔㄡ":"九疇",
"ㄐㄧㄡㄔㄢ":"糾纏",
"ㄐㄧㄡㄔㄣ":"舊臣",
"ㄐㄧㄡㄔㄤ":"久長/酒廠",
"ㄐㄧㄡㄔㄥ":"九成/舊稱",
"ㄐㄧㄡㄔㄨ":"揪出",
"ㄐㄧㄡㄕㄚ":"揪痧",
"ㄐㄧㄡㄕㄜ":"酒社/僦舍",
"ㄐㄧㄡㄕㄡ":"就手/舊手",
"ㄐㄧㄡㄕㄢ":"鷲山",
"ㄐㄧㄡㄕㄣ":"酒神",
"ㄐㄧㄡㄕㄤ":"酒觴",
"ㄐㄧㄡㄕㄥ":"救生/九聲",
"ㄐㄧㄡㄕㄨ":"舊書/九數",
"ㄐㄧㄡㄖㄣ":"就任/酒人",
"ㄐㄧㄡㄖㄨ":"九如",
"ㄐㄧㄡㄗㄞ":"救災",
"ㄐㄧㄡㄗㄠ":"酒糟",
"ㄐㄧㄡㄗㄨ":"舊族/九族",
"ㄐㄧㄡㄘㄞ":"韭菜/酒材",
"ㄐㄧㄡㄘㄢ":"就餐",
"ㄐㄧㄡㄙㄜ":"酒色",
"ㄐㄧㄡㄙㄠ":"舅嫂",
"ㄐㄧㄡㄙㄨ":"舊俗",
"ㄐㄧㄡㄧㄙ":"九一四",
"ㄐㄧㄡㄧㄝ":"就業/九野",
"ㄐㄧㄡㄧㄠ":"就要/久要",
"ㄐㄧㄡㄧㄡ":"九有/酒友",
"ㄐㄧㄡㄧㄢ":"酒宴/酒筵",
"ㄐㄧㄡㄧㄣ":"久音/酒癮",
"ㄐㄧㄡㄧㄤ":"就養/久仰",
"ㄐㄧㄡㄧㄥ":"救應/酒硬",
"ㄐㄧㄡㄨㄛ":"酒渦/酒窩",
"ㄐㄧㄡㄨㄟ":"就位/久違",
"ㄐㄧㄡㄨㄣ":"究問/舊聞",
"ㄐㄧㄡㄨㄤ":"救亡",
"ㄐㄧㄡㄩㄝ":"舊約",
"ㄐㄧㄡㄩㄢ":"久遠/救援",
"ㄐㄧㄡㄩㄣ":"酒暈",
"ㄐㄧㄢㄅㄚ":"簡拔/箭靶",
"ㄐㄧㄢㄅㄛ":"建白/檢波",
"ㄐㄧㄢㄅㄞ":"堅白",
"ㄐㄧㄢㄅㄟ":"兼備/見背",
"ㄐㄧㄢㄅㄠ":"見報/剪報",
"ㄐㄧㄢㄅㄢ":"減班/減半",
"ㄐㄧㄢㄅㄣ":"簡本/監本",
"ㄐㄧㄢㄅㄤ":"肩膀",
"ㄐㄧㄢㄅㄧ":"間壁/健筆",
"ㄐㄧㄢㄅㄨ":"箭步/間捕",
"ㄐㄧㄢㄆㄛ":"見破/煎迫",
"ㄐㄧㄢㄆㄞ":"簡派",
"ㄐㄧㄢㄆㄠ":"艦炮",
"ㄐㄧㄢㄆㄢ":"鍵盤",
"ㄐㄧㄢㄆㄨ":"簡樸/簡譜",
"ㄐㄧㄢㄇㄚ":"劍麻",
"ㄐㄧㄢㄇㄛ":"緘默/肩摩",
"ㄐㄧㄢㄇㄞ":"賤賣",
"ㄐㄧㄢㄇㄟ":"健美/劍眉",
"ㄐㄧㄢㄇㄢ":"減慢/簡慢",
"ㄐㄧㄢㄇㄣ":"監門",
"ㄐㄧㄢㄇㄨ":"建木/堅牡",
"ㄐㄧㄢㄈㄚ":"減法/箭法",
"ㄐㄧㄢㄈㄟ":"減肥/姦非",
"ㄐㄧㄢㄈㄢ":"監犯",
"ㄐㄧㄢㄈㄤ":"見方/檢方",
"ㄐㄧㄢㄈㄥ":"見風/尖峰",
"ㄐㄧㄢㄈㄨ":"肩負/姦夫",
"ㄐㄧㄢㄉㄚ":"鹼大",
"ㄐㄧㄢㄉㄜ":"見得/建德",
"ㄐㄧㄢㄉㄞ":"肩帶/箭袋",
"ㄐㄧㄢㄉㄠ":"見到/間道",
"ㄐㄧㄢㄉㄢ":"簡單",
"ㄐㄧㄢㄉㄤ":"建黨/建檔",
"ㄐㄧㄢㄉㄥ":"減等",
"ㄐㄧㄢㄉㄧ":"減低/見地",
"ㄐㄧㄢㄉㄨ":"監督/建都",
"ㄐㄧㄢㄊㄚ":"踐踏",
"ㄐㄧㄢㄊㄜ":"奸慝/姦慝",
"ㄐㄧㄢㄊㄠ":"檢討",
"ㄐㄧㄢㄊㄡ":"箭頭/尖頭",
"ㄐㄧㄢㄊㄢ":"健談/劍潭",
"ㄐㄧㄢㄊㄤ":"煎湯",
"ㄐㄧㄢㄊㄧ":"賤體",
"ㄐㄧㄢㄊㄨ":"鹼土/奸徒",
"ㄐㄧㄢㄋㄟ":"賤內",
"ㄐㄧㄢㄋㄠ":"間腦",
"ㄐㄧㄢㄋㄢ":"艱難/見難",
"ㄐㄧㄢㄋㄥ":"簡能",
"ㄐㄧㄢㄌㄞ":"見來",
"ㄐㄧㄢㄌㄟ":"鹼類/賤累",
"ㄐㄧㄢㄌㄠ":"監牢/堅牢",
"ㄐㄧㄢㄌㄡ":"簡陋/箭樓",
"ㄐㄧㄢㄌㄢ":"劍蘭/建蘭",
"ㄐㄧㄢㄌㄤ":"健浪/健朗",
"ㄐㄧㄢㄌㄧ":"建立/見禮",
"ㄐㄧㄢㄌㄨ":"餞路/檢錄",
"ㄐㄧㄢㄌㄩ":"劍履/踐履",
"ㄐㄧㄢㄍㄜ":"間隔/劍閣",
"ㄐㄧㄢㄍㄠ":"見告",
"ㄐㄧㄢㄍㄡ":"建構/澗溝",
"ㄐㄧㄢㄍㄢ":"鹼酐",
"ㄐㄧㄢㄍㄨ":"堅固/兼顧",
"ㄐㄧㄢㄎㄜ":"見客/尖刻",
"ㄐㄧㄢㄎㄠ":"監考",
"ㄐㄧㄢㄎㄡ":"緘口",
"ㄐㄧㄢㄎㄤ":"健康/建康",
"ㄐㄧㄢㄎㄨ":"艱苦/堅苦",
"ㄐㄧㄢㄏㄜ":"簡核/劍河",
"ㄐㄧㄢㄏㄠ":"見好/減號",
"ㄐㄧㄢㄏㄢ":"箋函",
"ㄐㄧㄢㄏㄨ":"監護/簡忽",
"ㄐㄧㄢㄐㄧ":"奸計/見幾",
"ㄐㄧㄢㄐㄩ":"艱巨/堅拒",
"ㄐㄧㄢㄑㄧ":"見棄/健起",
"ㄐㄧㄢㄑㄩ":"減去/閒覷",
"ㄐㄧㄢㄒㄧ":"見習/奸細",
"ㄐㄧㄢㄓㄚ":"簡札/減煠",
"ㄐㄧㄢㄓㄠ":"建照",
"ㄐㄧㄢㄓㄢ":"尖站/監斬",
"ㄐㄧㄢㄓㄣ":"見陣/堅貞",
"ㄐㄧㄢㄓㄤ":"見長/艦長",
"ㄐㄧㄢㄓㄥ":"見證/見證",
"ㄐㄧㄢㄓㄨ":"建築/箭豬",
"ㄐㄧㄢㄔㄚ":"檢查/監察",
"ㄐㄧㄢㄔㄞ":"兼差/撿柴",
"ㄐㄧㄢㄔㄠ":"煎炒",
"ㄐㄧㄢㄔㄡ":"繭綢",
"ㄐㄧㄢㄔㄢ":"減產/蹇產",
"ㄐㄧㄢㄔㄣ":"簡辰/奸臣",
"ㄐㄧㄢㄔㄤ":"見長/監場",
"ㄐㄧㄢㄔㄥ":"簡稱/見稱",
"ㄐㄧㄢㄔㄨ":"翦除/間出",
"ㄐㄧㄢㄕㄚ":"減殺/姦殺",
"ㄐㄧㄢㄕㄜ":"建設",
"ㄐㄧㄢㄕㄠ":"減少",
"ㄐㄧㄢㄕㄡ":"堅守/剪手",
"ㄐㄧㄢㄕㄢ":"劍山/兼善",
"ㄐㄧㄢㄕㄣ":"艱深/健身",
"ㄐㄧㄢㄕㄤ":"鑑賞/建商",
"ㄐㄧㄢㄕㄥ":"尖聲/減省",
"ㄐㄧㄢㄕㄨ":"建樹/減數",
"ㄐㄧㄢㄖㄢ":"漸染",
"ㄐㄧㄢㄖㄣ":"賤人/見人",
"ㄐㄧㄢㄖㄤ":"見讓",
"ㄐㄧㄢㄖㄨ":"間入/漸洳",
"ㄐㄧㄢㄗㄓ":"撿字紙",
"ㄐㄧㄢㄗㄚ":"間雜",
"ㄐㄧㄢㄗㄜ":"揀擇/見責",
"ㄐㄧㄢㄗㄞ":"健在",
"ㄐㄧㄢㄗㄟ":"奸賊",
"ㄐㄧㄢㄗㄠ":"建造/監造",
"ㄐㄧㄢㄗㄡ":"間奏/牋奏",
"ㄐㄧㄢㄗㄨ":"間阻/減租",
"ㄐㄧㄢㄘㄜ":"檢測/監測",
"ㄐㄧㄢㄘㄞ":"剪綵/建材",
"ㄐㄧㄢㄘㄠ":"諫草",
"ㄐㄧㄢㄘㄨ":"箭鏃/蹇促",
"ㄐㄧㄢㄙㄜ":"艱澀/間色",
"ㄐㄧㄢㄙㄨ":"簡素/減速",
"ㄐㄧㄢㄧㄚ":"減壓/監押",
"ㄐㄧㄢㄧㄝ":"建業/賤業",
"ㄐㄧㄢㄧㄠ":"簡要/建窯",
"ㄐㄧㄢㄧㄡ":"間有",
"ㄐㄧㄢㄧㄢ":"檢驗/建言",
"ㄐㄧㄢㄧㄣ":"尖音/監印",
"ㄐㄧㄢㄧㄤ":"健仰/賤恙",
"ㄐㄧㄢㄧㄥ":"剪影/堅硬",
"ㄐㄧㄢㄨㄛ":"堅臥/肩窩",
"ㄐㄧㄢㄨㄞ":"見外/劍外",
"ㄐㄧㄢㄨㄟ":"見危/兼味",
"ㄐㄧㄢㄨㄢ":"奸頑",
"ㄐㄧㄢㄨㄣ":"見聞",
"ㄐㄧㄢㄨㄤ":"見旺/健忘",
"ㄐㄧㄢㄩㄝ":"簡約/僭越",
"ㄐㄧㄢㄩㄢ":"見原/簡緣",
"ㄐㄧㄢㄩㄣ":"監運/蹇運",
"ㄐㄧㄢㄩㄥ":"兼用",
"ㄐㄧㄣㄅㄛ":"金波/金帛",
"ㄐㄧㄣㄅㄠ":"襟抱",
"ㄐㄧㄣㄅㄤ":"金榜/近傍",
"ㄐㄧㄣㄅㄥ":"緊繃",
"ㄐㄧㄣㄅㄧ":"金幣/進逼",
"ㄐㄧㄣㄅㄨ":"進步/進補",
"ㄐㄧㄣㄆㄛ":"緊迫",
"ㄐㄧㄣㄆㄞ":"金牌/禁牌",
"ㄐㄧㄣㄆㄠ":"浸泡/錦袍",
"ㄐㄧㄣㄆㄤ":"近旁",
"ㄐㄧㄣㄆㄨ":"金鋪",
"ㄐㄧㄣㄇㄚ":"金馬",
"ㄐㄧㄣㄇㄞ":"筋脈",
"ㄐㄧㄣㄇㄢ":"矜滿",
"ㄐㄧㄣㄇㄣ":"金門/進門",
"ㄐㄧㄣㄇㄧ":"緊密/謹密",
"ㄐㄧㄣㄇㄨ":"妗母/金母",
"ㄐㄧㄣㄈㄚ":"進發/津發",
"ㄐㄧㄣㄈㄢ":"今番/進犯",
"ㄐㄧㄣㄈㄣ":"金粉",
"ㄐㄧㄣㄈㄤ":"禁方/緊防",
"ㄐㄧㄣㄈㄥ":"金風/進奉",
"ㄐㄧㄣㄈㄨ":"巾服/斤斧",
"ㄐㄧㄣㄉㄜ":"禁得/進德",
"ㄐㄧㄣㄉㄞ":"近代/金帶",
"ㄐㄧㄣㄉㄠ":"金刀",
"ㄐㄧㄣㄉㄡ":"筋斗/金斗",
"ㄐㄧㄣㄉㄢ":"金丹",
"ㄐㄧㄣㄉㄤ":"禁當/金璫",
"ㄐㄧㄣㄉㄥ":"緊等",
"ㄐㄧㄣㄉㄧ":"禁地/襟弟",
"ㄐㄧㄣㄉㄨ":"進度/金都",
"ㄐㄧㄣㄊㄞ":"金臺",
"ㄐㄧㄣㄊㄡ":"盡頭/勁頭",
"ㄐㄧㄣㄊㄤ":"金湯",
"ㄐㄧㄣㄊㄥ":"金縢",
"ㄐㄧㄣㄊㄧ":"禁體",
"ㄐㄧㄣㄊㄨ":"禁土/禁屠",
"ㄐㄧㄣㄋㄤ":"錦囊",
"ㄐㄧㄣㄋㄧ":"金泥/金猊",
"ㄐㄧㄣㄋㄨ":"金奴",
"ㄐㄧㄣㄌㄜ":"金勒",
"ㄐㄧㄣㄌㄞ":"近來/進來",
"ㄐㄧㄣㄌㄟ":"金罍",
"ㄐㄧㄣㄌㄡ":"金鏤",
"ㄐㄧㄣㄌㄢ":"金蘭",
"ㄐㄧㄣㄌㄧ":"盡力/近理",
"ㄐㄧㄣㄌㄨ":"金鑪",
"ㄐㄧㄣㄍㄜ":"金革/錦歌",
"ㄐㄧㄣㄍㄠ":"金糕/浸膏",
"ㄐㄧㄣㄍㄡ":"僅夠/儘夠",
"ㄐㄧㄣㄍㄢ":"金柑/筋竿",
"ㄐㄧㄣㄍㄣ":"緊跟",
"ㄐㄧㄣㄍㄤ":"金剛/金釭",
"ㄐㄧㄣㄍㄨ":"筋骨/禁錮",
"ㄐㄧㄣㄎㄜ":"盡可/僅可",
"ㄐㄧㄣㄎㄡ":"進口/金口",
"ㄐㄧㄣㄎㄢ":"禁勘",
"ㄐㄧㄣㄎㄨ":"金庫",
"ㄐㄧㄣㄏㄜ":"金和",
"ㄐㄧㄣㄏㄞ":"近海/禁害",
"ㄐㄧㄣㄏㄡ":"今後/謹厚",
"ㄐㄧㄣㄏㄥ":"金衡",
"ㄐㄧㄣㄏㄨ":"近乎/金壺",
"ㄐㄧㄣㄐㄧ":"緊急/緊記",
"ㄐㄧㄣㄐㄩ":"巾車/進舉",
"ㄐㄧㄣㄑㄧ":"近期/進氣",
"ㄐㄧㄣㄑㄩ":"禁區/進去",
"ㄐㄧㄣㄒㄧ":"今夕/今昔",
"ㄐㄧㄣㄒㄩ":"矜恤",
"ㄐㄧㄣㄓㄜ":"緊著/儘著",
"ㄐㄧㄣㄓㄠ":"今朝/祲兆",
"ㄐㄧㄣㄓㄡ":"禁咒",
"ㄐㄧㄣㄓㄢ":"進展/進占",
"ㄐㄧㄣㄓㄣ":"金針",
"ㄐㄧㄣㄓㄤ":"緊張/金張",
"ㄐㄧㄣㄓㄥ":"金鉦",
"ㄐㄧㄣㄓㄨ":"進駐/噤住",
"ㄐㄧㄣㄔㄞ":"金釵",
"ㄐㄧㄣㄔㄠ":"金朝/晉朝",
"ㄐㄧㄣㄔㄢ":"金蟾/進讒",
"ㄐㄧㄣㄔㄣ":"緊襯/近臣",
"ㄐㄧㄣㄔㄤ":"進場/進廠",
"ㄐㄧㄣㄔㄥ":"進程/金城",
"ㄐㄧㄣㄔㄨ":"進出/近處",
"ㄐㄧㄣㄕㄕ":"近世史",
"ㄐㄧㄣㄕㄜ":"金蛇/錦蛇",
"ㄐㄧㄣㄕㄡ":"緊手/緊守",
"ㄐㄧㄣㄕㄢ":"金山/進膳",
"ㄐㄧㄣㄕㄣ":"謹慎/金身",
"ㄐㄧㄣㄕㄤ":"今上/近上",
"ㄐㄧㄣㄕㄥ":"今生/晉升",
"ㄐㄧㄣㄕㄨ":"金屬/禁書",
"ㄐㄧㄣㄕㄩ":"金石語",
"ㄐㄧㄣㄖㄡ":"筋肉",
"ㄐㄧㄣㄖㄢ":"浸染",
"ㄐㄧㄣㄖㄣ":"今人/近人",
"ㄐㄧㄣㄖㄤ":"儘讓",
"ㄐㄧㄣㄖㄨ":"進入/浸入",
"ㄐㄧㄣㄗㄜ":"盡責/巾幘",
"ㄐㄧㄣㄗㄠ":"儘早/金灶",
"ㄐㄧㄣㄗㄡ":"金奏/進奏",
"ㄐㄧㄣㄗㄨ":"禁足/禁阻",
"ㄐㄧㄣㄘㄞ":"菫菜",
"ㄐㄧㄣㄘㄠ":"今草/藎草",
"ㄐㄧㄣㄘㄡ":"緊湊",
"ㄐㄧㄣㄘㄢ":"進餐",
"ㄐㄧㄣㄘㄨ":"緊促/緊簇",
"ㄐㄧㄣㄙㄓ":"近似值",
"ㄐㄧㄣㄙㄜ":"金色/錦瑟",
"ㄐㄧㄣㄙㄨ":"金素/金粟",
"ㄐㄧㄣㄧㄚ":"金鴨/禁壓",
"ㄐㄧㄣㄧㄝ":"今夜/金葉",
"ㄐㄧㄣㄧㄠ":"緊要/襟要",
"ㄐㄧㄣㄧㄡ":"僅有/金油",
"ㄐㄧㄣㄧㄢ":"禁煙/金言",
"ㄐㄧㄣㄧㄣ":"浸淫/近因",
"ㄐㄧㄣㄧㄤ":"錦樣",
"ㄐㄧㄣㄧㄨ":"盡義務",
"ㄐㄧㄣㄨㄟ":"進位/浸微",
"ㄐㄧㄣㄨㄢ":"今晚/金丸",
"ㄐㄧㄣㄨㄣ":"金文/今文",
"ㄐㄧㄣㄨㄤ":"禁網",
"ㄐㄧㄣㄩㄢ":"禁苑",
"ㄐㄧㄣㄩㄣ":"今韻/襟韻",
"ㄐㄧㄣㄩㄥ":"進用/晉用",
"ㄐㄧㄤㄅㄛ":"匠伯",
"ㄐㄧㄤㄅㄟ":"姜被/獎杯",
"ㄐㄧㄤㄅㄤ":"將傍",
"ㄐㄧㄤㄆㄞ":"獎牌",
"ㄐㄧㄤㄆㄟ":"韁轡",
"ㄐㄧㄤㄆㄢ":"江畔",
"ㄐㄧㄤㄆㄨ":"僵仆",
"ㄐㄧㄤㄇㄛ":"漿膜",
"ㄐㄧㄤㄇㄟ":"江梅",
"ㄐㄧㄤㄇㄣ":"將門/江門",
"ㄐㄧㄤㄇㄧ":"江米/降冪",
"ㄐㄧㄤㄈㄚ":"講法",
"ㄐㄧㄤㄈㄟ":"江妃",
"ㄐㄧㄤㄈㄢ":"降凡",
"ㄐㄧㄤㄈㄤ":"蔣防/醬坊",
"ㄐㄧㄤㄈㄨ":"降服/降福",
"ㄐㄧㄤㄉㄞ":"將帶",
"ㄐㄧㄤㄉㄠ":"講道/講倒",
"ㄐㄧㄤㄉㄡ":"豇豆",
"ㄐㄧㄤㄉㄧ":"降低",
"ㄐㄧㄤㄉㄨ":"講讀",
"ㄐㄧㄤㄊㄞ":"講臺",
"ㄐㄧㄤㄊㄢ":"講壇",
"ㄐㄧㄤㄊㄤ":"講堂/薑湯",
"ㄐㄧㄤㄊㄧ":"講題/獎題",
"ㄐㄧㄤㄊㄨ":"疆土/茳芏",
"ㄐㄧㄤㄋㄢ":"江南",
"ㄐㄧㄤㄌㄞ":"將來",
"ㄐㄧㄤㄌㄡ":"江樓",
"ㄐㄧㄤㄌㄥ":"僵冷",
"ㄐㄧㄤㄌㄧ":"講理/獎勵",
"ㄐㄧㄤㄍㄜ":"降格",
"ㄐㄧㄤㄍㄠ":"講稿/江皋",
"ㄐㄧㄤㄍㄢ":"江干",
"ㄐㄧㄤㄍㄤ":"醬缸",
"ㄐㄧㄤㄍㄨ":"講古/僵固",
"ㄐㄧㄤㄎㄜ":"講課",
"ㄐㄧㄤㄎㄡ":"講口/江口",
"ㄐㄧㄤㄏㄜ":"講和/絳河",
"ㄐㄧㄤㄏㄢ":"江漢",
"ㄐㄧㄤㄏㄨ":"江湖/江湖",
"ㄐㄧㄤㄐㄧ":"降級/蔣濟",
"ㄐㄧㄤㄐㄩ":"僵局",
"ㄐㄧㄤㄑㄧ":"匠氣/絳氣",
"ㄐㄧㄤㄒㄧ":"講習/講席",
"ㄐㄧㄤㄓㄜ":"江浙",
"ㄐㄧㄤㄓㄠ":"降詔",
"ㄐㄧㄤㄓㄤ":"獎章/絳帳",
"ㄐㄧㄤㄓㄨ":"僵住/獎助",
"ㄐㄧㄤㄔㄣ":"疆臣",
"ㄐㄧㄤㄔㄤ":"疆場",
"ㄐㄧㄤㄔㄥ":"匠成/獎懲",
"ㄐㄧㄤㄔㄨ":"將出/將雛",
"ㄐㄧㄤㄕㄜ":"將攝",
"ㄐㄧㄤㄕㄡ":"講授/獎售",
"ㄐㄧㄤㄕㄢ":"江山",
"ㄐㄧㄤㄕㄣ":"將身/降神",
"ㄐㄧㄤㄕㄤ":"獎賞/姜尚",
"ㄐㄧㄤㄕㄥ":"降生/江聲",
"ㄐㄧㄤㄕㄨ":"講述/講書",
"ㄐㄧㄤㄖㄣ":"匠人",
"ㄐㄧㄤㄗㄞ":"降災",
"ㄐㄧㄤㄗㄢ":"獎贊",
"ㄐㄧㄤㄘㄞ":"將才/將才",
"ㄐㄧㄤㄘㄢ":"江參/殭蠶",
"ㄐㄧㄤㄙㄜ":"醬色",
"ㄐㄧㄤㄧㄚ":"矼牙",
"ㄐㄧㄤㄧㄝ":"獎掖/漿液",
"ㄐㄧㄤㄧㄠ":"將要/江瑤",
"ㄐㄧㄤㄧㄡ":"江右/醬油",
"ㄐㄧㄤㄧㄢ":"講演/強顏",
"ㄐㄧㄤㄧㄣ":"將印",
"ㄐㄧㄤㄧㄤ":"將養",
"ㄐㄧㄤㄧㄥ":"僵硬",
"ㄐㄧㄤㄨㄟ":"將為/將謂",
"ㄐㄧㄤㄨㄢ":"將晚/蔣琬",
"ㄐㄧㄤㄨㄣ":"降溫",
"ㄐㄧㄤㄩㄢ":"醬園/姜嫄",
"ㄐㄧㄤㄩㄥ":"江永",
"ㄐㄧㄥㄅㄚ":"勁拔",
"ㄐㄧㄥㄅㄛ":"鯨波",
"ㄐㄧㄥㄅㄞ":"精白/京白",
"ㄐㄧㄥㄅㄟ":"警備/鏡貝",
"ㄐㄧㄥㄅㄠ":"警報/京報",
"ㄐㄧㄥㄅㄢ":"經辦/靜辦",
"ㄐㄧㄥㄅㄣ":"淨本",
"ㄐㄧㄥㄅㄤ":"經邦",
"ㄐㄧㄥㄅㄧ":"經閉/警蹕",
"ㄐㄧㄥㄅㄨ":"經部/頸部",
"ㄐㄧㄥㄆㄚ":"驚怕",
"ㄐㄧㄥㄆㄞ":"京派",
"ㄐㄧㄥㄆㄟ":"敬佩",
"ㄐㄧㄥㄆㄢ":"晶盤",
"ㄐㄧㄥㄆㄧ":"精闢/靜僻",
"ㄐㄧㄥㄆㄨ":"荊璞",
"ㄐㄧㄥㄇㄛ":"靜默",
"ㄐㄧㄥㄇㄞ":"靜脈/經脈",
"ㄐㄧㄥㄇㄟ":"精美/靜美",
"ㄐㄧㄥㄇㄠ":"經貿/菁茅",
"ㄐㄧㄥㄇㄢ":"荊蠻",
"ㄐㄧㄥㄇㄣ":"荊門/旌門",
"ㄐㄧㄥㄇㄤ":"精芒",
"ㄐㄧㄥㄇㄧ":"精密/靜謐",
"ㄐㄧㄥㄇㄨ":"靜穆/景慕",
"ㄐㄧㄥㄈㄚ":"經法/淨髮",
"ㄐㄧㄥㄈㄟ":"經費/荊扉",
"ㄐㄧㄥㄈㄢ":"旌繁",
"ㄐㄧㄥㄈㄤ":"經方/京房",
"ㄐㄧㄥㄈㄥ":"驚風/景風",
"ㄐㄧㄥㄈㄨ":"驚服/精夫",
"ㄐㄧㄥㄉㄜ":"旌德",
"ㄐㄧㄥㄉㄞ":"晶帶/靜待",
"ㄐㄧㄥㄉㄠ":"精到/逕到",
"ㄐㄧㄥㄉㄤ":"精當",
"ㄐㄧㄥㄉㄧ":"境地/井地",
"ㄐㄧㄥㄉㄨ":"京都/精讀",
"ㄐㄧㄥㄊㄞ":"靜態/景泰",
"ㄐㄧㄥㄊㄡ":"鏡頭/淨頭",
"ㄐㄧㄥㄊㄢ":"驚嘆/驚歎",
"ㄐㄧㄥㄊㄤ":"驚堂/京堂",
"ㄐㄧㄥㄊㄧ":"警惕/晶體",
"ㄐㄧㄥㄊㄨ":"淨土",
"ㄐㄧㄥㄋㄟ":"境內",
"ㄐㄧㄥㄋㄢ":"靖難/荊南",
"ㄐㄧㄥㄋㄤ":"精囊",
"ㄐㄧㄥㄋㄧ":"鯨鯢",
"ㄐㄧㄥㄋㄨ":"勁弩",
"ㄐㄧㄥㄋㄩ":"靚女/靜女",
"ㄐㄧㄥㄌㄚ":"鯨蠟",
"ㄐㄧㄥㄌㄢ":"井闌",
"ㄐㄧㄥㄌㄧ":"經歷/經理",
"ㄐㄧㄥㄌㄨ":"徑路/精廬",
"ㄐㄧㄥㄌㄩ":"勁旅",
"ㄐㄧㄥㄍㄜ":"警鴿",
"ㄐㄧㄥㄍㄠ":"警告",
"ㄐㄧㄥㄍㄢ":"竟敢/精幹",
"ㄐㄧㄥㄍㄤ":"靜岡",
"ㄐㄧㄥㄍㄥ":"精耕",
"ㄐㄧㄥㄍㄨ":"警鼓/脛骨",
"ㄐㄧㄥㄎㄜ":"荊軻",
"ㄐㄧㄥㄎㄠ":"鏡考",
"ㄐㄧㄥㄎㄡ":"京口",
"ㄐㄧㄥㄎㄣ":"精懇",
"ㄐㄧㄥㄎㄤ":"靖康",
"ㄐㄧㄥㄏㄜ":"精核/精覈",
"ㄐㄧㄥㄏㄞ":"驚駭",
"ㄐㄧㄥㄏㄠ":"警號/荊浩",
"ㄐㄧㄥㄏㄡ":"警候/靜候",
"ㄐㄧㄥㄏㄢ":"驚汗/精悍",
"ㄐㄧㄥㄏㄨ":"驚呼/鏡湖",
"ㄐㄧㄥㄐㄧ":"經濟/經紀",
"ㄐㄧㄥㄐㄩ":"警局/警句",
"ㄐㄧㄥㄑㄧ":"驚奇/經期",
"ㄐㄧㄥㄑㄩ":"徑取/逕取",
"ㄐㄧㄥㄒㄧ":"驚喜/精細",
"ㄐㄧㄥㄒㄩ":"鯨鬚/旌卹",
"ㄐㄧㄥㄓㄚ":"驚乍",
"ㄐㄧㄥㄓㄜ":"經摺/驚蟄",
"ㄐㄧㄥㄓㄞ":"淨宅/京債",
"ㄐㄧㄥㄓㄠ":"警兆/京兆",
"ㄐㄧㄥㄓㄡ":"經咒/荊州",
"ㄐㄧㄥㄓㄢ":"精湛",
"ㄐㄧㄥㄓㄣ":"警枕",
"ㄐㄧㄥㄓㄤ":"經帳/驚張",
"ㄐㄧㄥㄓㄥ":"競爭/警政",
"ㄐㄧㄥㄓㄨ":"競逐/敬祝",
"ㄐㄧㄥㄔㄚ":"警察/驚詫",
"ㄐㄧㄥㄔㄜ":"警車",
"ㄐㄧㄥㄔㄞ":"荊柴",
"ㄐㄧㄥㄔㄠ":"精巢",
"ㄐㄧㄥㄔㄢ":"經懺",
"ㄐㄧㄥㄔㄣ":"經讖/驚塵",
"ㄐㄧㄥㄔㄤ":"經常",
"ㄐㄧㄥㄔㄥ":"經程/經承",
"ㄐㄧㄥㄔㄨ":"荊楚",
"ㄐㄧㄥㄕㄙ":"經史笥",
"ㄐㄧㄥㄕㄚ":"經紗",
"ㄐㄧㄥㄕㄜ":"經涉/精舍",
"ㄐㄧㄥㄕㄡ":"經手/經售",
"ㄐㄧㄥㄕㄢ":"景山/荊山",
"ㄐㄧㄥㄕㄣ":"精神/淨身",
"ㄐㄧㄥㄕㄤ":"經商/敬上",
"ㄐㄧㄥㄕㄥ":"經生/精省",
"ㄐㄧㄥㄕㄨ":"經書/經術",
"ㄐㄧㄥㄕㄩ":"經石峪",
"ㄐㄧㄥㄖㄠ":"驚擾",
"ㄐㄧㄥㄖㄡ":"精肉",
"ㄐㄧㄥㄖㄢ":"竟然/井然",
"ㄐㄧㄥㄖㄣ":"驚人",
"ㄐㄧㄥㄖㄨ":"徑入/逕入",
"ㄐㄧㄥㄗㄡ":"驚走/競走",
"ㄐㄧㄥㄗㄤ":"經藏",
"ㄐㄧㄥㄗㄨ":"京族",
"ㄐㄧㄥㄘㄜ":"警策",
"ㄐㄧㄥㄘㄞ":"精彩/精采",
"ㄐㄧㄥㄘㄠ":"勁草",
"ㄐㄧㄥㄘㄤ":"京倉",
"ㄐㄧㄥㄘㄨ":"精粗",
"ㄐㄧㄥㄙㄜ":"景色",
"ㄐㄧㄥㄙㄞ":"競賽/徑賽",
"ㄐㄧㄥㄙㄨ":"淨素",
"ㄐㄧㄥㄧㄚ":"驚訝",
"ㄐㄧㄥㄧㄝ":"精液/敬業",
"ㄐㄧㄥㄧㄡ":"經由/精油",
"ㄐㄧㄥㄧㄢ":"經驗/經筵",
"ㄐㄧㄥㄧㄣ":"鯨音/京尹",
"ㄐㄧㄥㄧㄤ":"景仰/靜養",
"ㄐㄧㄥㄧㄥ":"經營/精英",
"ㄐㄧㄥㄨㄚ":"井蛙",
"ㄐㄧㄥㄨㄟ":"警衛/經緯",
"ㄐㄧㄥㄨㄢ":"驚惋",
"ㄐㄧㄥㄨㄣ":"經文",
"ㄐㄧㄥㄨㄤ":"警網",
"ㄐㄧㄥㄩㄢ":"警員/經元",
"ㄐㄧㄥㄩㄣ":"景運/景雲",
"ㄐㄧㄦㄊㄜ":"基爾特",
"ㄐㄧㄦㄍㄜ":"幾兒個",
"ㄐㄧㄧㄊㄧ":"記憶體",
"ㄐㄧㄧㄌㄧ":"記憶力",
"ㄐㄧㄩㄓㄓ":"覬覦之志",
"ㄐㄧㄩㄩㄣ":"積雨雲",
"ㄐㄩㄅㄧㄢ":"巨變/劇變",
"ㄐㄩㄅㄧㄥ":"舉兵/橘餅",
"ㄐㄩㄅㄨㄩ":"局部雨",
"ㄐㄩㄆㄧㄠ":"局票/拘票",
"ㄐㄩㄆㄧㄢ":"局騙",
"ㄐㄩㄆㄧㄣ":"居貧",
"ㄐㄩㄇㄧㄝ":"俱滅",
"ㄐㄩㄇㄧㄢ":"局面/俱免",
"ㄐㄩㄇㄧㄣ":"居民",
"ㄐㄩㄇㄧㄥ":"具名",
"ㄐㄩㄉㄧㄝ":"苴絰",
"ㄐㄩㄉㄧㄢ":"據點/句點",
"ㄐㄩㄉㄨㄛ":"居多",
"ㄐㄩㄉㄨㄢ":"句斷/局段",
"ㄐㄩㄉㄨㄥ":"舉動",
"ㄐㄩㄊㄧㄥ":"居停",
"ㄐㄩㄊㄨㄟ":"遽退",
"ㄐㄩㄊㄨㄢ":"劇團",
"ㄐㄩㄊㄨㄥ":"劇痛",
"ㄐㄩㄋㄨㄥ":"掬弄",
"ㄐㄩㄌㄧㄝ":"劇烈/車裂",
"ㄐㄩㄌㄧㄠ":"詎料",
"ㄐㄩㄌㄧㄡ":"拘留/居留",
"ㄐㄩㄌㄧㄢ":"聚斂",
"ㄐㄩㄌㄧㄤ":"局量",
"ㄐㄩㄌㄧㄥ":"具領",
"ㄐㄩㄌㄨㄛ":"聚落/橘絡",
"ㄐㄩㄌㄨㄣ":"劇論",
"ㄐㄩㄌㄨㄥ":"聚攏",
"ㄐㄩㄌㄩㄢ":"拘攣",
"ㄐㄩㄍㄨㄛ":"舉國",
"ㄐㄩㄍㄨㄢ":"居官/具官",
"ㄐㄩㄍㄨㄤ":"聚光/駒光",
"ㄐㄩㄍㄨㄥ":"鞠躬/居功",
"ㄐㄩㄎㄨㄞ":"狙獪",
"ㄐㄩㄎㄨㄢ":"巨款/鉅款",
"ㄐㄩㄏㄜㄨ":"聚合物",
"ㄐㄩㄏㄨㄚ":"菊花/巨猾",
"ㄐㄩㄏㄨㄛ":"舉火/炬火",
"ㄐㄩㄏㄨㄞ":"沮壞",
"ㄐㄩㄏㄨㄟ":"聚會/聚會",
"ㄐㄩㄏㄨㄤ":"橘黃",
"ㄐㄩㄏㄨㄥ":"橘紅",
"ㄐㄩㄐㄧㄚ":"舉家/局家",
"ㄐㄩㄐㄧㄝ":"具結/拘介",
"ㄐㄩㄐㄧㄠ":"聚焦/矩教",
"ㄐㄩㄐㄧㄡ":"舉酒/橘酒",
"ㄐㄩㄐㄧㄢ":"舉薦/居間",
"ㄐㄩㄐㄧㄣ":"拘謹/拘緊",
"ㄐㄩㄐㄧㄤ":"巨匠/枸醬",
"ㄐㄩㄐㄧㄥ":"橘井",
"ㄐㄩㄐㄩㄝ":"拒絕/咀嚼",
"ㄐㄩㄐㄩㄢ":"拘倦",
"ㄐㄩㄑㄧㄡ":"菊秋",
"ㄐㄩㄑㄧㄢ":"拘牽/拘鉗",
"ㄐㄩㄑㄧㄣ":"鋸琴",
"ㄐㄩㄑㄧㄤ":"舉槍",
"ㄐㄩㄑㄧㄥ":"劇情/具慶",
"ㄐㄩㄑㄩㄝ":"拒卻/巨闕",
"ㄐㄩㄑㄩㄢ":"俱全/莒拳",
"ㄐㄩㄒㄧㄝ":"鋸屑",
"ㄐㄩㄒㄧㄠ":"劇校",
"ㄐㄩㄒㄧㄢ":"局限/拘限",
"ㄐㄩㄒㄧㄣ":"居心",
"ㄐㄩㄒㄧㄤ":"巨響/具象",
"ㄐㄩㄒㄧㄥ":"舉行/巨星",
"ㄐㄩㄒㄩㄣ":"鞫訊/巨勛",
"ㄐㄩㄓㄨㄤ":"具狀",
"ㄐㄩㄓㄨㄥ":"舉重/居中",
"ㄐㄩㄔㄘㄠ":"鋸齒草",
"ㄐㄩㄔㄨㄢ":"巨川",
"ㄐㄩㄔㄨㄤ":"鋸床",
"ㄐㄩㄕㄜㄕ":"俱舍師",
"ㄐㄩㄕㄨㄚ":"拘刷",
"ㄐㄩㄕㄨㄛ":"據說/巨碩",
"ㄐㄩㄕㄨㄟ":"掬水/沮水",
"ㄐㄩㄕㄨㄤ":"居孀",
"ㄐㄩㄖㄣㄗ":"窶人子",
"ㄐㄩㄖㄨㄛ":"蒟蒻",
"ㄐㄩㄗㄨㄛ":"劇作/巨作",
"ㄐㄩㄗㄨㄟ":"懼罪",
"ㄐㄩㄘㄨㄛ":"舉措",
"ㄐㄩㄙㄨㄛ":"居所/沮索",
"ㄐㄩㄙㄨㄥ":"聚訟/橘頌",
"ㄐㄩㄝㄅㄚ":"倔巴",
"ㄐㄩㄝㄅㄢ":"絕版",
"ㄐㄩㄝㄅㄧ":"絕筆/絕壁",
"ㄐㄩㄝㄅㄨ":"絕不/躩步",
"ㄐㄩㄝㄆㄟ":"絕配/絕轡",
"ㄐㄩㄝㄆㄧ":"譎譬",
"ㄐㄩㄝㄇㄚ":"角馬",
"ㄐㄩㄝㄇㄧ":"絕密",
"ㄐㄩㄝㄇㄨ":"絕目/抉目",
"ㄐㄩㄝㄈㄚ":"覺發/絕乏",
"ㄐㄩㄝㄈㄟ":"絕非",
"ㄐㄩㄝㄈㄤ":"決防",
"ㄐㄩㄝㄈㄨ":"爵服",
"ㄐㄩㄝㄉㄜ":"覺得",
"ㄐㄩㄝㄉㄞ":"絕代",
"ㄐㄩㄝㄉㄠ":"覺道/絕倒",
"ㄐㄩㄝㄉㄡ":"決鬥",
"ㄐㄩㄝㄉㄧ":"絕地/趹踶",
"ㄐㄩㄝㄊㄚ":"覺他",
"ㄐㄩㄝㄊㄡ":"钁頭",
"ㄐㄩㄝㄊㄧ":"決堤/抉剔",
"ㄐㄩㄝㄋㄠ":"蠼猱",
"ㄐㄩㄝㄋㄢ":"絕難",
"ㄐㄩㄝㄌㄚ":"嚼蠟",
"ㄐㄩㄝㄌㄟ":"蕨類",
"ㄐㄩㄝㄌㄧ":"絕力/角立",
"ㄐㄩㄝㄌㄨ":"絕路/爵祿",
"ㄐㄩㄝㄎㄡ":"決口/絕口",
"ㄐㄩㄝㄏㄞ":"覺海",
"ㄐㄩㄝㄏㄡ":"絕後/厥後",
"ㄐㄩㄝㄏㄨ":"絕戶",
"ㄐㄩㄝㄐㄧ":"絕技/絕跡",
"ㄐㄩㄝㄐㄩ":"絕句",
"ㄐㄩㄝㄑㄧ":"崛起/倔起",
"ㄐㄩㄝㄑㄩ":"掘取/攫取",
"ㄐㄩㄝㄒㄧ":"絕席",
"ㄐㄩㄝㄓㄚ":"譎詐",
"ㄐㄩㄝㄓㄠ":"絕招",
"ㄐㄩㄝㄓㄢ":"決戰/嚼旃",
"ㄐㄩㄝㄓㄣ":"角枕",
"ㄐㄩㄝㄓㄤ":"蹶張",
"ㄐㄩㄝㄓㄥ":"絕症",
"ㄐㄩㄝㄓㄨ":"攫住/爵主",
"ㄐㄩㄝㄔㄚ":"覺察",
"ㄐㄩㄝㄔㄡ":"爵仇",
"ㄐㄩㄝㄔㄣ":"絕塵",
"ㄐㄩㄝㄔㄤ":"絕唱",
"ㄐㄩㄝㄔㄨ":"厥初",
"ㄐㄩㄝㄕㄜ":"鴃舌",
"ㄐㄩㄝㄕㄠ":"角招",
"ㄐㄩㄝㄕㄤ":"爵賞",
"ㄐㄩㄝㄕㄥ":"決勝",
"ㄐㄩㄝㄕㄨ":"覺書",
"ㄐㄩㄝㄖㄜ":"絕熱",
"ㄐㄩㄝㄖㄢ":"決然/絕然",
"ㄐㄩㄝㄖㄣ":"絕人",
"ㄐㄩㄝㄖㄨ":"觖如",
"ㄐㄩㄝㄗㄜ":"抉擇/決擇",
"ㄐㄩㄝㄗㄠ":"絕早",
"ㄐㄩㄝㄗㄡ":"決驟",
"ㄐㄩㄝㄘㄜ":"決策",
"ㄐㄩㄝㄘㄞ":"絕才",
"ㄐㄩㄝㄙㄚ":"決撒/撅撒",
"ㄐㄩㄝㄙㄜ":"絕色",
"ㄐㄩㄝㄙㄞ":"決賽/絕塞",
"ㄐㄩㄝㄙㄨ":"絕俗",
"ㄐㄩㄝㄧㄝ":"絕業",
"ㄐㄩㄝㄧㄢ":"絕巖",
"ㄐㄩㄝㄧㄣ":"覺音/絕垠",
"ㄐㄩㄝㄧㄥ":"絕纓/角鷹",
"ㄐㄩㄝㄨㄟ":"爵位/蹶痿",
"ㄐㄩㄝㄨㄣ":"絕問",
"ㄐㄩㄝㄨㄤ":"絕望/覺王",
"ㄐㄩㄝㄩㄝ":"掘閱/躩躍",
"ㄐㄩㄝㄩㄢ":"絕緣",
"ㄐㄩㄢㄅㄛ":"卷柏/絹帛",
"ㄐㄩㄢㄅㄟ":"捐背",
"ㄐㄩㄢㄅㄠ":"捲包/眷保",
"ㄐㄩㄢㄅㄢ":"捐班",
"ㄐㄩㄢㄅㄣ":"絹本",
"ㄐㄩㄢㄆㄚ":"絹帕",
"ㄐㄩㄢㄆㄥ":"卷棚/捲棚",
"ㄐㄩㄢㄇㄚ":"圈馬",
"ㄐㄩㄢㄇㄟ":"娟美",
"ㄐㄩㄢㄇㄨ":"捐募",
"ㄐㄩㄢㄈㄚ":"捲髮/倦乏",
"ㄐㄩㄢㄈㄟ":"倦飛/捐廢",
"ㄐㄩㄢㄈㄣ":"悁忿/狷忿",
"ㄐㄩㄢㄈㄨ":"蠲賦",
"ㄐㄩㄢㄉㄞ":"倦怠",
"ㄐㄩㄢㄉㄧ":"涓滴/蠲滌",
"ㄐㄩㄢㄉㄨ":"身毒",
"ㄐㄩㄢㄊㄞ":"鐫汰",
"ㄐㄩㄢㄊㄠ":"捲逃",
"ㄐㄩㄢㄊㄧ":"蠲體",
"ㄐㄩㄢㄌㄟ":"倦累",
"ㄐㄩㄢㄌㄧ":"娟麗",
"ㄐㄩㄢㄍㄨ":"眷顧",
"ㄐㄩㄢㄎㄜ":"鐫刻/蠲苛",
"ㄐㄩㄢㄏㄠ":"娟好",
"ㄐㄩㄢㄏㄡ":"絹猴",
"ㄐㄩㄢㄐㄧ":"悁急/狷急",
"ㄐㄩㄢㄐㄩ":"捐局",
"ㄐㄩㄢㄑㄧ":"捐棄",
"ㄐㄩㄢㄑㄩ":"捐軀/眷區",
"ㄐㄩㄢㄒㄩ":"卷鬚",
"ㄐㄩㄢㄓㄡ":"卷軸",
"ㄐㄩㄢㄓㄨ":"捐助/眷注",
"ㄐㄩㄢㄔㄡ":"絹綢",
"ㄐㄩㄢㄔㄥ":"鄄城",
"ㄐㄩㄢㄔㄨ":"捐除/蠲除",
"ㄐㄩㄢㄕㄜ":"卷舌/眷舍",
"ㄐㄩㄢㄕㄣ":"捐身",
"ㄐㄩㄢㄕㄥ":"捐生/眷生",
"ㄐㄩㄢㄕㄨ":"捐輸/眷屬",
"ㄐㄩㄢㄖㄣ":"涓人",
"ㄐㄩㄢㄖㄨ":"捲入",
"ㄐㄩㄢㄗㄜ":"卷幘",
"ㄐㄩㄢㄗㄡ":"捲走",
"ㄐㄩㄢㄗㄥ":"捐贈",
"ㄐㄩㄢㄗㄨ":"眷族/蠲租",
"ㄐㄩㄢㄘㄜ":"捐冊",
"ㄐㄩㄢㄙㄜ":"倦色",
"ㄐㄩㄢㄙㄨ":"絹素",
"ㄐㄩㄢㄧㄡ":"倦遊/倦游",
"ㄐㄩㄢㄧㄢ":"眷言/捲菸",
"ㄐㄩㄢㄧㄣ":"絹印/眷姻",
"ㄐㄩㄢㄩㄣ":"卷雲",
"ㄐㄩㄢㄩㄥ":"雋永",
"ㄐㄩㄣㄅㄚ":"俊拔/雋拔",
"ㄐㄩㄣㄅㄟ":"軍備",
"ㄐㄩㄣㄅㄢ":"峻阪",
"ㄐㄩㄣㄅㄣ":"駿奔",
"ㄐㄩㄣㄅㄧ":"峻筆",
"ㄐㄩㄣㄅㄨ":"鈞部",
"ㄐㄩㄣㄆㄞ":"均派",
"ㄐㄩㄣㄆㄟ":"均賠",
"ㄐㄩㄣㄇㄚ":"軍馬/駿馬",
"ㄐㄩㄣㄇㄞ":"俊邁",
"ㄐㄩㄣㄇㄟ":"俊美",
"ㄐㄩㄣㄇㄠ":"俊茂/俊髦",
"ㄐㄩㄣㄇㄣ":"軍門",
"ㄐㄩㄣㄇㄧ":"峻密",
"ㄐㄩㄣㄇㄨ":"菌幕",
"ㄐㄩㄣㄈㄚ":"軍閥/駿發",
"ㄐㄩㄣㄈㄟ":"軍費",
"ㄐㄩㄣㄈㄣ":"均分",
"ㄐㄩㄣㄈㄨ":"軍服/郡府",
"ㄐㄩㄣㄉㄜ":"俊德/峻德",
"ㄐㄩㄣㄉㄠ":"軍刀",
"ㄐㄩㄣㄉㄥ":"均等",
"ㄐㄩㄣㄊㄠ":"鈞陶",
"ㄐㄩㄣㄊㄡ":"軍頭",
"ㄐㄩㄣㄊㄢ":"軍毯/均攤",
"ㄐㄩㄣㄌㄟ":"菌類",
"ㄐㄩㄣㄌㄠ":"軍牢",
"ㄐㄩㄣㄌㄤ":"雋朗",
"ㄐㄩㄣㄌㄧ":"軍力/浚利",
"ㄐㄩㄣㄌㄨ":"囷鹿/箘簬",
"ㄐㄩㄣㄌㄩ":"軍律/軍旅",
"ㄐㄩㄣㄍㄜ":"軍歌",
"ㄐㄩㄣㄍㄞ":"菌蓋",
"ㄐㄩㄣㄍㄣ":"菌根",
"ㄐㄩㄣㄍㄤ":"軍港",
"ㄐㄩㄣㄍㄨ":"駿骨",
"ㄐㄩㄣㄎㄜ":"均可/峻刻",
"ㄐㄩㄣㄎㄨ":"峻酷",
"ㄐㄩㄣㄏㄜ":"菌核",
"ㄐㄩㄣㄏㄠ":"軍號",
"ㄐㄩㄣㄏㄡ":"君侯/郡侯",
"ㄐㄩㄣㄏㄥ":"均衡",
"ㄐㄩㄣㄏㄨ":"俊鶻",
"ㄐㄩㄣㄐㄧ":"軍機/峻極",
"ㄐㄩㄣㄐㄩ":"峻拒/駿駒",
"ㄐㄩㄣㄑㄧ":"軍器/軍旗",
"ㄐㄩㄣㄑㄩ":"軍區",
"ㄐㄩㄣㄒㄩ":"軍需",
"ㄐㄩㄣㄓㄜ":"濬哲/菌褶",
"ㄐㄩㄣㄓㄡ":"鈞軸",
"ㄐㄩㄣㄓㄢ":"均霑",
"ㄐㄩㄣㄓㄤ":"軍長/君長",
"ㄐㄩㄣㄓㄥ":"軍政",
"ㄐㄩㄣㄓㄨ":"君主/郡主",
"ㄐㄩㄣㄔㄣ":"君臣",
"ㄐㄩㄣㄔㄥ":"郡丞",
"ㄐㄩㄣㄔㄨ":"雋楚",
"ㄐㄩㄣㄕㄡ":"龜手/軍售",
"ㄐㄩㄣㄕㄢ":"君山",
"ㄐㄩㄣㄕㄨ":"軍書/軍屬",
"ㄐㄩㄣㄖㄣ":"軍人/君人",
"ㄐㄩㄣㄘㄞ":"俊材/浚財",
"ㄐㄩㄣㄘㄠ":"軍操",
"ㄐㄩㄣㄘㄤ":"囷倉",
"ㄐㄩㄣㄙㄢ":"菌傘",
"ㄐㄩㄣㄧㄚ":"俊雅",
"ㄐㄩㄣㄧㄝ":"軍爺",
"ㄐㄩㄣㄧㄞ":"峻崖",
"ㄐㄩㄣㄧㄠ":"鈞窯",
"ㄐㄩㄣㄧㄡ":"俊遊/軍郵",
"ㄐㄩㄣㄧㄢ":"鈞顏/俊彥",
"ㄐㄩㄣㄧㄥ":"軍營",
"ㄐㄩㄣㄨㄟ":"軍威/鈞威",
"ㄐㄩㄣㄨㄤ":"君王/郡望",
"ㄐㄩㄣㄩㄝ":"軍樂",
"ㄐㄩㄣㄩㄢ":"峻原",
"ㄐㄩㄣㄩㄣ":"均勻",
"ㄐㄩㄣㄩㄥ":"軍用",
"ㄐㄩㄥㄅㄚ":"迥拔",
"ㄐㄩㄥㄅㄨ":"窘步",
"ㄐㄩㄥㄆㄛ":"窘迫",
"ㄐㄩㄥㄇㄣ":"扃門",
"ㄐㄩㄥㄇㄨ":"坰牧",
"ㄐㄩㄥㄈㄟ":"迥非",
"ㄐㄩㄥㄊㄞ":"窘態",
"ㄐㄩㄥㄏㄨ":"扃戶",
"ㄐㄩㄥㄐㄧ":"窘急",
"ㄐㄩㄥㄖㄢ":"冏然/迥然",
"ㄐㄩㄥㄘㄨ":"窘促/窘蹙",
"ㄐㄩㄥㄧㄝ":"坰野/迥野",
"ㄐㄩㄥㄩㄢ":"迥遠",
"ㄐㄩㄧㄒㄧ":"聚乙烯",
"ㄐㄩㄨㄅㄚ":"巨無霸",
"ㄑㄧㄅㄞㄕ":"齊白石",
"ㄑㄧㄅㄠㄗ":"氣包子",
"ㄑㄧㄅㄧㄠ":"汽表/淒飆",
"ㄑㄧㄅㄧㄥ":"騎兵/起兵",
"ㄑㄧㄅㄨㄕ":"豈不是",
"ㄑㄧㄆㄠㄕ":"氣泡室",
"ㄑㄧㄆㄧㄗ":"起皮子",
"ㄑㄧㄆㄧㄠ":"期票",
"ㄑㄧㄆㄧㄢ":"欺騙",
"ㄑㄧㄆㄧㄣ":"棋品",
"ㄑㄧㄆㄧㄥ":"棋枰",
"ㄑㄧㄇㄧㄠ":"奇妙/七廟",
"ㄑㄧㄇㄧㄢ":"期勉/乞免",
"ㄑㄧㄇㄧㄣ":"器皿/齊民",
"ㄑㄧㄇㄧㄥ":"齊明/啟明",
"ㄑㄧㄉㄧㄢ":"起點/氣墊",
"ㄑㄧㄉㄧㄥ":"旗丁/啟碇",
"ㄑㄧㄉㄨㄛ":"起奪",
"ㄑㄧㄉㄨㄢ":"氣短/淒斷",
"ㄑㄧㄉㄨㄣ":"棲遁",
"ㄑㄧㄉㄨㄥ":"啟動/起動",
"ㄑㄧㄊㄧㄠ":"起跳",
"ㄑㄧㄊㄧㄢ":"杞天/氣田",
"ㄑㄧㄊㄧㄥ":"緝聽/氣艇",
"ㄑㄧㄊㄨㄛ":"棲託",
"ㄑㄧㄊㄨㄢ":"氣團",
"ㄑㄧㄊㄨㄥ":"氣筒/奇童",
"ㄑㄧㄋㄢㄗ":"奇男子",
"ㄑㄧㄋㄧㄢ":"齊年/祈年",
"ㄑㄧㄋㄩㄝ":"欺虐",
"ㄑㄧㄌㄧㄠ":"訖了/豈料",
"ㄑㄧㄌㄧㄡ":"氣流/杞柳",
"ㄑㄧㄌㄧㄢ":"乞憐",
"ㄑㄧㄌㄧㄣ":"麒麟/乞鄰",
"ㄑㄧㄌㄧㄤ":"淒涼/氣量",
"ㄑㄧㄌㄧㄥ":"欺凌/起靈",
"ㄑㄧㄌㄨㄛ":"起落/綺羅",
"ㄑㄧㄌㄨㄣ":"齊論",
"ㄑㄧㄌㄩㄝ":"七略",
"ㄑㄧㄍㄨㄛ":"齊國/七國",
"ㄑㄧㄍㄨㄞ":"奇怪",
"ㄑㄧㄍㄨㄢ":"器官/氣管",
"ㄑㄧㄍㄨㄥ":"氣功/起工",
"ㄑㄧㄎㄨㄤ":"欺誑",
"ㄑㄧㄎㄨㄥ":"氣孔/七孔",
"ㄑㄧㄏㄨㄚ":"企劃/氣化",
"ㄑㄧㄏㄨㄛ":"起火/期貨",
"ㄑㄧㄏㄨㄞ":"奇懷",
"ㄑㄧㄏㄨㄟ":"期會/祇悔",
"ㄑㄧㄏㄨㄢ":"奇幻",
"ㄑㄧㄏㄨㄤ":"岐黃/悽惶",
"ㄑㄧㄏㄨㄥ":"起烘/起鬨",
"ㄑㄧㄐㄧㄚ":"起家/齊家",
"ㄑㄧㄐㄧㄝ":"氣結/氣節",
"ㄑㄧㄐㄧㄠ":"起轎",
"ㄑㄧㄐㄧㄡ":"戚舊/汽酒",
"ㄑㄧㄐㄧㄢ":"期間/起見",
"ㄑㄧㄐㄧㄣ":"起勁/迄今",
"ㄑㄧㄐㄧㄤ":"起講/齊姜",
"ㄑㄧㄐㄧㄥ":"奇景/起敬",
"ㄑㄧㄐㄩㄕ":"起居室",
"ㄑㄧㄐㄩㄝ":"氣絕/奇絕",
"ㄑㄧㄐㄩㄢ":"起圈/棄捐",
"ㄑㄧㄐㄩㄣ":"起軍/奇俊",
"ㄑㄧㄑㄧㄝ":"淒切/悽切",
"ㄑㄧㄑㄧㄠ":"齊巧/奇巧",
"ㄑㄧㄑㄧㄡ":"祈求/氣球",
"ㄑㄧㄑㄧㄢ":"綺錢",
"ㄑㄧㄑㄧㄣ":"妻親",
"ㄑㄧㄑㄧㄤ":"氣強/氣槍",
"ㄑㄧㄑㄧㄥ":"七情/綺情",
"ㄑㄧㄑㄩㄝ":"奇缺",
"ㄑㄧㄑㄩㄢ":"齊全/棄權",
"ㄑㄧㄒㄧㄚ":"旗下/棲霞",
"ㄑㄧㄒㄧㄝ":"氣歇/齊諧",
"ㄑㄧㄒㄧㄠ":"妻小",
"ㄑㄧㄒㄧㄡ":"乞休/綺繡",
"ㄑㄧㄒㄧㄢ":"期限/起先",
"ㄑㄧㄒㄧㄣ":"齊心/起薪",
"ㄑㄧㄒㄧㄤ":"氣象/奇想",
"ㄑㄧㄒㄧㄥ":"起行/起興",
"ㄑㄧㄒㄩㄝ":"氣血/泣血",
"ㄑㄧㄒㄩㄢ":"氣旋",
"ㄑㄧㄒㄩㄣ":"奇勛",
"ㄑㄧㄒㄩㄥ":"緝兇/氣胸",
"ㄑㄧㄓㄨㄛ":"緝捉",
"ㄑㄧㄓㄨㄢ":"砌磚",
"ㄑㄧㄓㄨㄤ":"旗裝",
"ㄑㄧㄓㄨㄥ":"其中/器重",
"ㄑㄧㄔㄏㄨ":"齊齒呼",
"ㄑㄧㄔㄑㄩ":"七尺軀",
"ㄑㄧㄔㄨㄟ":"氣錘",
"ㄑㄧㄔㄨㄢ":"氣喘/戚串",
"ㄑㄧㄔㄨㄤ":"起床/氣窗",
"ㄑㄧㄕㄌㄨ":"啟示錄",
"ㄑㄧㄕㄐㄩ":"祈使句",
"ㄑㄧㄕㄨㄛ":"奇說",
"ㄑㄧㄕㄨㄟ":"汽水/淇水",
"ㄑㄧㄖㄨㄗ":"氣褥子",
"ㄑㄧㄖㄨㄥ":"戚容",
"ㄑㄧㄗㄈㄚ":"七字法",
"ㄑㄧㄗㄨㄛ":"起坐/器作",
"ㄑㄧㄗㄨㄟ":"泣罪",
"ㄑㄧㄗㄨㄥ":"瘈瘲",
"ㄑㄧㄘㄨㄢ":"齊攢",
"ㄑㄧㄘㄨㄥ":"啟聰",
"ㄑㄧㄙㄈㄥ":"氣死風",
"ㄑㄧㄙㄨㄛ":"棲所/氣索",
"ㄑㄧㄚㄅㄚ":"掐巴",
"ㄑㄧㄚㄅㄢ":"洽辦",
"ㄑㄧㄚㄇㄠ":"帢帽",
"ㄑㄧㄚㄉㄤ":"恰當",
"ㄑㄧㄚㄍㄡ":"洽購",
"ㄑㄧㄚㄏㄠ":"恰好",
"ㄑㄧㄚㄓㄥ":"恰正",
"ㄑㄧㄚㄓㄨ":"掐住",
"ㄑㄧㄚㄖㄨ":"恰如",
"ㄑㄧㄚㄗㄜ":"恰則",
"ㄑㄧㄚㄘㄞ":"恰纔",
"ㄑㄧㄝㄅㄛ":"鍥薄",
"ㄑㄧㄝㄆㄢ":"切盼",
"ㄑㄧㄝㄇㄛ":"切莫/切末",
"ㄑㄧㄝㄇㄞ":"切脈",
"ㄑㄧㄝㄇㄢ":"且慢",
"ㄑㄧㄝㄈㄟ":"竊匪",
"ㄑㄧㄝㄈㄢ":"竊犯",
"ㄑㄧㄝㄈㄨ":"且夫/切膚",
"ㄑㄧㄝㄉㄞ":"挈帶/茄袋",
"ㄑㄧㄝㄉㄠ":"且道/竊盜",
"ㄑㄧㄝㄉㄤ":"切當/愜當",
"ㄑㄧㄝㄊㄧ":"切題",
"ㄑㄧㄝㄌㄢ":"伽藍",
"ㄑㄧㄝㄍㄜ":"切割",
"ㄑㄧㄝㄍㄨ":"切骨",
"ㄑㄧㄝㄎㄜ":"茄科",
"ㄑㄧㄝㄎㄡ":"切口/切口",
"ㄑㄧㄝㄏㄜ":"切合",
"ㄑㄧㄝㄏㄠ":"竊號",
"ㄑㄧㄝㄏㄨ":"挈壺",
"ㄑㄧㄝㄐㄧ":"切忌/切記",
"ㄑㄧㄝㄐㄩ":"竊據",
"ㄑㄧㄝㄑㄧ":"愜氣",
"ㄑㄧㄝㄑㄩ":"竊取",
"ㄑㄧㄝㄒㄧ":"竊喜",
"ㄑㄧㄝㄒㄩ":"契需",
"ㄑㄧㄝㄓㄢ":"竊占",
"ㄑㄧㄝㄓㄣ":"切診",
"ㄑㄧㄝㄓㄨ":"且住/切囑",
"ㄑㄧㄝㄔㄨ":"切除",
"ㄑㄧㄝㄕㄢ":"篋扇",
"ㄑㄧㄝㄕㄣ":"切身/妾身",
"ㄑㄧㄝㄖㄨ":"切入/且如",
"ㄑㄧㄝㄗㄜ":"且則/切責",
"ㄑㄧㄝㄗㄟ":"竊賊",
"ㄑㄧㄝㄧㄠ":"切要/且要",
"ㄑㄧㄝㄧㄢ":"篋衍",
"ㄑㄧㄝㄧㄣ":"切音",
"ㄑㄧㄝㄧㄥ":"妾媵",
"ㄑㄧㄝㄨㄟ":"竊位",
"ㄑㄧㄝㄩㄢ":"切圓",
"ㄑㄧㄝㄩㄣ":"切雲/切韻",
"ㄑㄧㄝㄩㄥ":"竊用",
"ㄑㄧㄠㄅㄚ":"蕎巴/峭拔",
"ㄑㄧㄠㄅㄛ":"峭薄/磽薄",
"ㄑㄧㄠㄅㄠ":"僑報/僑胞",
"ㄑㄧㄠㄅㄧ":"峭壁",
"ㄑㄧㄠㄆㄞ":"橋牌",
"ㄑㄧㄠㄆㄧ":"俏皮",
"ㄑㄧㄠㄆㄨ":"敲扑",
"ㄑㄧㄠㄇㄞ":"蕎麥",
"ㄑㄧㄠㄇㄣ":"竅門/敲門",
"ㄑㄧㄠㄇㄨ":"喬木/樵牧",
"ㄑㄧㄠㄈㄚ":"峭法",
"ㄑㄧㄠㄈㄨ":"巧婦/樵夫",
"ㄑㄧㄠㄉㄚ":"敲打",
"ㄑㄧㄠㄉㄧ":"磽地",
"ㄑㄧㄠㄊㄡ":"橋頭/俏頭",
"ㄑㄧㄠㄊㄨ":"橋堍",
"ㄑㄧㄠㄋㄠ":"橋腦",
"ㄑㄧㄠㄌㄜ":"巧了",
"ㄑㄧㄠㄌㄡ":"樵樓/譙樓",
"ㄑㄧㄠㄌㄥ":"翹棱",
"ㄑㄧㄠㄌㄧ":"峭立/巧曆",
"ㄑㄧㄠㄌㄨ":"譙櫓",
"ㄑㄧㄠㄌㄩ":"憔慮",
"ㄑㄧㄠㄍㄜ":"樵歌",
"ㄑㄧㄠㄍㄤ":"撬槓",
"ㄑㄧㄠㄍㄨ":"巧故",
"ㄑㄧㄠㄎㄜ":"瞧科/樵客",
"ㄑㄧㄠㄎㄞ":"敲開/撬開",
"ㄑㄧㄠㄏㄜ":"巧合/譙呵",
"ㄑㄧㄠㄏㄢ":"趫悍",
"ㄑㄧㄠㄏㄨ":"樵戶",
"ㄑㄧㄠㄐㄧ":"敲擊/巧計",
"ㄑㄧㄠㄐㄩ":"橋劇/僑居",
"ㄑㄧㄠㄑㄧ":"翹企/敲棋",
"ㄑㄧㄠㄑㄩ":"巧取",
"ㄑㄧㄠㄒㄧ":"蹺蹊/巧夕",
"ㄑㄧㄠㄓㄚ":"巧詐/敲詐",
"ㄑㄧㄠㄓㄡ":"譙周",
"ㄑㄧㄠㄔㄨ":"翹楚",
"ㄑㄧㄠㄕㄜ":"僑社/巧舌",
"ㄑㄧㄠㄕㄡ":"巧手/翹首",
"ㄑㄧㄠㄕㄢ":"橋山",
"ㄑㄧㄠㄕㄤ":"瞧上/僑商",
"ㄑㄧㄠㄕㄥ":"僑生/悄聲",
"ㄑㄧㄠㄖㄢ":"悄然/愀然",
"ㄑㄧㄠㄖㄣ":"僑人/喬人",
"ㄑㄧㄠㄖㄤ":"誚讓/譙讓",
"ㄑㄧㄠㄗㄜ":"譙責",
"ㄑㄧㄠㄗㄨ":"蹻足",
"ㄑㄧㄠㄘㄞ":"喬才/趫才",
"ㄑㄧㄠㄘㄨ":"俏簇",
"ㄑㄧㄠㄙㄨ":"喬素/樵蘇",
"ㄑㄧㄠㄧㄢ":"巧言/嫶妍",
"ㄑㄧㄠㄧㄣ":"樵隱",
"ㄑㄧㄠㄨㄢ":"巧丸",
"ㄑㄧㄡㄅㄚ":"丘八",
"ㄑㄧㄡㄅㄛ":"秋波/秋播",
"ㄑㄧㄡㄅㄟ":"求備/糗糒",
"ㄑㄧㄡㄅㄤ":"球棒/秋榜",
"ㄑㄧㄡㄆㄞ":"球拍",
"ㄑㄧㄡㄆㄢ":"虯蟠",
"ㄑㄧㄡㄇㄚ":"裘馬/秋麻",
"ㄑㄧㄡㄇㄟ":"遒美/秋眉",
"ㄑㄧㄡㄇㄠ":"酋矛/厹矛",
"ㄑㄧㄡㄇㄣ":"球門",
"ㄑㄧㄡㄇㄧ":"球迷",
"ㄑㄧㄡㄇㄨ":"求牡/丘木",
"ㄑㄧㄡㄈㄚ":"秋髮",
"ㄑㄧㄡㄈㄢ":"囚犯",
"ㄑㄧㄡㄈㄣ":"秋分/丘墳",
"ㄑㄧㄡㄈㄤ":"秋方/球房",
"ㄑㄧㄡㄈㄥ":"秋風",
"ㄑㄧㄡㄉㄠ":"求道",
"ㄑㄧㄡㄉㄢ":"球膽",
"ㄑㄧㄡㄊㄧ":"球體/鼽嚏",
"ㄑㄧㄡㄊㄨ":"囚徒/秋荼",
"ㄑㄧㄡㄋㄠ":"丘腦",
"ㄑㄧㄡㄌㄠ":"囚牢",
"ㄑㄧㄡㄌㄢ":"毬蘭",
"ㄑㄧㄡㄍㄜ":"裘葛",
"ㄑㄧㄡㄍㄠ":"求告",
"ㄑㄧㄡㄍㄢ":"球杆",
"ㄑㄧㄡㄍㄣ":"球根",
"ㄑㄧㄡㄍㄥ":"秋耕",
"ㄑㄧㄡㄎㄠ":"求靠",
"ㄑㄧㄡㄏㄜ":"求和/秋河",
"ㄑㄧㄡㄏㄠ":"秋毫",
"ㄑㄧㄡㄏㄢ":"秋旱",
"ㄑㄧㄡㄏㄨ":"秋胡",
"ㄑㄧㄡㄐㄧ":"秋季/球技",
"ㄑㄧㄡㄑㄧ":"秋氣/秋期",
"ㄑㄧㄡㄑㄩ":"求取",
"ㄑㄧㄡㄒㄧ":"秋禊/囚繫",
"ㄑㄧㄡㄒㄩ":"虯鬚/丘墟",
"ㄑㄧㄡㄓㄢ":"求戰",
"ㄑㄧㄡㄓㄣ":"求診/丘疹",
"ㄑㄧㄡㄓㄤ":"酋長",
"ㄑㄧㄡㄓㄥ":"球證/求證",
"ㄑㄧㄡㄓㄨ":"求助",
"ㄑㄧㄡㄓㄩ":"求知慾",
"ㄑㄧㄡㄔㄚ":"秋茶",
"ㄑㄧㄡㄔㄜ":"囚車",
"ㄑㄧㄡㄔㄢ":"秋蟬",
"ㄑㄧㄡㄔㄤ":"球場/秋場",
"ㄑㄧㄡㄔㄥ":"求成/秋成",
"ㄑㄧㄡㄕㄜ":"秋社",
"ㄑㄧㄡㄕㄡ":"秋收/求售",
"ㄑㄧㄡㄕㄢ":"丘山/秋扇",
"ㄑㄧㄡㄕㄣ":"秋審",
"ㄑㄧㄡㄕㄥ":"求生/求勝",
"ㄑㄧㄡㄖㄠ":"求饒",
"ㄑㄧㄡㄖㄢ":"虯髯",
"ㄑㄧㄡㄖㄣ":"求人",
"ㄑㄧㄡㄘㄞ":"求才",
"ㄑㄧㄡㄘㄠ":"秋草",
"ㄑㄧㄡㄙㄜ":"秋色",
"ㄑㄧㄡㄙㄞ":"球賽",
"ㄑㄧㄡㄙㄠ":"丘嫂",
"ㄑㄧㄡㄙㄨ":"求宿",
"ㄑㄧㄡㄧㄝ":"艽野/賕謁",
"ㄑㄧㄡㄧㄢ":"秋顏",
"ㄑㄧㄡㄧㄣ":"蚯蚓",
"ㄑㄧㄡㄧㄤ":"秋陽",
"ㄑㄧㄡㄧㄥ":"仇英",
"ㄑㄧㄡㄨㄟ":"秋闈",
"ㄑㄧㄡㄩㄢ":"球員/求援",
"ㄑㄧㄢㄅㄛ":"淺薄/錢帛",
"ㄑㄧㄢㄅㄞ":"鉛白",
"ㄑㄧㄢㄅㄟ":"前輩/謙卑",
"ㄑㄧㄢㄅㄠ":"錢包",
"ㄑㄧㄢㄅㄢ":"千般/鉛版",
"ㄑㄧㄢㄅㄣ":"槧本",
"ㄑㄧㄢㄅㄧ":"鉛筆/錢幣",
"ㄑㄧㄢㄅㄨ":"錢布/潛步",
"ㄑㄧㄢㄆㄛ":"虔婆",
"ㄑㄧㄢㄆㄢ":"潛盤",
"ㄑㄧㄢㄆㄨ":"錢鋪",
"ㄑㄧㄢㄇㄚ":"前馬/牽馬",
"ㄑㄧㄢㄇㄛ":"阡陌/錢陌",
"ㄑㄧㄢㄇㄠ":"前茅/遷貿",
"ㄑㄧㄢㄇㄡ":"僉謀",
"ㄑㄧㄢㄇㄣ":"前門/千門",
"ㄑㄧㄢㄇㄨ":"前母",
"ㄑㄧㄢㄈㄚ":"簽發/遣發",
"ㄑㄧㄢㄈㄢ":"遣返/前番",
"ㄑㄧㄢㄈㄣ":"鉛粉/芡粉",
"ㄑㄧㄢㄈㄤ":"前方",
"ㄑㄧㄢㄈㄥ":"前鋒/錢灃",
"ㄑㄧㄢㄈㄨ":"潛伏/前夫",
"ㄑㄧㄢㄉㄜ":"潛德/愆德",
"ㄑㄧㄢㄉㄞ":"前代/錢袋",
"ㄑㄧㄢㄉㄠ":"簽到/前導",
"ㄑㄧㄢㄉㄢ":"簽單/千石",
"ㄑㄧㄢㄉㄤ":"欠當",
"ㄑㄧㄢㄉㄧ":"潛邸",
"ㄑㄧㄢㄉㄨ":"遷都",
"ㄑㄧㄢㄊㄜ":"愆忒",
"ㄑㄧㄢㄊㄞ":"前臺",
"ㄑㄧㄢㄊㄠ":"潛逃",
"ㄑㄧㄢㄊㄡ":"前頭/牽頭",
"ㄑㄧㄢㄊㄢ":"淺談/淺灘",
"ㄑㄧㄢㄊㄤ":"前膛",
"ㄑㄧㄢㄊㄥ":"騫騰",
"ㄑㄧㄢㄊㄧ":"前提",
"ㄑㄧㄢㄊㄨ":"前途/乾圖",
"ㄑㄧㄢㄋㄤ":"慳囊",
"ㄑㄧㄢㄋㄥ":"潛能",
"ㄑㄧㄢㄋㄧ":"潛匿",
"ㄑㄧㄢㄋㄨ":"遷怒/鉛駑",
"ㄑㄧㄢㄋㄩ":"倩女",
"ㄑㄧㄢㄌㄞ":"前來",
"ㄑㄧㄢㄌㄟ":"鉛淚/牽累",
"ㄑㄧㄢㄌㄡ":"淺陋",
"ㄑㄧㄢㄌㄧ":"潛力/前例",
"ㄑㄧㄢㄌㄨ":"淺露",
"ㄑㄧㄢㄍㄤ":"乾綱",
"ㄑㄧㄢㄍㄨ":"千古/錢穀",
"ㄑㄧㄢㄎㄜ":"前科/遷客",
"ㄑㄧㄢㄎㄡ":"鉗口/拑口",
"ㄑㄧㄢㄎㄥ":"塹坑",
"ㄑㄧㄢㄏㄜ":"前和/譴何",
"ㄑㄧㄢㄏㄞ":"淺海",
"ㄑㄧㄢㄏㄠ":"欠好/塹壕",
"ㄑㄧㄢㄏㄡ":"前後/謙厚",
"ㄑㄧㄢㄏㄢ":"前漢",
"ㄑㄧㄢㄏㄨ":"千戶/縴戶",
"ㄑㄧㄢㄐㄧ":"牽記/鈐記",
"ㄑㄧㄢㄐㄩ":"前矩/鉗且",
"ㄑㄧㄢㄑㄧ":"前妻/錢起",
"ㄑㄧㄢㄑㄩ":"前去/前驅",
"ㄑㄧㄢㄒㄧ":"前夕/遷徙",
"ㄑㄧㄢㄒㄩ":"謙虛/前緒",
"ㄑㄧㄢㄓㄘ":"前置詞",
"ㄑㄧㄢㄓㄜ":"前者/掮著",
"ㄑㄧㄢㄓㄞ":"欠債/乾宅",
"ㄑㄧㄢㄓㄠ":"前趙/前兆",
"ㄑㄧㄢㄓㄡ":"譴咒",
"ㄑㄧㄢㄓㄢ":"前站",
"ㄑㄧㄢㄓㄤ":"千章/千嶂",
"ㄑㄧㄢㄓㄥ":"簽證/遷正",
"ㄑㄧㄢㄓㄨ":"前箸",
"ㄑㄧㄢㄔㄜ":"遣車/牽扯",
"ㄑㄧㄢㄔㄠ":"前朝/錢潮",
"ㄑㄧㄢㄔㄡ":"前仇",
"ㄑㄧㄢㄔㄢ":"牽纏/嵌巉",
"ㄑㄧㄢㄔㄣ":"前塵",
"ㄑㄧㄢㄔㄤ":"淺嘗/淺嚐",
"ㄑㄧㄢㄔㄥ":"虔誠/前程",
"ㄑㄧㄢㄔㄨ":"遷除",
"ㄑㄧㄢㄕㄜ":"牽涉/前設",
"ㄑㄧㄢㄕㄠ":"前哨",
"ㄑㄧㄢㄕㄡ":"簽收/前手",
"ㄑㄧㄢㄕㄢ":"謙善/遷善",
"ㄑㄧㄢㄕㄣ":"欠身/前身",
"ㄑㄧㄢㄕㄤ":"前晌",
"ㄑㄧㄢㄕㄥ":"前生/千乘",
"ㄑㄧㄢㄕㄨ":"簽署/前述",
"ㄑㄧㄢㄖㄜ":"潛熱",
"ㄑㄧㄢㄖㄢ":"遷染",
"ㄑㄧㄢㄖㄣ":"前人/遷人",
"ㄑㄧㄢㄖㄤ":"謙讓",
"ㄑㄧㄢㄖㄨ":"潛入",
"ㄑㄧㄢㄗㄜ":"譴責/歉仄",
"ㄑㄧㄢㄗㄞ":"潛在/前載",
"ㄑㄧㄢㄗㄠ":"乾造/嵌造",
"ㄑㄧㄢㄗㄡ":"前奏/欠揍",
"ㄑㄧㄢㄗㄤ":"遷葬",
"ㄑㄧㄢㄗㄨ":"欠租/傔卒",
"ㄑㄧㄢㄘㄞ":"錢財/牽彩",
"ㄑㄧㄢㄘㄠ":"茜草",
"ㄑㄧㄢㄘㄤ":"潛藏",
"ㄑㄧㄢㄙㄢ":"遣散",
"ㄑㄧㄢㄙㄨ":"淺俗/鉛素",
"ㄑㄧㄢㄧㄕ":"潛意識",
"ㄑㄧㄢㄧㄚ":"籤押/僉押",
"ㄑㄧㄢㄧㄝ":"前業/前夜",
"ㄑㄧㄢㄧㄠ":"乾曜",
"ㄑㄧㄢㄧㄡ":"愆尤",
"ㄑㄧㄢㄧㄢ":"前言/遷延",
"ㄑㄧㄢㄧㄣ":"牽引/前因",
"ㄑㄧㄢㄧㄤ":"愆陽",
"ㄑㄧㄢㄧㄥ":"潛影/倩影",
"ㄑㄧㄢㄨㄚ":"千瓦",
"ㄑㄧㄢㄨㄟ":"前衛",
"ㄑㄧㄢㄨㄢ":"千萬/前晚",
"ㄑㄧㄢㄨㄣ":"錢文/淺聞",
"ㄑㄧㄢㄨㄤ":"前往/僉望",
"ㄑㄧㄢㄩㄝ":"簽約/前月",
"ㄑㄧㄢㄩㄢ":"前緣/乾元",
"ㄑㄧㄢㄩㄥ":"錢泳/潛泳",
"ㄑㄧㄣㄅㄠ":"侵暴/擒抱",
"ㄑㄧㄣㄅㄣ":"親本",
"ㄑㄧㄣㄅㄤ":"親傍/侵傍",
"ㄑㄧㄣㄅㄧ":"親筆",
"ㄑㄧㄣㄆㄟ":"欽佩",
"ㄑㄧㄣㄆㄥ":"親朋",
"ㄑㄧㄣㄆㄨ":"琴譜",
"ㄑㄧㄣㄇㄡ":"侵牟",
"ㄑㄧㄣㄇㄣ":"寢門",
"ㄑㄧㄣㄇㄧ":"親密",
"ㄑㄧㄣㄇㄨ":"親母/親睦",
"ㄑㄧㄣㄈㄢ":"侵犯/欽犯",
"ㄑㄧㄣㄈㄣ":"勤奮",
"ㄑㄧㄣㄈㄨ":"親赴/擒服",
"ㄑㄧㄣㄉㄠ":"撳倒/擒盜",
"ㄑㄧㄣㄉㄢ":"衾單",
"ㄑㄧㄣㄉㄥ":"親等",
"ㄑㄧㄣㄉㄨ":"親睹/侵瀆",
"ㄑㄧㄣㄊㄤ":"琴堂",
"ㄑㄧㄣㄋㄚ":"擒拿",
"ㄑㄧㄣㄋㄧ":"親暱/親昵",
"ㄑㄧㄣㄌㄟ":"親類/噙淚",
"ㄑㄧㄣㄌㄠ":"勤勞",
"ㄑㄧㄣㄌㄡ":"秦樓/寢陋",
"ㄑㄧㄣㄌㄧ":"親歷/秦隸",
"ㄑㄧㄣㄍㄜ":"琴歌",
"ㄑㄧㄣㄍㄥ":"親耕",
"ㄑㄧㄣㄍㄨ":"親故",
"ㄑㄧㄣㄎㄡ":"親口",
"ㄑㄧㄣㄎㄣ":"勤懇",
"ㄑㄧㄣㄎㄨ":"勤苦",
"ㄑㄧㄣㄏㄞ":"侵害",
"ㄑㄧㄣㄏㄡ":"親厚",
"ㄑㄧㄣㄏㄢ":"沁寒",
"ㄑㄧㄣㄏㄤ":"勤行",
"ㄑㄧㄣㄐㄧ":"寢疾",
"ㄑㄧㄣㄐㄩ":"寢具",
"ㄑㄧㄣㄑㄧ":"親戚/親啟",
"ㄑㄧㄣㄒㄧ":"侵襲/親系",
"ㄑㄧㄣㄒㄩ":"勤恤/欽恤",
"ㄑㄧㄣㄓㄢ":"親展/侵占",
"ㄑㄧㄣㄓㄣ":"衾枕",
"ㄑㄧㄣㄓㄥ":"親政/勤政",
"ㄑㄧㄣㄓㄨ":"欽佇",
"ㄑㄧㄣㄔㄜ":"寢車",
"ㄑㄧㄣㄔㄞ":"欽差",
"ㄑㄧㄣㄔㄠ":"親潮/秦朝",
"ㄑㄧㄣㄔㄡ":"衾裯",
"ㄑㄧㄣㄔㄣ":"侵晨",
"ㄑㄧㄣㄔㄥ":"欽承",
"ㄑㄧㄣㄔㄨ":"寢處",
"ㄑㄧㄣㄕㄡ":"親手/禽獸",
"ㄑㄧㄣㄕㄢ":"親善",
"ㄑㄧㄣㄕㄣ":"親身/勤慎",
"ㄑㄧㄣㄕㄥ":"親生/秦聲",
"ㄑㄧㄣㄕㄨ":"親屬/琴書",
"ㄑㄧㄣㄖㄜ":"親熱",
"ㄑㄧㄣㄖㄠ":"侵擾",
"ㄑㄧㄣㄖㄢ":"嶔然",
"ㄑㄧㄣㄖㄣ":"親人/親任",
"ㄑㄧㄣㄖㄨ":"侵入",
"ㄑㄧㄣㄗㄠ":"侵早/芹藻",
"ㄑㄧㄣㄗㄨ":"親族",
"ㄑㄧㄣㄘㄞ":"芹菜",
"ㄑㄧㄣㄘㄣ":"嶔岑",
"ㄑㄧㄣㄙㄜ":"琴瑟/廞塞",
"ㄑㄧㄣㄧㄡ":"親友",
"ㄑㄧㄣㄧㄢ":"親眼/禽言",
"ㄑㄧㄣㄧㄣ":"侵淫/嶔崟",
"ㄑㄧㄣㄧㄤ":"欽仰",
"ㄑㄧㄣㄧㄥ":"親迎",
"ㄑㄧㄣㄨㄣ":"親吻",
"ㄑㄧㄣㄨㄤ":"親往/親王",
"ㄑㄧㄣㄩㄝ":"秦越/侵越",
"ㄑㄧㄣㄩㄣ":"琴韻",
"ㄑㄧㄣㄩㄥ":"侵用/秦俑",
"ㄑㄧㄤㄅㄚ":"槍把/鎗把",
"ㄑㄧㄤㄅㄛ":"將伯",
"ㄑㄧㄤㄅㄞ":"搶白/戕敗",
"ㄑㄧㄤㄅㄟ":"搶背",
"ㄑㄧㄤㄅㄠ":"強暴/襁抱",
"ㄑㄧㄤㄅㄢ":"強半",
"ㄑㄧㄤㄅㄣ":"彊本",
"ㄑㄧㄤㄅㄤ":"強棒",
"ㄑㄧㄤㄅㄧ":"牆壁/強逼",
"ㄑㄧㄤㄅㄨ":"搶步",
"ㄑㄧㄤㄆㄛ":"強迫",
"ㄑㄧㄤㄇㄧ":"蘠蘼",
"ㄑㄧㄤㄈㄚ":"槍法",
"ㄑㄧㄤㄈㄢ":"檣帆",
"ㄑㄧㄤㄈㄥ":"搶風",
"ㄑㄧㄤㄈㄨ":"襁負",
"ㄑㄧㄤㄉㄚ":"強大/搶答",
"ㄑㄧㄤㄉㄠ":"強盜",
"ㄑㄧㄤㄉㄢ":"槍彈/鎗彈",
"ㄑㄧㄤㄉㄧ":"搶地/羌笛",
"ㄑㄧㄤㄉㄨ":"強度/強渡",
"ㄑㄧㄤㄊㄡ":"牆頭",
"ㄑㄧㄤㄊㄢ":"搶灘",
"ㄑㄧㄤㄊㄤ":"槍膛/鎗膛",
"ㄑㄧㄤㄊㄧ":"槍替",
"ㄑㄧㄤㄋㄨ":"強弩",
"ㄑㄧㄤㄌㄜ":"強勒",
"ㄑㄧㄤㄌㄤ":"蜣螂",
"ㄑㄧㄤㄌㄧ":"強力/搶籬",
"ㄑㄧㄤㄌㄨ":"蹡路/強虜",
"ㄑㄧㄤㄍㄡ":"搶購",
"ㄑㄧㄤㄍㄢ":"強幹/強幹",
"ㄑㄧㄤㄍㄣ":"牆根",
"ㄑㄧㄤㄍㄤ":"搶槓",
"ㄑㄧㄤㄍㄥ":"強梗",
"ㄑㄧㄤㄍㄨ":"強固/搶孤",
"ㄑㄧㄤㄎㄜ":"嗆咳",
"ㄑㄧㄤㄎㄡ":"鎗口/強寇",
"ㄑㄧㄤㄏㄞ":"戕害",
"ㄑㄧㄤㄏㄢ":"強悍/蹌捍",
"ㄑㄧㄤㄏㄥ":"強橫",
"ㄑㄧㄤㄏㄨ":"搶呼",
"ㄑㄧㄤㄐㄧ":"槍擊/強記",
"ㄑㄧㄤㄒㄧ":"搶戲",
"ㄑㄧㄤㄓㄢ":"槍戰/強占",
"ㄑㄧㄤㄓㄨ":"戧柱",
"ㄑㄧㄤㄕㄚ":"槍殺",
"ㄑㄧㄤㄕㄡ":"搶手/槍手",
"ㄑㄧㄤㄕㄣ":"槍身",
"ㄑㄧㄤㄕㄤ":"搶上",
"ㄑㄧㄤㄕㄥ":"強盛",
"ㄑㄧㄤㄖㄣ":"強人/強忍",
"ㄑㄧㄤㄗㄜ":"戕賊",
"ㄑㄧㄤㄗㄨ":"羌族/廧崒",
"ㄑㄧㄤㄙㄜ":"錆色",
"ㄑㄧㄤㄧㄚ":"矼牙",
"ㄑㄧㄤㄧㄢ":"搶眼/鎗眼",
"ㄑㄧㄤㄧㄥ":"強硬",
"ㄑㄧㄤㄨㄟ":"薔薇/檣桅",
"ㄑㄧㄤㄩㄢ":"牆垣/嬙媛",
"ㄑㄧㄤㄩㄣ":"腔韻",
"ㄑㄧㄥㄅㄚ":"清拔",
"ㄑㄧㄥㄅㄛ":"輕薄",
"ㄑㄧㄥㄅㄞ":"清白",
"ㄑㄧㄥㄅㄠ":"情報/氫爆",
"ㄑㄧㄥㄅㄤ":"清幫/青幫",
"ㄑㄧㄥㄅㄥ":"傾崩",
"ㄑㄧㄥㄅㄧ":"清蹕/輕鄙",
"ㄑㄧㄥㄅㄨ":"黥布",
"ㄑㄧㄥㄆㄠ":"青袍",
"ㄑㄧㄥㄆㄣ":"傾盆",
"ㄑㄧㄥㄆㄧ":"青皮/傾圮",
"ㄑㄧㄥㄇㄞ":"請脈",
"ㄑㄧㄥㄇㄟ":"青黴",
"ㄑㄧㄥㄇㄠ":"情貌",
"ㄑㄧㄥㄇㄢ":"輕慢",
"ㄑㄧㄥㄇㄣ":"清門/青門",
"ㄑㄧㄥㄇㄤ":"青盲",
"ㄑㄧㄥㄇㄨ":"傾慕/青目",
"ㄑㄧㄥㄈㄚ":"清發/青發",
"ㄑㄧㄥㄈㄟ":"輕肥/圊肥",
"ㄑㄧㄥㄈㄣ":"情分",
"ㄑㄧㄥㄈㄤ":"親房",
"ㄑㄧㄥㄈㄥ":"清風/輕風",
"ㄑㄧㄥㄈㄨ":"輕浮/情婦",
"ㄑㄧㄥㄉㄞ":"輕怠",
"ㄑㄧㄥㄉㄠ":"傾倒/傾倒",
"ㄑㄧㄥㄉㄡ":"青豆/情竇",
"ㄑㄧㄥㄉㄢ":"清單/清淡",
"ㄑㄧㄥㄉㄤ":"清黨",
"ㄑㄧㄥㄉㄧ":"情敵/輕敵",
"ㄑㄧㄥㄉㄨ":"輕度/清都",
"ㄑㄧㄥㄊㄞ":"情態/青苔",
"ㄑㄧㄥㄊㄡ":"清頭",
"ㄑㄧㄥㄊㄢ":"傾談/清談",
"ㄑㄧㄥㄊㄤ":"清湯",
"ㄑㄧㄥㄊㄨ":"傾吐",
"ㄑㄧㄥㄋㄤ":"傾囊",
"ㄑㄧㄥㄋㄧ":"青泥",
"ㄑㄧㄥㄋㄨ":"青奴",
"ㄑㄧㄥㄋㄩ":"青女",
"ㄑㄧㄥㄌㄞ":"青睞",
"ㄑㄧㄥㄌㄡ":"青樓",
"ㄑㄧㄥㄌㄢ":"青覽",
"ㄑㄧㄥㄌㄤ":"晴朗/情郎",
"ㄑㄧㄥㄌㄥ":"清冷",
"ㄑㄧㄥㄌㄧ":"清理/情理",
"ㄑㄧㄥㄌㄨ":"青廬/青鷺",
"ㄑㄧㄥㄌㄩ":"情侶",
"ㄑㄧㄥㄍㄜ":"情歌/清歌",
"ㄑㄧㄥㄍㄞ":"傾蓋",
"ㄑㄧㄥㄍㄠ":"清高/清稿",
"ㄑㄧㄥㄍㄢ":"情感",
"ㄑㄧㄥㄎㄜ":"頃刻/請客",
"ㄑㄧㄥㄎㄞ":"謦欬",
"ㄑㄧㄥㄎㄨ":"清苦",
"ㄑㄧㄥㄏㄜ":"慶賀/清和",
"ㄑㄧㄥㄏㄞ":"青海/情海",
"ㄑㄧㄥㄏㄠ":"青蒿",
"ㄑㄧㄥㄏㄢ":"清寒/輕汗",
"ㄑㄧㄥㄏㄨ":"輕忽",
"ㄑㄧㄥㄐㄧ":"情急/清績",
"ㄑㄧㄥㄐㄩ":"輕車/輕舉",
"ㄑㄧㄥㄑㄧ":"清氣/清奇",
"ㄑㄧㄥㄑㄩ":"情趣/清臞",
"ㄑㄧㄥㄒㄧ":"清晰/清洗",
"ㄑㄧㄥㄒㄩ":"情緒",
"ㄑㄧㄥㄓㄜ":"頃者/磬折",
"ㄑㄧㄥㄓㄠ":"清朝",
"ㄑㄧㄥㄓㄡ":"輕舟",
"ㄑㄧㄥㄓㄢ":"清湛",
"ㄑㄧㄥㄓㄣ":"清真",
"ㄑㄧㄥㄓㄤ":"清丈/清帳",
"ㄑㄧㄥㄓㄥ":"清蒸/清正",
"ㄑㄧㄥㄓㄨ":"慶祝/傾注",
"ㄑㄧㄥㄔㄚ":"清察/清查",
"ㄑㄧㄥㄔㄜ":"清澈",
"ㄑㄧㄥㄔㄠ":"清朝/傾巢",
"ㄑㄧㄥㄔㄣ":"清晨/清塵",
"ㄑㄧㄥㄔㄤ":"清場/情場",
"ㄑㄧㄥㄔㄥ":"慶成/清澄",
"ㄑㄧㄥㄔㄨ":"清楚/清除",
"ㄑㄧㄥㄕㄚ":"情殺/輕紗",
"ㄑㄧㄥㄕㄜ":"青蛇",
"ㄑㄧㄥㄕㄠ":"清劭",
"ㄑㄧㄥㄕㄡ":"清瘦/擎手",
"ㄑㄧㄥㄕㄢ":"青山/青衫",
"ㄑㄧㄥㄕㄣ":"輕身/清神",
"ㄑㄧㄥㄕㄤ":"情商/情賞",
"ㄑㄧㄥㄕㄥ":"輕聲/輕生",
"ㄑㄧㄥㄕㄨ":"情書/情熟",
"ㄑㄧㄥㄖㄡ":"輕柔",
"ㄑㄧㄥㄖㄢ":"罄然",
"ㄑㄧㄥㄖㄣ":"情人/清人",
"ㄑㄧㄥㄗㄠ":"清早/輕躁",
"ㄑㄧㄥㄘㄜ":"清冊/傾側",
"ㄑㄧㄥㄘㄞ":"青菜/情采",
"ㄑㄧㄥㄘㄠ":"情操/清操",
"ㄑㄧㄥㄘㄤ":"清倉/清艙",
"ㄑㄧㄥㄙㄜ":"情色/青澀",
"ㄑㄧㄥㄙㄠ":"清掃",
"ㄑㄧㄥㄙㄨ":"傾訴/情素",
"ㄑㄧㄥㄧㄚ":"清雅/傾軋",
"ㄑㄧㄥㄧㄝ":"清夜/請業",
"ㄑㄧㄥㄧㄠ":"清要/清么",
"ㄑㄧㄥㄧㄡ":"清幽/情由",
"ㄑㄧㄥㄧㄢ":"輕言/清言",
"ㄑㄧㄥㄧㄣ":"清音",
"ㄑㄧㄥㄧㄤ":"清樣/清揚",
"ㄑㄧㄥㄧㄥ":"輕盈/請纓",
"ㄑㄧㄥㄨㄚ":"青蛙",
"ㄑㄧㄥㄨㄟ":"輕微/情味",
"ㄑㄧㄥㄨㄢ":"清玩",
"ㄑㄧㄥㄨㄣ":"請問/晴雯",
"ㄑㄧㄥㄨㄤ":"情網/清望",
"ㄑㄧㄥㄨㄥ":"親翁",
"ㄑㄧㄥㄩㄝ":"清樂/清越",
"ㄑㄧㄥㄩㄢ":"情願/請願",
"ㄑㄧㄥㄩㄣ":"情韻/青雲",
"ㄑㄧㄧㄢㄕ":"七言詩",
"ㄑㄧㄨㄤㄓ":"期望值",
"ㄑㄧㄩㄤㄤ":"氣宇昂昂",
"ㄑㄩㄅㄛㄩ":"蘧伯玉",
"ㄑㄩㄅㄧㄝ":"區別",
"ㄑㄩㄅㄧㄢ":"取便",
"ㄑㄩㄅㄧㄥ":"祛病/驅兵",
"ㄑㄩㄆㄧㄥ":"屈平",
"ㄑㄩㄇㄧㄢ":"曲面",
"ㄑㄩㄇㄧㄥ":"取名/軀命",
"ㄑㄩㄉㄠㄕ":"麴道士",
"ㄑㄩㄉㄥㄦ":"取燈兒",
"ㄑㄩㄉㄧㄠ":"曲調",
"ㄑㄩㄉㄧㄥ":"驅丁",
"ㄑㄩㄉㄨㄢ":"區段",
"ㄑㄩㄉㄨㄥ":"驅動",
"ㄑㄩㄊㄧㄢ":"區田",
"ㄑㄩㄊㄧㄥ":"趨庭",
"ㄑㄩㄊㄨㄟ":"祛退",
"ㄑㄩㄋㄧㄢ":"去年",
"ㄑㄩㄋㄨㄢ":"取暖",
"ㄑㄩㄋㄨㄥ":"渠儂",
"ㄑㄩㄌㄧㄝ":"臞劣",
"ㄑㄩㄌㄧㄡ":"去留/曲流",
"ㄑㄩㄌㄧㄤ":"屈量",
"ㄑㄩㄌㄨㄛ":"區落",
"ㄑㄩㄍㄨㄛ":"去國",
"ㄑㄩㄍㄨㄟ":"驅鬼",
"ㄑㄩㄍㄨㄢ":"去官",
"ㄑㄩㄎㄨㄟ":"渠魁",
"ㄑㄩㄎㄨㄢ":"取款",
"ㄑㄩㄎㄨㄥ":"覷空",
"ㄑㄩㄏㄨㄚ":"趣話/曲話",
"ㄑㄩㄏㄨㄛ":"取火/去火",
"ㄑㄩㄐㄧㄚ":"屈駕",
"ㄑㄩㄐㄧㄝ":"取解/曲解",
"ㄑㄩㄐㄧㄡ":"屈就/去就",
"ㄑㄩㄐㄧㄢ":"區間",
"ㄑㄩㄐㄧㄣ":"曲謹",
"ㄑㄩㄐㄧㄤ":"曲江/渠江",
"ㄑㄩㄐㄧㄥ":"取經/取景",
"ㄑㄩㄐㄩㄝ":"取決/覷絕",
"ㄑㄩㄑㄧㄝ":"胠篋",
"ㄑㄩㄑㄧㄠ":"取巧",
"ㄑㄩㄑㄧㄡ":"曲球",
"ㄑㄩㄑㄧㄢ":"麴錢/驅遣",
"ㄑㄩㄑㄧㄣ":"娶親/嶇嶔",
"ㄑㄩㄑㄧㄤ":"趨蹌",
"ㄑㄩㄑㄩㄥ":"曲瓊",
"ㄑㄩㄒㄧㄚ":"區夏",
"ㄑㄩㄒㄧㄝ":"驅邪",
"ㄑㄩㄒㄧㄠ":"取消/取笑",
"ㄑㄩㄒㄧㄢ":"曲線",
"ㄑㄩㄒㄧㄣ":"屈心/取信",
"ㄑㄩㄒㄧㄤ":"去向/趨向",
"ㄑㄩㄒㄩㄝ":"曲學/曲學",
"ㄑㄩㄒㄩㄢ":"取選",
"ㄑㄩㄓㄜㄩ":"屈折語",
"ㄑㄩㄔㄨㄥ":"渠衝/蛆蟲",
"ㄑㄩㄕㄨㄛ":"曲說",
"ㄑㄩㄕㄨㄞ":"渠率",
"ㄑㄩㄕㄨㄟ":"取水/曲水",
"ㄑㄩㄖㄨㄥ":"取容",
"ㄑㄩㄗㄨㄛ":"屈座",
"ㄑㄩㄗㄨㄣ":"屈尊",
"ㄑㄩㄘㄨㄥ":"曲從/屈從",
"ㄑㄩㄙㄨㄟ":"去歲",
"ㄑㄩㄙㄨㄥ":"屈宋",
"ㄑㄩㄝㄅㄠ":"確保/鵲報",
"ㄑㄩㄝㄅㄢ":"雀斑",
"ㄑㄩㄝㄅㄨ":"卻步/卻不",
"ㄑㄩㄝㄇㄞ":"雀麥",
"ㄑㄩㄝㄇㄠ":"雀瞀",
"ㄑㄩㄝㄇㄣ":"缺門",
"ㄑㄩㄝㄈㄚ":"缺乏/闕乏",
"ㄑㄩㄝㄈㄨ":"怯夫",
"ㄑㄩㄝㄉㄜ":"缺德",
"ㄑㄩㄝㄉㄞ":"卻待",
"ㄑㄩㄝㄉㄤ":"確當",
"ㄑㄩㄝㄌㄠ":"卻老",
"ㄑㄩㄝㄌㄡ":"缺漏",
"ㄑㄩㄝㄌㄧ":"確立/卻立",
"ㄑㄩㄝㄍㄨ":"榷酤",
"ㄑㄩㄝㄎㄜ":"缺刻/缺課",
"ㄑㄩㄝㄎㄠ":"缺考",
"ㄑㄩㄝㄎㄡ":"缺口/怯口",
"ㄑㄩㄝㄏㄡ":"卻後",
"ㄑㄩㄝㄏㄢ":"缺憾",
"ㄑㄩㄝㄏㄨ":"確乎",
"ㄑㄩㄝㄐㄧ":"确瘠",
"ㄑㄩㄝㄑㄧ":"鵲起",
"ㄑㄩㄝㄒㄧ":"缺席/雀息",
"ㄑㄩㄝㄓㄢ":"雀戰",
"ㄑㄩㄝㄓㄣ":"怯陣",
"ㄑㄩㄝㄓㄥ":"確證/怯症",
"ㄑㄩㄝㄔㄚ":"榷茶",
"ㄑㄩㄝㄔㄞ":"雀釵",
"ㄑㄩㄝㄔㄠ":"鵲巢",
"ㄑㄩㄝㄔㄤ":"怯場",
"ㄑㄩㄝㄕㄜ":"雀舌",
"ㄑㄩㄝㄕㄠ":"缺少/卻少",
"ㄑㄩㄝㄕㄢ":"卻扇",
"ㄑㄩㄝㄕㄤ":"怯上",
"ㄑㄩㄝㄕㄥ":"怯生",
"ㄑㄩㄝㄕㄨ":"雀鼠",
"ㄑㄩㄝㄖㄣ":"確認",
"ㄑㄩㄝㄖㄨ":"闕如",
"ㄑㄩㄝㄗㄠ":"鵲噪",
"ㄑㄩㄝㄘㄞ":"卻纔",
"ㄑㄩㄝㄧㄝ":"缺頁",
"ㄑㄩㄝㄧㄡ":"卻又",
"ㄑㄩㄝㄧㄢ":"榷鹽",
"ㄑㄩㄝㄧㄣ":"鵲印",
"ㄑㄩㄝㄧㄥ":"雀鷹",
"ㄑㄩㄝㄨㄣ":"闕文",
"ㄑㄩㄝㄩㄝ":"雀躍/卻月",
"ㄑㄩㄢㄅㄟ":"勸杯/泉貝",
"ㄑㄩㄢㄅㄠ":"全豹",
"ㄑㄩㄢㄅㄢ":"全般",
"ㄑㄩㄢㄅㄣ":"全本",
"ㄑㄩㄢㄅㄤ":"拳棒",
"ㄑㄩㄢㄅㄧ":"泉幣",
"ㄑㄩㄢㄅㄨ":"全部/銓部",
"ㄑㄩㄢㄆㄛ":"券破",
"ㄑㄩㄢㄆㄢ":"全盤/勸盤",
"ㄑㄩㄢㄇㄚ":"犬馬",
"ㄑㄩㄢㄇㄞ":"泉脈",
"ㄑㄩㄢㄇㄠ":"全貌",
"ㄑㄩㄢㄇㄡ":"權謀",
"ㄑㄩㄢㄇㄣ":"權門",
"ㄑㄩㄢㄇㄨ":"勸募/畎畝",
"ㄑㄩㄢㄈㄚ":"拳法/卷髮",
"ㄑㄩㄢㄈㄟ":"拳匪",
"ㄑㄩㄢㄈㄣ":"全分",
"ㄑㄩㄢㄈㄨ":"全福/全副",
"ㄑㄩㄢㄉㄜ":"全德",
"ㄑㄩㄢㄉㄞ":"權代",
"ㄑㄩㄢㄉㄠ":"勸導",
"ㄑㄩㄢㄉㄥ":"全等",
"ㄑㄩㄢㄉㄨ":"權度",
"ㄑㄩㄢㄊㄞ":"泉臺",
"ㄑㄩㄢㄊㄠ":"圈套/全套",
"ㄑㄩㄢㄊㄡ":"拳頭",
"ㄑㄩㄢㄊㄤ":"醛醣",
"ㄑㄩㄢㄊㄧ":"全體/筌蹄",
"ㄑㄩㄢㄋㄟ":"圈內",
"ㄑㄩㄢㄋㄥ":"全能/權能",
"ㄑㄩㄢㄌㄡ":"卷婁",
"ㄑㄩㄢㄌㄧ":"權力/權利",
"ㄑㄩㄢㄌㄨ":"全錄/全祿",
"ㄑㄩㄢㄍㄜ":"悛革/荃葛",
"ㄑㄩㄢㄍㄞ":"權概/悛改",
"ㄑㄩㄢㄍㄠ":"勸告",
"ㄑㄩㄢㄍㄨ":"顴骨",
"ㄑㄩㄢㄎㄜ":"痊可",
"ㄑㄩㄢㄎㄞ":"全開",
"ㄑㄩㄢㄎㄡ":"圈口",
"ㄑㄩㄢㄏㄜ":"勸和",
"ㄑㄩㄢㄏㄥ":"權衡/銓衡",
"ㄑㄩㄢㄐㄧ":"拳擊/全集",
"ㄑㄩㄢㄐㄩ":"全局/蜷局",
"ㄑㄩㄢㄑㄧ":"權奇",
"ㄑㄩㄢㄑㄩ":"全取/全軀",
"ㄑㄩㄢㄒㄧ":"全席",
"ㄑㄩㄢㄒㄩ":"銓敘",
"ㄑㄩㄢㄓㄚ":"權詐",
"ㄑㄩㄢㄓㄡ":"泉州",
"ㄑㄩㄢㄓㄣ":"全真",
"ㄑㄩㄢㄓㄤ":"權杖",
"ㄑㄩㄢㄓㄥ":"詮證",
"ㄑㄩㄢㄓㄨ":"權軸/詮注",
"ㄑㄩㄢㄔㄚ":"荃察",
"ㄑㄩㄢㄔㄡ":"勸酬",
"ㄑㄩㄢㄔㄣ":"權臣",
"ㄑㄩㄢㄔㄤ":"全長",
"ㄑㄩㄢㄔㄥ":"全程/全城",
"ㄑㄩㄢㄕㄡ":"權首",
"ㄑㄩㄢㄕㄢ":"勸善",
"ㄑㄩㄢㄕㄣ":"全身",
"ㄑㄩㄢㄕㄤ":"券商",
"ㄑㄩㄢㄕㄥ":"全盛/全勝",
"ㄑㄩㄢㄕㄨ":"全數/權術",
"ㄑㄩㄢㄖㄓ":"全日制",
"ㄑㄩㄢㄖㄢ":"全然",
"ㄑㄩㄢㄖㄣ":"全人",
"ㄑㄩㄢㄖㄤ":"泉壤",
"ㄑㄩㄢㄗㄜ":"權責/全責",
"ㄑㄩㄢㄗㄞ":"荃宰",
"ㄑㄩㄢㄗㄨ":"勸阻/全阻",
"ㄑㄩㄢㄘㄞ":"全才/全綵",
"ㄑㄩㄢㄙㄞ":"拳賽",
"ㄑㄩㄢㄙㄨ":"全速",
"ㄑㄩㄢㄧㄚ":"犬牙",
"ㄑㄩㄢㄧㄠ":"權要",
"ㄑㄩㄢㄧㄡ":"勸誘",
"ㄑㄩㄢㄧㄢ":"泉眼",
"ㄑㄩㄢㄧㄣ":"全音",
"ㄑㄩㄢㄨㄟ":"權威/權位",
"ㄑㄩㄢㄨㄢ":"蜷蜿",
"ㄑㄩㄢㄩㄢ":"泉源",
"ㄑㄩㄢㄩㄥ":"拳勇/泉湧",
"ㄑㄩㄣㄅㄟ":"群輩",
"ㄑㄩㄣㄅㄧ":"群辟",
"ㄑㄩㄣㄈㄤ":"群芳",
"ㄑㄩㄣㄉㄞ":"裙帶",
"ㄑㄩㄣㄉㄠ":"群島",
"ㄑㄩㄣㄊㄧ":"群體",
"ㄑㄩㄣㄌㄧ":"群黎",
"ㄑㄩㄣㄏㄡ":"群后",
"ㄑㄩㄣㄐㄧ":"群集/麇集",
"ㄑㄩㄣㄐㄩ":"群居/群聚",
"ㄑㄩㄣㄑㄧ":"群起",
"ㄑㄩㄣㄔㄞ":"裙釵",
"ㄑㄩㄣㄔㄡ":"群醜",
"ㄑㄩㄣㄕㄥ":"群生",
"ㄑㄩㄣㄗㄨ":"群組",
"ㄑㄩㄣㄧㄢ":"群彥",
"ㄑㄩㄣㄧㄥ":"群英",
"ㄑㄩㄥㄅㄠ":"窮暴",
"ㄑㄩㄥㄆㄚ":"瓊葩",
"ㄑㄩㄥㄆㄛ":"窮迫",
"ㄑㄩㄥㄇㄚ":"瓊麻",
"ㄑㄩㄥㄇㄠ":"藑茅",
"ㄑㄩㄥㄇㄤ":"窮忙",
"ㄑㄩㄥㄇㄨ":"窮目",
"ㄑㄩㄥㄈㄚ":"窮髮/窮乏",
"ㄑㄩㄥㄈㄥ":"窮瘋",
"ㄑㄩㄥㄉㄨ":"惸獨/煢獨",
"ㄑㄩㄥㄊㄨ":"窮途",
"ㄑㄩㄥㄌㄧ":"窮理/惸嫠",
"ㄑㄩㄥㄌㄨ":"穹廬",
"ㄑㄩㄥㄍㄨ":"穹谷",
"ㄑㄩㄥㄎㄡ":"窮寇",
"ㄑㄩㄥㄎㄨ":"窮苦",
"ㄑㄩㄥㄏㄢ":"窮漢",
"ㄑㄩㄥㄐㄧ":"窮極",
"ㄑㄩㄥㄐㄩ":"瓊劇/煢居",
"ㄑㄩㄥㄑㄧ":"窮氣",
"ㄑㄩㄥㄓㄤ":"邛杖",
"ㄑㄩㄥㄔㄡ":"窮愁",
"ㄑㄩㄥㄕㄣ":"窮神",
"ㄑㄩㄥㄕㄥ":"窮生",
"ㄑㄩㄥㄖㄗ":"窮日子",
"ㄑㄩㄥㄖㄢ":"跫然",
"ㄑㄩㄥㄖㄣ":"窮人",
"ㄑㄩㄥㄖㄤ":"穹壤",
"ㄑㄩㄥㄘㄤ":"穹蒼",
"ㄑㄩㄥㄘㄨ":"窮蹙",
"ㄑㄩㄥㄧㄠ":"瓊瑤",
"ㄑㄩㄥㄧㄢ":"瓊筵",
"ㄑㄩㄥㄧㄣ":"瓊音/跫音",
"ㄑㄩㄥㄧㄥ":"瓊英",
"ㄑㄩㄩㄧㄢ":"鴝鵒眼",
"ㄒㄧㄅㄛㄓ":"錫箔紙",
"ㄒㄧㄅㄟㄩ":"西北雨",
"ㄒㄧㄅㄠㄓ":"細胞質",
"ㄒㄧㄅㄠㄗ":"戲報子",
"ㄒㄧㄅㄧㄝ":"細別/惜別",
"ㄒㄧㄅㄧㄠ":"細表",
"ㄒㄧㄅㄧㄢ":"西邊/徙邊",
"ㄒㄧㄅㄧㄣ":"西賓",
"ㄒㄧㄅㄧㄥ":"喜病/習兵",
"ㄒㄧㄆㄧㄢ":"西片",
"ㄒㄧㄆㄧㄥ":"悉平/熹平",
"ㄒㄧㄇㄛㄓ":"吸墨紙",
"ㄒㄧㄇㄥㄙ":"席夢思",
"ㄒㄧㄇㄧㄝ":"熄滅/息滅",
"ㄒㄧㄇㄧㄢ":"洗面/席面",
"ㄒㄧㄇㄧㄣ":"細民",
"ㄒㄧㄇㄧㄥ":"錫命/繫命",
"ㄒㄧㄈㄨㄦ":"媳婦兒",
"ㄒㄧㄉㄧㄝ":"細蝶",
"ㄒㄧㄉㄧㄠ":"西調",
"ㄒㄧㄉㄧㄢ":"西點",
"ㄒㄧㄉㄧㄥ":"翕定/畦丁",
"ㄒㄧㄉㄨㄛ":"襲奪",
"ㄒㄧㄉㄨㄥ":"翕動/西東",
"ㄒㄧㄊㄧㄝ":"喜帖/禊帖",
"ㄒㄧㄊㄧㄠ":"細挑",
"ㄒㄧㄊㄧㄢ":"西天/畦田",
"ㄒㄧㄊㄧㄥ":"細聽",
"ㄒㄧㄊㄨㄛ":"洗脫",
"ㄒㄧㄊㄨㄟ":"西頹",
"ㄒㄧㄊㄨㄥ":"系統",
"ㄒㄧㄋㄢㄧ":"西南夷",
"ㄒㄧㄋㄧㄠ":"犀鳥",
"ㄒㄧㄋㄧㄡ":"犀牛",
"ㄒㄧㄋㄧㄢ":"昔年/觿年",
"ㄒㄧㄋㄧㄤ":"細娘/喜娘",
"ㄒㄧㄋㄧㄥ":"熙寧",
"ㄒㄧㄋㄨㄥ":"戲弄/嬉弄",
"ㄒㄧㄋㄩㄝ":"戲謔",
"ㄒㄧㄌㄧㄝ":"系列",
"ㄒㄧㄌㄧㄠ":"西遼",
"ㄒㄧㄌㄧㄡ":"細流/細柳",
"ㄒㄧㄌㄧㄢ":"細鍊/洗臉",
"ㄒㄧㄌㄧㄣ":"西鄰/餼廩",
"ㄒㄧㄌㄧㄤ":"細糧/西涼",
"ㄒㄧㄌㄧㄥ":"西陵/奚凌",
"ㄒㄧㄌㄨㄛ":"奚落/傒落",
"ㄒㄧㄌㄨㄣ":"戲論",
"ㄒㄧㄍㄞㄗ":"蓆蓋子",
"ㄒㄧㄍㄨㄚ":"西瓜/洗刮",
"ㄒㄧㄍㄨㄛ":"喜果/繫裹",
"ㄒㄧㄍㄨㄟ":"細貴/西歸",
"ㄒㄧㄍㄨㄢ":"習慣/戲館",
"ㄒㄧㄍㄨㄤ":"希光/娭光",
"ㄒㄧㄍㄨㄥ":"細工/喜功",
"ㄒㄧㄎㄨㄢ":"息款",
"ㄒㄧㄎㄨㄣ":"西崑",
"ㄒㄧㄎㄨㄤ":"錫礦",
"ㄒㄧㄎㄨㄥ":"惜恐",
"ㄒㄧㄏㄨㄚ":"西化/細話",
"ㄒㄧㄏㄨㄛ":"係獲/細活",
"ㄒㄧㄏㄨㄞ":"繫懷",
"ㄒㄧㄏㄨㄟ":"夕暉",
"ㄒㄧㄏㄨㄢ":"喜歡/洗換",
"ㄒㄧㄏㄨㄣ":"錫婚",
"ㄒㄧㄏㄨㄤ":"西皇/羲皇",
"ㄒㄧㄐㄧㄚ":"西家/犀甲",
"ㄒㄧㄐㄧㄝ":"細節/析解",
"ㄒㄧㄐㄧㄠ":"細嚼/息交",
"ㄒㄧㄐㄧㄡ":"細究/喜酒",
"ㄒㄧㄐㄧㄢ":"習見/西澗",
"ㄒㄧㄐㄧㄣ":"細謹/西晉",
"ㄒㄧㄐㄧㄤ":"西江/錫匠",
"ㄒㄧㄐㄧㄥ":"係頸/西經",
"ㄒㄧㄐㄩㄢ":"席捲/攜眷",
"ㄒㄧㄐㄩㄣ":"細菌/細君",
"ㄒㄧㄑㄧㄠ":"細巧/細瞧",
"ㄒㄧㄑㄧㄡ":"希求/繫囚",
"ㄒㄧㄑㄧㄢ":"洗錢/息錢",
"ㄒㄧㄑㄧㄣ":"西秦/奚琴",
"ㄒㄧㄑㄧㄤ":"西羌/鬩牆",
"ㄒㄧㄑㄧㄥ":"喜慶/細情",
"ㄒㄧㄒㄧㄚ":"膝下/西夏",
"ㄒㄧㄒㄧㄝ":"扱鞋",
"ㄒㄧㄒㄧㄠ":"細小/嬉笑",
"ㄒㄧㄒㄧㄢ":"巇嶮",
"ㄒㄧㄒㄧㄣ":"細心/悉心",
"ㄒㄧㄒㄧㄤ":"西廂/戲箱",
"ㄒㄧㄒㄧㄥ":"細行/希行",
"ㄒㄧㄒㄩㄝ":"西學/洗雪",
"ㄒㄧㄒㄩㄢ":"曦軒",
"ㄒㄧㄒㄩㄣ":"喜訊/夕曛",
"ㄒㄧㄓㄥㄨ":"係爭物",
"ㄒㄧㄓㄧㄢ":"矽質岩",
"ㄒㄧㄓㄨㄛ":"洗濯/洒濯",
"ㄒㄧㄓㄨㄤ":"西裝/戲裝",
"ㄒㄧㄓㄨㄥ":"係踵",
"ㄒㄧㄔㄨㄟ":"西垂",
"ㄒㄧㄔㄨㄢ":"息喘",
"ㄒㄧㄔㄨㄣ":"熙春",
"ㄒㄧㄔㄨㄥ":"席寵",
"ㄒㄧㄕㄐㄧ":"稀釋劑",
"ㄒㄧㄕㄑㄧ":"細石器",
"ㄒㄧㄕㄕㄜ":"西施舌",
"ㄒㄧㄕㄨㄚ":"洗刷/戲耍",
"ㄒㄧㄕㄨㄛ":"細說/悉率",
"ㄒㄧㄕㄨㄞ":"蟋蟀",
"ㄒㄧㄕㄨㄟ":"戲水/嬉水",
"ㄒㄧㄕㄨㄣ":"吸吮",
"ㄒㄧㄖㄨㄛ":"細弱/奚若",
"ㄒㄧㄖㄨㄟ":"犀銳",
"ㄒㄧㄖㄨㄢ":"細軟",
"ㄒㄧㄖㄨㄥ":"喜容/西戎",
"ㄒㄧㄗㄨㄛ":"細作/習作",
"ㄒㄧㄗㄨㄣ":"犧尊",
"ㄒㄧㄘㄨㄢ":"析爨",
"ㄒㄧㄙㄨㄟ":"細碎",
"ㄒㄧㄙㄨㄢ":"細算/細酸",
"ㄒㄧㄙㄨㄣ":"系孫",
"ㄒㄧㄙㄨㄥ":"息訟/稀鬆",
"ㄒㄧㄚㄅㄚ":"下巴/蝦芭",
"ㄒㄧㄚㄅㄞ":"下擺/下拜",
"ㄒㄧㄚㄅㄟ":"下輩",
"ㄒㄧㄚㄅㄢ":"下班",
"ㄒㄧㄚㄅㄧ":"下筆/下幣",
"ㄒㄧㄚㄅㄨ":"下部/夏布",
"ㄒㄧㄚㄆㄚ":"嚇怕",
"ㄒㄧㄚㄆㄛ":"下坡",
"ㄒㄧㄚㄆㄟ":"霞帔",
"ㄒㄧㄚㄆㄠ":"嚇跑",
"ㄒㄧㄚㄆㄢ":"下盤/下磐",
"ㄒㄧㄚㄆㄥ":"瞎碰",
"ㄒㄧㄚㄆㄧ":"下皮/蝦皮",
"ㄒㄧㄚㄇㄚ":"下馬",
"ㄒㄧㄚㄇㄣ":"下門",
"ㄒㄧㄚㄇㄤ":"瞎忙",
"ㄒㄧㄚㄇㄧ":"蝦米",
"ㄒㄧㄚㄈㄢ":"下凡/下番",
"ㄒㄧㄚㄈㄤ":"下放/下方",
"ㄒㄧㄚㄈㄥ":"下風",
"ㄒㄧㄚㄈㄨ":"下服/下浮",
"ㄒㄧㄚㄉㄚ":"下達",
"ㄒㄧㄚㄉㄜ":"下得/下的",
"ㄒㄧㄚㄉㄞ":"嚇呆",
"ㄒㄧㄚㄉㄠ":"俠盜",
"ㄒㄧㄚㄉㄢ":"下蛋",
"ㄒㄧㄚㄉㄤ":"下檔",
"ㄒㄧㄚㄉㄥ":"下等",
"ㄒㄧㄚㄉㄧ":"下地/下第",
"ㄒㄧㄚㄉㄨ":"下都/下毒",
"ㄒㄧㄚㄊㄚ":"下榻",
"ㄒㄧㄚㄊㄞ":"下臺",
"ㄒㄧㄚㄊㄡ":"下頭/下頭",
"ㄒㄧㄚㄊㄤ":"下堂",
"ㄒㄧㄚㄊㄧ":"下體",
"ㄒㄧㄚㄊㄨ":"下土",
"ㄒㄧㄚㄋㄠ":"瞎鬧",
"ㄒㄧㄚㄋㄧ":"狎暱/狎昵",
"ㄒㄧㄚㄋㄩ":"下女",
"ㄒㄧㄚㄌㄚ":"夏臘",
"ㄒㄧㄚㄌㄞ":"下來/下來",
"ㄒㄧㄚㄌㄡ":"下樓/罅漏",
"ㄒㄧㄚㄌㄧ":"下利/下禮",
"ㄒㄧㄚㄌㄨ":"下路/狹路",
"ㄒㄧㄚㄍㄠ":"瞎搞/瞎攪",
"ㄒㄧㄚㄍㄢ":"下疳",
"ㄒㄧㄚㄍㄨ":"峽谷/下顧",
"ㄒㄧㄚㄎㄜ":"俠客/下課",
"ㄒㄧㄚㄎㄡ":"下口",
"ㄒㄧㄚㄏㄞ":"下海/下頦",
"ㄒㄧㄚㄏㄡ":"夏侯",
"ㄒㄧㄚㄏㄢ":"下頷",
"ㄒㄧㄚㄏㄣ":"下狠",
"ㄒㄧㄚㄏㄨ":"下戶/嚇唬",
"ㄒㄧㄚㄐㄧ":"下級/夏季",
"ㄒㄧㄚㄐㄩ":"遐舉",
"ㄒㄧㄚㄑㄧ":"下氣/下妻",
"ㄒㄧㄚㄑㄩ":"下去/轄區",
"ㄒㄧㄚㄒㄧ":"罅隙/下席",
"ㄒㄧㄚㄒㄩ":"蝦鬚",
"ㄒㄧㄚㄓㄜ":"瑕謫",
"ㄒㄧㄚㄓㄞ":"狹窄",
"ㄒㄧㄚㄓㄠ":"下詔",
"ㄒㄧㄚㄓㄡ":"下晝/遐胄",
"ㄒㄧㄚㄓㄤ":"瞎帳",
"ㄒㄧㄚㄓㄥ":"夏正",
"ㄒㄧㄚㄓㄨ":"下注/下箸",
"ㄒㄧㄚㄔㄚ":"下茶",
"ㄒㄧㄚㄔㄜ":"下車/瞎扯",
"ㄒㄧㄚㄔㄠ":"夏朝",
"ㄒㄧㄚㄔㄣ":"下沉/下陳",
"ㄒㄧㄚㄔㄤ":"下場/下場",
"ㄒㄧㄚㄔㄥ":"下程",
"ㄒㄧㄚㄔㄨ":"下廚/下處",
"ㄒㄧㄚㄕㄠ":"下哨/下梢",
"ㄒㄧㄚㄕㄡ":"下手/下首",
"ㄒㄧㄚㄕㄢ":"下山",
"ㄒㄧㄚㄕㄣ":"下身/下神",
"ㄒㄧㄚㄕㄥ":"下剩/下乘",
"ㄒㄧㄚㄕㄨ":"下屬/下書",
"ㄒㄧㄚㄖㄢ":"岈然",
"ㄒㄧㄚㄖㄣ":"下人/諕人",
"ㄒㄧㄚㄗㄜ":"下澤",
"ㄒㄧㄚㄗㄞ":"下崽",
"ㄒㄧㄚㄗㄡ":"下走/瞎謅",
"ㄒㄧㄚㄗㄤ":"下葬",
"ㄒㄧㄚㄗㄨ":"下足",
"ㄒㄧㄚㄘㄜ":"下策",
"ㄒㄧㄚㄘㄞ":"下才/下彩",
"ㄒㄧㄚㄘㄠ":"下操",
"ㄒㄧㄚㄘㄥ":"下層",
"ㄒㄧㄚㄧㄕ":"下意識",
"ㄒㄧㄚㄧㄚ":"轄押",
"ㄒㄧㄚㄧㄝ":"下野/下夜",
"ㄒㄧㄚㄧㄠ":"下藥/下腰",
"ㄒㄧㄚㄧㄡ":"下游/狎遊",
"ㄒㄧㄚㄧㄢ":"下咽/下嚥",
"ㄒㄧㄚㄧㄥ":"暇景",
"ㄒㄧㄚㄨㄚ":"夏娃",
"ㄒㄧㄚㄨㄟ":"下位/下帷",
"ㄒㄧㄚㄨㄢ":"下晚/狎玩",
"ㄒㄧㄚㄨㄣ":"下文/下問",
"ㄒㄧㄚㄨㄤ":"下網",
"ㄒㄧㄚㄩㄢ":"下院/下元",
"ㄒㄧㄚㄩㄣ":"狹韻",
"ㄒㄧㄝㄅㄛ":"歇泊",
"ㄒㄧㄝㄅㄞ":"寫白/薤白",
"ㄒㄧㄝㄅㄢ":"協辦/歇班",
"ㄒㄧㄝㄅㄣ":"寫本",
"ㄒㄧㄝㄅㄤ":"鞋幫",
"ㄒㄧㄝㄅㄥ":"血崩",
"ㄒㄧㄝㄅㄧ":"脅逼",
"ㄒㄧㄝㄅㄨ":"謝步",
"ㄒㄧㄝㄆㄛ":"脅迫/斜坡",
"ㄒㄧㄝㄆㄞ":"歇拍/邪派",
"ㄒㄧㄝㄆㄣ":"血盆",
"ㄒㄧㄝㄆㄧ":"邪皮/邪僻",
"ㄒㄧㄝㄇㄚ":"歇馬",
"ㄒㄧㄝㄇㄛ":"邪魔",
"ㄒㄧㄝㄇㄞ":"血脈",
"ㄒㄧㄝㄇㄠ":"血毛",
"ㄒㄧㄝㄇㄡ":"邪謀/協謀",
"ㄒㄧㄝㄇㄢ":"懈慢/媟慢",
"ㄒㄧㄝㄇㄣ":"邪門",
"ㄒㄧㄝㄇㄧ":"洩密",
"ㄒㄧㄝㄇㄨ":"謝幕",
"ㄒㄧㄝㄈㄚ":"寫法/邪法",
"ㄒㄧㄝㄈㄣ":"血糞/洩憤",
"ㄒㄧㄝㄈㄤ":"協防",
"ㄒㄧㄝㄈㄥ":"協風",
"ㄒㄧㄝㄈㄨ":"協服/褻服",
"ㄒㄧㄝㄉㄞ":"挾帶/懈怠",
"ㄒㄧㄝㄉㄠ":"邪道/邪道",
"ㄒㄧㄝㄉㄡ":"械鬥",
"ㄒㄧㄝㄉㄢ":"械彈",
"ㄒㄧㄝㄉㄤ":"諧當",
"ㄒㄧㄝㄉㄧ":"鞋底/洩底",
"ㄒㄧㄝㄉㄨ":"斜度/褻瀆",
"ㄒㄧㄝㄊㄡ":"卸頭",
"ㄒㄧㄝㄊㄤ":"血糖",
"ㄒㄧㄝㄊㄧ":"屑涕",
"ㄒㄧㄝㄊㄨ":"謝土/瀉土",
"ㄒㄧㄝㄋㄧ":"斜睨",
"ㄒㄧㄝㄌㄟ":"鞋類/血淚",
"ㄒㄧㄝㄌㄠ":"偕老",
"ㄒㄧㄝㄌㄡ":"洩漏/泄漏",
"ㄒㄧㄝㄌㄤ":"屧廊",
"ㄒㄧㄝㄌㄧ":"謝禮/協力",
"ㄒㄧㄝㄌㄨ":"洩露/血路",
"ㄒㄧㄝㄌㄩ":"協律",
"ㄒㄧㄝㄍㄜ":"些個",
"ㄒㄧㄝㄍㄠ":"寫稿/斜高",
"ㄒㄧㄝㄍㄡ":"薢茩",
"ㄒㄧㄝㄍㄣ":"鞋跟",
"ㄒㄧㄝㄍㄨ":"嶰谷/澥谷",
"ㄒㄧㄝㄎㄜ":"謝客",
"ㄒㄧㄝㄎㄨ":"血庫",
"ㄒㄧㄝㄏㄜ":"協和/諧和",
"ㄒㄧㄝㄏㄞ":"血海",
"ㄒㄧㄝㄏㄡ":"邂逅",
"ㄒㄧㄝㄏㄢ":"血汗/謝函",
"ㄒㄧㄝㄏㄣ":"洩恨/挾恨",
"ㄒㄧㄝㄏㄤ":"頡頏",
"ㄒㄧㄝㄐㄧ":"血跡/血祭",
"ㄒㄧㄝㄐㄩ":"諧劇/絜矩",
"ㄒㄧㄝㄑㄧ":"洩氣/邪氣",
"ㄒㄧㄝㄑㄩ":"諧趣/邪曲",
"ㄒㄧㄝㄒㄧ":"歇息/血洗",
"ㄒㄧㄝㄒㄩ":"血虛/些許",
"ㄒㄧㄝㄓㄞ":"血債",
"ㄒㄧㄝㄓㄠ":"寫照/斜照",
"ㄒㄧㄝㄓㄢ":"血戰",
"ㄒㄧㄝㄓㄣ":"寫真",
"ㄒㄧㄝㄓㄤ":"挾長",
"ㄒㄧㄝㄓㄨ":"協助",
"ㄒㄧㄝㄔㄣ":"謝忱/邪臣",
"ㄒㄧㄝㄔㄨ":"卸除/歇處",
"ㄒㄧㄝㄕㄜ":"斜射",
"ㄒㄧㄝㄕㄡ":"歇手",
"ㄒㄧㄝㄕㄢ":"鞋扇",
"ㄒㄧㄝㄕㄣ":"謝神/邪神",
"ㄒㄧㄝㄕㄤ":"協商/歇晌",
"ㄒㄧㄝㄕㄥ":"寫生/諧聲",
"ㄒㄧㄝㄕㄨ":"寫書/血書",
"ㄒㄧㄝㄖㄡ":"血肉",
"ㄒㄧㄝㄖㄣ":"卸任/血刃",
"ㄒㄧㄝㄗㄜ":"卸責",
"ㄒㄧㄝㄗㄨ":"歇足",
"ㄒㄧㄝㄘㄞ":"鮭菜",
"ㄒㄧㄝㄘㄠ":"纈草",
"ㄒㄧㄝㄙㄜ":"血色",
"ㄒㄧㄝㄙㄨ":"歇宿/屑窣",
"ㄒㄧㄝㄧㄚ":"血壓/洩壓",
"ㄒㄧㄝㄧㄝ":"血液/歇業",
"ㄒㄧㄝㄧㄠ":"瀉藥",
"ㄒㄧㄝㄧㄡ":"鞋油/燮友",
"ㄒㄧㄝㄧㄢ":"斜眼/瀉鹽",
"ㄒㄧㄝㄧㄣ":"諧音/血印",
"ㄒㄧㄝㄧㄤ":"斜陽",
"ㄒㄧㄝㄨㄔ":"血汙池",
"ㄒㄧㄝㄨㄟ":"些微/邪偽",
"ㄒㄧㄝㄨㄢ":"褻玩",
"ㄒㄧㄝㄨㄣ":"斜紋",
"ㄒㄧㄝㄩㄝ":"協約",
"ㄒㄧㄝㄩㄢ":"血緣",
"ㄒㄧㄝㄩㄣ":"協韻",
"ㄒㄧㄝㄩㄥ":"械用",
"ㄒㄧㄠㄅㄞ":"小白/孝白",
"ㄒㄧㄠㄅㄟ":"小輩",
"ㄒㄧㄠㄅㄠ":"小報",
"ㄒㄧㄠㄅㄢ":"小班/小半",
"ㄒㄧㄠㄅㄤ":"囂謗",
"ㄒㄧㄠㄅㄧ":"小毖",
"ㄒㄧㄠㄅㄨ":"小補/孝布",
"ㄒㄧㄠㄆㄠ":"孝袍",
"ㄒㄧㄠㄆㄢ":"小盤/小弁",
"ㄒㄧㄠㄆㄧ":"曉譬",
"ㄒㄧㄠㄇㄚ":"笑罵",
"ㄒㄧㄠㄇㄛ":"消磨/小末",
"ㄒㄧㄠㄇㄞ":"小麥/小賣",
"ㄒㄧㄠㄇㄟ":"小妹",
"ㄒㄧㄠㄇㄠ":"小毛/小帽",
"ㄒㄧㄠㄇㄢ":"小滿/小蠻",
"ㄒㄧㄠㄇㄧ":"消弭/小米",
"ㄒㄧㄠㄇㄨ":"酵母",
"ㄒㄧㄠㄈㄚ":"效法/消乏",
"ㄒㄧㄠㄈㄟ":"消費/小費",
"ㄒㄧㄠㄈㄢ":"小販/小范",
"ㄒㄧㄠㄈㄣ":"宵分",
"ㄒㄧㄠㄈㄤ":"消防/校方",
"ㄒㄧㄠㄈㄥ":"校風/小蜂",
"ㄒㄧㄠㄈㄨ":"校服/小婦",
"ㄒㄧㄠㄉㄜ":"曉得/小的",
"ㄒㄧㄠㄉㄞ":"小戴",
"ㄒㄧㄠㄉㄠ":"孝道/小道",
"ㄒㄧㄠㄉㄢ":"小旦",
"ㄒㄧㄠㄉㄧ":"小弟",
"ㄒㄧㄠㄉㄨ":"消毒/小杜",
"ㄒㄧㄠㄊㄡ":"小偷/小頭",
"ㄒㄧㄠㄊㄢ":"笑談",
"ㄒㄧㄠㄊㄤ":"孝堂",
"ㄒㄧㄠㄊㄥ":"驍騰",
"ㄒㄧㄠㄊㄧ":"孝弟/孝悌",
"ㄒㄧㄠㄋㄚ":"笑納",
"ㄒㄧㄠㄋㄠ":"小腦/囂鬧",
"ㄒㄧㄠㄋㄢ":"孝男",
"ㄒㄧㄠㄋㄥ":"效能",
"ㄒㄧㄠㄋㄩ":"小女/孝女",
"ㄒㄧㄠㄌㄟ":"宵類",
"ㄒㄧㄠㄌㄠ":"小老/效勞",
"ㄒㄧㄠㄌㄤ":"小郎/蕭郎",
"ㄒㄧㄠㄌㄧ":"效力/小利",
"ㄒㄧㄠㄌㄨ":"小路/銷路",
"ㄒㄧㄠㄌㄩ":"效率/小呂",
"ㄒㄧㄠㄍㄜ":"小哥/校歌",
"ㄒㄧㄠㄍㄢ":"宵旰",
"ㄒㄧㄠㄍㄨ":"小姑/小故",
"ㄒㄧㄠㄎㄜ":"小可/消渴",
"ㄒㄧㄠㄎㄞ":"小開/小楷",
"ㄒㄧㄠㄎㄠ":"小考",
"ㄒㄧㄠㄎㄡ":"小口",
"ㄒㄧㄠㄎㄢ":"校刊/小看",
"ㄒㄧㄠㄎㄤ":"小康",
"ㄒㄧㄠㄏㄜ":"蕭何",
"ㄒㄧㄠㄏㄞ":"小孩",
"ㄒㄧㄠㄏㄠ":"消耗/小號",
"ㄒㄧㄠㄏㄡ":"哮吼",
"ㄒㄧㄠㄏㄢ":"小寒/霄漢",
"ㄒㄧㄠㄏㄨ":"小戶/哮虎",
"ㄒㄧㄠㄐㄧ":"校際/消極",
"ㄒㄧㄠㄐㄩ":"笑劇/嘯聚",
"ㄒㄧㄠㄑㄧ":"小器/小氣",
"ㄒㄧㄠㄑㄩ":"小曲/小覷",
"ㄒㄧㄠㄒㄧ":"消息/小喜",
"ㄒㄧㄠㄒㄩ":"小序/小畜",
"ㄒㄧㄠㄓㄚ":"嘵咋",
"ㄒㄧㄠㄓㄞ":"蕭齋",
"ㄒㄧㄠㄓㄠ":"小照",
"ㄒㄧㄠㄓㄢ":"小站",
"ㄒㄧㄠㄓㄤ":"校長/囂張",
"ㄒㄧㄠㄓㄥ":"宵征",
"ㄒㄧㄠㄓㄨ":"小住/小注",
"ㄒㄧㄠㄔㄜ":"小車",
"ㄒㄧㄠㄔㄞ":"銷差",
"ㄒㄧㄠㄔㄠ":"小潮/小抄",
"ㄒㄧㄠㄔㄡ":"小丑/消愁",
"ㄒㄧㄠㄔㄢ":"小產",
"ㄒㄧㄠㄔㄣ":"消沉/小臣",
"ㄒㄧㄠㄔㄤ":"笑場/小唱",
"ㄒㄧㄠㄔㄥ":"小成/效誠",
"ㄒㄧㄠㄔㄨ":"消除",
"ㄒㄧㄠㄕㄚ":"笑殺",
"ㄒㄧㄠㄕㄜ":"校舍/小舌",
"ㄒㄧㄠㄕㄠ":"簫韶/蠨蛸",
"ㄒㄧㄠㄕㄡ":"銷售/小手",
"ㄒㄧㄠㄕㄥ":"小生/小聖",
"ㄒㄧㄠㄕㄨ":"小數/消暑",
"ㄒㄧㄠㄖㄗ":"小日子",
"ㄒㄧㄠㄖㄢ":"蕭然/囂然",
"ㄒㄧㄠㄖㄣ":"小人/曉人",
"ㄒㄧㄠㄖㄤ":"霄壤",
"ㄒㄧㄠㄗㄚ":"囂雜",
"ㄒㄧㄠㄗㄞ":"消災",
"ㄒㄧㄠㄗㄟ":"小賊",
"ㄒㄧㄠㄗㄠ":"小灶",
"ㄒㄧㄠㄗㄨ":"小組/小卒",
"ㄒㄧㄠㄘㄞ":"小菜/小財",
"ㄒㄧㄠㄘㄠ":"小草/蕭曹",
"ㄒㄧㄠㄘㄢ":"篠驂",
"ㄒㄧㄠㄙㄚ":"瀟灑/蕭灑",
"ㄒㄧㄠㄙㄜ":"曉色/蕭瑟",
"ㄒㄧㄠㄙㄢ":"消散/蕭散",
"ㄒㄧㄠㄙㄣ":"蕭森",
"ㄒㄧㄠㄙㄤ":"小嗓",
"ㄒㄧㄠㄙㄥ":"小僧",
"ㄒㄧㄠㄙㄨ":"小蘇/酵素",
"ㄒㄧㄠㄧㄗ":"小姨子",
"ㄒㄧㄠㄧㄙ":"小意思",
"ㄒㄧㄠㄧㄚ":"小雅",
"ㄒㄧㄠㄧㄝ":"宵夜/小葉",
"ㄒㄧㄠㄧㄠ":"逍遙/消遙",
"ㄒㄧㄠㄧㄡ":"校友/小友",
"ㄒㄧㄠㄧㄢ":"小言/效驗",
"ㄒㄧㄠㄧㄣ":"小引/小印",
"ㄒㄧㄠㄧㄤ":"小樣/小洋",
"ㄒㄧㄠㄧㄥ":"效應/小影",
"ㄒㄧㄠㄨㄛ":"小我/笑渦",
"ㄒㄧㄠㄨㄟ":"驍衛",
"ㄒㄧㄠㄨㄢ":"小頑/小宛",
"ㄒㄧㄠㄨㄧ":"小五義",
"ㄒㄧㄠㄩㄝ":"小月/小月",
"ㄒㄧㄠㄩㄢ":"校園/小圓",
"ㄒㄧㄠㄩㄣ":"小運/校運",
"ㄒㄧㄠㄩㄥ":"效用/驍勇",
"ㄒㄧㄡㄅㄚ":"秀拔",
"ㄒㄧㄡㄅㄞ":"朽敗",
"ㄒㄧㄡㄅㄟ":"繡被",
"ㄒㄧㄡㄅㄢ":"修版",
"ㄒㄧㄡㄅㄨ":"修補/繡補",
"ㄒㄧㄡㄆㄨ":"修譜",
"ㄒㄧㄡㄇㄛ":"繡陌",
"ㄒㄧㄡㄇㄞ":"朽邁",
"ㄒㄧㄡㄇㄟ":"秀美/修眉",
"ㄒㄧㄡㄇㄨ":"修墓/修睦",
"ㄒㄧㄡㄈㄚ":"秀髮/秀發",
"ㄒㄧㄡㄈㄛ":"繡佛",
"ㄒㄧㄡㄈㄣ":"羞憤",
"ㄒㄧㄡㄈㄤ":"繡房",
"ㄒㄧㄡㄈㄨ":"修復/脩脯",
"ㄒㄧㄡㄉㄠ":"修道",
"ㄒㄧㄡㄉㄡ":"秀逗",
"ㄒㄧㄡㄉㄨ":"朽蠹",
"ㄒㄧㄡㄊㄠ":"袖套",
"ㄒㄧㄡㄊㄧ":"休提",
"ㄒㄧㄡㄊㄨ":"休屠",
"ㄒㄧㄡㄋㄠ":"羞惱",
"ㄒㄧㄡㄋㄢ":"羞赧",
"ㄒㄧㄡㄋㄨ":"羞怒",
"ㄒㄧㄡㄋㄩ":"修女/秀女",
"ㄒㄧㄡㄌㄢ":"朽爛",
"ㄒㄧㄡㄌㄧ":"修理/秀麗",
"ㄒㄧㄡㄌㄨ":"修路",
"ㄒㄧㄡㄍㄜ":"繡閣",
"ㄒㄧㄡㄍㄞ":"修改/修蓋",
"ㄒㄧㄡㄍㄥ":"休耕",
"ㄒㄧㄡㄎㄜ":"休克",
"ㄒㄧㄡㄎㄡ":"袖口/袖扣",
"ㄒㄧㄡㄎㄢ":"休刊",
"ㄒㄧㄡㄏㄜ":"修和",
"ㄒㄧㄡㄏㄠ":"修好",
"ㄒㄧㄡㄏㄢ":"修函",
"ㄒㄧㄡㄏㄨ":"修護/繡戶",
"ㄒㄧㄡㄐㄧ":"修己",
"ㄒㄧㄡㄑㄧ":"休憩/修葺",
"ㄒㄧㄡㄑㄩ":"繡镼",
"ㄒㄧㄡㄒㄧ":"休息/修習",
"ㄒㄧㄡㄓㄞ":"修齋",
"ㄒㄧㄡㄓㄢ":"休戰",
"ㄒㄧㄡㄓㄣ":"休診/袖珍",
"ㄒㄧㄡㄓㄤ":"袖章",
"ㄒㄧㄡㄓㄥ":"修正/修整",
"ㄒㄧㄡㄓㄨ":"修竹/修築",
"ㄒㄧㄡㄔㄤ":"修長/秀場",
"ㄒㄧㄡㄔㄨ":"秀出/休屠",
"ㄒㄧㄡㄕㄡ":"袖手",
"ㄒㄧㄡㄕㄢ":"修繕",
"ㄒㄧㄡㄕㄣ":"修身",
"ㄒㄧㄡㄕㄨ":"修書/休書",
"ㄒㄧㄡㄖㄣ":"羞人/袖刃",
"ㄒㄧㄡㄖㄤ":"朽壤",
"ㄒㄧㄡㄖㄨ":"羞辱",
"ㄒㄧㄡㄗㄠ":"修造",
"ㄒㄧㄡㄗㄨ":"修阻",
"ㄒㄧㄡㄘㄞ":"秀才",
"ㄒㄧㄡㄘㄢ":"羞慚",
"ㄒㄧㄡㄙㄜ":"羞澀",
"ㄒㄧㄡㄧㄚ":"秀雅",
"ㄒㄧㄡㄧㄝ":"修業/休業",
"ㄒㄧㄡㄧㄢ":"繡眼",
"ㄒㄧㄡㄧㄤ":"修養/休養",
"ㄒㄧㄡㄨㄟ":"臭味",
"ㄒㄧㄡㄨㄣ":"修文",
"ㄒㄧㄡㄩㄝ":"脩樾",
"ㄒㄧㄢㄅㄟ":"先輩/銜杯",
"ㄒㄧㄢㄅㄠ":"獻寶",
"ㄒㄧㄢㄅㄢ":"仙班/蘚斑",
"ㄒㄧㄢㄅㄤ":"限棒",
"ㄒㄧㄢㄅㄧ":"先妣/顯妣",
"ㄒㄧㄢㄅㄨ":"閒步",
"ㄒㄧㄢㄆㄞ":"憲牌",
"ㄒㄧㄢㄆㄟ":"銜轡",
"ㄒㄧㄢㄆㄨ":"獻曝",
"ㄒㄧㄢㄇㄚ":"線麻/洗馬",
"ㄒㄧㄢㄇㄛ":"陷沒/涎沫",
"ㄒㄧㄢㄇㄞ":"現賣",
"ㄒㄧㄢㄇㄟ":"獻媚/鮮美",
"ㄒㄧㄢㄇㄠ":"纖毛/仙茅",
"ㄒㄧㄢㄇㄣ":"舷門",
"ㄒㄧㄢㄇㄧ":"秈米/纖密",
"ㄒㄧㄢㄇㄨ":"羨慕/先母",
"ㄒㄧㄢㄈㄚ":"憲法/先發",
"ㄒㄧㄢㄈㄟ":"賢妃",
"ㄒㄧㄢㄈㄢ":"嫌犯/掀翻",
"ㄒㄧㄢㄈㄤ":"仙方/閒房",
"ㄒㄧㄢㄈㄥ":"先鋒/險峰",
"ㄒㄧㄢㄈㄨ":"先父/先夫",
"ㄒㄧㄢㄉㄚ":"先達/顯達",
"ㄒㄧㄢㄉㄜ":"顯得/先德",
"ㄒㄧㄢㄉㄞ":"現代",
"ㄒㄧㄢㄉㄠ":"先導/線道",
"ㄒㄧㄢㄉㄢ":"鹹蛋/仙丹",
"ㄒㄧㄢㄉㄤ":"閒蕩",
"ㄒㄧㄢㄉㄥ":"先登",
"ㄒㄧㄢㄉㄧ":"閒地/險地",
"ㄒㄧㄢㄉㄨ":"限度",
"ㄒㄧㄢㄊㄞ":"獻臺/憲臺",
"ㄒㄧㄢㄊㄠ":"仙桃",
"ㄒㄧㄢㄊㄡ":"先頭/線頭",
"ㄒㄧㄢㄊㄢ":"閒談/險灘",
"ㄒㄧㄢㄊㄤ":"線膛",
"ㄒㄧㄢㄊㄥ":"掀騰",
"ㄒㄧㄢㄊㄧ":"腺體/舷梯",
"ㄒㄧㄢㄊㄨ":"險途",
"ㄒㄧㄢㄋㄞ":"鮮奶",
"ㄒㄧㄢㄋㄣ":"鮮嫩",
"ㄒㄧㄢㄋㄥ":"賢能",
"ㄒㄧㄢㄋㄧ":"陷溺",
"ㄒㄧㄢㄋㄨ":"幰弩",
"ㄒㄧㄢㄋㄩ":"仙女",
"ㄒㄧㄢㄌㄜ":"銜勒",
"ㄒㄧㄢㄌㄞ":"先來",
"ㄒㄧㄢㄌㄟ":"蘚類",
"ㄒㄧㄢㄌㄠ":"賢勞",
"ㄒㄧㄢㄌㄤ":"仙郎/賢郎",
"ㄒㄧㄢㄌㄧ":"先例/獻禮",
"ㄒㄧㄢㄌㄨ":"顯露/線路",
"ㄒㄧㄢㄍㄜ":"弦歌/絃歌",
"ㄒㄧㄢㄍㄠ":"弦高",
"ㄒㄧㄢㄍㄤ":"憲綱",
"ㄒㄧㄢㄍㄨ":"先古/險固",
"ㄒㄧㄢㄎㄜ":"閒可/仙客",
"ㄒㄧㄢㄎㄞ":"掀開",
"ㄒㄧㄢㄎㄠ":"先考/顯考",
"ㄒㄧㄢㄎㄥ":"陷坑",
"ㄒㄧㄢㄏㄜ":"先河/顯赫",
"ㄒㄧㄢㄏㄞ":"陷害/仙海",
"ㄒㄧㄢㄏㄠ":"纖毫",
"ㄒㄧㄢㄏㄡ":"先後/先後",
"ㄒㄧㄢㄏㄢ":"閒漢",
"ㄒㄧㄢㄏㄣ":"銜恨",
"ㄒㄧㄢㄏㄨ":"鹹湖",
"ㄒㄧㄢㄐㄧ":"先機/仙機",
"ㄒㄧㄢㄐㄩ":"險句/閒居",
"ㄒㄧㄢㄑㄧ":"嫌棄/掀起",
"ㄒㄧㄢㄑㄩ":"先驅/孅趨",
"ㄒㄧㄢㄒㄧ":"纖細/險戲",
"ㄒㄧㄢㄒㄩ":"先緒/閒敘",
"ㄒㄧㄢㄓㄘ":"限制詞",
"ㄒㄧㄢㄓㄚ":"險詐",
"ㄒㄧㄢㄓㄜ":"顯著/先哲",
"ㄒㄧㄢㄓㄠ":"先兆",
"ㄒㄧㄢㄓㄡ":"筅帚",
"ㄒㄧㄢㄓㄣ":"仙真/陷陣",
"ㄒㄧㄢㄓㄤ":"憲章/縣長",
"ㄒㄧㄢㄓㄥ":"憲政/先正",
"ㄒㄧㄢㄓㄨ":"顯著/先主",
"ㄒㄧㄢㄔㄚ":"獻茶",
"ㄒㄧㄢㄔㄜ":"陷車/閒扯",
"ㄒㄧㄢㄔㄠ":"現鈔",
"ㄒㄧㄢㄔㄡ":"獻醜/先疇",
"ㄒㄧㄢㄔㄢ":"限產",
"ㄒㄧㄢㄔㄣ":"先臣/賢臣",
"ㄒㄧㄢㄔㄤ":"現場/閑常",
"ㄒㄧㄢㄔㄥ":"現成/見成",
"ㄒㄧㄢㄔㄨ":"顯出/現出",
"ㄒㄧㄢㄕㄚ":"羨煞",
"ㄒㄧㄢㄕㄠ":"鮮少",
"ㄒㄧㄢㄕㄡ":"先手/纖手",
"ㄒㄧㄢㄕㄣ":"現身/獻身",
"ㄒㄧㄢㄕㄤ":"銜觴",
"ㄒㄧㄢㄕㄥ":"先生/先聲",
"ㄒㄧㄢㄕㄨ":"嫻熟/賢淑",
"ㄒㄧㄢㄖㄡ":"鮮肉/纖柔",
"ㄒㄧㄢㄖㄢ":"顯然/洗然",
"ㄒㄧㄢㄖㄣ":"現任/線人",
"ㄒㄧㄢㄖㄨ":"陷入/洒如",
"ㄒㄧㄢㄗㄜ":"先澤/憲則",
"ㄒㄧㄢㄗㄞ":"現在/見在",
"ㄒㄧㄢㄗㄥ":"嫌憎",
"ㄒㄧㄢㄗㄨ":"先祖/跣足",
"ㄒㄧㄢㄘㄜ":"獻策/先策",
"ㄒㄧㄢㄘㄞ":"鹹菜/線材",
"ㄒㄧㄢㄘㄠ":"仙草/仙曹",
"ㄒㄧㄢㄘㄢ":"先蠶",
"ㄒㄧㄢㄙㄜ":"險塞/嶮澀",
"ㄒㄧㄢㄙㄢ":"閒散",
"ㄒㄧㄢㄧㄚ":"閑雅/嫻雅",
"ㄒㄧㄢㄧㄠ":"險要/纖腰",
"ㄒㄧㄢㄧㄡ":"仙遊/閒遊",
"ㄒㄧㄢㄧㄢ":"顯眼/先嚴",
"ㄒㄧㄢㄧㄣ":"縣尹",
"ㄒㄧㄢㄧㄤ":"現洋/顯揚",
"ㄒㄧㄢㄧㄥ":"先塋/顯應",
"ㄒㄧㄢㄨㄟ":"纖維/鮮味",
"ㄒㄧㄢㄨㄣ":"弦紋",
"ㄒㄧㄢㄨㄤ":"先王/憲網",
"ㄒㄧㄢㄩㄝ":"弦樂/閒月",
"ㄒㄧㄢㄩㄢ":"仙源/嫌怨",
"ㄒㄧㄢㄩㄣ":"限韻/險韻",
"ㄒㄧㄣㄅㄛ":"心波/心搏",
"ㄒㄧㄣㄅㄞ":"新敗/鋅白",
"ㄒㄧㄣㄅㄢ":"心版/新版",
"ㄒㄧㄣㄅㄧ":"信筆",
"ㄒㄧㄣㄅㄨ":"信步",
"ㄒㄧㄣㄆㄞ":"新派",
"ㄒㄧㄣㄆㄠ":"信炮",
"ㄒㄧㄣㄆㄢ":"辛盤",
"ㄒㄧㄣㄆㄧ":"心皮/心脾",
"ㄒㄧㄣㄇㄠ":"新貌",
"ㄒㄧㄣㄇㄣ":"囟門",
"ㄒㄧㄣㄇㄤ":"心盲/新莽",
"ㄒㄧㄣㄇㄨ":"心目/歆慕",
"ㄒㄧㄣㄈㄚ":"心法/新法",
"ㄒㄧㄣㄈㄟ":"心扉",
"ㄒㄧㄣㄈㄢ":"心煩",
"ㄒㄧㄣㄈㄣ":"新墳",
"ㄒㄧㄣㄈㄤ":"心房/心防",
"ㄒㄧㄣㄈㄥ":"信封/信奉",
"ㄒㄧㄣㄈㄨ":"心腹/心服",
"ㄒㄧㄣㄉㄜ":"心得",
"ㄒㄧㄣㄉㄞ":"信貸/心歹",
"ㄒㄧㄣㄉㄠ":"信道",
"ㄒㄧㄣㄉㄢ":"心膽",
"ㄒㄧㄣㄉㄧ":"心底/心地",
"ㄒㄧㄣㄊㄞ":"心態/新臺",
"ㄒㄧㄣㄊㄡ":"心頭",
"ㄒㄧㄣㄊㄥ":"心疼",
"ㄒㄧㄣㄊㄨ":"信徒/心土",
"ㄒㄧㄣㄋㄤ":"心囊",
"ㄒㄧㄣㄋㄩ":"信女",
"ㄒㄧㄣㄌㄚ":"辛辣",
"ㄒㄧㄣㄌㄞ":"信賴/新來",
"ㄒㄧㄣㄌㄠ":"辛勞",
"ㄒㄧㄣㄌㄤ":"新郎",
"ㄒㄧㄣㄌㄥ":"心冷",
"ㄒㄧㄣㄌㄧ":"心理/心裡",
"ㄒㄧㄣㄌㄨ":"心路",
"ㄒㄧㄣㄌㄩ":"心律/心膂",
"ㄒㄧㄣㄍㄜ":"信鴿",
"ㄒㄧㄣㄍㄠ":"心高",
"ㄒㄧㄣㄍㄢ":"心肝",
"ㄒㄧㄣㄍㄨ":"信鼓/釁鼓",
"ㄒㄧㄣㄎㄜ":"新科",
"ㄒㄧㄣㄎㄡ":"心口/信口",
"ㄒㄧㄣㄎㄢ":"心坎",
"ㄒㄧㄣㄎㄨ":"辛苦",
"ㄒㄧㄣㄏㄠ":"信號",
"ㄒㄧㄣㄏㄢ":"心寒/心汗",
"ㄒㄧㄣㄏㄣ":"心狠/新恨",
"ㄒㄧㄣㄐㄧ":"心機/心急",
"ㄒㄧㄣㄐㄩ":"新句/新劇",
"ㄒㄧㄣㄑㄧ":"新奇/心氣",
"ㄒㄧㄣㄑㄩ":"心曲",
"ㄒㄧㄣㄒㄧ":"信息/欣喜",
"ㄒㄧㄣㄒㄩ":"心虛/心許",
"ㄒㄧㄣㄓㄚ":"信札",
"ㄒㄧㄣㄓㄜ":"心折/辛螫",
"ㄒㄧㄣㄓㄞ":"心齋/心窄",
"ㄒㄧㄣㄓㄠ":"心照",
"ㄒㄧㄣㄓㄢ":"心戰",
"ㄒㄧㄣㄓㄥ":"心證/新正",
"ㄒㄧㄣㄓㄨ":"新竹",
"ㄒㄧㄣㄔㄞ":"信差",
"ㄒㄧㄣㄔㄠ":"新潮/心潮",
"ㄒㄧㄣㄔㄡ":"薪酬/新愁",
"ㄒㄧㄣㄔㄢ":"新蟾",
"ㄒㄧㄣㄔㄣ":"信臣",
"ㄒㄧㄣㄔㄤ":"心腸",
"ㄒㄧㄣㄕㄡ":"新手/信手",
"ㄒㄧㄣㄕㄢ":"心善",
"ㄒㄧㄣㄕㄣ":"心神",
"ㄒㄧㄣㄕㄤ":"欣賞/心上",
"ㄒㄧㄣㄕㄥ":"新生/心聲",
"ㄒㄧㄣㄕㄨ":"心數/心術",
"ㄒㄧㄣㄖㄜ":"心熱",
"ㄒㄧㄣㄖㄢ":"欣然/信然",
"ㄒㄧㄣㄖㄣ":"信任/新人",
"ㄒㄧㄣㄗㄤ":"心臟",
"ㄒㄧㄣㄘㄞ":"心材/心裁",
"ㄒㄧㄣㄙㄜ":"新色",
"ㄒㄧㄣㄙㄤ":"心喪",
"ㄒㄧㄣㄙㄨ":"信宿",
"ㄒㄧㄣㄢㄧ":"辛安驛",
"ㄒㄧㄣㄧㄚ":"新芽",
"ㄒㄧㄣㄧㄠ":"心藥",
"ㄒㄧㄣㄧㄡ":"心遊",
"ㄒㄧㄣㄧㄢ":"心眼",
"ㄒㄧㄣㄧㄣ":"心音/心印",
"ㄒㄧㄣㄧㄤ":"信仰/新樣",
"ㄒㄧㄣㄧㄥ":"新穎/心影",
"ㄒㄧㄣㄨㄛ":"心窩",
"ㄒㄧㄣㄨㄟ":"欣慰",
"ㄒㄧㄣㄨㄣ":"新聞/新文",
"ㄒㄧㄣㄩㄝ":"新月/欣躍",
"ㄒㄧㄣㄩㄢ":"心願/新垣",
"ㄒㄧㄣㄩㄥ":"信用",
"ㄒㄧㄤㄅㄛ":"香帛/相搏",
"ㄒㄧㄤㄅㄟ":"相背/向背",
"ㄒㄧㄤㄅㄠ":"香包/相保",
"ㄒㄧㄤㄅㄢ":"相伴/響板",
"ㄒㄧㄤㄅㄤ":"相幫",
"ㄒㄧㄤㄅㄧ":"相比/相比",
"ㄒㄧㄤㄅㄨ":"相簿/翔步",
"ㄒㄧㄤㄆㄞ":"響排/項牌",
"ㄒㄧㄤㄆㄟ":"相配/相陪",
"ㄒㄧㄤㄆㄢ":"向盤",
"ㄒㄧㄤㄆㄧ":"橡皮/鄉僻",
"ㄒㄧㄤㄆㄨ":"香鋪/香蒲",
"ㄒㄧㄤㄇㄚ":"相馬/相罵",
"ㄒㄧㄤㄇㄛ":"巷陌",
"ㄒㄧㄤㄇㄠ":"相貌/香茅",
"ㄒㄧㄤㄇㄥ":"詳夢",
"ㄒㄧㄤㄇㄧ":"詳密",
"ㄒㄧㄤㄇㄨ":"項目/想慕",
"ㄒㄧㄤㄈㄚ":"想法/相法",
"ㄒㄧㄤㄈㄟ":"香妃/向非",
"ㄒㄧㄤㄈㄢ":"相反/相煩",
"ㄒㄧㄤㄈㄣ":"香分/香粉",
"ㄒㄧㄤㄈㄤ":"香房/鄉方",
"ㄒㄧㄤㄈㄥ":"相逢/相風",
"ㄒㄧㄤㄈㄨ":"享福/相符",
"ㄒㄧㄤㄉㄚ":"相打",
"ㄒㄧㄤㄉㄜ":"相得",
"ㄒㄧㄤㄉㄞ":"香袋/相待",
"ㄒㄧㄤㄉㄠ":"嚮導/香稻",
"ㄒㄧㄤㄉㄡ":"香斗/橡斗",
"ㄒㄧㄤㄉㄤ":"相當/向當",
"ㄒㄧㄤㄉㄥ":"相等/香燈",
"ㄒㄧㄤㄉㄧ":"相抵/箱底",
"ㄒㄧㄤㄉㄨ":"響度/象度",
"ㄒㄧㄤㄊㄚ":"響搨",
"ㄒㄧㄤㄊㄞ":"相臺/像態",
"ㄒㄧㄤㄊㄡ":"想頭/香頭",
"ㄒㄧㄤㄊㄢ":"鄉談/詳談",
"ㄒㄧㄤㄊㄤ":"香湯/響糖",
"ㄒㄧㄤㄊㄥ":"驤騰",
"ㄒㄧㄤㄊㄧ":"相體/象揥",
"ㄒㄧㄤㄊㄨ":"鄉土",
"ㄒㄧㄤㄋㄤ":"香囊",
"ㄒㄧㄤㄌㄜ":"享樂",
"ㄒㄧㄤㄌㄞ":"向來",
"ㄒㄧㄤㄌㄟ":"相類/湘纍",
"ㄒㄧㄤㄌㄠ":"鄉老",
"ㄒㄧㄤㄌㄡ":"銗鏤",
"ㄒㄧㄤㄌㄧ":"鄉里/相離",
"ㄒㄧㄤㄌㄨ":"香爐",
"ㄒㄧㄤㄍㄜ":"相隔/鄉歌",
"ㄒㄧㄤㄍㄠ":"饗告",
"ㄒㄧㄤㄍㄢ":"相干/香乾",
"ㄒㄧㄤㄍㄣ":"箱根",
"ㄒㄧㄤㄍㄨ":"香菇/鄉故",
"ㄒㄧㄤㄎㄜ":"香客/相剋",
"ㄒㄧㄤㄎㄞ":"想開",
"ㄒㄧㄤㄎㄡ":"巷口",
"ㄒㄧㄤㄎㄢ":"相看/相看",
"ㄒㄧㄤㄎㄤ":"響炕",
"ㄒㄧㄤㄎㄨ":"巷哭",
"ㄒㄧㄤㄏㄜ":"祥和/相合",
"ㄒㄧㄤㄏㄠ":"相好/鄉豪",
"ㄒㄧㄤㄏㄡ":"向後/相厚",
"ㄒㄧㄤㄏㄢ":"香汗/箱涵",
"ㄒㄧㄤㄏㄨ":"相互/象笏",
"ㄒㄧㄤㄐㄧ":"相機/相繼",
"ㄒㄧㄤㄐㄩ":"相距/相聚",
"ㄒㄧㄤㄑㄧ":"象棋/香氣",
"ㄒㄧㄤㄑㄩ":"相去/相覷",
"ㄒㄧㄤㄒㄧ":"詳細/相襲",
"ㄒㄧㄤㄒㄩ":"庠序",
"ㄒㄧㄤㄓㄜ":"相者/曏者",
"ㄒㄧㄤㄓㄢ":"巷戰",
"ㄒㄧㄤㄓㄣ":"鄉鎮/響振",
"ㄒㄧㄤㄓㄤ":"香樟/鄉長",
"ㄒㄧㄤㄓㄥ":"象徵",
"ㄒㄧㄤㄓㄨ":"相助/香燭",
"ㄒㄧㄤㄔㄚ":"相差/香茶",
"ㄒㄧㄤㄔㄜ":"香車",
"ㄒㄧㄤㄔㄠ":"香巢",
"ㄒㄧㄤㄔㄡ":"鄉愁",
"ㄒㄧㄤㄔㄣ":"相趁/相襯",
"ㄒㄧㄤㄔㄤ":"香腸",
"ㄒㄧㄤㄔㄥ":"相稱/相稱",
"ㄒㄧㄤㄔㄨ":"相處",
"ㄒㄧㄤㄕㄜ":"香麝",
"ㄒㄧㄤㄕㄡ":"享受/享壽",
"ㄒㄧㄤㄕㄢ":"香山/香扇",
"ㄒㄧㄤㄕㄣ":"鄉紳/詳審",
"ㄒㄧㄤㄕㄤ":"向上/相商",
"ㄒㄧㄤㄕㄥ":"相聲/相生",
"ㄒㄧㄤㄕㄨ":"相熟/詳述",
"ㄒㄧㄤㄖㄠ":"相擾",
"ㄒㄧㄤㄖㄡ":"香肉",
"ㄒㄧㄤㄖㄢ":"香染",
"ㄒㄧㄤㄖㄣ":"相認/相人",
"ㄒㄧㄤㄖㄤ":"相讓",
"ㄒㄧㄤㄖㄥ":"相仍",
"ㄒㄧㄤㄖㄨ":"香薷",
"ㄒㄧㄤㄗㄜ":"香澤/薌澤",
"ㄒㄧㄤㄗㄠ":"香皂",
"ㄒㄧㄤㄗㄢ":"像贊",
"ㄒㄧㄤㄘㄞ":"香菜",
"ㄒㄧㄤㄘㄠ":"香草",
"ㄒㄧㄤㄙㄗ":"相思子",
"ㄒㄧㄤㄙㄘ":"相似詞",
"ㄒㄧㄤㄙㄜ":"香色",
"ㄒㄧㄤㄙㄞ":"香腮",
"ㄒㄧㄤㄙㄨ":"香酥/鄉俗",
"ㄒㄧㄤㄧㄗ":"香胰子",
"ㄒㄧㄤㄧㄚ":"象牙/鑲牙",
"ㄒㄧㄤㄧㄝ":"鄉野",
"ㄒㄧㄤㄧㄠ":"象窯/降妖",
"ㄒㄧㄤㄧㄡ":"享有/香油",
"ㄒㄧㄤㄧㄢ":"香煙/香豔",
"ㄒㄧㄤㄧㄣ":"鄉音/響音",
"ㄒㄧㄤㄧㄤ":"像樣/相羊",
"ㄒㄧㄤㄧㄥ":"相應/相應",
"ㄒㄧㄤㄨㄞ":"向外/象外",
"ㄒㄧㄤㄨㄟ":"香味/相為",
"ㄒㄧㄤㄨㄢ":"向晚",
"ㄒㄧㄤㄨㄣ":"詳文/象紋",
"ㄒㄧㄤㄨㄤ":"嚮往/想望",
"ㄒㄧㄤㄩㄝ":"相約/鄉約",
"ㄒㄧㄤㄩㄢ":"香願/香櫞",
"ㄒㄧㄤㄩㄣ":"祥雲",
"ㄒㄧㄤㄩㄥ":"享用/鄉勇",
"ㄒㄧㄥㄅㄢ":"興辦",
"ㄒㄧㄥㄅㄣ":"星奔",
"ㄒㄧㄥㄅㄤ":"興邦",
"ㄒㄧㄥㄅㄧ":"行筆",
"ㄒㄧㄥㄅㄨ":"刑部",
"ㄒㄧㄥㄆㄛ":"形魄",
"ㄒㄧㄥㄆㄢ":"星盤",
"ㄒㄧㄥㄆㄧ":"醒脾/刑辟",
"ㄒㄧㄥㄇㄚ":"星媽",
"ㄒㄧㄥㄇㄟ":"行媒/杏梅",
"ㄒㄧㄥㄇㄠ":"形貌/騂旄",
"ㄒㄧㄥㄇㄡ":"星眸",
"ㄒㄧㄥㄇㄥ":"幸蒙",
"ㄒㄧㄥㄇㄨ":"醒目/醒木",
"ㄒㄧㄥㄈㄚ":"刑罰/刑法",
"ㄒㄧㄥㄈㄟ":"興廢",
"ㄒㄧㄥㄈㄢ":"行飯/行販",
"ㄒㄧㄥㄈㄣ":"興奮/性分",
"ㄒㄧㄥㄈㄤ":"行房/行放",
"ㄒㄧㄥㄈㄥ":"興風/省風",
"ㄒㄧㄥㄈㄨ":"幸福/行復",
"ㄒㄧㄥㄉㄜ":"幸得/省得",
"ㄒㄧㄥㄉㄠ":"行道",
"ㄒㄧㄥㄉㄡ":"星斗",
"ㄒㄧㄥㄉㄥ":"行燈/星等",
"ㄒㄧㄥㄉㄧ":"性地",
"ㄒㄧㄥㄉㄨ":"行都/腥賭",
"ㄒㄧㄥㄊㄚ":"行踏",
"ㄒㄧㄥㄊㄞ":"形態/行臺",
"ㄒㄧㄥㄊㄡ":"行頭/興頭",
"ㄒㄧㄥㄊㄢ":"杏壇/星探",
"ㄒㄧㄥㄊㄤ":"行唐/餳糖",
"ㄒㄧㄥㄊㄥ":"興騰",
"ㄒㄧㄥㄊㄧ":"形體/性體",
"ㄒㄧㄥㄊㄨ":"星圖",
"ㄒㄧㄥㄋㄤ":"行囊",
"ㄒㄧㄥㄋㄥ":"性能/行能",
"ㄒㄧㄥㄌㄜ":"行樂",
"ㄒㄧㄥㄌㄞ":"行來",
"ㄒㄧㄥㄌㄠ":"行潦",
"ㄒㄧㄥㄌㄤ":"行郎/腥浪",
"ㄒㄧㄥㄌㄧ":"行李/行禮",
"ㄒㄧㄥㄌㄨ":"行路/行露",
"ㄒㄧㄥㄌㄩ":"行旅/行侶",
"ㄒㄧㄥㄍㄜ":"性格/行歌",
"ㄒㄧㄥㄍㄢ":"性感",
"ㄒㄧㄥㄍㄣ":"性根",
"ㄒㄧㄥㄍㄤ":"型鋼/騂剛",
"ㄒㄧㄥㄍㄥ":"鉶羹",
"ㄒㄧㄥㄍㄨ":"行賈",
"ㄒㄧㄥㄎㄜ":"行客/刑剋",
"ㄒㄧㄥㄎㄞ":"行開",
"ㄒㄧㄥㄎㄨ":"行哭",
"ㄒㄧㄥㄏㄜ":"興和/星河",
"ㄒㄧㄥㄏㄞ":"性海/形骸",
"ㄒㄧㄥㄏㄠ":"幸好/型號",
"ㄒㄧㄥㄏㄢ":"星漢",
"ㄒㄧㄥㄏㄨ":"行戶",
"ㄒㄧㄥㄐㄧ":"性急/星際",
"ㄒㄧㄥㄐㄩ":"行拘/性具",
"ㄒㄧㄥㄑㄧ":"星期/興起",
"ㄒㄧㄥㄑㄩ":"興趣/行取",
"ㄒㄧㄥㄒㄧ":"行息/星系",
"ㄒㄧㄥㄒㄩ":"興許",
"ㄒㄧㄥㄓㄚ":"行詐",
"ㄒㄧㄥㄓㄜ":"行者",
"ㄒㄧㄥㄓㄠ":"行照",
"ㄒㄧㄥㄓㄡ":"星洲/行舟",
"ㄒㄧㄥㄓㄣ":"行鍼",
"ㄒㄧㄥㄓㄤ":"行障/行杖",
"ㄒㄧㄥㄓㄥ":"行政/性徵",
"ㄒㄧㄥㄓㄨ":"行主/星主",
"ㄒㄧㄥㄔㄚ":"省察",
"ㄒㄧㄥㄔㄜ":"行車",
"ㄒㄧㄥㄔㄡ":"腥臭",
"ㄒㄧㄥㄔㄣ":"星辰/幸臣",
"ㄒㄧㄥㄔㄤ":"刑場/幸倡",
"ㄒㄧㄥㄔㄥ":"形成/行程",
"ㄒㄧㄥㄔㄨ":"行廚",
"ㄒㄧㄥㄕㄚ":"星鯊",
"ㄒㄧㄥㄕㄢ":"行善",
"ㄒㄧㄥㄕㄣ":"行神/幸甚",
"ㄒㄧㄥㄕㄤ":"行商/刑賞",
"ㄒㄧㄥㄕㄥ":"形聲/幸生",
"ㄒㄧㄥㄕㄨ":"行書/刑書",
"ㄒㄧㄥㄖㄢ":"幸然",
"ㄒㄧㄥㄖㄣ":"行人/刑人",
"ㄒㄧㄥㄗㄜ":"刑責",
"ㄒㄧㄥㄗㄞ":"行在",
"ㄒㄧㄥㄗㄠ":"行灶",
"ㄒㄧㄥㄗㄡ":"行走",
"ㄒㄧㄥㄗㄤ":"形藏",
"ㄒㄧㄥㄗㄨ":"星族/姓族",
"ㄒㄧㄥㄘㄞ":"行財/行菜",
"ㄒㄧㄥㄘㄤ":"行藏",
"ㄒㄧㄥㄙㄜ":"行色/性色",
"ㄒㄧㄥㄙㄠ":"腥臊",
"ㄒㄧㄥㄙㄢ":"行散/星散",
"ㄒㄧㄥㄙㄨ":"星宿/行速",
"ㄒㄧㄥㄧㄝ":"行業/行夜",
"ㄒㄧㄥㄧㄠ":"行藥/邢窯",
"ㄒㄧㄥㄧㄡ":"行遊",
"ㄒㄧㄥㄧㄢ":"杏眼/星眼",
"ㄒㄧㄥㄧㄣ":"行吟",
"ㄒㄧㄥㄧㄥ":"行營/形影",
"ㄒㄧㄥㄨㄛ":"行窩",
"ㄒㄧㄥㄨㄟ":"行為/行葦",
"ㄒㄧㄥㄨㄣ":"行文/刑問",
"ㄒㄧㄥㄨㄤ":"興旺/興亡",
"ㄒㄧㄥㄩㄝ":"杏月",
"ㄒㄧㄥㄩㄢ":"行轅/星院",
"ㄒㄧㄥㄩㄣ":"幸運/行雲",
"ㄒㄧㄧㄅㄢ":"洗衣板",
"ㄒㄧㄧㄈㄣ":"洗衣粉",
"ㄒㄧㄧㄐㄧ":"洗衣機",
"ㄒㄧㄧㄤㄕ":"西洋史",
"ㄒㄧㄩㄐㄧ":"西域記",
"ㄒㄧㄩㄢㄗ":"戲園子",
"ㄒㄩㄅㄧㄝ":"敘別",
"ㄒㄩㄅㄧㄥ":"恤病",
"ㄒㄩㄅㄨㄕ":"須不是",
"ㄒㄩㄆㄧㄣ":"續聘/虛牝",
"ㄒㄩㄇㄧㄢ":"勗勉",
"ㄒㄩㄇㄧㄥ":"虛名/虛明",
"ㄒㄩㄉㄧㄠ":"續貂",
"ㄒㄩㄉㄧㄢ":"蓄電/卹典",
"ㄒㄩㄉㄨㄛ":"許多",
"ㄒㄩㄉㄨㄢ":"續斷",
"ㄒㄩㄊㄠㄗ":"虛套子",
"ㄒㄩㄊㄨㄛ":"虛脫",
"ㄒㄩㄋㄧㄢ":"蓄念",
"ㄒㄩㄋㄧㄤ":"徐娘",
"ㄒㄩㄋㄨㄛ":"許諾",
"ㄒㄩㄌㄧㄝ":"序列",
"ㄒㄩㄌㄧㄡ":"須留",
"ㄒㄩㄌㄧㄥ":"徐陵",
"ㄒㄩㄌㄨㄛ":"墟落",
"ㄒㄩㄌㄨㄣ":"序論/緒論",
"ㄒㄩㄍㄨㄚ":"序卦/絮聒",
"ㄒㄩㄍㄨㄥ":"虛功/恤功",
"ㄒㄩㄎㄨㄛ":"敘闊",
"ㄒㄩㄎㄨㄤ":"獝狂/虛誑",
"ㄒㄩㄎㄨㄥ":"虛空",
"ㄒㄩㄏㄨㄚ":"虛話/敘話",
"ㄒㄩㄏㄨㄛ":"虛火/蓄火",
"ㄒㄩㄏㄨㄞ":"虛懷",
"ㄒㄩㄏㄨㄢ":"虛幻/徐緩",
"ㄒㄩㄏㄨㄣ":"許婚",
"ㄒㄩㄏㄨㄤ":"卹荒",
"ㄒㄩㄏㄨㄥ":"蓄洪",
"ㄒㄩㄐㄧㄚ":"虛假/續假",
"ㄒㄩㄐㄧㄝ":"慉結/虛竭",
"ㄒㄩㄐㄧㄠ":"虛驕",
"ㄒㄩㄐㄧㄡ":"酗酒/敘舊",
"ㄒㄩㄐㄧㄣ":"卹金",
"ㄒㄩㄐㄧㄤ":"旴江/敘獎",
"ㄒㄩㄐㄧㄥ":"虛驚/虛靜",
"ㄒㄩㄑㄧㄡ":"需求",
"ㄒㄩㄑㄧㄥ":"虛情",
"ㄒㄩㄑㄩㄝ":"虛怯",
"ㄒㄩㄑㄩㄢ":"繻券",
"ㄒㄩㄒㄧㄚ":"虛下",
"ㄒㄩㄒㄧㄝ":"虛邪/旭蟹",
"ㄒㄩㄒㄧㄠ":"虛囂/戌削",
"ㄒㄩㄒㄧㄢ":"虛線/續弦",
"ㄒㄩㄒㄧㄣ":"虛心",
"ㄒㄩㄒㄧㄤ":"虛像",
"ㄒㄩㄒㄧㄥ":"許行/徐行",
"ㄒㄩㄒㄩㄢ":"許宣/徐鉉",
"ㄒㄩㄒㄩㄣ":"敘勛",
"ㄒㄩㄓㄇㄛ":"徐志摩",
"ㄒㄩㄓㄡㄕ":"徐州市",
"ㄒㄩㄓㄨㄢ":"敘傳/虛賺",
"ㄒㄩㄔㄨㄢ":"虛船",
"ㄒㄩㄔㄨㄥ":"虛沖",
"ㄒㄩㄕㄐㄩ":"敘事句",
"ㄒㄩㄕㄑㄩ":"敘事曲",
"ㄒㄩㄕㄓㄤ":"序時帳",
"ㄒㄩㄕㄔㄤ":"徐世昌",
"ㄒㄩㄕㄨㄛ":"敘說",
"ㄒㄩㄕㄨㄞ":"勗率",
"ㄒㄩㄕㄨㄟ":"蓄水/漵水",
"ㄒㄩㄕㄨㄣ":"敘事文",
"ㄒㄩㄖㄨㄛ":"虛弱",
"ㄒㄩㄖㄨㄟ":"蓄銳",
"ㄒㄩㄖㄨㄥ":"虛榮",
"ㄒㄩㄗㄨㄛ":"虛左",
"ㄒㄩㄙㄨㄛ":"需索/須索",
"ㄒㄩㄙㄨㄟ":"虛歲",
"ㄒㄩㄙㄨㄥ":"酗訟",
"ㄒㄩㄝㄅㄠ":"學報/雪豹",
"ㄒㄩㄝㄅㄢ":"學伴",
"ㄒㄩㄝㄅㄥ":"雪崩",
"ㄒㄩㄝㄅㄧ":"削壁",
"ㄒㄩㄝㄅㄨ":"學部/學步",
"ㄒㄩㄝㄆㄞ":"學派",
"ㄒㄩㄝㄇㄛ":"踅摸",
"ㄒㄩㄝㄇㄤ":"雪盲",
"ㄒㄩㄝㄈㄚ":"學閥/削髮",
"ㄒㄩㄝㄈㄟ":"學費",
"ㄒㄩㄝㄈㄣ":"學分",
"ㄒㄩㄝㄈㄥ":"學風/學俸",
"ㄒㄩㄝㄈㄨ":"學府",
"ㄒㄩㄝㄉㄠ":"學道/穴道",
"ㄒㄩㄝㄉㄧ":"雪地/削地",
"ㄒㄩㄝㄉㄨ":"削牘",
"ㄒㄩㄝㄊㄞ":"學臺",
"ㄒㄩㄝㄊㄠ":"薛濤",
"ㄒㄩㄝㄊㄡ":"噱頭",
"ㄒㄩㄝㄊㄢ":"踅探",
"ㄒㄩㄝㄊㄤ":"學堂",
"ㄒㄩㄝㄊㄨ":"學徒",
"ㄒㄩㄝㄌㄧ":"學歷/學力",
"ㄒㄩㄝㄍㄠ":"雪糕",
"ㄒㄩㄝㄍㄨ":"雪姑",
"ㄒㄩㄝㄎㄜ":"學科",
"ㄒㄩㄝㄏㄞ":"學海",
"ㄒㄩㄝㄏㄠ":"學好/學號",
"ㄒㄩㄝㄏㄣ":"雪恨",
"ㄒㄩㄝㄐㄧ":"學記/學級",
"ㄒㄩㄝㄐㄩ":"穴居",
"ㄒㄩㄝㄑㄧ":"學期/吷氣",
"ㄒㄩㄝㄑㄩ":"學區",
"ㄒㄩㄝㄒㄧ":"學習/學習",
"ㄒㄩㄝㄓㄜ":"學者",
"ㄒㄩㄝㄓㄤ":"學長",
"ㄒㄩㄝㄓㄥ":"學政/削正",
"ㄒㄩㄝㄔㄜ":"雪車",
"ㄒㄩㄝㄔㄠ":"學潮",
"ㄒㄩㄝㄔㄥ":"學成/削成",
"ㄒㄩㄝㄔㄨ":"削除",
"ㄒㄩㄝㄕㄜ":"學舍/學舌",
"ㄒㄩㄝㄕㄢ":"雪山",
"ㄒㄩㄝㄕㄣ":"雪深",
"ㄒㄩㄝㄕㄥ":"學生",
"ㄒㄩㄝㄕㄨ":"學術/學塾",
"ㄒㄩㄝㄖㄣ":"學人/雪人",
"ㄒㄩㄝㄗㄨ":"學租",
"ㄒㄩㄝㄘㄠ":"削草",
"ㄒㄩㄝㄙㄜ":"削色",
"ㄒㄩㄝㄧㄝ":"學業/靴掖",
"ㄒㄩㄝㄧㄠ":"靴靿",
"ㄒㄩㄝㄧㄡ":"學友",
"ㄒㄩㄝㄧㄤ":"學養/學樣",
"ㄒㄩㄝㄨㄟ":"學位/穴位",
"ㄒㄩㄝㄨㄣ":"學問",
"ㄒㄩㄝㄩㄢ":"學院/學員",
"ㄒㄩㄝㄩㄣ":"學運",
"ㄒㄩㄢㄅㄚ":"選拔",
"ㄒㄩㄢㄅㄛ":"選播/懸薄",
"ㄒㄩㄢㄅㄠ":"玄豹",
"ㄒㄩㄢㄅㄣ":"選本",
"ㄒㄩㄢㄅㄧ":"宣筆/懸臂",
"ㄒㄩㄢㄅㄨ":"宣布",
"ㄒㄩㄢㄆㄞ":"選派/宣牌",
"ㄒㄩㄢㄆㄟ":"選配",
"ㄒㄩㄢㄆㄢ":"宣判",
"ㄒㄩㄢㄆㄧ":"旋辟/軒闢",
"ㄒㄩㄢㄆㄨ":"懸圃/懸瀑",
"ㄒㄩㄢㄇㄚ":"宣麻",
"ㄒㄩㄢㄇㄞ":"衒賣",
"ㄒㄩㄢㄇㄟ":"選美/軒眉",
"ㄒㄩㄢㄇㄡ":"玄謀",
"ㄒㄩㄢㄇㄣ":"懸門/玄門",
"ㄒㄩㄢㄇㄨ":"炫目/眩目",
"ㄒㄩㄢㄈㄚ":"懸法",
"ㄒㄩㄢㄈㄣ":"鏇粉",
"ㄒㄩㄢㄈㄤ":"渲房",
"ㄒㄩㄢㄈㄥ":"旋風/選風",
"ㄒㄩㄢㄈㄨ":"懸浮/玄服",
"ㄒㄩㄢㄉㄜ":"玄德/宣德",
"ㄒㄩㄢㄉㄠ":"宣導",
"ㄒㄩㄢㄉㄢ":"懸膽",
"ㄒㄩㄢㄉㄤ":"懸宕",
"ㄒㄩㄢㄉㄨ":"宣讀/玄都",
"ㄒㄩㄢㄊㄚ":"懸榻",
"ㄒㄩㄢㄊㄞ":"鉉臺",
"ㄒㄩㄢㄊㄡ":"懸頭/楥頭",
"ㄒㄩㄢㄊㄢ":"玄談",
"ㄒㄩㄢㄊㄤ":"玄堂/萱堂",
"ㄒㄩㄢㄊㄥ":"喧騰/宣騰",
"ㄒㄩㄢㄊㄧ":"選體",
"ㄒㄩㄢㄊㄨ":"璿圖/宣吐",
"ㄒㄩㄢㄋㄠ":"喧鬧/諠呶",
"ㄒㄩㄢㄋㄧ":"懸擬/宣尼",
"ㄒㄩㄢㄋㄩ":"玄女/衒女",
"ㄒㄩㄢㄌㄠ":"宣勞/宣勞",
"ㄒㄩㄢㄌㄢ":"絢爛/玄覽",
"ㄒㄩㄢㄌㄤ":"軒朗",
"ㄒㄩㄢㄌㄧ":"懸黎/玄理",
"ㄒㄩㄢㄌㄨ":"選錄",
"ㄒㄩㄢㄌㄩ":"旋律",
"ㄒㄩㄢㄍㄜ":"懸隔",
"ㄒㄩㄢㄍㄠ":"宣告",
"ㄒㄩㄢㄍㄡ":"選購",
"ㄒㄩㄢㄍㄣ":"玄根",
"ㄒㄩㄢㄎㄜ":"選課/宣科",
"ㄒㄩㄢㄎㄡ":"懸口",
"ㄒㄩㄢㄎㄢ":"選刊",
"ㄒㄩㄢㄏㄜ":"宣和/暄和",
"ㄒㄩㄢㄏㄞ":"玄海",
"ㄒㄩㄢㄏㄥ":"懸衡",
"ㄒㄩㄢㄏㄨ":"旋胡/懸壺",
"ㄒㄩㄢㄐㄧ":"旋即/玄機",
"ㄒㄩㄢㄐㄩ":"選舉/軒舉",
"ㄒㄩㄢㄑㄧ":"玄妻/縣跂",
"ㄒㄩㄢㄑㄩ":"選區/選取",
"ㄒㄩㄢㄒㄩ":"懸虛/玄虛",
"ㄒㄩㄢㄓㄠ":"宣召/宣詔",
"ㄒㄩㄢㄓㄡ":"懸肘/軒輖",
"ㄒㄩㄢㄓㄢ":"宣戰/選戰",
"ㄒㄩㄢㄓㄣ":"玄真/懸針",
"ㄒㄩㄢㄓㄨ":"玄著/玄珠",
"ㄒㄩㄢㄔㄜ":"軒車",
"ㄒㄩㄢㄔㄤ":"選場/軒裳",
"ㄒㄩㄢㄔㄥ":"宣稱/軒城",
"ㄒㄩㄢㄕㄓ":"宣室志",
"ㄒㄩㄢㄕㄜ":"玄社",
"ㄒㄩㄢㄕㄡ":"選手/懸首",
"ㄒㄩㄢㄕㄢ":"懸山",
"ㄒㄩㄢㄕㄣ":"玄參",
"ㄒㄩㄢㄕㄤ":"懸賞/縣賞",
"ㄒㄩㄢㄕㄥ":"玄聖",
"ㄒㄩㄢㄕㄨ":"懸殊/玄鼠",
"ㄒㄩㄢㄖㄠ":"旋繞/喧擾",
"ㄒㄩㄢㄖㄢ":"渲染/軒然",
"ㄒㄩㄢㄖㄣ":"選人/眩人",
"ㄒㄩㄢㄖㄤ":"喧嚷",
"ㄒㄩㄢㄗㄜ":"選擇",
"ㄒㄩㄢㄗㄠ":"喧噪",
"ㄒㄩㄢㄗㄢ":"宣贊",
"ㄒㄩㄢㄗㄤ":"玄奘",
"ㄒㄩㄢㄘㄜ":"玄策",
"ㄒㄩㄢㄘㄞ":"選材",
"ㄒㄩㄢㄘㄠ":"萱草",
"ㄒㄩㄢㄙㄜ":"衒色",
"ㄒㄩㄢㄙㄞ":"玄塞",
"ㄒㄩㄢㄧㄝ":"軒掖",
"ㄒㄩㄢㄧㄞ":"懸崖",
"ㄒㄩㄢㄧㄠ":"炫耀/衒耀",
"ㄒㄩㄢㄧㄢ":"宣言/懸岩",
"ㄒㄩㄢㄧㄣ":"玄陰/宣淫",
"ㄒㄩㄢㄧㄤ":"宣揚",
"ㄒㄩㄢㄧㄥ":"玄英",
"ㄒㄩㄢㄨㄛ":"漩渦",
"ㄒㄩㄢㄨㄟ":"宣威/宣慰",
"ㄒㄩㄢㄨㄢ":"懸腕",
"ㄒㄩㄢㄨㄤ":"懸望",
"ㄒㄩㄢㄩㄝ":"玄月/選閱",
"ㄒㄩㄢㄩㄢ":"玄遠/軒轅",
"ㄒㄩㄢㄩㄣ":"玄雲/眩暈",
"ㄒㄩㄢㄩㄥ":"選用/懸癰",
"ㄒㄩㄣㄅㄟ":"巡杯",
"ㄒㄩㄣㄅㄢ":"訊辦",
"ㄒㄩㄣㄅㄨ":"巡捕",
"ㄒㄩㄣㄆㄞ":"荀派",
"ㄒㄩㄣㄆㄨ":"巡鋪",
"ㄒㄩㄣㄇㄚ":"蕁麻",
"ㄒㄩㄣㄇㄛ":"尋摸/循默",
"ㄒㄩㄣㄇㄥ":"迅猛/訓蒙",
"ㄒㄩㄣㄇㄧ":"尋覓",
"ㄒㄩㄣㄇㄨ":"恂目/熏沐",
"ㄒㄩㄣㄈㄚ":"徇罰/勛伐",
"ㄒㄩㄣㄈㄣ":"循分",
"ㄒㄩㄣㄈㄤ":"尋訪/尋芳",
"ㄒㄩㄣㄈㄥ":"迅風/巡風",
"ㄒㄩㄣㄈㄨ":"馴服/勛附",
"ㄒㄩㄣㄉㄚ":"恂達",
"ㄒㄩㄣㄉㄠ":"訓導/殉道",
"ㄒㄩㄣㄉㄧ":"徇地/汛地",
"ㄒㄩㄣㄉㄨ":"訓讀",
"ㄒㄩㄣㄊㄠ":"薰陶/尋討",
"ㄒㄩㄣㄊㄤ":"巡堂",
"ㄒㄩㄣㄊㄧ":"洵涕",
"ㄒㄩㄣㄋㄠ":"尋鬧",
"ㄒㄩㄣㄋㄢ":"殉難",
"ㄒㄩㄣㄌㄜ":"尋樂",
"ㄒㄩㄣㄌㄟ":"迅雷",
"ㄒㄩㄣㄌㄠ":"勛勞",
"ㄒㄩㄣㄌㄤ":"蕈螂",
"ㄒㄩㄣㄌㄧ":"循例/巡禮",
"ㄒㄩㄣㄌㄨ":"馴鹿/熏鑪",
"ㄒㄩㄣㄍㄜ":"馴鴿",
"ㄒㄩㄣㄍㄞ":"循陔",
"ㄒㄩㄣㄍㄣ":"尋根",
"ㄒㄩㄣㄍㄥ":"巡更",
"ㄒㄩㄣㄍㄨ":"訓詁",
"ㄒㄩㄣㄎㄡ":"訊口",
"ㄒㄩㄣㄎㄢ":"旬刊",
"ㄒㄩㄣㄏㄜ":"曛黑/馴和",
"ㄒㄩㄣㄏㄠ":"訊號/焄蒿",
"ㄒㄩㄣㄏㄢ":"醺酣",
"ㄒㄩㄣㄏㄤ":"巡航",
"ㄒㄩㄣㄐㄧ":"迅即/迅急",
"ㄒㄩㄣㄐㄩ":"訊鞫/栒虡",
"ㄒㄩㄣㄑㄧ":"汛期/徇齊",
"ㄒㄩㄣㄑㄩ":"尋取",
"ㄒㄩㄣㄒㄧ":"訊息/熏習",
"ㄒㄩㄣㄒㄩ":"循序/荀勖",
"ㄒㄩㄣㄓㄠ":"尋找",
"ㄒㄩㄣㄓㄣ":"巡診",
"ㄒㄩㄣㄓㄤ":"勛章",
"ㄒㄩㄣㄓㄥ":"薰蒸/熏烝",
"ㄒㄩㄣㄔㄚ":"巡查/尋查",
"ㄒㄩㄣㄔㄜ":"撏扯/撏撦",
"ㄒㄩㄣㄔㄡ":"尋仇",
"ㄒㄩㄣㄔㄣ":"尋趁/勛臣",
"ㄒㄩㄣㄔㄤ":"尋常/循常",
"ㄒㄩㄣㄔㄨ":"郇廚",
"ㄒㄩㄣㄕㄠ":"巡哨",
"ㄒㄩㄣㄕㄡ":"徇首/巡守",
"ㄒㄩㄣㄕㄢ":"馴善",
"ㄒㄩㄣㄕㄣ":"殉身",
"ㄒㄩㄣㄕㄤ":"巡商/循尚",
"ㄒㄩㄣㄕㄨ":"蕈樹",
"ㄒㄩㄣㄖㄠ":"馴擾",
"ㄒㄩㄣㄖㄡ":"燻肉",
"ㄒㄩㄣㄖㄢ":"薰然/熏染",
"ㄒㄩㄣㄖㄣ":"熏人/訓人",
"ㄒㄩㄣㄗㄜ":"訓責",
"ㄒㄩㄣㄗㄡ":"迅走",
"ㄒㄩㄣㄗㄤ":"殉葬",
"ㄒㄩㄣㄘㄞ":"殉財",
"ㄒㄩㄣㄘㄠ":"燻草/薰草",
"ㄒㄩㄣㄙㄜ":"遜色",
"ㄒㄩㄣㄙㄠ":"汛掃",
"ㄒㄩㄣㄙㄨ":"迅速/尋俗",
"ㄒㄩㄣㄢㄕ":"巡按使",
"ㄒㄩㄣㄧㄝ":"勛業/巡夜",
"ㄒㄩㄣㄧㄡ":"巡遊/尋幽",
"ㄒㄩㄣㄧㄢ":"巽言",
"ㄒㄩㄣㄧㄣ":"尋引/勛蔭",
"ㄒㄩㄣㄧㄤ":"馴養",
"ㄒㄩㄣㄧㄥ":"蕈蠅",
"ㄒㄩㄣㄨㄟ":"遜位/勛位",
"ㄒㄩㄣㄨㄣ":"詢問/訊問",
"ㄒㄩㄣㄩㄝ":"旬月/荀悅",
"ㄒㄩㄣㄩㄣ":"葷允",
"ㄒㄩㄥㄅㄞ":"熊白",
"ㄒㄩㄥㄅㄠ":"凶暴",
"ㄒㄩㄥㄅㄣ":"熊本",
"ㄒㄩㄥㄅㄨ":"胸部",
"ㄒㄩㄥㄆㄧ":"熊羆",
"ㄒㄩㄥㄆㄨ":"胸脯",
"ㄒㄩㄥㄇㄛ":"胸膜",
"ㄒㄩㄥㄇㄟ":"兄妹",
"ㄒㄩㄥㄇㄠ":"熊貓",
"ㄒㄩㄥㄇㄣ":"凶門",
"ㄒㄩㄥㄇㄥ":"凶猛",
"ㄒㄩㄥㄈㄟ":"雄飛",
"ㄒㄩㄥㄈㄢ":"凶犯/雄藩",
"ㄒㄩㄥㄈㄥ":"雄風/雄蜂",
"ㄒㄩㄥㄈㄨ":"凶服/胸腹",
"ㄒㄩㄥㄉㄚ":"雄大",
"ㄒㄩㄥㄉㄜ":"凶德",
"ㄒㄩㄥㄉㄢ":"熊膽",
"ㄒㄩㄥㄉㄤ":"凶黨",
"ㄒㄩㄥㄉㄧ":"兄弟/兄弟",
"ㄒㄩㄥㄊㄞ":"兄臺",
"ㄒㄩㄥㄊㄤ":"胸膛",
"ㄒㄩㄥㄊㄨ":"雄圖/凶徒",
"ㄒㄩㄥㄋㄧ":"凶逆",
"ㄒㄩㄥㄋㄨ":"匈奴",
"ㄒㄩㄥㄌㄧ":"凶禮",
"ㄒㄩㄥㄍㄨ":"敻古/胸骨",
"ㄒㄩㄥㄎㄡ":"胸口",
"ㄒㄩㄥㄏㄠ":"雄豪/凶耗",
"ㄒㄩㄥㄏㄡ":"雄厚",
"ㄒㄩㄥㄏㄢ":"凶悍",
"ㄒㄩㄥㄏㄣ":"凶狠",
"ㄒㄩㄥㄏㄥ":"凶橫",
"ㄒㄩㄥㄏㄨ":"雄狐/熊虎",
"ㄒㄩㄥㄐㄧ":"胸肌/雄雞",
"ㄒㄩㄥㄐㄩ":"凶具/雄踞",
"ㄒㄩㄥㄑㄧ":"雄氣/凶氣",
"ㄒㄩㄥㄒㄧ":"熊戲",
"ㄒㄩㄥㄓㄞ":"凶宅",
"ㄒㄩㄥㄓㄠ":"胸罩/凶兆",
"ㄒㄩㄥㄓㄣ":"雄鎮/胸針",
"ㄒㄩㄥㄓㄤ":"兄長/雄長",
"ㄒㄩㄥㄓㄨ":"雄主/雄株",
"ㄒㄩㄥㄔㄚ":"詗察",
"ㄒㄩㄥㄕㄚ":"凶殺/凶煞",
"ㄒㄩㄥㄕㄡ":"凶手",
"ㄒㄩㄥㄕㄣ":"凶身/凶神",
"ㄒㄩㄥㄕㄥ":"雄勝",
"ㄒㄩㄥㄕㄨ":"凶豎",
"ㄒㄩㄥㄖㄣ":"凶人",
"ㄒㄩㄥㄘㄢ":"凶殘",
"ㄒㄩㄥㄙㄠ":"兄嫂",
"ㄒㄩㄥㄧㄢ":"凶焰/凶燄",
"ㄒㄩㄥㄨㄟ":"胸圍/雄偉",
"ㄒㄩㄥㄨㄣ":"雄文",
"ㄒㄩㄥㄩㄥ":"洶湧/洶涌",
"ㄒㄩㄨㄓㄣ":"余吾鎮",
"ㄒㄩㄩㄊㄧ":"徐庾體",
"ㄓㄅㄟㄓㄣ":"指北針",
"ㄓㄅㄢㄓㄤ":"紙半張",
"ㄓㄅㄧㄠㄦ":"紙標兒",
"ㄓㄅㄨㄐㄧ":"織布機",
"ㄓㄅㄨㄓㄨ":"止不住",
"ㄓㄇㄚㄆㄨ":"紙馬鋪",
"ㄓㄇㄚㄏㄨ":"芝麻糊",
"ㄓㄇㄚㄧㄡ":"芝麻油",
"ㄓㄇㄠㄐㄧ":"殖毛劑",
"ㄓㄇㄨㄕㄤ":"直木賞",
"ㄓㄈㄢㄨㄢ":"紙飯碗",
"ㄓㄈㄣㄑㄧ":"脂粉氣",
"ㄓㄈㄥㄘㄠ":"知風草",
"ㄓㄉㄚㄔㄜ":"直達車",
"ㄓㄉㄠㄩㄢ":"指導員",
"ㄓㄉㄥㄉㄥ":"直瞪瞪",
"ㄓㄊㄨㄅㄢ":"製圖板",
"ㄓㄊㄨㄥㄗ":"直筒子",
"ㄓㄋㄢㄓㄣ":"指南針",
"ㄓㄋㄢㄔㄜ":"指南車",
"ㄓㄋㄥㄓㄕ":"志能之士",
"ㄓㄋㄥㄔㄓ":"智能遲滯",
"ㄓㄋㄧㄡㄦ":"執牛耳",
"ㄓㄋㄩㄒㄩ":"姪女婿",
"ㄓㄌㄠㄏㄨ":"紙老虎",
"ㄓㄌㄢㄓㄕ":"芝蘭之室",
"ㄓㄌㄧㄖㄣ":"直立人",
"ㄓㄌㄧㄡㄗ":"摭溜子",
"ㄓㄌㄨㄖㄣ":"指路人",
"ㄓㄏㄡㄖㄣ":"祗候人",
"ㄓㄏㄨㄊㄡ":"紙糊頭",
"ㄓㄐㄧㄓㄡ":"執箕帚",
"ㄓㄐㄧㄚㄗ":"支架子",
"ㄓㄐㄧㄣㄨ":"執金吾",
"ㄓㄑㄧㄩㄗ":"質妻鬻子",
"ㄓㄒㄧㄚㄕ":"直轄市",
"ㄓㄒㄧㄥㄗ":"直性子",
"ㄓㄓㄥㄉㄤ":"執政黨",
"ㄓㄓㄨㄍㄤ":"蜘蛛綱",
"ㄓㄔㄐㄧㄝ":"止持戒",
"ㄓㄔㄓㄉㄧ":"咫尺之地",
"ㄓㄕㄈㄣㄗ":"知識分子",
"ㄓㄕㄌㄨㄣ":"知識論",
"ㄓㄕㄡㄕㄜ":"枳首蛇",
"ㄓㄕㄥㄐㄧ":"直升機",
"ㄓㄖㄣㄩㄥ":"智仁勇",
"ㄓㄗㄏㄨㄚ":"梔子花",
"ㄓㄗㄠㄕㄤ":"製造商",
"ㄓㄗㄠㄧㄝ":"製造業",
"ㄓㄗㄨㄓㄓ":"知足知止",
"ㄓㄘㄇㄢㄩ":"枝詞蔓語",
"ㄓㄙㄅㄨㄨ":"至死不悟",
"ㄓㄙㄦㄈㄣ":"治絲而棼",
"ㄓㄙㄧㄈㄣ":"治絲益棼",
"ㄓㄚㄆㄧㄢ":"詐騙",
"ㄓㄚㄇㄟㄢ":"鍘美案",
"ㄓㄚㄇㄥㄗ":"扎猛子",
"ㄓㄚㄈㄚㄗ":"扎罰子",
"ㄓㄚㄉㄧㄢ":"扎掂/扎墊",
"ㄓㄚㄉㄨㄟ":"扎堆",
"ㄓㄚㄊㄧㄥ":"乍聽",
"ㄓㄚㄌㄚㄗ":"柵剌子",
"ㄓㄚㄌㄧㄝ":"炸裂",
"ㄓㄚㄍㄨㄚ":"扎括",
"ㄓㄚㄍㄨㄛ":"查果/查裹",
"ㄓㄚㄍㄨㄣ":"軋輥",
"ㄓㄚㄍㄨㄥ":"炸供",
"ㄓㄚㄏㄨㄚ":"扎花",
"ㄓㄚㄏㄨㄟ":"炸毀/炸燬",
"ㄓㄚㄏㄨㄥ":"詐哄",
"ㄓㄚㄐㄧㄝ":"咋唶",
"ㄓㄚㄐㄧㄠ":"扎腳/紮腳",
"ㄓㄚㄐㄧㄢ":"乍見",
"ㄓㄚㄐㄧㄤ":"炸醬/鮓醬",
"ㄓㄚㄑㄧㄤ":"扎槍",
"ㄓㄚㄒㄧㄢ":"乍現",
"ㄓㄚㄒㄧㄣ":"扎心",
"ㄓㄚㄒㄧㄤ":"詐降",
"ㄓㄚㄓㄐㄧ":"榨汁機",
"ㄓㄚㄔㄨㄤ":"榨床",
"ㄓㄚㄕㄨㄟ":"溠水",
"ㄓㄚㄗㄨㄟ":"扎嘴",
"ㄓㄚㄨㄛㄗ":"扎窩子",
"ㄓㄚㄨㄢㄗ":"炸丸子",
"ㄓㄜㄅㄟㄗ":"這輩子",
"ㄓㄜㄅㄧㄢ":"這邊/折變",
"ㄓㄜㄅㄧㄥ":"折兵",
"ㄓㄜㄇㄧㄢ":"赭面",
"ㄓㄜㄉㄚㄦ":"這搭兒",
"ㄓㄜㄉㄤㄦ":"這當兒",
"ㄓㄜㄉㄧㄕ":"這的是",
"ㄓㄜㄉㄧㄝ":"折疊/摺疊",
"ㄓㄜㄉㄨㄟ":"折對/折兌",
"ㄓㄜㄉㄨㄢ":"折斷/遮斷",
"ㄓㄜㄉㄨㄥ":"浙東",
"ㄓㄜㄊㄧㄢ":"這天/遮天",
"ㄓㄜㄋㄧㄢ":"這年",
"ㄓㄜㄋㄨㄥ":"蔗農",
"ㄓㄜㄌㄧㄡ":"折柳",
"ㄓㄜㄌㄨㄛ":"遮羅/折籮",
"ㄓㄜㄍㄨㄟ":"折桂",
"ㄓㄜㄍㄨㄤ":"折光",
"ㄓㄜㄍㄨㄥ":"折肱",
"ㄓㄜㄏㄠㄕ":"這號事",
"ㄓㄜㄏㄨㄟ":"者回/這回",
"ㄓㄜㄏㄨㄢ":"謫宦",
"ㄓㄜㄏㄨㄤ":"柘黃/赭黃",
"ㄓㄜㄏㄨㄥ":"赭紅",
"ㄓㄜㄏㄨㄦ":"這忽兒",
"ㄓㄜㄐㄧㄚ":"遮架/折價",
"ㄓㄜㄐㄧㄝ":"折節",
"ㄓㄜㄐㄧㄡ":"折舊/蔗酒",
"ㄓㄜㄐㄧㄢ":"折簡/折箭",
"ㄓㄜㄐㄧㄤ":"浙江/哲匠",
"ㄓㄜㄐㄧㄥ":"蔗境",
"ㄓㄜㄑㄧㄢ":"折錢",
"ㄓㄜㄑㄩㄢ":"折券",
"ㄓㄜㄑㄩㄣ":"摺裙/褶裙",
"ㄓㄜㄒㄧㄚ":"這下",
"ㄓㄜㄒㄧㄝ":"這些",
"ㄓㄜㄒㄧㄠ":"者囂",
"ㄓㄜㄒㄧㄡ":"遮羞",
"ㄓㄜㄒㄧㄢ":"折線/謫仙",
"ㄓㄜㄒㄧㄤ":"這廂",
"ㄓㄜㄒㄧㄥ":"磔刑",
"ㄓㄜㄒㄩㄝ":"哲學",
"ㄓㄜㄓㄨㄥ":"折衷/折中",
"ㄓㄜㄔㄥㄗ":"這程子",
"ㄓㄜㄔㄨㄥ":"折衝",
"ㄓㄜㄕㄏㄡ":"這時候",
"ㄓㄜㄕㄨㄛ":"遮說",
"ㄓㄜㄕㄨㄤ":"蔗霜",
"ㄓㄜㄗㄒㄧ":"折子戲",
"ㄓㄜㄘㄨㄛ":"折挫/折剉",
"ㄓㄜㄙㄨㄢ":"折算",
"ㄓㄜㄙㄨㄣ":"折損",
"ㄓㄜㄦㄇㄨ":"遮耳目",
"ㄓㄞㄅㄧㄢ":"摘編",
"ㄓㄞㄍㄨㄛ":"齋果",
"ㄓㄞㄍㄨㄥ":"齋公/齋供",
"ㄓㄞㄏㄨㄚ":"摘花",
"ㄓㄞㄏㄨㄟ":"齋會",
"ㄓㄞㄐㄧㄝ":"齋戒/摘借",
"ㄓㄞㄐㄧㄠ":"齋醮",
"ㄓㄞㄑㄩㄢ":"債券/債權",
"ㄓㄞㄒㄧㄚ":"窄狹",
"ㄓㄞㄒㄧㄠ":"窄小",
"ㄓㄞㄒㄧㄣ":"齋心/摘心",
"ㄓㄞㄒㄧㄤ":"窄巷",
"ㄓㄞㄒㄧㄥ":"摘星",
"ㄓㄞㄓㄨㄤ":"齊莊/債樁",
"ㄓㄞㄘㄨㄟ":"摘翠",
"ㄓㄞㄨㄖㄣ":"債務人",
"ㄓㄠㄅㄧㄠ":"招標",
"ㄓㄠㄅㄧㄥ":"招兵",
"ㄓㄠㄆㄧㄠ":"照票/招票",
"ㄓㄠㄆㄧㄢ":"照片",
"ㄓㄠㄆㄧㄣ":"招聘",
"ㄓㄠㄆㄧㄥ":"找平",
"ㄓㄠㄇㄧㄢ":"照面",
"ㄓㄠㄇㄧㄣ":"兆民/召旻",
"ㄓㄠㄇㄧㄥ":"照明/昭明",
"ㄓㄠㄈㄥㄗ":"找縫子",
"ㄓㄠㄈㄥㄦ":"招風耳",
"ㄓㄠㄉㄨㄟ":"招對",
"ㄓㄠㄉㄨㄢ":"肇端",
"ㄓㄠㄊㄧㄝ":"照帖/招貼",
"ㄓㄠㄊㄧㄥ":"照廳",
"ㄓㄠㄊㄨㄣ":"朝暾",
"ㄓㄠㄌㄜㄗ":"找樂子",
"ㄓㄠㄌㄧㄠ":"照料",
"ㄓㄠㄌㄧㄢ":"照臉",
"ㄓㄠㄌㄧㄣ":"照臨",
"ㄓㄠㄌㄧㄤ":"照亮/著涼",
"ㄓㄠㄌㄧㄥ":"詔令/招領",
"ㄓㄠㄌㄨㄛ":"招羅",
"ㄓㄠㄌㄨㄢ":"兆亂",
"ㄓㄠㄌㄨㄣ":"肇論",
"ㄓㄠㄍㄨㄚ":"罩褂",
"ㄓㄠㄍㄨㄢ":"昭關/照管",
"ㄓㄠㄍㄨㄤ":"照光",
"ㄓㄠㄍㄨㄥ":"招供",
"ㄓㄠㄏㄢㄗ":"招漢子",
"ㄓㄠㄏㄨㄚ":"昭華",
"ㄓㄠㄏㄨㄛ":"著火/招禍",
"ㄓㄠㄏㄨㄞ":"招懷",
"ㄓㄠㄏㄨㄟ":"照會/朝會",
"ㄓㄠㄏㄨㄢ":"召喚/招喚",
"ㄓㄠㄏㄨㄣ":"招魂",
"ㄓㄠㄏㄨㄤ":"著慌",
"ㄓㄠㄐㄧㄚ":"招架/照價",
"ㄓㄠㄐㄧㄝ":"招解",
"ㄓㄠㄐㄧㄠ":"爪角",
"ㄓㄠㄐㄧㄡ":"照舊/招咎",
"ㄓㄠㄐㄧㄢ":"召見",
"ㄓㄠㄐㄧㄣ":"朝槿",
"ㄓㄠㄐㄩㄝ":"找絕",
"ㄓㄠㄐㄩㄣ":"招軍/朝菌",
"ㄓㄠㄑㄧㄢ":"找錢/照前",
"ㄓㄠㄑㄧㄣ":"招親",
"ㄓㄠㄑㄧㄤ":"照牆",
"ㄓㄠㄒㄧㄚ":"朝霞/沼蝦",
"ㄓㄠㄒㄧㄡ":"爪袖",
"ㄓㄠㄒㄧㄢ":"昭顯/招賢",
"ㄓㄠㄒㄧㄤ":"著想/照相",
"ㄓㄠㄒㄧㄥ":"召幸",
"ㄓㄠㄒㄩㄝ":"昭雪",
"ㄓㄠㄒㄩㄣ":"找尋/爪尋",
"ㄓㄠㄓㄨㄛ":"昭灼",
"ㄓㄠㄓㄨㄟ":"招贅",
"ㄓㄠㄓㄨㄣ":"照准",
"ㄓㄠㄓㄨㄤ":"招狀",
"ㄓㄠㄔㄚㄗ":"找岔子",
"ㄓㄠㄔㄚㄦ":"找碴兒",
"ㄓㄠㄕㄓㄜ":"肇事者",
"ㄓㄠㄕㄨㄛ":"照說/招說",
"ㄓㄠㄘㄨㄟ":"趙衰",
"ㄓㄠㄙㄨㄢ":"照算",
"ㄓㄠㄙㄨㄥ":"趙宋",
"ㄓㄠㄧㄝㄕ":"找野食",
"ㄓㄡㄅㄧㄝ":"皺彆",
"ㄓㄡㄅㄧㄢ":"周邊/周遍",
"ㄓㄡㄇㄧㄥ":"晝暝",
"ㄓㄡㄊㄧㄢ":"周天",
"ㄓㄡㄊㄨㄥ":"州同",
"ㄓㄡㄋㄧㄢ":"周年/週年",
"ㄓㄡㄌㄧㄡ":"周流",
"ㄓㄡㄍㄨㄢ":"周官/州官",
"ㄓㄡㄍㄨㄣ":"紂棍",
"ㄓㄡㄍㄨㄥ":"周公",
"ㄓㄡㄏㄨㄟ":"週會/週迴",
"ㄓㄡㄐㄧㄚ":"周浹",
"ㄓㄡㄐㄧㄠ":"周角",
"ㄓㄡㄐㄧㄡ":"啁啾",
"ㄓㄡㄐㄧㄣ":"週近/酎金",
"ㄓㄡㄐㄩㄣ":"州郡",
"ㄓㄡㄑㄧㄣ":"晝寢",
"ㄓㄡㄑㄩㄢ":"周全",
"ㄓㄡㄒㄧㄢ":"軸線/州縣",
"ㄓㄡㄒㄧㄣ":"軸心/週薪",
"ㄓㄡㄒㄧㄤ":"周詳",
"ㄓㄡㄒㄧㄥ":"周行/帚星",
"ㄓㄡㄒㄩㄝ":"州學",
"ㄓㄡㄒㄩㄢ":"周旋",
"ㄓㄡㄓㄨㄢ":"周轉",
"ㄓㄡㄕㄨㄟ":"咒水",
"ㄓㄡㄗㄨㄟ":"周晬",
"ㄓㄡㄙㄨㄟ":"週歲",
"ㄓㄡㄙㄨㄥ":"周頌/籀誦",
"ㄓㄡㄣㄌㄞ":"周恩來",
"ㄓㄡㄨㄨㄤ":"周武王",
"ㄓㄢㄅㄧㄝ":"暫別",
"ㄓㄢㄅㄧㄠ":"戰表/覘標",
"ㄓㄢㄅㄧㄢ":"沾邊",
"ㄓㄢㄅㄧㄥ":"沾病/覘兵",
"ㄓㄢㄇㄣㄗ":"站門子",
"ㄓㄢㄉㄧㄥ":"站定",
"ㄓㄢㄉㄨㄟ":"站隊",
"ㄓㄢㄉㄨㄢ":"占斷",
"ㄓㄢㄉㄨㄥ":"顫動",
"ㄓㄢㄊㄧㄠ":"氈條/瞻眺",
"ㄓㄢㄊㄧㄥ":"暫停",
"ㄓㄢㄊㄨㄛ":"展脫",
"ㄓㄢㄋㄧㄢ":"瞻念/沾粘",
"ㄓㄢㄌㄧㄝ":"綻裂",
"ㄓㄢㄌㄧㄢ":"棧戀",
"ㄓㄢㄌㄧㄤ":"戰糧",
"ㄓㄢㄌㄧㄥ":"占領",
"ㄓㄢㄌㄨㄢ":"戰亂",
"ㄓㄢㄌㄨㄥ":"站籠",
"ㄓㄢㄌㄩㄝ":"戰略",
"ㄓㄢㄍㄨㄚ":"占卦",
"ㄓㄢㄍㄨㄛ":"戰國/戰果",
"ㄓㄢㄍㄨㄤ":"沾光",
"ㄓㄢㄍㄨㄥ":"戰功",
"ㄓㄢㄎㄡㄦ":"綻口兒",
"ㄓㄢㄎㄨㄤ":"戰況",
"ㄓㄢㄏㄨㄛ":"戰火/斬獲",
"ㄓㄢㄏㄨㄟ":"邅回",
"ㄓㄢㄏㄨㄢ":"暫緩/展緩",
"ㄓㄢㄐㄧㄝ":"站街/斬截",
"ㄓㄢㄐㄧㄢ":"戰艦",
"ㄓㄢㄐㄧㄣ":"沾巾/沾襟",
"ㄓㄢㄐㄧㄤ":"戰將",
"ㄓㄢㄐㄧㄥ":"戰兢",
"ㄓㄢㄐㄩㄝ":"斬決",
"ㄓㄢㄐㄩㄢ":"展卷",
"ㄓㄢㄑㄧㄝ":"暫且",
"ㄓㄢㄑㄧㄠ":"棧橋",
"ㄓㄢㄑㄧㄡ":"氈裘/旃裘",
"ㄓㄢㄑㄧㄣ":"沾親/占親",
"ㄓㄢㄑㄧㄤ":"占強",
"ㄓㄢㄑㄧㄥ":"戰情",
"ㄓㄢㄑㄩㄣ":"戰裙",
"ㄓㄢㄒㄧㄚ":"沾洽",
"ㄓㄢㄒㄧㄢ":"展現/戰線",
"ㄓㄢㄒㄧㄣ":"嶄新/戰心",
"ㄓㄢㄒㄧㄤ":"氈鄉",
"ㄓㄢㄒㄧㄥ":"占星/展性",
"ㄓㄢㄒㄩㄣ":"瞻徇",
"ㄓㄢㄓㄨㄚ":"展爪",
"ㄓㄢㄓㄨㄛ":"斬斫",
"ㄓㄢㄓㄨㄢ":"輾轉/展轉",
"ㄓㄢㄔㄤㄦ":"占場兒",
"ㄓㄢㄔㄨㄢ":"戰船",
"ㄓㄢㄔㄨㄣ":"沾脣",
"ㄓㄢㄖㄨㄣ":"沾潤",
"ㄓㄢㄗㄨㄟ":"霑醉",
"ㄓㄢㄘㄨㄟ":"斬衰",
"ㄓㄢㄙㄨㄛ":"展縮",
"ㄓㄢㄠㄊㄡ":"占鰲頭",
"ㄓㄣㄅㄠㄣ":"知恩報恩",
"ㄓㄣㄅㄧㄝ":"甄別",
"ㄓㄣㄅㄧㄢ":"針砭/鍼砭",
"ㄓㄣㄆㄧㄣ":"真品/珍品",
"ㄓㄣㄆㄧㄥ":"真平",
"ㄓㄣㄇㄧㄥ":"朕命",
"ㄓㄣㄉㄧㄠ":"真鯛",
"ㄓㄣㄉㄧㄥ":"鎮定",
"ㄓㄣㄉㄨㄛ":"振鐸",
"ㄓㄣㄉㄨㄟ":"針對",
"ㄓㄣㄉㄨㄢ":"診斷",
"ㄓㄣㄉㄨㄥ":"震動/振動",
"ㄓㄣㄊㄧㄢ":"震天/賑田",
"ㄓㄣㄊㄧㄥ":"偵聽/震霆",
"ㄓㄣㄊㄨㄥ":"陣痛/鎮痛",
"ㄓㄣㄋㄧㄢ":"軫念",
"ㄓㄣㄋㄩㄕ":"貞女石",
"ㄓㄣㄌㄧㄝ":"貞烈",
"ㄓㄣㄌㄧㄠ":"診療",
"ㄓㄣㄌㄧㄣ":"榛林",
"ㄓㄣㄌㄧㄤ":"斟量/貞亮",
"ㄓㄣㄌㄨㄛ":"振落",
"ㄓㄣㄍㄨㄛ":"真果",
"ㄓㄣㄍㄨㄞ":"珍怪",
"ㄓㄣㄍㄨㄟ":"珍貴/箴規",
"ㄓㄣㄍㄨㄢ":"真官/針關",
"ㄓㄣㄍㄨㄥ":"針工/朕躬",
"ㄓㄣㄎㄨㄞ":"枕塊",
"ㄓㄣㄎㄨㄢ":"賑款",
"ㄓㄣㄎㄨㄥ":"真空/針孔",
"ㄓㄣㄏㄨㄚ":"真話",
"ㄓㄣㄏㄨㄛ":"真貨",
"ㄓㄣㄏㄨㄞ":"軫懷",
"ㄓㄣㄏㄨㄤ":"蓁荒",
"ㄓㄣㄐㄧㄚ":"真假",
"ㄓㄣㄐㄧㄝ":"貞節/貞潔",
"ㄓㄣㄐㄧㄠ":"陣腳/陣角",
"ㄓㄣㄐㄧㄡ":"針灸/斟酒",
"ㄓㄣㄐㄧㄢ":"箴諫",
"ㄓㄣㄐㄧㄣ":"真金/枕巾",
"ㄓㄣㄐㄧㄥ":"震驚/鎮靜",
"ㄓㄣㄐㄩㄢ":"賑捐",
"ㄓㄣㄐㄩㄣ":"真君/真菌",
"ㄓㄣㄑㄧㄝ":"真切/診切",
"ㄓㄣㄑㄧㄣ":"珍禽",
"ㄓㄣㄑㄧㄥ":"真情",
"ㄓㄣㄑㄩㄝ":"真確",
"ㄓㄣㄑㄩㄢ":"真詮",
"ㄓㄣㄒㄧㄚ":"震嚇",
"ㄓㄣㄒㄧㄝ":"鎮邪",
"ㄓㄣㄒㄧㄡ":"珍羞/珍饈",
"ㄓㄣㄒㄧㄢ":"陣線/針線",
"ㄓㄣㄒㄧㄣ":"真心/枕心",
"ㄓㄣㄒㄧㄤ":"真相/枕箱",
"ㄓㄣㄒㄧㄥ":"振興/真性",
"ㄓㄣㄒㄩㄢ":"甄選",
"ㄓㄣㄒㄩㄣ":"偵訊/甄訓",
"ㄓㄣㄓㄨㄛ":"斟酌",
"ㄓㄣㄓㄨㄥ":"珍重/震中",
"ㄓㄣㄔㄨㄢ":"真傳",
"ㄓㄣㄔㄨㄣ":"真純",
"ㄓㄣㄕㄨㄚ":"振刷",
"ㄓㄣㄕㄨㄞ":"真率",
"ㄓㄣㄕㄨㄟ":"湞水/溱水",
"ㄓㄣㄖㄨㄥ":"陣容",
"ㄓㄣㄗㄨㄛ":"振作",
"ㄓㄣㄘㄨㄛ":"珍錯/偵錯",
"ㄓㄣㄙㄨㄛ":"診所",
"ㄓㄣㄙㄨㄥ":"震悚",
"ㄓㄤㄅㄧㄠ":"章表",
"ㄓㄤㄅㄧㄢ":"掌鞭",
"ㄓㄤㄈㄨㄗ":"丈夫子",
"ㄓㄤㄉㄧㄝ":"漲跌",
"ㄓㄤㄉㄧㄢ":"張顛",
"ㄓㄤㄉㄨㄛ":"掌舵",
"ㄓㄤㄉㄨㄢ":"杖斷/漳緞",
"ㄓㄤㄉㄨㄥ":"章動",
"ㄓㄤㄊㄡㄗ":"杖頭資",
"ㄓㄤㄊㄧㄝ":"張貼",
"ㄓㄤㄊㄧㄥ":"漲停",
"ㄓㄤㄊㄨㄛ":"仗托",
"ㄓㄤㄊㄨㄥ":"脹痛",
"ㄓㄤㄌㄠㄕ":"張老師",
"ㄓㄤㄌㄧㄤ":"張良/丈量",
"ㄓㄤㄌㄧㄥ":"掌領",
"ㄓㄤㄌㄨㄛ":"張羅/張羅",
"ㄓㄤㄍㄨㄚ":"張掛",
"ㄓㄤㄍㄨㄛ":"掌摑/張果",
"ㄓㄤㄍㄨㄟ":"掌櫃",
"ㄓㄤㄍㄨㄢ":"長官/掌管",
"ㄓㄤㄍㄨㄤ":"幛光",
"ㄓㄤㄎㄨㄢ":"帳款",
"ㄓㄤㄎㄨㄤ":"張狂",
"ㄓㄤㄎㄨㄥ":"掌控",
"ㄓㄤㄏㄨㄚ":"張華/章華",
"ㄓㄤㄏㄨㄤ":"張皇/慞惶",
"ㄓㄤㄏㄨㄥ":"漲紅",
"ㄓㄤㄐㄧㄚ":"漲價/掌家",
"ㄓㄤㄐㄧㄝ":"章節",
"ㄓㄤㄐㄧㄠ":"掌教",
"ㄓㄤㄐㄧㄢ":"仗劍",
"ㄓㄤㄐㄧㄣ":"長進",
"ㄓㄤㄐㄧㄤ":"漳江",
"ㄓㄤㄐㄩㄝ":"張角",
"ㄓㄤㄐㄩㄣ":"長君/長俊",
"ㄓㄤㄑㄧㄢ":"張千/杖錢",
"ㄓㄤㄑㄧㄣ":"長親",
"ㄓㄤㄑㄩㄢ":"掌權",
"ㄓㄤㄒㄧㄚ":"帳下",
"ㄓㄤㄒㄧㄝ":"張協",
"ㄓㄤㄒㄧㄢ":"張先/張仙",
"ㄓㄤㄒㄧㄣ":"掌心",
"ㄓㄤㄒㄧㄤ":"長相",
"ㄓㄤㄒㄩㄣ":"張巡/張勳",
"ㄓㄤㄒㄩㄥ":"長兄",
"ㄓㄤㄓㄏㄜ":"張志和",
"ㄓㄤㄓㄨㄗ":"帳主子",
"ㄓㄤㄕㄔㄥ":"張士誠",
"ㄓㄤㄕㄤㄨ":"掌上舞",
"ㄓㄤㄕㄨㄟ":"章水/漳水",
"ㄓㄤㄖㄨㄥ":"漳絨",
"ㄓㄤㄗㄊㄡ":"杖子頭",
"ㄓㄤㄗㄨㄟ":"張嘴/掌嘴",
"ㄓㄤㄙㄨㄣ":"長孫",
"ㄓㄤㄢㄉㄜ":"掌案的",
"ㄓㄤㄧㄕㄜ":"張儀舌",
"ㄓㄤㄧㄠㄗ":"仗腰子",
"ㄓㄤㄩㄢㄕ":"張垣市",
"ㄓㄥㄅㄧㄢ":"爭辯/政變",
"ㄓㄥㄅㄧㄥ":"政柄/整兵",
"ㄓㄥㄆㄧㄢ":"正片",
"ㄓㄥㄆㄧㄣ":"正品/徵聘",
"ㄓㄥㄇㄧㄢ":"正面",
"ㄓㄥㄇㄧㄣ":"蒸民/烝民",
"ㄓㄥㄇㄧㄥ":"證明/正名",
"ㄓㄥㄉㄧㄠ":"徵調",
"ㄓㄥㄉㄧㄢ":"正點/整點",
"ㄓㄥㄉㄧㄥ":"正定/整錠",
"ㄓㄥㄉㄨㄛ":"爭奪",
"ㄓㄥㄉㄨㄟ":"正對",
"ㄓㄥㄉㄨㄢ":"爭端",
"ㄓㄥㄉㄨㄣ":"整頓",
"ㄓㄥㄊㄧㄢ":"整天",
"ㄓㄥㄊㄧㄥ":"正廳",
"ㄓㄥㄊㄨㄛ":"掙脫",
"ㄓㄥㄊㄨㄥ":"正統",
"ㄓㄥㄋㄧㄢ":"整年/正念",
"ㄓㄥㄋㄧㄥ":"猙獰",
"ㄓㄥㄌㄧㄡ":"蒸餾/整流",
"ㄓㄥㄌㄧㄢ":"整臉/爭臉",
"ㄓㄥㄌㄧㄥ":"政令",
"ㄓㄥㄌㄨㄣ":"爭論/政論",
"ㄓㄥㄌㄨㄥ":"蒸籠",
"ㄓㄥㄍㄨㄛ":"正果/證果",
"ㄓㄥㄍㄨㄟ":"正規/正軌",
"ㄓㄥㄍㄨㄤ":"爭光",
"ㄓㄥㄍㄨㄥ":"政工/正宮",
"ㄓㄥㄏㄨㄚ":"正話",
"ㄓㄥㄏㄨㄟ":"爭輝",
"ㄓㄥㄏㄨㄣ":"證婚/徵婚",
"ㄓㄥㄐㄧㄚ":"征駕",
"ㄓㄥㄐㄧㄝ":"整潔/政界",
"ㄓㄥㄐㄧㄠ":"正教/政教",
"ㄓㄥㄐㄧㄡ":"拯救",
"ㄓㄥㄐㄧㄢ":"證件/政見",
"ㄓㄥㄐㄧㄣ":"正襟",
"ㄓㄥㄐㄧㄥ":"正經/正經",
"ㄓㄥㄐㄩㄝ":"正覺",
"ㄓㄥㄐㄩㄣ":"整軍",
"ㄓㄥㄑㄧㄝ":"正切",
"ㄓㄥㄑㄧㄠ":"正橋/正巧",
"ㄓㄥㄑㄧㄡ":"徵求/爭球",
"ㄓㄥㄑㄧㄢ":"掙錢/鄭虔",
"ㄓㄥㄑㄧㄣ":"正寢",
"ㄓㄥㄑㄧㄤ":"爭強",
"ㄓㄥㄑㄧㄥ":"政情",
"ㄓㄥㄑㄧㄩ":"蒸氣浴",
"ㄓㄥㄑㄩㄝ":"正確",
"ㄓㄥㄑㄩㄢ":"政權/證券",
"ㄓㄥㄒㄧㄝ":"政協/爭些",
"ㄓㄥㄒㄧㄡ":"整修",
"ㄓㄥㄒㄧㄢ":"爭先/正弦",
"ㄓㄥㄒㄧㄣ":"正心/徵信",
"ㄓㄥㄒㄧㄤ":"正像/正項",
"ㄓㄥㄒㄧㄥ":"整型",
"ㄓㄥㄒㄩㄢ":"徵選/鄭玄",
"ㄓㄥㄒㄩㄣ":"徵詢",
"ㄓㄥㄒㄩㄥ":"正凶/爭雄",
"ㄓㄥㄓㄈㄢ":"政治犯",
"ㄓㄥㄓㄓㄢ":"政治戰",
"ㄓㄥㄓㄡㄕ":"鄭州市",
"ㄓㄥㄓㄨㄙ":"正直無私",
"ㄓㄥㄓㄨㄢ":"正傳",
"ㄓㄥㄓㄨㄤ":"徵狀/症狀",
"ㄓㄥㄓㄨㄥ":"正中/鄭重",
"ㄓㄥㄔㄨㄞ":"掙揣",
"ㄓㄥㄔㄨㄥ":"爭寵/怔忡",
"ㄓㄥㄕㄊㄧ":"正始體",
"ㄓㄥㄕㄓㄨ":"正事主",
"ㄓㄥㄕㄨㄛ":"正朔",
"ㄓㄥㄕㄨㄟ":"徵稅",
"ㄓㄥㄖㄨㄥ":"整容/崢嶸",
"ㄓㄥㄗㄈㄚ":"正字法",
"ㄓㄥㄗㄨㄛ":"證左",
"ㄓㄥㄗㄨㄟ":"爭嘴",
"ㄓㄥㄗㄨㄥ":"正宗",
"ㄓㄥㄘㄨㄛ":"掙挫",
"ㄓㄥㄘㄨㄥ":"錚鏦",
"ㄓㄥㄙㄨㄟ":"正思惟",
"ㄓㄥㄙㄨㄥ":"爭訟/諍訟",
"ㄓㄥㄧㄍㄢ":"正義感",
"ㄓㄧㄣㄕㄨ":"質因數",
"ㄓㄨㄅㄧㄠ":"朱標",
"ㄓㄨㄅㄧㄢ":"主編/主辯",
"ㄓㄨㄇㄧㄝ":"誅滅/竹篾",
"ㄓㄨㄇㄧㄣ":"住民",
"ㄓㄨㄇㄧㄥ":"著名/註明",
"ㄓㄨㄉㄠㄘ":"主導詞",
"ㄓㄨㄉㄧㄥ":"注定/註定",
"ㄓㄨㄉㄨㄛ":"諸多",
"ㄓㄨㄉㄨㄟ":"屬對/主隊",
"ㄓㄨㄉㄨㄥ":"主動",
"ㄓㄨㄊㄧㄝ":"鑄鐵",
"ㄓㄨㄊㄧㄥ":"珠庭",
"ㄓㄨㄊㄨㄛ":"屬託/囑託",
"ㄓㄨㄊㄨㄣ":"駐屯",
"ㄓㄨㄊㄨㄥ":"竹筒",
"ㄓㄨㄋㄧㄠ":"朱鳥",
"ㄓㄨㄋㄧㄢ":"逐年",
"ㄓㄨㄋㄨㄛ":"朱諾",
"ㄓㄨㄌㄧㄡ":"主流/瀦留",
"ㄓㄨㄌㄧㄢ":"株連/珠簾",
"ㄓㄨㄌㄧㄣ":"竹林/株林",
"ㄓㄨㄌㄧㄤ":"銖兩/逐涼",
"ㄓㄨㄌㄧㄥ":"屬令/豬苓",
"ㄓㄨㄌㄨㄛ":"諸羅/豬玀",
"ㄓㄨㄌㄨㄣ":"著論/朱輪",
"ㄓㄨㄌㄨㄥ":"燭龍/竹籠",
"ㄓㄨㄍㄢㄨ":"竹竿舞",
"ㄓㄨㄍㄨㄛ":"柱國",
"ㄓㄨㄍㄨㄞ":"拄拐",
"ㄓㄨㄍㄨㄢ":"主管/主觀",
"ㄓㄨㄍㄨㄤ":"燭光/朱光",
"ㄓㄨㄍㄨㄥ":"主公/豬公",
"ㄓㄨㄎㄨㄞ":"株塊",
"ㄓㄨㄎㄨㄤ":"屬纊",
"ㄓㄨㄎㄨㄥ":"主控",
"ㄓㄨㄏㄨㄚ":"珠花/燭花",
"ㄓㄨㄏㄨㄛ":"燭火",
"ㄓㄨㄏㄨㄢ":"珠還/豬獾",
"ㄓㄨㄏㄨㄣ":"主婚",
"ㄓㄨㄏㄨㄤ":"竹黃/朱黃",
"ㄓㄨㄏㄨㄥ":"朱紅/硃紅",
"ㄓㄨㄐㄧㄚ":"住家/主家",
"ㄓㄨㄐㄧㄝ":"注解/駐節",
"ㄓㄨㄐㄧㄠ":"主教/助教",
"ㄓㄨㄐㄧㄡ":"煮酒",
"ㄓㄨㄐㄧㄢ":"逐漸/主見",
"ㄓㄨㄐㄧㄣ":"逐進/朱槿",
"ㄓㄨㄐㄧㄤ":"主講/珠江",
"ㄓㄨㄐㄧㄥ":"竺經/主靜",
"ㄓㄨㄐㄩㄢ":"豬圈/硃卷",
"ㄓㄨㄐㄩㄣ":"諸君/駐軍",
"ㄓㄨㄑㄧㄘ":"支吾其詞",
"ㄓㄨㄑㄧㄡ":"誅求",
"ㄓㄨㄑㄧㄣ":"竹琴",
"ㄓㄨㄑㄩㄝ":"朱雀",
"ㄓㄨㄑㄩㄢ":"主權/朱權",
"ㄓㄨㄒㄧㄚ":"諸夏",
"ㄓㄨㄒㄧㄠ":"注銷/註銷",
"ㄓㄨㄒㄧㄡ":"主修/蠋繡",
"ㄓㄨㄒㄧㄢ":"住閒/主線",
"ㄓㄨㄒㄧㄣ":"燭信",
"ㄓㄨㄒㄧㄤ":"炷香",
"ㄓㄨㄒㄧㄥ":"主星/主刑",
"ㄓㄨㄒㄩㄝ":"植物學",
"ㄓㄨㄒㄩㄢ":"助選/逐旋",
"ㄓㄨㄓㄨㄟ":"屬綴",
"ㄓㄨㄓㄨㄥ":"注重",
"ㄓㄨㄔㄖㄣ":"主持人",
"ㄓㄨㄔㄢㄕ":"助產士",
"ㄓㄨㄔㄨㄣ":"朱脣",
"ㄓㄨㄔㄨㄥ":"蛀蟲",
"ㄓㄨㄕㄓㄜ":"主使者",
"ㄓㄨㄕㄚㄓ":"硃砂痣",
"ㄓㄨㄕㄨㄞ":"主帥",
"ㄓㄨㄕㄨㄟ":"注水/貯水",
"ㄓㄨㄖㄨㄥ":"祝融",
"ㄓㄨㄗㄨㄛ":"著作/住坐",
"ㄓㄨㄗㄨㄟ":"住嘴",
"ㄓㄨㄗㄨㄥ":"主從/豬鬃",
"ㄓㄨㄘㄤㄕ":"貯藏室",
"ㄓㄨㄘㄨㄟ":"珠翠",
"ㄓㄨㄘㄨㄣ":"貯存",
"ㄓㄨㄙㄨㄛ":"住所",
"ㄓㄨㄙㄨㄟ":"燭穗",
"ㄓㄨㄙㄨㄢ":"珠算",
"ㄓㄨㄙㄨㄣ":"竹孫/竹筍",
"ㄓㄨㄚㄋㄠ":"抓撓/撾撓",
"ㄓㄨㄚㄌㄠ":"抓牢",
"ㄓㄨㄚㄍㄣ":"抓哏",
"ㄓㄨㄚㄐㄧ":"抓髻/髽髻",
"ㄓㄨㄚㄐㄩ":"抓舉",
"ㄓㄨㄚㄓㄡ":"抓週",
"ㄓㄨㄚㄓㄨ":"抓住",
"ㄓㄨㄚㄖㄣ":"撾人",
"ㄓㄨㄚㄗㄦ":"撾子兒",
"ㄓㄨㄚㄘㄞ":"抓彩",
"ㄓㄨㄚㄧㄠ":"抓藥",
"ㄓㄨㄚㄧㄤ":"抓癢",
"ㄓㄨㄛㄅㄚ":"擢拔",
"ㄓㄨㄛㄅㄞ":"捉敗",
"ㄓㄨㄛㄅㄣ":"拙笨",
"ㄓㄨㄛㄅㄧ":"著筆/捉鼻",
"ㄓㄨㄛㄅㄨ":"桌布",
"ㄓㄨㄛㄆㄨ":"拙樸",
"ㄓㄨㄛㄇㄛ":"捉摸/著摸",
"ㄓㄨㄛㄇㄞ":"捉脈",
"ㄓㄨㄛㄇㄠ":"卓茂",
"ㄓㄨㄛㄇㄨ":"拙目/濁母",
"ㄓㄨㄛㄈㄚ":"捉髮/斫伐",
"ㄓㄨㄛㄈㄨ":"拙夫",
"ㄓㄨㄛㄉㄜ":"著地",
"ㄓㄨㄛㄉㄠ":"捉刀",
"ㄓㄨㄛㄉㄥ":"桌燈",
"ㄓㄨㄛㄉㄧ":"著地/斫地",
"ㄓㄨㄛㄊㄜ":"卓特",
"ㄓㄨㄛㄊㄡ":"捉頭/斫頭",
"ㄓㄨㄛㄊㄧ":"著題",
"ㄓㄨㄛㄋㄚ":"捉拿",
"ㄓㄨㄛㄋㄠ":"著惱/濯淖",
"ㄓㄨㄛㄌㄠ":"濁醪",
"ㄓㄨㄛㄌㄤ":"斫蜋",
"ㄓㄨㄛㄌㄧ":"著力/卓立",
"ㄓㄨㄛㄌㄨ":"著陸/涿鹿",
"ㄓㄨㄛㄍㄨ":"灼骨",
"ㄓㄨㄛㄐㄧ":"著己/著即",
"ㄓㄨㄛㄐㄩ":"琢句",
"ㄓㄨㄛㄑㄧ":"濁氣/著棋",
"ㄓㄨㄛㄒㄧ":"桌席",
"ㄓㄨㄛㄓㄠ":"桌罩",
"ㄓㄨㄛㄓㄢ":"捉顫",
"ㄓㄨㄛㄓㄤ":"茁長",
"ㄓㄨㄛㄓㄨ":"捉住/拙著",
"ㄓㄨㄛㄔㄨ":"著處",
"ㄓㄨㄛㄕㄚ":"斫殺",
"ㄓㄨㄛㄕㄠ":"灼燒",
"ㄓㄨㄛㄕㄡ":"著手/捉手",
"ㄓㄨㄛㄕㄣ":"著身/著甚",
"ㄓㄨㄛㄕㄤ":"灼傷",
"ㄓㄨㄛㄕㄥ":"擢升/捉生",
"ㄓㄨㄛㄕㄨ":"著數/卓殊",
"ㄓㄨㄛㄖㄜ":"灼熱",
"ㄓㄨㄛㄖㄢ":"著然/卓然",
"ㄓㄨㄛㄖㄣ":"著人",
"ㄓㄨㄛㄗㄠ":"啅噪",
"ㄓㄨㄛㄗㄨ":"濯足",
"ㄓㄨㄛㄘㄞ":"卓裁",
"ㄓㄨㄛㄙㄜ":"著色/拙澀",
"ㄓㄨㄛㄙㄤ":"斲喪",
"ㄓㄨㄛㄧㄢ":"拙眼",
"ㄓㄨㄛㄧㄣ":"濁音/酌飲",
"ㄓㄨㄛㄧㄥ":"斫營/濯纓",
"ㄓㄨㄛㄨㄤ":"卓望",
"ㄓㄨㄛㄩㄝ":"卓越/捉月",
"ㄓㄨㄛㄩㄥ":"擢用",
"ㄓㄨㄞㄇㄢ":"拽滿",
"ㄓㄨㄞㄊㄚ":"拽塌",
"ㄓㄨㄞㄓㄚ":"拽扎",
"ㄓㄨㄟㄅㄤ":"椎棒",
"ㄓㄨㄟㄅㄧ":"追逼",
"ㄓㄨㄟㄅㄨ":"追捕/追補",
"ㄓㄨㄟㄆㄟ":"追賠",
"ㄓㄨㄟㄇㄚ":"墜馬",
"ㄓㄨㄟㄇㄞ":"椎埋",
"ㄓㄨㄟㄇㄢ":"綴滿",
"ㄓㄨㄟㄈㄟ":"追肥",
"ㄓㄨㄟㄈㄥ":"追封",
"ㄓㄨㄟㄉㄠ":"追悼/錐刀",
"ㄓㄨㄟㄉㄥ":"墜蹬",
"ㄓㄨㄟㄉㄧ":"墜地",
"ㄓㄨㄟㄊㄠ":"追討",
"ㄓㄨㄟㄊㄧ":"錐體",
"ㄓㄨㄟㄋㄚ":"追拿",
"ㄓㄨㄟㄌㄡ":"墜樓",
"ㄓㄨㄟㄌㄧ":"惴慄",
"ㄓㄨㄟㄌㄨ":"椎魯",
"ㄓㄨㄟㄍㄢ":"追趕",
"ㄓㄨㄟㄍㄨ":"錐股/椎骨",
"ㄓㄨㄟㄏㄜ":"綴合",
"ㄓㄨㄟㄐㄧ":"追擊/綴輯",
"ㄓㄨㄟㄐㄩ":"贅聚",
"ㄓㄨㄟㄑㄧ":"追緝",
"ㄓㄨㄟㄒㄩ":"追敘/追胥",
"ㄓㄨㄟㄓㄜ":"綴宅",
"ㄓㄨㄟㄓㄨ":"追逐",
"ㄓㄨㄟㄔㄚ":"追查",
"ㄓㄨㄟㄕㄚ":"追殺",
"ㄓㄨㄟㄕㄢ":"雉尾扇",
"ㄓㄨㄟㄕㄨ":"追述/贅述",
"ㄓㄨㄟㄖㄡ":"贅肉",
"ㄓㄨㄟㄖㄣ":"追認",
"ㄓㄨㄟㄗㄤ":"追贓",
"ㄓㄨㄟㄗㄥ":"追贈",
"ㄓㄨㄟㄙㄨ":"追溯",
"ㄓㄨㄟㄧㄡ":"贅疣",
"ㄓㄨㄟㄧㄢ":"贅言",
"ㄓㄨㄟㄨㄣ":"追問/綴文",
"ㄓㄨㄟㄩㄢ":"追遠",
"ㄓㄨㄢㄅㄛ":"轉播",
"ㄓㄨㄢㄅㄟ":"轉背",
"ㄓㄨㄢㄅㄠ":"轉包",
"ㄓㄨㄢㄆㄢ":"轉盤/轉盼",
"ㄓㄨㄢㄆㄥ":"轉蓬",
"ㄓㄨㄢㄆㄧ":"磚坯",
"ㄓㄨㄢㄇㄛ":"轉磨",
"ㄓㄨㄢㄇㄞ":"轉賣/專賣",
"ㄓㄨㄢㄇㄟ":"專美",
"ㄓㄨㄢㄇㄣ":"專門/顓門",
"ㄓㄨㄢㄇㄥ":"顓蒙",
"ㄓㄨㄢㄈㄚ":"轉發",
"ㄓㄨㄢㄈㄤ":"專訪/專房",
"ㄓㄨㄢㄈㄥ":"轉風",
"ㄓㄨㄢㄈㄨ":"轉赴",
"ㄓㄨㄢㄉㄚ":"轉達/專達",
"ㄓㄨㄢㄉㄠ":"轉道",
"ㄓㄨㄢㄉㄥ":"轉燈",
"ㄓㄨㄢㄉㄨ":"轉讀",
"ㄓㄨㄢㄊㄚ":"轉踏/傳踏",
"ㄓㄨㄢㄊㄞ":"轉檯/轉臺",
"ㄓㄨㄢㄊㄡ":"轉頭/磚頭",
"ㄓㄨㄢㄊㄧ":"專題",
"ㄓㄨㄢㄌㄞ":"轉來",
"ㄓㄨㄢㄌㄡ":"轉漏",
"ㄓㄨㄢㄌㄢ":"專欄",
"ㄓㄨㄢㄌㄧ":"專利",
"ㄓㄨㄢㄌㄨ":"轉錄/專戮",
"ㄓㄨㄢㄍㄠ":"轉告/撰稿",
"ㄓㄨㄢㄍㄨ":"轉轂",
"ㄓㄨㄢㄎㄜ":"專科/篆刻",
"ㄓㄨㄢㄎㄡ":"轉口",
"ㄓㄨㄢㄎㄢ":"專刊",
"ㄓㄨㄢㄏㄡ":"囀喉",
"ㄓㄨㄢㄏㄤ":"轉行",
"ㄓㄨㄢㄏㄥ":"專橫",
"ㄓㄨㄢㄏㄨ":"專戶",
"ㄓㄨㄢㄐㄧ":"專輯/轉機",
"ㄓㄨㄢㄐㄩ":"傳車",
"ㄓㄨㄢㄑㄧ":"磚契",
"ㄓㄨㄢㄑㄩ":"專區/賺取",
"ㄓㄨㄢㄒㄧ":"轉系/轉徙",
"ㄓㄨㄢㄒㄩ":"顓頊",
"ㄓㄨㄢㄓㄜ":"轉折",
"ㄓㄨㄢㄓㄡ":"轉軸",
"ㄓㄨㄢㄓㄢ":"轉戰",
"ㄓㄨㄢㄓㄣ":"轉診",
"ㄓㄨㄢㄓㄤ":"轉帳",
"ㄓㄨㄢㄓㄥ":"專政/轉正",
"ㄓㄨㄢㄓㄨ":"專注/專著",
"ㄓㄨㄢㄔㄚ":"磚茶",
"ㄓㄨㄢㄔㄜ":"轉車/專車",
"ㄓㄨㄢㄔㄞ":"專差",
"ㄓㄨㄢㄔㄤ":"專長",
"ㄓㄨㄢㄔㄥ":"專程/專誠",
"ㄓㄨㄢㄕㄜ":"傳舍",
"ㄓㄨㄢㄕㄡ":"轉手/賺手",
"ㄓㄨㄢㄕㄢ":"專擅",
"ㄓㄨㄢㄕㄣ":"轉身/轉伸",
"ㄓㄨㄢㄕㄥ":"轉生/傳乘",
"ㄓㄨㄢㄕㄨ":"轉述/專書",
"ㄓㄨㄢㄖㄣ":"專人/轉任",
"ㄓㄨㄢㄖㄤ":"轉讓",
"ㄓㄨㄢㄖㄨ":"轉入",
"ㄓㄨㄢㄗㄜ":"專責",
"ㄓㄨㄢㄗㄞ":"轉載",
"ㄓㄨㄢㄗㄢ":"傳贊",
"ㄓㄨㄢㄗㄥ":"轉贈",
"ㄓㄨㄢㄗㄨ":"轉租",
"ㄓㄨㄢㄘㄜ":"轉側",
"ㄓㄨㄢㄘㄞ":"專才",
"ㄓㄨㄢㄘㄠ":"轉漕",
"ㄓㄨㄢㄙㄨ":"轉速",
"ㄓㄨㄢㄧㄝ":"專業/轉業",
"ㄓㄨㄢㄧㄠ":"磚窯",
"ㄓㄨㄢㄧㄢ":"轉眼/篆煙",
"ㄓㄨㄢㄧㄣ":"轉印",
"ㄓㄨㄢㄧㄥ":"專營",
"ㄓㄨㄢㄨㄚ":"磚瓦",
"ㄓㄨㄢㄨㄢ":"轉彎/轉彎",
"ㄓㄨㄢㄨㄣ":"撰文/轉文",
"ㄓㄨㄢㄩㄢ":"專員",
"ㄓㄨㄢㄩㄣ":"轉運/轉韻",
"ㄓㄨㄢㄩㄥ":"專用",
"ㄓㄨㄣㄅㄛ":"屯剝",
"ㄓㄨㄣㄅㄟ":"準備",
"ㄓㄨㄣㄅㄠ":"準保",
"ㄓㄨㄣㄆㄧ":"屯否",
"ㄓㄨㄣㄈㄨ":"准伏",
"ㄓㄨㄣㄉㄧ":"準的",
"ㄓㄨㄣㄊㄡ":"準頭/準頭",
"ㄓㄨㄣㄋㄢ":"屯難",
"ㄓㄨㄣㄋㄧ":"準擬/准擬",
"ㄓㄨㄣㄎㄢ":"屯坎",
"ㄓㄨㄣㄎㄣ":"諄懇",
"ㄓㄨㄣㄐㄩ":"準據",
"ㄓㄨㄣㄒㄧ":"窀穸",
"ㄓㄨㄣㄒㄩ":"准許",
"ㄓㄨㄣㄓㄜ":"准折",
"ㄓㄨㄣㄓㄢ":"屯邅/迍邅",
"ㄓㄨㄣㄕㄥ":"準繩",
"ㄓㄨㄣㄗㄜ":"準則",
"ㄓㄨㄣㄗㄞ":"迍災",
"ㄓㄨㄣㄗㄡ":"准奏",
"ㄓㄨㄣㄨㄟ":"准尉",
"ㄓㄨㄤㄅㄟ":"裝備/裝背",
"ㄓㄨㄤㄅㄢ":"裝扮/妝扮",
"ㄓㄨㄤㄆㄛ":"撞破",
"ㄓㄨㄤㄆㄟ":"裝配",
"ㄓㄨㄤㄆㄤ":"裝胖",
"ㄓㄨㄤㄇㄟ":"壯美",
"ㄓㄨㄤㄇㄠ":"狀貌",
"ㄓㄨㄤㄈㄤ":"莊房",
"ㄓㄨㄤㄈㄨ":"壯夫",
"ㄓㄨㄤㄉㄚ":"壯大/裝大",
"ㄓㄨㄤㄉㄢ":"裝旦/壯膽",
"ㄓㄨㄤㄊㄞ":"狀態/妝臺",
"ㄓㄨㄤㄊㄡ":"狀頭",
"ㄓㄨㄤㄊㄨ":"壯圖",
"ㄓㄨㄤㄌㄠ":"裝老",
"ㄓㄨㄤㄌㄡ":"妝樓",
"ㄓㄨㄤㄌㄤ":"壯浪",
"ㄓㄨㄤㄌㄧ":"壯麗",
"ㄓㄨㄤㄍㄨ":"裝孤/妝孤",
"ㄓㄨㄤㄎㄜ":"撞客/莊客",
"ㄓㄨㄤㄏㄢ":"裝憨",
"ㄓㄨㄤㄏㄨ":"莊戶",
"ㄓㄨㄤㄐㄧ":"撞擊",
"ㄓㄨㄤㄐㄩ":"壯舉/裝局",
"ㄓㄨㄤㄑㄧ":"壯氣",
"ㄓㄨㄤㄒㄧ":"撞席",
"ㄓㄨㄤㄓㄜ":"裝摺",
"ㄓㄨㄤㄓㄡ":"莊周",
"ㄓㄨㄤㄓㄣ":"撞針",
"ㄓㄨㄤㄓㄥ":"裝幀",
"ㄓㄨㄤㄓㄨ":"樁柱/莊主",
"ㄓㄨㄤㄔㄜ":"撞車",
"ㄓㄨㄤㄔㄡ":"撞籌",
"ㄓㄨㄤㄕㄚ":"裝傻",
"ㄓㄨㄤㄕㄜ":"裝設",
"ㄓㄨㄤㄕㄤ":"撞傷",
"ㄓㄨㄤㄕㄥ":"壯盛",
"ㄓㄨㄤㄕㄨ":"裝束/妝梳",
"ㄓㄨㄤㄖㄠ":"撞擾",
"ㄓㄨㄤㄗㄞ":"裝載",
"ㄓㄨㄤㄗㄨ":"撞族/壯族",
"ㄓㄨㄤㄙㄠ":"莊騷",
"ㄓㄨㄤㄙㄤ":"撞喪",
"ㄓㄨㄤㄧㄠ":"裝么",
"ㄓㄨㄤㄧㄡ":"壯遊/壯猷",
"ㄓㄨㄤㄧㄢ":"莊嚴/裝演",
"ㄓㄨㄤㄧㄤ":"妝樣/裝佯",
"ㄓㄨㄤㄨㄟ":"壯偉",
"ㄓㄨㄤㄩㄝ":"壯月",
"ㄓㄨㄤㄩㄢ":"狀元/莊院",
"ㄓㄨㄤㄩㄣ":"裝運",
"ㄓㄨㄥㄅㄚ":"中巴",
"ㄓㄨㄥㄅㄞ":"鐘擺",
"ㄓㄨㄥㄅㄠ":"中保/中飽",
"ㄓㄨㄥㄅㄢ":"中班/重辦",
"ㄓㄨㄥㄅㄧ":"重比",
"ㄓㄨㄥㄅㄨ":"中部",
"ㄓㄨㄥㄆㄠ":"重炮",
"ㄓㄨㄥㄆㄢ":"中盤",
"ㄓㄨㄥㄆㄧ":"種皮",
"ㄓㄨㄥㄇㄚ":"重馬/種麻",
"ㄓㄨㄥㄇㄟ":"中美",
"ㄓㄨㄥㄇㄢ":"中滿",
"ㄓㄨㄥㄇㄣ":"中門/踵門",
"ㄓㄨㄥㄇㄨ":"冢墓",
"ㄓㄨㄥㄈㄚ":"重罰",
"ㄓㄨㄥㄈㄟ":"中費",
"ㄓㄨㄥㄈㄢ":"中飯/重犯",
"ㄓㄨㄥㄈㄣ":"中分",
"ㄓㄨㄥㄈㄥ":"中風/中鋒",
"ㄓㄨㄥㄈㄨ":"中伏/中伏",
"ㄓㄨㄥㄉㄚ":"中大/重大",
"ㄓㄨㄥㄉㄜ":"種德",
"ㄓㄨㄥㄉㄞ":"重戴",
"ㄓㄨㄥㄉㄠ":"中道/中稻",
"ㄓㄨㄥㄉㄡ":"種痘",
"ㄓㄨㄥㄉㄢ":"中彈/重擔",
"ㄓㄨㄥㄉㄤ":"中檔",
"ㄓㄨㄥㄉㄥ":"中等",
"ㄓㄨㄥㄉㄧ":"重地/中地",
"ㄓㄨㄥㄉㄨ":"中毒/重讀",
"ㄓㄨㄥㄊㄚ":"鐘塔",
"ㄓㄨㄥㄊㄡ":"鐘頭",
"ㄓㄨㄥㄊㄢ":"中痰",
"ㄓㄨㄥㄊㄤ":"中堂/中堂",
"ㄓㄨㄥㄊㄨ":"中途/中土",
"ㄓㄨㄥㄋㄠ":"中腦",
"ㄓㄨㄥㄋㄢ":"中男/終南",
"ㄓㄨㄥㄋㄧ":"仲尼/鍾溺",
"ㄓㄨㄥㄋㄨ":"眾怒",
"ㄓㄨㄥㄌㄟ":"種類",
"ㄓㄨㄥㄌㄠ":"終老/中牢",
"ㄓㄨㄥㄌㄡ":"鐘樓/鐘漏",
"ㄓㄨㄥㄌㄢ":"中欄",
"ㄓㄨㄥㄌㄤ":"中郎",
"ㄓㄨㄥㄌㄧ":"中立/重力",
"ㄓㄨㄥㄌㄨ":"中路",
"ㄓㄨㄥㄌㄩ":"中呂",
"ㄓㄨㄥㄍㄥ":"中耕/忠鯁",
"ㄓㄨㄥㄍㄨ":"中古/忠告",
"ㄓㄨㄥㄎㄢ":"中看",
"ㄓㄨㄥㄎㄣ":"中肯",
"ㄓㄨㄥㄎㄨ":"中褲",
"ㄓㄨㄥㄏㄜ":"中和",
"ㄓㄨㄥㄏㄞ":"中海",
"ㄓㄨㄥㄏㄡ":"重厚/忠厚",
"ㄓㄨㄥㄏㄢ":"中寒",
"ㄓㄨㄥㄏㄨ":"中胡/中戶",
"ㄓㄨㄥㄐㄧ":"終極/中計",
"ㄓㄨㄥㄐㄩ":"中舉/中局",
"ㄓㄨㄥㄑㄧ":"中氣/中期",
"ㄓㄨㄥㄑㄩ":"中區/終曲",
"ㄓㄨㄥㄒㄧ":"中西/終席",
"ㄓㄨㄥㄒㄩ":"終須",
"ㄓㄨㄥㄓㄠ":"終朝",
"ㄓㄨㄥㄓㄡ":"中州",
"ㄓㄨㄥㄓㄣ":"重鎮/忠貞",
"ㄓㄨㄥㄓㄤ":"腫脹/重杖",
"ㄓㄨㄥㄓㄥ":"中正/中證",
"ㄓㄨㄥㄔㄠ":"中朝",
"ㄓㄨㄥㄔㄡ":"重酬",
"ㄓㄨㄥㄔㄢ":"中產",
"ㄓㄨㄥㄔㄣ":"重臣/忠臣",
"ㄓㄨㄥㄔㄤ":"中場/中常",
"ㄓㄨㄥㄔㄥ":"忠誠/中誠",
"ㄓㄨㄥㄔㄨ":"中廚/重處",
"ㄓㄨㄥㄕㄚ":"重砂",
"ㄓㄨㄥㄕㄡ":"中壽",
"ㄓㄨㄥㄕㄢ":"中山/鍾山",
"ㄓㄨㄥㄕㄣ":"終身/中身",
"ㄓㄨㄥㄕㄤ":"重傷/中上",
"ㄓㄨㄥㄕㄥ":"終生/眾生",
"ㄓㄨㄥㄕㄨ":"中暑/中樞",
"ㄓㄨㄥㄖㄣ":"眾人/中人",
"ㄓㄨㄥㄗㄜ":"重責",
"ㄓㄨㄥㄗㄞ":"冢宰",
"ㄓㄨㄥㄗㄠ":"重棗",
"ㄓㄨㄥㄗㄤ":"中藏/冢藏",
"ㄓㄨㄥㄗㄨ":"種族",
"ㄓㄨㄥㄘㄜ":"中策",
"ㄓㄨㄥㄘㄞ":"中彩/中才",
"ㄓㄨㄥㄘㄢ":"中餐/重殘",
"ㄓㄨㄥㄘㄥ":"中層",
"ㄓㄨㄥㄙㄨ":"中宿/衷素",
"ㄓㄨㄥㄧㄚ":"中亞",
"ㄓㄨㄥㄧㄝ":"中葉/中野",
"ㄓㄨㄥㄧㄠ":"重要/中藥",
"ㄓㄨㄥㄧㄡ":"中游/仲由",
"ㄓㄨㄥㄧㄢ":"重言/忠言",
"ㄓㄨㄥㄧㄣ":"中音/中音",
"ㄓㄨㄥㄧㄤ":"中央/終養",
"ㄓㄨㄥㄨㄞ":"中外",
"ㄓㄨㄥㄨㄟ":"中衛/中尉",
"ㄓㄨㄥㄨㄢ":"中浣",
"ㄓㄨㄥㄨㄣ":"中文/重文",
"ㄓㄨㄥㄨㄤ":"眾望/鍾王",
"ㄓㄨㄥㄩㄝ":"中嶽",
"ㄓㄨㄥㄩㄢ":"中原/中元",
"ㄓㄨㄥㄩㄣ":"中雲/中允",
"ㄓㄨㄥㄩㄥ":"重用/中用",
"ㄓㄨㄦㄇㄨ":"屬耳目",
"ㄓㄨㄦㄈㄣ":"珠兒粉",
"ㄓㄨㄧㄌㄧ":"注意力",
"ㄓㄨㄧㄕㄣ":"朱衣神",
"ㄓㄩㄓㄌㄜ":"知魚之樂",
"ㄓㄩㄓㄕㄢ":"止於至善",
"ㄓㄩㄢㄌㄨ":"職員錄",
"ㄓㄩㄢㄎㄚ":"志願卡",
"ㄓㄩㄢㄕㄨ":"志願書",
"ㄔㄅㄚㄅㄚ":"赤巴巴",
"ㄔㄅㄞㄅㄞ":"吃拜拜",
"ㄔㄅㄞㄈㄢ":"吃白飯",
"ㄔㄅㄤㄧㄥ":"翅膀硬",
"ㄔㄅㄧㄈㄨ":"赤壁賦",
"ㄔㄅㄨㄈㄨ":"吃不服",
"ㄔㄅㄨㄉㄜ":"吃不得",
"ㄔㄅㄨㄌㄞ":"吃不來",
"ㄔㄅㄨㄎㄞ":"吃不開",
"ㄔㄅㄨㄓㄨ":"吃不住",
"ㄔㄅㄨㄕㄤ":"吃不上",
"ㄔㄆㄞㄈㄢ":"吃派飯",
"ㄔㄆㄞㄊㄡ":"吃排頭",
"ㄔㄆㄟㄓㄤ":"吃賠賬",
"ㄔㄆㄧㄢㄕ":"吃偏食",
"ㄔㄇㄟㄙㄨ":"赤黴素",
"ㄔㄇㄨㄏㄨ":"眵目糊",
"ㄔㄈㄟㄘㄨ":"吃飛醋",
"ㄔㄈㄨㄈㄨ":"赤伏符",
"ㄔㄉㄚㄏㄨ":"吃大戶",
"ㄔㄉㄜㄌㄞ":"吃得來",
"ㄔㄉㄜㄎㄞ":"吃得開",
"ㄔㄉㄜㄓㄨ":"吃得住",
"ㄔㄉㄞㄓㄥ":"痴呆症",
"ㄔㄉㄠㄅㄠ":"吃到飽",
"ㄔㄉㄡㄈㄨ":"吃豆腐",
"ㄔㄉㄧㄥㄗ":"吃釘子",
"ㄔㄌㄜㄍㄜ":"敕勒歌",
"ㄔㄌㄠㄅㄣ":"吃老本",
"ㄔㄌㄧㄌㄧ":"赤力力",
"ㄔㄌㄨㄛㄙ":"吃螺絲",
"ㄔㄍㄢㄘㄨ":"吃乾醋",
"ㄔㄍㄨㄢㄗ":"吃館子",
"ㄔㄍㄨㄢㄙ":"吃官司",
"ㄔㄎㄠㄌㄠ":"吃犒勞",
"ㄔㄎㄢㄌㄡ":"赤嵌樓",
"ㄔㄎㄨㄊㄡ":"吃苦頭",
"ㄔㄏㄨㄉㄢ":"喫虎膽",
"ㄔㄏㄨㄛㄜ":"尺蠖蛾",
"ㄔㄓㄧㄅㄧ":"嗤之以鼻",
"ㄔㄓㄧㄏㄥ":"持之以恆",
"ㄔㄓㄨㄥㄨ":"池中物",
"ㄔㄕㄤㄌㄜ":"吃傷了",
"ㄔㄗㄌㄞㄕ":"吃自來食",
"ㄔㄙㄨㄥㄗ":"赤松子",
"ㄔㄚㄅㄛㄕ":"茶博士",
"ㄔㄚㄅㄧㄝ":"差別",
"ㄔㄚㄅㄧㄠ":"查表/插標",
"ㄔㄚㄅㄧㄢ":"查邊",
"ㄔㄚㄅㄧㄥ":"茶餅",
"ㄔㄚㄆㄚㄗ":"叉爬子",
"ㄔㄚㄆㄧㄠ":"查票",
"ㄔㄚㄆㄧㄣ":"茶品",
"ㄔㄚㄆㄧㄥ":"插屏",
"ㄔㄚㄇㄚㄕ":"茶馬市",
"ㄔㄚㄇㄧㄥ":"查明",
"ㄔㄚㄉㄧㄝ":"差迭",
"ㄔㄚㄉㄧㄢ":"茶點/查點",
"ㄔㄚㄉㄧㄥ":"插定/茶鼎",
"ㄔㄚㄉㄨㄟ":"插隊/查對",
"ㄔㄚㄊㄧㄠ":"插條",
"ㄔㄚㄊㄧㄢ":"插天",
"ㄔㄚㄊㄧㄥ":"察聽/茶亭",
"ㄔㄚㄊㄨㄛ":"茶托",
"ㄔㄚㄋㄨㄥ":"茶農",
"ㄔㄚㄌㄧㄡ":"岔流/汊流",
"ㄔㄚㄍㄤㄗ":"茶缸子",
"ㄔㄚㄍㄨㄛ":"茶果",
"ㄔㄚㄍㄨㄢ":"茶館",
"ㄔㄚㄍㄨㄣ":"叉棍",
"ㄔㄚㄏㄨㄚ":"插畫/插花",
"ㄔㄚㄏㄨㄛ":"查獲",
"ㄔㄚㄏㄨㄟ":"茶會/察惠",
"ㄔㄚㄏㄨㄢ":"岔換",
"ㄔㄚㄐㄧㄚ":"差價/插架",
"ㄔㄚㄐㄧㄝ":"插接/查詰",
"ㄔㄚㄐㄧㄠ":"插腳",
"ㄔㄚㄐㄧㄡ":"查究/茶酒",
"ㄔㄚㄐㄧㄢ":"茶鹼",
"ㄔㄚㄐㄧㄣ":"差勁/茶金",
"ㄔㄚㄐㄧㄥ":"茶經/察警",
"ㄔㄚㄐㄩㄗ":"茶局子",
"ㄔㄚㄐㄩㄝ":"察覺",
"ㄔㄚㄑㄧㄢ":"茶錢",
"ㄔㄚㄑㄧㄣ":"查勤",
"ㄔㄚㄑㄧㄤ":"茶槍",
"ㄔㄚㄑㄧㄥ":"茶青",
"ㄔㄚㄑㄩㄢ":"查拳",
"ㄔㄚㄒㄧㄠ":"插銷",
"ㄔㄚㄒㄧㄡ":"茶鏽",
"ㄔㄚㄒㄧㄢ":"茶筅",
"ㄔㄚㄒㄧㄤ":"察相",
"ㄔㄚㄒㄩㄝ":"查學",
"ㄔㄚㄒㄩㄣ":"查詢/查訊",
"ㄔㄚㄓㄨㄛ":"茶桌",
"ㄔㄚㄓㄨㄢ":"茶磚",
"ㄔㄚㄓㄨㄤ":"茶莊/插狀",
"ㄔㄚㄓㄨㄥ":"茶鍾/茶盅",
"ㄔㄚㄔㄨㄟ":"茶炊",
"ㄔㄚㄔㄨㄢ":"茶船",
"ㄔㄚㄕㄥㄦ":"差聲兒",
"ㄔㄚㄕㄨㄟ":"茶水",
"ㄔㄚㄗㄨㄛ":"茶座/插座",
"ㄔㄚㄗㄨㄟ":"插嘴/叉嘴",
"ㄔㄚㄘㄨㄛ":"差錯/岔錯",
"ㄔㄚㄧㄈㄚ":"差異法",
"ㄔㄜㄅㄚㄕ":"車把式",
"ㄔㄜㄅㄧㄥ":"撤兵/撤併",
"ㄔㄜㄆㄥㄗ":"車篷子",
"ㄔㄜㄆㄧㄠ":"車票/扯票",
"ㄔㄜㄆㄧㄥ":"扯平",
"ㄔㄜㄇㄧㄢ":"扯麵",
"ㄔㄜㄉㄧㄢ":"掣電/撤佃",
"ㄔㄜㄉㄨㄟ":"車隊",
"ㄔㄜㄊㄨㄛ":"掣脫/撤脫",
"ㄔㄜㄊㄨㄟ":"撤退/扯腿",
"ㄔㄜㄊㄨㄥ":"車僮",
"ㄔㄜㄋㄧㄢ":"車輦",
"ㄔㄜㄌㄢㄨ":"拆爛汙",
"ㄔㄜㄌㄧㄝ":"坼裂",
"ㄔㄜㄌㄧㄡ":"車流",
"ㄔㄜㄌㄧㄢ":"撤簾",
"ㄔㄜㄌㄧㄣ":"車鄰",
"ㄔㄜㄌㄧㄤ":"車輛",
"ㄔㄜㄌㄧㄥ":"扯鈴",
"ㄔㄜㄌㄨㄛ":"扯落",
"ㄔㄜㄌㄨㄣ":"車輪",
"ㄔㄜㄍㄨㄟ":"車軌",
"ㄔㄜㄍㄨㄤ":"掣光",
"ㄔㄜㄍㄨㄥ":"車工/車攻",
"ㄔㄜㄏㄨㄛ":"車禍",
"ㄔㄜㄏㄨㄟ":"撤回",
"ㄔㄜㄏㄨㄢ":"撤換",
"ㄔㄜㄏㄨㄤ":"扯謊",
"ㄔㄜㄐㄧㄚ":"車架",
"ㄔㄜㄐㄧㄣ":"扯筋",
"ㄔㄜㄐㄩㄣ":"撤軍",
"ㄔㄜㄑㄧㄢ":"車錢/車前",
"ㄔㄜㄑㄩㄢ":"扯勸",
"ㄔㄜㄒㄧㄚ":"車舝",
"ㄔㄜㄒㄧㄠ":"撤消/撤銷",
"ㄔㄜㄒㄧㄤ":"車廂",
"ㄔㄜㄒㄩㄢ":"徹懸",
"ㄔㄜㄓㄨㄢ":"徹饌",
"ㄔㄜㄓㄨㄥ":"車種",
"ㄔㄜㄔㄤㄗ":"車廠子",
"ㄔㄜㄔㄨㄤ":"車床",
"ㄔㄜㄕㄨㄟ":"車水",
"ㄔㄜㄖㄨㄥ":"車容",
"ㄔㄜㄘㄨㄛ":"扯撮",
"ㄔㄜㄙㄤㄗ":"車桑仔",
"ㄔㄞㄅㄧㄢ":"拆辯",
"ㄔㄞㄆㄧㄠ":"差票",
"ㄔㄞㄉㄧㄠ":"拆掉",
"ㄔㄞㄉㄨㄟ":"拆兌",
"ㄔㄞㄌㄢㄨ":"拆爛汙",
"ㄔㄞㄌㄧㄠ":"柴寮",
"ㄔㄞㄌㄧㄥ":"拆零",
"ㄔㄞㄌㄨㄣ":"差輪/儕倫",
"ㄔㄞㄍㄨㄢ":"差官",
"ㄔㄞㄎㄨㄢ":"拆款",
"ㄔㄞㄏㄨㄛ":"拆夥/柴火",
"ㄔㄞㄏㄨㄟ":"拆毀/柴毀",
"ㄔㄞㄐㄧㄝ":"拆解/拆借",
"ㄔㄞㄐㄧㄥ":"柴荊",
"ㄔㄞㄑㄧㄢ":"差遣/差錢",
"ㄔㄞㄒㄧㄝ":"拆卸",
"ㄔㄞㄒㄧㄢ":"拆線",
"ㄔㄞㄒㄧㄣ":"柴薪",
"ㄔㄞㄔㄨㄢ":"拆穿/釵釧",
"ㄔㄞㄕㄨㄟ":"柴水",
"ㄔㄞㄖㄨㄥ":"柴榮",
"ㄔㄞㄗㄍㄜ":"拆字格",
"ㄔㄠㄅㄚㄗ":"抄靶子",
"ㄔㄠㄅㄛㄕ":"超博士",
"ㄔㄠㄅㄧㄢ":"超編",
"ㄔㄠㄅㄧㄥ":"炒冰/朝柄",
"ㄔㄠㄅㄨㄓ":"晁補之",
"ㄔㄠㄆㄧㄠ":"鈔票",
"ㄔㄠㄆㄧㄣ":"超頻/朝聘",
"ㄔㄠㄇㄧㄢ":"炒麵",
"ㄔㄠㄉㄡㄦ":"炒豆兒",
"ㄔㄠㄉㄧㄥ":"朝頂",
"ㄔㄠㄉㄨㄛ":"抄奪/抄掇",
"ㄔㄠㄊㄧㄝ":"炒鐵",
"ㄔㄠㄊㄧㄢ":"朝天",
"ㄔㄠㄊㄧㄥ":"朝廷",
"ㄔㄠㄊㄨㄛ":"超脫",
"ㄔㄠㄋㄨㄥ":"嘲弄",
"ㄔㄠㄋㄩㄝ":"嘲謔",
"ㄔㄠㄌㄧㄗ":"炒栗子",
"ㄔㄠㄌㄧㄡ":"潮流",
"ㄔㄠㄌㄧㄥ":"超齡",
"ㄔㄠㄌㄨㄛ":"鈔邏",
"ㄔㄠㄌㄨㄣ":"超倫",
"ㄔㄠㄌㄩㄝ":"抄掠/鈔掠",
"ㄔㄠㄍㄨㄛ":"超過",
"ㄔㄠㄍㄨㄟ":"朝貴",
"ㄔㄠㄍㄨㄢ":"鈔關",
"ㄔㄠㄍㄨㄥ":"朝貢",
"ㄔㄠㄎㄨㄟ":"超虧",
"ㄔㄠㄏㄨㄚ":"抄化",
"ㄔㄠㄏㄨㄛ":"炒貨/抄獲",
"ㄔㄠㄏㄨㄟ":"嘲詼",
"ㄔㄠㄏㄨㄥ":"潮紅/超烘",
"ㄔㄠㄐㄧㄚ":"抄家/吵架",
"ㄔㄠㄐㄧㄝ":"潮解",
"ㄔㄠㄐㄧㄡ":"嘲啾",
"ㄔㄠㄐㄧㄢ":"朝見/抄件",
"ㄔㄠㄐㄧㄣ":"朝巾/朝覲",
"ㄔㄠㄐㄩㄝ":"超絕",
"ㄔㄠㄑㄧㄝ":"勦竊",
"ㄔㄠㄑㄧㄡ":"抄球",
"ㄔㄠㄑㄧㄢ":"超遷",
"ㄔㄠㄑㄧㄤ":"超強/抄搶",
"ㄔㄠㄑㄩㄣ":"超群",
"ㄔㄠㄒㄧㄝ":"抄寫",
"ㄔㄠㄒㄧㄠ":"嘲笑",
"ㄔㄠㄒㄧㄢ":"朝獻/朝憲",
"ㄔㄠㄒㄧㄣ":"潮信",
"ㄔㄠㄒㄧㄤ":"朝向/巢箱",
"ㄔㄠㄒㄩㄝ":"巢穴",
"ㄔㄠㄒㄩㄣ":"潮汛",
"ㄔㄠㄓㄨㄛ":"超卓",
"ㄔㄠㄓㄨㄢ":"抄轉",
"ㄔㄠㄓㄨㄥ":"超重",
"ㄔㄠㄕㄨㄛ":"勦說",
"ㄔㄠㄕㄨㄟ":"潮水",
"ㄔㄠㄗㄖㄢ":"超自然",
"ㄔㄠㄗㄨㄛ":"炒作",
"ㄔㄠㄗㄨㄟ":"吵嘴",
"ㄔㄠㄗㄨㄥ":"朝宗",
"ㄔㄠㄘㄨㄛ":"抄撮",
"ㄔㄠㄙㄨㄥ":"抄送",
"ㄔㄠㄧㄡㄩ":"炒魷魚",
"ㄔㄠㄨㄛㄗ":"吵窩子",
"ㄔㄡㄅㄧㄣ":"酬賓",
"ㄔㄡㄆㄛㄗ":"丑婆子",
"ㄔㄡㄇㄧㄥ":"臭名/醜名",
"ㄔㄡㄉㄧㄝ":"稠疊",
"ㄔㄡㄉㄧㄠ":"抽調",
"ㄔㄡㄉㄧㄢ":"抽點",
"ㄔㄡㄉㄧㄥ":"抽丁",
"ㄔㄡㄉㄨㄛ":"籌度",
"ㄔㄡㄉㄨㄟ":"酬對",
"ㄔㄡㄉㄨㄢ":"綢緞",
"ㄔㄡㄉㄨㄥ":"抽動",
"ㄔㄡㄊㄢㄘ":"搊彈詞",
"ㄔㄡㄊㄧㄗ":"抽梯子",
"ㄔㄡㄊㄧㄠ":"抽條",
"ㄔㄡㄌㄥㄗ":"抽冷子",
"ㄔㄡㄌㄧㄣ":"愁霖",
"ㄔㄡㄌㄧㄤ":"籌量",
"ㄔㄡㄌㄩㄝ":"籌略",
"ㄔㄡㄍㄨㄢ":"疇官",
"ㄔㄡㄎㄡㄦ":"仇口兒",
"ㄔㄡㄎㄨㄢ":"籌款",
"ㄔㄡㄎㄨㄥ":"抽空",
"ㄔㄡㄏㄨㄚ":"醜化/籌劃",
"ㄔㄡㄏㄨㄢ":"抽換",
"ㄔㄡㄐㄧㄚ":"仇家",
"ㄔㄡㄐㄧㄠ":"抽腳/讎校",
"ㄔㄡㄐㄧㄢ":"瞅見/籌建",
"ㄔㄡㄐㄧㄣ":"抽筋/酬金",
"ㄔㄡㄐㄧㄤ":"抽獎",
"ㄔㄡㄐㄩㄝ":"丑角",
"ㄔㄡㄑㄧㄡ":"抽球",
"ㄔㄡㄑㄧㄢ":"抽籤/臭錢",
"ㄔㄡㄒㄧㄚ":"抽暇",
"ㄔㄡㄒㄧㄝ":"抽血/酬謝",
"ㄔㄡㄒㄧㄢ":"抽閒/臭腺",
"ㄔㄡㄒㄧㄣ":"抽心",
"ㄔㄡㄒㄧㄤ":"抽象/仇香",
"ㄔㄡㄒㄧㄥ":"醜行",
"ㄔㄡㄓㄨㄛ":"稠濁",
"ㄔㄡㄔㄨㄣ":"臭椿",
"ㄔㄡㄔㄨㄥ":"臭蟲",
"ㄔㄡㄕㄨㄟ":"抽稅",
"ㄔㄡㄖㄨㄥ":"愁容",
"ㄔㄡㄗㄨㄛ":"愁坐/酬酢",
"ㄔㄡㄘㄨㄛ":"籌措/搊撮",
"ㄔㄡㄙㄨㄟ":"抽穗",
"ㄔㄡㄙㄨㄢ":"籌算/籌筭",
"ㄔㄡㄨㄣㄓ":"綢紋紙",
"ㄔㄢㄅㄧㄣ":"蟬鬢",
"ㄔㄢㄆㄧㄣ":"產品",
"ㄔㄢㄆㄧㄥ":"剷平/鏟平",
"ㄔㄢㄇㄧㄢ":"纏綿",
"ㄔㄢㄇㄧㄥ":"闡明",
"ㄔㄢㄈㄣㄗ":"摻分子",
"ㄔㄢㄉㄧㄥ":"禪定/讒鼎",
"ㄔㄢㄉㄨㄛ":"攙奪",
"ㄔㄢㄉㄨㄟ":"攙兌",
"ㄔㄢㄊㄧㄢ":"澶湉",
"ㄔㄢㄊㄨㄛ":"饞唾",
"ㄔㄢㄊㄨㄟ":"蟬蛻",
"ㄔㄢㄋㄧㄥ":"讒佞/諂佞",
"ㄔㄢㄌㄧㄠ":"饞獠",
"ㄔㄢㄌㄧㄢ":"嬋連/蟬聯",
"ㄔㄢㄌㄧㄣ":"禪林",
"ㄔㄢㄌㄧㄤ":"產量",
"ㄔㄢㄌㄧㄥ":"纏令",
"ㄔㄢㄌㄨㄢ":"產卵",
"ㄔㄢㄍㄨㄟ":"蟾桂",
"ㄔㄢㄍㄨㄢ":"禪關/蟬冠",
"ㄔㄢㄍㄨㄤ":"蟾光",
"ㄔㄢㄍㄨㄥ":"蟾宮",
"ㄔㄢㄎㄨㄣ":"孱困",
"ㄔㄢㄎㄨㄥ":"攙空",
"ㄔㄢㄏㄜㄗ":"禪和子",
"ㄔㄢㄏㄨㄛ":"攙和/摻和",
"ㄔㄢㄏㄨㄟ":"懺悔/儃佪",
"ㄔㄢㄏㄨㄢ":"闡緩",
"ㄔㄢㄐㄧㄚ":"產假/攙假",
"ㄔㄢㄐㄧㄝ":"纏結",
"ㄔㄢㄐㄧㄠ":"纏腳/纏繳",
"ㄔㄢㄐㄧㄡ":"闡究",
"ㄔㄢㄐㄧㄢ":"讒間",
"ㄔㄢㄐㄩㄢ":"嬋娟/蟺蜎",
"ㄔㄢㄐㄩㄣ":"巉峻",
"ㄔㄢㄑㄧㄠ":"巉峭",
"ㄔㄢㄑㄧㄢ":"產鉗/纏牽",
"ㄔㄢㄑㄧㄣ":"攙親",
"ㄔㄢㄑㄩㄢ":"產權",
"ㄔㄢㄒㄧㄠ":"諂笑/產銷",
"ㄔㄢㄒㄧㄢ":"攙先/產險",
"ㄔㄢㄒㄧㄣ":"禪心",
"ㄔㄢㄒㄩㄝ":"禪學",
"ㄔㄢㄓㄨㄛ":"瀺灂",
"ㄔㄢㄔㄨㄤ":"禪床",
"ㄔㄢㄕㄨㄟ":"滻水/羼水",
"ㄔㄢㄖㄨㄛ":"孱弱",
"ㄔㄢㄗㄨㄟ":"饞嘴",
"ㄔㄢㄗㄨㄥ":"禪宗",
"ㄔㄢㄙㄨㄛ":"孱瑣",
"ㄔㄢㄙㄨㄥ":"纏訟",
"ㄔㄣㄅㄛㄓ":"陳伯之",
"ㄔㄣㄅㄧㄠ":"塵表",
"ㄔㄣㄅㄧㄢ":"陳編/趁便",
"ㄔㄣㄅㄧㄥ":"陳病",
"ㄔㄣㄆㄧㄥ":"陳平",
"ㄔㄣㄆㄨㄦ":"襯鋪兒",
"ㄔㄣㄇㄧㄢ":"沉眠/沉綿",
"ㄔㄣㄇㄧㄣ":"臣民",
"ㄔㄣㄇㄧㄥ":"沉冥",
"ㄔㄣㄈㄥㄕ":"趁風勢",
"ㄔㄣㄉㄧㄢ":"沉澱",
"ㄔㄣㄉㄨㄢ":"宸斷",
"ㄔㄣㄊㄡㄕ":"趁頭市",
"ㄔㄣㄊㄧㄝ":"襯貼",
"ㄔㄣㄊㄨㄛ":"襯托",
"ㄔㄣㄊㄨㄢ":"陳摶",
"ㄔㄣㄊㄨㄥ":"沉痛",
"ㄔㄣㄋㄧㄢ":"陳年/塵念",
"ㄔㄣㄌㄧㄝ":"陳列",
"ㄔㄣㄌㄧㄠ":"臣僚",
"ㄔㄣㄌㄧㄡ":"陳留/宸旒",
"ㄔㄣㄌㄧㄢ":"晨練/陳鍊",
"ㄔㄣㄌㄧㄣ":"陳琳",
"ㄔㄣㄌㄧㄤ":"陳亮",
"ㄔㄣㄌㄨㄛ":"沉落",
"ㄔㄣㄌㄨㄣ":"沉淪",
"ㄔㄣㄍㄨㄞ":"嗔怪",
"ㄔㄣㄍㄨㄟ":"陳規",
"ㄔㄣㄍㄨㄤ":"晨光/辰光",
"ㄔㄣㄍㄨㄥ":"趁工/臣工",
"ㄔㄣㄎㄨㄣ":"忱悃",
"ㄔㄣㄎㄨㄥ":"趁空",
"ㄔㄣㄏㄨㄛ":"陳貨",
"ㄔㄣㄏㄨㄟ":"瞋恚",
"ㄔㄣㄏㄨㄢ":"塵寰",
"ㄔㄣㄏㄨㄣ":"晨昏",
"ㄔㄣㄏㄨㄥ":"陳鴻/趁哄",
"ㄔㄣㄐㄧㄚ":"沉枷",
"ㄔㄣㄐㄧㄝ":"塵界/塵劫",
"ㄔㄣㄐㄧㄡ":"陳舊/陳酒",
"ㄔㄣㄐㄧㄢ":"陳見/塵間",
"ㄔㄣㄐㄧㄣ":"沉浸/塵襟",
"ㄔㄣㄐㄧㄤ":"郴江",
"ㄔㄣㄐㄧㄥ":"沉靜/襯景",
"ㄔㄣㄐㄧㄨ":"沉積物",
"ㄔㄣㄐㄩㄢ":"宸眷",
"ㄔㄣㄑㄧㄝ":"臣妾",
"ㄔㄣㄑㄧㄠ":"陳橋",
"ㄔㄣㄑㄧㄢ":"趁錢/襯錢",
"ㄔㄣㄑㄧㄥ":"陳情/陳請",
"ㄔㄣㄑㄩㄣ":"襯裙",
"ㄔㄣㄒㄧㄠ":"塵囂",
"ㄔㄣㄒㄧㄢ":"沉陷",
"ㄔㄣㄒㄧㄣ":"沉心/趁心",
"ㄔㄣㄒㄧㄤ":"沉香/沉箱",
"ㄔㄣㄒㄧㄥ":"晨星/辰星",
"ㄔㄣㄒㄩㄢ":"陳玄",
"ㄔㄣㄒㄩㄣ":"陳洵",
"ㄔㄣㄒㄩㄥ":"沉雄",
"ㄔㄣㄓㄈㄢ":"陳之藩",
"ㄔㄣㄓㄨㄛ":"沉著/踸踔",
"ㄔㄣㄓㄨㄤ":"陳狀",
"ㄔㄣㄓㄨㄥ":"沉重/宸衷",
"ㄔㄣㄔㄨㄟ":"晨炊",
"ㄔㄣㄔㄨㄢ":"趁船",
"ㄔㄣㄔㄨㄣ":"陳淳",
"ㄔㄣㄕㄉㄠ":"陳師道",
"ㄔㄣㄕㄨㄛ":"陳說",
"ㄔㄣㄕㄨㄟ":"沉睡/沉水",
"ㄔㄣㄗㄨㄟ":"沉醉",
"ㄔㄣㄗㄨㄣ":"陳遵",
"ㄔㄣㄘㄨㄥ":"宸聰",
"ㄔㄣㄙㄨㄟ":"沉邃",
"ㄔㄣㄙㄨㄢ":"宸算",
"ㄔㄣㄙㄨㄤ":"陳思王",
"ㄔㄣㄨㄉㄧ":"陳武帝",
"ㄔㄤㄅㄧㄢ":"長便/長編",
"ㄔㄤㄅㄧㄥ":"長兵",
"ㄔㄤㄆㄧㄠ":"唱票",
"ㄔㄤㄆㄧㄢ":"長篇/唱片",
"ㄔㄤㄆㄧㄥ":"昌平",
"ㄔㄤㄇㄧㄢ":"場面/長眠",
"ㄔㄤㄇㄧㄥ":"長命/昌明",
"ㄔㄤㄉㄧㄠ":"長調",
"ㄔㄤㄉㄧㄢ":"娼店",
"ㄔㄤㄉㄨㄢ":"長短/唱段",
"ㄔㄤㄉㄨㄣ":"長噸",
"ㄔㄤㄊㄧㄠ":"倡條/長挑",
"ㄔㄤㄊㄧㄢ":"敞田",
"ㄔㄤㄊㄧㄥ":"長亭/敞廳",
"ㄔㄤㄊㄨㄥ":"暢通",
"ㄔㄤㄋㄧㄢ":"長年/常年",
"ㄔㄤㄌㄧㄝ":"長鬣",
"ㄔㄤㄌㄧㄠ":"倡寮/娼寮",
"ㄔㄤㄌㄧㄡ":"長流",
"ㄔㄤㄌㄧㄣ":"長林",
"ㄔㄤㄌㄧㄤ":"常量/敞亮",
"ㄔㄤㄌㄧㄥ":"長齡/長陵",
"ㄔㄤㄌㄨㄢ":"猖亂",
"ㄔㄤㄌㄨㄣ":"唱論/常倫",
"ㄔㄤㄌㄨㄥ":"長龍/昌隆",
"ㄔㄤㄍㄨㄟ":"常規/長跪",
"ㄔㄤㄍㄨㄢ":"長關",
"ㄔㄤㄍㄨㄤ":"昌光",
"ㄔㄤㄍㄨㄥ":"長工/唱工",
"ㄔㄤㄎㄡㄦ":"敞口兒",
"ㄔㄤㄎㄨㄞ":"暢快/敞快",
"ㄔㄤㄎㄨㄤ":"猖狂",
"ㄔㄤㄎㄨㄥ":"長空",
"ㄔㄤㄏㄨㄚ":"長話",
"ㄔㄤㄏㄨㄞ":"暢懷/敞懷",
"ㄔㄤㄏㄨㄟ":"常會",
"ㄔㄤㄏㄨㄢ":"償還",
"ㄔㄤㄏㄨㄤ":"悵怳/惝怳",
"ㄔㄤㄏㄨㄥ":"長紅/長虹",
"ㄔㄤㄐㄧㄚ":"長假/倡家",
"ㄔㄤㄐㄧㄝ":"長解",
"ㄔㄤㄐㄧㄠ":"唱叫",
"ㄔㄤㄐㄧㄡ":"長久/常久",
"ㄔㄤㄐㄧㄢ":"常見/常建",
"ㄔㄤㄐㄧㄤ":"長江",
"ㄔㄤㄐㄧㄥ":"場景/常經",
"ㄔㄤㄐㄩㄝ":"猖獗",
"ㄔㄤㄑㄧㄠ":"嘗巧",
"ㄔㄤㄑㄧㄡ":"長楸",
"ㄔㄤㄑㄧㄢ":"長錢",
"ㄔㄤㄑㄧㄤ":"唱腔/長槍",
"ㄔㄤㄑㄧㄥ":"長青/常情",
"ㄔㄤㄒㄧㄚ":"長夏/暢洽",
"ㄔㄤㄒㄧㄠ":"暢銷/長嘯",
"ㄔㄤㄒㄧㄢ":"長線/腸線",
"ㄔㄤㄒㄧㄣ":"嘗新",
"ㄔㄤㄒㄧㄤ":"暢想/長項",
"ㄔㄤㄒㄧㄥ":"長行/常行",
"ㄔㄤㄓㄨㄢ":"唱賺",
"ㄔㄤㄔㄨㄛ":"長戳",
"ㄔㄤㄔㄨㄢ":"長川/常川",
"ㄔㄤㄔㄨㄥ":"長蟲",
"ㄔㄤㄕㄅㄢ":"唱詩班",
"ㄔㄤㄕㄚㄕ":"長沙市",
"ㄔㄤㄖㄨㄥ":"廠容/昌容",
"ㄔㄤㄗㄨㄛ":"唱作",
"ㄔㄤㄘㄨㄣ":"長存",
"ㄔㄤㄙㄨㄛ":"場所",
"ㄔㄤㄙㄨㄟ":"長隨/倡隨",
"ㄔㄤㄙㄨㄢ":"長算/嘗酸",
"ㄔㄤㄨㄣㄩ":"長吻魚",
"ㄔㄤㄩㄥㄘ":"常用詞",
"ㄔㄥㄅㄧㄢ":"稱便/乘便",
"ㄔㄥㄅㄧㄥ":"稱病/稱兵",
"ㄔㄥㄆㄢㄗ":"秤盤子",
"ㄔㄥㄆㄧㄢ":"成篇",
"ㄔㄥㄆㄧㄣ":"成品",
"ㄔㄥㄆㄧㄥ":"承平",
"ㄔㄥㄇㄧㄠ":"程邈",
"ㄔㄥㄇㄧㄢ":"成眠",
"ㄔㄥㄇㄧㄥ":"成名/成命",
"ㄔㄥㄈㄤㄦ":"成方兒",
"ㄔㄥㄈㄨㄦ":"成副兒",
"ㄔㄥㄉㄧㄝ":"城堞",
"ㄔㄥㄉㄧㄠ":"城雕",
"ㄔㄥㄉㄧㄢ":"成典",
"ㄔㄥㄉㄧㄥ":"成丁",
"ㄔㄥㄉㄨㄕ":"成都市",
"ㄔㄥㄉㄨㄟ":"成對/承兌",
"ㄔㄥㄊㄧㄠ":"承祧/承蜩",
"ㄔㄥㄊㄧㄢ":"成天/乘田",
"ㄔㄥㄊㄨㄛ":"秤砣",
"ㄔㄥㄊㄨㄥ":"成童",
"ㄔㄥㄋㄧㄡ":"秤紐",
"ㄔㄥㄋㄧㄢ":"成年",
"ㄔㄥㄋㄨㄛ":"承諾",
"ㄔㄥㄌㄧㄡ":"乘流/承霤",
"ㄔㄥㄌㄧㄢ":"成連/成殮",
"ㄔㄥㄌㄧㄣ":"成林",
"ㄔㄥㄌㄧㄤ":"乘涼",
"ㄔㄥㄌㄧㄥ":"承領",
"ㄔㄥㄌㄨㄢ":"稱亂/乘亂",
"ㄔㄥㄌㄨㄥ":"成龍/乘龍",
"ㄔㄥㄍㄜㄦ":"成個兒",
"ㄔㄥㄍㄨㄛ":"成果/城郭",
"ㄔㄥㄍㄨㄟ":"成規",
"ㄔㄥㄍㄨㄢ":"城關/澄觀",
"ㄔㄥㄍㄨㄥ":"成功/城工",
"ㄔㄥㄎㄨㄞ":"稱快",
"ㄔㄥㄎㄨㄥ":"誠恐/程控",
"ㄔㄥㄏㄨㄚ":"成化/乘化",
"ㄔㄥㄏㄨㄛ":"成活",
"ㄔㄥㄏㄨㄞ":"騁懷",
"ㄔㄥㄏㄨㄢ":"成歡/承歡",
"ㄔㄥㄏㄨㄣ":"成婚",
"ㄔㄥㄏㄨㄤ":"城隍/乘黃",
"ㄔㄥㄏㄨㄥ":"噌吰",
"ㄔㄥㄐㄧㄚ":"成家/撐家",
"ㄔㄥㄐㄧㄝ":"承接/承睫",
"ㄔㄥㄐㄧㄠ":"成交/承教",
"ㄔㄥㄐㄧㄡ":"成就/乘酒",
"ㄔㄥㄐㄧㄢ":"成見/乘間",
"ㄔㄥㄐㄧㄥ":"成精/程敬",
"ㄔㄥㄐㄩㄣ":"成均",
"ㄔㄥㄑㄧㄣ":"成親/成擒",
"ㄔㄥㄑㄧㄤ":"逞強/城牆",
"ㄔㄥㄑㄧㄥ":"澄清/稱情",
"ㄔㄥㄑㄩㄝ":"城闕",
"ㄔㄥㄑㄩㄢ":"成全/懲勸",
"ㄔㄥㄑㄩㄣ":"成群",
"ㄔㄥㄒㄧㄝ":"稱謝",
"ㄔㄥㄒㄧㄠ":"成效/承銷",
"ㄔㄥㄒㄧㄡ":"承修",
"ㄔㄥㄒㄧㄢ":"呈現/成仙",
"ㄔㄥㄒㄧㄣ":"誠信/誠心",
"ㄔㄥㄒㄧㄤ":"丞相/城鄉",
"ㄔㄥㄒㄧㄥ":"成形/成行",
"ㄔㄥㄒㄩㄢ":"承宣/乘軒",
"ㄔㄥㄒㄩㄥ":"稱雄/逞凶",
"ㄔㄥㄓㄨㄢ":"承轉/乘傳",
"ㄔㄥㄓㄨㄣ":"呈准",
"ㄔㄥㄓㄨㄥ":"城中/承重",
"ㄔㄥㄔㄨㄟ":"秤錘",
"ㄔㄥㄔㄨㄢ":"乘船/撐船",
"ㄔㄥㄔㄨㄥ":"成蟲/承寵",
"ㄔㄥㄕㄨㄛ":"成說/稱說",
"ㄔㄥㄕㄨㄣ":"承順",
"ㄔㄥㄕㄨㄤ":"成雙",
"ㄔㄥㄗㄨㄛ":"乘坐",
"ㄔㄥㄗㄨㄥ":"成總/逞縱",
"ㄔㄥㄘㄨㄥ":"琤瑽",
"ㄔㄥㄙㄨㄢ":"成算",
"ㄔㄥㄙㄨㄥ":"稱頌/成誦",
"ㄔㄥㄣㄇㄣ":"承恩門",
"ㄔㄥㄧㄆㄨ":"成衣鋪",
"ㄔㄥㄧㄔㄤ":"成衣廠",
"ㄔㄥㄨㄛㄦ":"成窩兒",
"ㄔㄥㄨㄩㄢ":"乘務員",
"ㄔㄧㄎㄢㄦ":"吃一看二",
"ㄔㄧㄕㄓㄜ":"赤衣使者",
"ㄔㄧㄚㄉㄢ":"吃鴨蛋",
"ㄔㄧㄝㄘㄠ":"吃夜草",
"ㄔㄧㄡㄑㄧ":"蚩尤旗",
"ㄔㄧㄡㄒㄧ":"蚩尤戲",
"ㄔㄧㄤㄈㄢ":"吃洋飯",
"ㄔㄨㄅㄧㄝ":"擉鱉",
"ㄔㄨㄅㄧㄣ":"出殯",
"ㄔㄨㄅㄧㄥ":"出兵",
"ㄔㄨㄆㄧㄣ":"出品",
"ㄔㄨㄇㄣㄗ":"出門子",
"ㄔㄨㄇㄧㄠ":"出苗",
"ㄔㄨㄇㄧㄢ":"出面/初眠",
"ㄔㄨㄇㄧㄣ":"初民",
"ㄔㄨㄇㄧㄥ":"出名/除名",
"ㄔㄨㄈㄣㄗ":"出分子",
"ㄔㄨㄉㄧㄠ":"除掉",
"ㄔㄨㄉㄧㄢ":"觸電/出典",
"ㄔㄨㄉㄧㄥ":"出定/出頂",
"ㄔㄨㄉㄨㄢ":"處斷",
"ㄔㄨㄉㄨㄥ":"出動/觸動",
"ㄔㄨㄊㄡㄩ":"鋤頭雨",
"ㄔㄨㄊㄧㄠ":"出跳/出挑",
"ㄔㄨㄊㄧㄢ":"楚天",
"ㄔㄨㄊㄧㄥ":"出庭",
"ㄔㄨㄊㄨㄛ":"出脫/出橐",
"ㄔㄨㄊㄨㄟ":"黜退",
"ㄔㄨㄊㄨㄥ":"觸痛/觸桶",
"ㄔㄨㄊㄨㄨ":"出土物",
"ㄔㄨㄋㄧㄠ":"雛鳥",
"ㄔㄨㄋㄧㄤ":"廚娘",
"ㄔㄨㄋㄨㄛ":"搐搦",
"ㄔㄨㄌㄧㄝ":"出列/出獵",
"ㄔㄨㄌㄧㄡ":"出溜",
"ㄔㄨㄌㄧㄢ":"初戀",
"ㄔㄨㄌㄧㄤ":"出糧/芻糧",
"ㄔㄨㄌㄧㄥ":"除靈/芻靈",
"ㄔㄨㄌㄨㄛ":"出落",
"ㄔㄨㄌㄨㄥ":"出籠",
"ㄔㄨㄍㄨㄛ":"出國",
"ㄔㄨㄍㄨㄟ":"出軌/櫥櫃",
"ㄔㄨㄍㄨㄢ":"出關/出官",
"ㄔㄨㄍㄨㄥ":"出恭/出工",
"ㄔㄨㄎㄨㄟ":"初虧",
"ㄔㄨㄎㄨㄤ":"楚狂",
"ㄔㄨㄏㄨㄚ":"黜華",
"ㄔㄨㄏㄨㄛ":"出火/出活",
"ㄔㄨㄏㄨㄢ":"芻豢",
"ㄔㄨㄏㄨㄣ":"初婚",
"ㄔㄨㄏㄨㄤ":"初篁",
"ㄔㄨㄐㄧㄚ":"出家/出價",
"ㄔㄨㄐㄧㄝ":"初階/出結",
"ㄔㄨㄐㄧㄠ":"出教/初交",
"ㄔㄨㄐㄧㄡ":"杵臼",
"ㄔㄨㄐㄧㄢ":"出監/出尖",
"ㄔㄨㄐㄧㄣ":"出金",
"ㄔㄨㄐㄧㄤ":"黜降",
"ㄔㄨㄐㄧㄥ":"處境/出境",
"ㄔㄨㄐㄩㄝ":"觸覺/處決",
"ㄔㄨㄐㄩㄣ":"儲君",
"ㄔㄨㄑㄧㄡ":"出糗/初秋",
"ㄔㄨㄑㄧㄢ":"出錢/楮錢",
"ㄔㄨㄑㄧㄣ":"出勤",
"ㄔㄨㄑㄧㄤ":"楮鏹",
"ㄔㄨㄑㄧㄥ":"出清",
"ㄔㄨㄑㄩㄝ":"出缺/除卻",
"ㄔㄨㄑㄩㄢ":"除權/楮券",
"ㄔㄨㄑㄩㄣ":"出群",
"ㄔㄨㄒㄧㄚ":"廚下/初夏",
"ㄔㄨㄒㄧㄝ":"出血",
"ㄔㄨㄒㄧㄠ":"初小/除孝",
"ㄔㄨㄒㄧㄢ":"出現/出線",
"ㄔㄨㄒㄧㄣ":"初心/出心",
"ㄔㄨㄒㄧㄤ":"出項/芻像",
"ㄔㄨㄒㄧㄥ":"出行/雛形",
"ㄔㄨㄒㄩㄝ":"初學/初雪",
"ㄔㄨㄒㄩㄢ":"初選",
"ㄔㄨㄒㄩㄣ":"出巡/初旬",
"ㄔㄨㄓㄅㄛ":"楮知白",
"ㄔㄨㄓㄣㄗ":"出疹子",
"ㄔㄨㄓㄨㄟ":"出贅",
"ㄔㄨㄓㄨㄤ":"俶裝",
"ㄔㄨㄓㄨㄥ":"初中/出眾",
"ㄔㄨㄓㄨㄧ":"出主意",
"ㄔㄨㄔㄚㄗ":"出岔子",
"ㄔㄨㄔㄚㄦ":"出岔兒",
"ㄔㄨㄔㄨㄣ":"初春/觸脣",
"ㄔㄨㄔㄨㄤ":"櫥窗/初創",
"ㄔㄨㄕㄐㄧ":"除溼機",
"ㄔㄨㄕㄨㄟ":"出水",
"ㄔㄨㄕㄨㄤ":"除霜/初霜",
"ㄔㄨㄕㄨㄦ":"出數兒",
"ㄔㄨㄗㄨㄟ":"除罪",
"ㄔㄨㄘㄨㄛ":"出錯",
"ㄔㄨㄘㄨㄣ":"儲存",
"ㄔㄨㄙㄨㄛ":"處所/楚些",
"ㄔㄨㄙㄨㄟ":"初歲",
"ㄔㄨㄚㄌㄚ":"欻拉",
"ㄔㄨㄛㄅㄢ":"綽板",
"ㄔㄨㄛㄅㄧ":"輟筆",
"ㄔㄨㄛㄆㄛ":"戳破",
"ㄔㄨㄛㄉㄚ":"惙怛",
"ㄔㄨㄛㄉㄠ":"綽道",
"ㄔㄨㄛㄉㄥ":"綽燈/戳燈",
"ㄔㄨㄛㄉㄨ":"輟讀",
"ㄔㄨㄛㄎㄞ":"綽開",
"ㄔㄨㄛㄏㄠ":"綽號",
"ㄔㄨㄛㄐㄧ":"戳記",
"ㄔㄨㄛㄑㄧ":"啜泣/綽起",
"ㄔㄨㄛㄑㄩ":"綽趣",
"ㄔㄨㄛㄔㄚ":"啜茶",
"ㄔㄨㄛㄕㄚ":"戳紗",
"ㄔㄨㄛㄕㄜ":"戳舌",
"ㄔㄨㄛㄖㄢ":"惙然",
"ㄔㄨㄛㄧㄣ":"啜飲/戳印",
"ㄔㄨㄛㄩㄝ":"綽約/婥約",
"ㄔㄨㄞㄇㄛ":"揣摩/揣摸",
"ㄔㄨㄞㄊㄚ":"踹踏/嘬嚃",
"ㄔㄨㄞㄍㄨ":"揣骨",
"ㄔㄨㄞㄐㄧ":"揣己",
"ㄔㄨㄞㄘㄜ":"揣測",
"ㄔㄨㄟㄅㄞ":"垂白",
"ㄔㄨㄟㄅㄟ":"捶背",
"ㄔㄨㄟㄅㄧ":"垂璧",
"ㄔㄨㄟㄆㄢ":"垂盼",
"ㄔㄨㄟㄆㄥ":"吹捧",
"ㄔㄨㄟㄆㄧ":"垂披",
"ㄔㄨㄟㄇㄠ":"吹毛",
"ㄔㄨㄟㄇㄨ":"垂暮",
"ㄔㄨㄟㄈㄚ":"垂髮",
"ㄔㄨㄟㄈㄥ":"吹風",
"ㄔㄨㄟㄈㄨ":"吹拂/捶拊",
"ㄔㄨㄟㄉㄚ":"吹打/捶打",
"ㄔㄨㄟㄉㄥ":"吹燈",
"ㄔㄨㄟㄊㄚ":"捶撻",
"ㄔㄨㄟㄊㄞ":"吹臺",
"ㄔㄨㄟㄊㄡ":"垂頭",
"ㄔㄨㄟㄊㄢ":"吹彈",
"ㄔㄨㄟㄊㄤ":"吹糖/垂堂",
"ㄔㄨㄟㄊㄧ":"垂體/垂涕",
"ㄔㄨㄟㄌㄚ":"吹啦",
"ㄔㄨㄟㄌㄟ":"垂淚/吹擂",
"ㄔㄨㄟㄌㄠ":"垂老",
"ㄔㄨㄟㄌㄨ":"垂露",
"ㄔㄨㄟㄍㄠ":"垂櫜",
"ㄔㄨㄟㄍㄨ":"錘骨",
"ㄔㄨㄟㄏㄨ":"吹呼",
"ㄔㄨㄟㄐㄧ":"垂棘",
"ㄔㄨㄟㄐㄩ":"炊具",
"ㄔㄨㄟㄑㄧ":"垂泣",
"ㄔㄨㄟㄒㄧ":"吹襲",
"ㄔㄨㄟㄒㄩ":"吹噓",
"ㄔㄨㄟㄓㄠ":"垂照",
"ㄔㄨㄟㄓㄡ":"炊帚",
"ㄔㄨㄟㄓㄨ":"吹竹",
"ㄔㄨㄟㄔㄚ":"垂察",
"ㄔㄨㄟㄔㄥ":"垂成",
"ㄔㄨㄟㄔㄨ":"捶楚/棰楚",
"ㄔㄨㄟㄕㄡ":"吹手/垂手",
"ㄔㄨㄟㄕㄨ":"炊熟",
"ㄔㄨㄟㄗㄡ":"吹奏",
"ㄔㄨㄟㄗㄨ":"垂足",
"ㄔㄨㄟㄧㄠ":"捶腰",
"ㄔㄨㄟㄧㄡ":"吹釉",
"ㄔㄨㄟㄧㄢ":"炊煙",
"ㄔㄨㄟㄧㄣ":"垂陰",
"ㄔㄨㄟㄧㄤ":"垂楊",
"ㄔㄨㄟㄨㄟ":"垂危",
"ㄔㄨㄟㄨㄢ":"捶丸",
"ㄔㄨㄟㄨㄣ":"垂問",
"ㄔㄨㄟㄨㄤ":"吹網",
"ㄔㄨㄟㄩㄣ":"吹雲",
"ㄔㄨㄢㄅㄛ":"傳播/船舶",
"ㄔㄨㄢㄅㄟ":"川貝",
"ㄔㄨㄢㄅㄠ":"傳報",
"ㄔㄨㄢㄅㄢ":"傳板",
"ㄔㄨㄢㄅㄤ":"穿幫/船幫",
"ㄔㄨㄢㄅㄧ":"穿鼻/椽筆",
"ㄔㄨㄢㄅㄨ":"傳布/船埠",
"ㄔㄨㄢㄆㄥ":"船篷",
"ㄔㄨㄢㄇㄟ":"傳媒",
"ㄔㄨㄢㄇㄠ":"船錨",
"ㄔㄨㄢㄇㄨ":"椽木",
"ㄔㄨㄢㄈㄚ":"傳發/傳法",
"ㄔㄨㄢㄈㄟ":"川費",
"ㄔㄨㄢㄈㄢ":"船帆",
"ㄔㄨㄢㄈㄣ":"傳粉",
"ㄔㄨㄢㄈㄥ":"傳諷",
"ㄔㄨㄢㄈㄨ":"船夫",
"ㄔㄨㄢㄉㄚ":"傳達/串打",
"ㄔㄨㄢㄉㄞ":"傳代/穿帶",
"ㄔㄨㄢㄉㄠ":"傳道/串道",
"ㄔㄨㄢㄉㄢ":"傳單",
"ㄔㄨㄢㄉㄥ":"傳燈",
"ㄔㄨㄢㄉㄧ":"傳遞/船底",
"ㄔㄨㄢㄉㄨ":"川瀆",
"ㄔㄨㄢㄊㄞ":"船臺",
"ㄔㄨㄢㄊㄡ":"穿透/船頭",
"ㄔㄨㄢㄊㄤ":"穿堂/川堂",
"ㄔㄨㄢㄊㄧ":"船體",
"ㄔㄨㄢㄊㄨ":"川土",
"ㄔㄨㄢㄋㄧ":"舛逆",
"ㄔㄨㄢㄌㄡ":"船樓",
"ㄔㄨㄢㄌㄢ":"船纜",
"ㄔㄨㄢㄌㄤ":"穿廊",
"ㄔㄨㄢㄌㄨ":"傳臚/川陸",
"ㄔㄨㄢㄍㄜ":"船歌",
"ㄔㄨㄢㄍㄢ":"傳柑",
"ㄔㄨㄢㄍㄨ":"傳鼓/串鼓",
"ㄔㄨㄢㄎㄜ":"串客/船殼",
"ㄔㄨㄢㄎㄢ":"傳看",
"ㄔㄨㄢㄏㄡ":"川后",
"ㄔㄨㄢㄏㄨ":"傳呼/船戶",
"ㄔㄨㄢㄐㄧ":"船籍/船驥",
"ㄔㄨㄢㄐㄩ":"川劇/串疽",
"ㄔㄨㄢㄑㄧ":"傳奇/喘氣",
"ㄔㄨㄢㄑㄩ":"船蛆",
"ㄔㄨㄢㄒㄧ":"喘息/傳習",
"ㄔㄨㄢㄓㄚ":"船閘",
"ㄔㄨㄢㄓㄣ":"傳真",
"ㄔㄨㄢㄓㄤ":"船長/串杖",
"ㄔㄨㄢㄓㄥ":"遄征",
"ㄔㄨㄢㄓㄨ":"船主/椽柱",
"ㄔㄨㄢㄔㄚ":"穿插",
"ㄔㄨㄢㄔㄜ":"輲車",
"ㄔㄨㄢㄔㄠ":"傳抄/傳鈔",
"ㄔㄨㄢㄔㄤ":"串場/船廠",
"ㄔㄨㄢㄔㄥ":"傳承",
"ㄔㄨㄢㄕㄠ":"串燒/船梢",
"ㄔㄨㄢㄕㄡ":"傳授",
"ㄔㄨㄢㄕㄣ":"傳神/船身",
"ㄔㄨㄢㄕㄨ":"傳輸/傳述",
"ㄔㄨㄢㄖㄜ":"傳熱",
"ㄔㄨㄢㄖㄢ":"傳染",
"ㄔㄨㄢㄖㄣ":"傳人",
"ㄔㄨㄢㄗㄚ":"舛雜",
"ㄔㄨㄢㄗㄜ":"川澤",
"ㄔㄨㄢㄘㄤ":"船艙",
"ㄔㄨㄢㄙㄨ":"穿素",
"ㄔㄨㄢㄧㄡ":"串游",
"ㄔㄨㄢㄧㄢ":"傳言/串演",
"ㄔㄨㄢㄧㄤ":"傳揚/穿楊",
"ㄔㄨㄢㄨㄟ":"傳位/船位",
"ㄔㄨㄢㄨㄣ":"傳聞",
"ㄔㄨㄢㄩㄝ":"穿越/傳閱",
"ㄔㄨㄢㄩㄢ":"船員",
"ㄔㄨㄢㄩㄣ":"船運",
"ㄔㄨㄣㄅㄛ":"春播/踳駁",
"ㄔㄨㄣㄅㄞ":"純白/淳白",
"ㄔㄨㄣㄅㄟ":"醇備",
"ㄔㄨㄣㄅㄢ":"脣瓣",
"ㄔㄨㄣㄅㄣ":"蠢笨",
"ㄔㄨㄣㄅㄤ":"春榜",
"ㄔㄨㄣㄅㄧ":"脣筆/醇碧",
"ㄔㄨㄣㄆㄢ":"春盤",
"ㄔㄨㄣㄆㄤ":"淳龐",
"ㄔㄨㄣㄆㄨ":"純樸/醇朴",
"ㄔㄨㄣㄇㄞ":"春麥",
"ㄔㄨㄣㄇㄟ":"醇美",
"ㄔㄨㄣㄇㄤ":"春忙",
"ㄔㄨㄣㄇㄥ":"春夢",
"ㄔㄨㄣㄈㄟ":"春肥",
"ㄔㄨㄣㄈㄢ":"春旛",
"ㄔㄨㄣㄈㄣ":"春分",
"ㄔㄨㄣㄈㄤ":"春方/春芳",
"ㄔㄨㄣㄈㄥ":"春風/淳風",
"ㄔㄨㄣㄈㄨ":"春服/鶉服",
"ㄔㄨㄣㄉㄥ":"春凳",
"ㄔㄨㄣㄉㄨ":"純度/脣讀",
"ㄔㄨㄣㄊㄞ":"春臺",
"ㄔㄨㄣㄌㄟ":"醇類/春雷",
"ㄔㄨㄣㄌㄠ":"醇醪",
"ㄔㄨㄣㄌㄡ":"蠢陋",
"ㄔㄨㄣㄌㄢ":"春蘭",
"ㄔㄨㄣㄌㄧ":"純利/純麗",
"ㄔㄨㄣㄍㄠ":"脣膏",
"ㄔㄨㄣㄍㄥ":"春耕",
"ㄔㄨㄣㄍㄨ":"淳古/純嘏",
"ㄔㄨㄣㄎㄜ":"蓴科",
"ㄔㄨㄣㄏㄜ":"醇和/淳和",
"ㄔㄨㄣㄏㄡ":"醇厚/純厚",
"ㄔㄨㄣㄏㄢ":"蠢漢",
"ㄔㄨㄣㄏㄤ":"蠢夯",
"ㄔㄨㄣㄐㄧ":"春季/春祭",
"ㄔㄨㄣㄐㄩ":"鶉居",
"ㄔㄨㄣㄑㄧ":"春氣",
"ㄔㄨㄣㄒㄧ":"春禊/淳熙",
"ㄔㄨㄣㄓㄡ":"醇酎",
"ㄔㄨㄣㄓㄣ":"純真",
"ㄔㄨㄣㄓㄥ":"純正",
"ㄔㄨㄣㄔㄚ":"春茶",
"ㄔㄨㄣㄔㄣ":"純臣",
"ㄔㄨㄣㄕㄜ":"春社/脣舌",
"ㄔㄨㄣㄕㄡ":"鶉首/椿壽",
"ㄔㄨㄣㄕㄢ":"春山",
"ㄔㄨㄣㄕㄤ":"春上",
"ㄔㄨㄣㄕㄥ":"春勝",
"ㄔㄨㄣㄕㄨ":"純熟/春書",
"ㄔㄨㄣㄖㄢ":"純然",
"ㄔㄨㄣㄖㄣ":"蠢人/純仁",
"ㄔㄨㄣㄖㄨ":"純如/醇儒",
"ㄔㄨㄣㄘㄞ":"蠢材",
"ㄔㄨㄣㄘㄠ":"春草",
"ㄔㄨㄣㄙㄜ":"春色/純色",
"ㄔㄨㄣㄙㄞ":"春賽",
"ㄔㄨㄣㄙㄡ":"春蒐",
"ㄔㄨㄣㄧㄠ":"春藥",
"ㄔㄨㄣㄧㄡ":"春遊",
"ㄔㄨㄣㄧㄣ":"純音/脣音",
"ㄔㄨㄣㄧㄤ":"純陽",
"ㄔㄨㄣㄧㄥ":"春鶯",
"ㄔㄨㄣㄨㄓ":"純物質",
"ㄔㄨㄣㄨㄟ":"鶉尾/春闈",
"ㄔㄨㄣㄨㄣ":"春瘟/脣吻",
"ㄔㄨㄣㄨㄤ":"春望",
"ㄔㄨㄣㄩㄢ":"春院",
"ㄔㄨㄣㄩㄣ":"春運",
"ㄔㄨㄣㄩㄧ":"淳于意",
"ㄔㄨㄤㄅㄚ":"瘡疤",
"ㄔㄨㄤㄅㄢ":"創辦/床板",
"ㄔㄨㄤㄆㄨ":"床鋪",
"ㄔㄨㄤㄇㄨ":"床母",
"ㄔㄨㄤㄈㄢ":"幢幡",
"ㄔㄨㄤㄉㄢ":"床單",
"ㄔㄨㄤㄉㄤ":"闖蕩",
"ㄔㄨㄤㄊㄚ":"床榻",
"ㄔㄨㄤㄊㄞ":"窗臺",
"ㄔㄨㄤㄊㄡ":"床頭",
"ㄔㄨㄤㄊㄧ":"窗屜",
"ㄔㄨㄤㄌㄠ":"瘡癆",
"ㄔㄨㄤㄌㄥ":"床楞",
"ㄔㄨㄤㄌㄧ":"創立",
"ㄔㄨㄤㄍㄜ":"窗格",
"ㄔㄨㄤㄍㄞ":"幢蓋",
"ㄔㄨㄤㄍㄠ":"創稿",
"ㄔㄨㄤㄎㄜ":"窗課",
"ㄔㄨㄤㄎㄡ":"窗口/創口",
"ㄔㄨㄤㄎㄢ":"創刊",
"ㄔㄨㄤㄏㄣ":"創痕/瘡痕",
"ㄔㄨㄤㄏㄨ":"窗戶",
"ㄔㄨㄤㄐㄩ":"創舉",
"ㄔㄨㄤㄓㄠ":"床罩",
"ㄔㄨㄤㄓㄤ":"床帳",
"ㄔㄨㄤㄕㄚ":"窗紗",
"ㄔㄨㄤㄕㄜ":"創設",
"ㄔㄨㄤㄕㄡ":"創收",
"ㄔㄨㄤㄕㄤ":"創傷",
"ㄔㄨㄤㄖㄢ":"愴然",
"ㄔㄨㄤㄖㄨ":"床褥/闖入",
"ㄔㄨㄤㄗㄠ":"創造",
"ㄔㄨㄤㄘㄜ":"愴惻",
"ㄔㄨㄤㄙㄤ":"闖喪",
"ㄔㄨㄤㄧㄝ":"創業",
"ㄔㄨㄤㄧㄡ":"窗友/窗牖",
"ㄔㄨㄤㄧㄢ":"窗眼/床沿",
"ㄔㄨㄤㄧㄤ":"瘡瘍",
"ㄔㄨㄤㄨㄞ":"窗外",
"ㄔㄨㄤㄨㄟ":"床位/床幃",
"ㄔㄨㄤㄨㄤ":"闖王",
"ㄔㄨㄤㄩㄝ":"闖越",
"ㄔㄨㄤㄩㄢ":"窗緣",
"ㄔㄨㄥㄅㄛ":"重播",
"ㄔㄨㄥㄅㄞ":"崇拜",
"ㄔㄨㄥㄅㄢ":"重辦/重版",
"ㄔㄨㄥㄅㄧ":"重壁/沖鼻",
"ㄔㄨㄥㄆㄛ":"衝破",
"ㄔㄨㄥㄆㄞ":"重拍",
"ㄔㄨㄥㄆㄟ":"充沛",
"ㄔㄨㄥㄆㄠ":"沖泡",
"ㄔㄨㄥㄇㄛ":"沖默/沖末",
"ㄔㄨㄥㄇㄟ":"沖昧",
"ㄔㄨㄥㄇㄢ":"充滿",
"ㄔㄨㄥㄇㄣ":"重門",
"ㄔㄨㄥㄇㄧ":"舂米",
"ㄔㄨㄥㄇㄨ":"沖模",
"ㄔㄨㄥㄈㄚ":"充發",
"ㄔㄨㄥㄈㄢ":"重返/重犯",
"ㄔㄨㄥㄈㄣ":"充分",
"ㄔㄨㄥㄈㄤ":"种放",
"ㄔㄨㄥㄈㄥ":"重逢/衝鋒",
"ㄔㄨㄥㄈㄨ":"重複/沖服",
"ㄔㄨㄥㄉㄜ":"崇德",
"ㄔㄨㄥㄉㄞ":"重戴",
"ㄔㄨㄥㄉㄢ":"沖淡",
"ㄔㄨㄥㄉㄤ":"充當",
"ㄔㄨㄥㄉㄧ":"沖滌",
"ㄔㄨㄥㄉㄨ":"重讀",
"ㄔㄨㄥㄊㄚ":"重沓",
"ㄔㄨㄥㄊㄞ":"重臺",
"ㄔㄨㄥㄊㄨ":"衝突",
"ㄔㄨㄥㄌㄡ":"重樓",
"ㄔㄨㄥㄌㄤ":"衝浪",
"ㄔㄨㄥㄌㄧ":"衝力/重黎",
"ㄔㄨㄥㄌㄨ":"寵祿",
"ㄔㄨㄥㄌㄩ":"充閭",
"ㄔㄨㄥㄍㄠ":"崇高",
"ㄔㄨㄥㄍㄣ":"重根",
"ㄔㄨㄥㄎㄜ":"衝剋/沖剋",
"ㄔㄨㄥㄎㄠ":"重考",
"ㄔㄨㄥㄏㄜ":"重合/沖和",
"ㄔㄨㄥㄏㄞ":"蟲害",
"ㄔㄨㄥㄐㄧ":"衝擊/充飢",
"ㄔㄨㄥㄐㄩ":"重聚",
"ㄔㄨㄥㄑㄧ":"充氣/蟲漆",
"ㄔㄨㄥㄑㄩ":"充詘",
"ㄔㄨㄥㄒㄧ":"沖洗/沖喜",
"ㄔㄨㄥㄒㄩ":"沖虛",
"ㄔㄨㄥㄓㄠ":"寵召/崇朝",
"ㄔㄨㄥㄓㄣ":"重振/崇禎",
"ㄔㄨㄥㄓㄤ":"重張/沖帳",
"ㄔㄨㄥㄓㄥ":"重整",
"ㄔㄨㄥㄔㄚ":"沖茶",
"ㄔㄨㄥㄔㄤ":"重唱/充暢",
"ㄔㄨㄥㄔㄥ":"衝程",
"ㄔㄨㄥㄔㄨ":"重出/蟲出",
"ㄔㄨㄥㄕㄚ":"衝殺",
"ㄔㄨㄥㄕㄜ":"重舌",
"ㄔㄨㄥㄕㄢ":"重山/崇山",
"ㄔㄨㄥㄕㄣ":"重申/充身",
"ㄔㄨㄥㄕㄤ":"重傷/崇尚",
"ㄔㄨㄥㄕㄥ":"重生",
"ㄔㄨㄥㄕㄨ":"充數/蟲書",
"ㄔㄨㄥㄖㄢ":"衝然/充然",
"ㄔㄨㄥㄖㄣ":"沖人/充任",
"ㄔㄨㄥㄖㄨ":"崇儒",
"ㄔㄨㄥㄗㄞ":"蟲災",
"ㄔㄨㄥㄗㄡ":"重奏",
"ㄔㄨㄥㄗㄨ":"充足/重組",
"ㄔㄨㄥㄙㄜ":"充塞",
"ㄔㄨㄥㄙㄢ":"重三",
"ㄔㄨㄥㄧㄚ":"沖壓/蟲牙",
"ㄔㄨㄥㄧㄞ":"崇崖",
"ㄔㄨㄥㄧㄠ":"衝要",
"ㄔㄨㄥㄧㄢ":"重演/重言",
"ㄔㄨㄥㄧㄣ":"重印/重陰",
"ㄔㄨㄥㄧㄤ":"重陽/崇洋",
"ㄔㄨㄥㄧㄥ":"重穎/充盈",
"ㄔㄨㄥㄨㄞ":"崇外",
"ㄔㄨㄥㄨㄟ":"重圍/重幃",
"ㄔㄨㄥㄨㄣ":"重文",
"ㄔㄨㄥㄩㄢ":"重淵",
"ㄔㄨㄥㄩㄥ":"崇墉",
"ㄔㄨㄧㄕㄨ":"初一十五",
"ㄔㄨㄧㄨㄞ":"出意外",
"ㄔㄨㄨㄑㄧ":"除霧器",
"ㄔㄨㄨㄛㄗ":"處窩子",
"ㄔㄨㄩㄝㄗ":"出月子",
"ㄔㄩㄓㄧㄤ":"池魚之殃",
"ㄔㄩㄢㄨㄤ":"吃冤枉",
"ㄕㄅㄚㄋㄚ":"士巴拿",
"ㄕㄅㄛㄑㄧ":"示波器",
"ㄕㄅㄢㄌㄨ":"石版路",
"ㄕㄅㄧㄕㄓ":"使臂使指",
"ㄕㄅㄨㄉㄜ":"使不得",
"ㄕㄅㄨㄧㄔ":"事不宜遲",
"ㄕㄆㄛㄌㄢ":"拾破爛",
"ㄕㄆㄞㄆㄞ":"實拍拍",
"ㄕㄆㄧㄑㄧ":"使脾氣",
"ㄕㄇㄨㄧㄙ":"拭目以俟",
"ㄕㄈㄟㄊㄧ":"是非題",
"ㄕㄈㄟㄩㄢ":"試飛員",
"ㄕㄈㄢㄏㄨ":"示範戶",
"ㄕㄈㄢㄕㄥ":"師範生",
"ㄕㄈㄣㄖㄣ":"拾糞人",
"ㄕㄈㄥㄊㄡ":"試風頭",
"ㄕㄈㄥㄨㄩ":"十風五雨",
"ㄕㄈㄨㄕㄗ":"是父是子",
"ㄕㄉㄚㄈㄨ":"士大夫",
"ㄕㄉㄚㄉㄚ":"溼答答",
"ㄕㄉㄚㄊㄧ":"識大體",
"ㄕㄉㄚㄎㄞ":"石達開",
"ㄕㄉㄨㄐㄧ":"溼度計",
"ㄕㄉㄨㄓㄞ":"舐犢之愛",
"ㄕㄊㄡㄐㄧ":"石頭記",
"ㄕㄊㄡㄔㄥ":"石頭城",
"ㄕㄊㄡㄘㄠ":"石頭草",
"ㄕㄊㄢㄐㄧ":"石炭紀",
"ㄕㄊㄢㄕㄩ":"使貪使愚",
"ㄕㄊㄢㄨㄧ":"時談物議",
"ㄕㄊㄧㄈㄚ":"實體法",
"ㄕㄊㄧㄕㄨ":"十體書",
"ㄕㄋㄟㄓㄨ":"室內植物",
"ㄕㄋㄟㄩㄝ":"室內樂",
"ㄕㄋㄨㄅㄧ":"史奴比",
"ㄕㄌㄧㄡㄧ":"詩六義",
"ㄕㄌㄧㄧㄢ":"勢利眼",
"ㄕㄌㄨㄌㄨ":"濕漉漉",
"ㄕㄌㄨㄥㄗ":"石龍子",
"ㄕㄍㄢㄉㄤ":"石敢當",
"ㄕㄍㄨㄥㄢ":"施公案",
"ㄕㄍㄨㄨㄣ":"石鼓文",
"ㄕㄎㄜㄈㄚ":"史可法",
"ㄕㄎㄜㄌㄤ":"屎蚵蜋",
"ㄕㄎㄜㄦㄓ":"適可而止",
"ㄕㄏㄨㄌㄢ":"石斛蘭",
"ㄕㄏㄨㄌㄨ":"實葫蘆",
"ㄕㄏㄨㄛㄓ":"食貨志",
"ㄕㄏㄨㄟㄓ":"石灰質",
"ㄕㄏㄨㄟㄕ":"石灰石",
"ㄕㄐㄧㄇㄛ":"世紀末",
"ㄕㄐㄧㄝㄩ":"世界語",
"ㄕㄐㄧㄣㄓ":"十進制",
"ㄕㄐㄧㄣㄕ":"試金石",
"ㄕㄐㄩㄣㄗ":"士君子",
"ㄕㄑㄧㄠㄦ":"使巧兒",
"ㄕㄑㄧㄢㄕ":"史前史",
"ㄕㄒㄧㄕㄥ":"實習生",
"ㄕㄒㄧㄥㄗ":"使性子",
"ㄕㄓㄤㄕㄓ":"失張失智",
"ㄕㄓㄥㄈㄨ":"市政府",
"ㄕㄔㄜㄐㄩ":"矢車菊",
"ㄕㄔㄣㄅㄟ":"史晨碑",
"ㄕㄔㄤㄆㄨ":"石菖蒲",
"ㄕㄕㄉㄚㄨ":"識時達務",
"ㄕㄕㄓㄋㄢ":"矢石之難",
"ㄕㄕㄖㄨㄩ":"矢石如雨",
"ㄕㄕㄣㄈㄣ":"失身分",
"ㄕㄕㄥㄆㄛ":"始生魄",
"ㄕㄖㄣㄕㄚ":"食人鯊",
"ㄕㄗㄏㄨㄟ":"獅子會",
"ㄕㄗㄐㄧㄚ":"十字架",
"ㄕㄗㄐㄧㄝ":"十字街",
"ㄕㄗㄐㄧㄠ":"柿子椒",
"ㄕㄗㄐㄩㄣ":"十字軍",
"ㄕㄗㄒㄧㄡ":"十字繡",
"ㄕㄗㄗㄨㄛ":"獅子座",
"ㄕㄘㄞㄊㄤ":"世綵堂",
"ㄕㄘㄞㄠㄨ":"恃才傲物",
"ㄕㄙㄅㄨㄦ":"誓死不二",
"ㄕㄙㄇㄧㄥ":"史思明",
"ㄕㄙㄏㄤㄕ":"十四行詩",
"ㄕㄙㄖㄨㄧ":"視死如飴",
"ㄕㄙㄢㄏㄤ":"十三行",
"ㄕㄙㄢㄓㄜ":"十三轍",
"ㄕㄙㄧㄕㄥ":"十死一生",
"ㄕㄚㄇㄠㄦ":"傻帽兒",
"ㄕㄚㄉㄧㄝ":"沙嗲",
"ㄕㄚㄉㄧㄠ":"沙雕",
"ㄕㄚㄉㄧㄥ":"紗錠",
"ㄕㄚㄉㄨㄛ":"殺剁",
"ㄕㄚㄊㄧㄢ":"沙田",
"ㄕㄚㄊㄨㄛ":"沙陀",
"ㄕㄚㄌㄨㄣ":"砂輪",
"ㄕㄚㄌㄨㄥ":"沙龍/沙隆",
"ㄕㄚㄌㄩㄝ":"殺掠",
"ㄕㄚㄍㄨㄚ":"傻瓜",
"ㄕㄚㄍㄨㄛ":"沙鍋/砂鍋",
"ㄕㄚㄎㄜㄗ":"沙殼子",
"ㄕㄚㄏㄞㄗ":"傻孩子",
"ㄕㄚㄏㄨㄢ":"沙獾",
"ㄕㄚㄏㄨㄤ":"沙皇/沙荒",
"ㄕㄚㄐㄧㄚ":"殺價",
"ㄕㄚㄐㄧㄝ":"殺戒",
"ㄕㄚㄐㄧㄠ":"傻角",
"ㄕㄚㄐㄧㄣ":"沙金/砂金",
"ㄕㄚㄐㄧㄤ":"砂漿",
"ㄕㄚㄐㄩㄣ":"殺菌",
"ㄕㄚㄑㄧㄡ":"沙丘/砂丘",
"ㄕㄚㄑㄧㄢ":"沙錢",
"ㄕㄚㄑㄧㄤ":"沙牆/蔱蘠",
"ㄕㄚㄑㄧㄥ":"殺青",
"ㄕㄚㄒㄧㄠ":"傻笑",
"ㄕㄚㄒㄧㄥ":"煞星",
"ㄕㄚㄒㄩㄝ":"唼血/歃血",
"ㄕㄚㄒㄩㄣ":"沙噀",
"ㄕㄚㄔㄨㄢ":"沙船",
"ㄕㄚㄔㄨㄤ":"紗窗",
"ㄕㄚㄕㄔㄜ":"砂石車",
"ㄕㄚㄕㄔㄤ":"砂石場",
"ㄕㄚㄕㄨㄟ":"沙水",
"ㄕㄚㄖㄤㄦ":"沙瓤兒",
"ㄕㄚㄗㄨㄟ":"沙嘴",
"ㄕㄜㄆㄧㄣ":"射頻",
"ㄕㄜㄆㄧㄥ":"社評",
"ㄕㄜㄇㄧㄢ":"赦免",
"ㄕㄜㄇㄧㄣ":"畬民",
"ㄕㄜㄇㄧㄥ":"捨命",
"ㄕㄜㄉㄧㄢ":"設奠",
"ㄕㄜㄉㄧㄥ":"設定",
"ㄕㄜㄉㄨㄛ":"捨墮",
"ㄕㄜㄊㄨㄟ":"蛇蛻",
"ㄕㄜㄊㄨㄢ":"社團",
"ㄕㄜㄋㄧㄡ":"麝牛",
"ㄕㄜㄌㄧㄗ":"舍利子",
"ㄕㄜㄌㄧㄝ":"涉獵/射獵",
"ㄕㄜㄌㄧㄡ":"射流/射柳",
"ㄕㄜㄌㄧㄤ":"射糧",
"ㄕㄜㄌㄧㄥ":"設靈",
"ㄕㄜㄌㄨㄣ":"社論/設論",
"ㄕㄜㄍㄨㄟ":"社鬼/攝龜",
"ㄕㄜㄍㄨㄥ":"社工/社公",
"ㄕㄜㄏㄨㄚ":"奢華/攝畫",
"ㄕㄜㄏㄨㄛ":"設或/社火",
"ㄕㄜㄏㄨㄟ":"社會",
"ㄕㄜㄏㄨㄣ":"攝魂",
"ㄕㄜㄐㄧㄕ":"設計師",
"ㄕㄜㄐㄧㄚ":"社家",
"ㄕㄜㄐㄧㄝ":"射界/賒借",
"ㄕㄜㄐㄧㄠ":"社交/社教",
"ㄕㄜㄐㄧㄢ":"射箭/舌尖",
"ㄕㄜㄐㄧㄥ":"射精",
"ㄕㄜㄑㄧㄡ":"奢求",
"ㄕㄜㄑㄧㄢ":"賒欠",
"ㄕㄜㄑㄧㄣ":"舍親/涉禽",
"ㄕㄜㄒㄧㄚ":"舍下",
"ㄕㄜㄒㄧㄝ":"蛇蠍",
"ㄕㄜㄒㄧㄠ":"賒銷",
"ㄕㄜㄒㄧㄢ":"涉嫌/射線",
"ㄕㄜㄒㄧㄣ":"設心/蛇信",
"ㄕㄜㄒㄧㄤ":"設想/涉想",
"ㄕㄜㄒㄧㄥ":"蛇行/攝行",
"ㄕㄜㄒㄩㄝ":"社學/涉血",
"ㄕㄜㄓㄨㄢ":"攝篆",
"ㄕㄜㄓㄨㄥ":"射中",
"ㄕㄜㄔㄧㄣ":"舌齒音",
"ㄕㄜㄕㄨㄟ":"涉水/設帨",
"ㄕㄜㄖㄨㄛ":"設若",
"ㄕㄜㄗㄨㄟ":"赦罪",
"ㄕㄜㄙㄨㄥ":"涉訟",
"ㄕㄜㄧㄥㄕ":"攝影師",
"ㄕㄜㄨㄣㄕ":"蛇紋石",
"ㄕㄞㄌㄨㄛ":"篩鑼",
"ㄕㄞㄍㄨㄢ":"篩管",
"ㄕㄞㄐㄧㄡ":"篩酒",
"ㄕㄞㄐㄧㄢ":"篩檢",
"ㄕㄞㄒㄩㄢ":"篩選",
"ㄕㄞㄕㄨㄦ":"色數兒",
"ㄕㄞㄧㄔㄤ":"晒衣場",
"ㄕㄟㄐㄧㄚ":"誰家",
"ㄕㄠㄅㄧㄥ":"哨兵/燒餅",
"ㄕㄠㄆㄧㄥ":"燒瓶",
"ㄕㄠㄇㄚㄗ":"捎馬子",
"ㄕㄠㄉㄠㄗ":"燒刀子",
"ㄕㄠㄉㄨㄥ":"少東",
"ㄕㄠㄊㄧㄥ":"少停",
"ㄕㄠㄋㄧㄢ":"少年",
"ㄕㄠㄌㄧㄕ":"燒利市",
"ㄕㄠㄌㄧㄠ":"紹繚",
"ㄕㄠㄌㄧㄢ":"燒煉",
"ㄕㄠㄌㄧㄤ":"少量",
"ㄕㄠㄌㄧㄥ":"劭令",
"ㄕㄠㄍㄨㄛ":"燒果/燒鍋",
"ㄕㄠㄍㄨㄢ":"哨官",
"ㄕㄠㄍㄨㄤ":"韶光",
"ㄕㄠㄍㄨㄥ":"梢公/艄公",
"ㄕㄠㄏㄨㄚ":"燒化/韶華",
"ㄕㄠㄏㄨㄛ":"燒活/燒火",
"ㄕㄠㄏㄨㄟ":"燒毀",
"ㄕㄠㄏㄨㄤ":"燒荒",
"ㄕㄠㄐㄧㄝ":"燒結/紹介",
"ㄕㄠㄐㄧㄠ":"捎腳/燒焦",
"ㄕㄠㄐㄧㄡ":"燒酒",
"ㄕㄠㄐㄧㄢ":"少見/少間",
"ㄕㄠㄑㄧㄥ":"少頃",
"ㄕㄠㄒㄧㄝ":"少歇/少懈",
"ㄕㄠㄒㄧㄠ":"少小",
"ㄕㄠㄒㄧㄡ":"韶秀",
"ㄕㄠㄒㄧㄣ":"燒心/捎信",
"ㄕㄠㄒㄧㄤ":"燒香/少像",
"ㄕㄠㄒㄧㄥ":"紹興",
"ㄕㄠㄓㄨㄛ":"燒灼",
"ㄕㄠㄓㄨㄤ":"少壯",
"ㄕㄠㄔㄨㄢ":"哨船",
"ㄕㄠㄔㄨㄣ":"燒春",
"ㄕㄠㄕㄍㄠ":"燒石膏",
"ㄕㄠㄕㄨㄟ":"燒水",
"ㄕㄠㄗㄨㄛ":"少坐",
"ㄕㄠㄙㄨㄛ":"哨所",
"ㄕㄠㄧㄉㄢ":"燒夷彈",
"ㄕㄠㄧㄚㄗ":"燒鴨子",
"ㄕㄡㄅㄚㄗ":"手把子",
"ㄕㄡㄅㄢㄗ":"手板子",
"ㄕㄡㄅㄧㄝ":"受癟",
"ㄕㄡㄅㄧㄠ":"手錶",
"ㄕㄡㄅㄧㄢ":"收編",
"ㄕㄡㄅㄧㄥ":"收兵/受病",
"ㄕㄡㄆㄚㄗ":"手帕子",
"ㄕㄡㄆㄧㄠ":"售票",
"ㄕㄡㄆㄧㄢ":"受騙",
"ㄕㄡㄆㄧㄣ":"受聘",
"ㄕㄡㄇㄧㄢ":"手面/壽麵",
"ㄕㄡㄇㄧㄣ":"手民/壽民",
"ㄕㄡㄇㄧㄥ":"壽命/受命",
"ㄕㄡㄉㄨㄢ":"手段",
"ㄕㄡㄊㄡㄗ":"手頭字",
"ㄕㄡㄊㄧㄕ":"手提式",
"ㄕㄡㄊㄧㄝ":"手帖",
"ㄕㄡㄊㄧㄠ":"收條",
"ㄕㄡㄊㄧㄢ":"受田/授田",
"ㄕㄡㄊㄧㄥ":"收聽",
"ㄕㄡㄊㄨㄛ":"受託/手拖",
"ㄕㄡㄋㄧㄢ":"手黏",
"ㄕㄡㄋㄩㄝ":"受虐",
"ㄕㄡㄌㄧㄝ":"狩獵",
"ㄕㄡㄌㄧㄡ":"收留/瘦溜",
"ㄕㄡㄌㄧㄢ":"收斂/收殮",
"ㄕㄡㄌㄧㄤ":"受涼",
"ㄕㄡㄌㄧㄥ":"手令/守靈",
"ㄕㄡㄌㄨㄛ":"收羅",
"ㄕㄡㄌㄨㄣ":"首輪",
"ㄕㄡㄌㄨㄥ":"手籠/收攏",
"ㄕㄡㄍㄨㄚ":"守寡",
"ㄕㄡㄍㄨㄛ":"瘦果/壽國",
"ㄕㄡㄍㄨㄟ":"收歸",
"ㄕㄡㄍㄨㄢ":"收管",
"ㄕㄡㄍㄨㄥ":"收工/手工",
"ㄕㄡㄎㄨㄞ":"手快",
"ㄕㄡㄎㄨㄢ":"收款",
"ㄕㄡㄎㄨㄣ":"受困",
"ㄕㄡㄏㄨㄚ":"手滑",
"ㄕㄡㄏㄨㄛ":"售貨/收穫",
"ㄕㄡㄏㄨㄟ":"受惠/收回",
"ㄕㄡㄏㄨㄢ":"獸環",
"ㄕㄡㄐㄧㄗ":"手擊子",
"ㄕㄡㄐㄧㄚ":"售價",
"ㄕㄡㄐㄧㄝ":"收介/受戒",
"ㄕㄡㄐㄧㄠ":"手腳/受教",
"ㄕㄡㄐㄧㄡ":"守舊/收救",
"ㄕㄡㄐㄧㄢ":"手簡/收監",
"ㄕㄡㄐㄧㄣ":"手緊/手巾",
"ㄕㄡㄐㄧㄤ":"受獎/授獎",
"ㄕㄡㄐㄧㄥ":"受驚/受精",
"ㄕㄡㄐㄩㄝ":"受爵",
"ㄕㄡㄐㄩㄢ":"手卷/手卷",
"ㄕㄡㄐㄩㄥ":"受窘",
"ㄕㄡㄑㄧㄡ":"手球/首丘",
"ㄕㄡㄑㄧㄤ":"手槍",
"ㄕㄡㄑㄧㄥ":"售罄",
"ㄕㄡㄑㄩㄢ":"授權",
"ㄕㄡㄒㄧㄚ":"手下/收下",
"ㄕㄡㄒㄧㄠ":"瘦小/收效",
"ㄕㄡㄒㄧㄢ":"首先/手癬",
"ㄕㄡㄒㄧㄣ":"手心/守信",
"ㄕㄡㄒㄧㄤ":"首相/手相",
"ㄕㄡㄒㄧㄥ":"壽星/壽星",
"ㄕㄡㄒㄩㄝ":"瘦削/壽穴",
"ㄕㄡㄒㄩㄣ":"受訓",
"ㄕㄡㄓㄤㄓ":"首長制",
"ㄕㄡㄓㄨㄚ":"手爪",
"ㄕㄡㄓㄨㄛ":"手鐲/收捉",
"ㄕㄡㄓㄨㄤ":"首狀/壽妝",
"ㄕㄡㄓㄨㄥ":"受終/壽終",
"ㄕㄡㄔㄨㄤ":"受創/首創",
"ㄕㄡㄔㄨㄥ":"受寵",
"ㄕㄡㄕㄌㄩ":"收視率",
"ㄕㄡㄕㄢㄕ":"壽山石",
"ㄕㄡㄕㄨㄕ":"手術室",
"ㄕㄡㄕㄨㄟ":"獸睡",
"ㄕㄡㄖㄈㄥ":"首日封",
"ㄕㄡㄖㄨㄛ":"瘦弱",
"ㄕㄡㄖㄨㄢ":"手軟",
"ㄕㄡㄖㄨㄥ":"收容",
"ㄕㄡㄗㄨㄛ":"手作/首座",
"ㄕㄡㄗㄨㄟ":"受罪",
"ㄕㄡㄗㄨㄥ":"首從",
"ㄕㄡㄘㄨㄛ":"受挫/收撮",
"ㄕㄡㄙㄨㄛ":"收縮",
"ㄕㄡㄙㄨㄟ":"守歲",
"ㄕㄡㄙㄨㄣ":"受損/瘦損",
"ㄕㄡㄙㄨㄥ":"手鬆",
"ㄕㄡㄧㄈㄟ":"受益費",
"ㄕㄡㄧㄖㄣ":"受益人",
"ㄕㄡㄩㄍㄜ":"手語歌",
"ㄕㄢㄅㄧㄢ":"善變/擅變",
"ㄕㄢㄅㄧㄥ":"山冰/擅兵",
"ㄕㄢㄇㄧㄢ":"扇面",
"ㄕㄢㄉㄧㄢ":"閃電/山巔",
"ㄕㄢㄉㄧㄥ":"山頂",
"ㄕㄢㄉㄧㄨ":"山地舞",
"ㄕㄢㄉㄨㄛ":"閃躲",
"ㄕㄢㄉㄨㄟ":"扇對",
"ㄕㄢㄉㄨㄥ":"煽動/山東",
"ㄕㄢㄊㄡㄕ":"汕頭市",
"ㄕㄢㄊㄧㄥ":"山庭",
"ㄕㄢㄊㄨㄛ":"疝脫",
"ㄕㄢㄌㄧㄢ":"訕臉",
"ㄕㄢㄌㄧㄣ":"山林",
"ㄕㄢㄌㄧㄤ":"善良/閃亮",
"ㄕㄢㄌㄧㄥ":"山嶺/山陵",
"ㄕㄢㄌㄨㄛ":"芟落",
"ㄕㄢㄌㄨㄢ":"山巒",
"ㄕㄢㄍㄨㄗ":"扇骨子",
"ㄕㄢㄍㄨㄛ":"閃過/赸過",
"ㄕㄢㄍㄨㄢ":"杉關",
"ㄕㄢㄍㄨㄣ":"善棍",
"ㄕㄢㄍㄨㄤ":"閃光",
"ㄕㄢㄎㄨㄞ":"苫塊",
"ㄕㄢㄏㄨㄚ":"善化",
"ㄕㄢㄏㄨㄛ":"山貨/搧火",
"ㄕㄢㄏㄨㄟ":"芟穢",
"ㄕㄢㄏㄨㄥ":"山洪",
"ㄕㄢㄐㄧㄚ":"山家/善價",
"ㄕㄢㄐㄧㄝ":"刪節",
"ㄕㄢㄐㄧㄠ":"山腳/山轎",
"ㄕㄢㄐㄧㄢ":"山澗/刪減",
"ㄕㄢㄐㄧㄥ":"山徑/山荊",
"ㄕㄢㄐㄩㄣ":"山君",
"ㄕㄢㄑㄧㄡ":"山丘/芟秋",
"ㄕㄢㄑㄧㄤ":"山牆",
"ㄕㄢㄑㄧㄥ":"煽情",
"ㄕㄢㄑㄩㄢ":"山泉/擅權",
"ㄕㄢㄑㄩㄣ":"山群",
"ㄕㄢㄒㄧㄝ":"繕寫",
"ㄕㄢㄒㄧㄠ":"姍笑/訕笑",
"ㄕㄢㄒㄧㄢ":"閃現/山線",
"ㄕㄢㄒㄧㄣ":"善心/羶心",
"ㄕㄢㄒㄧㄤ":"山向/山響",
"ㄕㄢㄒㄧㄥ":"善行/羶行",
"ㄕㄢㄒㄩㄢ":"潸泫",
"ㄕㄢㄓㄓㄨ":"山躑躅",
"ㄕㄢㄓㄨㄛ":"閃灼",
"ㄕㄢㄓㄨㄢ":"擅專/閃賺",
"ㄕㄢㄓㄨㄤ":"山莊",
"ㄕㄢㄓㄨㄥ":"善終",
"ㄕㄢㄓㄨㄩ":"山茱萸",
"ㄕㄢㄔㄨㄢ":"山川",
"ㄕㄢㄕㄨㄛ":"閃爍",
"ㄕㄢㄕㄨㄟ":"山水",
"ㄕㄢㄖㄨㄣ":"刪潤",
"ㄕㄢㄗㄕㄥ":"扇子生",
"ㄕㄢㄗㄨㄟ":"山嘴/訕嘴",
"ㄕㄢㄘㄨㄣ":"山村",
"ㄕㄢㄙㄨㄟ":"善歲",
"ㄕㄢㄨㄨㄟ":"善無畏",
"ㄕㄣㄅㄧㄢ":"身邊/申辯",
"ㄕㄣㄅㄧㄥ":"神兵",
"ㄕㄣㄆㄧㄣ":"神品",
"ㄕㄣㄇㄧㄠ":"神妙/神廟",
"ㄕㄣㄇㄧㄥ":"神明/身命",
"ㄕㄣㄉㄧㄢ":"神殿",
"ㄕㄣㄉㄧㄥ":"審訂/審定",
"ㄕㄣㄉㄨㄛ":"審度",
"ㄕㄣㄉㄨㄢ":"身段/審斷",
"ㄕㄣㄉㄨㄥ":"紳董",
"ㄕㄣㄊㄨㄟ":"伸腿",
"ㄕㄣㄊㄨㄥ":"神童/神通",
"ㄕㄣㄋㄧㄢ":"深念",
"ㄕㄣㄋㄧㄤ":"嬸娘",
"ㄕㄣㄌㄧㄠ":"神聊",
"ㄕㄣㄌㄧㄤ":"身量",
"ㄕㄣㄌㄧㄥ":"神靈/申領",
"ㄕㄣㄌㄨㄣ":"申論",
"ㄕㄣㄍㄨㄚ":"沈括",
"ㄕㄣㄍㄨㄞ":"神怪",
"ㄕㄣㄍㄨㄟ":"深閨",
"ㄕㄣㄍㄨㄢ":"神觀",
"ㄕㄣㄍㄨㄣ":"神棍",
"ㄕㄣㄍㄨㄤ":"深廣",
"ㄕㄣㄍㄨㄥ":"申公/神功",
"ㄕㄣㄎㄨㄟ":"腎虧",
"ㄕㄣㄎㄨㄥ":"深恐",
"ㄕㄣㄏㄡㄕ":"身後事",
"ㄕㄣㄏㄨㄚ":"神話/神化",
"ㄕㄣㄏㄨㄛ":"甚或",
"ㄕㄣㄏㄨㄟ":"神會",
"ㄕㄣㄏㄨㄣ":"神魂",
"ㄕㄣㄐㄧㄚ":"身家/身價",
"ㄕㄣㄐㄧㄝ":"申解/神界",
"ㄕㄣㄐㄧㄠ":"深交/身教",
"ㄕㄣㄐㄧㄡ":"深究/申救",
"ㄕㄣㄐㄧㄢ":"深澗",
"ㄕㄣㄐㄧㄣ":"紳衿",
"ㄕㄣㄐㄧㄥ":"神經/神京",
"ㄕㄣㄐㄩㄝ":"審決",
"ㄕㄣㄐㄩㄣ":"神君",
"ㄕㄣㄑㄧㄝ":"深切/甚且",
"ㄕㄣㄑㄧㄡ":"深秋",
"ㄕㄣㄑㄧㄢ":"深淺/神遷",
"ㄕㄣㄑㄧㄥ":"神情/申請",
"ㄕㄣㄑㄩㄢ":"神權",
"ㄕㄣㄒㄧㄚ":"身下",
"ㄕㄣㄒㄧㄝ":"申謝",
"ㄕㄣㄒㄧㄠ":"深宵/哂笑",
"ㄕㄣㄒㄧㄡ":"神秀",
"ㄕㄣㄒㄧㄢ":"神仙/深陷",
"ㄕㄣㄒㄧㄣ":"深信/身心",
"ㄕㄣㄒㄧㄤ":"神像/申詳",
"ㄕㄣㄒㄧㄥ":"身形/慎行",
"ㄕㄣㄒㄩㄝ":"神學/申雪",
"ㄕㄣㄒㄩㄣ":"審訊",
"ㄕㄣㄓㄨㄤ":"申狀",
"ㄕㄣㄓㄨㄥ":"慎重/深重",
"ㄕㄣㄔㄨㄟ":"邥垂",
"ㄕㄣㄔㄨㄥ":"申重",
"ㄕㄣㄕㄨㄛ":"申說",
"ㄕㄣㄕㄨㄟ":"神水/滲水",
"ㄕㄣㄗㄨㄛ":"神座",
"ㄕㄣㄘㄨㄥ":"深叢",
"ㄕㄣㄙㄨㄛ":"伸縮",
"ㄕㄣㄙㄨㄟ":"神髓/深邃",
"ㄕㄣㄙㄨㄢ":"神算",
"ㄕㄣㄧㄚㄓ":"沈亞之",
"ㄕㄣㄧㄤㄕ":"瀋陽市",
"ㄕㄣㄩㄧㄢ":"腎盂炎",
"ㄕㄤㄅㄟㄗ":"上輩子",
"ㄕㄤㄅㄧㄠ":"商標/上表",
"ㄕㄤㄅㄧㄢ":"上邊",
"ㄕㄤㄅㄧㄣ":"上賓",
"ㄕㄤㄅㄧㄥ":"傷兵",
"ㄕㄤㄆㄧㄢ":"上片",
"ㄕㄤㄆㄧㄣ":"商品/上品",
"ㄕㄤㄇㄚㄕ":"上馬石",
"ㄕㄤㄇㄧㄢ":"上面",
"ㄕㄤㄇㄧㄥ":"上命",
"ㄕㄤㄉㄧㄠ":"上吊/上弔",
"ㄕㄤㄉㄧㄢ":"商店",
"ㄕㄤㄉㄧㄥ":"上丁/商定",
"ㄕㄤㄉㄨㄟ":"商隊/商兌",
"ㄕㄤㄉㄨㄥ":"上凍",
"ㄕㄤㄊㄧㄢ":"上天",
"ㄕㄤㄊㄧㄥ":"商亭",
"ㄕㄤㄊㄨㄥ":"傷痛",
"ㄕㄤㄋㄧㄢ":"上年",
"ㄕㄤㄌㄧㄝ":"上列",
"ㄕㄤㄌㄧㄡ":"上流",
"ㄕㄤㄌㄧㄢ":"上聯/上臉",
"ㄕㄤㄌㄧㄤ":"商量/上梁",
"ㄕㄤㄌㄨㄛ":"上落",
"ㄕㄤㄌㄩㄝ":"商略",
"ㄕㄤㄍㄨㄗ":"尚古自",
"ㄕㄤㄍㄨㄛ":"上國",
"ㄕㄤㄍㄨㄢ":"上官",
"ㄕㄤㄍㄨㄤ":"賞光",
"ㄕㄤㄍㄨㄥ":"上工/上公",
"ㄕㄤㄎㄡㄗ":"上口字",
"ㄕㄤㄎㄨㄞ":"商儈",
"ㄕㄤㄎㄨㄢ":"上款",
"ㄕㄤㄎㄨㄥ":"上空",
"ㄕㄤㄏㄞㄕ":"上海市",
"ㄕㄤㄏㄨㄚ":"上畫/賞花",
"ㄕㄤㄏㄨㄛ":"上貨/上活",
"ㄕㄤㄏㄨㄞ":"傷懷",
"ㄕㄤㄏㄨㄟ":"上會/商會",
"ㄕㄤㄏㄨㄢ":"傷患/上浣",
"ㄕㄤㄏㄨㄤ":"上皇",
"ㄕㄤㄐㄧㄚ":"商家/上家",
"ㄕㄤㄐㄧㄝ":"上街/上界",
"ㄕㄤㄐㄧㄠ":"上焦/晌覺",
"ㄕㄤㄐㄧㄡ":"上九/傷酒",
"ㄕㄤㄐㄧㄢ":"商檢/賞鑒",
"ㄕㄤㄐㄧㄣ":"上進/上緊",
"ㄕㄤㄐㄧㄤ":"上將/上江",
"ㄕㄤㄐㄧㄥ":"上京",
"ㄕㄤㄐㄩㄢ":"上捐",
"ㄕㄤㄑㄧㄚ":"商洽",
"ㄕㄤㄑㄧㄝ":"尚且",
"ㄕㄤㄑㄧㄡ":"商秋",
"ㄕㄤㄑㄧㄢ":"上前/賞錢",
"ㄕㄤㄑㄧㄥ":"上清/上卿",
"ㄕㄤㄑㄩㄝ":"商榷",
"ㄕㄤㄑㄩㄢ":"商圈",
"ㄕㄤㄒㄧㄚ":"上下",
"ㄕㄤㄒㄧㄝ":"上鞋",
"ㄕㄤㄒㄧㄠ":"上校",
"ㄕㄤㄒㄧㄢ":"上限/上線",
"ㄕㄤㄒㄧㄣ":"傷心/上心",
"ㄕㄤㄒㄧㄤ":"上香/上相",
"ㄕㄤㄒㄧㄥ":"上行/上刑",
"ㄕㄤㄒㄩㄝ":"上學/賞雪",
"ㄕㄤㄒㄩㄣ":"上旬",
"ㄕㄤㄓㄨㄛ":"上桌/商酌",
"ㄕㄤㄓㄨㄤ":"上裝/上妝",
"ㄕㄤㄓㄨㄥ":"賞鍾",
"ㄕㄤㄔㄨㄢ":"商船",
"ㄕㄤㄔㄨㄤ":"上床",
"ㄕㄤㄕㄨㄟ":"上水/上稅",
"ㄕㄤㄕㄨㄢ":"上閂",
"ㄕㄤㄗㄨㄛ":"上座",
"ㄕㄤㄗㄨㄣ":"上尊",
"ㄕㄤㄙㄨㄛ":"上鎖",
"ㄕㄤㄙㄨㄢ":"上算",
"ㄕㄤㄙㄨㄣ":"傷損",
"ㄕㄤㄙㄨㄥ":"商頌",
"ㄕㄤㄧㄩㄢ":"上議院",
"ㄕㄥㄅㄧㄢ":"生變/聲辯",
"ㄕㄥㄅㄧㄥ":"生病",
"ㄕㄥㄆㄧㄣ":"聲頻/聖品",
"ㄕㄥㄆㄧㄥ":"生平/昇平",
"ㄕㄥㄇㄧㄠ":"生苗/聖廟",
"ㄕㄥㄇㄧㄢ":"生面",
"ㄕㄥㄇㄧㄣ":"生民",
"ㄕㄥㄇㄧㄥ":"生命/聲明",
"ㄕㄥㄉㄧㄝ":"聲碟",
"ㄕㄥㄉㄧㄠ":"聲調",
"ㄕㄥㄉㄧㄢ":"盛典",
"ㄕㄥㄉㄨㄢ":"生端",
"ㄕㄥㄉㄨㄥ":"生動/盛冬",
"ㄕㄥㄊㄡㄦ":"剩頭兒",
"ㄕㄥㄊㄧㄝ":"生鐵",
"ㄕㄥㄊㄧㄠ":"聲調",
"ㄕㄥㄊㄧㄢ":"升天/生天",
"ㄕㄥㄋㄧㄡ":"聲紐",
"ㄕㄥㄋㄧㄢ":"生年/盛年",
"ㄕㄥㄋㄧㄥ":"生獰",
"ㄕㄥㄌㄧㄠ":"生料",
"ㄕㄥㄌㄧㄡ":"勝流",
"ㄕㄥㄌㄧㄤ":"聲量",
"ㄕㄥㄌㄧㄥ":"生靈/省令",
"ㄕㄥㄌㄩㄝ":"省略",
"ㄕㄥㄍㄨㄛ":"聖果",
"ㄕㄥㄍㄨㄢ":"升官/甥館",
"ㄕㄥㄍㄨㄤ":"生光/聲光",
"ㄕㄥㄍㄨㄥ":"聖功/聖躬",
"ㄕㄥㄎㄨㄤ":"盛況/生壙",
"ㄕㄥㄎㄨㄥ":"升空/生恐",
"ㄕㄥㄏㄨㄗ":"生虎子",
"ㄕㄥㄏㄨㄚ":"昇華/生花",
"ㄕㄥㄏㄨㄛ":"生活/生火",
"ㄕㄥㄏㄨㄟ":"盛會/省會",
"ㄕㄥㄏㄨㄢ":"生還/聲喚",
"ㄕㄥㄏㄨㄣ":"生魂",
"ㄕㄥㄏㄨㄤ":"生荒/笙簧",
"ㄕㄥㄐㄧㄚ":"聲價/省家",
"ㄕㄥㄐㄧㄝ":"繩結/聖潔",
"ㄕㄥㄐㄧㄠ":"生角/聲教",
"ㄕㄥㄐㄧㄡ":"生就",
"ㄕㄥㄐㄧㄢ":"省減/盛鬋",
"ㄕㄥㄐㄧㄣ":"生津",
"ㄕㄥㄐㄧㄤ":"升降/生薑",
"ㄕㄥㄐㄧㄥ":"聖經/勝景",
"ㄕㄥㄐㄩㄣ":"生菌",
"ㄕㄥㄑㄧㄠ":"繩橋",
"ㄕㄥㄑㄧㄢ":"生前/升遷",
"ㄕㄥㄑㄧㄣ":"生擒",
"ㄕㄥㄑㄧㄤ":"聲腔",
"ㄕㄥㄑㄧㄥ":"聲請/盛情",
"ㄕㄥㄑㄩㄝ":"省卻",
"ㄕㄥㄒㄧㄚ":"剩下/盛夏",
"ㄕㄥㄒㄧㄠ":"生效/生小",
"ㄕㄥㄒㄧㄢ":"聖賢/生鮮",
"ㄕㄥㄒㄧㄣ":"生心/聖心",
"ㄕㄥㄒㄧㄤ":"聲響/生相",
"ㄕㄥㄒㄧㄥ":"生性/盛行",
"ㄕㄥㄒㄩㄝ":"升學/聲學",
"ㄕㄥㄒㄩㄣ":"聲訓",
"ㄕㄥㄒㄩㄥ":"聖雄",
"ㄕㄥㄓㄑㄧ":"生殖器",
"ㄕㄥㄓㄨㄢ":"盛饌",
"ㄕㄥㄓㄨㄤ":"盛裝",
"ㄕㄥㄓㄨㄥ":"省中",
"ㄕㄥㄔㄨㄢ":"盛傳",
"ㄕㄥㄔㄨㄤ":"繩床",
"ㄕㄥㄕㄍㄠ":"生石膏",
"ㄕㄥㄕㄨㄞ":"盛衰",
"ㄕㄥㄕㄨㄟ":"生水/聖水",
"ㄕㄥㄖㄎㄚ":"生日卡",
"ㄕㄥㄖㄨㄥ":"聲容",
"ㄕㄥㄗㄨㄛ":"升坐",
"ㄕㄥㄘㄨㄣ":"生存",
"ㄕㄥㄙㄅㄨ":"生死簿",
"ㄕㄥㄙㄏㄞ":"生死海",
"ㄕㄥㄙㄨㄛ":"繩索",
"ㄕㄥㄙㄨㄢ":"勝算",
"ㄕㄥㄦㄓㄓ":"生而知之",
"ㄕㄥㄧㄎㄡ":"生意口",
"ㄕㄥㄧㄖㄣ":"生意人",
"ㄕㄥㄨㄋㄥ":"生物能",
"ㄕㄥㄨㄓㄢ":"生物戰",
"ㄕㄦㄍㄨㄥ":"十二宮",
"ㄕㄦㄐㄧㄥ":"十二經",
"ㄕㄦㄓㄔㄤ":"十二指腸",
"ㄕㄦㄕㄊㄨ":"十二使徒",
"ㄕㄧㄏㄨㄟ":"市議會",
"ㄕㄧㄓㄌㄧ":"什一之利",
"ㄕㄧㄕㄍㄞ":"時移事改",
"ㄕㄧㄕㄕㄨ":"時異事殊",
"ㄕㄧㄙㄨㄧ":"時移俗易",
"ㄕㄧㄝㄌㄩ":"失業率",
"ㄕㄧㄡㄈㄥ":"石尤風",
"ㄕㄧㄡㄋㄠ":"石油腦",
"ㄕㄧㄡㄑㄧ":"石油氣",
"ㄕㄧㄢㄈㄚ":"實驗法",
"ㄕㄧㄢㄙㄜ":"使眼色",
"ㄕㄧㄣㄅㄣ":"石印本",
"ㄕㄧㄥㄕㄥ":"侍應生",
"ㄕㄧㄥㄧㄢ":"石英岩",
"ㄕㄧㄧㄢㄓ":"詩以言志",
"ㄕㄨㄅㄧㄝ":"殊別",
"ㄕㄨㄅㄧㄢ":"戍邊",
"ㄕㄨㄅㄨㄓ":"殊不知",
"ㄕㄨㄆㄧㄣ":"書品/殊品",
"ㄕㄨㄆㄧㄥ":"書評",
"ㄕㄨㄇㄟㄗ":"樹莓子",
"ㄕㄨㄇㄧㄕ":"樞密使",
"ㄕㄨㄇㄧㄠ":"熟苗/樹苗",
"ㄕㄨㄇㄧㄢ":"書面/庶免",
"ㄕㄨㄇㄧㄣ":"庶民",
"ㄕㄨㄇㄧㄥ":"署名/贖命",
"ㄕㄨㄇㄨㄗ":"數目字",
"ㄕㄨㄉㄞㄗ":"書呆子",
"ㄕㄨㄉㄧㄗ":"書底子",
"ㄕㄨㄉㄧㄠ":"豎刁",
"ㄕㄨㄉㄧㄢ":"書店/贖典",
"ㄕㄨㄉㄨㄣ":"樹墩",
"ㄕㄨㄊㄠㄗ":"熟套子",
"ㄕㄨㄊㄧㄝ":"熟鐵",
"ㄕㄨㄊㄧㄠ":"薯條",
"ㄕㄨㄊㄧㄢ":"暑天",
"ㄕㄨㄊㄧㄥ":"樹梃/蜀艇",
"ㄕㄨㄊㄨㄥ":"疏通/書僮",
"ㄕㄨㄋㄧㄝ":"庶孽",
"ㄕㄨㄋㄧㄡ":"樞紐",
"ㄕㄨㄋㄧㄢ":"熟年",
"ㄕㄨㄌㄧㄝ":"數列",
"ㄕㄨㄌㄧㄠ":"孰料",
"ㄕㄨㄌㄧㄢ":"熟練",
"ㄕㄨㄌㄧㄣ":"樹林/書林",
"ㄕㄨㄌㄧㄤ":"數量/輸糧",
"ㄕㄨㄌㄧㄥ":"樹齡",
"ㄕㄨㄌㄨㄛ":"數落/熟落",
"ㄕㄨㄌㄨㄥ":"梳櫳",
"ㄕㄨㄍㄨㄚ":"樹掛",
"ㄕㄨㄍㄨㄛ":"蔬果/屬國",
"ㄕㄨㄍㄨㄟ":"書櫃",
"ㄕㄨㄍㄨㄢ":"事務官",
"ㄕㄨㄍㄨㄤ":"曙光",
"ㄕㄨㄍㄨㄥ":"叔公",
"ㄕㄨㄎㄡㄗ":"書扣子",
"ㄕㄨㄎㄨㄛ":"疏闊",
"ㄕㄨㄎㄨㄟ":"蜀葵",
"ㄕㄨㄎㄨㄢ":"贖款",
"ㄕㄨㄎㄨㄣ":"紓困",
"ㄕㄨㄎㄨㄤ":"疏狂",
"ㄕㄨㄎㄨㄥ":"書空",
"ㄕㄨㄏㄤㄗ":"樹行子",
"ㄕㄨㄏㄨㄚ":"書畫/熟話",
"ㄕㄨㄏㄨㄛ":"熟貨",
"ㄕㄨㄏㄨㄞ":"舒懷/攄懷",
"ㄕㄨㄏㄨㄟ":"書會/熟會",
"ㄕㄨㄏㄨㄢ":"舒緩/豎宦",
"ㄕㄨㄏㄨㄤ":"熟荒/儵怳",
"ㄕㄨㄐㄧㄚ":"暑假/書架",
"ㄕㄨㄐㄧㄝ":"疏解/抒解",
"ㄕㄨㄐㄧㄠ":"熟腳/樹膠",
"ㄕㄨㄐㄧㄡ":"數九/秫酒",
"ㄕㄨㄐㄧㄢ":"熟間/書簡",
"ㄕㄨㄐㄧㄣ":"贖金/束緊",
"ㄕㄨㄐㄧㄤ":"輸將",
"ㄕㄨㄐㄧㄥ":"書經/熟精",
"ㄕㄨㄐㄩㄝ":"殊絕",
"ㄕㄨㄐㄩㄢ":"書卷/輸捐",
"ㄕㄨㄐㄩㄣ":"淑均/疏浚",
"ㄕㄨㄑㄧㄝ":"書篋/鼠竊",
"ㄕㄨㄑㄧㄢ":"書籤/輸錢",
"ㄕㄨㄑㄧㄣ":"豎琴",
"ㄕㄨㄑㄧㄥ":"抒情/攄情",
"ㄕㄨㄑㄩㄝ":"署缺",
"ㄕㄨㄑㄩㄣ":"書裙",
"ㄕㄨㄒㄧㄚ":"屬下",
"ㄕㄨㄒㄧㄝ":"書寫/輸寫",
"ㄕㄨㄒㄧㄠ":"殊效",
"ㄕㄨㄒㄧㄡ":"束修/束脩",
"ㄕㄨㄒㄧㄢ":"束線/熟閑",
"ㄕㄨㄒㄧㄣ":"書信/輸心",
"ㄕㄨㄒㄧㄤ":"書香",
"ㄕㄨㄒㄧㄥ":"屬性/淑性",
"ㄕㄨㄒㄩㄝ":"數學/書學",
"ㄕㄨㄒㄩㄢ":"殊選",
"ㄕㄨㄒㄩㄣ":"殊勛",
"ㄕㄨㄒㄩㄥ":"束胸",
"ㄕㄨㄓㄨㄛ":"書桌",
"ㄕㄨㄓㄨㄢ":"書傳",
"ㄕㄨㄓㄨㄤ":"梳妝/束裝",
"ㄕㄨㄓㄨㄥ":"數中/書種",
"ㄕㄨㄔㄨㄥ":"書蟲/殊寵",
"ㄕㄨㄕㄍㄠ":"熟石膏",
"ㄕㄨㄕㄨㄛ":"數說/述說",
"ㄕㄨㄕㄨㄞ":"疏率",
"ㄕㄨㄕㄨㄟ":"熟睡/熟水",
"ㄕㄨㄕㄨㄣ":"倏瞬",
"ㄕㄨㄕㄨㄤ":"舒爽",
"ㄕㄨㄕㄨㄦ":"數數兒",
"ㄕㄨㄖㄨㄛ":"孰若",
"ㄕㄨㄖㄨㄥ":"殊榮",
"ㄕㄨㄗㄨㄛ":"述作",
"ㄕㄨㄗㄨㄟ":"贖罪/恕罪",
"ㄕㄨㄘㄨㄢ":"鼠竄/鼠攛",
"ㄕㄨㄘㄨㄥ":"樹叢",
"ㄕㄨㄙㄓㄢ":"殊死戰",
"ㄕㄨㄙㄨㄛ":"事務所",
"ㄕㄨㄙㄨㄟ":"暑歲",
"ㄕㄨㄙㄨㄣ":"叔孫",
"ㄕㄨㄙㄨㄥ":"輸送/疏鬆",
"ㄕㄨㄚㄅㄞ":"刷白",
"ㄕㄨㄚㄅㄠ":"耍寶",
"ㄕㄨㄚㄅㄢ":"刷扮",
"ㄕㄨㄚㄉㄡ":"耍鬥",
"ㄕㄨㄚㄌㄚ":"刷拉",
"ㄕㄨㄚㄌㄞ":"耍賴",
"ㄕㄨㄚㄎㄚ":"刷卡",
"ㄕㄨㄚㄏㄣ":"耍狠",
"ㄕㄨㄚㄒㄧ":"耍戲/刷洗",
"ㄕㄨㄚㄓㄠ":"刷照",
"ㄕㄨㄚㄧㄚ":"刷牙",
"ㄕㄨㄚㄧㄣ":"刷印",
"ㄕㄨㄛㄅㄞ":"說白",
"ㄕㄨㄛㄅㄨ":"說部",
"ㄕㄨㄛㄆㄛ":"說破",
"ㄕㄨㄛㄇㄛ":"朔漠",
"ㄕㄨㄛㄇㄟ":"說媒",
"ㄕㄨㄛㄇㄠ":"蒴帽",
"ㄕㄨㄛㄇㄥ":"說夢",
"ㄕㄨㄛㄈㄚ":"說法",
"ㄕㄨㄛㄈㄢ":"說泛",
"ㄕㄨㄛㄈㄤ":"朔方",
"ㄕㄨㄛㄈㄥ":"朔風",
"ㄕㄨㄛㄈㄨ":"說郛",
"ㄕㄨㄛㄉㄚ":"碩大",
"ㄕㄨㄛㄉㄠ":"說道",
"ㄕㄨㄛㄉㄤ":"朔黨",
"ㄕㄨㄛㄌㄞ":"說來",
"ㄕㄨㄛㄌㄧ":"說理",
"ㄕㄨㄛㄍㄨ":"說古",
"ㄕㄨㄛㄎㄞ":"說開",
"ㄕㄨㄛㄎㄡ":"說口",
"ㄕㄨㄛㄏㄜ":"說合/說和",
"ㄕㄨㄛㄏㄠ":"說好",
"ㄕㄨㄛㄑㄧ":"說起/朔氣",
"ㄕㄨㄛㄓㄥ":"朔政",
"ㄕㄨㄛㄔㄚ":"說岔",
"ㄕㄨㄛㄔㄜ":"說徹",
"ㄕㄨㄛㄔㄤ":"說唱",
"ㄕㄨㄛㄔㄨ":"說處",
"ㄕㄨㄛㄕㄜ":"說舌",
"ㄕㄨㄛㄕㄠ":"箾韶",
"ㄕㄨㄛㄕㄨ":"說書/碩鼠",
"ㄕㄨㄛㄖㄣ":"碩人",
"ㄕㄨㄛㄖㄨ":"碩儒",
"ㄕㄨㄛㄧㄢ":"碩言/搠渰",
"ㄕㄨㄛㄨㄣ":"說文",
"ㄕㄨㄛㄨㄤ":"朔望/碩望",
"ㄕㄨㄛㄩㄝ":"朔月",
"ㄕㄨㄛㄩㄢ":"說苑",
"ㄕㄨㄛㄩㄣ":"朔雲",
"ㄕㄨㄞㄅㄞ":"衰敗",
"ㄕㄨㄞㄆㄛ":"摔破",
"ㄕㄨㄞㄆㄠ":"摔炮",
"ㄕㄨㄞㄇㄞ":"衰邁",
"ㄕㄨㄞㄇㄨ":"衰暮",
"ㄕㄨㄞㄈㄚ":"甩髮",
"ㄕㄨㄞㄉㄚ":"摔打",
"ㄕㄨㄞㄉㄠ":"摔倒",
"ㄕㄨㄞㄊㄚ":"衰塌",
"ㄕㄨㄞㄊㄧ":"衰替",
"ㄕㄨㄞㄌㄠ":"衰老",
"ㄕㄨㄞㄍㄜ":"帥哥",
"ㄕㄨㄞㄑㄧ":"帥氣",
"ㄕㄨㄞㄓㄣ":"率真",
"ㄕㄨㄞㄔㄜ":"甩車",
"ㄕㄨㄞㄕㄡ":"甩手/摔手",
"ㄕㄨㄞㄕㄤ":"摔傷",
"ㄕㄨㄞㄖㄢ":"率然",
"ㄕㄨㄞㄙㄤ":"摔喪",
"ㄕㄨㄞㄧㄢ":"衰顏",
"ㄕㄨㄞㄨㄟ":"衰微",
"ㄕㄨㄞㄨㄤ":"衰亡",
"ㄕㄨㄟㄅㄚ":"水壩",
"ㄕㄨㄟㄅㄞ":"水稗",
"ㄕㄨㄟㄅㄥ":"水泵",
"ㄕㄨㄟㄅㄧ":"水筆",
"ㄕㄨㄟㄅㄨ":"水部",
"ㄕㄨㄟㄆㄞ":"水牌",
"ㄕㄨㄟㄆㄠ":"水泡/睡袍",
"ㄕㄨㄟㄆㄣ":"水盆",
"ㄕㄨㄟㄇㄚ":"水麻",
"ㄕㄨㄟㄇㄛ":"水磨/水磨",
"ㄕㄨㄟㄇㄞ":"水脈",
"ㄕㄨㄟㄇㄠ":"睡帽",
"ㄕㄨㄟㄇㄣ":"水門",
"ㄕㄨㄟㄇㄥ":"睡夢",
"ㄕㄨㄟㄇㄧ":"水米",
"ㄕㄨㄟㄇㄨ":"水母/水木",
"ㄕㄨㄟㄈㄚ":"稅法",
"ㄕㄨㄟㄈㄟ":"水飛/水費",
"ㄕㄨㄟㄈㄢ":"水飯",
"ㄕㄨㄟㄈㄣ":"水分/水粉",
"ㄕㄨㄟㄈㄨ":"說服/水府",
"ㄕㄨㄟㄉㄞ":"睡袋",
"ㄕㄨㄟㄉㄠ":"水道/水稻",
"ㄕㄨㄟㄉㄡ":"水痘",
"ㄕㄨㄟㄉㄢ":"稅單/水單",
"ㄕㄨㄟㄉㄥ":"水燈",
"ㄕㄨㄟㄉㄧ":"水滴",
"ㄕㄨㄟㄉㄨ":"水都",
"ㄕㄨㄟㄊㄚ":"水塔/水獺",
"ㄕㄨㄟㄊㄡ":"水頭",
"ㄕㄨㄟㄊㄤ":"水塘",
"ㄕㄨㄟㄊㄧ":"水體",
"ㄕㄨㄟㄊㄨ":"水土",
"ㄕㄨㄟㄋㄥ":"水能",
"ㄕㄨㄟㄋㄧ":"水泥",
"ㄕㄨㄟㄌㄟ":"水雷",
"ㄕㄨㄟㄌㄡ":"水漏",
"ㄕㄨㄟㄌㄧ":"水利/水力",
"ㄕㄨㄟㄌㄨ":"水路/水陸",
"ㄕㄨㄟㄌㄩ":"稅率",
"ㄕㄨㄟㄍㄡ":"水溝",
"ㄕㄨㄟㄍㄣ":"水根",
"ㄕㄨㄟㄍㄤ":"水缸",
"ㄕㄨㄟㄍㄥ":"水耕",
"ㄕㄨㄟㄎㄜ":"水客/說客",
"ㄕㄨㄟㄎㄡ":"水口",
"ㄕㄨㄟㄎㄥ":"水坑",
"ㄕㄨㄟㄎㄨ":"水庫/睡褲",
"ㄕㄨㄟㄏㄜ":"水鶴",
"ㄕㄨㄟㄏㄢ":"水旱",
"ㄕㄨㄟㄏㄨ":"水壺/水戽",
"ㄕㄨㄟㄐㄧ":"水雞/稅基",
"ㄕㄨㄟㄐㄩ":"水局/水居",
"ㄕㄨㄟㄑㄧ":"水氣/稅契",
"ㄕㄨㄟㄑㄩ":"水曲",
"ㄕㄨㄟㄒㄧ":"水洗/水系",
"ㄕㄨㄟㄓㄚ":"水閘/水柵",
"ㄕㄨㄟㄓㄠ":"睡著",
"ㄕㄨㄟㄓㄢ":"水戰",
"ㄕㄨㄟㄓㄨ":"水注/水珠",
"ㄕㄨㄟㄔㄚ":"水汊",
"ㄕㄨㄟㄔㄜ":"水車",
"ㄕㄨㄟㄔㄞ":"水蠆",
"ㄕㄨㄟㄔㄢ":"水產/水懺",
"ㄕㄨㄟㄔㄣ":"帨辰",
"ㄕㄨㄟㄔㄤ":"水廠",
"ㄕㄨㄟㄔㄥ":"水程/水秤",
"ㄕㄨㄟㄕㄜ":"水蛇",
"ㄕㄨㄟㄕㄞ":"水篩",
"ㄕㄨㄟㄕㄠ":"水勺/水筲",
"ㄕㄨㄟㄕㄡ":"水手/稅收",
"ㄕㄨㄟㄕㄢ":"水杉",
"ㄕㄨㄟㄕㄣ":"水神",
"ㄕㄨㄟㄕㄤ":"水商",
"ㄕㄨㄟㄖㄨ":"水乳",
"ㄕㄨㄟㄗㄜ":"水澤/稅則",
"ㄕㄨㄟㄗㄞ":"水災",
"ㄕㄨㄟㄗㄟ":"水賊",
"ㄕㄨㄟㄗㄠ":"水藻/水蚤",
"ㄕㄨㄟㄗㄤ":"水葬",
"ㄕㄨㄟㄗㄨ":"水族",
"ㄕㄨㄟㄘㄞ":"水彩",
"ㄕㄨㄟㄘㄠ":"水草/水槽",
"ㄕㄨㄟㄧㄚ":"水壓/水鴨",
"ㄕㄨㄟㄧㄢ":"水煙/睡眼",
"ㄕㄨㄟㄧㄣ":"水銀/水印",
"ㄕㄨㄟㄧㄤ":"水楊",
"ㄕㄨㄟㄨㄙ":"稅務司",
"ㄕㄨㄟㄨㄟ":"水位",
"ㄕㄨㄟㄨㄣ":"水溫/水文",
"ㄕㄨㄟㄨㄤ":"水網",
"ㄕㄨㄟㄩㄝ":"水月",
"ㄕㄨㄟㄩㄢ":"水源/稅源",
"ㄕㄨㄟㄩㄣ":"水運",
"ㄕㄨㄢㄆㄧ":"栓皮",
"ㄕㄨㄢㄈㄨ":"拴縛",
"ㄕㄨㄢㄐㄧ":"栓劑",
"ㄕㄨㄢㄓㄨ":"拴住",
"ㄕㄨㄢㄕㄨ":"拴束",
"ㄕㄨㄢㄙㄜ":"栓塞",
"ㄕㄨㄣㄅㄚ":"順把",
"ㄕㄨㄣㄅㄧ":"楯鼻",
"ㄕㄨㄣㄇㄨ":"瞬目",
"ㄕㄨㄣㄈㄥ":"順風",
"ㄕㄨㄣㄈㄨ":"順服",
"ㄕㄨㄣㄉㄜ":"順德",
"ㄕㄨㄣㄉㄞ":"順帶/順袋",
"ㄕㄨㄣㄉㄠ":"順道/順導",
"ㄕㄨㄣㄉㄤ":"順當",
"ㄕㄨㄣㄌㄧ":"順利/順理",
"ㄕㄨㄣㄌㄨ":"順路",
"ㄕㄨㄣㄎㄡ":"順口",
"ㄕㄨㄣㄏㄜ":"順和",
"ㄕㄨㄣㄏㄡ":"順候",
"ㄕㄨㄣㄐㄧ":"瞬即",
"ㄕㄨㄣㄐㄩ":"吮疽",
"ㄕㄨㄣㄒㄧ":"瞬息/吮吸",
"ㄕㄨㄣㄒㄩ":"順序/順序",
"ㄕㄨㄣㄓㄥ":"順證",
"ㄕㄨㄣㄔㄚ":"順差",
"ㄕㄨㄣㄔㄢ":"順產",
"ㄕㄨㄣㄔㄤ":"順暢",
"ㄕㄨㄣㄕㄡ":"順手/順守",
"ㄕㄨㄣㄙㄨ":"順俗",
"ㄕㄨㄣㄧㄢ":"順眼/順延",
"ㄕㄨㄣㄧㄥ":"順應/舜英",
"ㄕㄨㄣㄨㄟ":"順位",
"ㄕㄨㄣㄩㄣ":"順運",
"ㄕㄨㄤㄅㄟ":"雙倍",
"ㄕㄨㄤㄅㄠ":"雙包",
"ㄕㄨㄤㄇㄛ":"視網膜",
"ㄕㄨㄤㄇㄨ":"孀母",
"ㄕㄨㄤㄈㄟ":"雙飛",
"ㄕㄨㄤㄈㄤ":"雙方",
"ㄕㄨㄤㄈㄥ":"霜鋒",
"ㄕㄨㄤㄈㄨ":"雙扶/孀婦",
"ㄕㄨㄤㄉㄚ":"雙打",
"ㄕㄨㄤㄊㄤ":"雙醣",
"ㄕㄨㄤㄌㄤ":"爽朗",
"ㄕㄨㄤㄌㄧ":"爽利/雙禮",
"ㄕㄨㄤㄌㄨ":"雙陸",
"ㄕㄨㄤㄍㄡ":"雙鉤",
"ㄕㄨㄤㄍㄤ":"雙槓/瀧岡",
"ㄕㄨㄤㄎㄞ":"爽塏/爽愷",
"ㄕㄨㄤㄎㄡ":"爽口",
"ㄕㄨㄤㄏㄞ":"霜害",
"ㄕㄨㄤㄏㄠ":"霜毫",
"ㄕㄨㄤㄐㄧ":"雙戟",
"ㄕㄨㄤㄐㄩ":"孀居",
"ㄕㄨㄤㄑㄧ":"爽氣/孀妻",
"ㄕㄨㄤㄔㄚ":"雙差",
"ㄕㄨㄤㄕㄚ":"雙殺",
"ㄕㄨㄤㄕㄡ":"雙手",
"ㄕㄨㄤㄕㄥ":"雙生/雙聲",
"ㄕㄨㄤㄕㄨ":"雙數",
"ㄕㄨㄤㄖㄢ":"爽然",
"ㄕㄨㄤㄘㄥ":"雙層",
"ㄕㄨㄤㄧㄥ":"雙贏",
"ㄕㄨㄤㄨㄟ":"霜威",
"ㄕㄨㄤㄨㄤ":"雙亡",
"ㄕㄨㄤㄩㄝ":"霜月/爽約",
"ㄕㄨㄤㄩㄣ":"霜隕",
"ㄕㄨㄧㄐㄧ":"述異記",
"ㄕㄨㄨㄟㄘ":"數位詞",
"ㄕㄩㄓㄨㄛ":"拾玉鐲",
"ㄕㄩㄥㄌㄩ":"使用率",
"ㄕㄩㄥㄍㄣ":"食用根",
"ㄖㄅㄣㄏㄞ":"日本海",
"ㄖㄆㄧㄢㄕ":"日偏食",
"ㄖㄈㄨㄧㄖ":"日復一日",
"ㄖㄍㄨㄟㄧ":"日晷儀",
"ㄖㄍㄨㄤㄩ":"日光浴",
"ㄖㄎㄜㄗㄜ":"日喀則",
"ㄖㄏㄨㄢㄕ":"日環蝕",
"ㄖㄐㄧㄓㄤ":"日記帳",
"ㄖㄑㄩㄢㄕ":"日全食",
"ㄖㄓㄕㄉㄞ":"日治時代",
"ㄖㄕㄑㄧㄢ":"日事錢",
"ㄖㄕㄣㄧㄖ":"日甚一日",
"ㄖㄗㄩㄝㄧ":"日滋月益",
"ㄖㄜㄅㄧㄥ":"熱病",
"ㄖㄜㄆㄧㄥ":"熱屏",
"ㄖㄜㄉㄞㄩ":"熱帶魚",
"ㄖㄜㄉㄧㄢ":"熱點/熱電",
"ㄖㄜㄊㄧㄢ":"熱天",
"ㄖㄜㄌㄟㄩ":"熱雷雨",
"ㄖㄜㄌㄧㄝ":"熱烈",
"ㄖㄜㄌㄧㄠ":"熱療",
"ㄖㄜㄌㄧㄡ":"熱流",
"ㄖㄜㄌㄧㄢ":"熱戀",
"ㄖㄜㄌㄧㄤ":"熱量",
"ㄖㄜㄌㄨㄛ":"熱絡",
"ㄖㄜㄌㄨㄢ":"熱亂",
"ㄖㄜㄍㄨㄢ":"熱官",
"ㄖㄜㄏㄨㄚ":"熱化",
"ㄖㄜㄏㄨㄛ":"惹火/熱火",
"ㄖㄜㄐㄩㄝ":"熱覺",
"ㄖㄜㄑㄧㄝ":"熱切",
"ㄖㄜㄑㄧㄢ":"熱錢",
"ㄖㄜㄑㄧㄥ":"熱情",
"ㄖㄜㄒㄧㄝ":"熱血",
"ㄖㄜㄒㄧㄠ":"熱銷/熱孝",
"ㄖㄜㄒㄧㄢ":"熱線",
"ㄖㄜㄒㄧㄣ":"熱心",
"ㄖㄜㄒㄩㄝ":"熱學",
"ㄖㄜㄓㄨㄥ":"熱衷/熱中",
"ㄖㄜㄕㄨㄟ":"熱水",
"ㄖㄜㄗㄨㄛ":"熱作",
"ㄖㄜㄗㄨㄟ":"熱嘴",
"ㄖㄜㄨㄖㄢ":"熱汙染",
"ㄖㄠㄅㄛㄗ":"繞脖子",
"ㄖㄠㄇㄧㄢ":"饒免",
"ㄖㄠㄇㄧㄣ":"擾民",
"ㄖㄠㄇㄧㄥ":"饒命",
"ㄖㄠㄉㄨㄥ":"擾動",
"ㄖㄠㄌㄧㄤ":"繞梁",
"ㄖㄠㄌㄨㄢ":"擾亂",
"ㄖㄠㄏㄨㄚ":"蕘花",
"ㄖㄠㄐㄧㄥ":"繞境",
"ㄖㄠㄒㄧㄥ":"繞行",
"ㄖㄠㄓㄖㄡ":"繞指柔",
"ㄖㄠㄔㄨㄥ":"蟯蟲",
"ㄖㄠㄗㄨㄟ":"繞嘴",
"ㄖㄠㄨㄢㄗ":"繞彎子",
"ㄖㄡㄅㄠㄗ":"肉包子",
"ㄖㄡㄅㄧㄥ":"肉餅",
"ㄖㄡㄆㄧㄠ":"肉票",
"ㄖㄡㄊㄧㄠ":"柔條",
"ㄖㄡㄊㄨㄥ":"肉痛",
"ㄖㄡㄋㄨㄛ":"柔懦",
"ㄖㄡㄌㄧㄠ":"鞣料",
"ㄖㄡㄌㄧㄡ":"肉瘤",
"ㄖㄡㄌㄧㄣ":"蹂躪",
"ㄖㄡㄌㄨㄣ":"揉輪",
"ㄖㄡㄍㄨㄟ":"肉桂",
"ㄖㄡㄍㄨㄢ":"肉冠",
"ㄖㄡㄏㄨㄚ":"柔化",
"ㄖㄡㄏㄨㄥ":"肉紅",
"ㄖㄡㄐㄧㄚ":"柔嘉",
"ㄖㄡㄐㄧㄠ":"肉角",
"ㄖㄡㄐㄧㄤ":"肉醬",
"ㄖㄡㄑㄧㄥ":"柔情",
"ㄖㄡㄒㄧㄢ":"肉餡/揉弦",
"ㄖㄡㄒㄧㄥ":"肉刑",
"ㄖㄡㄕㄍㄜ":"肉食鴿",
"ㄖㄡㄕㄓㄜ":"肉食者",
"ㄖㄡㄕㄨㄣ":"柔順",
"ㄖㄡㄖㄨㄛ":"柔弱",
"ㄖㄡㄖㄨㄢ":"柔軟",
"ㄖㄡㄗㄠㄦ":"肉棗兒",
"ㄖㄡㄗㄨㄥ":"肉粽",
"ㄖㄡㄘㄨㄛ":"揉搓",
"ㄖㄡㄙㄨㄢ":"鞣酸",
"ㄖㄡㄙㄨㄥ":"肉鬆",
"ㄖㄢㄅㄧㄥ":"染病",
"ㄖㄢㄉㄧㄢ":"燃點",
"ㄖㄢㄋㄧㄠ":"姌嫋",
"ㄖㄢㄌㄧㄠ":"染料/燃料",
"ㄖㄢㄏㄨㄚ":"染化/染畫",
"ㄖㄢㄑㄧㄡ":"冉求",
"ㄖㄢㄒㄩㄢ":"染渲",
"ㄖㄢㄖㄨㄛ":"苒弱",
"ㄖㄢㄙㄨㄟ":"然雖",
"ㄖㄣㄅㄧㄢ":"任便",
"ㄖㄣㄆㄧㄣ":"人品",
"ㄖㄣㄆㄧㄥ":"任憑",
"ㄖㄣㄇㄧㄢ":"人面/任免",
"ㄖㄣㄇㄧㄣ":"人民",
"ㄖㄣㄇㄧㄥ":"人命/人名",
"ㄖㄣㄈㄢㄗ":"人販子",
"ㄖㄣㄉㄧㄥ":"認定/人定",
"ㄖㄣㄉㄨㄥ":"忍冬",
"ㄖㄣㄊㄡㄦ":"人頭兒",
"ㄖㄣㄊㄧㄢ":"人天/任天",
"ㄖㄣㄊㄨㄥ":"認同/忍痛",
"ㄖㄣㄌㄧㄡ":"人柳",
"ㄖㄣㄌㄧㄢ":"人鏈",
"ㄖㄣㄌㄧㄣ":"壬林",
"ㄖㄣㄌㄧㄥ":"認領",
"ㄖㄣㄌㄨㄢ":"稔亂",
"ㄖㄣㄌㄨㄣ":"人倫",
"ㄖㄣㄍㄨㄥ":"人工/任公",
"ㄖㄣㄎㄨㄥ":"人孔",
"ㄖㄣㄏㄨㄛ":"人禍",
"ㄖㄣㄏㄨㄟ":"仁惠",
"ㄖㄣㄏㄨㄢ":"人寰",
"ㄖㄣㄏㄨㄤ":"人皇",
"ㄖㄣㄐㄧㄚ":"人家/人家",
"ㄖㄣㄐㄧㄝ":"人傑",
"ㄖㄣㄐㄧㄠ":"任教",
"ㄖㄣㄐㄧㄢ":"人間/人鑑",
"ㄖㄣㄐㄧㄥ":"人境/人鏡",
"ㄖㄣㄐㄩㄝ":"人爵",
"ㄖㄣㄐㄩㄢ":"認捐",
"ㄖㄣㄐㄩㄣ":"人君/仁君",
"ㄖㄣㄑㄧㄚ":"仁洽",
"ㄖㄣㄑㄧㄣ":"認親",
"ㄖㄣㄑㄧㄥ":"人情/任情",
"ㄖㄣㄑㄩㄢ":"人權",
"ㄖㄣㄑㄩㄣ":"人群",
"ㄖㄣㄒㄧㄚ":"任俠",
"ㄖㄣㄒㄧㄠ":"任囂/仁孝",
"ㄖㄣㄒㄧㄣ":"人心/忍心",
"ㄖㄣㄒㄧㄤ":"人像",
"ㄖㄣㄒㄧㄥ":"人性/人性",
"ㄖㄣㄒㄩㄢ":"人選",
"ㄖㄣㄒㄩㄥ":"仁兄/人熊",
"ㄖㄣㄓㄨㄣ":"認準",
"ㄖㄣㄓㄨㄥ":"人中/人種",
"ㄖㄣㄔㄨㄤ":"認床",
"ㄖㄣㄖㄨㄛ":"荏弱",
"ㄖㄣㄖㄨㄟ":"人瑞/仁瑞",
"ㄖㄣㄗㄠㄩ":"人造雨",
"ㄖㄣㄗㄨㄟ":"認罪",
"ㄖㄣㄗㄨㄣ":"仁尊",
"ㄖㄣㄘㄨㄛ":"認錯",
"ㄖㄣㄘㄨㄥ":"人從/人叢",
"ㄖㄣㄙㄨㄛ":"任所",
"ㄖㄣㄧㄓㄕ":"仁義之師",
"ㄖㄣㄧㄚㄦ":"人牙兒",
"ㄖㄣㄧㄤㄗ":"人樣子",
"ㄖㄣㄧㄤㄦ":"人樣兒",
"ㄖㄣㄧㄥㄦ":"人影兒",
"ㄖㄣㄨㄛㄗ":"人窩子",
"ㄖㄣㄩㄕㄨ":"人與鼠",
"ㄖㄤㄅㄧㄥ":"禳病",
"ㄖㄤㄉㄨㄛ":"攘奪",
"ㄖㄤㄊㄧㄢ":"禳田",
"ㄖㄤㄍㄨㄚ":"嚷刮",
"ㄖㄤㄎㄡㄦ":"瓤口兒",
"ㄖㄤㄐㄧㄚ":"讓價",
"ㄖㄤㄐㄧㄝ":"禳解",
"ㄖㄤㄐㄧㄠ":"嚷叫",
"ㄖㄤㄒㄧㄡ":"攘袖",
"ㄖㄤㄒㄧㄢ":"讓賢",
"ㄖㄤㄗㄨㄛ":"讓座",
"ㄖㄤㄙㄨㄟ":"穰歲",
"ㄖㄤㄧㄅㄨ":"讓一步",
"ㄖㄥㄉㄧㄠ":"扔掉",
"ㄖㄥㄐㄧㄡ":"仍舊",
"ㄖㄥㄒㄧㄚ":"扔下",
"ㄖㄥㄙㄨㄣ":"仍孫",
"ㄖㄧㄩㄝㄗ":"日益月滋",
"ㄖㄨㄆㄧㄣ":"乳品",
"ㄖㄨㄇㄧㄥ":"乳名/辱命",
"ㄖㄨㄉㄧㄥ":"入定",
"ㄖㄨㄊㄨㄛ":"入托",
"ㄖㄨㄊㄨㄥ":"如同",
"ㄖㄨㄋㄧㄝ":"嚅囁",
"ㄖㄨㄋㄧㄡ":"乳牛",
"ㄖㄨㄌㄧㄝ":"入列",
"ㄖㄨㄌㄧㄡ":"入流",
"ㄖㄨㄌㄧㄢ":"入簾/入殮",
"ㄖㄨㄌㄧㄣ":"儒林",
"ㄖㄨㄌㄨㄛ":"乳酪",
"ㄖㄨㄌㄨㄥ":"入壟",
"ㄖㄨㄍㄨㄛ":"如果/乳果",
"ㄖㄨㄍㄨㄢ":"入關/入官",
"ㄖㄨㄍㄨㄥ":"入貢/入宮",
"ㄖㄨㄎㄨㄢ":"入款",
"ㄖㄨㄏㄨㄚ":"入話/乳化",
"ㄖㄨㄏㄨㄛ":"入夥",
"ㄖㄨㄏㄨㄟ":"入會",
"ㄖㄨㄏㄨㄣ":"茹葷",
"ㄖㄨㄐㄧㄚ":"儒家",
"ㄖㄨㄐㄧㄝ":"縟節",
"ㄖㄨㄐㄧㄠ":"入教/入腳",
"ㄖㄨㄐㄧㄡ":"如舊",
"ㄖㄨㄐㄧㄢ":"入監/入監",
"ㄖㄨㄐㄧㄣ":"如今/入覲",
"ㄖㄨㄐㄧㄤ":"儒將",
"ㄖㄨㄐㄧㄥ":"入境/入靜",
"ㄖㄨㄑㄧㄡ":"入秋",
"ㄖㄨㄑㄧㄣ":"入侵",
"ㄖㄨㄑㄩㄢ":"乳泉",
"ㄖㄨㄒㄧㄡ":"乳臭/縟繡",
"ㄖㄨㄒㄧㄢ":"乳腺",
"ㄖㄨㄒㄧㄤ":"入庠/乳香",
"ㄖㄨㄒㄩㄝ":"入學/儒學",
"ㄖㄨㄒㄩㄢ":"入選",
"ㄖㄨㄒㄩㄥ":"如兄",
"ㄖㄨㄓㄨㄟ":"入贅",
"ㄖㄨㄔㄨㄤ":"褥瘡/蓐瘡",
"ㄖㄨㄕㄨㄟ":"入睡/汝水",
"ㄖㄨㄗㄧㄥ":"孺子嬰",
"ㄖㄨㄗㄨㄛ":"入座",
"ㄖㄨㄗㄨㄟ":"入罪",
"ㄖㄨㄗㄨㄥ":"儒宗",
"ㄖㄨㄙㄨㄢ":"乳酸",
"ㄖㄨㄛㄅㄧ":"若必",
"ㄖㄨㄛㄇㄠ":"篛帽",
"ㄖㄨㄛㄇㄨ":"若木",
"ㄖㄨㄛㄈㄟ":"若非",
"ㄖㄨㄛㄈㄨ":"若夫",
"ㄖㄨㄛㄉㄚ":"偌大/若大",
"ㄖㄨㄛㄋㄞ":"若乃",
"ㄖㄨㄛㄌㄧ":"篛笠",
"ㄖㄨㄛㄍㄜ":"若個",
"ㄖㄨㄛㄍㄢ":"若干",
"ㄖㄨㄛㄏㄜ":"若何",
"ㄖㄨㄛㄏㄢ":"弱翰",
"ㄖㄨㄛㄐㄧ":"弱疾/弱笄",
"ㄖㄨㄛㄒㄧ":"弱息/蒻席",
"ㄖㄨㄛㄓㄜ":"弱者",
"ㄖㄨㄛㄓㄨ":"篛竹",
"ㄖㄨㄛㄔㄣ":"爇櫬",
"ㄖㄨㄛㄙㄤ":"弱喪",
"ㄖㄨㄛㄧㄥ":"若英",
"ㄖㄨㄟㄅㄤ":"蕊榜",
"ㄖㄨㄟㄇㄞ":"瑞麥",
"ㄖㄨㄟㄈㄤ":"瑞芳",
"ㄖㄨㄟㄈㄨ":"瑞符",
"ㄖㄨㄟㄋㄠ":"瑞腦",
"ㄖㄨㄟㄌㄧ":"銳利",
"ㄖㄨㄟㄑㄧ":"銳氣/瑞氣",
"ㄖㄨㄟㄓㄜ":"睿哲",
"ㄖㄨㄟㄓㄠ":"瑞兆",
"ㄖㄨㄟㄓㄥ":"瑞徵",
"ㄖㄨㄟㄕㄡ":"瑞獸",
"ㄖㄨㄟㄕㄨ":"蕊書",
"ㄖㄨㄟㄗㄠ":"睿藻",
"ㄖㄨㄟㄘㄠ":"瑞草",
"ㄖㄨㄟㄧㄥ":"瑞應",
"ㄖㄨㄟㄩㄣ":"瑞雲",
"ㄖㄨㄢㄅㄠ":"軟飽",
"ㄖㄨㄢㄅㄢ":"軟半",
"ㄖㄨㄢㄅㄧ":"軟壁",
"ㄖㄨㄢㄇㄨ":"軟木",
"ㄖㄨㄢㄈㄥ":"軟風",
"ㄖㄨㄢㄊㄤ":"軟糖",
"ㄖㄨㄢㄊㄧ":"軟體/軟梯",
"ㄖㄨㄢㄍㄠ":"軟膏",
"ㄖㄨㄢㄍㄤ":"軟鋼",
"ㄖㄨㄢㄍㄨ":"軟骨",
"ㄖㄨㄢㄏㄠ":"軟毫",
"ㄖㄨㄢㄐㄧ":"阮籍",
"ㄖㄨㄢㄒㄧ":"軟席",
"ㄖㄨㄢㄓㄢ":"軟戰",
"ㄖㄨㄢㄕㄢ":"軟善",
"ㄖㄨㄢㄕㄥ":"軟繩",
"ㄖㄨㄢㄕㄨ":"軟熟",
"ㄖㄨㄢㄘㄞ":"軟材",
"ㄖㄨㄢㄩㄢ":"阮元/堧垣",
"ㄖㄨㄣㄅㄧ":"潤筆",
"ㄖㄨㄣㄈㄟ":"潤肺",
"ㄖㄨㄣㄈㄨ":"潤膚",
"ㄖㄨㄣㄋㄧ":"潤膩",
"ㄖㄨㄣㄍㄜ":"潤格",
"ㄖㄨㄣㄏㄡ":"潤喉",
"ㄖㄨㄣㄕㄣ":"潤身",
"ㄖㄨㄣㄗㄜ":"潤澤",
"ㄖㄨㄣㄙㄜ":"潤色",
"ㄖㄨㄣㄧㄣ":"閏音",
"ㄖㄨㄣㄨㄟ":"閏位",
"ㄖㄨㄣㄩㄝ":"閏月",
"ㄖㄨㄥㄅㄧ":"冗筆",
"ㄖㄨㄥㄅㄨ":"絨布",
"ㄖㄨㄥㄇㄚ":"戎馬",
"ㄖㄨㄥㄇㄟ":"溶媒",
"ㄖㄨㄥㄇㄠ":"容貌/絨毛",
"ㄖㄨㄥㄇㄤ":"冗忙",
"ㄖㄨㄥㄇㄨ":"戎幕",
"ㄖㄨㄥㄈㄟ":"冗費",
"ㄖㄨㄥㄈㄢ":"容範/冗繁",
"ㄖㄨㄥㄈㄥ":"融風",
"ㄖㄨㄥㄈㄨ":"戎服/冗賦",
"ㄖㄨㄥㄉㄠ":"容刀",
"ㄖㄨㄥㄉㄥ":"榮登",
"ㄖㄨㄥㄉㄧ":"戎狄",
"ㄖㄨㄥㄊㄞ":"容態",
"ㄖㄨㄥㄊㄢ":"絨毯",
"ㄖㄨㄥㄋㄚ":"容納",
"ㄖㄨㄥㄌㄧ":"頌禮",
"ㄖㄨㄥㄌㄨ":"榮祿/熔爐",
"ㄖㄨㄥㄎㄨ":"榮枯",
"ㄖㄨㄥㄏㄜ":"融合/溶合",
"ㄖㄨㄥㄏㄤ":"戎行",
"ㄖㄨㄥㄐㄧ":"戎機/容積",
"ㄖㄨㄥㄐㄩ":"容車",
"ㄖㄨㄥㄑㄧ":"容器/容乞",
"ㄖㄨㄥㄒㄧ":"容膝/頌繫",
"ㄖㄨㄥㄒㄩ":"容許/容敘",
"ㄖㄨㄥㄓㄢ":"戎旃",
"ㄖㄨㄥㄓㄤ":"冗長",
"ㄖㄨㄥㄓㄨ":"鎔鑄",
"ㄖㄨㄥㄔㄜ":"戎車",
"ㄖㄨㄥㄔㄤ":"冗長",
"ㄖㄨㄥㄔㄥ":"容成/蓉城",
"ㄖㄨㄥㄕㄡ":"容受/戎首",
"ㄖㄨㄥㄕㄣ":"容身/榮身",
"ㄖㄨㄥㄕㄥ":"榮升",
"ㄖㄨㄥㄕㄨ":"榕樹/容恕",
"ㄖㄨㄥㄖㄣ":"容忍/榮任",
"ㄖㄨㄥㄖㄤ":"容讓",
"ㄖㄨㄥㄖㄨ":"榮辱/溶入",
"ㄖㄨㄥㄗㄚ":"冗雜",
"ㄖㄨㄥㄘㄞ":"鎔裁",
"ㄖㄨㄥㄙㄜ":"容色",
"ㄖㄨㄥㄙㄢ":"冗散",
"ㄖㄨㄥㄧㄝ":"溶液/容冶",
"ㄖㄨㄥㄧㄠ":"榮耀",
"ㄖㄨㄥㄧㄢ":"熔岩/容顏",
"ㄖㄨㄥㄧㄣ":"容隱",
"ㄖㄨㄥㄧㄥ":"榮膺",
"ㄖㄨㄥㄨㄣ":"榮問",
"ㄖㄨㄥㄩㄝ":"容悅",
"ㄖㄨㄥㄩㄢ":"冗員/蠑螈",
"ㄖㄩㄝㄊㄢ":"日月潭",
"ㄗㄅㄛㄕㄨ":"自白書",
"ㄗㄅㄟㄍㄢ":"自卑感",
"ㄗㄅㄟㄘㄠ":"紫背草",
"ㄗㄅㄣㄓㄤ":"資本帳",
"ㄗㄅㄣㄘㄞ":"資本財",
"ㄗㄅㄧㄓㄥ":"自閉症",
"ㄗㄇㄛㄌㄧ":"紫茉莉",
"ㄗㄇㄨㄐㄧ":"字幕機",
"ㄗㄇㄨㄧㄣ":"子母印",
"ㄗㄈㄟㄕㄥ":"自費生",
"ㄗㄉㄞㄈㄨ":"子大夫",
"ㄗㄉㄧㄒㄧ":"子弟戲",
"ㄗㄉㄧㄕㄨ":"子弟書",
"ㄗㄉㄨㄑㄩ":"自度曲",
"ㄗㄋㄧㄈㄥ":"紫泥封",
"ㄗㄍㄢㄧㄥ":"自感應",
"ㄗㄍㄨㄥㄕ":"自貢市",
"ㄗㄎㄣㄓㄜ":"茲懇者",
"ㄗㄏㄜㄔㄜ":"紫河車",
"ㄗㄐㄧㄖㄣ":"自己人",
"ㄗㄑㄧㄓㄜ":"茲啟者",
"ㄗㄒㄧㄅㄠ":"子細胞",
"ㄗㄒㄩㄈㄨ":"子虛賦",
"ㄗㄓㄇㄟㄩ":"紫芝眉宇",
"ㄗㄓㄉㄨㄕ":"自治都市",
"ㄗㄓㄌㄧㄥ":"自治領",
"ㄗㄓㄏㄨㄟ":"自治會",
"ㄗㄓㄨㄘㄢ":"自助餐",
"ㄗㄓㄨㄧㄡ":"自主遊",
"ㄗㄔㄞㄐㄧ":"紫釵記",
"ㄗㄔㄢㄍㄨ":"資產股",
"ㄗㄕㄚㄊㄠ":"紫砂陶",
"ㄗㄕㄚㄑㄧ":"紫砂器",
"ㄗㄖㄢㄇㄟ":"自然美",
"ㄗㄖㄢㄊㄧ":"自然堤",
"ㄗㄖㄢㄌㄧ":"自然力",
"ㄗㄖㄢㄌㄩ":"自然率",
"ㄗㄖㄢㄕㄨ":"自然數",
"ㄗㄖㄢㄖㄣ":"自然人",
"ㄗㄖㄢㄧㄣ":"自然銀",
"ㄗㄗㄓㄨㄩ":"字字珠玉",
"ㄗㄗㄜㄈㄣ":"自責分",
"ㄗㄗㄞㄈㄢ":"自在飯",
"ㄗㄙㄗㄌㄧ":"自私自利",
"ㄗㄙㄨㄐㄧ":"恣肆無忌",
"ㄗㄙㄨㄓㄜ":"茲肅者",
"ㄗㄙㄨㄖㄣ":"自訴人",
"ㄗㄚㄆㄧㄥ":"雜評",
"ㄗㄚㄇㄧㄢ":"雜麵",
"ㄗㄚㄉㄨㄟ":"砸兌",
"ㄗㄚㄋㄧㄢ":"雜念",
"ㄗㄚㄌㄧㄡ":"雜流",
"ㄗㄚㄌㄧㄤ":"雜糧",
"ㄗㄚㄌㄨㄢ":"雜亂",
"ㄗㄚㄍㄨㄛ":"砸鍋",
"ㄗㄚㄍㄨㄥ":"雜工",
"ㄗㄚㄎㄨㄞ":"雜膾",
"ㄗㄚㄏㄨㄛ":"雜貨",
"ㄗㄚㄏㄨㄞ":"砸壞",
"ㄗㄚㄏㄨㄟ":"雜燴/砸毀",
"ㄗㄚㄐㄧㄚ":"雜家/咱家",
"ㄗㄚㄐㄧㄠ":"雜交",
"ㄗㄚㄒㄧㄤ":"雜項",
"ㄗㄚㄒㄧㄥ":"雜興",
"ㄗㄚㄒㄩㄝ":"雜學",
"ㄗㄚㄒㄩㄣ":"雜訊",
"ㄗㄚㄓㄨㄥ":"雜種",
"ㄗㄚㄕㄨㄚ":"雜耍",
"ㄗㄚㄕㄨㄛ":"雜說",
"ㄗㄚㄕㄨㄟ":"雜稅",
"ㄗㄚㄗㄨㄟ":"咂嘴",
"ㄗㄚㄘㄨㄛ":"雜錯",
"ㄗㄚㄙㄨㄟ":"雜碎",
"ㄗㄚㄧㄢㄕ":"雜言詩",
"ㄗㄜㄊㄧㄢ":"則天",
"ㄗㄜㄋㄩㄝ":"賊虐",
"ㄗㄜㄌㄧㄢ":"擇戀",
"ㄗㄜㄌㄧㄣ":"擇鄰",
"ㄗㄜㄌㄧㄤ":"澤梁",
"ㄗㄜㄍㄨㄛ":"澤國",
"ㄗㄜㄍㄨㄞ":"責怪",
"ㄗㄜㄍㄨㄟ":"昃晷",
"ㄗㄜㄐㄧㄝ":"責詰",
"ㄗㄜㄐㄧㄠ":"擇交",
"ㄗㄜㄐㄧㄣ":"幘巾",
"ㄗㄜㄒㄧㄝ":"澤瀉",
"ㄗㄜㄒㄧㄥ":"仄行",
"ㄗㄜㄖㄣㄓ":"責任制",
"ㄗㄜㄙㄨㄛ":"則索",
"ㄗㄞㄅㄧㄢ":"災變",
"ㄗㄞㄅㄧㄥ":"災病",
"ㄗㄞㄇㄧㄣ":"災民",
"ㄗㄞㄇㄧㄥ":"載明",
"ㄗㄞㄍㄨㄢ":"在官",
"ㄗㄞㄏㄨㄛ":"災禍/載貨",
"ㄗㄞㄏㄨㄟ":"再會",
"ㄗㄞㄏㄨㄢ":"災患",
"ㄗㄞㄏㄨㄣ":"再婚",
"ㄗㄞㄏㄨㄤ":"災荒",
"ㄗㄞㄐㄧㄚ":"在家/再嫁",
"ㄗㄞㄐㄧㄠ":"再醮/在教",
"ㄗㄞㄐㄧㄡ":"在疚/載酒",
"ㄗㄞㄐㄧㄢ":"再見/載見",
"ㄗㄞㄑㄧㄥ":"災情",
"ㄗㄞㄒㄧㄚ":"在下",
"ㄗㄞㄒㄧㄢ":"在先/再現",
"ㄗㄞㄒㄧㄣ":"在心",
"ㄗㄞㄒㄧㄤ":"宰相/災祥",
"ㄗㄞㄒㄩㄝ":"在學",
"ㄗㄞㄓㄧㄢ":"再製鹽",
"ㄗㄞㄓㄨㄣ":"災迍",
"ㄗㄞㄓㄨㄥ":"栽種/載重",
"ㄗㄞㄕㄋㄩ":"在室女",
"ㄗㄞㄕㄥㄓ":"再生紙",
"ㄗㄞㄕㄨㄛ":"再說",
"ㄗㄞㄖㄨㄥ":"栽絨",
"ㄗㄞㄗㄨㄛ":"在座",
"ㄗㄟㄉㄧㄢ":"賊店",
"ㄗㄟㄍㄨㄟ":"賊鬼",
"ㄗㄟㄒㄧㄣ":"賊心",
"ㄗㄟㄒㄧㄥ":"賊性/賊星",
"ㄗㄟㄔㄨㄢ":"賊船",
"ㄗㄟㄨㄛㄗ":"賊窩子",
"ㄗㄠㄇㄧㄥ":"造命",
"ㄗㄠㄉㄧㄢ":"早點",
"ㄗㄠㄉㄧㄥ":"灶丁",
"ㄗㄠㄉㄨㄢ":"造端",
"ㄗㄠㄉㄨㄥ":"躁動/譟動",
"ㄗㄠㄊㄧㄠ":"躁佻",
"ㄗㄠㄊㄨㄟ":"早退",
"ㄗㄠㄋㄧㄝ":"造孽",
"ㄗㄠㄋㄧㄢ":"早年",
"ㄗㄠㄋㄨㄥ":"簉弄",
"ㄗㄠㄌㄧㄡ":"棗騮",
"ㄗㄠㄌㄧㄣ":"造林",
"ㄗㄠㄌㄨㄢ":"造亂",
"ㄗㄠㄍㄨㄚ":"噪聒",
"ㄗㄠㄎㄨㄞ":"皁快",
"ㄗㄠㄎㄨㄤ":"躁狂",
"ㄗㄠㄏㄨㄚ":"造化/造化",
"ㄗㄠㄏㄨㄛ":"灶火",
"ㄗㄠㄏㄨㄟ":"早慧/藻繪",
"ㄗㄠㄏㄨㄣ":"早婚",
"ㄗㄠㄐㄧㄚ":"造假/造價",
"ㄗㄠㄐㄧㄝ":"遭劫",
"ㄗㄠㄐㄧㄡ":"造就/早就",
"ㄗㄠㄐㄧㄢ":"早尖/蹧踐",
"ㄗㄠㄐㄧㄣ":"躁進",
"ㄗㄠㄐㄧㄥ":"藻鏡/躁競",
"ㄗㄠㄐㄩㄣ":"灶君",
"ㄗㄠㄑㄧㄡ":"糟丘",
"ㄗㄠㄒㄧㄚ":"灶下/早霞",
"ㄗㄠㄒㄧㄝ":"皁鞋",
"ㄗㄠㄒㄧㄢ":"早先",
"ㄗㄠㄒㄧㄣ":"糟心/遭心",
"ㄗㄠㄒㄧㄤ":"造像",
"ㄗㄠㄒㄧㄥ":"造型/造形",
"ㄗㄠㄒㄩㄝ":"澡雪/皁靴",
"ㄗㄠㄓㄨㄛ":"燥灼/藻梲",
"ㄗㄠㄔㄢㄦ":"早產兒",
"ㄗㄠㄔㄨㄢ":"造船",
"ㄗㄠㄔㄨㄣ":"早春",
"ㄗㄠㄔㄨㄤ":"灶床",
"ㄗㄠㄕㄨㄞ":"早衰",
"ㄗㄠㄕㄨㄤ":"早霜",
"ㄗㄠㄗㄨㄛ":"造作",
"ㄗㄠㄗㄨㄟ":"造罪/遭罪",
"ㄗㄠㄧㄖㄣ":"皁衣人",
"ㄗㄠㄧㄝㄗ":"棗椰子",
"ㄗㄠㄨㄓㄜ":"造物者",
"ㄗㄠㄨㄓㄨ":"造物主",
"ㄗㄠㄩㄓㄢ":"遭遇戰",
"ㄗㄠㄩㄓㄥ":"躁鬱症",
"ㄗㄡㄅㄧㄠ":"走鏢",
"ㄗㄡㄅㄧㄢ":"驟變/揍扁",
"ㄗㄡㄆㄧㄠ":"走票",
"ㄗㄡㄇㄣㄗ":"走門子",
"ㄗㄡㄉㄠㄦ":"走道兒",
"ㄗㄡㄉㄧㄝ":"驟跌",
"ㄗㄡㄉㄧㄠ":"走調",
"ㄗㄡㄉㄨㄟ":"奏對",
"ㄗㄡㄉㄨㄥ":"走動",
"ㄗㄡㄊㄧㄠ":"走跳",
"ㄗㄡㄊㄨㄛ":"走脫",
"ㄗㄡㄌㄨㄗ":"走路子",
"ㄗㄡㄌㄨㄛ":"陬落",
"ㄗㄡㄍㄤㄙ":"走鋼絲",
"ㄗㄡㄍㄨㄥ":"奏功",
"ㄗㄡㄎㄨㄥ":"走空",
"ㄗㄡㄏㄨㄚ":"走話",
"ㄗㄡㄏㄨㄛ":"走火",
"ㄗㄡㄏㄨㄥ":"走紅",
"ㄗㄡㄐㄧㄝ":"奏捷",
"ㄗㄡㄐㄧㄣ":"走近/走進",
"ㄗㄡㄐㄧㄤ":"驟降",
"ㄗㄡㄐㄧㄥ":"走莖",
"ㄗㄡㄑㄧㄠ":"走俏",
"ㄗㄡㄑㄧㄣ":"走親/走禽",
"ㄗㄡㄑㄧㄥ":"奏請",
"ㄗㄡㄒㄧㄠ":"奏效",
"ㄗㄡㄒㄧㄤ":"走向",
"ㄗㄡㄒㄩㄝ":"奏削",
"ㄗㄡㄔㄨㄣ":"走春",
"ㄗㄡㄕㄊㄨ":"走勢圖",
"ㄗㄡㄕㄨㄟ":"走水/走水",
"ㄗㄡㄖㄨㄥ":"鄒容",
"ㄗㄡㄗㄆㄞ":"走資派",
"ㄗㄡㄗㄨㄛ":"走作",
"ㄗㄡㄗㄨㄟ":"走嘴",
"ㄗㄡㄗㄨㄥ":"騶從",
"ㄗㄡㄙㄨㄛ":"走索",
"ㄗㄡㄧㄡㄗ":"走油子",
"ㄗㄡㄨㄔㄤ":"走無常",
"ㄗㄢㄇㄟㄕ":"讚美詩",
"ㄗㄢㄊㄨㄥ":"贊同",
"ㄗㄢㄎㄡㄦ":"鏨口兒",
"ㄗㄢㄏㄨㄚ":"簪花",
"ㄗㄢㄐㄧㄣ":"攢勁",
"ㄗㄢㄑㄧㄢ":"儹錢/攢錢",
"ㄗㄢㄒㄧㄥ":"趲行",
"ㄗㄢㄙㄨㄥ":"讚頌",
"ㄗㄤㄌㄨㄢ":"髒亂",
"ㄗㄤㄍㄨㄢ":"贓官",
"ㄗㄤㄎㄨㄞ":"駔儈",
"ㄗㄤㄎㄨㄢ":"贓款",
"ㄗㄤㄏㄨㄚ":"髒話",
"ㄗㄤㄏㄨㄛ":"臧獲",
"ㄗㄤㄐㄧㄥ":"藏經",
"ㄗㄤㄑㄧㄢ":"髒錢",
"ㄗㄤㄑㄧㄥ":"藏青",
"ㄗㄤㄒㄧㄣ":"髒心",
"ㄗㄤㄒㄧㄤ":"藏香",
"ㄗㄤㄙㄨㄥ":"葬送",
"ㄗㄤㄧㄕㄜ":"葬儀社",
"ㄗㄥㄅㄧㄝ":"贈別",
"ㄗㄥㄆㄧㄣ":"贈品/增聘",
"ㄗㄥㄉㄧㄢ":"曾點",
"ㄗㄥㄉㄧㄥ":"增訂",
"ㄗㄥㄉㄨㄛ":"增多",
"ㄗㄥㄊㄧㄢ":"增添",
"ㄗㄥㄌㄧㄝ":"增列",
"ㄗㄥㄌㄧㄥ":"繒綾",
"ㄗㄥㄍㄨㄤ":"增光/增廣",
"ㄗㄥㄍㄨㄥ":"曾鞏",
"ㄗㄥㄐㄧㄚ":"增加/贈嫁",
"ㄗㄥㄐㄧㄢ":"增建/增減",
"ㄗㄥㄐㄧㄣ":"增進",
"ㄗㄥㄐㄧㄤ":"贈獎",
"ㄗㄥㄑㄧㄤ":"增強",
"ㄗㄥㄓㄨㄛ":"矰繳",
"ㄗㄥㄙㄨㄣ":"曾孫/增損",
"ㄗㄥㄙㄨㄥ":"贈送",
"ㄗㄧㄑㄩㄢ":"自益權",
"ㄗㄧㄚㄏㄜ":"子牙河",
"ㄗㄧㄝㄍㄜ":"子夜歌",
"ㄗㄧㄡㄋㄥ":"自由能",
"ㄗㄧㄡㄍㄤ":"自由港",
"ㄗㄧㄡㄔㄜ":"自由車",
"ㄗㄧㄡㄕㄥ":"資優生",
"ㄗㄧㄡㄧㄓ":"自由意志",
"ㄗㄧㄡㄧㄝ":"自由業",
"ㄗㄧㄡㄩㄥ":"自由泳",
"ㄗㄧㄢㄗㄩ":"自言自語",
"ㄗㄧㄧㄑㄧ":"自詒伊戚",
"ㄗㄧㄨㄟㄕ":"自以為是",
"ㄗㄨㄇㄧㄠ":"祖廟",
"ㄗㄨㄉㄧㄢ":"祖奠/租佃",
"ㄗㄨㄉㄨㄢ":"阻斷",
"ㄗㄨㄊㄧㄢ":"族田",
"ㄗㄨㄊㄨㄛ":"捽脫",
"ㄗㄨㄋㄧㄢ":"卒年",
"ㄗㄨㄌㄧㄢ":"子午蓮",
"ㄗㄨㄌㄧㄣ":"租賃",
"ㄗㄨㄌㄨㄥ":"祖龍",
"ㄗㄨㄍㄨㄛ":"祖國",
"ㄗㄨㄍㄨㄢ":"祖貫",
"ㄗㄨㄏㄜㄕ":"組合式",
"ㄗㄨㄏㄨㄚ":"組畫",
"ㄗㄨㄏㄨㄞ":"足踝",
"ㄗㄨㄐㄧㄚ":"租價/組甲",
"ㄗㄨㄐㄧㄝ":"租界/租借",
"ㄗㄨㄐㄧㄢ":"組件/足見",
"ㄗㄨㄐㄧㄣ":"租金/足金",
"ㄗㄨㄐㄩㄝ":"阻絕",
"ㄗㄨㄑㄧㄡ":"足球",
"ㄗㄨㄑㄧㄣ":"族親",
"ㄗㄨㄑㄩㄣ":"族群",
"ㄗㄨㄒㄧㄚ":"足下",
"ㄗㄨㄒㄧㄡ":"阻修",
"ㄗㄨㄒㄧㄢ":"祖先",
"ㄗㄨㄒㄧㄥ":"族姓",
"ㄗㄨㄒㄩㄣ":"組訓/祖訓",
"ㄗㄨㄓㄧㄝ":"組織液",
"ㄗㄨㄓㄨㄤ":"組裝",
"ㄗㄨㄔㄨㄢ":"祖傳",
"ㄗㄨㄕㄥㄔ":"阻生齒",
"ㄗㄨㄕㄧㄝ":"祖師爺",
"ㄗㄨㄕㄨㄟ":"租稅",
"ㄗㄨㄗㄨㄥ":"祖宗",
"ㄗㄨㄙㄨㄟ":"足歲/卒歲",
"ㄗㄨㄙㄨㄣ":"祖孫",
"ㄗㄨㄙㄨㄥ":"祖送",
"ㄗㄨㄛㄅㄚ":"作罷",
"ㄗㄨㄛㄅㄟ":"作盃",
"ㄗㄨㄛㄅㄠ":"做保/作保",
"ㄗㄨㄛㄅㄢ":"作伴/做伴",
"ㄗㄨㄛㄅㄧ":"作弊",
"ㄗㄨㄛㄅㄨ":"坐簿",
"ㄗㄨㄛㄆㄛ":"坐婆/坐坡",
"ㄗㄨㄛㄆㄞ":"左派/做牌",
"ㄗㄨㄛㄆㄟ":"做胚/作陪",
"ㄗㄨㄛㄆㄨ":"作鋪",
"ㄗㄨㄛㄇㄛ":"作摩",
"ㄗㄨㄛㄇㄟ":"作美/作媒",
"ㄗㄨㄛㄇㄥ":"做夢/作夢",
"ㄗㄨㄛㄇㄨ":"作幕",
"ㄗㄨㄛㄈㄚ":"做法/作法",
"ㄗㄨㄛㄈㄟ":"作廢",
"ㄗㄨㄛㄈㄢ":"做飯/作反",
"ㄗㄨㄛㄈㄤ":"左方/作房",
"ㄗㄨㄛㄈㄥ":"作風",
"ㄗㄨㄛㄉㄚ":"做大/作答",
"ㄗㄨㄛㄉㄜ":"做得",
"ㄗㄨㄛㄉㄞ":"作歹",
"ㄗㄨㄛㄉㄠ":"做到/左道",
"ㄗㄨㄛㄉㄧ":"坐地",
"ㄗㄨㄛㄊㄚ":"作塌/作蹋",
"ㄗㄨㄛㄊㄞ":"作態",
"ㄗㄨㄛㄊㄡ":"做頭/坐頭",
"ㄗㄨㄛㄊㄢ":"左袒/座談",
"ㄗㄨㄛㄊㄤ":"坐堂",
"ㄗㄨㄛㄊㄨ":"左徒",
"ㄗㄨㄛㄋㄢ":"作難",
"ㄗㄨㄛㄌㄚ":"坐蠟",
"ㄗㄨㄛㄌㄜ":"作樂",
"ㄗㄨㄛㄌㄞ":"昨來",
"ㄗㄨㄛㄌㄠ":"坐牢",
"ㄗㄨㄛㄌㄧ":"佐理/作禮",
"ㄗㄨㄛㄍㄜ":"做格",
"ㄗㄨㄛㄍㄥ":"作梗/坐更",
"ㄗㄨㄛㄍㄨ":"左顧/作古",
"ㄗㄨㄛㄎㄜ":"作客/做客",
"ㄗㄨㄛㄎㄨ":"作苦",
"ㄗㄨㄛㄏㄠ":"作耗/座號",
"ㄗㄨㄛㄐㄧ":"左計/作計",
"ㄗㄨㄛㄐㄩ":"坐具",
"ㄗㄨㄛㄑㄧ":"做七/左契",
"ㄗㄨㄛㄑㄩ":"作曲",
"ㄗㄨㄛㄒㄧ":"作息/作戲",
"ㄗㄨㄛㄓㄜ":"作者",
"ㄗㄨㄛㄓㄠ":"做朝",
"ㄗㄨㄛㄓㄢ":"作戰",
"ㄗㄨㄛㄓㄣ":"坐鎮/葄枕",
"ㄗㄨㄛㄓㄤ":"作仗/坐帳",
"ㄗㄨㄛㄓㄥ":"作證/佐證",
"ㄗㄨㄛㄓㄨ":"做主/作主",
"ㄗㄨㄛㄔㄜ":"坐車/座車",
"ㄗㄨㄛㄔㄢ":"坐禪",
"ㄗㄨㄛㄔㄤ":"作場",
"ㄗㄨㄛㄔㄥ":"作成/左丞",
"ㄗㄨㄛㄔㄨ":"做處",
"ㄗㄨㄛㄕㄡ":"做手/左手",
"ㄗㄨㄛㄕㄢ":"佐膳",
"ㄗㄨㄛㄕㄣ":"做甚",
"ㄗㄨㄛㄕㄥ":"做生/做聲",
"ㄗㄨㄛㄕㄧ":"左拾遺",
"ㄗㄨㄛㄕㄨ":"左書/作數",
"ㄗㄨㄛㄖㄡ":"胙肉",
"ㄗㄨㄛㄖㄢ":"作染",
"ㄗㄨㄛㄖㄣ":"做人/作人",
"ㄗㄨㄛㄖㄨ":"坐褥/坐蓐",
"ㄗㄨㄛㄘㄜ":"左側",
"ㄗㄨㄛㄘㄞ":"做菜/左猜",
"ㄗㄨㄛㄘㄠ":"坐草/坐曹",
"ㄗㄨㄛㄘㄡ":"左湊",
"ㄗㄨㄛㄘㄢ":"佐餐/柞蠶",
"ㄗㄨㄛㄘㄤ":"座艙",
"ㄗㄨㄛㄙㄜ":"作色",
"ㄗㄨㄛㄙㄨ":"作速",
"ㄗㄨㄛㄧㄝ":"作業/坐夜",
"ㄗㄨㄛㄧㄠ":"坐藥",
"ㄗㄨㄛㄧㄡ":"左右",
"ㄗㄨㄛㄧㄢ":"做眼",
"ㄗㄨㄛㄧㄣ":"坐隱/祚胤",
"ㄗㄨㄛㄧㄥ":"左營",
"ㄗㄨㄛㄨㄞ":"作外",
"ㄗㄨㄛㄨㄟ":"作為/座位",
"ㄗㄨㄛㄨㄢ":"昨晚",
"ㄗㄨㄛㄨㄣ":"作文",
"ㄗㄨㄛㄨㄤ":"坐忘",
"ㄗㄨㄛㄩㄝ":"作樂",
"ㄗㄨㄛㄩㄢ":"作緣",
"ㄗㄨㄛㄩㄥ":"作用/作俑",
"ㄗㄨㄟㄅㄚ":"嘴巴",
"ㄗㄨㄟㄅㄣ":"嘴笨",
"ㄗㄨㄟㄅㄧ":"觜鼻",
"ㄗㄨㄟㄆㄢ":"晬盤",
"ㄗㄨㄟㄇㄛ":"醉墨",
"ㄗㄨㄟㄈㄢ":"罪犯",
"ㄗㄨㄟㄊㄞ":"醉態",
"ㄗㄨㄟㄊㄡ":"嘴頭",
"ㄗㄨㄟㄋㄚ":"嘴吶",
"ㄗㄨㄟㄌㄧ":"罪隸/罪戾",
"ㄗㄨㄟㄏㄠ":"最好",
"ㄗㄨㄟㄏㄡ":"最後",
"ㄗㄨㄟㄏㄢ":"醉漢",
"ㄗㄨㄟㄐㄧ":"罪己/嘴急",
"ㄗㄨㄟㄓㄤ":"罪障",
"ㄗㄨㄟㄓㄥ":"罪證",
"ㄗㄨㄟㄔㄕ":"嘴吃屎",
"ㄗㄨㄟㄔㄢ":"嘴饞",
"ㄗㄨㄟㄔㄤ":"嘴敞",
"ㄗㄨㄟㄔㄨ":"最初",
"ㄗㄨㄟㄕㄜ":"嘴舌",
"ㄗㄨㄟㄕㄠ":"最少",
"ㄗㄨㄟㄕㄥ":"醉聖",
"ㄗㄨㄟㄖㄣ":"罪人",
"ㄗㄨㄟㄗㄜ":"罪責",
"ㄗㄨㄟㄧㄝ":"罪業",
"ㄗㄨㄟㄧㄢ":"醉眼/嘴嚴",
"ㄗㄨㄟㄧㄣ":"罪因",
"ㄗㄨㄟㄧㄥ":"嘴硬",
"ㄗㄨㄟㄨㄛ":"醉臥",
"ㄗㄨㄟㄨㄟ":"醉尉",
"ㄗㄨㄟㄨㄣ":"嘴穩",
"ㄗㄨㄟㄨㄥ":"醉翁",
"ㄗㄨㄟㄨㄨ":"醉兀兀",
"ㄗㄨㄢㄇㄡ":"鑽謀",
"ㄗㄨㄢㄊㄡ":"鑽頭",
"ㄗㄨㄢㄊㄢ":"鑽探",
"ㄗㄨㄢㄌㄡ":"賺漏",
"ㄗㄨㄢㄐㄧ":"鑽機/鑽疾",
"ㄗㄨㄢㄧㄢ":"鑽研",
"ㄗㄨㄢㄧㄤ":"鑽仰",
"ㄗㄨㄢㄧㄥ":"鑽營",
"ㄗㄨㄣㄅㄟ":"尊卑",
"ㄗㄨㄣㄅㄢ":"遵辦",
"ㄗㄨㄣㄇㄣ":"尊門",
"ㄗㄨㄣㄈㄥ":"遵奉",
"ㄗㄨㄣㄈㄨ":"尊府/尊甫",
"ㄗㄨㄣㄊㄚ":"噂沓",
"ㄗㄨㄣㄊㄤ":"尊堂",
"ㄗㄨㄣㄌㄠ":"尊老",
"ㄗㄨㄣㄍㄡ":"圳溝",
"ㄗㄨㄣㄏㄠ":"尊號",
"ㄗㄨㄣㄐㄧ":"尊紀",
"ㄗㄨㄣㄑㄩ":"撙詘",
"ㄗㄨㄣㄓㄜ":"尊者",
"ㄗㄨㄣㄓㄠ":"遵照",
"ㄗㄨㄣㄓㄤ":"尊長/尊嫜",
"ㄗㄨㄣㄔㄡ":"遵紬",
"ㄗㄨㄣㄔㄥ":"尊稱",
"ㄗㄨㄣㄕㄡ":"遵守",
"ㄗㄨㄣㄕㄤ":"尊上",
"ㄗㄨㄣㄕㄥ":"撙省",
"ㄗㄨㄣㄖㄣ":"尊人",
"ㄗㄨㄣㄗㄢ":"噂噆",
"ㄗㄨㄣㄗㄨ":"尊俎/樽俎",
"ㄗㄨㄣㄙㄨ":"尊宿",
"ㄗㄨㄣㄧㄢ":"尊嚴/尊顏",
"ㄗㄨㄣㄨㄥ":"尊翁",
"ㄗㄨㄥㄅㄛ":"從伯/縱波",
"ㄗㄨㄥㄅㄠ":"總苞",
"ㄗㄨㄥㄅㄢ":"總辦",
"ㄗㄨㄥㄅㄥ":"宗祊",
"ㄗㄨㄥㄅㄨ":"總部/縱步",
"ㄗㄨㄥㄆㄞ":"宗派",
"ㄗㄨㄥㄆㄨ":"總鋪/總譜",
"ㄗㄨㄥㄇㄚ":"縱馬",
"ㄗㄨㄥㄇㄠ":"棕毛",
"ㄗㄨㄥㄇㄣ":"宗門",
"ㄗㄨㄥㄇㄧ":"宗密",
"ㄗㄨㄥㄇㄨ":"總目/從母",
"ㄗㄨㄥㄈㄚ":"從法/宗法",
"ㄗㄨㄥㄈㄢ":"從犯/宗藩",
"ㄗㄨㄥㄈㄤ":"縱放",
"ㄗㄨㄥㄈㄥ":"宗風",
"ㄗㄨㄥㄈㄨ":"從父/宗婦",
"ㄗㄨㄥㄉㄟ":"總得",
"ㄗㄨㄥㄉㄢ":"縱誕",
"ㄗㄨㄥㄉㄧ":"從弟/宗弟",
"ㄗㄨㄥㄉㄨ":"總督",
"ㄗㄨㄥㄊㄢ":"縱談",
"ㄗㄨㄥㄊㄧ":"總體/縱體",
"ㄗㄨㄥㄋㄩ":"從女/宗女",
"ㄗㄨㄥㄌㄠ":"宗老",
"ㄗㄨㄥㄌㄢ":"總攬/綜攬",
"ㄗㄨㄥㄌㄤ":"縱浪",
"ㄗㄨㄥㄌㄧ":"總理/綜理",
"ㄗㄨㄥㄌㄩ":"棕櫚",
"ㄗㄨㄥㄍㄤ":"總綱",
"ㄗㄨㄥㄍㄨ":"縱谷",
"ㄗㄨㄥㄏㄜ":"綜合/總和",
"ㄗㄨㄥㄏㄤ":"總行",
"ㄗㄨㄥㄏㄥ":"縱橫/從衡",
"ㄗㄨㄥㄐㄧ":"蹤跡/總計",
"ㄗㄨㄥㄑㄧ":"宗器",
"ㄗㄨㄥㄒㄩ":"蹤緒",
"ㄗㄨㄥㄓㄜ":"從者",
"ㄗㄨㄥㄓㄡ":"宗周/縱軸",
"ㄗㄨㄥㄓㄤ":"總章/總帳",
"ㄗㄨㄥㄓㄥ":"宗正",
"ㄗㄨㄥㄓㄨ":"宗主",
"ㄗㄨㄥㄔㄣ":"宗臣",
"ㄗㄨㄥㄔㄤ":"總廠",
"ㄗㄨㄥㄔㄥ":"總成/總稱",
"ㄗㄨㄥㄔㄨ":"縱出",
"ㄗㄨㄥㄕㄜ":"宗社",
"ㄗㄨㄥㄕㄣ":"縱身/縱深",
"ㄗㄨㄥㄕㄥ":"宗聖",
"ㄗㄨㄥㄕㄨ":"總數/綜述",
"ㄗㄨㄥㄖㄢ":"縱然/總然",
"ㄗㄨㄥㄖㄣ":"宗人",
"ㄗㄨㄥㄗㄜ":"總則/宗澤",
"ㄗㄨㄥㄗㄡ":"縱走",
"ㄗㄨㄥㄗㄨ":"宗族",
"ㄗㄨㄥㄘㄞ":"總裁",
"ㄗㄨㄥㄙㄜ":"棕色",
"ㄗㄨㄥㄧㄝ":"總爺",
"ㄗㄨㄥㄧㄠ":"總要",
"ㄗㄨㄥㄧㄡ":"蹤由",
"ㄗㄨㄥㄧㄢ":"縱言",
"ㄗㄨㄥㄧㄤ":"宗仰",
"ㄗㄨㄥㄧㄥ":"蹤影/宗英",
"ㄗㄨㄥㄩㄝ":"從約",
"ㄗㄨㄥㄩㄥ":"縱臾",
"ㄗㄩㄝㄏㄤ":"子曰行",
"ㄗㄩㄢㄗㄧ":"自怨自艾",
"ㄗㄩㄣㄧㄥ":"紫雲英",
"ㄗㄩㄥㄔㄜ":"自用車",
"ㄗㄩㄩㄖㄣ":"自娛娛人",
"ㄘㄅㄟㄐㄧ":"磁北極",
"ㄘㄈㄢㄨㄢ":"瓷飯碗",
"ㄘㄉㄚㄌㄨ":"次大陸",
"ㄘㄉㄞㄐㄧ":"磁帶機",
"ㄘㄋㄢㄐㄧ":"磁南極",
"ㄘㄌㄠㄏㄨ":"雌老虎",
"ㄘㄍㄢㄧㄥ":"磁感應",
"ㄘㄐㄧㄙㄨ":"刺激素",
"ㄘㄓㄡㄧㄠ":"磁州窯",
"ㄘㄔㄥㄏㄜ":"次成河",
"ㄘㄕㄊㄧㄢ":"刺史天",
"ㄘㄕㄘㄎㄜ":"此時此刻",
"ㄘㄙㄅㄧㄢ":"刺絲鞭",
"ㄘㄚㄌㄧㄢ":"擦臉",
"ㄘㄚㄍㄨㄚ":"擦刮",
"ㄘㄚㄏㄟㄦ":"擦黑兒",
"ㄘㄚㄐㄧㄢ":"擦肩",
"ㄘㄚㄓㄨㄤ":"擦撞",
"ㄘㄚㄗㄨㄛ":"擦坐",
"ㄘㄜㄆㄧㄥ":"測評",
"ㄘㄜㄇㄧㄢ":"側面",
"ㄘㄜㄇㄧㄥ":"策名/策命",
"ㄘㄜㄉㄧㄥ":"測定",
"ㄘㄜㄉㄨㄛ":"測度",
"ㄘㄜㄉㄨㄥ":"策動",
"ㄘㄜㄊㄧㄥ":"側聽",
"ㄘㄜㄌㄧㄓ":"側理紙",
"ㄘㄜㄌㄧㄤ":"測量",
"ㄘㄜㄌㄨㄣ":"策論",
"ㄘㄜㄌㄩㄝ":"策略",
"ㄘㄜㄏㄨㄚ":"策劃/策畫",
"ㄘㄜㄏㄨㄟ":"測繪",
"ㄘㄜㄐㄧㄢ":"策蹇",
"ㄘㄜㄐㄧㄣ":"策進",
"ㄘㄜㄑㄧㄢ":"測鉛",
"ㄘㄜㄒㄧㄢ":"側線",
"ㄘㄜㄒㄩㄣ":"策勛",
"ㄘㄜㄓㄨㄥ":"側重",
"ㄘㄜㄔㄨㄤ":"惻愴",
"ㄘㄜㄕㄊㄨ":"側視圖",
"ㄘㄜㄕㄌㄧ":"側蝕力",
"ㄘㄜㄙㄨㄛ":"廁所",
"ㄘㄞㄅㄠㄗ":"菜包子",
"ㄘㄞㄅㄧㄥ":"裁兵/裁併",
"ㄘㄞㄆㄧㄠ":"彩票",
"ㄘㄞㄆㄧㄣ":"采蘋",
"ㄘㄞㄇㄧㄥ":"才名",
"ㄘㄞㄈㄤㄕ":"採訪使",
"ㄘㄞㄉㄞㄨ":"彩帶舞",
"ㄘㄞㄉㄧㄠ":"才調/彩調",
"ㄘㄞㄉㄧㄢ":"彩電",
"ㄘㄞㄉㄧㄥ":"裁定",
"ㄘㄞㄉㄨㄛ":"猜度/裁度",
"ㄘㄞㄉㄨㄢ":"裁斷/綵緞",
"ㄘㄞㄉㄨㄥ":"財東",
"ㄘㄞㄊㄨㄢ":"財團",
"ㄘㄞㄋㄧㄠ":"菜鳥",
"ㄘㄞㄋㄧㄡ":"菜牛",
"ㄘㄞㄋㄨㄥ":"菜農",
"ㄘㄞㄌㄧㄝ":"採獵",
"ㄘㄞㄌㄧㄠ":"材料/才料",
"ㄘㄞㄌㄧㄢ":"彩練",
"ㄘㄞㄌㄧㄤ":"裁量",
"ㄘㄞㄌㄧㄥ":"采苓",
"ㄘㄞㄌㄨㄢ":"綵鸞",
"ㄘㄞㄌㄨㄣ":"蔡倫",
"ㄘㄞㄌㄩㄝ":"才略",
"ㄘㄞㄍㄜㄗ":"菜鴿子",
"ㄘㄞㄍㄨㄚ":"菜瓜",
"ㄘㄞㄍㄨㄞ":"才怪",
"ㄘㄞㄍㄨㄢ":"菜館/材官",
"ㄘㄞㄍㄨㄤ":"採光",
"ㄘㄞㄎㄜㄗ":"菜殼子",
"ㄘㄞㄎㄨㄛ":"彩擴",
"ㄘㄞㄎㄨㄤ":"採礦",
"ㄘㄞㄏㄜㄗ":"菜盒子",
"ㄘㄞㄏㄡㄓ":"蔡侯紙",
"ㄘㄞㄏㄨㄗ":"菜虎子",
"ㄘㄞㄏㄨㄚ":"才華/菜花",
"ㄘㄞㄏㄨㄛ":"財貨/菜貨",
"ㄘㄞㄏㄨㄟ":"彩繪/財賄",
"ㄘㄞㄏㄨㄥ":"彩虹",
"ㄘㄞㄐㄧㄝ":"採擷",
"ㄘㄞㄐㄧㄠ":"彩轎",
"ㄘㄞㄐㄧㄢ":"裁減/裁剪",
"ㄘㄞㄐㄧㄣ":"彩金/裁錦",
"ㄘㄞㄐㄧㄥ":"財經/蔡京",
"ㄘㄞㄐㄩㄝ":"裁決/採掘",
"ㄘㄞㄐㄩㄣ":"才俊/裁軍",
"ㄘㄞㄑㄧㄠ":"跴蹻",
"ㄘㄞㄑㄧㄡ":"彩球/綵球",
"ㄘㄞㄑㄧㄢ":"彩錢",
"ㄘㄞㄑㄧㄣ":"采芹",
"ㄘㄞㄑㄧㄥ":"才情/菜青",
"ㄘㄞㄑㄩㄢ":"財權/彩券",
"ㄘㄞㄒㄧㄚ":"彩霞",
"ㄘㄞㄒㄧㄢ":"猜嫌",
"ㄘㄞㄒㄧㄣ":"菜心/採信",
"ㄘㄞㄒㄧㄤ":"猜想/財鄉",
"ㄘㄞㄒㄧㄥ":"採行",
"ㄘㄞㄒㄩㄝ":"才學",
"ㄘㄞㄒㄩㄢ":"採選",
"ㄘㄞㄓㄨㄢ":"彩磚/菜饌",
"ㄘㄞㄓㄨㄥ":"採種",
"ㄘㄞㄔㄨㄥ":"菜蟲",
"ㄘㄞㄕㄎㄡ":"菜市口",
"ㄘㄞㄕㄐㄧ":"采石磯",
"ㄘㄞㄕㄓㄧ":"采石之役",
"ㄘㄞㄕㄔㄤ":"菜市場",
"ㄘㄞㄕㄨㄟ":"踩水",
"ㄘㄞㄗㄕㄨ":"才子書",
"ㄘㄞㄙㄨㄣ":"裁損",
"ㄘㄞㄧㄅㄢ":"才藝班",
"ㄘㄞㄧㄝㄩ":"彩葉芋",
"ㄘㄞㄨㄛㄗ":"踩窩子",
"ㄘㄠㄅㄧㄢ":"草編",
"ㄘㄠㄅㄧㄥ":"操兵",
"ㄘㄠㄆㄧㄥ":"草坪",
"ㄘㄠㄇㄧㄢ":"草棉",
"ㄘㄠㄇㄧㄣ":"草民",
"ㄘㄠㄉㄧㄢ":"操典",
"ㄘㄠㄉㄨㄟ":"草堆/槽碓",
"ㄘㄠㄌㄧㄠ":"草料/草寮",
"ㄘㄠㄌㄧㄢ":"操練",
"ㄘㄠㄌㄧㄤ":"糙糧/漕糧",
"ㄘㄠㄌㄨㄢ":"嘈亂",
"ㄘㄠㄌㄩㄝ":"草略",
"ㄘㄠㄍㄨㄚ":"嘈聒",
"ㄘㄠㄎㄨㄣ":"曹錕",
"ㄘㄠㄎㄨㄥ":"操控",
"ㄘㄠㄏㄨㄚ":"槽化",
"ㄘㄠㄏㄨㄟ":"草灰",
"ㄘㄠㄏㄨㄤ":"草荒",
"ㄘㄠㄐㄧㄝ":"草芥",
"ㄘㄠㄐㄧㄢ":"草荐",
"ㄘㄠㄑㄧㄝ":"操切/草竊",
"ㄘㄠㄑㄧㄡ":"曹丘",
"ㄘㄠㄑㄧㄢ":"草簽",
"ㄘㄠㄑㄧㄣ":"操琴",
"ㄘㄠㄑㄧㄤ":"操槍",
"ㄘㄠㄑㄩㄢ":"操券",
"ㄘㄠㄒㄧㄚ":"草蝦",
"ㄘㄠㄒㄧㄝ":"草寫/草鞋",
"ㄘㄠㄒㄧㄣ":"操心",
"ㄘㄠㄒㄧㄥ":"操行",
"ㄘㄠㄓㄓㄨ":"草蜘蛛",
"ㄘㄠㄓㄡㄦ":"草帚兒",
"ㄘㄠㄓㄨㄢ":"漕轉/草篆",
"ㄘㄠㄓㄨㄥ":"草螽",
"ㄘㄠㄔㄨㄢ":"草船",
"ㄘㄠㄔㄨㄤ":"草創",
"ㄘㄠㄔㄨㄥ":"草蟲",
"ㄘㄠㄕㄘㄢ":"草石蠶",
"ㄘㄠㄕㄨㄞ":"草率",
"ㄘㄠㄗㄜㄧ":"草澤醫",
"ㄘㄠㄗㄨㄛ":"操作",
"ㄘㄠㄗㄨㄥ":"操縱",
"ㄘㄠㄘㄨㄥ":"草叢",
"ㄘㄠㄙㄨㄢ":"草酸",
"ㄘㄠㄜㄅㄟ":"曹娥碑",
"ㄘㄡㄈㄣㄗ":"湊分子",
"ㄘㄡㄉㄢㄗ":"湊膽子",
"ㄘㄡㄌㄨㄥ":"湊攏",
"ㄘㄡㄐㄧㄣ":"湊近",
"ㄘㄡㄑㄧㄠ":"湊巧",
"ㄘㄡㄑㄧㄢ":"湊錢",
"ㄘㄢㄅㄧㄢ":"慘變/殘編",
"ㄘㄢㄆㄧㄣ":"殘品",
"ㄘㄢㄇㄧㄢ":"蠶眠",
"ㄘㄢㄉㄧㄢ":"餐點",
"ㄘㄢㄉㄧㄥ":"參訂",
"ㄘㄢㄉㄨㄛ":"參度",
"ㄘㄢㄉㄨㄥ":"殘冬",
"ㄘㄢㄊㄧㄢ":"參天",
"ㄘㄢㄊㄧㄥ":"餐廳",
"ㄘㄢㄊㄨㄥ":"慘痛",
"ㄘㄢㄋㄧㄢ":"殘年",
"ㄘㄢㄋㄩㄝ":"殘虐",
"ㄘㄢㄌㄧㄝ":"慘烈",
"ㄘㄢㄌㄧㄡ":"殘留",
"ㄘㄢㄌㄧㄢ":"蠶連",
"ㄘㄢㄌㄧㄣ":"憯懍",
"ㄘㄢㄌㄧㄥ":"參靈",
"ㄘㄢㄍㄨㄢ":"參觀/餐館",
"ㄘㄢㄍㄨㄥ":"蠶工",
"ㄘㄢㄎㄨㄟ":"慚愧",
"ㄘㄢㄏㄨㄚ":"蠶花",
"ㄘㄢㄏㄨㄛ":"慘禍",
"ㄘㄢㄏㄨㄟ":"餐會",
"ㄘㄢㄏㄨㄤ":"慚惶",
"ㄘㄢㄏㄨㄥ":"殘紅",
"ㄘㄢㄐㄧㄚ":"參加/參假",
"ㄘㄢㄐㄧㄠ":"慘叫/參校",
"ㄘㄢㄐㄧㄢ":"參見/蠶繭",
"ㄘㄢㄐㄧㄣ":"餐巾/蠶禁",
"ㄘㄢㄐㄧㄤ":"參將",
"ㄘㄢㄐㄧㄥ":"慘境",
"ㄘㄢㄐㄩㄣ":"參軍",
"ㄘㄢㄑㄧㄝ":"慘切",
"ㄘㄢㄑㄧㄡ":"殘秋",
"ㄘㄢㄑㄧㄥ":"參請",
"ㄘㄢㄑㄩㄝ":"殘缺",
"ㄘㄢㄒㄧㄚ":"餐霞/殘夏",
"ㄘㄢㄒㄧㄠ":"慘笑",
"ㄘㄢㄒㄩㄢ":"參選",
"ㄘㄢㄓㄨㄛ":"餐桌/參酌",
"ㄘㄢㄓㄨㄤ":"慘狀",
"ㄘㄢㄓㄨㄥ":"慘重",
"ㄘㄢㄔㄨㄢ":"殘喘",
"ㄘㄢㄔㄨㄣ":"殘春",
"ㄘㄢㄔㄨㄤ":"慘愴",
"ㄘㄢㄗㄨㄛ":"參佐/慚怍",
"ㄘㄢㄗㄨㄥ":"參綜",
"ㄘㄢㄘㄨㄣ":"殘存",
"ㄘㄢㄘㄨㄥ":"蠶叢",
"ㄘㄢㄙㄨㄣ":"參孫",
"ㄘㄢㄙㄨㄥ":"慚悚",
"ㄘㄢㄧㄩㄢ":"參議院",
"ㄘㄣㄌㄧㄥ":"岑嶺",
"ㄘㄣㄗㄨㄥ":"慈恩宗",
"ㄘㄣㄘㄨㄛ":"參錯/嵾嵯",
"ㄘㄣㄩㄧㄥ":"岑毓英",
"ㄘㄤㄇㄠㄦ":"藏貓兒",
"ㄘㄤㄇㄧㄥ":"滄溟",
"ㄘㄤㄉㄨㄛ":"藏躲",
"ㄘㄤㄊㄧㄢ":"蒼天",
"ㄘㄤㄋㄧㄠ":"蒼鳥",
"ㄘㄤㄋㄧㄥ":"傖儜",
"ㄘㄤㄌㄧㄣ":"倉廩",
"ㄘㄤㄌㄧㄤ":"蒼涼",
"ㄘㄤㄌㄨㄥ":"蒼龍",
"ㄘㄤㄍㄨㄚ":"鶬鴰",
"ㄘㄤㄍㄨㄥ":"倉公",
"ㄘㄤㄏㄨㄛ":"藏活",
"ㄘㄤㄏㄨㄤ":"蒼黃/倉皇",
"ㄘㄤㄐㄧㄝ":"倉頡",
"ㄘㄤㄐㄧㄠ":"藏嬌",
"ㄘㄤㄐㄧㄡ":"藏鬮",
"ㄘㄤㄐㄧㄢ":"藏奸",
"ㄘㄤㄐㄧㄤ":"滄江",
"ㄘㄤㄐㄧㄥ":"蒼勁",
"ㄘㄤㄑㄩㄥ":"蒼穹",
"ㄘㄤㄒㄧㄥ":"藏形/藏幸",
"ㄘㄤㄓㄨㄛ":"藏拙",
"ㄘㄤㄖㄨㄥ":"倉容",
"ㄘㄤㄘㄨㄟ":"蒼翠",
"ㄘㄤㄧㄥㄓ":"蒼蠅紙",
"ㄘㄥㄇㄧㄢ":"層面",
"ㄘㄥㄌㄥㄗ":"蹭稜子",
"ㄘㄥㄌㄧㄡ":"層流",
"ㄘㄥㄐㄧㄥ":"曾經",
"ㄘㄥㄦㄒㄧ":"蹭兒戲",
"ㄘㄧㄒㄩㄝ":"詞義學",
"ㄘㄨㄅㄧㄥ":"促病",
"ㄘㄨㄆㄧㄣ":"粗品",
"ㄘㄨㄊㄢㄗ":"醋罈子",
"ㄘㄨㄊㄨㄥ":"粗通",
"ㄘㄨㄌㄧㄝ":"粗劣",
"ㄘㄨㄌㄧㄠ":"粗料",
"ㄘㄨㄌㄧㄤ":"粗糧",
"ㄘㄨㄌㄨㄛ":"殂落",
"ㄘㄨㄌㄩㄝ":"粗略",
"ㄘㄨㄍㄨㄤ":"粗獷",
"ㄘㄨㄍㄨㄥ":"粗工",
"ㄘㄨㄏㄨㄚ":"粗話/粗畫",
"ㄘㄨㄏㄨㄛ":"粗活",
"ㄘㄨㄐㄧㄝ":"猝嗟",
"ㄘㄨㄐㄧㄢ":"粗賤",
"ㄘㄨㄐㄧㄣ":"促進/蹙金",
"ㄘㄨㄑㄧㄚ":"促掐",
"ㄘㄨㄑㄧㄢ":"粗淺",
"ㄘㄨㄑㄧㄥ":"促請",
"ㄘㄨㄒㄧㄚ":"促狹",
"ㄘㄨㄒㄧㄝ":"徂謝/殂謝",
"ㄘㄨㄒㄧㄠ":"促銷",
"ㄘㄨㄒㄧㄣ":"粗心/簇新",
"ㄘㄨㄓㄧㄝ":"詞無枝葉",
"ㄘㄨㄓㄨㄤ":"粗壯/促裝",
"ㄘㄨㄓㄨㄥ":"粗重/卒中",
"ㄘㄨㄕㄨㄞ":"粗率",
"ㄘㄨㄗㄨㄛ":"促坐",
"ㄘㄨㄙㄨㄟ":"徂歲",
"ㄘㄨㄙㄨㄢ":"醋酸",
"ㄘㄨㄙㄨㄥ":"蹙竦",
"ㄘㄨㄛㄅㄞ":"挫敗",
"ㄘㄨㄛㄅㄢ":"措辦/搓板",
"ㄘㄨㄛㄅㄧ":"錯臂",
"ㄘㄨㄛㄅㄨ":"撮哺",
"ㄘㄨㄛㄇㄛ":"錯莫/磋磨",
"ㄘㄨㄛㄈㄟ":"錯非",
"ㄘㄨㄛㄈㄥ":"撮風",
"ㄘㄨㄛㄉㄚ":"措大",
"ㄘㄨㄛㄉㄠ":"錯刀/矬倒",
"ㄘㄨㄛㄊㄨ":"撮土",
"ㄘㄨㄛㄌㄡ":"錯漏",
"ㄘㄨㄛㄎㄢ":"錯勘",
"ㄘㄨㄛㄏㄜ":"撮合",
"ㄘㄨㄛㄐㄧ":"厝基",
"ㄘㄨㄛㄒㄧ":"矬西",
"ㄘㄨㄛㄓㄜ":"挫折/剉折",
"ㄘㄨㄛㄔㄜ":"錯車",
"ㄘㄨㄛㄔㄨ":"錯處",
"ㄘㄨㄛㄕㄡ":"搓手/措手",
"ㄘㄨㄛㄕㄣ":"厝身/矬身",
"ㄘㄨㄛㄕㄤ":"磋商/挫傷",
"ㄘㄨㄛㄖㄡ":"搓揉",
"ㄘㄨㄛㄖㄨ":"挫辱",
"ㄘㄨㄛㄗㄚ":"錯雜",
"ㄘㄨㄛㄗㄠ":"搓澡",
"ㄘㄨㄛㄘㄠ":"莝草",
"ㄘㄨㄛㄧㄠ":"撮要/撮藥",
"ㄘㄨㄛㄧㄢ":"錯眼",
"ㄘㄨㄟㄅㄛ":"脆薄/悴薄",
"ㄘㄨㄟㄅㄞ":"粹白/崔白",
"ㄘㄨㄟㄅㄢ":"催辦",
"ㄘㄨㄟㄅㄧ":"催逼",
"ㄘㄨㄟㄆㄛ":"催迫",
"ㄘㄨㄟㄆㄧ":"催批",
"ㄘㄨㄟㄇㄛ":"縗墨",
"ㄘㄨㄟㄇㄟ":"翠眉/摧眉",
"ㄘㄨㄟㄇㄠ":"毳毛",
"ㄘㄨㄟㄇㄨ":"毳幕",
"ㄘㄨㄟㄈㄣ":"翠氛",
"ㄘㄨㄟㄈㄨ":"翠阜",
"ㄘㄨㄟㄉㄞ":"翠黛",
"ㄘㄨㄟㄉㄨ":"催督",
"ㄘㄨㄟㄊㄠ":"催討/崔韜",
"ㄘㄨㄟㄊㄧ":"榱題",
"ㄘㄨㄟㄊㄨ":"崔塗",
"ㄘㄨㄟㄋㄞ":"催奶",
"ㄘㄨㄟㄋㄠ":"摧撓",
"ㄘㄨㄟㄌㄡ":"翠樓",
"ㄘㄨㄟㄌㄧ":"淬厲/淬礪",
"ㄘㄨㄟㄌㄩ":"翠綠",
"ㄘㄨㄟㄍㄞ":"翠蓋",
"ㄘㄨㄟㄍㄠ":"催告",
"ㄘㄨㄟㄍㄨ":"脆骨",
"ㄘㄨㄟㄎㄜ":"催科/毳客",
"ㄘㄨㄟㄏㄠ":"崔浩/崔顥",
"ㄘㄨㄟㄐㄩ":"倅車/翠菊",
"ㄘㄨㄟㄑㄧ":"粹器",
"ㄘㄨㄟㄑㄩ":"萃取",
"ㄘㄨㄟㄓㄜ":"摧折",
"ㄘㄨㄟㄓㄤ":"焠掌/毳帳",
"ㄘㄨㄟㄓㄨ":"翠竹/崔杼",
"ㄘㄨㄟㄔㄢ":"催產",
"ㄘㄨㄟㄕㄠ":"摧燒",
"ㄘㄨㄟㄕㄥ":"催生/脆生",
"ㄘㄨㄟㄕㄨ":"催熟/崔述",
"ㄘㄨㄟㄖㄢ":"萃然",
"ㄘㄨㄟㄖㄨ":"摧辱",
"ㄘㄨㄟㄗㄢ":"催趲",
"ㄘㄨㄟㄗㄤ":"脺臟",
"ㄘㄨㄟㄗㄨ":"悴族/催租",
"ㄘㄨㄟㄘㄞ":"綷縩",
"ㄘㄨㄟㄘㄢ":"摧殘/璀璨",
"ㄘㄨㄟㄘㄤ":"摧藏",
"ㄘㄨㄟㄘㄨ":"催促/脆促",
"ㄘㄨㄟㄧㄚ":"催芽",
"ㄘㄨㄟㄧㄝ":"脺液/翠靨",
"ㄘㄨㄟㄧㄢ":"摧顏",
"ㄘㄨㄟㄧㄣ":"啐飲/崔駰",
"ㄘㄨㄟㄨㄛ":"翠渦",
"ㄘㄨㄟㄨㄟ":"翠微/崔嵬",
"ㄘㄨㄟㄩㄢ":"翠苑/崔瑗",
"ㄘㄨㄢㄅㄧ":"爨婢",
"ㄘㄨㄢㄆㄢ":"攢盤",
"ㄘㄨㄢㄇㄟ":"攢眉",
"ㄘㄨㄢㄈㄢ":"竄犯",
"ㄘㄨㄢㄈㄨ":"竄伏/爨婦",
"ㄘㄨㄢㄉㄠ":"攛道",
"ㄘㄨㄢㄉㄧ":"攢底",
"ㄘㄨㄢㄊㄠ":"竄逃",
"ㄘㄨㄢㄊㄤ":"攢湯/汆湯",
"ㄘㄨㄢㄊㄧ":"攢蹄",
"ㄘㄨㄢㄋㄧ":"篡逆/竄匿",
"ㄘㄨㄢㄌㄡ":"攢露",
"ㄘㄨㄢㄍㄞ":"竄改/篡改",
"ㄘㄨㄢㄏㄜ":"攢盒",
"ㄘㄨㄢㄐㄩ":"竄句/攢聚",
"ㄘㄨㄢㄑㄧ":"竄起",
"ㄘㄨㄢㄒㄧ":"躥稀",
"ㄘㄨㄢㄓㄨ":"竄逐",
"ㄘㄨㄢㄔㄚ":"攢茶",
"ㄘㄨㄢㄕㄜ":"攢射",
"ㄘㄨㄢㄕㄣ":"竄身",
"ㄘㄨㄢㄕㄥ":"竄升",
"ㄘㄨㄢㄖㄠ":"竄擾",
"ㄘㄨㄢㄘㄡ":"攢湊",
"ㄘㄨㄢㄘㄨ":"攢簇/攢蹙",
"ㄘㄨㄢㄧㄡ":"竄遊",
"ㄘㄨㄢㄨㄟ":"篡位",
"ㄘㄨㄣㄅㄧ":"寸碧",
"ㄘㄨㄣㄅㄨ":"寸步",
"ㄘㄨㄣㄆㄛ":"村潑",
"ㄘㄨㄣㄇㄛ":"存沒",
"ㄘㄨㄣㄈㄚ":"皴法",
"ㄘㄨㄣㄈㄟ":"存廢",
"ㄘㄨㄣㄈㄤ":"存放",
"ㄘㄨㄣㄈㄨ":"存撫/村夫",
"ㄘㄨㄣㄉㄢ":"存單",
"ㄘㄨㄣㄉㄤ":"存檔",
"ㄘㄨㄣㄉㄧ":"存底",
"ㄘㄨㄣㄊㄨ":"寸土",
"ㄘㄨㄣㄌㄠ":"存勞",
"ㄘㄨㄣㄌㄢ":"存欄",
"ㄘㄨㄣㄌㄤ":"村郎",
"ㄘㄨㄣㄌㄧ":"村里",
"ㄘㄨㄣㄌㄨ":"存錄/寸祿",
"ㄘㄨㄣㄌㄩ":"寸縷",
"ㄘㄨㄣㄍㄜ":"村歌",
"ㄘㄨㄣㄍㄠ":"存稿",
"ㄘㄨㄣㄍㄣ":"存根",
"ㄘㄨㄣㄍㄨ":"存孤/村姑",
"ㄘㄨㄣㄎㄡ":"寸口",
"ㄘㄨㄣㄏㄡ":"存候",
"ㄘㄨㄣㄏㄨ":"存戶",
"ㄘㄨㄣㄐㄧ":"存記/存濟",
"ㄘㄨㄣㄑㄧ":"村氣",
"ㄘㄨㄣㄒㄧ":"寸隙",
"ㄘㄨㄣㄒㄩ":"存續/存恤",
"ㄘㄨㄣㄓㄚ":"寸札",
"ㄘㄨㄣㄓㄜ":"存摺",
"ㄘㄨㄣㄓㄠ":"存照",
"ㄘㄨㄣㄓㄡ":"村紂",
"ㄘㄨㄣㄓㄣ":"村鎮",
"ㄘㄨㄣㄓㄤ":"村長",
"ㄘㄨㄣㄔㄚ":"存查",
"ㄘㄨㄣㄔㄤ":"寸長/寸腸",
"ㄘㄨㄣㄔㄨ":"寸楮",
"ㄘㄨㄣㄕㄚ":"村沙",
"ㄘㄨㄣㄕㄣ":"存身/存神",
"ㄘㄨㄣㄕㄨ":"村書/村塾",
"ㄘㄨㄣㄖㄣ":"村人",
"ㄘㄨㄣㄗㄞ":"存在",
"ㄘㄨㄣㄘㄜ":"忖測",
"ㄘㄨㄣㄘㄠ":"寸草",
"ㄘㄨㄣㄙㄨ":"存宿/村俗",
"ㄘㄨㄣㄧㄝ":"村野",
"ㄘㄨㄣㄧㄣ":"寸陰",
"ㄘㄨㄣㄧㄤ":"存養",
"ㄘㄨㄣㄨㄟ":"存慰",
"ㄘㄨㄣㄨㄣ":"存問",
"ㄘㄨㄣㄨㄤ":"存亡",
"ㄘㄨㄥㄅㄛ":"叢薄",
"ㄘㄨㄥㄅㄞ":"蔥白",
"ㄘㄨㄥㄅㄨ":"賨布",
"ㄘㄨㄥㄇㄚ":"驄馬",
"ㄘㄨㄥㄇㄤ":"匆忙",
"ㄘㄨㄥㄇㄧ":"叢密",
"ㄘㄨㄥㄇㄨ":"叢木",
"ㄘㄨㄥㄈㄥ":"從風",
"ㄘㄨㄥㄉㄚ":"從打",
"ㄘㄨㄥㄊㄡ":"從頭/蔥頭",
"ㄘㄨㄥㄊㄢ":"叢談",
"ㄘㄨㄥㄌㄞ":"從來",
"ㄘㄨㄥㄌㄢ":"蔥蘭",
"ㄘㄨㄥㄌㄩ":"蔥綠",
"ㄘㄨㄥㄎㄜ":"叢刻",
"ㄘㄨㄥㄎㄢ":"叢刊",
"ㄘㄨㄥㄐㄧ":"從吉/叢集",
"ㄘㄨㄥㄐㄩ":"叢聚/匆遽",
"ㄘㄨㄥㄓㄥ":"從政",
"ㄘㄨㄥㄔㄣ":"叢辰",
"ㄘㄨㄥㄔㄥ":"淙琤/琮琤",
"ㄘㄨㄥㄔㄨ":"從初",
"ㄘㄨㄥㄕㄣ":"從諗",
"ㄘㄨㄥㄕㄤ":"從商",
"ㄘㄨㄥㄕㄥ":"叢生",
"ㄘㄨㄥㄕㄨ":"從屬/叢書",
"ㄘㄨㄥㄖㄣ":"從人/賨人",
"ㄘㄨㄥㄗㄚ":"叢雜",
"ㄘㄨㄥㄗㄤ":"叢葬",
"ㄘㄨㄥㄘㄨ":"匆促/匆猝",
"ㄘㄨㄥㄙㄨ":"從速/從俗",
"ㄘㄨㄥㄧㄡ":"從優",
"ㄘㄨㄥㄧㄢ":"從嚴",
"ㄘㄨㄥㄧㄥ":"從影/聰穎",
"ㄘㄨㄥㄨㄟ":"從未",
"ㄘㄨㄧㄜㄕ":"粗衣惡食",
"ㄙㄆㄧㄥㄕ":"四平市",
"ㄙㄆㄨㄏㄤ":"廝撲行",
"ㄙㄇㄚㄇㄣ":"司馬門",
"ㄙㄇㄚㄈㄚ":"司馬法",
"ㄙㄇㄚㄈㄨ":"緦麻服",
"ㄙㄇㄚㄊㄢ":"司馬談",
"ㄙㄇㄚㄓㄠ":"司馬昭",
"ㄙㄇㄚㄧㄢ":"司馬炎",
"ㄙㄈㄚㄩㄢ":"司法院",
"ㄙㄈㄣㄌㄧ":"四分曆",
"ㄙㄈㄣㄌㄩ":"四分律",
"ㄙㄈㄣㄨㄟ":"四分衛",
"ㄙㄈㄤㄅㄨ":"四方步",
"ㄙㄈㄨㄇㄨ":"嗣父母",
"ㄙㄉㄚㄓㄡ":"四大洲",
"ㄙㄊㄧㄕㄨ":"四體書",
"ㄙㄌㄤㄌㄤ":"廝琅琅",
"ㄙㄍㄨㄥㄗ":"四公子",
"ㄙㄏㄜㄈㄤ":"四合房",
"ㄙㄏㄜㄩㄢ":"四合院",
"ㄙㄐㄧㄉㄡ":"四季豆",
"ㄙㄐㄧㄚㄕ":"四家詩",
"ㄙㄐㄧㄣㄕ":"四進士",
"ㄙㄐㄩㄣㄗ":"四君子",
"ㄙㄒㄧㄉㄞ":"四喜袋",
"ㄙㄒㄧㄤㄕ":"思想史",
"ㄙㄓㄆㄧㄣ":"絲織品",
"ㄙㄓㄡㄒㄧ":"泗州戲",
"ㄙㄓㄨㄘㄜ":"四柱冊",
"ㄙㄓㄨㄩㄝ":"絲竹樂",
"ㄙㄕㄣㄊㄤ":"四神湯",
"ㄙㄕㄦㄈㄟ":"似是而非",
"ㄙㄕㄨㄨㄣ":"四書文",
"ㄙㄖㄣㄅㄤ":"四人幫",
"ㄙㄚㄇㄧㄩ":"撒謎語",
"ㄙㄚㄉㄧㄠ":"撒刁",
"ㄙㄚㄊㄨㄛ":"灑脫",
"ㄙㄚㄊㄨㄟ":"撒腿",
"ㄙㄚㄋㄧㄠ":"撒尿/撒溺",
"ㄙㄚㄌㄨㄛ":"灑落/撒落",
"ㄙㄚㄏㄨㄚ":"撒花",
"ㄙㄚㄏㄨㄢ":"撒歡",
"ㄙㄚㄏㄨㄤ":"撒謊",
"ㄙㄚㄐㄧㄚ":"洒家",
"ㄙㄚㄐㄧㄠ":"撒嬌/撒腳",
"ㄙㄚㄒㄧㄝ":"撒鞋/靸鞋",
"ㄙㄚㄒㄧㄢ":"撒線",
"ㄙㄚㄓㄊㄧ":"撒滯殢",
"ㄙㄚㄓㄨㄥ":"撒種",
"ㄙㄚㄕㄨㄟ":"灑水",
"ㄙㄚㄕㄨㄤ":"颯爽",
"ㄙㄚㄗㄨㄟ":"撒嘴",
"ㄙㄚㄘㄨㄣ":"撒村",
"ㄙㄚㄧㄚㄗ":"撒鴨子",
"ㄙㄜㄉㄧㄠ":"色調/瑟調",
"ㄙㄜㄍㄨㄟ":"色鬼",
"ㄙㄜㄍㄨㄤ":"色光",
"ㄙㄜㄏㄨㄢ":"色環",
"ㄙㄜㄏㄨㄤ":"色荒",
"ㄙㄜㄐㄧㄝ":"色界/色戒",
"ㄙㄜㄐㄩㄝ":"色覺",
"ㄙㄜㄑㄧㄥ":"色情",
"ㄙㄜㄒㄧㄠ":"色笑",
"ㄙㄜㄒㄧㄣ":"色心",
"ㄙㄜㄒㄧㄤ":"色相",
"ㄙㄜㄒㄧㄦ":"塞席爾",
"ㄙㄜㄙㄨㄛ":"瑟縮/澀縮",
"ㄙㄜㄙㄨㄣ":"色思溫",
"ㄙㄞㄅㄤㄗ":"腮幫子",
"ㄙㄞㄏㄨㄚ":"塞話",
"ㄙㄞㄏㄨㄟ":"賽會",
"ㄙㄞㄐㄧㄚ":"腮頰",
"ㄙㄞㄑㄧㄡ":"賽球",
"ㄙㄞㄒㄧㄢ":"腮腺",
"ㄙㄠㄅㄧㄢ":"掃邊",
"ㄙㄠㄆㄧㄥ":"掃平",
"ㄙㄠㄇㄧㄠ":"掃描",
"ㄙㄠㄉㄨㄥ":"騷動",
"ㄙㄠㄊㄧㄥ":"掃聽",
"ㄙㄠㄌㄧㄢ":"掃臉",
"ㄙㄠㄌㄨㄢ":"騷亂",
"ㄙㄠㄍㄨㄛ":"騷國",
"ㄙㄠㄏㄨㄛ":"騷貨",
"ㄙㄠㄏㄨㄤ":"掃黃",
"ㄙㄠㄐㄧㄝ":"掃街",
"ㄙㄠㄐㄧㄢ":"繅繭",
"ㄙㄠㄐㄧㄥ":"騷經",
"ㄙㄠㄒㄧㄝ":"騷屑",
"ㄙㄠㄒㄧㄥ":"掃星",
"ㄙㄡㄅㄧㄥ":"叟兵",
"ㄙㄡㄇㄧㄢ":"溲麵",
"ㄙㄡㄋㄧㄠ":"溲溺",
"ㄙㄡㄌㄧㄡ":"颼飀",
"ㄙㄡㄌㄨㄛ":"搜羅/蒐羅",
"ㄙㄡㄍㄨㄚ":"搜刮/搜括",
"ㄙㄡㄏㄨㄚ":"溲話",
"ㄙㄡㄏㄨㄛ":"擻火",
"ㄙㄡㄐㄧㄝ":"搜劫",
"ㄙㄡㄐㄧㄡ":"搜救",
"ㄙㄡㄐㄧㄢ":"搜檢",
"ㄙㄡㄑㄧㄡ":"搜求",
"ㄙㄡㄒㄩㄣ":"搜尋",
"ㄙㄡㄓㄨㄧ":"餿主意",
"ㄙㄡㄕㄨㄟ":"餿水",
"ㄙㄡㄙㄨㄛ":"搜索/蒐索",
"ㄙㄢㄅㄚㄓ":"三八制",
"ㄙㄢㄅㄧㄠ":"三表",
"ㄙㄢㄅㄧㄢ":"三變",
"ㄙㄢㄅㄧㄥ":"傘兵/三病",
"ㄙㄢㄅㄨㄓ":"三不知",
"ㄙㄢㄅㄨㄧ":"三不易",
"ㄙㄢㄆㄧㄣ":"三品",
"ㄙㄢㄇㄧㄠ":"三苗",
"ㄙㄢㄇㄧㄢ":"三眠",
"ㄙㄢㄇㄧㄥ":"三明/三命",
"ㄙㄢㄈㄚㄙ":"三法司",
"ㄙㄢㄉㄧㄢ":"三典",
"ㄙㄢㄉㄨㄛ":"三多",
"ㄙㄢㄉㄨㄢ":"三端",
"ㄙㄢㄉㄨㄥ":"三冬",
"ㄙㄢㄊㄢㄗ":"散攤子",
"ㄙㄢㄊㄧㄥ":"三停",
"ㄙㄢㄊㄨㄟ":"三推",
"ㄙㄢㄊㄨㄥ":"三通/三統",
"ㄙㄢㄋㄨㄥ":"三農",
"ㄙㄢㄌㄧㄡ":"三六/三流",
"ㄙㄢㄌㄧㄤ":"三兩",
"ㄙㄢㄌㄧㄥ":"三靈",
"ㄙㄢㄌㄨㄛ":"散落/三落",
"ㄙㄢㄌㄨㄢ":"散亂",
"ㄙㄢㄌㄩㄝ":"三略",
"ㄙㄢㄍㄨㄛ":"三國",
"ㄙㄢㄍㄨㄟ":"三歸/三皈",
"ㄙㄢㄍㄨㄢ":"三關/三觀",
"ㄙㄢㄍㄨㄤ":"三光/散光",
"ㄙㄢㄍㄨㄥ":"三公/三宮",
"ㄙㄢㄏㄨㄚ":"散話",
"ㄙㄢㄏㄨㄛ":"散伙/散夥",
"ㄙㄢㄏㄨㄞ":"三槐",
"ㄙㄢㄏㄨㄟ":"散會",
"ㄙㄢㄏㄨㄢ":"三桓",
"ㄙㄢㄏㄨㄤ":"三皇",
"ㄙㄢㄐㄧㄚ":"三甲/三家",
"ㄙㄢㄐㄧㄝ":"三界/三節",
"ㄙㄢㄐㄧㄠ":"三角/三教",
"ㄙㄢㄐㄧㄡ":"三酒",
"ㄙㄢㄐㄧㄢ":"三監/三鑑",
"ㄙㄢㄐㄧㄣ":"三晉",
"ㄙㄢㄐㄧㄤ":"三江",
"ㄙㄢㄐㄧㄥ":"三精/三境",
"ㄙㄢㄐㄩㄝ":"三絕",
"ㄙㄢㄐㄩㄣ":"三軍",
"ㄙㄢㄑㄧㄗ":"三七仔",
"ㄙㄢㄑㄧㄠ":"三僑",
"ㄙㄢㄑㄧㄡ":"三秋",
"ㄙㄢㄑㄧㄢ":"三遷",
"ㄙㄢㄑㄧㄣ":"三親/三秦",
"ㄙㄢㄑㄧㄥ":"三清/三卿",
"ㄙㄢㄑㄩㄢ":"三泉",
"ㄙㄢㄒㄧㄚ":"三峽/三夏",
"ㄙㄢㄒㄧㄠ":"三笑/三消",
"ㄙㄢㄒㄧㄡ":"三秀",
"ㄙㄢㄒㄧㄢ":"三鮮/三獻",
"ㄙㄢㄒㄧㄣ":"散心",
"ㄙㄢㄒㄧㄤ":"三相",
"ㄙㄢㄒㄧㄥ":"三行/三星",
"ㄙㄢㄒㄩㄝ":"三學",
"ㄙㄢㄒㄩㄢ":"三玄",
"ㄙㄢㄒㄩㄣ":"三巡",
"ㄙㄢㄓㄕㄡ":"三隻手",
"ㄙㄢㄓㄨㄢ":"三傳",
"ㄙㄢㄓㄨㄣ":"三準",
"ㄙㄢㄓㄨㄤ":"散裝",
"ㄙㄢㄔㄈㄚ":"三尺法",
"ㄙㄢㄔㄨㄢ":"三川",
"ㄙㄢㄔㄨㄣ":"三春",
"ㄙㄢㄔㄨㄥ":"三重",
"ㄙㄢㄕㄆㄢ":"散氏盤",
"ㄙㄢㄕㄈㄛ":"三世佛",
"ㄙㄢㄕㄕㄣ":"三尸神",
"ㄙㄢㄕㄥㄕ":"三生石",
"ㄙㄢㄕㄨㄚ":"散耍",
"ㄙㄢㄕㄨㄤ":"槮爽",
"ㄙㄢㄗㄨㄨ":"三足烏",
"ㄙㄢㄘㄈㄤ":"三次方",
"ㄙㄢㄘㄨㄥ":"三從",
"ㄙㄢㄙㄊㄞ":"三思臺",
"ㄙㄢㄜㄉㄠ":"三惡道",
"ㄙㄢㄧㄌㄩ":"三一律",
"ㄙㄢㄨㄣㄕ":"散文詩",
"ㄙㄢㄩㄩㄢ":"三語掾",
"ㄙㄣㄌㄧㄣ":"森林",
"ㄙㄤㄆㄧㄓ":"桑皮紙",
"ㄙㄤㄇㄧㄥ":"喪明/喪命",
"ㄙㄤㄊㄧㄢ":"桑田",
"ㄙㄤㄋㄚㄩ":"桑那浴",
"ㄙㄤㄌㄨㄛ":"桑落",
"ㄙㄤㄌㄨㄢ":"喪亂",
"ㄙㄤㄐㄧㄚ":"喪家",
"ㄙㄤㄑㄧㄣ":"桑欽",
"ㄙㄤㄑㄩㄢ":"喪權",
"ㄙㄤㄒㄧㄣ":"喪心",
"ㄙㄤㄓㄨㄥ":"桑中/喪鐘",
"ㄙㄥㄍㄨㄢ":"僧官",
"ㄙㄥㄑㄧㄝ":"僧伽",
"ㄙㄥㄒㄧㄝ":"僧鞋",
"ㄙㄦㄏㄡㄓ":"死而後止",
"ㄙㄦㄏㄡㄧ":"死而後已",
"ㄙㄦㄨㄩㄢ":"死而無怨",
"ㄙㄧㄥㄆㄞ":"死硬派",
"ㄙㄨㄇㄧㄠ":"素描",
"ㄙㄨㄇㄧㄢ":"素面",
"ㄙㄨㄇㄧㄥ":"宿命/俗名",
"ㄙㄨㄉㄨㄣ":"宿頓",
"ㄙㄨㄊㄧㄝ":"蘇鐵",
"ㄙㄨㄊㄨㄟ":"素退",
"ㄙㄨㄋㄧㄢ":"俗念",
"ㄙㄨㄋㄨㄛ":"宿諾",
"ㄙㄨㄌㄧㄠ":"宿料/塑料",
"ㄙㄨㄌㄧㄡ":"素流/俗流",
"ㄙㄨㄌㄧㄢ":"蘇聯/素練",
"ㄙㄨㄌㄨㄛ":"訴落",
"ㄙㄨㄌㄨㄣ":"素論",
"ㄙㄨㄍㄨㄢ":"素冠",
"ㄙㄨㄍㄨㄥ":"肅恭",
"ㄙㄨㄏㄨㄚ":"俗話/俗化",
"ㄙㄨㄏㄨㄞ":"素懷",
"ㄙㄨㄏㄨㄟ":"宿慧/蘇蕙",
"ㄙㄨㄏㄨㄢ":"素宦",
"ㄙㄨㄐㄧㄚ":"俗家/速駕",
"ㄙㄨㄐㄧㄝ":"俗界/素節",
"ㄙㄨㄐㄧㄠ":"塑膠/素交",
"ㄙㄨㄐㄧㄡ":"素酒/素舊",
"ㄙㄨㄐㄧㄤ":"俗講/宿將",
"ㄙㄨㄐㄧㄥ":"肅靜/素淨",
"ㄙㄨㄐㄩㄝ":"速決",
"ㄙㄨㄐㄩㄢ":"素絹",
"ㄙㄨㄐㄩㄣ":"蘇峻",
"ㄙㄨㄑㄧㄡ":"訴求",
"ㄙㄨㄑㄧㄢ":"酥簽",
"ㄙㄨㄑㄧㄣ":"素琴/蘇秦",
"ㄙㄨㄑㄧㄥ":"肅清/俗情",
"ㄙㄨㄑㄩㄢ":"訴權",
"ㄙㄨㄒㄧㄝ":"思無邪",
"ㄙㄨㄒㄧㄡ":"蘇繡",
"ㄙㄨㄒㄧㄣ":"素心/宿心",
"ㄙㄨㄒㄧㄤ":"塑像",
"ㄙㄨㄒㄧㄥ":"素行/素性",
"ㄙㄨㄒㄩㄝ":"宿學",
"ㄙㄨㄒㄩㄣ":"蘇洵",
"ㄙㄨㄒㄩㄥ":"酥胸",
"ㄙㄨㄓㄨㄟ":"訴追",
"ㄙㄨㄓㄨㄤ":"訴狀/宿妝",
"ㄙㄨㄓㄨㄥ":"素衷",
"ㄙㄨㄕㄨㄛ":"訴說",
"ㄙㄨㄕㄨㄟ":"涑水",
"ㄙㄨㄕㄨㄣ":"肅順",
"ㄙㄨㄕㄨㄤ":"橚爽/肅爽",
"ㄙㄨㄖㄨㄢ":"酥軟",
"ㄙㄨㄗㄧㄡ":"蘇子油",
"ㄙㄨㄗㄨㄛ":"肅坐",
"ㄙㄨㄗㄨㄟ":"宿醉",
"ㄙㄨㄘㄎㄤ":"速賜康",
"ㄙㄨㄙㄨㄥ":"訴訟",
"ㄙㄨㄛㄆㄛ":"娑婆",
"ㄙㄨㄛㄆㄟ":"索賠",
"ㄙㄨㄛㄇㄛ":"索寞",
"ㄙㄨㄛㄇㄟ":"鎖眉",
"ㄙㄨㄛㄇㄣ":"鎖門",
"ㄙㄨㄛㄈㄣ":"索粉",
"ㄙㄨㄛㄈㄤ":"索放",
"ㄙㄨㄛㄈㄨ":"所伏/索婦",
"ㄙㄨㄛㄉㄜ":"所得",
"ㄙㄨㄛㄉㄠ":"索道",
"ㄙㄨㄛㄊㄚ":"瑣闥",
"ㄙㄨㄛㄊㄠ":"索討",
"ㄙㄨㄛㄊㄡ":"索頭/鎖頭",
"ㄙㄨㄛㄊㄨ":"縮圖",
"ㄙㄨㄛㄋㄚ":"嗩吶",
"ㄙㄨㄛㄋㄠ":"索鬧",
"ㄙㄨㄛㄋㄨ":"璅弩",
"ㄙㄨㄛㄌㄧ":"索利/蓑笠",
"ㄙㄨㄛㄌㄨ":"索虜",
"ㄙㄨㄛㄍㄨ":"鎖骨",
"ㄙㄨㄛㄏㄚ":"梭哈",
"ㄙㄨㄛㄏㄜ":"索合/縮合",
"ㄙㄨㄛㄐㄩ":"索居",
"ㄙㄨㄛㄑㄩ":"索取",
"ㄙㄨㄛㄒㄧ":"瑣細",
"ㄙㄨㄛㄓㄞ":"索債",
"ㄙㄨㄛㄓㄤ":"所長",
"ㄙㄨㄛㄔㄤ":"所長",
"ㄙㄨㄛㄔㄥ":"縮稱",
"ㄙㄨㄛㄔㄨ":"所除",
"ㄙㄨㄛㄕㄥ":"所生",
"ㄙㄨㄛㄕㄨ":"所屬",
"ㄙㄨㄛㄖㄢ":"索然",
"ㄙㄨㄛㄗㄞ":"所在",
"ㄙㄨㄛㄘㄞ":"瑣才",
"ㄙㄨㄛㄘㄠ":"莎草/蓑草",
"ㄙㄨㄛㄧㄠ":"鎖鑰",
"ㄙㄨㄛㄧㄡ":"所有/所由",
"ㄙㄨㄛㄧㄣ":"索引/縮印",
"ㄙㄨㄛㄧㄤ":"鎖陽",
"ㄙㄨㄛㄧㄥ":"縮影",
"ㄙㄨㄛㄨㄟ":"所謂/所為",
"ㄙㄨㄛㄨㄣ":"瑣聞",
"ㄙㄨㄛㄩㄢ":"鎖院",
"ㄙㄨㄟㄅㄟ":"隨輩",
"ㄙㄨㄟㄅㄧ":"隨筆/歲幣",
"ㄙㄨㄟㄅㄨ":"碎步",
"ㄙㄨㄟㄇㄛ":"歲末",
"ㄙㄨㄟㄇㄧ":"碎密/邃密",
"ㄙㄨㄟㄇㄨ":"歲暮/燧木",
"ㄙㄨㄟㄈㄣ":"隨分",
"ㄙㄨㄟㄈㄥ":"隨封/隧蜂",
"ㄙㄨㄟㄈㄨ":"綏服/綏撫",
"ㄙㄨㄟㄉㄞ":"隨帶/隨逮",
"ㄙㄨㄟㄉㄠ":"隧道",
"ㄙㄨㄟㄉㄧ":"隨地",
"ㄙㄨㄟㄊㄧ":"隋隄",
"ㄙㄨㄟㄌㄟ":"隨類",
"ㄙㄨㄟㄌㄢ":"歲闌",
"ㄙㄨㄟㄍㄨ":"雖故/邃古",
"ㄙㄨㄟㄎㄠ":"歲考",
"ㄙㄨㄟㄎㄡ":"隨口",
"ㄙㄨㄟㄏㄜ":"隨和/隨和",
"ㄙㄨㄟㄏㄡ":"隨後",
"ㄙㄨㄟㄏㄢ":"歲寒",
"ㄙㄨㄟㄏㄨ":"邃戶",
"ㄙㄨㄟㄐㄧ":"隨機/隨即",
"ㄙㄨㄟㄒㄧ":"隨喜",
"ㄙㄨㄟㄒㄩ":"睢盱",
"ㄙㄨㄟㄓㄠ":"歲朝",
"ㄙㄨㄟㄓㄡ":"穗軸",
"ㄙㄨㄟㄓㄤ":"繐帳",
"ㄙㄨㄟㄓㄨ":"隋珠",
"ㄙㄨㄟㄔㄚ":"歲差",
"ㄙㄨㄟㄔㄠ":"隋朝",
"ㄙㄨㄟㄔㄣ":"隨趁",
"ㄙㄨㄟㄔㄤ":"隨常/繐裳",
"ㄙㄨㄟㄔㄨ":"隨處/歲出",
"ㄙㄨㄟㄕㄡ":"隨手/歲收",
"ㄙㄨㄟㄕㄣ":"隨身",
"ㄙㄨㄟㄕㄨ":"歲數/隋書",
"ㄙㄨㄟㄖㄢ":"雖然",
"ㄙㄨㄟㄖㄨ":"歲入",
"ㄙㄨㄟㄗㄜ":"雖則",
"ㄙㄨㄟㄗㄤ":"隨葬",
"ㄙㄨㄟㄙㄨ":"隨俗",
"ㄙㄨㄟㄧㄝ":"歲夜",
"ㄙㄨㄟㄧㄢ":"歲晏",
"ㄙㄨㄟㄧㄣ":"歲陰",
"ㄙㄨㄟㄧㄤ":"歲陽/睢陽",
"ㄙㄨㄟㄨㄟ":"睢維/繐帷",
"ㄙㄨㄟㄩㄝ":"歲月",
"ㄙㄨㄟㄩㄢ":"隨緣/隨員",
"ㄙㄨㄢㄅㄞ":"酸敗",
"ㄙㄨㄢㄅㄧ":"酸鼻",
"ㄙㄨㄢㄆㄢ":"算盤/筭盤",
"ㄙㄨㄢㄇㄚ":"酸麻/痠麻",
"ㄙㄨㄢㄇㄛ":"酸模",
"ㄙㄨㄢㄇㄟ":"酸梅",
"ㄙㄨㄢㄈㄚ":"算法/算髮",
"ㄙㄨㄢㄈㄨ":"酸腐",
"ㄙㄨㄢㄉㄜ":"算得",
"ㄙㄨㄢㄊㄞ":"蒜薹",
"ㄙㄨㄢㄊㄡ":"蒜頭",
"ㄙㄨㄢㄊㄥ":"酸藤/酸疼",
"ㄙㄨㄢㄊㄧ":"算題",
"ㄙㄨㄢㄋㄞ":"酸奶",
"ㄙㄨㄢㄋㄧ":"蒜泥/狻猊",
"ㄙㄨㄢㄌㄜ":"算了",
"ㄙㄨㄢㄌㄞ":"算來",
"ㄙㄨㄢㄌㄢ":"酸懶/痠懶",
"ㄙㄨㄢㄌㄧ":"算曆",
"ㄙㄨㄢㄍㄢ":"酸酐",
"ㄙㄨㄢㄍㄣ":"酸根",
"ㄙㄨㄢㄏㄠ":"蒜毫",
"ㄙㄨㄢㄏㄢ":"酸寒",
"ㄙㄨㄢㄐㄧ":"算計/算計",
"ㄙㄨㄢㄑㄧ":"算器",
"ㄙㄨㄢㄓㄤ":"算帳",
"ㄙㄨㄢㄔㄡ":"算籌",
"ㄙㄨㄢㄔㄨ":"酸楚",
"ㄙㄨㄢㄕㄤ":"算上",
"ㄙㄨㄢㄕㄨ":"算數/算術",
"ㄙㄨㄢㄖㄨ":"酸乳",
"ㄙㄨㄢㄗㄠ":"酸棗",
"ㄙㄨㄢㄘㄞ":"酸菜",
"ㄙㄨㄣㄅㄧ":"蓀壁",
"ㄙㄨㄣㄆㄧ":"筍皮",
"ㄙㄨㄣㄇㄟ":"蓀美",
"ㄙㄨㄣㄈㄨ":"筍脯",
"ㄙㄨㄣㄉㄜ":"損德",
"ㄙㄨㄣㄊㄡ":"榫頭",
"ㄙㄨㄣㄋㄩ":"孫女",
"ㄙㄨㄣㄌㄤ":"孫郎",
"ㄙㄨㄣㄍㄢ":"筍乾",
"ㄙㄨㄣㄏㄞ":"損害",
"ㄙㄨㄣㄏㄠ":"損耗",
"ㄙㄨㄣㄐㄧ":"筍雞",
"ㄙㄨㄣㄐㄩ":"筍虡/簨虡",
"ㄙㄨㄣㄒㄧ":"筍席",
"ㄙㄨㄣㄓㄜ":"損折",
"ㄙㄨㄣㄓㄨ":"孫竹",
"ㄙㄨㄣㄕㄡ":"損壽",
"ㄙㄨㄣㄕㄢ":"孫山",
"ㄙㄨㄣㄕㄤ":"損傷",
"ㄙㄨㄣㄕㄨ":"損書",
"ㄙㄨㄣㄖㄣ":"斯文人",
"ㄙㄨㄣㄘㄜ":"孫策",
"ㄙㄨㄣㄧㄚ":"筍鴨",
"ㄙㄨㄣㄧㄡ":"損友",
"ㄙㄨㄣㄧㄢ":"筍眼/榫眼",
"ㄙㄨㄣㄨㄣ":"孫文",
"ㄙㄨㄤㄌㄩ":"死亡率",
"ㄙㄨㄤㄨㄖ":"死亡無日",
"ㄙㄨㄥㄅㄛ":"松柏",
"ㄙㄨㄥㄅㄠ":"送報",
"ㄙㄨㄥㄅㄢ":"宋板",
"ㄙㄨㄥㄅㄣ":"宋本",
"ㄙㄨㄥㄅㄤ":"鬆綁",
"ㄙㄨㄥㄇㄛ":"松漠",
"ㄙㄨㄥㄇㄟ":"頌美/聳昧",
"ㄙㄨㄥㄇㄠ":"松毛",
"ㄙㄨㄥㄇㄨ":"松木/送目",
"ㄙㄨㄥㄈㄥ":"松風",
"ㄙㄨㄥㄉㄚ":"送達",
"ㄙㄨㄥㄉㄨ":"誦讀",
"ㄙㄨㄥㄊㄠ":"松濤",
"ㄙㄨㄥㄊㄧ":"悚惕",
"ㄙㄨㄥㄌㄠ":"送老",
"ㄙㄨㄥㄌㄧ":"送禮/聳立",
"ㄙㄨㄥㄌㄨ":"送路/松露",
"ㄙㄨㄥㄍㄜ":"頌歌",
"ㄙㄨㄥㄍㄠ":"崧高",
"ㄙㄨㄥㄍㄨ":"送故",
"ㄙㄨㄥㄎㄜ":"送客/宋克",
"ㄙㄨㄥㄎㄞ":"鬆開",
"ㄙㄨㄥㄎㄡ":"鬆口",
"ㄙㄨㄥㄎㄥ":"宋牼",
"ㄙㄨㄥㄏㄜ":"松鶴",
"ㄙㄨㄥㄏㄡ":"嵩厚",
"ㄙㄨㄥㄏㄨ":"嵩呼",
"ㄙㄨㄥㄐㄧ":"松雞/嵩箕",
"ㄙㄨㄥㄐㄩ":"聳懼/悚懼",
"ㄙㄨㄥㄑㄧ":"送氣/鬆氣",
"ㄙㄨㄥㄒㄧ":"誦習/悚息",
"ㄙㄨㄥㄓㄕ":"松脂石",
"ㄙㄨㄥㄓㄠ":"宋朝",
"ㄙㄨㄥㄓㄣ":"松針",
"ㄙㄨㄥㄓㄨ":"嵩祝/松竹",
"ㄙㄨㄥㄔㄚ":"送茶",
"ㄙㄨㄥㄔㄠ":"宋朝",
"ㄙㄨㄥㄕㄠ":"嵩少",
"ㄙㄨㄥㄕㄡ":"鬆手/嵩壽",
"ㄙㄨㄥㄕㄢ":"松山/嵩山",
"ㄙㄨㄥㄕㄣ":"竦身/送神",
"ㄙㄨㄥㄕㄥ":"頌聲",
"ㄙㄨㄥㄕㄨ":"宋書/松樹",
"ㄙㄨㄥㄖㄢ":"聳然/悚然",
"ㄙㄨㄥㄖㄣ":"送人/松仁",
"ㄙㄨㄥㄖㄤ":"松瓤",
"ㄙㄨㄥㄖㄨ":"宋儒",
"ㄙㄨㄥㄗㄠ":"送灶",
"ㄙㄨㄥㄗㄢ":"頌讚",
"ㄙㄨㄥㄗㄤ":"送葬",
"ㄙㄨㄥㄘㄞ":"菘菜",
"ㄙㄨㄥㄙㄢ":"鬆散",
"ㄙㄨㄥㄙㄤ":"送喪",
"ㄙㄨㄥㄧㄢ":"誦言/訟言",
"ㄙㄨㄥㄧㄤ":"頌揚",
"ㄙㄨㄥㄨㄟ":"誦味",
"ㄙㄨㄥㄨㄢ":"宋琬",
"ㄙㄨㄥㄨㄣ":"松紋",
"ㄙㄨㄥㄩㄥ":"慫恿/誦詠",
"ㄙㄨㄦㄋㄞ":"蘇爾奈",
"ㄙㄨㄧㄧㄡ":"死無遺憂",
"ㄙㄨㄨㄟㄞ":"蘇維埃",
"ㄚㄅㄛㄌㄜ":"阿勃勒",
"ㄚㄇㄚㄉㄚ":"阿馬達",
"ㄚㄇㄟㄗㄨ":"阿美族",
"ㄚㄇㄧㄅㄚ":"阿米巴",
"ㄚㄇㄨㄏㄜ":"阿姆河",
"ㄚㄉㄡㄧㄢ":"阿兜眼",
"ㄚㄋㄚㄌㄩ":"阿那律",
"ㄚㄋㄚㄏㄢ":"阿那含",
"ㄚㄌㄢㄖㄜ":"阿蘭若",
"ㄚㄌㄧㄌㄤ":"阿里郎",
"ㄚㄍㄜㄍㄜ":"阿哥哥",
"ㄚㄍㄨㄨㄥ":"阿家翁",
"ㄚㄎㄜㄙㄨ":"阿克蘇",
"ㄚㄔㄤㄗㄨ":"阿昌族",
"ㄚㄕㄚㄌㄧ":"阿莎力",
"ㄚㄕㄜㄌㄧ":"阿闍梨",
"ㄚㄙㄢㄍㄜ":"阿三哥",
"ㄜㄅㄚㄙㄤ":"阿巴桑",
"ㄜㄅㄧㄏㄜ":"鄂畢河",
"ㄜㄅㄧㄥㄓ":"惡病質",
"ㄜㄇㄟㄅㄢ":"蛾眉班",
"ㄜㄇㄟㄉㄡ":"蛾眉豆",
"ㄜㄇㄟㄕㄢ":"峨嵋山",
"ㄜㄇㄟㄩㄝ":"蛾眉月",
"ㄜㄇㄠㄅㄟ":"鵝毛被",
"ㄜㄇㄠㄕㄢ":"鵝毛扇",
"ㄜㄈㄥㄕㄚ":"額風痧",
"ㄜㄌㄨㄢㄕ":"鵝卵石",
"ㄜㄏㄣㄏㄣ":"惡狠狠",
"ㄜㄒㄧㄤㄧ":"鵝項椅",
"ㄜㄓㄤㄈㄥ":"鵝掌風",
"ㄜㄓㄤㄘㄠ":"鵝掌草",
"ㄜㄕㄒㄩㄝ":"阿是穴",
"ㄜㄗㄐㄧㄢ":"蚵仔煎",
"ㄜㄧㄈㄟㄕ":"惡衣菲食",
"ㄜㄧㄌㄧㄕ":"惡衣糲食",
"ㄜㄧㄕㄨㄕ":"惡衣蔬食",
"ㄜㄧㄢㄜㄩ":"惡言惡語",
"ㄜㄩㄣㄕㄜ":"遏雲社",
"ㄞㄅㄚㄨㄦ":"愛巴物兒",
"ㄞㄆㄚㄆㄚ":"矮趴趴",
"ㄞㄇㄧㄢㄗ":"愛面子",
"ㄞㄉㄠㄉㄜ":"挨刀的",
"ㄞㄋㄞㄑㄩ":"欸乃曲",
"ㄞㄌㄨㄛㄙ":"愛羅斯",
"ㄞㄍㄨㄣㄗ":"挨棍子",
"ㄞㄐㄧㄌㄧ":"埃及曆",
"ㄞㄖㄣㄗㄨ":"矮人族",
"ㄞㄗㄅㄧㄥ":"愛滋病",
"ㄞㄘㄨㄛㄗ":"矮矬子",
"ㄞㄧㄠㄧㄠ":"噯喲喲",
"ㄞㄨㄐㄧㄨ":"愛屋及烏",
"ㄞㄨㄛㄨㄛ":"艾窩窩",
"ㄞㄩㄉㄨㄥ":"愛玉凍",
"ㄠㄅㄨㄉㄥ":"拗不登",
"ㄠㄅㄨㄓㄨ":"熬不住",
"ㄠㄉㄜㄙㄞ":"奧德賽",
"ㄠㄊㄠㄐㄧ":"奧陶紀",
"ㄠㄋㄧㄧㄕ":"傲睨一世",
"ㄠㄎㄡㄈㄥ":"拗口風",
"ㄠㄓㄡㄜㄩ":"澳洲鱷魚",
"ㄠㄔㄨㄊㄡ":"熬出頭",
"ㄠㄕㄨㄤㄓ":"傲霜枝",
"ㄠㄦㄌㄧㄤ":"奧爾良",
"ㄡㄅㄚㄙㄤ":"歐巴桑",
"ㄡㄆㄟㄎㄜ":"歐佩克",
"ㄡㄊㄧㄇㄨ":"偶蹄目",
"ㄡㄌㄧㄥㄦ":"藕零兒",
"ㄡㄏㄢㄕㄨ":"偶函數",
"ㄡㄐㄧㄙㄤ":"歐吉桑",
"ㄡㄒㄧㄖㄣ":"偶戲人",
"ㄡㄕㄍㄨㄢ":"歐氏管",
"ㄡㄧㄤㄕㄥ":"歐陽生",
"ㄡㄧㄨㄟㄓ":"偶一為之",
"ㄢㄆㄟㄐㄧ":"安培計",
"ㄢㄇㄛㄑㄧ":"按摩器",
"ㄢㄇㄛㄨㄚ":"按摩襪",
"ㄢㄈㄢㄧㄥ":"暗反應",
"ㄢㄉㄧㄥㄗ":"安釘子",
"ㄢㄉㄧㄥㄧ":"安定翼",
"ㄢㄉㄨㄥㄕ":"安東市",
"ㄢㄊㄞㄈㄨ":"安胎符",
"ㄢㄊㄞㄧㄠ":"安胎藥",
"ㄢㄊㄡㄐㄩ":"案頭劇",
"ㄢㄊㄡㄕㄨ":"案頭書",
"ㄢㄌㄜㄨㄛ":"安樂窩",
"ㄢㄌㄠㄩㄢ":"安老院",
"ㄢㄌㄨㄕㄢ":"安祿山",
"ㄢㄍㄜㄌㄚ":"安哥拉",
"ㄢㄍㄨㄥㄗ":"安公子",
"ㄢㄏㄨㄚㄦ":"暗花兒",
"ㄢㄐㄧㄈㄤ":"安濟坊",
"ㄢㄑㄧㄕㄥ":"安期生",
"ㄢㄕㄌㄧㄡ":"安石榴",
"ㄢㄕㄜㄋㄚ":"安闍那",
"ㄢㄕㄢㄧㄢ":"安山岩",
"ㄢㄖㄢㄨㄕ":"安然無事",
"ㄢㄧㄤㄏㄜ":"安陽河",
"ㄦㄅㄚㄉㄠ":"二把刀",
"ㄦㄅㄠㄕㄣ":"耳報神",
"ㄦㄅㄨㄌㄥ":"二不愣",
"ㄦㄆㄤㄈㄥ":"耳旁風",
"ㄦㄇㄨㄓㄩ":"耳目之欲",
"ㄦㄈㄣㄈㄚ":"二分法",
"ㄦㄈㄨㄖㄣ":"二夫人",
"ㄦㄉㄥㄈㄣ":"二等分",
"ㄦㄉㄧㄓㄨ":"二地主",
"ㄦㄉㄨㄇㄟ":"二度梅",
"ㄦㄊㄞㄧㄝ":"二太爺",
"ㄦㄊㄧㄢㄓ":"二田制",
"ㄦㄋㄧㄤㄗ":"二娘子",
"ㄦㄋㄩㄊㄞ":"兒女態",
"ㄦㄋㄩㄓㄞ":"兒女債",
"ㄦㄌㄤㄕㄣ":"二郎神",
"ㄦㄌㄧㄡㄗ":"二流子",
"ㄦㄍㄜㄉㄚ":"二哥大",
"ㄦㄍㄤㄊㄤ":"二缸湯",
"ㄦㄍㄨㄚㄗ":"耳刮子",
"ㄦㄍㄨㄛㄗ":"耳摑子",
"ㄦㄏㄨㄟㄗ":"二會子",
"ㄦㄏㄨㄣㄗ":"二混子",
"ㄦㄐㄧㄊㄧ":"二極體",
"ㄦㄐㄧㄣㄓ":"二進制",
"ㄦㄒㄧㄤㄕ":"二項式",
"ㄦㄒㄧㄥㄗ":"二性子",
"ㄦㄓㄨㄟㄗ":"耳墜子",
"ㄦㄔㄣㄊㄤ":"二陳湯",
"ㄦㄕㄓㄊㄢ":"耳食之談",
"ㄦㄕㄓㄧㄢ":"耳食之言",
"ㄦㄕㄓㄨㄣ":"耳食之聞",
"ㄦㄕㄡㄧㄢ":"二手煙",
"ㄦㄖㄣㄊㄞ":"二人臺",
"ㄦㄘㄨㄖㄢ":"二次汙染",
"ㄦㄙㄞㄌㄟ":"二腮類",
"ㄧㄅㄚㄓㄠ":"一把罩",
"ㄧㄅㄚㄓㄤ":"一巴掌",
"ㄧㄅㄚㄕㄡ":"一把手",
"ㄧㄅㄛㄊㄚ":"衣缽塔",
"ㄧㄅㄞㄈㄣ":"一百分",
"ㄧㄅㄢㄈㄚ":"一般法",
"ㄧㄅㄢㄨㄦ":"一般無二",
"ㄧㄅㄧㄍㄡ":"一筆勾",
"ㄧㄅㄨㄅㄨ":"一步步",
"ㄧㄅㄨㄈㄣ":"一部分",
"ㄧㄅㄨㄑㄧ":"一步棋",
"ㄧㄆㄡㄊㄨ":"一抔土",
"ㄧㄆㄧㄍㄨ":"一屁股",
"ㄧㄆㄧㄢㄕ":"一片石",
"ㄧㄇㄚㄇㄨ":"伊瑪目",
"ㄧㄇㄚㄔㄜ":"驛馬車",
"ㄧㄇㄚㄧㄢ":"一馬一鞍",
"ㄧㄇㄧㄣㄗ":"一抿子",
"ㄧㄇㄨㄘㄠ":"益母草",
"ㄧㄈㄟㄗㄨ":"意飛族",
"ㄧㄈㄢㄓㄣ":"一飯之恩",
"ㄧㄈㄥㄩㄙ":"遺風餘思",
"ㄧㄉㄚㄅㄢ":"一大半",
"ㄧㄉㄚㄗㄠ":"一大早",
"ㄧㄉㄜㄓㄩ":"一得之愚",
"ㄧㄉㄞㄓㄠ":"衣帶詔",
"ㄧㄉㄠㄈㄥ":"一道風",
"ㄧㄉㄠㄊㄤ":"一道湯",
"ㄧㄉㄠㄔㄨ":"一到處",
"ㄧㄉㄠㄙㄨ":"胰島素",
"ㄧㄉㄠㄧㄢ":"一道煙",
"ㄧㄉㄥㄉㄥ":"屹蹬蹬",
"ㄧㄉㄧㄌㄧ":"一遞裡",
"ㄧㄉㄧㄡㄦ":"一丟兒",
"ㄧㄉㄨㄆㄧ":"一肚皮",
"ㄧㄉㄨㄌㄨ":"一嘟嚕",
"ㄧㄊㄞㄊㄞ":"姨太太",
"ㄧㄊㄡㄖㄜ":"一頭熱",
"ㄧㄊㄧㄥㄦ":"一停兒",
"ㄧㄊㄨㄥㄓ":"一統志",
"ㄧㄊㄨㄥㄕ":"易通釋",
"ㄧㄋㄚㄊㄡ":"一納頭",
"ㄧㄋㄞㄋㄞ":"姨奶奶",
"ㄧㄋㄨㄥㄦ":"一弄兒",
"ㄧㄌㄡㄧㄡ":"一簍油",
"ㄧㄌㄢㄨㄧ":"一覽無遺",
"ㄧㄌㄢㄨㄩ":"一覽無餘",
"ㄧㄌㄤㄊㄤ":"一浪淌",
"ㄧㄌㄧㄅㄨ":"伊里布",
"ㄧㄌㄧㄇㄚ":"伊犁馬",
"ㄧㄌㄧㄏㄜ":"伊犁河",
"ㄧㄌㄧㄡㄗ":"一溜子",
"ㄧㄌㄧㄡㄦ":"一溜兒",
"ㄧㄌㄧㄥㄦ":"一零兒",
"ㄧㄌㄧㄧㄙ":"意裡意思",
"ㄧㄌㄨㄎㄨ":"一路哭",
"ㄧㄌㄨㄖㄣ":"一路人",
"ㄧㄍㄜㄗㄦ":"一個子兒",
"ㄧㄍㄨㄌㄨ":"一骨碌",
"ㄧㄍㄨㄥㄦ":"一工兒",
"ㄧㄎㄡㄈㄢ":"一口飯",
"ㄧㄎㄡㄑㄧ":"一口氣",
"ㄧㄏㄜㄩㄢ":"頤和園",
"ㄧㄏㄟㄗㄠ":"一黑早",
"ㄧㄏㄨㄓㄧ":"一狐之腋",
"ㄧㄏㄨㄟㄓ":"議會制",
"ㄧㄏㄨㄟㄕ":"一回事",
"ㄧㄏㄨㄟㄗ":"一會子",
"ㄧㄐㄧㄓㄙ":"一己之私",
"ㄧㄐㄧㄚㄗ":"一家子",
"ㄧㄐㄧㄠㄓ":"一腳指",
"ㄧㄐㄧㄢㄓ":"夷堅志",
"ㄧㄐㄧㄢㄕ":"醫檢師",
"ㄧㄐㄧㄥㄦ":"一景兒",
"ㄧㄐㄩㄝㄢ":"議決案",
"ㄧㄒㄧㄉㄧ":"一席地",
"ㄧㄒㄧㄚㄗ":"一下子",
"ㄧㄒㄧㄥㄗ":"一星子",
"ㄧㄒㄧㄥㄘ":"宜興瓷",
"ㄧㄒㄧㄥㄦ":"一星兒",
"ㄧㄒㄩㄒㄧ":"噫吁戲",
"ㄧㄓㄏㄨㄚ":"一枝花",
"ㄧㄓㄑㄧㄕ":"頤指氣使",
"ㄧㄓㄒㄧㄥ":"一致性",
"ㄧㄓㄔㄨㄣ":"一枝春",
"ㄧㄓㄚㄧㄢ":"一眨眼",
"ㄧㄓㄜㄕㄡ":"一磔手",
"ㄧㄓㄡㄕㄨ":"逸周書",
"ㄧㄓㄡㄗㄠ":"一週遭",
"ㄧㄓㄣㄈㄥ":"一陣風",
"ㄧㄓㄤㄧㄕ":"一張一弛",
"ㄧㄓㄨㄢㄩ":"一轉語",
"ㄧㄔㄚㄋㄚ":"一剎那",
"ㄧㄔㄚㄦㄨ":"一差二誤",
"ㄧㄔㄢㄊㄧ":"一闡提",
"ㄧㄕㄊㄧㄥ":"議事廳",
"ㄧㄕㄌㄧㄡ":"意識流",
"ㄧㄕㄐㄧㄝ":"一世界",
"ㄧㄕㄐㄧㄢ":"一時間",
"ㄧㄕㄓㄇㄡ":"衣食之謀",
"ㄧㄕㄓㄉㄧ":"一矢之地",
"ㄧㄕㄓㄐㄧ":"一時之急",
"ㄧㄕㄓㄤㄞ":"意識障礙",
"ㄧㄕㄖㄔㄥ":"議事日程",
"ㄧㄕㄡㄇㄨ":"翼手目",
"ㄧㄕㄡㄌㄟ":"翼手類",
"ㄧㄕㄢㄕㄣ":"一閃神",
"ㄧㄕㄥㄧㄕ":"一生一世",
"ㄧㄕㄧㄎㄜ":"一時一刻",
"ㄧㄕㄧㄓㄤ":"一弛一張",
"ㄧㄕㄧㄗㄣ":"刈蓍遺簪",
"ㄧㄕㄨㄐㄧ":"藝術季",
"ㄧㄕㄨㄔㄥ":"一事無成",
"ㄧㄕㄨㄣㄦ":"一順兒",
"ㄧㄖㄐㄧㄚ":"一日家",
"ㄧㄖㄓㄓㄤ":"一日之長",
"ㄧㄖㄓㄔㄤ":"一日之長",
"ㄧㄖㄓㄧㄚ":"一日之雅",
"ㄧㄖㄢㄗㄩ":"怡然自娛",
"ㄧㄖㄣㄅㄢ":"一人班",
"ㄧㄖㄨㄐㄧ":"義乳記",
"ㄧㄖㄨㄓㄤ":"艾如張",
"ㄧㄗㄅㄨㄕ":"一字不識",
"ㄧㄗㄒㄧㄥ":"以字行",
"ㄧㄗㄠㄑㄧ":"一早起",
"ㄧㄗㄧㄅㄢ":"一字一板",
"ㄧㄗㄧㄌㄟ":"一字一淚",
"ㄧㄗㄧㄓㄨ":"一字一珠",
"ㄧㄙㄅㄢㄙ":"一絲半絲",
"ㄧㄙㄓㄨㄧ":"意思主義",
"ㄧㄙㄢㄗㄨ":"夷三族",
"ㄧㄙㄧㄏㄠ":"一絲一毫",
"ㄧㄙㄧㄕㄥ":"一死一生",
"ㄧㄚㄅㄚㄦ":"丫巴兒",
"ㄧㄚㄅㄧㄣ":"鴉鬢",
"ㄧㄚㄆㄢㄖ":"牙盤日",
"ㄧㄚㄆㄧㄠ":"押票/鴨票",
"ㄧㄚㄆㄧㄢ":"鴉片/雅片",
"ㄧㄚㄉㄢㄗ":"鴉膽子",
"ㄧㄚㄉㄧㄠ":"牙雕",
"ㄧㄚㄉㄧㄢ":"押店",
"ㄧㄚㄉㄨㄟ":"壓隊/押隊",
"ㄧㄚㄉㄨㄥ":"亞東",
"ㄧㄚㄊㄧㄠ":"壓條/鴨條",
"ㄧㄚㄊㄨㄟ":"牙推/衙推",
"ㄧㄚㄊㄨㄥ":"壓痛",
"ㄧㄚㄋㄧㄝ":"牙櫱",
"ㄧㄚㄋㄩㄝ":"雅謔",
"ㄧㄚㄌㄧㄢ":"雅利安",
"ㄧㄚㄌㄧㄤ":"雅量",
"ㄧㄚㄌㄧㄥ":"啞鈴/砑綾",
"ㄧㄚㄌㄨㄢ":"壓卵",
"ㄧㄚㄌㄨㄣ":"牙輪",
"ㄧㄚㄍㄨㄚ":"聐聒",
"ㄧㄚㄍㄨㄟ":"押櫃",
"ㄧㄚㄍㄨㄢ":"牙關/雅觀",
"ㄧㄚㄍㄨㄤ":"砑光",
"ㄧㄚㄎㄨㄞ":"牙儈",
"ㄧㄚㄎㄨㄢ":"押款",
"ㄧㄚㄏㄨㄚ":"壓花/牙花",
"ㄧㄚㄏㄨㄞ":"雅懷",
"ㄧㄚㄏㄨㄟ":"雅會/牙慧",
"ㄧㄚㄏㄨㄢ":"丫鬟/鴉鬟",
"ㄧㄚㄏㄨㄤ":"鴨黃/鴉黃",
"ㄧㄚㄐㄧㄚ":"牙家/壓價",
"ㄧㄚㄐㄧㄝ":"押解/雅潔",
"ㄧㄚㄐㄧㄠ":"鴨腳/亞膠",
"ㄧㄚㄐㄧㄡ":"壓酒/啞酒",
"ㄧㄚㄐㄧㄢ":"雅健",
"ㄧㄚㄐㄧㄣ":"押金",
"ㄧㄚㄐㄧㄤ":"牙將",
"ㄧㄚㄐㄧㄥ":"壓境/壓驚",
"ㄧㄚㄐㄩㄝ":"壓覺",
"ㄧㄚㄐㄩㄢ":"壓卷",
"ㄧㄚㄐㄩㄣ":"亞軍",
"ㄧㄚㄑㄧㄠ":"牙橋",
"ㄧㄚㄑㄧㄢ":"牙籤",
"ㄧㄚㄑㄧㄤ":"壓強",
"ㄧㄚㄑㄧㄥ":"壓青/鴉青",
"ㄧㄚㄒㄧㄢ":"壓線/牙線",
"ㄧㄚㄒㄧㄣ":"鴨信",
"ㄧㄚㄒㄧㄥ":"雅興",
"ㄧㄚㄒㄩㄝ":"啞穴",
"ㄧㄚㄒㄩㄣ":"雅馴",
"ㄧㄚㄓㄘㄠ":"鴨跖草",
"ㄧㄚㄓㄡㄗ":"壓軸子",
"ㄧㄚㄓㄨㄚ":"牙爪",
"ㄧㄚㄓㄨㄛ":"壓桌",
"ㄧㄚㄓㄨㄥ":"雅重",
"ㄧㄚㄔㄨㄟ":"牙槌",
"ㄧㄚㄔㄨㄢ":"押船",
"ㄧㄚㄔㄨㄤ":"牙床/押床",
"ㄧㄚㄔㄨㄥ":"牙蟲/蚜蟲",
"ㄧㄚㄕㄨㄚ":"牙刷",
"ㄧㄚㄕㄨㄟ":"牙稅",
"ㄧㄚㄖㄨㄥ":"鴨絨",
"ㄧㄚㄗㄒㄧ":"壓子息",
"ㄧㄚㄗㄓㄙ":"睚眥之私",
"ㄧㄚㄗㄨㄛ":"雅座",
"ㄧㄚㄗㄨㄢ":"牙鑽",
"ㄧㄚㄙㄨㄛ":"壓縮",
"ㄧㄚㄙㄨㄟ":"壓歲/亞歲",
"ㄧㄚㄙㄨㄥ":"押送/雅頌",
"ㄧㄚㄦㄌㄧ":"鴨兒梨",
"ㄧㄚㄧㄉㄠ":"壓衣刀",
"ㄧㄚㄧㄡㄓ":"牙釉質",
"ㄧㄚㄧㄣㄗ":"牙印子",
"ㄧㄝㄅㄧㄥ":"葉柄",
"ㄧㄝㄆㄧㄠ":"野莩",
"ㄧㄝㄆㄧㄢ":"葉片",
"ㄧㄝㄇㄠㄗ":"夜貓子",
"ㄧㄝㄇㄧㄥ":"夜明",
"ㄧㄝㄉㄧㄠ":"椰雕",
"ㄧㄝㄉㄧㄢ":"野店/靨鈿",
"ㄧㄝㄉㄨㄗ":"夜度資",
"ㄧㄝㄊㄧㄢ":"業田",
"ㄧㄝㄊㄧㄥ":"掖庭",
"ㄧㄝㄋㄧㄠ":"野鳥",
"ㄧㄝㄋㄧㄡ":"野牛",
"ㄧㄝㄋㄧㄤ":"爺娘/爺孃",
"ㄧㄝㄌㄚㄕ":"葉蠟石",
"ㄧㄝㄌㄧㄝ":"野獵",
"ㄧㄝㄌㄧㄡ":"野柳",
"ㄧㄝㄌㄧㄢ":"冶煉",
"ㄧㄝㄌㄨㄕ":"野廬氏",
"ㄧㄝㄌㄨㄗ":"野路子",
"ㄧㄝㄌㄨㄣ":"葉輪",
"ㄧㄝㄍㄨㄛ":"業果/液果",
"ㄧㄝㄍㄨㄟ":"業鬼/謁歸",
"ㄧㄝㄍㄨㄤ":"夜光",
"ㄧㄝㄍㄨㄥ":"夜工/冶工",
"ㄧㄝㄎㄨㄥ":"夜空",
"ㄧㄝㄏㄞㄗ":"野孩子",
"ㄧㄝㄏㄢㄗ":"野漢子",
"ㄧㄝㄏㄨㄚ":"野話/野花",
"ㄧㄝㄏㄨㄛ":"野火/業火",
"ㄧㄝㄐㄧㄚ":"鄴架",
"ㄧㄝㄐㄧㄝ":"業界",
"ㄧㄝㄐㄧㄢ":"夜間/謁見",
"ㄧㄝㄐㄧㄣ":"夜緊/冶金",
"ㄧㄝㄐㄧㄥ":"夜景/業經",
"ㄧㄝㄑㄧㄠ":"葉鞘",
"ㄧㄝㄑㄧㄣ":"夜勤",
"ㄧㄝㄑㄧㄥ":"野情",
"ㄧㄝㄑㄩㄢ":"野犬",
"ㄧㄝㄒㄧㄚ":"腋下",
"ㄧㄝㄒㄧㄠ":"夜校/夜消",
"ㄧㄝㄒㄧㄢ":"也先/掖縣",
"ㄧㄝㄒㄧㄣ":"野心/頁心",
"ㄧㄝㄒㄧㄤ":"業相",
"ㄧㄝㄒㄧㄥ":"野性/也行",
"ㄧㄝㄒㄩㄝ":"夜學",
"ㄧㄝㄒㄩㄢ":"謁選",
"ㄧㄝㄓㄇㄚ":"野芝麻",
"ㄧㄝㄓㄨㄥ":"野種/業種",
"ㄧㄝㄔㄤㄗ":"野場子",
"ㄧㄝㄔㄨㄟ":"野炊",
"ㄧㄝㄔㄨㄣ":"夜春",
"ㄧㄝㄕㄔㄤ":"夜市場",
"ㄧㄝㄖㄨㄥ":"冶容",
"ㄧㄝㄗㄍㄜ":"葉子格",
"ㄧㄝㄗㄒㄧ":"葉子戲",
"ㄧㄝㄗㄧㄡ":"椰子油",
"ㄧㄝㄗㄧㄢ":"葉子菸",
"ㄧㄝㄗㄨㄛ":"夜作",
"ㄧㄝㄘㄍㄨ":"野茨菰",
"ㄧㄝㄙㄨㄛ":"謁索",
"ㄧㄝㄙㄨㄢ":"葉酸",
"ㄧㄝㄧㄡㄗ":"夜遊子",
"ㄧㄝㄨㄩㄢ":"業務員",
"ㄧㄞㄅㄧㄢ":"崖邊",
"ㄧㄞㄌㄩㄝ":"崖略",
"ㄧㄞㄐㄧㄢ":"崖檢",
"ㄧㄞㄒㄧㄢ":"崖縣",
"ㄧㄠㄅㄚㄗ":"腰把子",
"ㄧㄠㄅㄧㄢ":"窯變",
"ㄧㄠㄅㄧㄥ":"藥餅/耀兵",
"ㄧㄠㄆㄧㄢ":"藥片/么篇",
"ㄧㄠㄆㄧㄣ":"藥品",
"ㄧㄠㄇㄧㄠ":"要妙/要眇",
"ㄧㄠㄇㄧㄢ":"藥棉",
"ㄧㄠㄇㄧㄥ":"要命/窈冥",
"ㄧㄠㄉㄚㄗ":"腰褡子",
"ㄧㄠㄉㄧㄝ":"要絰",
"ㄧㄠㄉㄧㄠ":"妖調",
"ㄧㄠㄉㄧㄢ":"要點/藥典",
"ㄧㄠㄉㄧㄥ":"咬定/藥錠",
"ㄧㄠㄉㄨㄢ":"要端",
"ㄧㄠㄉㄨㄥ":"搖動/窯洞",
"ㄧㄠㄊㄧㄝ":"藥貼",
"ㄧㄠㄊㄧㄠ":"窈窕/窅窱",
"ㄧㄠㄊㄧㄢ":"堯天",
"ㄧㄠㄊㄨㄟ":"腰腿",
"ㄧㄠㄊㄨㄥ":"藥筒",
"ㄧㄠㄋㄧㄝ":"妖孽",
"ㄧㄠㄋㄧㄠ":"要褭/騕褭",
"ㄧㄠㄋㄧㄢ":"堯年/遙念",
"ㄧㄠㄋㄧㄤ":"窅娘",
"ㄧㄠㄋㄨㄛ":"夭娜/窈娜",
"ㄧㄠㄋㄨㄥ":"藥農",
"ㄧㄠㄌㄧㄠ":"窔遼",
"ㄧㄠㄌㄧㄡ":"邀留",
"ㄧㄠㄌㄧㄢ":"要臉",
"ㄧㄠㄌㄧㄤ":"藥量",
"ㄧㄠㄌㄧㄥ":"要領/要領",
"ㄧㄠㄌㄨㄛ":"搖落",
"ㄧㄠㄌㄨㄢ":"淆亂/殽亂",
"ㄧㄠㄌㄩㄝ":"要略",
"ㄧㄠㄍㄢㄗ":"腰桿子",
"ㄧㄠㄍㄨㄛ":"腰果/腰鍋",
"ㄧㄠㄍㄨㄞ":"妖怪",
"ㄧㄠㄍㄨㄟ":"腰櫃",
"ㄧㄠㄍㄨㄢ":"嶢關/鑰管",
"ㄧㄠㄍㄨㄣ":"搖滾",
"ㄧㄠㄍㄨㄤ":"搖光/瑤光",
"ㄧㄠㄍㄨㄥ":"邀功/要公",
"ㄧㄠㄎㄨㄚ":"腰胯",
"ㄧㄠㄎㄨㄥ":"遙控",
"ㄧㄠㄏㄨㄚ":"腰花/瑤華",
"ㄧㄠㄏㄨㄛ":"淆惑",
"ㄧㄠㄏㄨㄟ":"搖會/邀會",
"ㄧㄠㄏㄨㄣ":"夭昏",
"ㄧㄠㄏㄨㄤ":"搖晃/要荒",
"ㄧㄠㄏㄨㄥ":"夭紅",
"ㄧㄠㄐㄧㄕ":"藥劑師",
"ㄧㄠㄐㄧㄚ":"要價",
"ㄧㄠㄐㄧㄝ":"要結/要劫",
"ㄧㄠㄐㄧㄠ":"要角/夭矯",
"ㄧㄠㄐㄧㄡ":"藥酒/堯韭",
"ㄧㄠㄐㄧㄢ":"要件/遙見",
"ㄧㄠㄐㄧㄣ":"要緊/要近",
"ㄧㄠㄐㄧㄤ":"姚江",
"ㄧㄠㄐㄧㄥ":"妖精/妖鏡",
"ㄧㄠㄐㄩㄝ":"要訣/咬嚼",
"ㄧㄠㄐㄩㄣ":"遙郡",
"ㄧㄠㄑㄧㄝ":"要切",
"ㄧㄠㄑㄧㄡ":"要求/邀求",
"ㄧㄠㄑㄧㄢ":"要錢/藥籤",
"ㄧㄠㄑㄧㄣ":"瑤琴",
"ㄧㄠㄑㄧㄤ":"要強",
"ㄧㄠㄑㄧㄥ":"邀請",
"ㄧㄠㄑㄩㄣ":"咬群",
"ㄧㄠㄒㄧㄚ":"夭邪/穾廈",
"ㄧㄠㄒㄧㄝ":"要脅/要挾",
"ㄧㄠㄒㄧㄠ":"藥效",
"ㄧㄠㄒㄧㄡ":"餚羞",
"ㄧㄠㄒㄧㄢ":"藥線/么絃",
"ㄧㄠㄒㄧㄣ":"搖心/堯心",
"ㄧㄠㄒㄧㄤ":"要項/爻象",
"ㄧㄠㄒㄧㄥ":"藥性/妖星",
"ㄧㄠㄒㄩㄝ":"藥學",
"ㄧㄠㄓㄨㄛ":"謠諑",
"ㄧㄠㄓㄨㄟ":"腰椎",
"ㄧㄠㄓㄨㄢ":"餚饌/殽饌",
"ㄧㄠㄓㄨㄤ":"搖裝",
"ㄧㄠㄔㄨㄟ":"搖槌/爻槌",
"ㄧㄠㄔㄨㄢ":"謠傳/搖船",
"ㄧㄠㄔㄨㄣ":"咬春",
"ㄧㄠㄔㄨㄥ":"要衝",
"ㄧㄠㄕㄈㄛ":"藥師佛",
"ㄧㄠㄕㄜㄗ":"咬舌子",
"ㄧㄠㄕㄜㄦ":"咬舌兒",
"ㄧㄠㄕㄨㄟ":"藥水/徭稅",
"ㄧㄠㄕㄨㄣ":"堯舜",
"ㄧㄠㄗㄨㄟ":"咬嘴",
"ㄧㄠㄙㄨㄟ":"姚燧",
"ㄧㄠㄧㄣㄗ":"藥引子",
"ㄧㄠㄨㄛㄦ":"腰窩兒",
"ㄧㄡㄅㄧㄠ":"游標",
"ㄧㄡㄅㄧㄢ":"右邊",
"ㄧㄡㄅㄧㄥ":"有病/油餅",
"ㄧㄡㄆㄛㄧ":"優婆夷",
"ㄧㄡㄆㄧㄠ":"郵票",
"ㄧㄡㄆㄧㄢ":"有偏/郵片",
"ㄧㄡㄆㄧㄣ":"油品",
"ㄧㄡㄆㄧㄥ":"油瓶/油軿",
"ㄧㄡㄆㄨㄦ":"有譜兒",
"ㄧㄡㄇㄧㄠ":"油苗/幼苗",
"ㄧㄡㄇㄧㄡ":"悠謬",
"ㄧㄡㄇㄧㄢ":"油麵/宥免",
"ㄧㄡㄇㄧㄣ":"遊民/游民",
"ㄧㄡㄇㄧㄥ":"有名/有命",
"ㄧㄡㄇㄨㄗ":"有模子",
"ㄧㄡㄈㄤㄓ":"油倣紙",
"ㄧㄡㄉㄜㄕ":"有的是",
"ㄧㄡㄉㄧㄢ":"有點/優點",
"ㄧㄡㄉㄨㄛ":"遊惰",
"ㄧㄡㄉㄨㄢ":"右端",
"ㄧㄡㄊㄡㄦ":"有頭兒",
"ㄧㄡㄊㄧㄠ":"油條",
"ㄧㄡㄊㄧㄢ":"油田/幽天",
"ㄧㄡㄊㄧㄥ":"遊艇/悠停",
"ㄧㄡㄊㄨㄛ":"幼托",
"ㄧㄡㄊㄨㄥ":"幼童/郵筒",
"ㄧㄡㄋㄧㄝ":"由櫱",
"ㄧㄡㄋㄧㄢ":"幼年/有年",
"ㄧㄡㄌㄠㄦ":"有落兒",
"ㄧㄡㄌㄧㄕ":"有理式",
"ㄧㄡㄌㄧㄝ":"優劣/右列",
"ㄧㄡㄌㄧㄠ":"油料",
"ㄧㄡㄌㄧㄡ":"幽流",
"ㄧㄡㄌㄧㄢ":"有臉",
"ㄧㄡㄌㄧㄣ":"幼林",
"ㄧㄡㄌㄧㄤ":"優良/有量",
"ㄧㄡㄌㄧㄥ":"幽靈/有零",
"ㄧㄡㄌㄨㄛ":"油絡",
"ㄧㄡㄌㄨㄣ":"郵輪/油輪",
"ㄧㄡㄌㄨㄥ":"游龍/猶龍",
"ㄧㄡㄍㄨㄗ":"猶古自",
"ㄧㄡㄍㄨㄛ":"憂國/油鍋",
"ㄧㄡㄍㄨㄞ":"誘拐",
"ㄧㄡㄍㄨㄟ":"油鬼",
"ㄧㄡㄍㄨㄢ":"有關/攸關",
"ㄧㄡㄍㄨㄤ":"油光/右廣",
"ㄧㄡㄍㄨㄥ":"有功/幼功",
"ㄧㄡㄎㄨㄟ":"憂葵",
"ㄧㄡㄎㄨㄤ":"油礦/鈾礦",
"ㄧㄡㄎㄨㄥ":"有空/猶恐",
"ㄧㄡㄏㄠㄦ":"有好兒",
"ㄧㄡㄏㄨㄚ":"油畫/油花",
"ㄧㄡㄏㄨㄛ":"誘惑",
"ㄧㄡㄏㄨㄟ":"優惠/有會",
"ㄧㄡㄏㄨㄢ":"憂患/游宦",
"ㄧㄡㄏㄨㄣ":"遊魂/幽魂",
"ㄧㄡㄏㄨㄤ":"幽篁",
"ㄧㄡㄐㄧㄓ":"有機質",
"ㄧㄡㄐㄧㄚ":"有家/優假",
"ㄧㄡㄐㄧㄝ":"遊街",
"ㄧㄡㄐㄧㄠ":"幼教/優角",
"ㄧㄡㄐㄧㄡ":"有救/悠久",
"ㄧㄡㄐㄧㄢ":"有間/郵件",
"ㄧㄡㄐㄧㄣ":"有勁/幽禁",
"ㄧㄡㄐㄧㄤ":"優獎",
"ㄧㄡㄐㄧㄥ":"幽靜/油井",
"ㄧㄡㄐㄧㄨ":"有機物",
"ㄧㄡㄐㄩㄝ":"幽絕",
"ㄧㄡㄐㄩㄣ":"友軍/右軍",
"ㄧㄡㄑㄧㄠ":"有竅",
"ㄧㄡㄑㄧㄡ":"有秋/幽囚",
"ㄧㄡㄑㄧㄢ":"有錢",
"ㄧㄡㄑㄧㄥ":"友情/有請",
"ㄧㄡㄑㄩㄝ":"憂闕",
"ㄧㄡㄑㄩㄣ":"油裙",
"ㄧㄡㄑㄩㄥ":"有窮",
"ㄧㄡㄒㄧㄚ":"有夏/優洽",
"ㄧㄡㄒㄧㄝ":"有些/油鞋",
"ㄧㄡㄒㄧㄠ":"有效/有孝",
"ㄧㄡㄒㄧㄡ":"優秀",
"ㄧㄡㄒㄧㄢ":"有限/優先",
"ㄧㄡㄒㄧㄣ":"有心/憂心",
"ㄧㄡㄒㄧㄤ":"郵箱/油香",
"ㄧㄡㄒㄧㄥ":"遊行/有幸",
"ㄧㄡㄒㄩㄝ":"遊學/右學",
"ㄧㄡㄒㄩㄢ":"輶軒",
"ㄧㄡㄒㄩㄣ":"由旬/憂尋",
"ㄧㄡㄒㄩㄥ":"有熊/幽敻",
"ㄧㄡㄓㄏㄨ":"猶之乎",
"ㄧㄡㄓㄓㄕ":"有志之士",
"ㄧㄡㄓㄕㄢ":"油紙扇",
"ㄧㄡㄓㄙㄢ":"油紙傘",
"ㄧㄡㄓㄚㄗ":"油渣子",
"ㄧㄡㄓㄨㄛ":"油棹",
"ㄧㄡㄓㄨㄢ":"右轉",
"ㄧㄡㄓㄨㄥ":"有種/由衷",
"ㄧㄡㄓㄩㄢ":"幼稚園",
"ㄧㄡㄔㄚㄦ":"有碴兒",
"ㄧㄡㄔㄠㄕ":"有巢氏",
"ㄧㄡㄔㄨㄛ":"憂惙/郵戳",
"ㄧㄡㄔㄨㄢ":"郵傳/遊船",
"ㄧㄡㄔㄨㄥ":"幼蟲",
"ㄧㄡㄕㄏㄡ":"有時候",
"ㄧㄡㄕㄓㄕ":"有識之士",
"ㄧㄡㄕㄨㄟ":"游水/遊說",
"ㄧㄡㄕㄨㄦ":"有數兒",
"ㄧㄡㄖㄨㄛ":"有若/猶若",
"ㄧㄡㄖㄨㄥ":"優容",
"ㄧㄡㄗㄆㄞ":"酉字牌",
"ㄧㄡㄗㄧㄣ":"遊子吟",
"ㄧㄡㄗㄨㄟ":"油嘴",
"ㄧㄡㄗㄨㄥ":"遊蹤/油棕",
"ㄧㄡㄘㄨㄣ":"猶存",
"ㄧㄡㄘㄨㄥ":"幽叢",
"ㄧㄡㄙㄔㄜ":"有司徹",
"ㄧㄡㄙㄨㄟ":"幽邃",
"ㄧㄡㄙㄨㄢ":"油酸",
"ㄧㄡㄙㄨㄥ":"有娀/油松",
"ㄧㄡㄙㄨㄦ":"有死無二",
"ㄧㄡㄧㄊㄠ":"有一套",
"ㄧㄡㄧㄕㄡ":"有一手",
"ㄧㄡㄧㄙㄞ":"友誼賽",
"ㄧㄡㄧㄤㄗ":"有樣子",
"ㄧㄡㄧㄨㄦ":"有一無二",
"ㄧㄡㄧㄨㄧ":"有意無意",
"ㄧㄡㄨㄐㄩ":"有無句",
"ㄧㄡㄨㄖㄢ":"油汙染",
"ㄧㄡㄨㄩㄢ":"郵務員",
"ㄧㄡㄩㄓㄥ":"憂鬱症",
"ㄧㄡㄩㄥㄔ":"游泳池",
"ㄧㄡㄩㄥㄧ":"游泳衣",
"ㄧㄢㄅㄧㄢ":"演變/延邊",
"ㄧㄢㄅㄧㄣ":"延賓",
"ㄧㄢㄅㄧㄥ":"厭兵",
"ㄧㄢㄆㄧㄗ":"眼皮子",
"ㄧㄢㄆㄧㄠ":"驗票/鹽票",
"ㄧㄢㄆㄧㄣ":"贗品/延聘",
"ㄧㄢㄇㄧㄝ":"淹滅",
"ㄧㄢㄇㄧㄠ":"煙苗",
"ㄧㄢㄇㄧㄢ":"顏面/掩面",
"ㄧㄢㄇㄧㄥ":"言明/驗明",
"ㄧㄢㄉㄠㄗ":"咽刀子",
"ㄧㄢㄉㄡㄙ":"菸斗絲",
"ㄧㄢㄉㄧㄠ":"唁弔",
"ㄧㄢㄉㄧㄢ":"眼點",
"ㄧㄢㄉㄧㄥ":"研訂/贗鼎",
"ㄧㄢㄉㄨㄟ":"言對",
"ㄧㄢㄉㄨㄢ":"豔段/焰段",
"ㄧㄢㄉㄨㄣ":"厭鈍",
"ㄧㄢㄉㄨㄥ":"嚴冬/岩洞",
"ㄧㄢㄊㄞㄕ":"煙臺市",
"ㄧㄢㄊㄧㄠ":"眼跳/延眺",
"ㄧㄢㄊㄧㄢ":"炎天/鹽田",
"ㄧㄢㄊㄧㄥ":"蝘蜓",
"ㄧㄢㄊㄨㄛ":"咽唾/嚥唾",
"ㄧㄢㄊㄨㄟ":"延退",
"ㄧㄢㄊㄨㄥ":"眼同/淹通",
"ㄧㄢㄋㄧㄢ":"延年",
"ㄧㄢㄋㄨㄢ":"妍暖",
"ㄧㄢㄋㄨㄥ":"菸農",
"ㄧㄢㄌㄧㄠ":"顏料",
"ㄧㄢㄌㄧㄡ":"淹留/顏柳",
"ㄧㄢㄌㄧㄢ":"眼簾/演練",
"ㄧㄢㄌㄧㄤ":"炎涼",
"ㄧㄢㄌㄧㄥ":"咽領/延陵",
"ㄧㄢㄌㄨㄛ":"閻羅",
"ㄧㄢㄌㄨㄣ":"言論",
"ㄧㄢㄍㄨㄛ":"煙鍋",
"ㄧㄢㄍㄨㄟ":"煙鬼/琰圭",
"ㄧㄢㄍㄨㄢ":"言官/煙館",
"ㄧㄢㄍㄨㄤ":"眼光/驗光",
"ㄧㄢㄎㄨㄢ":"延款",
"ㄧㄢㄎㄨㄤ":"眼眶",
"ㄧㄢㄎㄨㄥ":"眼孔",
"ㄧㄢㄏㄨㄚ":"煙花/演化",
"ㄧㄢㄏㄨㄛ":"煙火/炎火",
"ㄧㄢㄏㄨㄟ":"煙灰/宴會",
"ㄧㄢㄏㄨㄢ":"煙鬟/延緩",
"ㄧㄢㄏㄨㄤ":"炎黃/演幌",
"ㄧㄢㄏㄨㄥ":"眼紅/殷紅",
"ㄧㄢㄐㄧㄚ":"演架/嚴駕",
"ㄧㄢㄐㄧㄝ":"眼界/延接",
"ㄧㄢㄐㄧㄠ":"眼角/言教",
"ㄧㄢㄐㄧㄡ":"研究/菸酒",
"ㄧㄢㄐㄧㄢ":"宴見/眼尖",
"ㄧㄢㄐㄧㄣ":"嚴謹/嚴禁",
"ㄧㄢㄐㄧㄤ":"演講/岩漿",
"ㄧㄢㄐㄧㄥ":"眼鏡/眼睛",
"ㄧㄢㄐㄩㄝ":"言絕/豔絕",
"ㄧㄢㄐㄩㄢ":"厭倦/掩卷",
"ㄧㄢㄐㄩㄣ":"嚴峻/嚴君",
"ㄧㄢㄑㄧㄝ":"嚴切",
"ㄧㄢㄑㄧㄠ":"鹽橋",
"ㄧㄢㄑㄧㄡ":"眼球/炎丘",
"ㄧㄢㄑㄧㄢ":"眼前/妍蒨",
"ㄧㄢㄑㄧㄣ":"嚴親/演禽",
"ㄧㄢㄑㄧㄤ":"煙槍",
"ㄧㄢㄑㄧㄥ":"豔情/延請",
"ㄧㄢㄑㄩㄝ":"燕雀/鴳雀",
"ㄧㄢㄑㄩㄢ":"眼圈/言泉",
"ㄧㄢㄑㄩㄥ":"研窮",
"ㄧㄢㄒㄧㄚ":"炎夏/眼下",
"ㄧㄢㄒㄧㄝ":"驗血/晏歇",
"ㄧㄢㄒㄧㄠ":"言笑/鹽梟",
"ㄧㄢㄒㄧㄡ":"研修/掩袖",
"ㄧㄢㄒㄧㄢ":"沿線/眼線",
"ㄧㄢㄒㄧㄣ":"岩心/焰心",
"ㄧㄢㄒㄧㄤ":"宴饗",
"ㄧㄢㄒㄧㄥ":"言行/嚴刑",
"ㄧㄢㄒㄩㄝ":"巖穴",
"ㄧㄢㄒㄩㄣ":"煙燻",
"ㄧㄢㄒㄩㄥ":"硯兄",
"ㄧㄢㄓㄏㄨ":"胭脂虎",
"ㄧㄢㄓㄕㄢ":"焉支山",
"ㄧㄢㄓㄨㄗ":"眼珠子",
"ㄧㄢㄓㄨㄛ":"眼拙",
"ㄧㄢㄓㄨㄤ":"言狀/嚴裝",
"ㄧㄢㄓㄨㄥ":"嚴重/言重",
"ㄧㄢㄓㄨㄦ":"眼珠兒",
"ㄧㄢㄓㄨㄨ":"言之無物",
"ㄧㄢㄔㄨㄢ":"言傳/眼穿",
"ㄧㄢㄔㄨㄣ":"齞脣",
"ㄧㄢㄔㄨㄤ":"岩床",
"ㄧㄢㄔㄨㄥ":"眼蟲",
"ㄧㄢㄕㄈㄢ":"嚴世蕃",
"ㄧㄢㄕㄍㄨ":"顏師古",
"ㄧㄢㄕㄓㄥ":"厭食症",
"ㄧㄢㄕㄥㄨ":"衍生物",
"ㄧㄢㄕㄨㄛ":"演說/言說",
"ㄧㄢㄕㄨㄟ":"鹽水/鹽水",
"ㄧㄢㄕㄨㄤ":"嚴霜/鹽霜",
"ㄧㄢㄖㄨㄣ":"淹潤",
"ㄧㄢㄖㄨㄥ":"岩溶/沇溶",
"ㄧㄢㄖㄨㄩ":"顏如玉",
"ㄧㄢㄗㄌㄡ":"燕子樓",
"ㄧㄢㄗㄐㄧ":"燕子磯",
"ㄧㄢㄗㄕㄨ":"鹽漬食物",
"ㄧㄢㄗㄘㄞ":"眼子菜",
"ㄧㄢㄗㄨㄛ":"驗左/宴坐",
"ㄧㄢㄗㄨㄣ":"嚴遵",
"ㄧㄢㄗㄩㄢ":"顏子淵",
"ㄧㄢㄘㄨㄛ":"眼挫",
"ㄧㄢㄘㄨㄢ":"煙爨",
"ㄧㄢㄘㄨㄥ":"煙囪/彥琮",
"ㄧㄢㄙㄨㄟ":"煙穗/延髓",
"ㄧㄢㄙㄨㄢ":"演算/驗算",
"ㄧㄢㄙㄨㄣ":"罨損",
"ㄧㄢㄙㄨㄥ":"嚴嵩/偃松",
"ㄧㄢㄦㄩㄗ":"言兒語子",
"ㄧㄢㄧㄇㄚ":"一鞍一馬",
"ㄧㄢㄧㄝㄜ":"煙夜蛾",
"ㄧㄢㄧㄢㄓ":"顏延之",
"ㄧㄢㄩㄌㄡ":"煙雨樓",
"ㄧㄢㄩㄣㄕ":"鹽運使",
"ㄧㄣㄅㄚㄗ":"印把子",
"ㄧㄣㄅㄧㄠ":"音標",
"ㄧㄣㄅㄧㄢ":"音變",
"ㄧㄣㄅㄧㄣ":"寅賓",
"ㄧㄣㄅㄧㄥ":"飲冰/引兵",
"ㄧㄣㄆㄧㄠ":"引票/銀票",
"ㄧㄣㄆㄧㄢ":"飲片",
"ㄧㄣㄆㄧㄣ":"音品/音頻",
"ㄧㄣㄆㄧㄥ":"陰平/銀瓶",
"ㄧㄣㄇㄧㄝ":"堙滅/湮滅",
"ㄧㄣㄇㄧㄠ":"陰廟/銀苗",
"ㄧㄣㄇㄧㄣ":"隱閔",
"ㄧㄣㄇㄧㄥ":"音名/因明",
"ㄧㄣㄉㄧㄠ":"音調/陰調",
"ㄧㄣㄉㄧㄢ":"陰電",
"ㄧㄣㄉㄧㄥ":"銀錠",
"ㄧㄣㄉㄨㄟ":"引對",
"ㄧㄣㄉㄨㄣ":"銀盾/隱遁",
"ㄧㄣㄉㄨㄥ":"引動",
"ㄧㄣㄊㄡㄦ":"癮頭兒",
"ㄧㄣㄊㄧㄠ":"引調",
"ㄧㄣㄊㄧㄢ":"陰天",
"ㄧㄣㄊㄧㄥ":"隱聽/陰挺",
"ㄧㄣㄊㄨㄟ":"引退/隱退",
"ㄧㄣㄊㄨㄥ":"殷同/姻通",
"ㄧㄣㄋㄧㄡ":"印鈕",
"ㄧㄣㄋㄧㄢ":"引年",
"ㄧㄣㄌㄧㄗ":"陰離子",
"ㄧㄣㄌㄧㄠ":"飲料",
"ㄧㄣㄌㄧㄡ":"引流",
"ㄧㄣㄌㄧㄢ":"蚓廉",
"ㄧㄣㄌㄧㄣ":"隱轔/殷轔",
"ㄧㄣㄌㄧㄤ":"陰涼/音量",
"ㄧㄣㄌㄧㄥ":"引領/陰令",
"ㄧㄣㄌㄨㄛ":"陰絡",
"ㄧㄣㄌㄨㄢ":"淫亂",
"ㄧㄣㄌㄨㄣ":"隱淪",
"ㄧㄣㄍㄨㄚ":"隱括/檃栝",
"ㄧㄣㄍㄨㄛ":"因果/因國",
"ㄧㄣㄍㄨㄟ":"音軌/陰鬼",
"ㄧㄣㄍㄨㄢ":"銀管/陰官",
"ㄧㄣㄍㄨㄥ":"陰功/隱宮",
"ㄧㄣㄎㄨㄤ":"銀礦",
"ㄧㄣㄎㄨㄥ":"陰空",
"ㄧㄣㄏㄨㄚ":"印花/音畫",
"ㄧㄣㄏㄨㄛ":"引火/陰火",
"ㄧㄣㄏㄨㄟ":"隱晦/音徽",
"ㄧㄣㄏㄨㄢ":"陰緩/隱患",
"ㄧㄣㄏㄨㄣ":"銀婚/陰魂",
"ㄧㄣㄏㄨㄤ":"銀黃/銀潢",
"ㄧㄣㄏㄨㄥ":"銀紅",
"ㄧㄣㄐㄧㄚ":"姻家/銀價",
"ㄧㄣㄐㄧㄝ":"音節/印結",
"ㄧㄣㄐㄧㄠ":"陰教/銀角",
"ㄧㄣㄐㄧㄡ":"飲酒/姻舊",
"ㄧㄣㄐㄧㄢ":"陰間/印鑑",
"ㄧㄣㄐㄧㄣ":"引進",
"ㄧㄣㄐㄧㄤ":"銀匠",
"ㄧㄣㄐㄧㄥ":"陰精/引頸",
"ㄧㄣㄐㄩㄝ":"引決",
"ㄧㄣㄐㄩㄢ":"姻眷",
"ㄧㄣㄐㄩㄣ":"引軍",
"ㄧㄣㄑㄧㄝ":"殷切",
"ㄧㄣㄑㄧㄠ":"引橋/淫巧",
"ㄧㄣㄑㄧㄢ":"銀錢/陰譴",
"ㄧㄣㄑㄧㄣ":"殷勤/隱親",
"ㄧㄣㄑㄧㄤ":"音強",
"ㄧㄣㄑㄧㄥ":"引擎/隱情",
"ㄧㄣㄑㄩㄝ":"銀闕",
"ㄧㄣㄑㄩㄥ":"吟蛩",
"ㄧㄣㄒㄧㄝ":"淫褻/隱血",
"ㄧㄣㄒㄧㄠ":"音效/陰消",
"ㄧㄣㄒㄧㄡ":"隱秀",
"ㄧㄣㄒㄧㄢ":"陰險/隱現",
"ㄧㄣㄒㄧㄣ":"音信/引信",
"ㄧㄣㄒㄧㄤ":"印象/音響",
"ㄧㄣㄒㄧㄥ":"隱形/銀杏",
"ㄧㄣㄒㄩㄝ":"音學/淫學",
"ㄧㄣㄒㄩㄣ":"音訊/因循",
"ㄧㄣㄒㄩㄥ":"姻兄",
"ㄧㄣㄓㄠㄦ":"陰著兒",
"ㄧㄣㄓㄨㄟ":"音綴",
"ㄧㄣㄓㄨㄢ":"飲饌",
"ㄧㄣㄓㄨㄣ":"音準",
"ㄧㄣㄓㄨㄤ":"陰狀",
"ㄧㄣㄓㄨㄥ":"音重/引重",
"ㄧㄣㄔㄨㄢ":"印串",
"ㄧㄣㄔㄨㄣ":"陰脣",
"ㄧㄣㄔㄨㄤ":"印床",
"ㄧㄣㄕㄈㄨ":"陰時夫",
"ㄧㄣㄕㄓㄧ":"因時制宜",
"ㄧㄣㄕㄜㄗ":"銀揲子",
"ㄧㄣㄕㄣㄧ":"引申義",
"ㄧㄣㄕㄨㄚ":"印刷",
"ㄧㄣㄕㄨㄟ":"飲水/引水",
"ㄧㄣㄕㄨㄢ":"音栓",
"ㄧㄣㄖㄨㄥ":"音容",
"ㄧㄣㄗㄨㄛ":"銀座",
"ㄧㄣㄘㄕㄤ":"因此上",
"ㄧㄣㄙㄨㄟ":"陰燧",
"ㄧㄣㄙㄨㄥ":"吟誦/嚚訟",
"ㄧㄣㄧㄤㄧ":"陰陽移",
"ㄧㄣㄨㄣㄗ":"尹文子",
"ㄧㄣㄩㄕㄧ":"引喻失義",
"ㄧㄤㄅㄟㄕ":"羊背石",
"ㄧㄤㄅㄠㄗ":"洋包子",
"ㄧㄤㄅㄧㄢ":"揚鞭",
"ㄧㄤㄅㄧㄥ":"養病/養兵",
"ㄧㄤㄆㄧㄓ":"羊皮紙",
"ㄧㄤㄆㄧㄣ":"樣品",
"ㄧㄤㄆㄧㄥ":"陽平",
"ㄧㄤㄇㄚㄗ":"洋碼子",
"ㄧㄤㄇㄚㄦ":"洋馬兒",
"ㄧㄤㄇㄠㄓ":"羊毛脂",
"ㄧㄤㄇㄧㄠ":"秧苗",
"ㄧㄤㄇㄧㄢ":"陽面/仰面",
"ㄧㄤㄇㄧㄣ":"殃民",
"ㄧㄤㄇㄧㄥ":"揚名/陽明",
"ㄧㄤㄉㄧㄠ":"陽調",
"ㄧㄤㄉㄧㄢ":"陽電",
"ㄧㄤㄉㄨㄢ":"洋緞",
"ㄧㄤㄊㄧㄝ":"洋鐵",
"ㄧㄤㄊㄧㄢ":"仰天/秧田",
"ㄧㄤㄊㄨㄛ":"央託/羊駝",
"ㄧㄤㄋㄞㄨ":"楊乃武",
"ㄧㄤㄋㄧㄠ":"陽鳥",
"ㄧㄤㄋㄧㄤ":"養娘",
"ㄧㄤㄌㄧㄗ":"陽離子",
"ㄧㄤㄌㄧㄠ":"養料",
"ㄧㄤㄌㄧㄡ":"洋流/楊柳",
"ㄧㄤㄌㄧㄢ":"養廉/楊漣",
"ㄧㄤㄌㄧㄥ":"揚靈/秧齡",
"ㄧㄤㄍㄨㄢ":"陽關/洋關",
"ㄧㄤㄍㄨㄤ":"陽光/楊廣",
"ㄧㄤㄍㄨㄥ":"羊工/佯攻",
"ㄧㄤㄎㄨㄤ":"陽狂/佯狂",
"ㄧㄤㄏㄨㄚ":"氧化/洋話",
"ㄧㄤㄏㄨㄛ":"養活/洋火",
"ㄧㄤㄏㄨㄞ":"洋槐",
"ㄧㄤㄏㄨㄟ":"養會/洋灰",
"ㄧㄤㄏㄨㄢ":"楊喚",
"ㄧㄤㄏㄨㄥ":"洋紅",
"ㄧㄤㄐㄧㄚ":"養家",
"ㄧㄤㄐㄧㄠ":"洋叫/仰角",
"ㄧㄤㄐㄧㄡ":"洋酒/陽九",
"ㄧㄤㄐㄧㄢ":"陽間/楊堅",
"ㄧㄤㄐㄧㄣ":"洋金",
"ㄧㄤㄐㄧㄥ":"養靜/陽景",
"ㄧㄤㄐㄩㄣ":"養軍",
"ㄧㄤㄑㄧㄕ":"陽起石",
"ㄧㄤㄑㄧㄡ":"央求/仰求",
"ㄧㄤㄑㄧㄢ":"洋錢",
"ㄧㄤㄑㄧㄣ":"洋琴/揚琴",
"ㄧㄤㄑㄧㄤ":"洋槍/央戧",
"ㄧㄤㄑㄧㄥ":"央請",
"ㄧㄤㄑㄩㄝ":"揚搉",
"ㄧㄤㄒㄧㄡ":"楊修",
"ㄧㄤㄒㄧㄢ":"陽羨",
"ㄧㄤㄒㄧㄣ":"羊欣",
"ㄧㄤㄒㄧㄤ":"洋相",
"ㄧㄤㄒㄧㄥ":"陽性/養性",
"ㄧㄤㄒㄩㄥ":"揚雄",
"ㄧㄤㄓㄅㄞ":"羊脂白",
"ㄧㄤㄓㄓㄨ":"羊躑躅",
"ㄧㄤㄓㄔㄤ":"養殖場",
"ㄧㄤㄓㄨㄛ":"養拙",
"ㄧㄤㄓㄨㄤ":"洋裝/佯裝",
"ㄧㄤㄓㄨㄥ":"陽中/央中",
"ㄧㄤㄔㄞㄗ":"樣冊子",
"ㄧㄤㄔㄨㄣ":"陽春",
"ㄧㄤㄔㄨㄥ":"恙蟲",
"ㄧㄤㄕㄨㄛ":"陽朔",
"ㄧㄤㄕㄨㄟ":"羊水/揚水",
"ㄧㄤㄗㄋㄩ":"養子女",
"ㄧㄤㄗㄨㄟ":"洋罪",
"ㄧㄤㄘㄨㄥ":"洋蔥",
"ㄧㄤㄙㄤㄗ":"洋嗓子",
"ㄧㄤㄙㄨㄟ":"陽燧",
"ㄧㄤㄦㄈㄥ":"羊兒風",
"ㄧㄤㄩㄌㄢ":"洋玉蘭",
"ㄧㄥㄅㄧㄠ":"瀛表",
"ㄧㄥㄅㄧㄢ":"應變",
"ㄧㄥㄅㄧㄣ":"迎賓",
"ㄧㄥㄅㄧㄥ":"硬病",
"ㄧㄥㄆㄧㄢ":"影片",
"ㄧㄥㄆㄧㄣ":"應聘",
"ㄧㄥㄆㄧㄥ":"影評/熒屏",
"ㄧㄥㄇㄧㄢ":"迎面/硬麵",
"ㄧㄥㄇㄧㄣ":"穎敏",
"ㄧㄥㄇㄧㄥ":"英明/應名",
"ㄧㄥㄉㄧㄝ":"影碟/硬碟",
"ㄧㄥㄉㄧㄠ":"影調",
"ㄧㄥㄉㄧㄢ":"應典",
"ㄧㄥㄉㄨㄟ":"應對/營隊",
"ㄧㄥㄉㄨㄣ":"英噸",
"ㄧㄥㄉㄨㄥ":"楹棟",
"ㄧㄥㄊㄡㄦ":"迎頭兒",
"ㄧㄥㄊㄧㄢ":"營田",
"ㄧㄥㄊㄧㄥ":"英挺/硬挺",
"ㄧㄥㄊㄨㄛ":"穎脫",
"ㄧㄥㄋㄧㄢ":"英年/迎年",
"ㄧㄥㄋㄧㄥ":"攖寧/嚶嚀",
"ㄧㄥㄋㄨㄛ":"應諾",
"ㄧㄥㄌㄧㄗ":"硬裡子",
"ㄧㄥㄌㄧㄝ":"英烈",
"ㄧㄥㄌㄧㄠ":"營療/熒燎",
"ㄧㄥㄌㄧㄡ":"癭瘤",
"ㄧㄥㄌㄧㄢ":"楹聯",
"ㄧㄥㄌㄧㄣ":"嬰鱗",
"ㄧㄥㄌㄧㄤ":"英兩/贏糧",
"ㄧㄥㄌㄧㄥ":"英靈/硬領",
"ㄧㄥㄌㄨㄛ":"纓絡/瓔珞",
"ㄧㄥㄌㄨㄥ":"應龍",
"ㄧㄥㄍㄨㄛ":"英國/穎果",
"ㄧㄥㄍㄨㄢ":"盈貫/纓冠",
"ㄧㄥㄍㄨㄤ":"螢光/瑩光",
"ㄧㄥㄍㄨㄥ":"營工/硬弓",
"ㄧㄥㄍㄨㄩ":"硬骨魚",
"ㄧㄥㄎㄡㄕ":"營口市",
"ㄧㄥㄎㄨㄟ":"盈虧",
"ㄧㄥㄎㄨㄤ":"盈眶",
"ㄧㄥㄏㄨㄚ":"櫻花/硬話",
"ㄧㄥㄏㄨㄛ":"營活/營火",
"ㄧㄥㄏㄨㄞ":"縈懷",
"ㄧㄥㄏㄨㄟ":"迎會/映輝",
"ㄧㄥㄏㄨㄢ":"瀛寰",
"ㄧㄥㄏㄨㄣ":"英魂",
"ㄧㄥㄏㄨㄤ":"英皇/硬黃",
"ㄧㄥㄐㄧㄚ":"贏家/鷹架",
"ㄧㄥㄐㄧㄝ":"迎接/應屆",
"ㄧㄥㄐㄧㄠ":"硬腳",
"ㄧㄥㄐㄧㄡ":"營救",
"ㄧㄥㄐㄧㄢ":"營建",
"ㄧㄥㄐㄧㄤ":"郢匠",
"ㄧㄥㄐㄧㄥ":"應景/瑩鏡",
"ㄧㄥㄐㄩㄣ":"英俊",
"ㄧㄥㄑㄧㄝ":"媵妾",
"ㄧㄥㄑㄧㄡ":"營求",
"ㄧㄥㄑㄧㄢ":"贏錢/鶯遷",
"ㄧㄥㄑㄧㄣ":"迎親",
"ㄧㄥㄑㄩㄢ":"影圈/鷹犬",
"ㄧㄥㄒㄧㄡ":"穎秀",
"ㄧㄥㄒㄧㄢ":"映現",
"ㄧㄥㄒㄧㄣ":"迎新",
"ㄧㄥㄒㄧㄤ":"影響/影像",
"ㄧㄥㄒㄧㄥ":"硬性/影星",
"ㄧㄥㄒㄩㄖ":"迎婿日",
"ㄧㄥㄒㄩㄝ":"映雪/螢雪",
"ㄧㄥㄒㄩㄢ":"應選/膺選",
"ㄧㄥㄒㄩㄣ":"應訊/英尋",
"ㄧㄥㄒㄩㄥ":"英雄",
"ㄧㄥㄓㄡㄗ":"蠅帚子",
"ㄧㄥㄓㄨㄛ":"郢斲",
"ㄧㄥㄓㄨㄤ":"迎妝",
"ㄧㄥㄓㄨㄥ":"應鐘",
"ㄧㄥㄔㄨㄢ":"潁川",
"ㄧㄥㄔㄨㄣ":"迎春/櫻脣",
"ㄧㄥㄔㄨㄤ":"硬闖/螢窗",
"ㄧㄥㄕㄍㄠ":"硬石膏",
"ㄧㄥㄕㄎㄚ":"影視卡",
"ㄧㄥㄕㄨㄟ":"硬水/潁水",
"ㄧㄥㄕㄨㄤ":"英爽",
"ㄧㄥㄖㄨㄟ":"英銳",
"ㄧㄥㄖㄨㄣ":"瑩潤",
"ㄧㄥㄗㄍㄨ":"迎紫姑",
"ㄧㄥㄗㄎㄡ":"甖子口",
"ㄧㄥㄗㄠㄔ":"營造尺",
"ㄧㄥㄗㄨㄛ":"營作",
"ㄧㄥㄗㄨㄥ":"影蹤",
"ㄧㄥㄘㄨㄣ":"英寸",
"ㄧㄥㄘㄨㄥ":"景從",
"ㄧㄥㄙㄨㄛ":"鶯梭",
"ㄧㄥㄦㄔㄜ":"嬰兒車",
"ㄧㄥㄧㄝㄖ":"營業日",
"ㄧㄥㄧㄝㄜ":"營業額",
"ㄧㄥㄧㄡㄦ":"嬰幼兒",
"ㄧㄥㄨㄅㄟ":"鸚鵡杯",
"ㄧㄥㄨㄇㄨ":"鸚鵡目",
"ㄧㄥㄨㄎㄜ":"鸚鵡科",
"ㄧㄥㄨㄓㄡ":"鸚鵡洲",
"ㄧㄥㄨㄖㄜ":"鸚鵡熱",
"ㄧㄧㄈㄚㄧ":"以夷伐夷",
"ㄧㄧㄉㄤㄕ":"一以當十",
"ㄧㄧㄋㄧㄓ":"以意逆志",
"ㄧㄧㄍㄜㄣ":"以義割恩",
"ㄧㄧㄍㄨㄢ":"移液管",
"ㄧㄧㄐㄧㄕ":"以一擊十",
"ㄧㄧㄐㄧㄡ":"一一九",
"ㄧㄧㄓㄅㄤ":"薏苡之謗",
"ㄧㄧㄓㄨㄢ":"以一知萬",
"ㄧㄧㄔㄨㄢ":"以一持萬",
"ㄧㄧㄝㄌㄢ":"一葉蘭",
"ㄧㄧㄠㄈㄟ":"醫藥費",
"ㄧㄧㄡㄗㄗ":"逸游自恣",
"ㄧㄧㄢㄊㄤ":"一言堂",
"ㄧㄧㄣㄐㄧ":"曳引機",
"ㄧㄧㄣㄔㄜ":"曳引車",
"ㄧㄧㄤㄕㄥ":"一陽生",
"ㄧㄧㄩㄨㄢ":"以一馭萬",
"ㄧㄨㄅㄧㄥ":"義務兵",
"ㄧㄨㄅㄨㄓ":"一物不知",
"ㄧㄨㄓㄤㄨ":"一無長物",
"ㄧㄨㄕㄔㄨ":"一無是處",
"ㄧㄨㄗㄞㄨ":"一誤再誤",
"ㄧㄨㄛㄈㄥ":"一窩蜂",
"ㄧㄨㄟㄔㄢ":"一味禪",
"ㄧㄨㄢㄋㄧ":"一丸泥",
"ㄧㄨㄣㄏㄠ":"疑問號",
"ㄧㄨㄣㄐㄩ":"疑問句",
"ㄧㄨㄣㄧㄕ":"逸文軼事",
"ㄧㄨㄤㄓㄥ":"遺忘症",
"ㄧㄨㄤㄦㄓ":"一望而知",
"ㄧㄨㄧㄓㄨ":"一物一主",
"ㄧㄩㄓㄉㄧ":"一隅之地",
"ㄧㄩㄢㄊㄧ":"衣原體",
"ㄧㄩㄢㄐㄧ":"易元吉",
"ㄨㄅㄟㄐㄧ":"無碑記",
"ㄨㄅㄨㄓㄨ":"摀不住",
"ㄨㄅㄨㄕㄜ":"五步蛇",
"ㄨㄅㄨㄩㄕ":"無補於事",
"ㄨㄆㄟㄈㄨ":"吳佩孚",
"ㄨㄆㄣㄐㄧ":"烏盆計",
"ㄨㄇㄤㄇㄤ":"霧茫茫",
"ㄨㄇㄧㄥㄓ":"無名指",
"ㄨㄇㄧㄥㄕ":"無名氏",
"ㄨㄈㄤㄈㄛ":"五方佛",
"ㄨㄈㄥㄉㄞ":"無風帶",
"ㄨㄈㄥㄕㄩ":"五風十雨",
"ㄨㄉㄚㄈㄨ":"五大夫",
"ㄨㄉㄚㄌㄤ":"武大郎",
"ㄨㄉㄚㄓㄡ":"五大洲",
"ㄨㄉㄚㄔㄥ":"吳大澂",
"ㄨㄉㄚㄧㄤ":"五大洋",
"ㄨㄉㄠㄓㄥ":"舞蹈症",
"ㄨㄉㄡㄇㄧ":"五斗米",
"ㄨㄉㄤㄆㄞ":"武當派",
"ㄨㄉㄤㄕㄢ":"武當山",
"ㄨㄉㄧㄅㄨ":"兀的不",
"ㄨㄉㄨㄛㄗ":"無多子",
"ㄨㄊㄞㄐㄩ":"舞臺劇",
"ㄨㄊㄞㄕㄢ":"五臺山",
"ㄨㄊㄡㄩㄢ":"無頭願",
"ㄨㄌㄚㄕㄢ":"烏拉山",
"ㄨㄌㄚㄘㄠ":"烏拉草",
"ㄨㄌㄧㄉㄜ":"屋裡的",
"ㄨㄌㄧㄍㄢ":"無力感",
"ㄨㄌㄧㄍㄣ":"無理根",
"ㄨㄌㄧㄕㄨ":"無理數",
"ㄨㄌㄧㄖㄣ":"屋裡人",
"ㄨㄌㄧㄢㄗ":"五斂子",
"ㄨㄌㄩㄨㄙ":"無慮無思",
"ㄨㄍㄥㄊㄡ":"五更頭",
"ㄨㄍㄥㄐㄧ":"五更雞",
"ㄨㄍㄨㄐㄧ":"烏骨雞",
"ㄨㄍㄨㄓㄩ":"巫蠱之獄",
"ㄨㄍㄨㄛㄩ":"吳郭魚",
"ㄨㄎㄜㄓㄧ":"無可置疑",
"ㄨㄎㄠㄨㄧ":"無靠無依",
"ㄨㄏㄡㄓㄥ":"五侯鯖",
"ㄨㄏㄨㄢㄗ":"無患子",
"ㄨㄐㄧㄊㄧ":"無機體",
"ㄨㄐㄧㄚㄩ":"烏頰魚",
"ㄨㄐㄧㄝㄕ":"無价事",
"ㄨㄐㄧㄣㄕ":"武進士",
"ㄨㄐㄧㄥㄗ":"吳敬梓",
"ㄨㄐㄧㄥㄙ":"五經笥",
"ㄨㄐㄧㄧㄢ":"無機鹽",
"ㄨㄐㄧㄩㄕ":"無濟於事",
"ㄨㄐㄩㄣㄕ":"無菌室",
"ㄨㄒㄧㄣㄕ":"烏心石",
"ㄨㄒㄧㄤㄕ":"無響室",
"ㄨㄒㄧㄥㄓ":"無行止",
"ㄨㄓㄏㄨㄟ":"吳稚暉",
"ㄨㄓㄐㄧㄥ":"無止境",
"ㄨㄔㄓㄧㄡ":"無恥之尤",
"ㄨㄔㄥㄨㄤ":"武成王",
"ㄨㄕㄍㄨㄥ":"亡是公",
"ㄨㄕㄐㄧㄢ":"五十肩",
"ㄨㄕㄚㄇㄠ":"烏紗帽",
"ㄨㄕㄡㄏㄤ":"五熟行",
"ㄨㄕㄥㄒㄧ":"無聲戲",
"ㄨㄕㄨㄈㄟ":"無是無非",
"ㄨㄕㄨㄎㄜ":"無時無刻",
"ㄨㄖㄨㄧㄝ":"無日無夜",
"ㄨㄗㄐㄩㄝ":"五字訣",
"ㄨㄗㄓㄍㄜ":"五子之歌",
"ㄨㄗㄟㄔㄜ":"烏賊車",
"ㄨㄗㄤㄕㄣ":"五臟神",
"ㄨㄘㄢㄈㄟ":"誤餐費",
"ㄨㄙㄜㄅㄧ":"五色筆",
"ㄨㄙㄨㄌㄩ":"無思無慮",
"ㄨㄚㄆㄧㄢ":"瓦片",
"ㄨㄚㄇㄧㄥ":"蛙鳴",
"ㄨㄚㄉㄨㄥ":"挖洞",
"ㄨㄚㄌㄥㄓ":"瓦楞紙",
"ㄨㄚㄌㄥㄗ":"瓦楞子",
"ㄨㄚㄌㄨㄥ":"瓦壟",
"ㄨㄚㄍㄨㄢ":"瓦罐",
"ㄨㄚㄎㄨㄞ":"瓦塊",
"ㄨㄚㄏㄨㄤ":"媧皇",
"ㄨㄚㄐㄧㄝ":"瓦解",
"ㄨㄚㄐㄧㄤ":"瓦匠",
"ㄨㄚㄐㄧㄥ":"蛙鏡",
"ㄨㄚㄐㄩㄝ":"挖掘/嗢噱",
"ㄨㄚㄑㄩㄢ":"瓦全/瓦圈",
"ㄨㄚㄒㄧㄢ":"襪線",
"ㄨㄚㄔㄨㄢ":"襪船",
"ㄨㄚㄗㄨㄣ":"汙尊",
"ㄨㄚㄙㄉㄢ":"瓦斯彈",
"ㄨㄚㄙㄌㄨ":"瓦斯爐",
"ㄨㄚㄙㄔㄜ":"瓦斯車",
"ㄨㄚㄙㄨㄥ":"瓦松",
"ㄨㄚㄨㄚㄩ":"娃娃魚",
"ㄨㄛㄅㄧㄝ":"握別/窩憋",
"ㄨㄛㄅㄧㄥ":"臥病/臥冰",
"ㄨㄛㄇㄧㄢ":"渥眄",
"ㄨㄛㄉㄨㄣ":"窩頓",
"ㄨㄛㄊㄨㄦ":"臥兔兒",
"ㄨㄛㄌㄧㄚ":"我倆",
"ㄨㄛㄌㄧㄡ":"渦流",
"ㄨㄛㄌㄨㄣ":"渦輪",
"ㄨㄛㄌㄨㄥ":"臥龍",
"ㄨㄛㄍㄨㄚ":"倭瓜",
"ㄨㄛㄍㄨㄛ":"我國",
"ㄨㄛㄍㄨㄟ":"臥軌",
"ㄨㄛㄍㄨㄢ":"握管",
"ㄨㄛㄍㄨㄥ":"窩弓",
"ㄨㄛㄏㄨㄛ":"窩火",
"ㄨㄛㄏㄨㄟ":"渥惠",
"ㄨㄛㄐㄧㄚ":"我家/窩家",
"ㄨㄛㄐㄧㄤ":"我將",
"ㄨㄛㄐㄩㄣ":"我軍",
"ㄨㄛㄑㄩㄢ":"握拳",
"ㄨㄛㄒㄧㄣ":"窩心",
"ㄨㄛㄒㄩㄝ":"臥雪",
"ㄨㄛㄒㄩㄢ":"斡旋",
"ㄨㄛㄔㄨㄛ":"齷齪",
"ㄨㄛㄔㄨㄤ":"臥床",
"ㄨㄛㄔㄨㄥ":"渦蟲",
"ㄨㄞㄅㄧㄝ":"歪憋",
"ㄨㄞㄅㄧㄠ":"外表/外標",
"ㄨㄞㄅㄧㄢ":"外邊",
"ㄨㄞㄅㄧㄣ":"外賓",
"ㄨㄞㄆㄧㄢ":"外篇",
"ㄨㄞㄇㄧㄢ":"外面",
"ㄨㄞㄇㄧㄥ":"外名",
"ㄨㄞㄉㄠㄦ":"外道兒",
"ㄨㄞㄉㄧㄠ":"外調",
"ㄨㄞㄉㄧㄢ":"外電",
"ㄨㄞㄌㄞㄩ":"外來語",
"ㄨㄞㄌㄧㄡ":"外流",
"ㄨㄞㄍㄨㄚ":"外掛/外褂",
"ㄨㄞㄍㄨㄛ":"外國",
"ㄨㄞㄍㄨㄢ":"外觀/外官",
"ㄨㄞㄍㄨㄥ":"外公/外功",
"ㄨㄞㄎㄨㄞ":"外快",
"ㄨㄞㄏㄡㄖ":"外後日",
"ㄨㄞㄏㄨㄚ":"外話/歪話",
"ㄨㄞㄏㄨㄛ":"外貨",
"ㄨㄞㄏㄨㄟ":"外匯",
"ㄨㄞㄏㄨㄢ":"外患",
"ㄨㄞㄐㄧㄚ":"外家/外加",
"ㄨㄞㄐㄧㄝ":"外界",
"ㄨㄞㄐㄧㄠ":"外交/外教",
"ㄨㄞㄐㄧㄡ":"外舅",
"ㄨㄞㄐㄧㄢ":"外間/外艱",
"ㄨㄞㄐㄧㄤ":"外江",
"ㄨㄞㄐㄧㄥ":"外景/外淨",
"ㄨㄞㄑㄧㄠ":"外僑",
"ㄨㄞㄑㄧㄢ":"外欠",
"ㄨㄞㄑㄧㄣ":"外親/外勤",
"ㄨㄞㄒㄧㄝ":"外洩/外泄",
"ㄨㄞㄒㄧㄠ":"外銷",
"ㄨㄞㄒㄧㄢ":"外線/外縣",
"ㄨㄞㄒㄧㄣ":"外心",
"ㄨㄞㄒㄧㄤ":"外向/外相",
"ㄨㄞㄒㄧㄥ":"外形/外型",
"ㄨㄞㄓㄨㄢ":"外傳",
"ㄨㄞㄓㄨㄧ":"歪主意",
"ㄨㄞㄕㄨㄟ":"外水",
"ㄨㄞㄙㄌㄨ":"外四路",
"ㄨㄞㄙㄔㄢ":"歪廝纏",
"ㄨㄞㄙㄨㄣ":"外孫",
"ㄨㄞㄦㄉㄠ":"外耳道",
"ㄨㄞㄧㄤㄦ":"外秧兒",
"ㄨㄞㄨㄩㄢ":"外務員",
"ㄨㄟㄅㄛㄦ":"圍脖兒",
"ㄨㄟㄅㄧㄠ":"圍標",
"ㄨㄟㄅㄧㄢ":"未便/韋編",
"ㄨㄟㄅㄧㄥ":"胃病/衛兵",
"ㄨㄟㄆㄧㄣ":"偽品",
"ㄨㄟㄆㄧㄥ":"圍屏/幃屏",
"ㄨㄟㄇㄧㄠ":"微妙/微渺",
"ㄨㄟㄇㄧㄢ":"未免/衛冕",
"ㄨㄟㄇㄧㄥ":"威名/委命",
"ㄨㄟㄉㄧㄠ":"鮪釣",
"ㄨㄟㄉㄧㄥ":"未定",
"ㄨㄟㄉㄨㄟ":"衛隊",
"ㄨㄟㄉㄨㄢ":"尾端",
"ㄨㄟㄉㄨㄣ":"委頓",
"ㄨㄟㄊㄨㄛ":"委託/未妥",
"ㄨㄟㄋㄚㄙ":"維納斯",
"ㄨㄟㄋㄧㄤ":"韋娘",
"ㄨㄟㄋㄨㄛ":"唯諾",
"ㄨㄟㄋㄨㄥ":"煨膿/偎儂",
"ㄨㄟㄌㄧㄝ":"圍獵",
"ㄨㄟㄌㄧㄠ":"未了/未料",
"ㄨㄟㄌㄧㄡ":"慰留/偎留",
"ㄨㄟㄌㄧㄤ":"微量",
"ㄨㄟㄌㄧㄥ":"違令/威靈",
"ㄨㄟㄌㄨㄛ":"罻羅",
"ㄨㄟㄌㄨㄥ":"圍攏",
"ㄨㄟㄍㄨㄛ":"諉過/委過",
"ㄨㄟㄍㄨㄟ":"違規",
"ㄨㄟㄍㄨㄢ":"圍觀/未冠",
"ㄨㄟㄍㄨㄤ":"畏光",
"ㄨㄟㄍㄨㄥ":"圍攻/為公",
"ㄨㄟㄎㄨㄢ":"尾款",
"ㄨㄟㄎㄨㄣ":"圍困",
"ㄨㄟㄎㄨㄥ":"唯恐/惟恐",
"ㄨㄟㄏㄨㄚ":"緯繣",
"ㄨㄟㄏㄨㄣ":"未婚",
"ㄨㄟㄏㄨㄤ":"未遑",
"ㄨㄟㄐㄧㄝ":"慰藉/委結",
"ㄨㄟㄐㄧㄠ":"圍剿",
"ㄨㄟㄐㄧㄡ":"未就/委咎",
"ㄨㄟㄐㄧㄢ":"為間/未見",
"ㄨㄟㄐㄧㄣ":"未盡/圍巾",
"ㄨㄟㄐㄧㄥ":"味精/偽經",
"ㄨㄟㄐㄩㄝ":"味覺/未決",
"ㄨㄟㄐㄩㄢ":"微卷",
"ㄨㄟㄑㄧㄢ":"尾欠",
"ㄨㄟㄑㄧㄣ":"委禽",
"ㄨㄟㄑㄧㄤ":"圍牆/危檣",
"ㄨㄟㄑㄧㄥ":"微情/危情",
"ㄨㄟㄑㄩㄝ":"畏怯/魏闕",
"ㄨㄟㄑㄩㄢ":"威權",
"ㄨㄟㄑㄩㄣ":"圍裙",
"ㄨㄟㄒㄧㄝ":"威脅/萎謝",
"ㄨㄟㄒㄧㄠ":"微笑/微小",
"ㄨㄟㄒㄧㄡ":"維修",
"ㄨㄟㄒㄧㄢ":"危險/緯線",
"ㄨㄟㄒㄧㄣ":"違心/維新",
"ㄨㄟㄒㄧㄤ":"未詳/緯象",
"ㄨㄟㄒㄧㄥ":"衛星/微型",
"ㄨㄟㄒㄩㄝ":"為學",
"ㄨㄟㄒㄩㄣ":"微醺",
"ㄨㄟㄓㄕㄨ":"未知數",
"ㄨㄟㄓㄨㄛ":"圍桌",
"ㄨㄟㄓㄨㄟ":"尾追/尾椎",
"ㄨㄟㄓㄨㄤ":"偽裝/韋莊",
"ㄨㄟㄓㄨㄥ":"威重",
"ㄨㄟㄓㄩㄢ":"未知元",
"ㄨㄟㄔㄨㄛ":"危惙",
"ㄨㄟㄕㄐㄧ":"威士忌",
"ㄨㄟㄕㄥㄓ":"衛生紙",
"ㄨㄟㄕㄥㄧ":"衛生衣",
"ㄨㄟㄕㄥㄨ":"微生物",
"ㄨㄟㄕㄨㄛ":"嵬說",
"ㄨㄟㄕㄨㄟ":"渭水",
"ㄨㄟㄕㄨㄦ":"未熟兒",
"ㄨㄟㄖㄨㄛ":"微弱/未若",
"ㄨㄟㄖㄨㄟ":"威蕤/萎蕤",
"ㄨㄟㄗㄨㄓ":"偽組織",
"ㄨㄟㄗㄨㄛ":"圍坐/危坐",
"ㄨㄟㄗㄨㄟ":"委罪/畏罪",
"ㄨㄟㄗㄨㄥ":"衛從",
"ㄨㄟㄙㄨㄛ":"猥瑣/畏縮",
"ㄨㄟㄙㄨㄟ":"尾隨/未遂",
"ㄨㄟㄙㄨㄢ":"胃酸",
"ㄨㄟㄢㄈㄨ":"慰安婦",
"ㄨㄟㄧㄘㄞ":"未易才",
"ㄨㄟㄧㄥㄨ":"韋應物",
"ㄨㄟㄨㄐㄧ":"魏無忌",
"ㄨㄟㄨㄦㄩ":"維吾爾語",
"ㄨㄟㄩㄢㄓ":"委員制",
"ㄨㄢㄆㄧㄠ":"玩票",
"ㄨㄢㄇㄞㄗ":"挽麥子",
"ㄨㄢㄇㄧㄠ":"宛妙",
"ㄨㄢㄇㄧㄢ":"挽面",
"ㄨㄢㄇㄧㄣ":"頑民",
"ㄨㄢㄇㄧㄥ":"玩命",
"ㄨㄢㄉㄧㄢ":"晚點",
"ㄨㄢㄉㄨㄢ":"萬端",
"ㄨㄢㄉㄨㄣ":"頑鈍",
"ㄨㄢㄊㄧㄢ":"晚田",
"ㄨㄢㄊㄨㄛ":"碗脫",
"ㄨㄢㄊㄨㄢ":"刓團",
"ㄨㄢㄊㄨㄥ":"頑童",
"ㄨㄢㄋㄧㄢ":"晚年/萬年",
"ㄨㄢㄋㄧㄤ":"晚娘",
"ㄨㄢㄋㄨㄥ":"玩弄/玩弄",
"ㄨㄢㄌㄧㄝ":"頑劣",
"ㄨㄢㄌㄧㄠ":"完了",
"ㄨㄢㄌㄧㄡ":"挽留/灣流",
"ㄨㄢㄌㄧㄢ":"晚戀/輓聯",
"ㄨㄢㄌㄧㄤ":"完糧",
"ㄨㄢㄌㄨㄢ":"完卵",
"ㄨㄢㄌㄩㄢ":"婉孌",
"ㄨㄢㄍㄨㄛ":"萬國/碗粿",
"ㄨㄢㄍㄨㄟ":"晚歸/琬圭",
"ㄨㄢㄍㄨㄢ":"萬貫",
"ㄨㄢㄍㄨㄥ":"完工/碗公",
"ㄨㄢㄎㄨㄞ":"碗筷",
"ㄨㄢㄏㄨㄚ":"頑話/萬華",
"ㄨㄢㄏㄨㄛ":"玩火/萬壑",
"ㄨㄢㄏㄨㄟ":"晚會/挽回",
"ㄨㄢㄏㄨㄢ":"灣澴",
"ㄨㄢㄏㄨㄣ":"完婚/晚婚",
"ㄨㄢㄐㄧㄚ":"玩家",
"ㄨㄢㄐㄧㄝ":"完結/綰結",
"ㄨㄢㄐㄧㄡ":"挽救",
"ㄨㄢㄐㄧㄢ":"晚間/頑健",
"ㄨㄢㄐㄧㄣ":"萬金/晚近",
"ㄨㄢㄐㄧㄥ":"晚景/晚境",
"ㄨㄢㄐㄩㄢ":"萬卷",
"ㄨㄢㄐㄩㄣ":"完竣/萬鈞",
"ㄨㄢㄑㄧㄠ":"萬竅",
"ㄨㄢㄑㄧㄡ":"晚秋/宛丘",
"ㄨㄢㄑㄧㄢ":"萬千",
"ㄨㄢㄑㄧㄤ":"頑強",
"ㄨㄢㄑㄧㄥ":"萬頃",
"ㄨㄢㄑㄩㄢ":"完全/萬全",
"ㄨㄢㄒㄧㄚ":"晚霞",
"ㄨㄢㄒㄧㄝ":"婉謝",
"ㄨㄢㄒㄧㄠ":"玩笑/頑笑",
"ㄨㄢㄒㄧㄡ":"挽袖",
"ㄨㄢㄒㄧㄢ":"頑涎/頑癬",
"ㄨㄢㄒㄧㄤ":"萬象/萬箱",
"ㄨㄢㄒㄧㄥ":"萬幸/萬姓",
"ㄨㄢㄒㄩㄝ":"晚學",
"ㄨㄢㄒㄩㄥ":"丸熊",
"ㄨㄢㄓㄨㄢ":"婉轉/灣轉",
"ㄨㄢㄓㄨㄤ":"萬狀",
"ㄨㄢㄓㄨㄥ":"萬種/萬鍾",
"ㄨㄢㄔㄨㄢ":"腕釧",
"ㄨㄢㄔㄨㄤ":"惋愴",
"ㄨㄢㄔㄨㄥ":"萬重",
"ㄨㄢㄕㄡㄦ":"挽手兒",
"ㄨㄢㄕㄧㄕ":"萬世一時",
"ㄨㄢㄕㄨㄚ":"玩耍/頑耍",
"ㄨㄢㄕㄨㄞ":"玩帥",
"ㄨㄢㄕㄨㄟ":"完稅",
"ㄨㄢㄕㄨㄣ":"婉順",
"ㄨㄢㄕㄨㄤ":"晚霜",
"ㄨㄢㄖㄨㄛ":"宛若",
"ㄨㄢㄖㄨㄥ":"婉容",
"ㄨㄢㄗㄨㄦ":"碗足兒",
"ㄨㄢㄘㄨㄟ":"晚翠",
"ㄨㄢㄙㄨㄟ":"萬歲/晚歲",
"ㄨㄢㄨㄕㄧ":"萬無失一",
"ㄨㄢㄨㄧㄕ":"萬無一失",
"ㄨㄣㄅㄧㄠ":"文表",
"ㄨㄣㄅㄧㄢ":"穩便",
"ㄨㄣㄅㄧㄥ":"文柄/瘟病",
"ㄨㄣㄆㄧㄥ":"文憑",
"ㄨㄣㄇㄧㄠ":"文廟",
"ㄨㄣㄇㄧㄢ":"文面",
"ㄨㄣㄇㄧㄥ":"文明/聞名",
"ㄨㄣㄉㄧㄝ":"文牒",
"ㄨㄣㄉㄧㄠ":"文調",
"ㄨㄣㄉㄧㄥ":"穩定/問鼎",
"ㄨㄣㄉㄨㄟ":"問對",
"ㄨㄣㄉㄨㄢ":"問斷",
"ㄨㄣㄊㄧㄝ":"穩貼",
"ㄨㄣㄊㄨㄛ":"穩妥",
"ㄨㄣㄊㄨㄣ":"溫暾",
"ㄨㄣㄊㄨㄥ":"文同/文童",
"ㄨㄣㄋㄧㄠ":"文鳥",
"ㄨㄣㄋㄧㄡ":"問牛",
"ㄨㄣㄋㄨㄢ":"溫暖",
"ㄨㄣㄌㄧㄣ":"文林",
"ㄨㄣㄌㄧㄤ":"溫良/轀輬",
"ㄨㄣㄌㄨㄢ":"紊亂",
"ㄨㄣㄍㄨㄛ":"文過",
"ㄨㄣㄍㄨㄟ":"文軌",
"ㄨㄣㄍㄨㄢ":"文官/問官",
"ㄨㄣㄎㄨㄞ":"文儈",
"ㄨㄣㄏㄨㄚ":"文化/問話",
"ㄨㄣㄏㄨㄛ":"文火",
"ㄨㄣㄏㄨㄟ":"文會",
"ㄨㄣㄐㄧㄝ":"問結/蚊睫",
"ㄨㄣㄐㄧㄠ":"文教/溫嶠",
"ㄨㄣㄐㄧㄡ":"溫酒",
"ㄨㄣㄐㄧㄢ":"文件/穩健",
"ㄨㄣㄐㄧㄣ":"問津/問禁",
"ㄨㄣㄐㄧㄥ":"文靜/文旌",
"ㄨㄣㄐㄩㄝ":"溫覺",
"ㄨㄣㄐㄩㄢ":"問卷/溫卷",
"ㄨㄣㄐㄩㄣ":"文君",
"ㄨㄣㄑㄧㄢ":"問遣",
"ㄨㄣㄑㄧㄣ":"問寢",
"ㄨㄣㄑㄧㄥ":"溫情/文情",
"ㄨㄣㄑㄩㄢ":"溫泉/文券",
"ㄨㄣㄒㄧㄡ":"文繡",
"ㄨㄣㄒㄧㄢ":"文獻/紋線",
"ㄨㄣㄒㄧㄣ":"溫馨/問心",
"ㄨㄣㄒㄧㄤ":"蚊香/閿鄉",
"ㄨㄣㄒㄩㄝ":"文學",
"ㄨㄣㄒㄩㄢ":"文宣/文選",
"ㄨㄣㄒㄩㄣ":"溫馴/問訊",
"ㄨㄣㄓㄨㄤ":"文狀",
"ㄨㄣㄓㄨㄥ":"穩重/文種",
"ㄨㄣㄔㄤㄩ":"文昌魚",
"ㄨㄣㄔㄨㄣ":"溫淳",
"ㄨㄣㄔㄨㄤ":"溫床",
"ㄨㄣㄕㄨㄟ":"溫水/汶水",
"ㄨㄣㄕㄨㄣ":"溫順",
"ㄨㄣㄖㄨㄛ":"文弱",
"ㄨㄣㄖㄨㄟ":"蚊蚋",
"ㄨㄣㄖㄨㄣ":"溫潤",
"ㄨㄣㄗㄧㄣ":"文字飲",
"ㄨㄣㄗㄨㄟ":"問罪",
"ㄨㄣㄗㄨㄥ":"文宗",
"ㄨㄣㄘㄨㄟ":"文粹",
"ㄨㄣㄘㄨㄣ":"溫存",
"ㄨㄣㄧㄓㄕ":"聞一知十",
"ㄨㄣㄧㄠㄩ":"文鰩魚",
"ㄨㄣㄨㄔㄤ":"文武場",
"ㄨㄤㄆㄧㄥ":"網屏",
"ㄨㄤㄇㄧㄢ":"王冕",
"ㄨㄤㄇㄧㄥ":"亡命/王命",
"ㄨㄤㄈㄨㄓ":"王夫之",
"ㄨㄤㄈㄨㄕ":"望夫石",
"ㄨㄤㄉㄧㄠ":"忘掉",
"ㄨㄤㄉㄧㄢ":"網點",
"ㄨㄤㄉㄨㄛ":"王鐸",
"ㄨㄤㄉㄨㄢ":"望斷/枉斷",
"ㄨㄤㄉㄨㄣ":"王敦",
"ㄨㄤㄉㄨㄥ":"妄動",
"ㄨㄤㄊㄧㄠ":"王條",
"ㄨㄤㄊㄧㄢ":"王田",
"ㄨㄤㄊㄧㄥ":"王庭",
"ㄨㄤㄊㄨㄥ":"王通",
"ㄨㄤㄋㄧㄢ":"往年/忘年",
"ㄨㄤㄌㄠㄨ":"王老五",
"ㄨㄤㄌㄧㄡ":"王留",
"ㄨㄤㄌㄧㄢ":"王蓮",
"ㄨㄤㄌㄧㄤ":"罔兩/魍魎",
"ㄨㄤㄌㄧㄥ":"亡靈/王陵",
"ㄨㄤㄌㄨㄛ":"網絡/網羅",
"ㄨㄤㄍㄨㄚ":"王瓜",
"ㄨㄤㄍㄨㄛ":"王國/亡國",
"ㄨㄤㄍㄨㄟ":"王珪",
"ㄨㄤㄍㄨㄢ":"王官/王冠",
"ㄨㄤㄍㄨㄥ":"王公/王宮",
"ㄨㄤㄎㄨㄟ":"王魁",
"ㄨㄤㄏㄨㄚ":"王化/亡化",
"ㄨㄤㄏㄨㄞ":"忘懷",
"ㄨㄤㄏㄨㄟ":"王翬/王虺",
"ㄨㄤㄏㄨㄢ":"往還",
"ㄨㄤㄏㄨㄣ":"亡魂/忘魂",
"ㄨㄤㄐㄧㄚ":"枉駕",
"ㄨㄤㄐㄧㄡ":"忘舊",
"ㄨㄤㄐㄧㄢ":"望見/王建",
"ㄨㄤㄐㄧㄣ":"網巾",
"ㄨㄤㄐㄧㄤ":"王漿",
"ㄨㄤㄑㄧㄠ":"王喬",
"ㄨㄤㄑㄧㄡ":"網球/妄求",
"ㄨㄤㄑㄧㄢ":"往前",
"ㄨㄤㄑㄧㄤ":"王嬙",
"ㄨㄤㄑㄧㄥ":"忘情",
"ㄨㄤㄑㄩㄝ":"忘卻",
"ㄨㄤㄑㄩㄢ":"網圈",
"ㄨㄤㄒㄧㄓ":"王羲之",
"ㄨㄤㄒㄧㄝ":"王謝",
"ㄨㄤㄒㄧㄠ":"旺銷",
"ㄨㄤㄒㄧㄤ":"妄想/旺相",
"ㄨㄤㄒㄧㄥ":"忘形/望幸",
"ㄨㄤㄓㄜㄕ":"王者師",
"ㄨㄤㄓㄨㄥ":"汪中",
"ㄨㄤㄔㄨㄢ":"輞川",
"ㄨㄤㄔㄨㄥ":"王充",
"ㄨㄤㄕㄈㄨ":"王實甫",
"ㄨㄤㄕㄓㄣ":"王世貞",
"ㄨㄤㄕㄨㄛ":"望朔/妄說",
"ㄨㄤㄕㄨㄟ":"王水",
"ㄨㄤㄖㄨㄥ":"王融/王戎",
"ㄨㄤㄗㄨㄛ":"妄作",
"ㄨㄤㄙㄔㄥ":"枉死城",
"ㄨㄤㄙㄨㄟ":"望歲",
"ㄨㄤㄙㄨㄣ":"王孫",
"ㄨㄤㄣㄕㄧ":"忘恩失義",
"ㄨㄤㄦㄉㄜ":"王爾德",
"ㄨㄤㄧㄡㄨ":"忘憂物",
"ㄨㄤㄧㄣㄓ":"王引之",
"ㄨㄤㄨㄣㄓ":"王文治",
"ㄨㄤㄩㄔㄥ":"王禹偁",
"ㄨㄤㄩㄣㄨ":"王雲五",
"ㄨㄤㄩㄧㄝ":"網漁業",
"ㄨㄥㄊㄧㄢ":"甕天",
"ㄨㄥㄎㄨㄛ":"甕闊",
"ㄨㄥㄓㄨㄥ":"翁仲",
"ㄨㄥㄖㄨㄥ":"蓊茸",
"ㄨㄧㄋㄧㄠ":"無翼鳥",
"ㄨㄧㄐㄧㄢ":"無意間",
"ㄨㄧㄒㄧㄤ":"烏衣巷",
"ㄨㄧㄓㄨㄥ":"無意中",
"ㄨㄧㄗㄉㄧ":"烏衣子弟",
"ㄨㄧㄚㄧㄚ":"烏壓壓",
"ㄨㄧㄠㄦㄩ":"勿藥而愈",
"ㄨㄧㄡㄧㄡ":"烏油油",
"ㄨㄧㄢㄇㄟ":"無煙煤",
"ㄨㄧㄢㄐㄧ":"五眼雞",
"ㄨㄧㄦㄈㄟ":"無翼而飛",
"ㄨㄧㄨㄎㄠ":"無依無靠",
"ㄨㄨㄛㄧㄠ":"吳沃堯",
"ㄨㄨㄟㄇㄨ":"無尾目",
"ㄨㄨㄟㄦㄓ":"無為而治",
"ㄨㄨㄟㄧㄝ":"吳偉業",
"ㄨㄨㄣㄧㄥ":"吳文英",
"ㄨㄨㄤㄘㄠ":"勿忘草",
"ㄨㄩㄥㄓㄧ":"毋庸置疑",
"ㄩㄅㄞㄆㄧ":"榆白皮",
"ㄩㄇㄟㄖㄣ":"虞美人",
"ㄩㄇㄟㄨㄓ":"愚昧無知",
"ㄩㄈㄤㄓㄣ":"預防針",
"ㄩㄈㄥㄎㄜ":"馭風客",
"ㄩㄈㄨㄎㄚ":"預付卡",
"ㄩㄉㄚㄈㄨ":"郁達夫",
"ㄩㄉㄚㄐㄧ":"雨打雞",
"ㄩㄉㄚㄧㄡ":"俞大猷",
"ㄩㄉㄨㄅㄞ":"魚肚白",
"ㄩㄊㄞㄊㄧ":"玉臺體",
"ㄩㄊㄧㄨㄣ":"語體文",
"ㄩㄌㄞㄙㄢ":"雨來散",
"ㄩㄌㄟㄕㄢ":"玉壘山",
"ㄩㄌㄢㄆㄣ":"盂蘭盆",
"ㄩㄌㄨㄓㄣ":"雨露之恩",
"ㄩㄍㄢㄧㄡ":"魚肝油",
"ㄩㄍㄨㄗㄨ":"裕固族",
"ㄩㄏㄨㄚㄕ":"雨花石",
"ㄩㄐㄧㄚㄩ":"榆莢雨",
"ㄩㄐㄧㄢㄨ":"庾肩吾",
"ㄩㄒㄧㄕㄥ":"玉谿生",
"ㄩㄒㄧㄠㄗ":"予小子",
"ㄩㄓㄓㄐㄧ":"迂直之計",
"ㄩㄓㄓㄙㄜ":"欲炙之色",
"ㄩㄓㄡㄈㄥ":"宇宙鋒",
"ㄩㄓㄡㄓㄢ":"宇宙站",
"ㄩㄓㄡㄔㄣ":"宇宙塵",
"ㄩㄓㄨㄉㄤ":"玉卮無當",
"ㄩㄔㄒㄧㄤ":"魚池鄉",
"ㄩㄔㄢㄑㄧ":"預產期",
"ㄩㄔㄣㄔㄣ":"鬱沉沉",
"ㄩㄔㄧㄙㄥ":"尉遲乙僧",
"ㄩㄔㄨㄤㄗ":"魚床子",
"ㄩㄕㄔㄤㄘ":"與世長辭",
"ㄩㄕㄢㄅㄥ":"玉山崩",
"ㄩㄕㄢㄆㄞ":"虞山派",
"ㄩㄕㄢㄈㄤ":"御膳房",
"ㄩㄕㄢㄉㄡ":"羽扇豆",
"ㄩㄕㄨㄅㄨ":"於事無補",
"ㄩㄕㄨㄈㄤ":"御書房",
"ㄩㄕㄨㄐㄧ":"於事無濟",
"ㄩㄕㄨㄓㄥ":"與世無爭",
"ㄩㄕㄨㄕㄨ":"玉蜀黍",
"ㄩㄗㄊㄡㄦ":"雨字頭兒",
"ㄩㄗㄐㄧㄤ":"魚子醬",
"ㄩㄘㄒㄧㄢ":"榆次縣",
"ㄩㄘㄥㄩㄣ":"雨層雲",
"ㄩㄙㄠㄊㄡ":"玉搔頭",
"ㄩㄝㄅㄧㄢ":"躍變",
"ㄩㄝㄅㄧㄥ":"月餅/閱兵",
"ㄩㄝㄆㄧㄠ":"月票",
"ㄩㄝㄆㄧㄣ":"樂品",
"ㄩㄝㄇㄧㄠ":"月杪/岳廟",
"ㄩㄝㄇㄧㄢ":"月面",
"ㄩㄝㄈㄨㄕ":"樂府詩",
"ㄩㄝㄉㄧㄝ":"約迭",
"ㄩㄝㄉㄧㄠ":"越調",
"ㄩㄝㄉㄧㄥ":"約定",
"ㄩㄝㄉㄨㄟ":"樂隊",
"ㄩㄝㄉㄨㄥ":"躍動/月洞",
"ㄩㄝㄊㄡㄦ":"月頭兒",
"ㄩㄝㄊㄨㄢ":"樂團/月團",
"ㄩㄝㄊㄨㄥ":"約同",
"ㄩㄝㄋㄧㄠ":"越鳥",
"ㄩㄝㄋㄧㄢ":"閱年",
"ㄩㄝㄌㄢㄕ":"閱覽室",
"ㄩㄝㄌㄧㄤ":"月亮",
"ㄩㄝㄌㄧㄥ":"月令/月令",
"ㄩㄝㄌㄨㄣ":"樂論/月輪",
"ㄩㄝㄌㄩㄝ":"約略",
"ㄩㄝㄍㄨㄚ":"越瓜",
"ㄩㄝㄍㄨㄛ":"越過",
"ㄩㄝㄍㄨㄟ":"越軌/月桂",
"ㄩㄝㄍㄨㄤ":"月光",
"ㄩㄝㄍㄨㄥ":"樂工/嶽公",
"ㄩㄝㄏㄨㄚ":"月華",
"ㄩㄝㄏㄨㄟ":"約會/月會",
"ㄩㄝㄐㄧㄚ":"岳家",
"ㄩㄝㄐㄧㄝ":"越界",
"ㄩㄝㄐㄧㄢ":"約見/月建",
"ㄩㄝㄐㄧㄣ":"躍進",
"ㄩㄝㄐㄧㄤ":"粵江",
"ㄩㄝㄐㄧㄥ":"月經/樂經",
"ㄩㄝㄐㄩㄢ":"閱卷",
"ㄩㄝㄑㄧㄡ":"月球",
"ㄩㄝㄑㄧㄢ":"月錢",
"ㄩㄝㄑㄧㄣ":"月琴",
"ㄩㄝㄑㄧㄥ":"約請",
"ㄩㄝㄑㄩㄢ":"越權",
"ㄩㄝㄒㄧㄢ":"越線",
"ㄩㄝㄒㄧㄣ":"月薪/悅心",
"ㄩㄝㄒㄧㄤ":"月相",
"ㄩㄝㄒㄧㄥ":"刖刑",
"ㄩㄝㄓㄨㄛ":"鸑鷟",
"ㄩㄝㄓㄨㄥ":"月中/月終",
"ㄩㄝㄔㄤㄕ":"月長石",
"ㄩㄝㄔㄨㄤ":"樂床",
"ㄩㄝㄕㄨㄛ":"月朔",
"ㄩㄝㄖㄨㄛ":"越若",
"ㄩㄝㄗㄈㄤ":"月子房",
"ㄩㄝㄗㄨㄥ":"約從",
"ㄩㄝㄧㄠㄖ":"月曜日",
"ㄩㄢㄅㄧㄢ":"圓便/員弁",
"ㄩㄢㄅㄧㄥ":"援兵",
"ㄩㄢㄇㄧㄕ":"元祕史",
"ㄩㄢㄇㄧㄠ":"原廟",
"ㄩㄢㄇㄧㄢ":"原棉",
"ㄩㄢㄇㄧㄥ":"原名/元命",
"ㄩㄢㄉㄧㄠ":"圓雕",
"ㄩㄢㄉㄧㄢ":"原點",
"ㄩㄢㄉㄧㄥ":"原定/園丁",
"ㄩㄢㄉㄨㄟ":"怨懟",
"ㄩㄢㄉㄨㄣ":"遠遁",
"ㄩㄢㄉㄨㄥ":"遠東",
"ㄩㄢㄊㄧㄠ":"遠眺",
"ㄩㄢㄊㄧㄢ":"原田/園田",
"ㄩㄢㄊㄨㄥ":"圓通/圓筒",
"ㄩㄢㄋㄧㄝ":"冤孽",
"ㄩㄢㄋㄧㄢ":"元年",
"ㄩㄢㄌㄧㄠ":"原料",
"ㄩㄢㄌㄧㄡ":"源流",
"ㄩㄢㄌㄧㄢ":"圓臉",
"ㄩㄢㄌㄧㄣ":"園林",
"ㄩㄢㄌㄧㄤ":"原諒",
"ㄩㄢㄌㄧㄥ":"園令/員領",
"ㄩㄢㄌㄨㄛ":"院落",
"ㄩㄢㄌㄨㄥ":"圓籠",
"ㄩㄢㄌㄩㄝ":"遠略",
"ㄩㄢㄍㄨㄟ":"圓規",
"ㄩㄢㄍㄨㄢ":"園官",
"ㄩㄢㄍㄨㄤ":"圓光",
"ㄩㄢㄍㄨㄥ":"員工/院公",
"ㄩㄢㄏㄨㄚ":"圓滑/遠話",
"ㄩㄢㄏㄨㄛ":"圓活/圓活",
"ㄩㄢㄏㄨㄟ":"元會/緣會",
"ㄩㄢㄏㄨㄢ":"圓環",
"ㄩㄢㄏㄨㄣ":"冤魂/圓渾",
"ㄩㄢㄏㄨㄤ":"圓謊",
"ㄩㄢㄏㄨㄥ":"袁宏",
"ㄩㄢㄐㄧㄚ":"冤家/原價",
"ㄩㄢㄐㄧㄝ":"元結/怨結",
"ㄩㄢㄐㄧㄠ":"援交/圓教",
"ㄩㄢㄐㄧㄡ":"圓就/原舊",
"ㄩㄢㄐㄧㄢ":"遠見/原件",
"ㄩㄢㄐㄧㄣ":"遠近",
"ㄩㄢㄐㄧㄤ":"沅江",
"ㄩㄢㄐㄧㄥ":"圓經/員警",
"ㄩㄢㄐㄩㄝ":"緣覺",
"ㄩㄢㄐㄩㄣ":"院君/元君",
"ㄩㄢㄑㄧㄝ":"園妾",
"ㄩㄢㄑㄧㄠ":"圓鍬",
"ㄩㄢㄑㄧㄡ":"圜丘",
"ㄩㄢㄑㄧㄣ":"遠親/園寢",
"ㄩㄢㄑㄧㄤ":"圜牆/垣牆",
"ㄩㄢㄑㄧㄥ":"冤情",
"ㄩㄢㄑㄩㄢ":"圓圈/圓全",
"ㄩㄢㄒㄧㄠ":"元宵",
"ㄩㄢㄒㄧㄢ":"原先/原憲",
"ㄩㄢㄒㄧㄣ":"圓心/原心",
"ㄩㄢㄒㄧㄥ":"圓形/原型",
"ㄩㄢㄒㄩㄢ":"蝯眩",
"ㄩㄢㄒㄩㄣ":"圓汛/元勛",
"ㄩㄢㄒㄩㄥ":"元凶",
"ㄩㄢㄓㄨㄛ":"圓桌",
"ㄩㄢㄓㄨㄤ":"原狀/原裝",
"ㄩㄢㄓㄨㄥ":"圜鍾",
"ㄩㄢㄕㄎㄞ":"袁世凱",
"ㄩㄢㄕㄖㄣ":"原始人",
"ㄩㄢㄕㄗㄨ":"元世祖",
"ㄩㄢㄕㄥㄓ":"原生質",
"ㄩㄢㄕㄧㄢ":"遠視眼",
"ㄩㄢㄕㄨㄓ":"元書紙",
"ㄩㄢㄕㄨㄛ":"原說",
"ㄩㄢㄕㄨㄞ":"元帥",
"ㄩㄢㄕㄨㄟ":"原水/源水",
"ㄩㄢㄕㄨㄩ":"源氏物語",
"ㄩㄢㄖㄨㄣ":"圓潤",
"ㄩㄢㄖㄨㄥ":"圓融/元戎",
"ㄩㄢㄗㄅㄧ":"原子筆",
"ㄩㄢㄗㄉㄢ":"原子彈",
"ㄩㄢㄗㄊㄤ":"圓仔湯",
"ㄩㄢㄗㄋㄥ":"原子能",
"ㄩㄢㄗㄌㄨ":"原子爐",
"ㄩㄢㄗㄏㄜ":"原子核",
"ㄩㄢㄗㄒㄩ":"原子序",
"ㄩㄢㄗㄖㄜ":"原子熱",
"ㄩㄢㄗㄨㄛ":"原作/圓作",
"ㄩㄢㄗㄨㄟ":"原罪",
"ㄩㄢㄗㄩㄣ":"原子雲",
"ㄩㄢㄙㄨㄟ":"芫荽",
"ㄩㄢㄨㄑㄩ":"圓舞曲",
"ㄩㄢㄨㄓㄜ":"原舞者",
"ㄩㄢㄨㄟㄓ":"元微之",
"ㄩㄣㄅㄧㄢ":"芸編",
"ㄩㄣㄅㄧㄣ":"雲鬢",
"ㄩㄣㄆㄧㄥ":"雲屏",
"ㄩㄣㄇㄧㄝ":"隕滅",
"ㄩㄣㄇㄧㄢ":"勻面",
"ㄩㄣㄇㄧㄥ":"運命/隕命",
"ㄩㄣㄉㄨㄛ":"雲朵",
"ㄩㄣㄉㄨㄟ":"勻兌/慍懟",
"ㄩㄣㄉㄨㄢ":"雲端",
"ㄩㄣㄉㄨㄥ":"運動/運動",
"ㄩㄣㄊㄡㄦ":"雲頭兒",
"ㄩㄣㄊㄧㄝ":"隕鐵",
"ㄩㄣㄊㄧㄢ":"雲天",
"ㄩㄣㄊㄧㄥ":"勻停",
"ㄩㄣㄊㄨㄟ":"雲腿",
"ㄩㄣㄊㄨㄣ":"雲吞",
"ㄩㄣㄋㄧㄤ":"醞釀",
"ㄩㄣㄋㄨㄛ":"允諾",
"ㄩㄣㄋㄨㄥ":"運弄",
"ㄩㄣㄌㄧㄡ":"勻溜",
"ㄩㄣㄌㄧㄢ":"勻臉",
"ㄩㄣㄌㄧㄣ":"雲林",
"ㄩㄣㄌㄧㄤ":"運量/雲量",
"ㄩㄣㄌㄧㄥ":"雲嶺",
"ㄩㄣㄌㄨㄛ":"隕落/雲鑼",
"ㄩㄣㄌㄨㄣ":"奫淪",
"ㄩㄣㄌㄩㄝ":"韻略",
"ㄩㄣㄍㄠㄦ":"暈高兒",
"ㄩㄣㄍㄨㄥ":"運功",
"ㄩㄣㄏㄨㄚ":"雲華",
"ㄩㄣㄏㄨㄛ":"熅火/隕穫",
"ㄩㄣㄏㄨㄟ":"運會/蕓輝",
"ㄩㄣㄏㄨㄢ":"雲鬟",
"ㄩㄣㄏㄨㄣ":"允婚",
"ㄩㄣㄐㄧㄝ":"蘊結/蘊藉",
"ㄩㄣㄐㄧㄠ":"運腳/韻腳",
"ㄩㄣㄐㄧㄢ":"雲肩",
"ㄩㄣㄐㄧㄣ":"雲錦",
"ㄩㄣㄐㄧㄥ":"雲精/勻淨",
"ㄩㄣㄐㄩㄝ":"暈厥",
"ㄩㄣㄑㄩㄝ":"雲雀",
"ㄩㄣㄒㄧㄚ":"雲霞/允洽",
"ㄩㄣㄒㄧㄠ":"雲霄/運銷",
"ㄩㄣㄒㄧㄢ":"運限/鄖縣",
"ㄩㄣㄒㄧㄤ":"芸香",
"ㄩㄣㄒㄧㄥ":"運行/隕星",
"ㄩㄣㄒㄩㄝ":"韻學",
"ㄩㄣㄒㄩㄢ":"暈眩",
"ㄩㄣㄓㄨㄟ":"隕墜",
"ㄩㄣㄓㄨㄢ":"運轉",
"ㄩㄣㄓㄨㄣ":"允准",
"ㄩㄣㄓㄨㄥ":"雲中",
"ㄩㄣㄔㄨㄢ":"暈船",
"ㄩㄣㄔㄨㄤ":"芸窗",
"ㄩㄣㄕㄨㄟ":"雲水",
"ㄩㄣㄖㄨㄥ":"慍容",
"ㄩㄣㄗㄨㄛ":"運作/運祚",
"ㄩㄣㄘㄨㄥ":"允從",
"ㄩㄣㄙㄨㄟ":"孕穗",
"ㄩㄣㄙㄨㄢ":"運算",
"ㄩㄣㄙㄨㄣ":"雲孫",
"ㄩㄣㄙㄨㄥ":"運送",
"ㄩㄣㄧㄓㄣ":"熨衣枕",
"ㄩㄣㄧㄤㄕ":"雲陽市",
"ㄩㄥㄅㄧㄝ":"永別",
"ㄩㄥㄅㄧㄥ":"用兵/擁兵",
"ㄩㄥㄆㄧㄣ":"用品",
"ㄩㄥㄇㄧㄢ":"永眠",
"ㄩㄥㄇㄧㄥ":"用命/永命",
"ㄩㄥㄉㄧㄢ":"佣鈿",
"ㄩㄥㄉㄨㄛ":"慵惰",
"ㄩㄥㄊㄨㄟ":"勇退",
"ㄩㄥㄊㄨㄢ":"涌湍",
"ㄩㄥㄋㄧㄢ":"永年",
"ㄩㄥㄌㄧㄝ":"勇烈",
"ㄩㄥㄌㄧㄠ":"用料",
"ㄩㄥㄌㄧㄡ":"涌流",
"ㄩㄥㄌㄩㄝ":"勇略",
"ㄩㄥㄍㄨㄟ":"踴貴",
"ㄩㄥㄍㄨㄤ":"用光",
"ㄩㄥㄍㄨㄥ":"用功/傭工",
"ㄩㄥㄏㄨㄞ":"詠懷",
"ㄩㄥㄏㄨㄟ":"用匯/擁篲",
"ㄩㄥㄐㄧㄚ":"永嘉",
"ㄩㄥㄐㄧㄝ":"永劫",
"ㄩㄥㄐㄧㄡ":"永久",
"ㄩㄥㄐㄧㄢ":"勇健",
"ㄩㄥㄐㄧㄣ":"佣金/用盡",
"ㄩㄥㄐㄧㄤ":"勇將",
"ㄩㄥㄐㄧㄥ":"用勁",
"ㄩㄥㄐㄩㄝ":"勇決/永訣",
"ㄩㄥㄑㄧㄢ":"用錢/佣錢",
"ㄩㄥㄑㄧㄥ":"用情",
"ㄩㄥㄑㄩㄢ":"湧泉",
"ㄩㄥㄒㄧㄢ":"湧現",
"ㄩㄥㄒㄧㄣ":"用心",
"ㄩㄥㄒㄧㄤ":"用項/永巷",
"ㄩㄥㄒㄧㄥ":"用刑/庸行",
"ㄩㄥㄓㄨㄤ":"泳裝",
"ㄩㄥㄓㄨㄥ":"擁腫/臃腫",
"ㄩㄥㄕㄨㄟ":"用水",
"ㄩㄥㄖㄨㄥ":"雍容",
"ㄩㄥㄗㄨㄛ":"傭作/顒坐",
"ㄩㄥㄘㄨㄣ":"永存",
"ㄩㄥㄙㄨㄣ":"饔飧",
"ㄩㄧㄍㄢㄕ":"褕衣甘食",
"ㄩㄧㄒㄩㄝ":"語意學",
"ㄩㄧㄝㄇㄟ":"榆葉梅",
"ㄩㄧㄡㄖㄣ":"于右任",
"ㄩㄧㄡㄩㄢ":"育幼院",
"ㄩㄧㄧㄔㄥ":"羽翼已成",
"ㄩㄨㄟㄨㄣ":"魚尾紋",
"ㄩㄨㄣㄊㄞ":"宇文泰",
"ㄩㄨㄣㄏㄨ":"宇文護",
"ㄩㄨㄤㄊㄞ":"禹王臺",
"ㄩㄩㄌㄧㄤ":"禹餘糧",
"ㄩㄩㄒㄧㄩ":"喁喁細語",
"ㄩㄩㄣㄨㄣ":"虞允文"
      }
